import { Component, HostListener, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { COL_DEF_FIELDS, COLS, COMPANY, COMPANY_FILTERS, COMPANY_USERS, COMPANY_USERS_FILTER_TYPES, DATEPICKER_CONSTANTS, DEFAULT_STATUS, DropdownSettings, GETSTATUS_CLASS, ICON_ACTION_NAMES, MESSAGE, RESPONSE_STATUS } from '../../../constants/studio-portal.constant';
import { COMPANY_NUMBER, INDEX, PAGES } from '../../../enum/studio-portal.enum';
import { COMPANY_CONTACT_INFO, COMPANY_USERS_STUDIO } from 'mockData/development/company-list';
import { AddCompanyUserComponent } from 'src/app/shared/components/add-company-user/add-company-user.component';
import { COMMON_GRID_BREAKPOINTS, FORMAT_TIME, ITEMS_PER_PAGE, PAGINATION_PAGES } from 'src/app/shared/enum/shared.enum';
import { COMMON_GRID_OPTIONS } from 'src/app/shared/constants/common';
import { companyFilterData, companyId, activeProjectsTypeahead, companyJobTitleTypeahead, companyStatusData, companyTypeahead, companyUserTypeahead, userIdTypeahead } from 'mockData/development/company-filters-data';
import * as _ from 'lodash';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import { CompanyService } from '../../../services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IconsService } from 'src/app/shared/services/icons/icons.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { HeaderService } from 'src/app/core/services/header/header.service';
import { UserProfileResponse } from 'src/app/shared/models/profileModel';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-company-project',
  templateUrl: './company-project.component.html',
  styleUrls: ['./company-project.component.scss']
})
export class CompanyProjectComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>; // Template for success toast
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>; // Template for danger toast
  @ViewChild('successTplActive', { static: false }) successTplActive!: TemplateRef<any>;
  @ViewChild('successEditUserTpl', { static: false }) successEditUserTpl!: TemplateRef<any>;
  @ViewChild('dangerEditUserTpl', { static: false }) dangerEditUserTpl!: TemplateRef<any>; // Template for danger toast


  companyUsersData: any;
  defaultSelectedStatus: any[];
  companyUserData: any[] = [];
  userFilterData: any[] = [];
  jobTitleFilterData: any[] = [];
  activeprojects: any[] = [];
  typeaheadData: { id: any; fullName: string; }[];
  company_id: number;
  typeaheadId: any = [];
  typeaheadUser: any = [];
  userIdFilterData: any[] = [];
  tranferValue: any;
  currentValue: string;
  message = MESSAGE;
  company_name: any;
  companyUserMetaData: any;
  userDetails: any;
  userProfileId: any;
  userLoggedIn: any;
  statusDeactivate: string;
  companyuserId: any;
  iconActions = ICON_ACTION_NAMES;
  addUserSuccess: any;
  addUserError: any;
  page: any;
  editUserSuccess: any;
  editUserError: any;
  datePickerPlaceholder = DATEPICKER_CONSTANTS;
  dateFormatId;
  userProfileSettings;
  constructor(
    private modalService: NgbModal,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private iconService: IconsService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private headerService: HeaderService
  ) {
  }

  dropdownList: any[] = [];
  selectedItems: any[] = [];
  cols = COLS;
  colsForLargeScreen = this.cols.company_user[0].colsForLargeScreen;
  colsForSmallScreen = this.cols.company_user[0].colsForSmallScreen;
  colsForMediumScreen = this.cols.company_user[0].colsForMediumScreen;
  colsForExtraLargeScreen = this.cols.company_user[0].colsForExtraLargeScreen;
  modalReference: NgbModalRef;
  company = COMPANY;
  company_users = COMPANY_USERS;
  companynumber = COMPANY_NUMBER;
  company_contact_info = COMPANY_CONTACT_INFO;
  getClass = GETSTATUS_CLASS;
  pages = PAGINATION_PAGES;
  breakpoints = COMMON_GRID_BREAKPOINTS;
  itemsPerPage = ITEMS_PER_PAGE;
  gridApi!: GridApi;
  @Input() pageSizeOptions: number[] = [this.itemsPerPage.ten, this.itemsPerPage.twenty, this.itemsPerPage.fifty];  // Input for page size options in pagination
  startRow: number = PAGINATION_PAGES.one;
  endRow: number = this.itemsPerPage.ten;
  gridOptions: GridOptions = COMMON_GRID_OPTIONS;
  selectedFromDate: any = null;
  selectedToDate: any = null;
  company_data = COMPANY_USERS_STUDIO;
  // Arrays to hold the selected filters for projects, types, departments, and statuses
  filteredProjects: any[] = [];
  filteredStatus: any[] = [];
  filteredData: any[] = [];
  filteredId: any[] = [];
  filteredUser: any[] = [];
  filteredJobtitle: any[] = [];
  filterTypes = COMPANY_USERS_FILTER_TYPES;
  isFiltersSelected: boolean = false;

  // Data for dropdown lists projects, types, departments, status and dayes
  activeProjects = companyFilterData;
  status = companyStatusData;
  id = companyId;
  companyConstants = COMPANY_FILTERS;
  //user = companyStatusData;
  jobTitle = companyStatusData;
  activity = companyStatusData;
  dropdown = DropdownSettings;
  colDefFields = COL_DEF_FIELDS;
  defaultStatusConst = DEFAULT_STATUS;
  responseStatus = RESPONSE_STATUS;
  // Default settings for the dropdown components
  defaultSettings: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false

  };
  user = companyUserTypeahead;
  jobtitle = companyJobTitleTypeahead;
  companySearch = companyTypeahead;
  activeProjectType = activeProjectsTypeahead;
  userId = userIdTypeahead;
  activeProjectsTypeahead: any = 'typeahead';
  userTypeahead: any = 'typeahead';
  companyTypeahead: any = 'typeahead';
  userIdTypeahead: any = 'typeahead';
  rowData: any = [];
  totalRows: number = this.rowData?.length;
  colDefs: ColDef[];
  private unsubscribe$ = new Subject<void>();
  ngOnInit() {
    this.getColDef();
    this.iconService.currentValue.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.currentValue = value;
      if (!_.isNil(this.currentValue)) {
        this.onValueChange(value); // Trigger a method
      }
    });
    this.route.queryParams.subscribe(params => {
      this.company_id = params['companyid'];
      this.company_name = params['companyName'];
      this.page = params['page']
    });
    if (this.company_id != null) {
      this.getCompanyUsersData();
    }
    this.getCompanyUserMetaData();
    this.getDateFormatId();


  }

  /**
* getDateFormat() - method to get the date fomrat from user profile
*/
  getDateFormatId(): void {
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    this.headerService
      .getuserprofile(userProfileId.userProfileId)
      .subscribe({
        next: (response: UserProfileResponse | null) => {
          if (response) {
            this.userProfileSettings = response?.userProfileSearch;
            this.dateFormatId = this.userProfileSettings?.userPreferences?.dateFormatSettings?.dateFormatId;
          }
        }
      });
  }
  /**
  *getColDef() - getColDef method to get column definations for the ag-grid table
  */
  getColDef() {
    this.colDefs = [
      {
        headerName: 'ID#',
        field: 'userid', cellClass: 'common-cell-data-bold', maxWidth: 93, sortable: true
      },
      {
        headerName: 'User',
        field: 'user',
        wrapText: true,
        autoHeight: true,
        filter: true,
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.fullName;
        },
        comparator: (valueA, valueB) => {
          const lowerA = (valueA || '').toString().toLowerCase();
          const lowerB = (valueB || '').toString().toLowerCase();
          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },
        cellRenderer: (params: ICellRendererParams) => {
          const fullName = params.data.fullName;
          const email = params.data.email;
          const requestType = params.data.requestType;
          const requestTypes = requestType.split(',').map(type => type.trim()); // Ensure it's an array
          // Check if both 'company admin' and 'account payable' are present
          const hasCompanyAdmin = requestTypes.some(type => type.toLowerCase() === 'company admin');
          const hasAccountPayable = requestTypes.some(type => type.toLowerCase() === 'account payable');
          const rolesHtml = requestTypes.map(type => {
            let requestTypeClass = '';
            if (type.toLowerCase() === 'account payable' && !hasCompanyAdmin) {
              requestTypeClass = 'account-payable';
            } else if (type.toLowerCase() === 'company admin' && !hasAccountPayable) {
              requestTypeClass = 'company-admin';
            } else if (type.toLowerCase() === 'company admin' && hasAccountPayable) {
              requestTypeClass = 'company-admin account-company merged-mb';
            } else if (type.toLowerCase() === 'account payable' && hasCompanyAdmin) {
              requestTypeClass = 'account-payable account-company ';
            } else {
              requestTypeClass = '';
            }
            return `<span class="common-studio-user-request-label ${requestTypeClass}">${type}</span>`;

          }).join('');

          return `
      <span class="common-split-cell-section">
      <span class="common-split-cell-data-1">${fullName}</span>
      <span class="common-split-cell-data-2 ellipsis-email">${email}</span>
      <span>${rolesHtml}</span>
      </span>`;
        },


        minWidth: 165,
      },
      {
        headerName: 'USER',
        field: 'merged-field',
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams) => {
          const fullName = params.data.fullName;
          const email = params.data.email;
          const status = params.data.status;
          const statusClass = this.getStatusClass(status);

          return `
            <span class="common-split-cell-section">
              <span class="common-split-cell-data-1">${fullName}</span>
              <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
              <span class="common-split-cell-data-4">
                <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
              </span>
            </span>
          `;
        },
        minWidth: 160,
        hide: true
      },
      {
        headerName: 'STATUS',
        field: 'status',
        sortable: true,
        cellRenderer: (params: ICellRendererParams) => {
          const statusClass = this.getStatusClass(params.value);
          return `
              <div class="status-container">
                <div class="${statusClass}">${params.value}</div>
              </div>
            `;

        },
        minWidth: 160,
      },
      {
        headerName: 'Last Login',
        field: 'activity',
        filter: false,
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.lastLoginDate;
        },
        cellRenderer: (params: ICellRendererParams) => {
          let formattedDate = '';
          let formattedTime = '';
          if (params.data.lastLoginDate != null) {
            formattedDate = params.data.lastLoginDate;
            formattedTime = params.data.lastLoginTime;
          } else {
            formattedDate = 'N/A';
            formattedTime = '';
          }
          return `<span class="common-split-cell-section">
                    <span class="common-split-cell-data-default-font common-split-cell-data-1">${formattedDate}</span>
                    <span class="common-split-cell-data-2">${formattedTime}</span>
                  </span>`;
        }, minWidth: 120,
      },
      {
        headerName: 'USER-STATUS',
        field: 'user-status',
        sortable: true,
        cellRenderer: (params: ICellRendererParams) => {

          const fullName = params.data.fullName;
          const email = params.data.email;
          const status = params.data.status;
          const statusClass = this.getStatusClass(status);
          return `<span class="common-split-cell-section common-mobile-section">
                    <span class="common-split-cell-data-default-font common-split-cell-data-1">${fullName}</span>
                    <span class="common-split-cell-data-2">${email}</span>
                    <span class="status-container">
                      <span class="${statusClass} common-merged-cell-sm">${status}</span>
                    </span>
                  </span>`;
        }, minWidth: 140, flex: 1.7,
      },
      {
        headerName: 'JOB TITLE', field: 'jobTitle', cellClass: 'common-cell-data-bold', minWidth: 150,
        comparator: (valueA, valueB) => {
          const lowerA = (valueA || '').toString().toLowerCase();
          const lowerB = (valueB || '').toString().toLowerCase();
          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },
      },
      {
        headerName: 'ACTIVE PROJECTS', field: 'activeProjects', cellClass: 'common-cell-data-mt account-user-active-project-cell', minWidth: 120, flex: 1.5, filter: false,
        comparator: (valueA, valueB) => {
          const lowerA = (valueA || '').toString().toLowerCase();
          const lowerB = (valueB || '').toString().toLowerCase();
          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },
      },
      {
        field: 'icons',
        headerName: '',
        cellRenderer: IconsComponent,
        cellClass: 'company-user-icons',
        filter: false,
        minWidth: 125,
        maxWidth: 140
      }
    ];
  }

  /**
  *getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
   * to add the user in company.
  */

  getCompanyUserMetaData(): void {
    this.companyService.getCompanyUsersMetadata(this.company_id).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data?.companyUserMetaData;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }
  /**
    * onCellClicked() - Method triggered when a cell is clicked in the ag-grid table.
    * Opens the edit user modal upon cell click.
    * @param evt - The event data containing the clicked row details.
   */
  onCellClicked(evt: any) {
    if (evt?.colDef?.field === this.colDefFields.icons) {
      evt.event.stopPropagation();
      return;
    }
    const userProfileId = evt?.data?.userid;
    this.userProfileId = userProfileId;
    this.getUserDetailsById(this.userProfileId);
  }
  /**
    * getUserDetailsById() - Fetches user details by user ID and opens the edit user modal.
    * @param userId - The ID of the user whose details are to be fetched.
   */
  getUserDetailsById(userProfileId: number): void {
    this.companyService.getUserByUserProfileId(userProfileId).subscribe(
      (data) => {
        this.userDetails = data?.companyUser;
        if (this.company_name != null) {
          this.userDetails['company'] = this.company_name;
        }
        this.openEditUserModal(this.userDetails);
      },
      (error) => {
        this.showDanger(this.successTpl);
      }
    );
  }
  /**
    * openEditUserModal() - Method to open the modal for editing a user.
    * The modal is initialized with "Edit User" as the title and is displayed in full-screen.
   */
  openEditUserModal(userDetails: any) {
    console.log(userDetails)
    window.scrollTo(0, 0);
    let title = this.company?.editCustomerUser;
    let metaData = this.companyUserMetaData;
    let modalType = this.company?.modalEdit;
    let componentType = this.company?.componentType;
    let portalType = this.company?.portaltype;
    let userType = this.company?.companyUserType;
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true
    });
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.isEditMode = true; // Edit mode
    this.modalReference.componentInstance.userDetails = userDetails;
    this.modalReference.componentInstance.userType = userType;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data?.status == this.responseStatus.success) {
          this.editUserSuccess = this.message?.companyUserEditSuccess;
          this.showSuccess(this.successEditUserTpl);
          this.getCompanyUsersData();
        } else {
          this.editUserError = data?.message;
          this.showDanger(this.dangerEditUserTpl);
        }
      }
    });
    this.modalReference.componentInstance.emitError.subscribe((data) => {
      if (data) {
        this.addUserError = data?.message;
        this.showDanger(this.dangerTpl);
      }
    });
  }
  /**
  *applyDefaultStatus() - Method to to set default active status 
 */
  applyDefaultStatus(): void {
    let defaultStatus = this.defaultStatusConst.active;
    let defaultId = this.defaultStatusConst.activeId;
    this.filteredStatus = [{ id: defaultId, text: defaultStatus.toUpperCase() }];
    this.defaultSelectedStatus = this.filteredStatus;

    let value: any = [];
    this.status.forEach((item: any) => {
      if (item.text == this.defaultStatusConst.activeCapital)
        value.push(item)
    })
    this.defaultSelectedStatus = value;
    this.applyFilters();
  }

  /**
   *getCompanyUsersData() - Method to call company users data by passing company id
  */
  getCompanyUsersData(): void {
    this.loaderService.setLoadingState(true);

    this.companyService.getCompanyUsersByCompID(this.company_id).subscribe((data: any) => {

      this.loaderService.setLoadingState(false);
      this.rowData = _.map(data?.companyUsers || [], user => {
        let requestType = [];
        let requestTypeClass = [];
        this.typeaheadId.push(user.userProfileId);
        this.typeaheadUser.push({
          'firstname': user.firstName,
          'email': user.email
        });

        if (user.companyAdminInd === 1) {
          requestTypeClass.push('company admin');
          requestType.push('Company Admin');
        }
        if (user.accountPayableUserInd === 1) {
          requestTypeClass.push('account payable');
          requestType.push('Account Payable');
        }
        return {
          userid: user.userProfileId,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.emailAddress,
          requestType: requestType.join(','),
          requestTypeClass: requestTypeClass.join(','),
          status: user.statusActiveInd === 'Y' ? 'ACTIVE' : 'DEACTIVATED',
          lastLoginDate: user.lastLoginDate,
          lastLoginTime: user.lastLoginTime,
          jobTitle: user.jobTitle || '',
          activeProjects: user.projects.split(','),

        };

      });
      this.companyUserData = this.rowData;
      this.modifyDataset(data.companyUsers);
      this.applyDefaultStatus()
    },
      (error) => {
        this.loaderService.setLoadingState(false);
      }
    )
  }

  /**
*modifyDataset() - modifyDataset method transform the data for the filters.
* @param metaData - getting metadata from the API based on screen specific.
*/

  modifyDataset(metaData): void {

    const filteredMetaData = _.filter(metaData, x => x.emailAddress !== null);

    this.userFilterData = _.map(filteredMetaData, ({ userProfileId, emailAddress, firstName, lastName }) => ({
      id: userProfileId,
      email: emailAddress,
      firstname: firstName,
      lastname: lastName,
      fullName: `${firstName} ${lastName}`
    }));


    this.userIdFilterData = _.cloneDeep(this.userFilterData);

    const uniqueJobTitles = _.uniqBy(
      _.map(filteredMetaData, ({ userProfileId, jobTitle }) => ({
        id: userProfileId,
        jobtitle: jobTitle
      })),
      item => item.jobtitle?.toLowerCase()
    );

    // Transform data for projects
    const transformedData = _.flatMap(filteredMetaData, ({ userProfileId, projects }) => {
      return projects
        ? _.map(projects.split(','), project => ({
          userProfileId,
          project: _.trim(project)
        }))
        : [{ userProfileId, project: _.trim(projects) }];
    });

    // Generate unique active projects
    const uniqueActiveProjects = _.uniqBy(
      _.filter(transformedData, x => x.project && x.project.length > 0),
      'project'
    );

    // Generate job title filter data
    this.jobTitleFilterData = _.map(uniqueJobTitles, (item, index) => ({
      id: index,
      jobtitle: item.jobtitle
    }));

    // Generate active projects data
    this.activeprojects = _.map(uniqueActiveProjects, (item, index) => ({
      id: index,
      text: item.project
    }));
  }

  /**
     *formatDateTime() - Method to covert date from api to last login format
    */
  formatDateTime(dateTimeString: string): { formattedDate: string, formattedTime: string } {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + FORMAT_TIME.one;
    const year = date.getFullYear().toString().slice(-FORMAT_TIME.two);

    const formattedDate = `${month}/${day}/${year}`;
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(FORMAT_TIME.two, '0');
    const ampm = hours >= FORMAT_TIME.twelve ? FORMAT_TIME.PM : FORMAT_TIME.AM;
    hours = hours % FORMAT_TIME.twelve;
    hours = hours ? hours : FORMAT_TIME.twelve;

    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return { formattedDate, formattedTime };
  }

  /**
    * statusCellRenderer() - Custom cell renderer for displaying a status with a specific class.
    * @param params -  Parameters for the cell renderer, provided by the grid framework. 
    * This includes the cell value and other metadata.
    * @returns - A string containing HTML markup for rendering the cell content.
    */
  statusCellRenderer(params: ICellRendererParams): string {
    const statusClass = this.getStatusClass(params.value);
    return `
      <div class="status-container">
        <div class="${statusClass}">${params.value}</div>
      </div>
    `;
  }

  /**
     * getStatusClass() - Returns the appropriate class for each status value
     * @param status - The status string to get class for
     */
  getStatusClass(status: string): string {
    switch (status) {
      case this.getClass.active:
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case this.getClass.deactivated:
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }

  /**
   * onGridReady() - Method to handle the grid's initialization
   * @param params - Parameters passed when the grid is ready
   */
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.updatePagination();
  }

  /**
  * onPageSizeChanged() - Method to handle changes in the number of items per page in the grid
  * @param event - The event containing the new page size value
  */
  onPageSizeChanged(event: any): void {
    const value = event.target.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }

  /**
   * onBtNext() - Method to handle clicking the "Next Page" button
   */
  onBtNext(): void {
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }

  /**
   * onBtPrevious() - Method to handle clicking the "Previous Page" button
   */
  onBtPrevious(): void {
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }

  /**
   * updatePagination() - Method to update the pagination details such as the current start and end rows
   */
  updatePagination(): void {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + this.pages.one;
      this.endRow = Math.min((currentPage + this.pages.one) * pageSize, this.totalRows);
      this.totalRows = this.rowData?.length;
    }
  }

  /**
   * @HostListener('window:resize') - Method to listen to window resize events
   * Adjusts the column visibility based on the screen size when the window is resized
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const screenWidth = event?.target?.innerWidth;
    this.adjustColumns(screenWidth);
  }

  /**
     * adjustColumns() - Method to adjust the visibility of columns based on screen width
     * @param screenWidth - The width of the screen to determine which columns to display
     */
  adjustColumns(screenWidth: number): void {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      // Set visible columns based on the screen width ranges
      if (screenWidth <= this.breakpoints.threeSeventyEight) {
        this.setVisibleColumns(this.colsForSmallScreen);
      } else if (screenWidth > this.breakpoints.threeSeventyEight && screenWidth <= this.breakpoints.fiveHundredAndTen) {
        this.setVisibleColumns(this.colsForMediumScreen);
      } else if (screenWidth > this.breakpoints.fiveHundredAndTen && screenWidth <= this.breakpoints.nineNintyTwo) {
        this.setVisibleColumns(this.colsForLargeScreen);
      } else {
        this.setVisibleColumns(this.colsForExtraLargeScreen);
      }
    }
  }

  /**
     * setVisibleColumns() - Method to set which columns are visible
     * @param visibleCols - An array of column IDs that should be visible
     */
  setVisibleColumns(visibleCols: string[]): void {
    const allColumnIds: string[] = [];
    this.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    this.gridApi.setColumnsVisible(allColumnIds, false);
    this.gridApi.setColumnsVisible(visibleCols, true);
  }

  /**
  *addUser() - invoke the add user modal popup
  */

  addUser(): void {
    let title = this.company?.adduser;
    let metaData = this.companyUserMetaData;
    let portalType = this.company?.portaltype;
    let modalType = this.company?.modalAdd;
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = this.company?.componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data?.status == this.responseStatus.success) {
          this.addUserSuccess = this.message?.companyUserAddSuccess;
          this.showSuccess(this.successTpl);
          this.getCompanyUsersData();
        }
      }
    });
    this.modalReference.componentInstance.emitError.subscribe((data) => {
      if (data) {
        this.addUserError = data?.message;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
 * selectedStatus() - Method to handle selection of status from the dropdown
 * @param selectedItems - The selected items from the dropdown
 */
  selectedStatus(selectedItems: any): void {
    this.filteredStatus = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
* selectedStatus() - Method to handle selection of status from the dropdown
* @param selectedItems - The selected items from the dropdown
*/
  selectedId(selectedItems: any): void {
    this.filteredId = selectedItems;
    this.applyFilters();
  }

  /**
* selectedUser() - Method to handle selection of users from the dropdown
* @param onSelect - The selected items from the dropdown
*/

  selectedUser(onSelect: any): void {
    this.filteredUser = onSelect;
    this.applyFilters();
  }

  /**
     * clearFilter() - Method to clear a specific filter
     * @param filterType - The type of filter to clear (project, type, department, status)
     */
  clearAllFilters(): void {
    // Clear Projects
    this.filteredProjects = [];
    this.selectedProject(this.filteredProjects);
    this.activeProjects = this.filteredProjects;

    // Clear Id
    this.filteredId = [];
    this.selectedId(this.filteredId);
    this.id = this.filteredId;

    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;


    this.filteredUser = []; // Clear the user filter
    this.selectedUser(this.filteredUser);
    this.user = this.filteredUser;

    //clear the selected dates
    this.selectedFromDate = null;
    this.selectedToDate = null;

    this.filteredJobtitle = []; // Clear the jobtitle filter
    this.selectedJobtitle(this.filteredJobtitle);
    this.jobtitle = this.filteredJobtitle;

    // Re-apply filters to reflect the cleared state
    this.applyFilters();
  }

  /**
    * onDateRangeSelected() - Method triggered when a date range is selected.
    * @param dateRange - The object containing the selected date range
  */

  onDateRangeSelected(dateRange: { fromDate: NgbDate | null; toDate: NgbDate | null }) {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate;  // Treat single date as both 'from' and 'to'
    }
    else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }
  /**
     * formatDateRange() - method to format the date
     * @param fromDate - The start date.
     * @param toDate - The end date.
     * @returns A string representing the formatted date range.
   */
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    // dateFormatId = 1 represents (mm/dd/yyyy) format and dateFormatId = 2 represents(dd/mm/yyyy) format
    const formattedFromDate = this.dateFormatId === 1 ? `${formatToTwoDigits(fromDate.month)}/${formatToTwoDigits(fromDate.day)}/${fromDate.year.toString()}` : `${formatToTwoDigits(fromDate.day)}/${formatToTwoDigits(fromDate.month)}/${fromDate.year.toString()}`;
    const formattedToDate = this.dateFormatId === 1 ? `${formatToTwoDigits(toDate.month)}/${formatToTwoDigits(toDate.day)}/${toDate.year.toString()}` : `${formatToTwoDigits(toDate.day)}/${formatToTwoDigits(toDate.month)}/${toDate.year.toString()}`;

    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }
  /**
     * clearDateRange() - method to clear the selected date range.
   */
  clearDateRange(): void {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }
  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any {
    return _.filter(arr, i => i.id !== item.id);
  }

  /**
   * applyFilters() - Method to apply selected filters to the project data and update the paginated data
   */
  applyFilters(): void {
    let filteredData = this.companyUserData;
    if (this.filteredStatus?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredStatus, x => project.status === x.text)
      );
    }
    if (this.filteredProjects?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredProjects, x => _.includes(project.activeProjects, x.text))
      );
    }
    if (this.filteredId?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredId, x => project.userid == x.id)
      );
    }
    if (this.filteredUser?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredUser, x => {
          const firstNameFromFullName = project.fullName.split(' ')[0];
          return firstNameFromFullName === x.firstname;
        })
      );
    }

    if (this.filteredJobtitle?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredJobtitle, x => project.jobTitle === x.jobtitle)
      );
    }
    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);

      // Filter rows based on the last login date
      filteredData = _.filter(filteredData, row => {
        // dateFormatId = 1 represents (mm/dd/yyyy) format and dateFormatId = 2 represents(dd/mm/yyyy) format
        if (this.dateFormatId === 1) {
          const lastLoginDate = new Date(row?.lastLoginDate);
          return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
        }
        else {
          const lastLoginDate = this.parseDate(row?.lastLoginDate);
          return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
        }
      });
    }

    this.hasSelectedFilters()
    this.filteredData = filteredData;
    this.rowData = this.filteredData;
  }

  /**
* parseDate() - method to convert the date into dd/mm/yyyy format
* @param dateString 
* @returns 
*/
  parseDate(dateString: string | null): Date | NgbDate | null {
    if (!dateString) {
      return null; // Return null if the dateString is null or empty
    }
    else {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    }
  }

  /**
* selectedProject() - Method to handle selection of projects from the dropdown
* @param selectedItems - The selected items from the dropdown
*/
  selectedProject(onSelect: any): void {
    this.filteredProjects = onSelect;
    this.applyFilters(); // Apply filters after selection
  }
  /**
    * selectedProject() - Method to handle selection of jobtitle from the dropdown
    * @param onSelect - The selected items from the dropdown
    */

  selectedJobtitle(onSelect: any): void {
    this.filteredJobtitle = onSelect;
    this.applyFilters();
  }
  /**
     * removeSelectedItem() - Method to remove a specific item from a selected filter
     * @param filterType - The type of filter the item belongs to (project, type, department, status)
     * @param item - The item to be removed
     */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.activeprojects: {
        let projectArray = [];
        this.filteredProjects = this.filteredDataList(this.filteredProjects, item);
        projectArray.push(item);
        this.activeProjects = projectArray;
      }
        break;
      case this.filterTypes.status: {
        let statusArray = [];
        this.filteredStatus = this.filteredDataList(this.filteredStatus, item);
        statusArray.push(item);
        this.status = statusArray;
      }
        break;
      case this.filterTypes.id: {
        let idArray = [];
        this.filteredId = this.filteredDataList(this.filteredId, item);
        idArray.push(item);
        this.id = idArray;
      }
        break;
      case this.filterTypes.user: {
        let userArray = [];
        this.filteredUser = this.filteredDataList(this.filteredUser, item);
        userArray.push(item);
        this.user = userArray;
      }
        break;
      case this.filterTypes.jobtitle: {
        let jobtitleArray = [];
        this.filteredJobtitle = this.filteredDataList(this.filteredJobtitle, item);
        jobtitleArray.push(item);
        this.jobtitle = jobtitleArray;
      }
        break;
    }
    this.applyFilters();
  }

  /**hasSelectedFilters: Returns isFiltersSelected */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredProjects?.length > 0 ||
      this.filteredId?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      this.selectedFromDate !== null ||
      this.selectedToDate !== null
    return this.isFiltersSelected;
  }


  /**
  *viewAllCompanies() - mthd used for redirect to companies list
  */
  viewAllCompanies(): void {
    this.router.navigate(['/feature/studio-dashboard/company/companies']);
  }

  /**
  *redirectViewCompany() - mthd used for redirect to view company page
  */
  redirectViewCompany(): void {
    this.router.navigate(['/feature/studio-dashboard/company'], {
      queryParams: {
        companyid: this.company_id,
      }
    });
  }
  /**
    * onValueChange(val: any) - Method to handle changes in value.
    * This method processes the incoming value, determines the associated icon action,
    * and triggers the appropriate user action based on the parsed value.
    * 
    * @param val - The value representing the action to be taken, expected to be a JSON string.
    */
  onValueChange(val: any) {
    this.tranferValue = val;
    const parsedVal = val;
    if (parsedVal != null) {
      this.companyuserId = parsedVal.rowData?.userid;
      this.statusDeactivate = parsedVal.rowData?.status != 'ACTIVE' ? 'Y' : 'N';
    }
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    if (userProfile != null) {
      this.userLoggedIn = userProfile.userProfileId;
    }
    switch (parsedVal.iconInfo) {
      case this.iconActions.freez:
        console.log('Anyone can takeover this icon');
        break;
      case this.iconActions.deActivate:
        this.deactivatingUser();
        break;
      case this.iconActions.activate:
        this.activatingUser();
        break;
      default:
        console.log('Unknown action: ' + parsedVal.iconInfo);
        break;
    }
  }

  /**
    *  onToastEvent() - method to display the toast
    * @param - event to display success
    */
  onToastEvent(event: any): void {
    if (event === 'success')
      this.toastService.show('Success', {
        classname: 'custom-toast',
        delay: 3000,
      });
  }

  /**
   * deactivatingUser() - Method to handle user deactivation.
   * Calls the deactivateUser method and dismisses all active modals.
   */
  deactivatingUser(): void {
    this.deactivateUser();
    this.modalService.dismissAll();
  }

  /**
   * activateUser() - Method to handle user activation.
   * Calls the activatingUser method and dismisses all active modals.
   */
  activateUser(): void {
    this.activatingUser();
    this.modalService.dismissAll();
  }

  /**
   * deactivateUser() - Method to deactivate a user.
   * Calls the iconService to deactivate the user and handles the response.
   * Displays success message if deactivation is successful; logs a warning if not.
   */
  deactivateUser(): void {

    this.iconService.deactivateCompanyUser(this.companyuserId, this.userLoggedIn, this.statusDeactivate).subscribe({
      next: (response: any) => {
        if (response) {
          this.showSuccess(this.successTpl);
          this.currentValue = null;
          this.iconService.changeValue(null);
          this.getCompanyUsersData();
        } else {
          this.showDanger(this.successTpl);
        }
      },
      error: (error) => {
        this.showDanger(this.successTpl);
      },
    });
  }

  /**
   * activatingUser() - Method to activate a user.
   * Calls the iconService to activate the user and handles the response.
   * Displays success message if activation is successful; logs a warning if not.
   */
  activatingUser(): void {

    this.iconService.activateCompanyUser(this.companyuserId, this.userLoggedIn, this.statusDeactivate).subscribe({
      next: (response: any) => {
        if (response) {
          this.showSuccessActi(this.successTplActive);
          this.currentValue = null;
          this.iconService.changeValue(null);
          this.getCompanyUsersData();
        } else {
          this.showDanger(this.successTpl);
        }
      },
      error: (error) => {
        this.showDanger(this.successTpl);
      },
    });
  }


  /**
    * showSuccess() - Displays a success toast notification with custom content and options.
    * @param successTpl - The template reference containing the toast's content.
    */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  showSuccessActi(successTplActive: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTplActive, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message.
   * @param dangerTpl - The template reference for the danger message to display.
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.clear()
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

}

