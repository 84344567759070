import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { PASSES_EXPECTED_TODAY } from '../../../constants/customer-portal.constant';
import { PASSES_NUMBER } from '../../../enum/customer-portal.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewPassesComponent } from '../view-passes/view-passes/view-passes.component';
import { PassesService } from '../../../services/wbd-passes/passes.service';
import { All_PASSES } from 'mockData/development/passes';
import * as _ from 'lodash';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-pass-expected-today',
  templateUrl: './pass-expected-today.component.html',
  styleUrls: ['./pass-expected-today.component.scss'],
})
export class PassExpectedTodayComponent implements OnInit {
  @ViewChild('passesGrid') passesGrid!: AgGridAngular;
  gridApi!: GridApi;
  gridOptions: GridOptions = {};
  rowDataPasses: any;
  colDefsPasses: ColDef[] = [];
  passesExpectedToday = PASSES_EXPECTED_TODAY;
  passesNumber = PASSES_NUMBER
  data: any;
  showCheckedInOnly: boolean;
  filteredRowData: any;
  filtered: any;
  filteredData: any;
  selectedPassType: string;
  count: any;
  driveOn: any;
  walkOn: any;
  allPasses: any;
  userProfile: any;

  constructor( private modalService: NgbModal, private passesservice: PassesService, private loaderService: LoaderService,) {}

  ngOnInit(): void {
    this.userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.getPasses()
    this.generateRequestAgGrid();
  }


  /**
   * getPasses() - to get the reponse of passes
   */
  getPasses(): void {
    this.loaderService.setLoadingState(true);
    const body =
    {
      "Email": this.userProfile.emailAddress,
      "TableName": "Pass",
      "Key": null,
      "ScreenName": "GreenLight",
      "SiteID": 1,
      "ObjectID": null,
      "ParentObjectField": null,
      "LimitNeeded": true
  }
   
    this.passesservice.getPasses(body).subscribe({
      next: (response: any) => {
        if (response) {
          this.data = response?.wbdPassApiResponse
          this.filterData()
          this.loaderService.setLoadingState(false);
        } else {
          console.warn(this.passesExpectedToday.error);
        }
      },
      error: (error) => {
        console.error(error);
        this.loaderService.setLoadingState(false);
      },
    });
  }
  

  /**
   * applyInitialFilter() - Initially filter the  to show only "CHECKED IN" passes
   */
  applyInitialFilter(): void {
    this.showCheckedInOnly = true;
    this.filteredRowData = _.map(
      _.filter(this?.filteredData, (pass) => pass.Status === this.passesExpectedToday.checkedInStatus), 
      (pass) => {  
        return {
          entry_type: pass.EntryType,
          username: pass.VisitorName,
          gate: pass.EntryPoint,
          status: pass.Status,
          passArrival: pass.PassArrival
        };
      }
    );
    this.rowDataPasses = this?.filteredRowData;
  }

  /**
   * filterData() - to filter the data for today
   */
    filterData(): void {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.filtered = _.filter(this.data, (item) => {
        const itemDate = new Date(item.PassActive);
        itemDate.setHours(0, 0, 0, 0); 
        return itemDate.getTime() === today.getTime();  
      });
      this.filteredData = this.filtered
      this.allPasses = this.filteredData?.length
      this.driveOn = _.filter(this.filteredData, (item) => item.EntryType === this.passesExpectedToday.drive_on)?.length;
      this.walkOn = _.filter(this.filteredData, (item) => item.EntryType === this.passesExpectedToday.walk_on)?.length;
      this.getAllPasses()
  }    

    /**
   * toggleCheckedInStatus() - Filter the data based on the toggle state
   */
     toggleCheckedInStatus(event: any): void {
      this.showCheckedInOnly = event.target.checked;
      if (this.showCheckedInOnly) {
        this.applyInitialFilter()
      } else {
         this.getAllPasses()
      }
    }

  /**
   * getAllPasses() - to get all the data related to that specific day
   */
    getAllPasses(): void{
      const passesData = this.filteredData?.map((pass) => {
        return{
          entry_type: pass.EntryType,
          username: pass.VisitorName,
          gate: pass.EntryPoint,
          status: pass.Status,
          passArrival: pass.PassArrival
        }   
      });
      this.rowDataPasses = passesData;
    }
  
  /**
   * generateRequestAgGrid() - configuration for Ag grid
   */
  generateRequestAgGrid() {
    this.gridOptions = {
      domLayout: 'autoHeight',
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        filter: true,
      },
    };

    this.colDefsPasses = [
      {
        headerName: '',
        field: 'merged-field',
        wrapText: true,
        autoHeight: true,
        cellStyle: { paddingRight: '0px', paddingLeft:'0px' },
        cellRenderer: (params: ICellRendererParams) => {
          const username = params.data?.username || '';
          const entry_type = params.data?.entry_type || '';
          const gate = params.data?.gate || '';
          const status = params.data?.status || null;
          const arrivalTime = params.data?.passArrival || '';
        
          const gateNumber = gate.trim() !== '' ? gate.split(' ').slice(0, 2).join(' ') : '---';
          const imageUrl = entry_type === 'Walk-On'
            ? 'assets/images/man.svg'
            : entry_type === 'Drive-On'
            ? 'assets/images/car.svg'
            : 'assets/images/default.svg';
        
          const formatTime = (time: string) => {
            const [hours, minutes] = time.split(':');
            let hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12;
            return `${hour}:${minutes} ${ampm}`;
          };
        
          const formattedArrivalTime = arrivalTime ? formatTime(arrivalTime) : '';
          const statusClass = status ? this.getStatusClass(status.toUpperCase()) : '';
        
          return `
            <div class="common-split-cell-with-image">
              <div class="image-section-on-grid">
                <span class="image-block">
                  <img src="${imageUrl}" alt="icon" class="man-icon-img"/>
                </span>
              </div>
              <div class="split-column-data-on-grid">
                <span title="${username}" class="common-cell-data-bold ellipsis-email-pass-expected">${username}</span>
                <span class="move-content-on-top">${gateNumber}</span>
              </div>
              <div class="passes-status-block">
                ${
                  status
                    ? `<div class="request-status-tag ${statusClass}">${status.toUpperCase()}</div>`
                    : `<div class="arrival-time">Arrival Time:&nbsp;${formattedArrivalTime}</div>`
                }
              </div>
            </div>`;
              },        
      }, 
    ];
  }

 

  
  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'CHECKED IN':
        return 'checked-in account-user-common-status-cell checked-status';
      case 'CHECKED OUT' :
        return 'checked-out account-user-common-status-cell checked-status'
      default:
        return '';
    }
  }

  /**
   * HostListener() - listen for window resize events
   * @param event - The 'event' parameter is the native resize event object
   */
 

  /**
   * onGridReady() - Event handler for when the grid is ready
   * @param params - it is the object provided by AG Grid when the grid is ready
   */
  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  modalReference: NgbModalRef;
  viewPasses(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(ViewPassesComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
    if (this.modalReference && this.modalReference.componentInstance) {
      this.modalReference.componentInstance.passesData = this.data;
    }
  }

}