import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import * as bootstrap from 'bootstrap';
import { GO_TO_DROPDOWN, GO_TO_DROPDOWN_DEFAULT } from '../../constants/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-go-to-dropdown',
  templateUrl: './go-to-dropdown.component.html',
  styleUrls: ['./go-to-dropdown.component.scss']
})
export class GoToDropdownComponent {
  companyid: any;
  companyname: any;
  activeProjects: any;
  userInfo: any;
  currentRoute: string;
  redirectPath: string;
  redirectPathCompany: string;
  redirectPathCompanyUser: string;
  redirectPathProjects: string;
  redirectPathProjectDetails: string;
  constructor(private router: Router) {} 
  go_to_dropdown = GO_TO_DROPDOWN;
  go_to_dropdown_default = GO_TO_DROPDOWN_DEFAULT;

  agInit(params: ICellRendererParams): void {
    this.companyid = params?.data?.compid;
    this.companyname = params?.data?.companyName;
    this.activeProjects = params?.data?.activeProjects;
    this.currentRoute = this.router.url;
    if(this.currentRoute.includes('/feature/ar-dashboard/company/companies')){

      this.redirectPathCompany = '/feature/ar-dashboard/company';
      this.redirectPathCompanyUser = '/feature/ar-dashboard/company/company-user';
      this.redirectPathProjects = '/feature/studio-dashboard/project/project-list';
      this.redirectPathProjectDetails = '/feature/studio-dashboard/project/project-details';

    }else if(this.currentRoute.includes('/feature/studio-dashboard/company/companies')){
      this.redirectPathCompany = '/feature/studio-dashboard/company';
      this.redirectPathCompanyUser = '/feature/studio-dashboard/company/company-user';
      this.redirectPathProjects = '/feature/studio-dashboard/project/project-list';
      this.redirectPathProjectDetails = '/feature/studio-dashboard/project/project-details';

    }

  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
  /*
  onChange()- Method to redirect to diff pages on change of the dropdown
  */
  onChange(event: any) {
    const selectedId = event.target.value;

    const selectedGoToOption = this.go_to_dropdown.find(option => option.description == selectedId);
    const selectedActiveProject = this.activeProjects.find(project => project.projectName == selectedId);

    if (selectedGoToOption && selectedGoToOption.description) {
      console.log('Selected from go_to_dropdown:', selectedGoToOption);
      switch (selectedGoToOption.description) {
        case 'Company':
          this.router.navigate([this.redirectPathCompany],{
            queryParams: {
              companyid: this.companyid,
            }
          }); 
          break;
        case 'Projects':
          this.router.navigate([ this.redirectPathProjects],{
            queryParams: {
              companyid: this.companyid,
              companyName: this.companyname
            }
          });
          break;
        case 'Users':
          this.router.navigate([ this.redirectPathCompanyUser],{
              queryParams: {
                companyid: this.companyid,
                companyName: this.companyname,
                page:'gotopage'
              }
            }
          );
          break;
        }
    } 
    else if (selectedActiveProject && selectedActiveProject.projectName) {
          this.userInfo = JSON.parse(localStorage.getItem('user-profile'));
          const userProfileId = this.userInfo.userProfileId;
          const projectId = selectedActiveProject.projectId;
          this.router.navigate([this.redirectPathProjectDetails, userProfileId, projectId], {
            queryParams: {
              viewmycompaniespage: true
            },
          });
    }
    else {
      console.log('No match found for selected value');
    }

    
  }

}
