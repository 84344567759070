

 
import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
@Component({
  selector: 'app-hot-lock-request',
  templateUrl: './hot-lock-request.component.html',
  styleUrls: ['./hot-lock-request.component.scss']
})
export class HotLockRequestComponent {
  savedResponse: any;
  projectName: string = '';
  userName: string = '';
  companyName: string = '';
  projectId: string = '';

  constructor(private renderer: Renderer2, private http: HttpClient, private route: ActivatedRoute, private loaderService: LoaderService,) { }
 
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectName = params['projectName'];
      this.userName = params['userName'];
      this.companyName = params['companyName'];
      this.projectId = params['projectId'];
    });

    this.loadCognitoForm();
    this.setFormValues(); 
    
  }
 
 
  loadCognitoForm() {
    const script = this.renderer.createElement('script');
    script.src = 'https://www.cognitoforms.com/f/seamless.js';
    script.setAttribute('data-key', 'N3FI4rb02UaP6_35zGfexQ');
    script.setAttribute('data-form', '8');
    script.async = true;
   
 
    const container = document.getElementById('cognito-form-container');
    if (container) {
      this.renderer.appendChild(container, script);
  
      console.log('Cognito form script added to the container.');
    } else {
      console.error('Cognito form container not found');
    }
  }
  setFormValues() {
 
    const trySetValue = () => {
      const projectNameField = document.getElementById('cog-1') as HTMLInputElement | null;
      const companyField = document.getElementById('cog-0') as HTMLInputElement | null;
      const userField = document.getElementById('cog-2') as HTMLInputElement | null;
 
      let allFieldsSet = true;
 
      if (projectNameField) {
        projectNameField.value = this.projectName; // Use this.projectName
        const inputEvent = new Event('input', { bubbles: true, cancelable: true });
        const changeEvent = new Event('change', { bubbles: true, cancelable: true });
        projectNameField.dispatchEvent(inputEvent);
        projectNameField.dispatchEvent(changeEvent);
        console.log('Field "projectNameField" set to:', this.projectName);
      } else {
        console.warn('Field "projectNameFields" not found, retrying...');
        allFieldsSet = false;
      }
 
      if (companyField) {
        companyField.value = this.companyName; // Use this.companyName
        const inputEvent = new Event('input', { bubbles: true, cancelable: true });
        const changeEvent = new Event('change', { bubbles: true, cancelable: true });
        companyField.dispatchEvent(inputEvent);
        companyField.dispatchEvent(changeEvent);
        console.log('Field "Company" set to:', this.companyName);
      } else {
        console.warn('Field "Company" not found, retrying...');
        allFieldsSet = false;
      }
 
      if (userField) {
        userField.value = this.userName; // Use this.userName
        const inputEvent = new Event('input', { bubbles: true, cancelable: true });
        const changeEvent = new Event('change', { bubbles: true, cancelable: true });
        userField.dispatchEvent(inputEvent);
        userField.dispatchEvent(changeEvent);
        console.log('Field "User" set to:', this.userName);
      } else {
        console.warn('Field "User" not found, retrying...');
        allFieldsSet = false;
      }
 
      if (!allFieldsSet) {
        setTimeout(trySetValue, 500);
      }
    };
 
    window.addEventListener('load', () => {
      trySetValue();
    
      
    });

  }

 
addProjectDetails(){
window.location.reload();
}
}