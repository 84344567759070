import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: string | number, locale: string = 'en-US'): string {
    if (value === null || value === undefined || value === '') return '';

    // Convert string to number and remove non-numeric characters
    const numericValue = typeof value === 'string' 
      ? parseFloat(value.replace(/[^0-9.-]/g, '')) // Allow negative sign as well
      : value;

    if (isNaN(numericValue)) return '';

    // Return the formatted number without enforcing decimals
    return numericValue.toLocaleString(locale);
  }
}
