import { Component, ElementRef, EventEmitter, Output, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CREDIT_APP_REPORT_SECTION } from 'src/app/feature/ar-portal/constants/ar-portal.constant';
import { CERTIFICATE_OF_INSURANCE, CRED_STEP_ONE_CONSTANT, CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { ADD_PROJECT_USER } from 'src/app/shared/constants/common';
import { FormValidationService } from 'src/app/shared/services/form-validations/form-validation.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ViewCreditAppServiceService } from 'src/app/shared/services/view-credit-app-service/view-credit-app-service.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-certificate-of-insurance',
  templateUrl: './certificate-of-insurance.component.html',
  styleUrls: ['./certificate-of-insurance.component.scss']
})
export class CertificateOfInsuranceComponent {
  @Input() customerStatus;
  @Input() revisionData: any;
  @Input() states: any;
  @Input() creditAppStatus: string;
  @Input() startApplicationData: any;
  @Input() caSeqId: number;
  @Input() caNumber: number;
  pdfUrl: string;
  s3BucketUrl: string = environment.s3BucketUrl;
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() prevStepEvent = new EventEmitter<void>();
  @Output() stepCompleted = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<any[]>();
  @Output() stepOneFormChange = new EventEmitter()
  @ViewChild('fileInput') fileInput!: ElementRef;  // Reference to the file input element
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('uploadSuccess', { static: false }) uploadSuccess!: TemplateRef<any>;
  @ViewChild('removeSuccess', { static: false }) removeSuccess!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  isFileOver = false;
  files: File[] = [];
  insuranceForm: FormGroup;
  addProjectUser = ADD_PROJECT_USER;
  //states;
  constants = CERTIFICATE_OF_INSURANCE;
  toastMessages = CREDIT_APP_TOAST_MESSAGES;
  insuranceError;
  coiData;
  uploadedFiles;
  coiUploadResponse;
  isFileUploaded: boolean = false;
  coiMessage: any;
  initialFormValues: any;
  disableRemove: boolean = false;
  cerificateOfInsurancePDFDocument: any;
  errorMsg: any;
  localFormData;
  isFormError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private creditApplicationService: CreditApplicationsService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private sharedViewCreditAppService: ViewCreditAppServiceService
  ) { }

  ngOnInit(): void {
    this.pdfUrl = `${this.s3BucketUrl}/creditapp/sampledocuments/coi/sample_coi_v1.pdf`;
    this.initializeForm();
    this.checkCreditAppStatus();
    if (this.startApplicationData) {
      this.coiData = this.startApplicationData?.creditApplicationList?.certificateOfInsurance;
    }
    else if (this.revisionData) {
      this.revisionData = this.revisionData;
      this.coiData = this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails;
    }
    if (this.customerStatus === this.constants.needsRevision) {
      this.getRevisionRequiredMessage();
    }
    if(this.startApplicationData && this.customerStatus === 'Finish Application' || this.customerStatus === 'Start Application'){
      this.initialFormValues =this.insuranceForm?.getRawValue();
      this.insuranceForm.valueChanges.subscribe(() => {
          this.checkFormChanges();
        });
    }
    if(this.customerStatus === 'NEEDS REVISIONS'){
      if(this.revisionData?.caRevisionDetails?.applicationFormDetails){
      this.initialFormValues = this.insuranceForm?.getRawValue();
      this.insuranceForm.valueChanges.subscribe(() => {
        this.checkFormChanges();
      });
      }
    }
   if(this.startApplicationData && this.customerStatus === 'Finish Application' || this.customerStatus === 'Start Application'){
      this.initialFormValues =this.insuranceForm?.getRawValue();
      this.insuranceForm.valueChanges.subscribe(() => {
          this.checkFormChanges();
        });
    }
    if(this.customerStatus === 'NEEDS REVISIONS'){
      if(this.revisionData?.caRevisionDetails?.applicationFormDetails){
      this.initialFormValues = this.insuranceForm?.getRawValue();
      this.insuranceForm.valueChanges.subscribe(() => {
        this.checkFormChanges();
      });
      }
    }
   
  }

  /**
   * checkFormChanges() - to check the form chnages and according to that we need to show dialgue box
   */
   checkFormChanges(): void {
    const currentFormValues = this.insuranceForm?.getRawValue();
    const isChanged = !_.isEqual(this.initialFormValues, currentFormValues);
    this.stepOneFormChange.emit(isChanged);
  }

   /**
   * isInitialState() - returns a boolean value by checking initial values and recent value
   * @returns - boolean value checking initial state and formvalue 
   */
  isInitialState(): boolean {
    return JSON.stringify(this.insuranceForm?.value) === JSON.stringify(this.initialFormValues);
  }

  ngOnChanges() {
    this.pdfUrl = `${this.s3BucketUrl}/creditapp/sampledocuments/coi/sample_coi_v1.pdf`;
    this.initializeForm();
    this.checkCreditAppStatus();
    if (this.startApplicationData) {
      this.coiData = this.startApplicationData?.creditApplicationList?.certificateOfInsurance;
    }
    else if (this.revisionData) {
      this.coiData = this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails;
    }
    if (this.customerStatus === this.constants.needsRevision) {
      this.getRevisionRequiredMessage();
    }
  }

  /**
   * method to get the messages for revisions required sections
   */
  getRevisionRequiredMessage() {
    const data = this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails;
    this.coiMessage = data?.reviewDetails?.reviewMailBody;
  }

  /**
  * patchRevisionsData()  - to patch the revision data
  */
  patchRevisionsData(): void {
    this.localFormData = JSON.parse(localStorage?.getItem('certificateData'));
    if (this.localFormData) {
      this.coiData = this.localFormData;
    }
    this.insuranceForm?.patchValue({
      producer: this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.coiProducerName,
      insured: this.coiData?.coiInsuredName,
      street: this.coiData?.coiStreet,
      city: this.coiData?.city,
      state: this.coiData?.stateId,
      zipcode: this.coiData?.zipCode,
      details: this.coiData?.reviewDetails,
    });
    this.uploadedFiles = [];
    if (this.coiData?.coiDocumentUrl) {
      const fileName = this.coiData?.coiDocumentUrl.split('/').pop(); // Extract the file name
      const fileExists = this.files.map(file => file.name).indexOf(fileName) === -1;
      if (fileExists) {
        const file: File = new File([], fileName); // Create a new File object
        this.files.push(file); // Push the new file
        this.uploadedFiles = this.files; // Store the updated files
      }
    }
  }

  /**
   * initializeForm() - method to initialize the insurance form
   */
  initializeForm(): void {
    this.insuranceForm = this.fb.group({
      producer: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphanumericAndSpecialCharacters]],
      insured: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphanumericAndSpecialCharacters]],
      street: ['', [Validators.required, this.formValidationService.streetValidator]],
      city: ['', [Validators.required, this.formValidationService.cityValidator]],
      state: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.maxLength(12), this.formValidationService.zipCodeValidator]],
      details: ['']
    });
  }

  /**
   * patchCoiData() - method to patch the form values
   */
  patchCoiData(): void {
    this.localFormData = JSON.parse(localStorage?.getItem('certificateData'));
    if (this.localFormData) {
      this.coiData = this.localFormData;
    }
    this.insuranceForm.patchValue({
      producer: this.coiData?.coiProducerName,
      insured: this.coiData?.coiInsuredName,
      street: this.coiData?.coiStreet,
      city: this.coiData?.city,
      state: this.coiData?.stateId,
      zipcode: this.coiData?.zipCode
    });

    if (this.coiData?.coiDocumentUrl) {
      const fileName = this.coiData?.coiDocumentUrl.split('/').pop(); // Extract the file name
      const fileExists = this.files.map(file => file.name).indexOf(fileName) === -1;
      if (fileExists) {
        const file: File = new File([], fileName); // Create a new File object
        this.files.push(file); // Push the new file
        this.uploadedFiles = this.files; // Store the updated files
      }
    }

  }

  /**
   * updateFormControls() - method to enable/disable the form based the review required status
   */
  updateFormControls(): void {
    if (this.coiData) {
      const status = this.coiData?.reviewRequired;
      const enableControls = status === true;

      this.insuranceForm?.[enableControls ? 'enable' : 'disable']();
    }
    else {
      this.disableFormControls();
    }
  }

  /**
   * openPdf() - to open the pdf  file on click
   */
  openPdf(): void {
    this.loaderService.setLoadingState(true);
    let docUrl;
    if(this.revisionData){
      docUrl = this.coiData?.coiDocumentUrl;
    }else{
      docUrl = this.startApplicationData?.creditApplicationList?.certificateOfInsurance?.coiDocumentUrl;
    }
    const file = this.coiUploadResponse ? this.coiUploadResponse?.responseDTO?.coiDocumentUrl :docUrl;
    this.sharedViewCreditAppService.getCreditAppReport(file, 'certificateOfInsurance').pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          this.loaderService.setLoadingState(false);
          console.error("error uploading the log file", error);
          this.errorMsg = error.errorMessage;
          this.showDanger(this.dangerTpl);
        }
      })
  }

/**
   * openSamplePdf() - to open the sample pdf  file on click
   */
  openSamplePdf(): void {
    this.loaderService.setLoadingState(true);
    const file = this.pdfUrl;
    this.sharedViewCreditAppService.getCreditAppReport(file, 'certificateOfInsurance').pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          this.loaderService.setLoadingState(false);
          console.error("error uploading the log file", error);
          this.errorMsg = error.errorMessage;
          this.showDanger(this.dangerTpl);
        }
      })
  }
  
  /**
   * submitCertificateOfInsurance() - Method to submit the form data to the API
   */
  submitCertificateOfInsurance(): void {
    if (this.creditAppStatus !== "Needs Revisions") {
      this.addCertificateOfInsurance();
    } else {
      this.updateCertificateOfInsurance();
    }
  }

  /**
   * addCertificateOfInsurance() - Method to add the certificate of insurance data
   */
  addCertificateOfInsurance(): void {
    this.formSubmittedEvent.emit();
    this.loaderService.setLoadingState(true);
    let payloadData = this.payloadData();

    this.creditApplicationService.addCertificateOfInsurance(payloadData).subscribe({
      next: (response: any) => {
        this.isFormError = false;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
        this.stepCompleted.emit(2);
      },
      error: (error) => {
        console.error('Error submitting Certificate of Insurance:', error);
        this.insuranceError = error;
        localStorage.setItem('certificateData', JSON.stringify(payloadData));
        this.isFormError = true;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * updateCertificateOfInsurance() - Method to submit the certificate of insurance data for revisions
   */
  updateCertificateOfInsurance(): void {
    this.formSubmittedEvent.emit();
    this.loaderService.setLoadingState(true);
    let payloadData = this.revisionPayload();
    this.creditApplicationService.updateCertificateOfInsurance(payloadData).subscribe({
      next: (response: any) => {
        this.isFormError = false;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
        this.stepCompleted.emit(2);
      },
      error: (error) => {
        this.isFormError = true;
        localStorage.setItem('certificateData', JSON.stringify(payloadData));
        this.loaderService.setLoadingState(false);
        console.error('Error submitting Certificate of Insurance:', error);
        this.insuranceError = error;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
 * onFilesChanged() - method to get the uploaded file
 * @param files - the file to be uploaded
 */
  onFilesChanged(files: File[]): void {
    this.files = files;
    this.uploadFile(this.files);
    this.isFileUploaded = files.length > 0;
  }

  /**
   * onFileRemoved() - method toremove the file
   * @param file - file to be removed
   */
  onFileRemoved(removedFiles: File[]): void {
    if (removedFiles) {
      this.removeUploadedFile();
      this.isFileUploaded = removedFiles.length > 0;
    }
  }

  /**
   * uploadFile() - method to upload the coi file in pdf format
   */
  uploadFile(files: File[]) {
    this.loaderService.setLoadingState(true);
    const coiId = this.coiData?.coiId;

    this.uploadedFiles = files;
    const formData = new FormData();
    _.forEach(this.uploadedFiles, (file) => {
      formData.append('file', file);
    });

    const data = {
      caSeqId: this.caSeqId,
      caNumber: this.caNumber,
      coiID: coiId,
      sectionName: "coi"
    };
    formData.append('data', JSON.stringify(data));

    this.creditApplicationService.certificateOfInsuranceFileUpload(formData).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.coiUploadResponse = response;
        this.showSuccess(this.uploadSuccess);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error('Error uploading the file:', error);
        this.insuranceError = error;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * removeUploadedFile() - method to remove the uploaded file
   */
  removeUploadedFile(): void {
    const coiDetails = this.coiUploadResponse?.responseDTO;
    const formData = new FormData();
    let caSeqId;
    if(this.revisionData){
      caSeqId = this.revisionData?.caRevisionDetails?.caSeqId;
    }else{
      caSeqId = this.startApplicationData?.creditApplicationList?.caSeqId;
    }
    const data = {
      caSeqId: coiDetails?.caSeqId ? coiDetails?.caSeqId : caSeqId,
      caNumber: coiDetails ? coiDetails?.caNumber : this.startApplicationData?.creditApplicationList?.caNumber,
      coiID: coiDetails ? coiDetails?.coiId : this.coiData?.coiId,
      sectionName: "coi",
      isRemove: true,
      filePath: coiDetails ? coiDetails?.coiDocumentUrl : this.coiData?.coiDocumentUrl
    }
    formData.append('data', JSON.stringify(data));
    
    this.creditApplicationService.removeCertificateOfInsuranceFile(formData).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.removeSuccess);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        this.insuranceError = error;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * payloadData() - method to set the payload data to be passed to the api
   * @returns - the payload data
   */
  payloadData(): any {
    const formValue = this.insuranceForm.getRawValue();
    let caSeqId = this.startApplicationData?.creditApplicationList?.caSeqId;
    let caNumber = this.startApplicationData?.creditApplicationList?.caNumber;
    let payload = {
      caSeqId: caSeqId,
      caNumber: caNumber,
      coiId: this.startApplicationData?.creditApplicationList?.certificateOfInsurance?.coiId ? this.startApplicationData?.creditApplicationList?.certificateOfInsurance?.coiId : null,
      coiProducerName: formValue.producer,
      coiInsuredName: formValue.insured,
      coiStreet: formValue.street,
      city: formValue.city,
      stateId: formValue.state,
      zipCode: formValue.zipcode
    };

    return payload;
  }

  /**
   * revisionPayload() - method to set the payload data to be passed to the api
   * @returns - the payload data
   */
  revisionPayload(): any {
    const formValue = this.insuranceForm.getRawValue();
    let payload = {
      caSeqId: this.revisionData?.caRevisionDetails?.caSeqId,
      caNumber: this.revisionData?.caRevisionDetails?.caNumber,
      coiId: this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.coiId ? this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.coiId : null,
      coiProducerName: formValue.producer,
      coiInsuredName: formValue.insured,
      coiStreet: formValue.street,
      city: formValue.city,
      stateId: formValue.state,
      zipCode: formValue.zipcode
    };

    return payload;
  }

  /**
   * nextPage() - Method to handle the action of proceeding to the next page.
   */
  nextPage(): void {
    if (this.customerStatus !== this.constants.startApplication && this.customerStatus !== this.constants.finishApplication && this.customerStatus !== this.constants.needsRevision) {
      this.nextStepEvent.emit();
    } else {
      this.nextStepEvent.emit();
      this.submitCertificateOfInsurance();
    }

    const currentFormValues = this.insuranceForm?.getRawValue();
    const isChanged = !_.isEqual(this.initialFormValues, currentFormValues);
    this.stepOneFormChange.emit(!isChanged);
  }

  /**
   * previousPage() - method to handle the event of going back to previous page
   */
  previousPage(): void {
    this.prevStepEvent.emit();
  }

  /**
   * saveAndClose() - Method to handle the action of saving and closing the form.
   * It calls the submitCertificateOfInsurance method to handle form submission.
   */
  saveAndClose(): void {
    this.submitCertificateOfInsurance();
    setTimeout(() => {
      if (!this.isFormError) {
        this.saveAndCloseEvent.emit();
      }
    }
      , 1000);
  }

  /**
   * checkCreditAppStatus() - method to disable the form based on credit application status
   */
  checkCreditAppStatus(): void {
    if (this.customerStatus === this.constants.startApplication) {
      this.disableRemove = false;
      this.initializeForm();
      this.patchCoiData();
    }
    else if (this.customerStatus === this.constants.finishApplication) {
      this.disableRemove = false;
      this.patchCoiData();
    }
    else if (this.customerStatus === this.constants.needsRevision) {
      this.disableRemove = false;
      this.updateFormControls();
      this.patchRevisionsData();
    }
    else {
      this.disableRemove = true;
      this.disableFormControls();
      this.patchCoiData();
    }
  }

  /**
   * disableFormControls() - method to disable the form controls
   */
  disableFormControls(): void {
    Object.keys(this.insuranceForm.controls).forEach((key) => {
      this.insuranceForm.get(key)?.disable(); // Disable top-level controls
    });
  }

  /**
 * viewCertificateOfInsuranceReport() - method to call  the getCertificateOfInsuranceReport method
 */
  viewCertificateOfInsuranceReport(): void {
    const section = CREDIT_APP_REPORT_SECTION.coiSection;
    this.getCertificateOfInsuranceReport(this.coiData?.coiDocumentUrl, section);
  }

  /**
 * getCertificateOfInsuranceReport() - method to get the Certificate of Insurance through API call
 */
  getCertificateOfInsuranceReport(url: any, section: any): void {
    this.loaderService.setLoadingState(true);
    this.sharedViewCreditAppService.getCreditAppReport(url, section)
      .pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          console.error(error);
          this.insuranceError = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        }
      })
  }

  /**
   * showSuccess() - Method to display a success toast notification.
   * @param successTpl - The template reference for the success message
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
