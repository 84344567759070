import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { BOOKMARKED_PROJECTS } from 'mockData/development/studio-portal-dashboard';
import { STUDIO_DASHBOARD_BOOKMARK_PROJECTS, USER } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { StudioDashboardService } from '../../../services/studio-dashboard/studio-dashboard.service';
import { ToastService } from 'src/app/shared/components/toast.service';

@Component({
  selector: 'app-studio-dashboard-bookmarked-projects',
  templateUrl: './studio-dashboard-bookmarked-projects.component.html',
  styleUrls: ['./studio-dashboard-bookmarked-projects.component.scss']
})
export class StudioDashboardBookmarkedProjectsComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Output() projectData = new EventEmitter;
  bookmarkConstant = STUDIO_DASHBOARD_BOOKMARK_PROJECTS;
  bookmarkedProjects = BOOKMARKED_PROJECTS;
  user = USER;
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    },
  };
  bookmarkData: any;
  displayErrorMsg: any;
  userProfileId: any;
  requestResponse: any;

  constructor(private router: Router,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private studioDashboardService: StudioDashboardService){}

  ngOnInit() {
    const userInfo = JSON.parse(localStorage.getItem('user-profile'));
    if(userInfo) {
      this.userProfileId = userInfo.userProfileId;
    }
    this.getBookmarkedData();
  }

  /**
   * getBookmarkedData() - Method to fetch the bookmarked data
  */
  getBookmarkedData(): void{
   this.loaderService.setLoadingState(true);
   this.studioDashboardService.getDashboardBookmarked().subscribe((response: any) => {
     this.loaderService.setLoadingState(false);
     this.bookmarkData = response?.data;
    },
    (error) => {
      this.loaderService.setLoadingState(false);
      this.displayErrorMsg = error;
      this.showDanger(this.displayErrorMsg);
    });
  }

  /**
   * onSelectChnage() -  Navigate based on the selected value
   * @param event - The event object containing details about the change event. 
   */
  onSelectChange(event: any, profileId: number, projectId: number, companyId: number, companyName: string, projectName: string): void {
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case this.bookmarkConstant.one:
        this.router.navigate(['/feature/studio-dashboard/project/project-details/'+ `${profileId}`+ '/' + `${projectId}`], {
          queryParams: {
            viewmydashboardpage: true
          },
        });
        break;
      case this.bookmarkConstant.two:
        this.router.navigate(['/feature/studio-dashboard/project/view-users'], {
          queryParams: {
            projectId: projectId,
            companyId: companyId,
            projectName: projectName,
            companyName: companyName,
            viewmydashboardpage: true
          },
        });
        break;
      case this.bookmarkConstant.three:
        this.router.navigate(['/feature/studio-dashboard/company'], {
          queryParams: {
            companyid: companyId,
            viewmydashboardpage: true
          },
        });
        break;
      default:
        break;
    }
  }

   /**
   * setBookmarkedValue() - Set the Bookmarked data
   */
  setBookmarkedValue(BookmarkedProjects): void{
    this.projectData.emit(BookmarkedProjects)
  }

  /**
   * responseSuccessMessage() - get succes message response
  */
  responseSuccessMessage(event: any) {
    this.requestResponse = this.user.addUserSuccessMsg;
    this.showSuccess(this.successTpl);
  }

  /**
   * responseErrMessage() - get error message response
  */
  responseErrMessage(event: any) {
    this.requestResponse = event;
    this.showDanger(this.dangerTpl);
  }
  
  /**
   * onClickLoadBookmarkedData() - after successfully removed the bookmark, called this method to
   * refresh the bookmarked data
  */
    onClickLoadBookmarkedData(){
      this.getBookmarkedData();
    }
  
 /**
 * showSuccess() - Method to display a success toast notification.
 * @param successTpl - The template reference for the success message
 */
 showSuccess(successTpl: TemplateRef<any>): void {
  this.toastService.show(successTpl, {
    classname: 'custom-toast',
    delay: 3000,
  });
 }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

}
