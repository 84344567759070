import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RISK_CONSTANTS } from '../../../constants/studio-portal.constant';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FinalCredAppFormComponent } from '../final-cred-app-form/final-cred-app-form.component';
import { CreditStatusDetailsComponent } from '../credit-status-details/credit-status-details.component';
import { ApprovedWithRiskComponent } from '../approved-with-risk/approved-with-risk.component';

@Component({
  selector: 'app-cred-app',
  templateUrl: './cred-app.component.html',
  styleUrls: ['./cred-app.component.scss']
})
export class CredAppComponent {
  @Input() rowData: any;
  modalReference: NgbModalRef;
  constants = RISK_CONSTANTS;
  selectedTab: string = this.constants.tabs.status;
  @Output() modalClosed = new EventEmitter<void>();
  service: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) { }
  /**
   *ngOninit()- This method is used to trigger the opening of two modals when the component is initialized.
   */
  ngOninit(): void {
    this.openFinalCredAppModal();
    this.openComponentModal(); // Open the appropriate component modal
  }
  /**
   * showApprovesWithRisk() - Getter that checks whether the current row's status is 'APPROVED (RISK)', 'APPROVED', or 'DENIED'.
   */
  get showApprovedWithRisk(): boolean {
    return ['APPROVED (RISK)', 'APPROVED', 'DENIED'].includes(this.rowData?.status);
  }
  /**
   * showCreditStatusDetails() - Getter that checks if the current row's status is 'PENDING RISK' or 'NEEDS APPROVAL'.
   */
  get showCreditStatusDetails(): boolean {
    return ['PENDING RISK', 'NEEDS APPROVAL'].includes(this.rowData?.status);
  }

  /**
 * openComponentModal() - Opens the appropriate component modal based on the status.
 */
  openComponentModal(): void {
    if (this.showApprovedWithRisk) {
      this.openApprovedWithRiskModal();
    } else if (this.showCreditStatusDetails) {
      this.openCreditStatusDetailsModal();
    }
  }

  /**
   * navigateToProject() - Navigates to the project details page in a new browser tab.
   */
  navigateToProject(): void {
    const profileId = this.rowData?.profileId;
    const projectId = this.rowData?.projectId;
    if (projectId !== null) {
      const url = `/feature/studio-dashboard/project/project-details/${profileId}/${projectId}`;
      window.open(url, '_blank');
    }
  }

  /**
   * navigateToCompany() - Navigates to the company details page in a new browser tab.
   */
  navigateToCompany(): void {
    const companyId = this.rowData?.companyId;
    if (companyId !== null && companyId !== undefined) {
      const url = `/feature/studio-dashboard/company?companyid=${companyId}`;
      window.open(url, '_blank');
    }
  }
  /**
 * openApprovedWithRiskModal() - Opens the ApprovedWithRiskComponent as a modal.
 */
  openApprovedWithRiskModal(): void {
    this.modalReference = this.modalService.open(ApprovedWithRiskComponent, {
      windowClass: 'common-modal',
      size: 'lg',
      fullscreen: false,
      backdrop: 'static'
    });

    if (this.modalReference.componentInstance) {
      this.modalReference.componentInstance.rowData = this.rowData;
    }
  }

  /**
   * openCreditStatusDetailsModal() - Opens the CreditStatusDetailsComponent as a modal.
   */
  openCreditStatusDetailsModal(): void {
    this.modalReference = this.modalService.open(CreditStatusDetailsComponent, {
      windowClass: 'common-modal',
      size: 'lg',
      fullscreen: false,
      backdrop: 'static'
    });

    if (this.modalReference.componentInstance) {
      this.modalReference.componentInstance.rowData = this.rowData;
    }
  }
  /**
   * switchTab(tab: string) - Switches the active tab.
   * @param tab - The identifier for the tab to switch to.
   */
  switchTab(tab: string): void {
    this.selectedTab = tab;
  }

  /**
   * openFinalCredAppModal() - Opens the modal for the final credit application form.
   */
  openFinalCredAppModal(): void {
    this.modalReference = this.modalService.open(FinalCredAppFormComponent, {
      windowClass: 'common-modal',
      size: 'lg',
      fullscreen: false,
      backdrop: 'static',
    });
  }

  /**
   * closeModal() - Closes the currently active modal.
   */
  closeModal(): void {
    this.modalClosed.emit();
    this.activeModal.close();

  }
}