import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ADD_DEPARTMENT_ARRAY } from '../../../constants/studio-portal.constant';
import _ from 'lodash';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit {
  deptData = ADD_DEPARTMENT_ARRAY;
  departmentForm: FormGroup;
  @Input() selectedDepartments: any[] = [];
  @Input() public departments: any = {};
  @Output() departmentsSelected = new EventEmitter<string[]>();

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {
    this.departmentForm = this.fb.group({});
  }

  /**
     *Interates through all data of deptData 
  */
  ngOnInit(): void {
    this.deptData.forEach(dept => {
      this.departmentForm.addControl(dept.departmentId.toString(), this.fb.control(false));
    })
    this.setupEditDepartments();
  }

  /** 
    *setupEditDepartments()=Method to set up the form with selected departments
  */
  setupEditDepartments(): void {
    this.selectedDepartments.forEach(dept => {
      const controlName = dept.departmentId.toString();
      if (this.departmentForm.controls[controlName]) {
        this.departmentForm.get(controlName)?.setValue(true);
        this.departmentForm.get(controlName)?.disable();
      }
    });
  }

  /**
       * addDept()=Updates the value of a specific department control in the form based on checkbox state.
       * @param id - The identifier for the department control.
       * @param event - The checkbox event indicating whether the department is selected.
  */
  addDept(id: any, event: any): void {
    const controlName = id.toString();
    if (!this.departmentForm.get(controlName)?.disabled) {
      this.departmentForm.get(controlName)?.setValue(event.target.checked);
    }
  }

  /**
     * onSaveDepartments()=Collects selected department IDs and closes the modal with these IDs .
  */
  onSaveDepartments(): void {
    const selectedIds = _.map(_.pickBy(this.departmentForm.value, Boolean), (value, key) => parseInt(key));
    this.activeModal.close(selectedIds);
  }
  /**
   * onCancel() - Close the active modal.
   */
  onCancel(): void {
    this.activeModal.close();
  }
}