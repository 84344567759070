import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TOGGLE_INDEX } from '../../../enum/studio-portal.enum';
import _ from 'lodash';

@Component({
  selector: 'app-credit-application-dept',
  templateUrl: './credit-application-dept.component.html',
  styleUrls: ['./credit-application-dept.component.scss']
})
export class CreditApplicationDeptComponent implements ICellRendererAngularComp {

  displayedDept: string[] = [];
  hiddenDept: string[] = [];
  /**
   * agInit() - Initializes the component with the given parameters and processes the department data.
   * @param params  - The input parameters containing the data to be processed. It includes a `value` array of department names.
   */
  agInit(params: any): void {
    const Dept: string[] = _.map(params.value, department => this.capitalizeWords(department));
    Dept.sort();
    Dept.sort();;
    Dept.sort();
    const maxVisibleDept = TOGGLE_INDEX.three;

    if (Dept?.length > maxVisibleDept) {
      this.displayedDept = Dept.slice(TOGGLE_INDEX.zero, maxVisibleDept);
      this.hiddenDept = Dept.slice(maxVisibleDept);
    } else {
      this.displayedDept = Dept;
      this.hiddenDept = [];
    }
  }
  /**
   *refresh() - Refreshes the component or grid by re-initializing with the provided parameters.
   * @param params - The parameters used for re-initializing or refreshing the component
   * @returns - A boolean value, indicating the success of the refresh operation (always returns true).
   */
  refresh(params: any): boolean {
    this.agInit(params);
    return true;
  }
  /**
   *capitalizeWords() - Capitalizes the first letter of each word in a given string.
   * @param str - The input string to be capitalized.
   * @returns - A new string with the first letter of each word capitalized, and the rest of the letters in lowercase.
 */
  private capitalizeWords(str: string): string {
    return _.startCase(_.toLower(str));
  }

}



