import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CRED_FORM_FOUR_CONSTANT, CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { SuccessBannerService } from 'src/app/feature/customer-portal/services/success-banner/success-banner.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-cred-app-step-four',
  templateUrl: './cred-app-step-four.component.html',
  styleUrls: ['./cred-app-step-four.component.scss']
})
export class CredAppStepFourComponent {
  @Input() startApplicationData: any;
  @Input() customerStatus: any;
  @Input() caSeqId;
  @Input() caNumber;
  @Input() revisionData: any;
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @Output() prevStepEvent = new EventEmitter<void>();
  @Output() formSubmittedEvent = new EventEmitter<any[]>();
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('saveApplication', { static: false }) saveApplication!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  credFormFour = CRED_FORM_FOUR_CONSTANT;
  toastMessages = CREDIT_APP_TOAST_MESSAGES;
  submissionError; string;
  lineOfCreditData;
  letterOfGuaranteeData;
  caProdCompanyData;
  caProdCompanyData2;
  accountPayableContactData;
  companyProfileData;
  prinipalOfficerData;
  bankingReferenceData;
  tradeReferenceOneData;
  tradeReferenceTwoData;
  tradeReferenceThreeData;
  certificateOfInsuranceData;
  termsAndConditionsData;
  isBillingAddressSame: boolean = false;
  reviewStartApplicationData;
  reviewCustomerStatus;
  reviewCaSeqId;
  reviewCaNumber;
  reviewRevisionData;
  isSubmitClicked: boolean = false;
  isCaSubmitted: boolean = false;
  areButtonsEnabled: boolean;

  constructor(
    private loaderService: LoaderService,
    private creditApplicationService: CreditApplicationsService,
    private toastService: ToastService,
    private router: Router,
    private alertService: SuccessBannerService
  ) { }

  ngOnInit(): void {
    this.creditApplicationList();
    this.updateButtonState();
    this.reviewCustomerStatus = this.customerStatus;
    this.reviewRevisionData = this.revisionData;
    this.patchDetailsOnCustomerStatus();
  }

  ngOnChanges(): void {
    this.updateButtonState();
    if (this.startApplicationData) {
      this.companyProfileData = this.reviewStartApplicationData?.creditApplicationList?.caCompanyProfile;
      this.reviewStartApplicationData = this.startApplicationData;
      this.reviewCustomerStatus = this.customerStatus;
      this.reviewCaSeqId = this.caSeqId;
      this.reviewCaNumber = this.caNumber ? this.caNumber : this.startApplicationData?.creditApplicationList?.caNumber;
      this.reviewRevisionData = this.revisionData;
      this.patchDetailsOnCustomerStatus();
    }
  }

  /**
   * patchDetailsOnCustomerStatus() - to patch the details based on customer status for revisions and non-revision screen
   */
  patchDetailsOnCustomerStatus(): void {
    const data = this.reviewRevisionData?.caRevisionDetails;
    if (this.reviewCustomerStatus !== this.credFormFour.needsRevisions && this.reviewCustomerStatus !== this.credFormFour.revisionsSubmitted) {
      this.lineOfCreditData = this.reviewStartApplicationData?.creditApplicationList?.lineOfCredit;
      this.letterOfGuaranteeData = this.reviewStartApplicationData?.creditApplicationList?.letterOfGuarantee;
      this.caProdCompanyData = this.reviewStartApplicationData?.creditApplicationList?.caProdCompany[0];
      this.caProdCompanyData2 = this.reviewStartApplicationData?.creditApplicationList?.caProdCompany[1];
      this.accountPayableContactData = this.reviewStartApplicationData?.creditApplicationList?.accountPayableContact;
      this.companyProfileData = this.reviewStartApplicationData?.creditApplicationList?.caCompanyProfile;
      this.prinipalOfficerData = this.reviewStartApplicationData?.creditApplicationList?.partners;
      this.bankingReferenceData = this.reviewStartApplicationData?.creditApplicationList?.bankingReferences;
      this.tradeReferenceOneData = this.reviewStartApplicationData?.creditApplicationList?.tradeReferences1;
      this.tradeReferenceTwoData = this.reviewStartApplicationData?.creditApplicationList?.tradeReferences2;
      this.tradeReferenceThreeData = this.reviewStartApplicationData?.creditApplicationList?.tradeReferences3;
      this.certificateOfInsuranceData = this.reviewStartApplicationData?.creditApplicationList?.certificateOfInsurance;
      this.termsAndConditionsData = this.reviewStartApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus;

      if (this.caProdCompanyData?.billingAddressSameInd === 1) {
        this.isBillingAddressSame = true;
      }
      else {
        this.isBillingAddressSame = false;
      }
    }
    else if (this.reviewCustomerStatus === this.credFormFour.needsRevisions || this.reviewCustomerStatus === this.credFormFour.revisionsSubmitted) {
      this.lineOfCreditData = data?.applicationFormDetails?.lineOfCreditDetails;
      this.letterOfGuaranteeData = data?.applicationFormDetails?.letterOfGuaranteeDetails;
      this.caProdCompanyData = data?.applicationFormDetails?.productionCompanyAddressDetails[0];
      this.caProdCompanyData2 = this.reviewStartApplicationData?.creditApplicationList?.caProdCompany[1];
      this.accountPayableContactData = data?.applicationFormDetails?.accountsPayableContactsDetails;
      this.companyProfileData = data?.applicationFormDetails?.caCompanyProfileDetails;
      this.prinipalOfficerData = data?.applicationFormDetails?.partnersDetails;
      this.bankingReferenceData = data?.applicationFormDetails?.bankingReferencesDetails;
      this.tradeReferenceOneData = data?.applicationFormDetails?.tradeReference1Details;
      this.tradeReferenceTwoData = data?.applicationFormDetails?.tradeReference2Details;
      this.tradeReferenceThreeData = data?.applicationFormDetails?.tradeReference3Details;
      this.certificateOfInsuranceData = data?.certificateOfInsuranceDetails;
      this.termsAndConditionsData = data?.termsAndConditionsDetails?.formStatusDetails;

      if (this.caProdCompanyData?.billingAddressSameInd === 1) {
        this.isBillingAddressSame = true;
      }
      else {
        this.isBillingAddressSame = false;
      }
    }

  }

  /**
   * updateButtonState() - method to update the button state to enable and disable the buttons
   */
  updateButtonState(): void {
    if (this.startApplicationData) {
      const formStatuses = this.startApplicationData?.creditApplicationList;

      // Check if the first three forms are completed
      const firstThreeFormsCompleted =
        formStatuses?.applicationFormStatus?.caFormStatus?.status === 'Completed' &&
        formStatuses?.certificateOfInsurance?.caFormStatus?.status === 'Completed' &&
        formStatuses?.termAndCondition?.caFormStatus?.status === 'Completed';
      // Check if the fourth form is not yet completed
      const fourthFormNotCompleted =
        formStatuses?.reviewApplicationStatusDetails?.formSatusDetails?.status !== 'Completed';
      // Enable buttons only if the first three are completed and the fourth is not
      this.areButtonsEnabled = firstThreeFormsCompleted && fourthFormNotCompleted;
    }
    else if (this.reviewRevisionData) {
      const formStatuses = this.reviewRevisionData?.caRevisionDetails;

      // Check if the first three forms are completed
      const firstThreeFormsCompleted =
        formStatuses?.applicationFormDetails?.formStatusDetails?.status === 'Completed' &&
        formStatuses?.certificateOfInsuranceDetails?.formStatusDetails?.status === 'Completed' &&
        formStatuses?.termsAndConditionsDetails?.formStatusDetails?.status === 'Completed';
      // Check if the fourth form is not yet completed
      const fourthFormNotCompleted =
        formStatuses?.reviewApplicationStatusDetails?.formSatusDetails?.status !== 'Completed';
      // Enable buttons only if the first three are completed and the fourth is not
      this.areButtonsEnabled = firstThreeFormsCompleted && fourthFormNotCompleted;
    }
  }

  /**
   * saveAndClose() - method to save the credit application and move the page to previously opened page prior to credit application
   */
  saveAndClose(): void {
    this.isSubmitClicked = false;
    this.saveAndCloseEvent.emit();
    this.showSuccess(this.saveApplication);
  }

  /**
   * submitApplication() - method to submit the credit application
   */
  submitApplication() {
    this.isSubmitClicked = true;
    this.submitCreditApplication();
  }

  /**
   * submitCreditApplication() - method to submit the credit application
   */
  submitCreditApplication(): void {
    this.formSubmittedEvent.emit();
    this.loaderService.setLoadingState(true);
    let payloadData = this.payloadData();

    this.creditApplicationService.submitCreditApplication(payloadData).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.isCaSubmitted = true;
        if (this.isSubmitClicked) {
          this.saveAndCloseEvent.emit();
          this.alertService.showAlert('success');
          this.router.navigate(['feature/customer-dashboard/overview']);
        }
      },
      error: (error) => {
        console.error('Error submitting Certificate of Insurance:', error);
        this.submissionError = error;
        this.saveAndCloseEvent.emit();
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * payloadData() - method to return the payload data to be sent to the api
   * @returns - the payload data
   */
  payloadData(): any {
    let payload = {
      caSeqId: this.caSeqId,
      caNumber: this.caNumber,
      isCASubmitted: this.reviewRevisionData ? true : false
    }
    return payload;
  }

  /**
  * previousPage() - method to handle the event of going back to previous page
  */
  previousPage(): void {
    this.prevStepEvent.emit();
  }

  /**
   * showSuccess() - Method to display a success toast notification.
   * @param successTpl - The template reference for the success message
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * creditApplicationList() - method to trigger to get the credit application list
   */
  creditApplicationList(): void {
    this.formSubmittedEvent.emit();
  }
}
