import { Component, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HELP_CONTENT } from '../../../constants/studio-portal.constant';

@Component({
  selector: 'app-help-slide',
  templateUrl: './help-slide.component.html',
  styleUrls: ['./help-slide.component.scss']
})
export class HelpSlideComponent {
  title = 'studios-portal-app';
  noPaddingTop = false;
  @Input() helpSliderVisible: boolean = false;
  @Input() sidebarVisible = true;
  helpContent = HELP_CONTENT;
  constructor(private renderer: Renderer2, private elRef: ElementRef, public activeModal: NgbActiveModal, private router: Router) { }
  ngOnInit(): void { }

  sticky: boolean = false;
  stickyPoint: number = 100;

  /**
   * toggleSidebar - Toggles the visibility of the sidebar.
   */
  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }

  /**
   * handleScroll - Listens to the window scroll event and updates the sticky state.
   */
  @HostListener('window:scroll', ['$event'])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.stickyPoint;
    this.noPaddingTop = windowScroll >= this.stickyPoint;
  }
  /**
   *  getGuarantorList() - Retrieves a list of guarantors from the help content.
   * @returns {string[]} An array of guarantor names.
   */
  getGuarantorList(): string[] {
    return this.helpContent.list.content.split(',');
  }

  /**
   * closeHelpSlider - Closes the help slider.
   */
  closeHelpSlider(): void {
    this.helpSliderVisible = false;
  }
}
