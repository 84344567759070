    <div class="page-wrapper">
        <div class="container px-0">
            <div class="row">
                <!-- Title Section -->
                <div class="comman-title-block">
                    <div class="comman-title-section">
                        <h1>{{company.companytitle}}</h1>
                        <button class="btn btn-dark custom-button" (click)="addCompany()">+ {{company.companybtn}}</button>
                    </div>
                    <!-- Filter Section -->
                    <div class="comman-filter-block">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-9 col-12 px-0">
                                    <div class="comman-filter-title-section">
                                        <p>{{company_users.filter}}:</p>
                                    </div>
                                    <div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
                                        <!-- Multiselect Dropdowns -->
                                        <div class="search-wrapper">
                                        <app-ng-multiselect-dropdown [placeholder]="'Status'"
                                            [settings]="defaultSettings"
                                            [data]="status"
                                            (onSelect)="selectedStatus($event)"
                                            [(ngModel)]="defaultSelectedStatus"
                                            >
                                        </app-ng-multiselect-dropdown>

                                        <app-search-company  [companiesSubject]="companiesSubject"  [placeholder]="placeholder" (searchComplete)="onSearchCompleted($event)"></app-search-company>                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Selected filters section -->
                    <div class="selected-filters-section">
                        <div  *ngIf="isFiltersSelected" class="selected-filters">
                        <div *ngFor="let status of filteredStatus" class="filter-tag">
                            {{ status.text }}
                            <button type="button" (click)="removeSelectedItem('status', status)">×</button>
                        </div>
                        <!-- Clear All Filters Button -->
                            <a class="text-link" (click)="clearAllFilters()">{{ companyConstants.clearFilters }}</a>
                        </div>
                    </div>
                </div>
                <!-- ag-grid table -->
                <div class="common-grid-block">
                    <div class="mt-4">
                        <app-common-ag-grid  class="ag-theme-custom" 
                        [colDefs]="colDefs"
                        [rowData]="rowData"
                        [gridOptions]="gridOptions"
                        [colsForSmallScreen]="colsForSmallScreen"
                        [colsForMediumScreen]="colsForMediumScreen"
                        [colsForLargeScreen]="colsForLargeScreen"
                        [colsForExtraLargeScreen]="colsForExtraLargeScreen"
                        (cellClicked)="onCellClicked($event)"
                        >
                    </app-common-ag-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #successTpl>
        <div class="success">
            <span *ngIf="addCompanySuccess">{{addCompanySuccess}}</span>
        </div>
    </ng-template>
    <ng-template #successDisableCompanyTpl>
        <div class="success">
            <span>{{message.disableCompanySuccessMessage}}</span> 
        </div>
    </ng-template>
    <ng-template #successTplActive>
        <div class="success">{{message.enableCompanySuccessMessage}}</div>
    </ng-template>
    <ng-template #dangerTplActive>
        <div class="danger">{{message.enableCompanyErrorMessage}}</div>
    </ng-template>
    <ng-template #dangerDisableCompanyTplActive>
        <div class="danger">{{message.disableCompanyErrorMessage}}</div>
    </ng-template>
    <ng-template #dangerTpl>
        <div class="danger">{{message.errorCompanies}}</div>
    </ng-template>
    
    <app-toast aria-live="polite" aria-atomic="true"></app-toast><ng-template #successTpl>