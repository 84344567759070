import { Component, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RiskEmailComponent } from '../risk-email/risk-email.component';
import { AddDepartmentComponent } from '../add-department/add-department.component';
import { CREDIT_APP_DETAILS_CONSTANTS, STATUS_CLASSES, STUDIO_SUCCESS_APIMESSAGE } from '../../../constants/studio-portal.constant';
import { FinalCredAppFormComponent } from '../final-cred-app-form/final-cred-app-form.component';
import { FormsModule } from '@angular/forms'
import { ToastService } from 'src/app/shared/components/toast.service';
import { CreditStatusDetailsService } from '../../../services/credit-status-details/credit-status-details.service';
import { RiskEmailTier1Component } from '../risk-email-tier1/risk-email-tier1.component';


@Component({
  selector: 'app-credit-status-details',
  templateUrl: './credit-status-details.component.html',
  styleUrls: ['./credit-status-details.component.scss']
})
export class CreditStatusDetailsComponent {
  constructor(private toastService: ToastService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formsModule: FormsModule,
    private creditStatusService: CreditStatusDetailsService) { }
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('RiskEmailComponent', { static: false }) RiskEmailComponent!: TemplateRef<any>;
  modalReference: NgbModalRef;
  @Input() rowData: any;
  pdfUrl: string;
  constants = CREDIT_APP_DETAILS_CONSTANTS;
  msg = STUDIO_SUCCESS_APIMESSAGE;
  caNumber: number = 4;
  caId: number = 4;
  commentText: string = '';
  error_msg: string = '';
  success_msg: string = '';
  comments: any[] = [];
  modifiedByName: string = '';


  ngOnInit(): void {
    this.pdfUrl = this.getPdfUrl(this.rowData);
  }

  /**
* getPdfUrl(rowData) - Generates the PDF URL based on the application's status.
* @param rowData - Row data of the credit application.
* @returns The URL string for the PDF.
*/
  getPdfUrl(rowData: any): string {
    return `https://example.com/pdfs/${rowData.id}.pdf`;
  }

  /**
   * openRiskEmailModal() - Opens the Risk Email modal for user review.
   */
  openRiskEmailModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(RiskEmailComponent, {
      windowClass: 'common-modal',
      size: 'lg',
      fullscreen: false,
      backdrop: 'static'
    });
  }

  /**
     * openRiskEmailTier1Modal()=Opens the Risk Email Tier1 Modal
  */
  openRiskEmailTier1Modal(): void {
    window.scrollTo(0, 0);
    const modalRef = this.modalService.open(RiskEmailTier1Component, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
  }

  /**
   * getStatusClass(status) - Determines the CSS class for the application's status.
   * @param status - Current status of the credit application.
   * @returns A string representing the CSS class.
   */
  getStatusClass(status: string): string {
    return STATUS_CLASSES[status];
  }

  /**
   *addComments() - This method handles the adding a comment.It handles both success and error 
    responses by displaying appropriate messages to the user via toast notifications.
  */
  addComments() {
    if (!this.commentText.trim()) {
      this.error_msg = this.msg.errorwithoutinput;
      this.showDanger(this.dangerTpl);
      return;
    }
    const commentData = {
      caNumber: this.caNumber,
      caId: this.caId,
      commentText: this.commentText
    };
    this.creditStatusService.addaComment(commentData).subscribe(
      (response) => {
        this.success_msg = response.message;
        this.commentText = '';
        this.error_msg = '';
        // this.modifiedByName = this.getModifiedByName(response);
        this.comments.push({
          modifiedByName: response.caCommentAdd.modifiedByName,
          modifiedDatetime: response.caCommentAdd.modifiedDatetime,
          commentText: response.caCommentAdd.commentText
        });
        this.showSuccess(this.successTpl);
        console.log('Success:', response);
      },
      (error) => {
        this.error_msg = this.msg.errorwithmethod;
        this.showDanger(this.dangerTpl);
        console.error('Error:', error);
      }
    );
  }

  //need this function for get api to fetch the names and display
  // /**
  //  * getModifiedByName() - Retrieves the name of the person who modified last from local storage.
  //  * @param response - The response object containing modification details.
  //  * @returns - The full name of the person who modified, or an empty string if not available.
  // */
  //   getModifiedByName(response: any): string {
  //   let modifiedByName = '';
  //     if (response.caCommentAdd && response.caCommentAdd.modifiedByName) {
  //     modifiedByName = response.caCommentAdd.modifiedByName;
  //     } else {
  //     const userProfile = localStorage.getItem('user-profile');
  //     if (userProfile) {
  //       const user = JSON.parse(userProfile);
  //         if (user.firstName && user.lastName) {
  //           modifiedByName = `${user.firstName} ${user.lastName}`;
  //         }
  //       }
  //     }
  //     return modifiedByName ||undefined
  //   }
  /**
   * getUserInitials() - This function extracts the first letter of the first and last name 
     from a given full name and returns them as a string. 
   * @param name - The full name of the user, assumed to be in the format "First Last".
   * @returns - A string containing the first letter of the first name and the first letter 
     of the last name, both in uppercase.
  */
  // getUserInitials(name: string): string {
  //   const nameParts = name.split(' ');
  //   const firstNameInitial = nameParts[0] ? nameParts[0][0].toUpperCase() : '';
  //   const lastNameInitial = nameParts[1] ? nameParts[1][0].toUpperCase() : '';
  //   return firstNameInitial + lastNameInitial;
  // }

  /**
    * showSuccess() - Displays a success toast notification with custom content and options.
    * @param successTpl - The template reference containing the toast's content.
  */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Displays a danger (error) toast message.
   * This method is called when there is an error in adding the user.
   * @param dangerTpl - The template reference for the danger message to display.
  */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}


