import { Component } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './shared/services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showHeaderFooter = true;
  public isAuthenticated$!: Observable<boolean>;
  fromDate: NgbDate;
  toDate: NgbDate;

  constructor(private router: Router, private _oktaStateService: OktaAuthStateService,
    private loaderService: LoaderService  ) {
      
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        window.scrollTo(0, 0);
      });
    }
     
  loading: boolean = false;
  overlayState: boolean = false;

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeaderFooter = !event.urlAfterRedirects.startsWith('/acc');
      }
    });
    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState),
      map((authState: AuthState) => authState.isAuthenticated ?? false)
    );
    this.loaderService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
    this.loaderService.overlayState$.subscribe((applying) => {
      this.overlayState = applying;
    });
  }
  
}
