import { Component, Input } from '@angular/core';
import {  ViewChild, ElementRef, AfterViewInit, TemplateRef } from '@angular/core';
import {
  COL_DEF_FIELDS,
  COMPANY,
  COMPANY_CREDIT_STATUS,
  CONFIRM_COMP_POPUP,
  CUSTOM_ORDER,
  DEFAULT_STATUS,
  PROJECT_DETAILS_CONSTANTS,
  RESPONSE_STATUS
} from '../../../constants/studio-portal.constant';
import {
  COMPANY_NUMBER
} from '../../../enum/studio-portal.enum';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyUserComponent } from '../../../../../shared/components/add-company-user/add-company-user.component';
import { COMPANY_CONTACT_INFO } from 'mockData/development/company-list';
import { AddEditCompanyComponent } from '../../../../../shared/components/add-edit-company/add-edit-company.component';
import { AddNewProjectComponent } from 'src/app/shared/components/add-new-project/add-new-project.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../services/company.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { AddProjectComponent } from '../../projects/add-project/add-project.component';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { take } from 'rxjs';
import { ICONS_RENDERED } from 'src/app/shared/constants/common';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.scss']
})
export class ViewCompanyComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>; 
  @ViewChild('successTplCompany', { static: false }) successTplCompany!: TemplateRef<any>;
  @ViewChild('dangerTplCompany', { static: false }) dangerTplCompany!: TemplateRef<any>;  
  @ViewChild('successEnableCompanyTpl', { static: false }) successEnableCompanyTpl!: TemplateRef<any>;
  @ViewChild('successDisableCompanyTpl', { static: false }) successDisableCompanyTpl!: TemplateRef<any>;
  @ViewChild('dangerEnableCompanyTpl', { static: false }) dangerEnableCompanyTpl!: TemplateRef<any>;
  @ViewChild('dangerDisableCompanyTpl', { static: false }) dangerDisableCompanyTpl!: TemplateRef<any>;
  @ViewChild('restrictDisableCompanyTpl', { static: false }) restrictDisableCompanyTpl!: TemplateRef<any>; // Template for danger toast

  
  disableCompany: boolean=false;
  modalReference: NgbModalRef;
  company = COMPANY;
  companynumber = COMPANY_NUMBER;
  company_contact_info = COMPANY_CONTACT_INFO;
  @Input() public title;
  confirm=CONFIRM_COMP_POPUP
  companyData:any;
  formattedDate: Date;
  company_credit_status=COMPANY_CREDIT_STATUS
  creditStatus: string;
  companyName: any;
  isLoading: boolean=true;
  response: any;
  companyid: any;
  portalType: any;
  companyUserMetaData: any;
  addUserSuccess: any;
  addUserError: any;
  companyMetaData: any;
  countries: any;
  customOrder: string[];
  companyTypes: any;
  companyAddressTypes: any;
  customOrderCountry = CUSTOM_ORDER;
  addCompanySuccess: any;
  addCompanyError: any;
  companyError: boolean;
  enableCompany: boolean;
  colDefFields = COL_DEF_FIELDS;
  defaultStatusConst = DEFAULT_STATUS;
  responseStatus = RESPONSE_STATUS;
  iconsRenderer = ICONS_RENDERED;
  projectDetailsConstants = PROJECT_DETAILS_CONSTANTS;
  permissionsData: any;
  activeProjects: any;
  viewprojectpage: any;
  viewmydashboardpage: any;
  constructor(
    private modalService: NgbModal, 
    private router: Router, 
    private route: ActivatedRoute,
    private companyService:CompanyService,
    private toastService:ToastService,
    private loaderService: LoaderService
  ) {}
  
  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.customOrder = this.customOrderCountry;
    this.portalType = this.company?.portaltype;
    this.companyService.getCompaniesSubject().subscribe((data: any[]) => {
      const selectedCompanyData: any = data;
      this.activeProjects = selectedCompanyData?.activeProjects?.length;
    });
    this.route.queryParams?.subscribe(params => {
      this.companyid = params['companyid'];
      this.viewprojectpage = params['viewprojectpage'];
      this.viewmydashboardpage = params['viewmydashboardpage'];
      if(this.companyid !== undefined && this.companyid !== null){
        this.getData(this.companyid);
      }
    });
    
    this.getCompanyMetaData();
  }


  /**
*getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
 * to add the user in company.
*/

getCompanyUserMetaData(): void {
  this.companyService.getCompanyUsersMetadata(this.companyid).subscribe({
    next: (data: any) => {
      this.companyUserMetaData = data?.companyUserMetaData;
    },
    error: (error: any) => {
      console.error(error);
    }
  })
}

/**
*getData() - invoke the get company data api by passing company id
*/
  getData(companyId:number): void {
    this.loaderService.setLoadingState(true);
    this.companyService.getCompanyDetailsByCompanyId(companyId).subscribe(
      (data) => {
        if(data?.success){
          this.isLoading = false; 
          this.loaderService.setLoadingState(false);
          this.companyData = data?.companySearchData || null;
          if(this.companyData?.statusActiveInd == 'Y'){
            this.disableCompany = false;
            this.getCompanyUserMetaData();
          }else{
            this.disableCompany = true;
          }
          if(this.companyData?.validUntil != null){
            this.formattedDate = new Date(this.companyData?.validUntil);
          }
        } else {
          this.loaderService.setLoadingState(false);
          this.companyError = true;
        }
      },
      (error) => {
        console.error(error)
        this.isLoading = false;
        this.companyError = true;
        this.loaderService.setLoadingState(false);
      });
  }

/**
*addUser() - invoke the add user modal popup
*/
 
  addUser(): void {
   let metaData = this.companyUserMetaData;
    let portalType = this.company?.portaltype;
    let modalType = this.company?.modalAdd;
    let userDetails = {
      company : this.companyData?.companyName
    }
    window.scrollTo(0,0);
    let title = this.company?.adduser;
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl',fullscreen: true  
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.companyId = this.companyid;
    this.modalReference.componentInstance.componentType = this.company?.componentType;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.userDetails = userDetails;

    this.modalReference.result.then((data) => {
      if (data) {
        if (data?.status == this.responseStatus.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
          this.getData(this.companyid);
        }
      }
    });
    this.modalReference.componentInstance.emitError.subscribe((data) => {
      if (data) {
        this.addUserError = data?.message;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
*addProject() - invoke the add project modal popup
*/
  addProject(): void{
    window.scrollTo(0,0);
    let title = this.company?.addnewproject;
    let componentType = this.company?.componentType;
    let portalType = this.company?.portaltype;
    let  projectInfo = {};
    if(this.companyData){
       projectInfo = {
        company:{
          companyType:{
            companyTypeId: this.companyData?.companyType.companyTypeId, 
            companyType: this.companyData?.companyType.companyType
          },
          companyName: this.companyData?.companyName,
          companyId: this.companyData?.companyId
        }
      }
    }
   
    this.modalReference = this.modalService.open(AddProjectComponent, {windowClass: 'common-modal-xl',fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = title;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.projectInfo = projectInfo;
  }

/**
*editCompany() - invoke the edit company modal popupc
*/
  editCompany(): void {
    window.scrollTo(0,0);
    let title = this.company?.editcompany;
    let portalType = this.portalType;
    let modalType = this.company?.modalType;
    let metaData = this.companyMetaData;
  
    this.modalReference = this.modalService.open(AddEditCompanyComponent, {windowClass: 'common-modal-xl ',
      backdrop: 'static', size: 'lg', keyboard: false, fullscreen: true  
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.companyData = this.companyData;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data?.status == this.responseStatus.success) {
          this.addCompanySuccess = data?.message;
          this.showSuccess(this.successTplCompany);
          this.getData(this.companyid);
          
        } else {
          this.addCompanyError = data?.message;
          this.showDanger(this.dangerTplCompany);
        }
      }
    });
  }

   /** getCompanyMetaData - mthd to call get company meta data from the getCompanyMetadata api */
   getCompanyMetaData(): void {
    this.companyService.getCompanyMetadata().subscribe((data: any) => {
      this.companyMetaData = data?.companyMetaData;
    },
      (error) => {
        console.error(error)
      }
   )
 }

  /**
*openConfirmModal() - invoke the disable company confirmation modal popup
*/
  openConfirmModal(template: TemplateRef<any>):void {
      if(this.company){
        this.title = this.disableCompany ? this.company?.enablecompany : this.company?.disablecompany;

        this.modalReference = this.modalService.open(template, {
          windowClass: 'common-modal-center',
          centered: true
        });
      }
     this.modalReference.componentInstance.title = this.title;
  }

/**
*confirmDisable() - invoke the confirmation disable company modal popup
*/
  confirmDisable(): void {
    this.disableCompany = true;
    if (this.modalReference) {
      this.modalReference.close();
      this.modalReference = null; 
      let companyId = this.companyid;
      this.callDisableCompanyAPI(companyId);
    } 

  }
  confirmEnable(): void {
    this.enableCompany = true;
    if (this.modalReference) {
      this.modalReference.close();
      this.modalReference = null; 
      let companyId = this.companyid;
      this.callEnableCompanyAPI(companyId)
    } 
  }

 /**
*callDisableCompanyAPI() - to call disable company API
*/

  callDisableCompanyAPI(companyId) :  void {
    this.companyService.disableCompany(companyId).pipe(take(1)).subscribe(
      (data) => {
        if(data?.success){
          this.showSuccess(this.successDisableCompanyTpl);
          this.getData(companyId);
        }else{
          this.showDanger(this.successTpl);
        }
      },
      (error) => {
        console.error(error);
      });
  }

/**
*callEnableCompanyAPI() - to call enable company API
*/

callEnableCompanyAPI(companyId) :  void {
  this.companyService.enableCompany(companyId).pipe(take(1)).subscribe(
    (data) => {
      if(data?.success){
        this.showSuccess(this.successEnableCompanyTpl);
        this.getData(companyId);
      }else{
        this.showDanger(this.successTpl);
      }
    },
    (error) => {
      console.error(error);
    });
}
  /**
*closeModal() - close modal popup
*/
  closeModal(): void {
    if (this.modalReference) {
      this.modalReference.close();
      this.modalReference = null; 
    } 
  }

/**
*viewAllProjects() - mthd used for redirect to project list with params companyid
*/
  viewAllProjects(companyid,companyname) : void {
    this.router.navigate(['/feature/studio-dashboard/project/project-list'],{
      queryParams: {
        companyid: companyid,
        companyName: companyname,
        viewcompanypage:true
      }
    });
  }
  
/**
*viewAllCompanies() - mthd used for redirect to companies list
*/
  viewAllCompanies() : void {
    this.router.navigate(['/feature/studio-dashboard/company/companies']);
  }
  
  /**
*redirectCompanyUser() - mthd used for redirect to companies user
*/
  redirectCompanyUser(companyId,companyName) : void {
    this.router.navigate(['/feature/studio-dashboard/company/company-user'],{
      queryParams: {
        companyid: companyId,
        companyName: companyName,
      }
    });
  }
   /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
   showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 5000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any) {
    this.toastService.clear()
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

}
