import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewCompanyComponent } from './components/company/view-company/view-company.component';
import { StudioPortalComponent } from './studio-portal.component';
import { CompanyProjectComponent } from './components/company/company-project/company-project.component';
import { UserProjectComponent } from '../components/user-project/user-project.component';
import { CompaniesComponent } from './components/company/companies/companies.component';
import { UsersComponent } from './components/users/users.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ViewAllUsersComponent } from './components/projects/view-all-users/view-all-users.component';
import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { UserComponent } from 'src/app/shared/components/user/user.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreditApplicationComponent } from './components/credit-application/credit-application.component';
import { CreditStatusDetailsComponent } from './components/credit-application/credit-status-details/credit-status-details.component';
import { CredAppComponent } from './components/credit-application/cred-app/cred-app.component';
import { RiskEmailComponent } from '../components/studio-portal/risk-email/risk-email.component';

const routes: Routes = [{
  path: '', component: StudioPortalComponent,
  
  children: [
    { path: 'Mydashboard', component: DashboardComponent },
    { path: 'users', component: UsersComponent },

    {
      path: 'company',
      children: [
        {
          path: '',
          component: ViewCompanyComponent
        },
        {
          path: 'company-user',
          component: CompanyProjectComponent
        },
        {
          path: 'user-project',
          component: UserProjectComponent
        },
        {
          path: 'companies',
          component: CompaniesComponent
        }
      ]
    },

    {
      path: 'project',
      children: [
        {
          path: '',
          component: ViewCompanyComponent
        },
        {
          path: 'project-list',
          component: ProjectsComponent
        },
        {
          path: 'view-users',
          component: ViewAllUsersComponent
        },
        { path: 'project-details/:profileId/:projectId', component: ProjectDetailsComponent }, ///
      ]
    },

    {
      path: 'credit-app', component: CreditApplicationComponent,

      children: [
        {
          path: 'studio-pending-risk',
          component: CreditStatusDetailsComponent
        },
        {
          path: 'risk-email',
          component: RiskEmailComponent
        },
        { path: 'cred-app', component: CredAppComponent },


      ]
    },
    
{    path: 'user-dropdown', component:UserComponent
}
  ],
}]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudioPortalRoutingModule { }
