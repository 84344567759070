import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CRED_FORM_THREE_CONSTANT,
  TERMS_AND_CONDITIONS,
} from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { FormValidationService } from 'src/app/shared/services/form-validations/form-validation.service';
import {
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-cred-app-step-three',
  templateUrl: './cred-app-step-three.component.html',
  styleUrls: ['./cred-app-step-three.component.scss'],
  providers: [DatePipe],
})
export class CredAppStepThreeComponent implements OnInit {
  @Input() customerStatus;
  @Input() revisionData: any;
  @Input() creditAppStatus: string;
  @Input() creditMetaData: any;
  @Input() startApplicationData: any;
  @Input() caSeqId;
  @Input() caNumber;
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() prevStepEvent = new EventEmitter<void>();
  @Output() formSubmittedEvent = new EventEmitter<any[]>();
  @Output() stepOneFormChange = new EventEmitter();
  @ViewChild('NgbdDatepicker') d: NgbDateStruct;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  form: FormGroup;
  credFormThree = CRED_FORM_THREE_CONSTANT;
  termsContent = TERMS_AND_CONDITIONS;
  model: NgbDateStruct;
  termsAndConditionsData;
  submissionError;
  termsAndConditionsMessage;
  initialFormValues: any;

  constructor(
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private creditApplicationsService: CreditApplicationsService,
    private datePipe: DatePipe,
    private toastService: ToastService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.initializeStep3Form();
    this.revisionData = this.revisionData;
    this.termsAndConditionsData =
      this.startApplicationData?.creditApplicationList?.termAndCondition;
    this.checkCreditAppStatus();
    if (this.customerStatus === this.credFormThree.needsRevision) {
      this.getRevisionRequiredMessage();
    }
    if(this.startApplicationData && this.customerStatus === 'Finish Application' || this.customerStatus === 'Start Application'){
      this.initialFormValues =this.form?.getRawValue();
      this.form?.valueChanges.subscribe(() => {
          this.checkFormChanges();
        });
    }
    if(this.customerStatus === 'NEEDS REVISIONS'){
      if(this.revisionData?.caRevisionDetails?.applicationFormDetails){
      this.initialFormValues = this.form?.getRawValue();
      this.form?.valueChanges.subscribe(() => {
        this.checkFormChanges();
      });
      }
    }
   
  }

  /**
   * checkFormChanges() - to check the form chnages and according to that we need to show dialgue box
   */
  checkFormChanges(): void {
    const currentFormValues = this.form?.getRawValue();
    const isChanged = !_.isEqual(this.initialFormValues, currentFormValues);
    this.stepOneFormChange.emit(isChanged);
  }

   /**
   * isInitialState() - returns a boolean value by checking initial values and recent value
   * @returns - boolean value checking initial state and formvalue 
   */
  isInitialState(): boolean {
    return (
      JSON.stringify(this.form?.value) ===
      JSON.stringify(this.initialFormValues)
    );
  }

  ngOnChanges(): void {
    this.initializeStep3Form();
    this.revisionData = this.revisionData;
    this.termsAndConditionsData =
      this.startApplicationData?.creditApplicationList?.termAndCondition;
    this.checkCreditAppStatus();
    if (this.customerStatus === this.credFormThree.needsRevision) {
      this.getRevisionRequiredMessage();
    }
  }

  /**
   * initializeStep3Form() - method to initialize the step 3 form
   */
  initializeStep3Form() {
    this.form = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphabets,
        ],
      ],
      title: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphanumericAndSpecialCharacters,
        ],
      ],
      signature: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          this.formValidationService.validateAlphabets,
        ],
      ],
      date: ['', [Validators.required]],
      convertedDate: [''],
    });
  }

  /**
   * method to get the messages for revisions required sections
   */
  getRevisionRequiredMessage() {
    const data =
      this.revisionData?.caRevisionDetails?.termsAndConditionsDetails;
    this.termsAndConditionsMessage = data?.reviewDetails?.reviewMailBody;
  }

  /**
   * updateFormControls() - to update the form controld based on review required
   */
  updateFormControls(): void {
    const data =
      this.revisionData?.caRevisionDetails?.termsAndConditionsDetails
        ?.reviewRequired;
    const enableControls = data === true;

    if (this.revisionData) {
      this.form?.[enableControls ? 'enable' : 'disable']();
    }
  }

  /**
   * patchFormData() - patches the form with the data from termsAndConditionsData
   */
  patchFormData(data: any): void {
    this.form.patchValue({
      name: data?.name || '',
      title: data?.title || '',
      signature: data?.signature || '',
      date: this.convertStringToNgbDate(data?.date) || null, // Convert string date to NgbDateStruct
    });
  }

  /**
   * convertStringToNgbDate() - converts a date string to NgbDateStruct
   */
  convertStringToNgbDate(dateString: string): NgbDateStruct {
    if (!dateString) return null;

    const parts = dateString.split('/');
    return {
      year: parseInt(parts[2], 10),
      month: parseInt(parts[0], 10),
      day: parseInt(parts[1], 10),
    };
  }

  /**
   * method to save and close the credit application step 3
   */
  saveAndClose() {
    this.onSubmit();
    this.saveAndCloseEvent.emit();
  }

  /**
   * nextPage() - Method to handle the action of proceeding to the next page.
   */
  nextPage(): void {
    if (
      this.customerStatus !== this.credFormThree.startApplication &&
      this.customerStatus !== this.credFormThree.finishApplication &&
      this.customerStatus !== this.credFormThree.needsRevision
    ) {
      this.nextStepEvent.emit();
    } else {
      this.onSubmit();
      this.nextStepEvent.emit();
    }

    const currentFormValues = this.form?.getRawValue();
    const isChanged = !_.isEqual(this.initialFormValues, currentFormValues);
    this.stepOneFormChange.emit(!isChanged);
  }

  /**
   *onSubmit() - submitting the step3 form for the credit application
   */
  onSubmit(): any {
    this.loaderService.setLoadingState(true);

    const formValue = this.form.getRawValue();
    if (formValue?.date) {
      this.onDateSelect(formValue?.date); // This will set the convertedDate
  }
    if (this.creditAppStatus !== 'Needs Revisions') {
      let payLoadData = this.payLoadData();
      if (payLoadData) {
        this.formSubmittedEvent.emit();
        this.creditApplicationsService
          .creditApplicationTermsandCon(payLoadData)
          .subscribe({
            next: (response: any) => {
              this.loaderService.setLoadingState(false);
              this.showSuccess(this.successTpl);
            },
            error: (error) => {
              this.submissionError = error;
              this.loaderService.setLoadingState(false);
              this.showDanger(this.dangerTpl);
            },
          });
      }
    } else {
      let revisionPayLoadData = this.revisionPayLoadData();
      if (revisionPayLoadData) {
        this.formSubmittedEvent.emit();
        this.creditApplicationsService
          .creditApplicationRevisionTermsandCon(revisionPayLoadData)
          .subscribe({
            next: (response: any) => {
              this.loaderService.setLoadingState(false);
              this.showSuccess(this.successTpl);
            },
            error: (error) => {
              this.submissionError = error;
              this.loaderService.setLoadingState(false);
              this.showDanger(this.dangerTpl);
            },
          });
      }
    }
  }

  /**
   *payLoadData() - returns the payload based on condition to submit the form
   */
  payLoadData(): any {
    const formValue = this.form.getRawValue();
    let payload = {};
    payload = {
      tcVersionId: this.creditMetaData?.tcVersionId,
      caSeqId: this.caSeqId,
      caNumber: this.caNumber,
      tcId: this.termsAndConditionsData?.tcId,
      name: formValue?.name ? formValue.name : null,
      title: formValue?.title ? formValue.title : null,
      signature: formValue?.signature ? formValue.signature : null,
      date: formValue?.date ? formValue.convertedDate : null,
    };
    return payload;
  }

  /**
   *revisionPayLoadData() - returns the payload based on condition to submit the form for revision
   */
  revisionPayLoadData(): any {
    const formValue = this.form.getRawValue();
    const revisionDate =
      this.revisionData?.caRevisionDetails?.termsAndConditionsDetails?.date;
    let payload = {
      tcVersionId: this.creditMetaData?.tcVersionId,
      // dynamic data should be passed once the credit app api is done
      caSeqId: this.revisionData?.caRevisionDetails?.caSeqId,
      caNumber: this.revisionData?.caRevisionDetails?.caNumber,
      tcId: this.revisionData?.caRevisionDetails?.termsAndConditionsDetails
        .tcId,
      name: this.revisionData?.caRevisionDetails?.termsAndConditionsDetails
        ?.name
        ? this.revisionData?.caRevisionDetails?.termsAndConditionsDetails?.name
        : formValue.name,
      title: this.revisionData?.caRevisionDetails?.termsAndConditionsDetails
        ?.title
        ? this.revisionData?.caRevisionDetails?.termsAndConditionsDetails?.title
        : formValue.title,
      signature: this.revisionData?.caRevisionDetails?.termsAndConditionsDetails
        ?.signature
        ? this.revisionData?.caRevisionDetails?.termsAndConditionsDetails
            ?.signature
        : formValue.signature,
      date: formValue.convertedDate ? formValue.convertedDate : revisionDate,
    };

    return payload;
  }

  /**
   * checkCreditAppStatus() - method to disable the form based on credit application status
   */
  checkCreditAppStatus(): void {
    if (this.customerStatus === this.credFormThree.startApplication) {
      this.initializeStep3Form();
      this.patchFormData(this.termsAndConditionsData);
    } else if (this.customerStatus === this.credFormThree.finishApplication) {
      this.patchFormData(this.termsAndConditionsData);
      this.form.enable();
    } else if (this.customerStatus === this.credFormThree.needsRevision) {
      this.patchRevisions();
      this.updateFormControls();
    } else {
      this.disableFormControls();
      this.patchFormData(this.termsAndConditionsData);
    }
  }

  /**
   * disableFormControls() - method to disable the form controls
   */
  disableFormControls(): void {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.disable(); // Disable top-level controls
    });
  }

  /**
   * previousPage() - method to handle the event of going back to previous page
   */
  previousPage(): void {
    this.prevStepEvent.emit();
  }

  /**
   * onDateSelect() - method to convert the selected date for the payload
   */

  onDateSelect(date: any) {
    const formattedDate = this.datePipe.transform(
      new Date(date.year, date.month - 1, date.day),
      'yyyy-MM-dd'
    );
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0'); // Ensure 2 digits
    const minutes = String(now.getMinutes()).padStart(2, '0'); // Ensure 2 digits
    const seconds = String(now.getSeconds()).padStart(2, '0'); // Ensure 2 digits
    const finalDate = `${formattedDate} ${hours}:${minutes}:${seconds}`;
    this.form?.get('convertedDate').setValue(finalDate);
  }

  /**
   * patchRevisions() - to patch values in form for step three
   */
  patchRevisions(): void {
    const data =
      this.revisionData?.caRevisionDetails?.termsAndConditionsDetails;
    this.form?.patchValue({
      name: data?.name,
      title: data?.title,
      signature: data?.signature,
      date: this.convertRevisionDateToString(data?.date), // Convert to NgbDateStruct
    });
  }

  /**
   * convertRevisionDateToString() - method to convert the revisions date
   * @param dateString - date from api response
   * @returns - the date object
   */
  convertRevisionDateToString(dateString: string) {
    if (!dateString) return null;
    const datePart = dateString.split(' ')[0];
    const parts = datePart.split('-');
    return {
      year: +parts[0],
      month: +parts[1],
      day: +parts[2],
    };
  }

  /**
   * showSuccess() - Method to display a success toast notification.
   * @param successTpl - The template reference for the success message
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * startApplication() - method to get the credit application data
   * @param sequenceId
   * @param creditNumber
   */
  startApplication(sequenceId, creditNumber?) {
    this.loaderService.setLoadingState(true);
    this.creditApplicationsService
      .startCreditApplication(sequenceId, creditNumber)
      .subscribe({
        next: (response: any) => {
          this.startApplicationData = response;
          this.loaderService.setLoadingState(false);
        },
        error: (error) => {
          console.error(error);
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        },
      });
  }
}
