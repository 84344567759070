import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ATTACH_QUOTES_CA } from 'mockData/development/studio-send-credit-application';
import { STUDIO_CREDIT_APPLICATION } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ICONS_RENDERED } from 'src/app/shared/constants/common';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import _ from 'lodash';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { concatMap, Observable, of } from 'rxjs';
import { CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';

@Component({
  selector: 'app-attach-quotes',
  templateUrl: './attach-quotes.component.html',
  styleUrls: ['./attach-quotes.component.scss'],
})
export class AttachQuotesComponent {
  @ViewChild('fileInput') fileInput!: ElementRef; // Reference to the file input element
  @ViewChild('WarningMsgModal', { static: false })
  WarningMsgModal!: TemplateRef<any>;
  @Input() departmentDetails;
  @Input() caSeqId;
  @Input() caNumber;
  creditApplicationConstants = STUDIO_CREDIT_APPLICATION;
  iconsRenderer = ICONS_RENDERED;
  readonly MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  isFileOver = false;
  files: any[] = [];
  quotesForm: FormGroup;
  fileError = '';
  newUploadFileName: string;
  fileSizeErrorMessage: string;
  pdfFileErrorMessage: string;
  selectedQuotesValue: any;
  
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private http: HttpClient,
    private uploadQuoteService: ProjectsCreditApplicationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.quotesForm = new FormGroup({
      quotesComment: new FormControl('', Validators.required),
      files: new FormArray([]),
    });
  }

  /**
   * filesFormArray() - Getter for accessing the 'files' FormArray within the quotesForm.
   */
  get filesFormArray(): FormArray {
    return this.quotesForm.get('files') as FormArray;
  }

  /**
   * onDragOver() - onDragOver method used to drag the mouse
   */
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }

  /**
   * onDragLeave() - onDragLeave method used to leave the mouse
   */
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }

  /**
   * onDrop() - onDrop method used to drop the the files
  */
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
    const files = event.dataTransfer?.files; 
    const isError = _.some(files, (selectedFile) => {
     if (selectedFile?.size > this.MAX_FILE_SIZE) {
      this.fileSizeErrorMessage = CREDIT_APP_TOAST_MESSAGES.fileMoreThan5MBErrMsg;
      this.pdfFileErrorMessage = '';
      this.resetFileInput();
      return true;;
     }
     if (selectedFile?.type !== 'application/pdf') {
      this.resetFileInput();
      this.pdfFileErrorMessage = CREDIT_APP_TOAST_MESSAGES.selectPdfFile;
      this.fileSizeErrorMessage = '';
      return true;;
     }
     return false;
    });
    if (!isError && event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  /**
   * onFileSelect() - onFileSelect method used to select the the file
  */
  onFileSelect(event: any): void {
    const input = event.target as HTMLInputElement;
    const files = event.target?.files; 
    const isError = _.some(files, (file) => {
      if (file?.size > this.MAX_FILE_SIZE) {
        this.fileSizeErrorMessage = CREDIT_APP_TOAST_MESSAGES.fileMoreThan5MBErrMsg;
        this.pdfFileErrorMessage = '';
        this.resetFileInput();
        return true;
      }
      if (file?.type !== 'application/pdf') {
        this.resetFileInput();
        this.pdfFileErrorMessage = CREDIT_APP_TOAST_MESSAGES.selectPdfFile;
        this.fileSizeErrorMessage = '';
        return true;;
       }
      return false;
    });
    if (!isError && input?.files) {
      this.addFiles(input.files);
    }
    this.resetFileInput(); // Reset input after handling the selection
  }

  /**
   * addFiles() - addFiles method used to add the the file
   */
  addFiles(files: FileList): void {
    this.fileSizeErrorMessage = '';
    this.pdfFileErrorMessage = '';
    // Show an error if the total file count exceeds 20
    const totalFiles = this.files.length + files.length;
    if (totalFiles > 20) {
      this.fileSizeErrorMessage = '';
      this.pdfFileErrorMessage = CREDIT_APP_TOAST_MESSAGES.moreThan20Files;
      return;
    }
    _.forEach(files, (file) => {
      const isDuplicate = this.files.some((existingFile) => existingFile.file.name === file.name);
    // Set error message if duplicate found
      if (isDuplicate) {
        this.fileSizeErrorMessage = '';
        this.pdfFileErrorMessage = CREDIT_APP_TOAST_MESSAGES.duplicateFileName;
        return;
      }
    
      this.files.push({
        file,
        departmentId: '',
        quotesValue: '',
        error: '',
      });
  
      const fileFormGroup = new FormGroup({
        departmentId: new FormControl('', Validators.required),
        quotesValue: new FormControl('', Validators.required),
      });
  
      this.filesFormArray.push(fileFormGroup);
    });
  }
  

  /**
   * removeFile() - Remove the file which was selected in browse
   */
  removeFile(file: any, index: number): void {
    this.files.splice(index, 1);
    this.filesFormArray.removeAt(index);
    // clear error messages if the file count is below the limit
    if (this.files.length <= 20) {
      this.pdfFileErrorMessage = '';
    }
  }

  /**
   * resetFileInput() - Reset the file input to allow re-uploading of the same files
   */
  resetFileInput(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
  * onQuoteValueChange() -  Method to format the value while typing
  */
   onQuoteValueChange(event: any): void {
    const inputElement = event.target;
    let value = inputElement.value.replace(/[^0-9.]/g, '');
    if (value) {
      value = '$' + Number(value).toLocaleString('en-US');
    }
    inputElement.value = value;
  }
 
  /**
   * openWarningModal() - Method to open the warning modal
   */
  openWarningModal(): void {
    this.modalService.open(this.WarningMsgModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static',
    });
  }

  /**
   * closeModal() - Method to close the warning modal
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * viewFile() - Method to view the file
   */
  viewFile(file: any): void {
    const url = URL.createObjectURL(file.file);
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    URL.revokeObjectURL(url);
  }

  /**
   * uploadFiles() - Method to upload files
   */
  uploadFiles(): void {
    this.loaderService.setLoadingState(true);
    if (this.quotesForm.invalid) {
      this.loaderService.setLoadingState(false);
      return;
    }
    const filesData = [];
    _.forEach(this.files, (fileObj, index) => {
      const file = fileObj.file;
      const newFileName = `file_${index + 1}.${file.name.split('.').pop()}`;
      const newFile = new File([file], newFileName, { type: file.type });
      let quoteValue = this.filesFormArray.at(index).get('quotesValue')?.value;
      if (quoteValue) {
       this.selectedQuotesValue = quoteValue.replace(/[^0-9.]/g, '');  // Remove non-numeric characters, including the dollar sign
      }
      const data = {
        file: newFile,
        caSeqId: this.caSeqId,
        caNumber: this.caNumber,
        uploadSequenceNo: index + 1,
        fileName: newFileName,
        quotesValue: this.selectedQuotesValue,
        departmentId: this.filesFormArray.at(index).get('departmentId').value,
        quotesComment: this.quotesForm.get('quotesComment').value,
      }
      filesData.push(data);
    });
  
    // Now append each file to formData separately, along with its metadata
    const apiCalls: Observable<any>[] = [];
    _.forEach(filesData, (data) => {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('data', JSON.stringify({
        caSeqId: data.caSeqId,
        caNumber: data.caNumber,
        uploadSequenceNo: data.uploadSequenceNo,
        quotesValue: data.quotesValue,
        departmentId: data.departmentId,
        quotesComment: data.quotesComment,
      }));
      const apiCall = this.uploadQuoteService.attachQuotes(formData);
      apiCalls.push(apiCall);
    });
  
    // Use concatMap to send the requests sequentially
    of(...apiCalls).pipe(concatMap((call) => call)).subscribe({
      next: (response: any) => {
        let message = { status: 'success', message: response.message };
        this.activeModal.close(message);
      },
      complete: () => {
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        let errorMessage = { status: 'error', message: error };
        this.activeModal.close(error);
        console.error(error);
        this.loaderService.setLoadingState(false);
      },
    });
  }
  
}
