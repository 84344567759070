<div class="page-wrapper">
  <div class="container px-0">
    <div class="row">
      <div class="comman-title-block">
        <div class="comman-title-section">
          <h1>Credit Applications</h1>
        </div>
        <div class="comman-filter-block">
          <div class="comman-filter-title-section mb-0">
            <p>FILTERS :</p>
          </div>
          <div class="dual-filter-section">
            <div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
              <app-ng-multiselect-dropdown [placeholder]="'Companies'" [settings]="defaultSettings" [data]="companyData"
                (onSelect)="selectedCompany($event)">
              </app-ng-multiselect-dropdown>
              <app-ng-multiselect-dropdown [placeholder]="'Projects'" [settings]="defaultSettings" [data]="projectData"
                (onSelect)="selectedProject($event)">
              </app-ng-multiselect-dropdown>
              <app-ng-multiselect-dropdown [placeholder]="'Departments'" [settings]="defaultSettings"
                [data]="departmentData" (onSelect)="selectedDepartment($event)">
              </app-ng-multiselect-dropdown>
              <app-ng-multiselect-dropdown [placeholder]="'Status'" [settings]="defaultSettingsStatus" [data]="statusData"
                (onSelect)="selectedStatus($event)" [(ngModel)]="toggleSelectedFilter">
              </app-ng-multiselect-dropdown>
            </div>
            <div class="notification-coloum">
              <img src="assets/images/approved-email.svg" alt="approved-email" />
              <span class="notification-status">Letter of Approval / COD not sent</span>
              <div class="common-toggle-button">
                <input class="common-toggle-input" type="checkbox" role="switch"
                  (change)="toggleFilter()" [checked]="isFilterEnabled" />
              </div>
            </div>
          </div>
        </div>
        <div class="selected-filters-section">
          <div *ngIf="isFiltersSelected" class="selected-filters">
            <div *ngFor="let company of filteredCompany" class="filter-tag">
              {{ company.text | titlecase}}
              <button type="button" (click)="removeSelectedItem('company', company)">×</button>
            </div>
            <div *ngFor="let projects of filteredProjects" class="filter-tag">
              {{ projects.text | titlecase }}
              <button type="button" (click)="removeSelectedItem('projects', projects)">×</button>
            </div>
            <div *ngFor="let dept of filteredDepartments" class="filter-tag">
              {{ dept.text | titlecase }}
              <button type="button" (click)="removeSelectedItem('department', dept)">×</button>
            </div>
            <div *ngFor="let status of filteredStatus" class="filter-tag">
              {{ status.text | titlecase}}
              <button type="button" (click)="removeSelectedItem('status', status)">×</button>
            </div>
            <a class="text-link" (click)="clearFilter()">{{ companyConstants.clearFilters }}</a>
          </div>
        </div>
      </div>

      <div class="common-grid-section">
        <div class="common-grid-block">
          <div class="mt-4">
            <app-common-ag-grid  
             #agGrid 
             class="ag-theme-custom"
             [colDefs]="colDefs"
            [rowData]="filteredRowData"
            (cellClicked)="onCellClicked($event)" 
            [colsForSmallScreen]="colsForSmallScreen"
            [colsForMediumScreen]="colsForMediumScreen" 
            [colsForLargeScreen]="colsForLargeScreen"
            [colsForExtraLargeScreen]="colsForExtraLargeScreen" 
            [editType]="'fullRow'" 
            [suppressClickEdit]="true"
            [animateRows]="true">
            </app-common-ag-grid>
           </div>
        </div>
      </div>
    </div>
  </div>

</div>


<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{constants.message}}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="closeUnderReviewModal()">
        {{constants.okButton}}
      </button>
    </div>
  </div>
</ng-template>
