import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-sales',
  templateUrl: './sidebar-sales.component.html',
  styleUrls: ['./sidebar-sales.component.scss']
})
export class SidebarSalesComponent {
  @Input() projectName: string;
  @Input() userName: string;
  @Input() companyName: string;
  @Input() projectId: string;

}


