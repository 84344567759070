import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessBannerService {
  private alertMessageSource = new BehaviorSubject<string | null>(null);
  alertMessage$ = this.alertMessageSource.asObservable();

  showAlert(message: string): void {
    this.alertMessageSource.next(message);
    setTimeout(() => this.clearAlert(), 10000); // Clear after 10 seconds
  }

  clearAlert(): void {
    this.alertMessageSource.next(null);
  }
}
