import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CONFIRM_CLOSE_POPUP, CRED_DEPARTMENT_CONSTANT, CREDIT_APPLICATION_CONSTANTS } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsListService } from 'src/app/feature/customer-portal/services/projects-list/projects-list.service';

@Component({
  selector: 'app-credit-app-sidebar',
  templateUrl: './credit-app-sidebar.component.html',
  styleUrls: ['./credit-app-sidebar.component.scss']
})
export class CreditAppSidebarComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('WarningMsgModal', { static: false }) WarningMsgModal!: TemplateRef<any>;
  
  @Input() creditAppStatus: string;
  @Input() projectId: number;
  @Input() projectName: string;
  @Input() caSeqId: number;
  // @Input() caNumber?: number;
  @Input() customerStatus;
  @Input() companyId;
  customerForm: FormGroup;
  departmentsForm: FormGroup;
  startApplicationData;
  completedSteps: boolean[] = [false, false, false, false];
  currentStep: number = 1; // Default to the first step
  departments: any;
  modalReference!: NgbModalRef;
  credFormDepartmentConstant = CRED_DEPARTMENT_CONSTANT;
  credAppConstants = CREDIT_APPLICATION_CONSTANTS;
  confirmClosePopup = CONFIRM_CLOSE_POPUP;
  departmentForm: FormGroup;
  editDepartmentsResponse: any;
  deptError: any;
  errorMessage: any;
  states: any;
  creditMetaData: any;
  departmentsData = [];
  departmentsDataList = [];
  revisionData: any;
  projectDetails;
  caNumber: number;
  initialSelectedDepartments: any[] = []; // Save initial selected departments
  isFormEdited: boolean;

  constructor(
    private creditApplicationService: CreditApplicationsService,
    private projectsService: ProjectsListService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    localStorage.removeItem('formData');
    localStorage.removeItem('tandcData');
    localStorage.removeItem('certificateData');
    this.getProjectDetails();
    this.initCustomerCreditForm();
    this.initDepartmentsForm();
    // Initialize the form selected departments
    this.departmentForm = this.fb.group({
      selectedDepartments: this.fb.array([]),
    });
    this.fetchStateMetaData();
    this.selectStep(this.currentStep);
  }

  ngOnChanges() {
    this.getProjectDetails();
    this.getDetailsByStatus();
    this.selectStep(this.currentStep);
  }

  /**
   * getProjectDetails() - Method to retrieve detailed information about a specific project
   * Retrieves the project ID from the route parameters.
   * Fetches project details by project ID and assigns the response to appropriate variables.
   * Sets the project status, project users data, and spaces/resources data.
   * Processes spaces and resources data, counts project users, and sorts the project user list.
   */
  getProjectDetails() {
    this.loaderService.setLoadingState(true);
    const projectId = this.projectId;
    this.projectId = projectId;
    const projectIdNumber = +projectId;
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    const userId = userProfileId.userProfileId;
    this.projectsService.getProjectDataById(projectIdNumber, userId).subscribe({
      next: (response: any) => {
        if (response) {
          const res = response.project;
          this.projectDetails = res;
          this.getCaNumberBySeqId(this.projectDetails, this.caSeqId);
          this.loaderService.setLoadingState(false);
        } else {
          console.warn('Profile data could not be retrieved.');
        }
      },
      error: (error) => {
        console.error('Error fetching project details', error);
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      },
    });
  }

  /**
   * getCaNumberBySeqId() - method to get the caNumber based on the caSeqID
   * @param caSeqId - the caSeqId of the credit application
   * @returns - the caNumber
   */
  getCaNumberBySeqId(projectDetails, caSeqId: number): void {
    const application = projectDetails?.creditApplications?.find(app => app.caSeqId === caSeqId);
    this.caNumber = application?.caNumber;
    this.getDetailsByStatus();
    // this.startApplication(this.caSeqId, this.caNumber);
  }

  /**
  * getDetailsByStatus() - method to call the api based on status
  */
  getDetailsByStatus() {
    if (this.customerStatus === this.credAppConstants.startApplicationStatus ||
      this.customerStatus === this.credAppConstants.finishApplication) {
      this.startApplication(this.caSeqId, this.caNumber);
    }
    else if (this.customerStatus === this.credAppConstants.needsRevision || 
      this.customerStatus === this.credAppConstants.revisionsSubmitted
    ) {
      this.getRevisionForm();
    }
    else {
      this.startApplication(this.caSeqId, this.caNumber);
    }
  }

  initCustomerCreditForm(): void {
    this.customerForm = new FormGroup({
      stepOneFormGroup: new FormGroup({
        applicationForm: new FormControl(""),
      }),
      stepTwoFormGroup: new FormGroup({
        certificateOfInsurance: new FormControl(""),
      }),
      stepThreeFormGroup: new FormGroup({
        termsandCondition: new FormControl("")
      }),
      stepFourFormGroup: new FormGroup({
        reviewForm: new FormControl("")
      })
    })
  }

  initDepartmentsForm(): void {
    this.departmentForm = this.fb.group({
      selectedDepartments: this.fb.array([]),
    });
  }

  /**
* currStepForm() - method to get the current step formgroup
* @returns current step formgroup
*/
  get currStepForm(): any {
    return this.customerForm.get(`step${this.currentStep}`) as FormGroup
  }

  /**
   * statusChanges() - method to check the changes in form status after form is submitted
   */
  statusChanges(): void {
    this.getProjectDetails();
  }

  /**
   * startApplication() - method to get the credit application data
   * @param sequenceId 
   * @param creditNumber 
   */
  startApplication(sequenceId, creditNumber?) {
    this.loaderService.setLoadingState(true);
    this.creditApplicationService.startCreditApplication(sequenceId, creditNumber).subscribe({
      next: (response: any) => {
        this.startApplicationData = response;
        this.departmentsDataList = this.startApplicationData?.creditApplicationList?.caDepartments;

        this.departmentsData = this.startApplicationData?.creditApplicationList?.caDepartments;
        const foundDepartment = this.departmentsDataList.find(department => department.departments?.departmentId === 1);
        if(foundDepartment){
          this.departmentsData = [foundDepartment]
        }
        else{
          this.departmentsData = this.departmentsDataList;
        }
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(error);
        this.loaderService.setLoadingState(false);
        this.errorMessage = error;
        this.showDanger(this.dangerTpl);
      }
    })
  }

  /**
   * toPascalCase() - to convert into pasacl case
   * @param str - the department that need to be converted to pascal case
   * @returns - a department with pascal case
   */
  toPascalCase(str: string): string {
    return str
    .split(/\s+/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
    .join(' ');
  }
  

  /**
   * selectedDepartments() - get selected departments
   */
  get selectedDepartments() {
    return (this.departmentForm.get('selectedDepartments') as FormArray);
  }

  /**
   * selectStep() - method to change the step 
   * @param step - the current step/form
   */
  selectStep(step: number) {
    this.currentStep = step;
  }

  /**
     * fetchStateMetaData() - API call to fetch the data for states
     */
  fetchStateMetaData() {
    this.creditApplicationService.getCreditApplicationMetaData().subscribe({
      next: (response: any) => {
        if (response) {
          const res = response;
          this.creditMetaData = response?.pageData?.termConditionUrlData;
          this.departments = response?.pageData?.departments;
          this.states = response?.pageData?.certOfInsuranceStates?.countryWithStates?.states;
        } else {
          console.warn('Profile data could not be retrieved.');
        }
      },
      error: (error) => {
        console.error('Error fetching user profile:', error);
      },
    });
  }

  /**
   * openEditDepartmentsModal() - Method to open the edit deaprtments modal.
   * @param editDepartmentsModal - Template reference for the edit deaprtments modal.
   */
  openEditDepartmentsModal(editDepartmentsModal: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(editDepartmentsModal, { size: 'lg', centered: true });

    this.selectedDepartments.clear();
    this.departmentsDataList.forEach((department) => {
        this.selectedDepartments.push(this.fb.control(department?.departments?.departmentId));
     
    });
  
    this.initialSelectedDepartments = JSON.parse(JSON.stringify(this.selectedDepartments.value));
  }
/**
   * toggleDepartment() - Method to toggle the edit deaprtments modal.
   * @param toggleDepartment - Template reference for the edit deaprtments modal.
   */
 toggleDepartment(departmentId: number, event: any) {
      const selectedDepartments = this.selectedDepartments; 
     
      if (event.target.checked) {
        const allDepartment = this.departments.find(department => department.departmentName === 'All Departments');
        if (departmentId === allDepartment?.departmentId) {
          this.departments.forEach(department => {
                selectedDepartments.push(this.fb.control(department.departmentId));
            });
        }else{
          selectedDepartments.push(this.fb.control(departmentId));
        }   
    } else {
      const allDepartment = this.departments.find(department => department.departmentName === 'All Departments');
      if (departmentId === allDepartment?.departmentId) {
        selectedDepartments.clear();
      }else{
        const index = selectedDepartments.controls.findIndex(
          (control) => control.value === departmentId
        );
        if (index >= 0) {
          selectedDepartments.removeAt(index);
        }
        }
      } 
    }
  
   /**
   * isSelected() - Method to check whether checkbox is selected 
   */
  
   isSelected(departmentId: number): boolean {
    const allDepartment = this.departments.find(department => department.departmentName === 'All Departments');
    
    if (departmentId === allDepartment?.departmentId) {
      const selectedDepartments = this.selectedDepartments.controls.map(control => control.value);
      return this.departments
        .filter(department => department.departmentName !== 'All Departments') // Exclude 'All' department
        .every(department => selectedDepartments.includes(department.departmentId));
    } else {
      return this.selectedDepartments.controls.some(control => control.value === departmentId);
    }
  }

  /**
   * hasChanges() - Method to check any changes in department checkbox 
   */
  hasChanges(): boolean {
    const currentSelected = this.selectedDepartments.value;
    const initialSelected = this.initialSelectedDepartments;
    // Check if the arrays have different lengths
    if (currentSelected?.length !== initialSelected?.length) {
      return true;
    }
    for (let i = 0; i < currentSelected?.length; i++) {
      if (currentSelected[i] !== initialSelected[i]) {
        return true; 
      }
    }
    return false;
  }
  
  
  /**
   * saveChanges() - Method to save departments 
   */
  saveChanges() {
    this.loaderService.setLoadingState(true);
    const selectedDeptIds = this.selectedDepartments.value;
    const payload = {
      companyId: this.companyId,
      projectId: this.projectId,
      caSeqId: this.caSeqId,
      departmentsIds: selectedDeptIds
    }
    if (payload) {
      this.editDepartmentsCallAPI(payload);
    }
  }
  /**
   * editDepartmentsCallAPI() - Method to edit departments 
   */
  editDepartmentsCallAPI(payload: any): void {
    this.creditApplicationService.editDepartments(payload).subscribe({
      next: (response: any) => {
        this.editDepartmentsResponse = response;
        this.startApplication(this.caSeqId, this.caNumber);
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
        this.modalReference.dismiss();
      },
      error: (error) => {
        console.error('Error', error);
        this.errorMessage = error.message;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * cancel() - Method to reset department form
   */
  cancel(): void {
    this.departmentForm.reset();
    this.modalReference.dismiss();
  }

  /**
 * showSuccess() - Displays a success toast notification with custom content and options.
 * @param successTpl - The template reference containing the toast's content.
 */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
  * showDanger() - Method to display a danger (error) toast message
  * @param dangerTpl - The template reference for the danger message to display
  */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
  /**
 * closeModal() - method to close the modal when called.
 */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
 * nextStep() - method to go to the next step
 */
  nextStep() {
    this.currentStep++;
  }

  /**
   * previousStep() - method to go to the previous step
   */
  previousStep() {
    this.currentStep--;
  }

  /**
   * onStepCompleted() - method to check if the step is completed
   * @param step - the completed step
   */
  onStepCompleted(step: number) {
    this.completedSteps[step - 1] = true; // Mark the current step as completed
  }

  /**
  * getRevisionForm() - the get the revision form data
  * @param caSeqId - the caseq need to be passed
  * @param caNumber - the caNumber need to be passed
  */
  getRevisionForm(): void {
    this.loaderService.setLoadingState(true);
    this.creditApplicationService.getRevisionForm(this.caSeqId, this.caNumber).subscribe({
      next: (response: any) => {
        this.revisionData = response;
        this.departmentsData = response?.caRevisionDetails?.departmentDetails;

        this.departmentsData = response?.caRevisionDetails?.departmentDetails.map((department: any) => {
          return {
            departmentName: this.toPascalCase(department.departments.departmentName)
          };
        });

        if (this.departmentsData.some(dept => dept.departmentName === 'All Departments')) {
          this.departmentsData = [{ departmentName: 'All Departments' }];
        }
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        this.errorMessage = error;
        this.showDanger(this.dangerTpl);
        this.loaderService.setLoadingState(false);
      },
    });
  }
   /**
   * OpenWarningModal() - Method to close the modal on X 
   */
   OpenWarningModal(): void {
    if(this.customerStatus === 'Finish Application' || this.customerStatus === 'Start Application' || this.customerStatus === 'NEEDS REVISIONS'){
      if(this.isFormEdited){
        this.modalService.open(this.WarningMsgModal, {
          windowClass: 'common-modal-center',
          centered: true,
          backdrop: 'static'
        });
      }
      else{
        this.activeModal.close();
      }
    }
    else{
      this.activeModal.close();
    }
    
  }

  /**
   * isformEdited() - based on form edditted the modal will show and hide
   * @param value - value that we are getting by emitting the value
   */
  isformEdited(value: boolean): void {
    this.isFormEdited = value;
  }

  ngOnDestroy() {
    localStorage.removeItem('formData');
  }
}
