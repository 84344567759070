import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RISK_EMAIL_TIER1_CONSTANTS } from '../../../constants/studio-portal.constant';

@Component({
  selector: 'app-risk-email-tier1',
  templateUrl: './risk-email-tier1.component.html',
  styleUrls: ['./risk-email-tier1.component.scss']
})
export class RiskEmailTier1Component {
  riskEmailTier1Constants = RISK_EMAIL_TIER1_CONSTANTS;
  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) { }
  helpSliderVisible: boolean = false;
  selectedAction = { text: 'Actions…', img: '' };

  actions = [
    { text: 'Accept on Behalf of SVP', img: '../../../../../assets/images/orange-email-icon.svg', src: '#' },
    { text: 'Send to SVP', img: '../../../../../assets/images/pending-icon.svg', src: '#' },
    { text: 'Deny', img: '../../../../../assets/images/approved-email.svg', src: '#' }
  ];
  updateButtonText(action: { text: string, img: string, src: string }) {
    this.selectedAction = action;
  }
  /**
   * openHelpSlider() - Opens the help slider by setting the visibility flag to true.
   */
  openHelpSlider() {
    this.helpSliderVisible = true;
  }
  /**
   * closeHelpSlider() - Closes the help slider by setting the visibility flag to false.
   */
  closeHelpSlider() {
    this.helpSliderVisible = false;
  }
  /**
   * onClose() - Closes the active modal dialog.
   */
  onClose() {
    this.activeModal.close();
  }

}
