<div class="modal-body justify-content-center align-items-center">
    <div class="cm-header-section">
        <h5 class="modal-title" id="commonModalLabel">{{creditApplicationConstants.quotesForCreditApp}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"><span
                class="cm-close-text">{{creditApplicationConstants.close}}</span></button>
    </div>
    <div class="container cm-main-container">
        <div *ngFor="let quoteGroup of caQuotesGroupDetails" class="common-modal-input-section lower-section">
            <div class="quote-card">
                <div class="upload-info">
                    <div class="uploaded-date">{{creditApplicationConstants.uploadFrom}} {{ quoteGroup.createdDate }} {{creditApplicationConstants.at}} {{ quoteGroup.createdTime }}</div>
                    <div class="uploaded-by"> {{creditApplicationConstants.by}} {{quoteGroup.createdByDetails?.firstName}} {{quoteGroup.createdByDetails?.lastName}}</div>
                </div>
                <div *ngIf="quoteGroup?.quotesComment" class="status-message">
                    {{quoteGroup.quotesComment}}
                </div>
                <div class="list-div">
                    <ul class="quote-list">
                        <li *ngFor="let quote of quoteGroup.caQuotes" class="quote-row">
                            <div class="row">
                                <div class="col-lg-6 col-md-4 col-sm-4 col-4">
                                    <div class="department">{{quote.departmentName}}</div>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4">
                                    <a class="view-quote" (click)="viewQuotes(quote?.quotesUrl,'quotes')">{{creditApplicationConstants.viewQuote}}</a>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-4 col-4 text-end">
                                    <span class="price">${{quote.quotesValue | number:'1.2-2'}}</span>
                                </div>
                            </div>
                        </li>
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>

</div>

<ng-template #dangerTpl>
    <div class="danger">{{requestResponse}}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
