import { Component, inject, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ALL_PASSES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { INDEX } from 'src/app/feature/customer-portal/enum/customer-portal.enum';
import { PassesService } from 'src/app/feature/customer-portal/services/wbd-passes/passes.service';
import { PassApiResponse } from 'src/app/shared/models/wbdpass';
import * as XLSX from 'xlsx';
import * as _ from 'lodash'
import { firstValueFrom } from 'rxjs';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-view-passes',
  templateUrl: './view-passes.component.html',
  styleUrls: ['./view-passes.component.scss'],
})
export class ViewPassesComponent implements OnInit {
  @Input() passesData: any;
  filteredRowData: any[] = [];
  passes = ALL_PASSES;
  currentDate: string;
  currentDay: string;
  selectedTab: any;
  showCheckedInOnly: boolean;
  rowData: any;
  nextDays: any[] = [];
  todayDate: string;
  index = INDEX;
  filtered: any;
  data: any;
  selectedPassType: string;
  count: any;
  allPasses: any;
  driveOn: any;
  walkOn: any;
  showCounts: boolean= false;
  driveOnCount: boolean = false;
  walkOnCount: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private passesservice: PassesService,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    const today = new Date();
    this.todayDate = this.formatDate(today);
    this.selectedTab = {
      date: this.todayDate,
      isToday: true,
    };
    this.nextDays = this.getNextDays(today);
    this.filterData(this.todayDate);
  }

  /**
   * getNextDays() - to get the next four days values
   * @param today - passin today to get next 4 days
   * @returns - next four days
   */
  getNextDays(today: Date): string[] {
    const daysOfWeek = this.passes.days;
    const nextDays = [];
    for (let i = this.index.zero; i < this.index.five; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);
      nextDays.push({
        day: daysOfWeek[nextDay.getDay()],
        date: this.formatDate(nextDay),
        isToday: i === this.index.zero,
      });
    }
    return nextDays;
  }

  /**
   * formatDate() - the date will be formatted
   * @param date - the date need to be formatted
   * @returns - a formatted data
   */
  formatDate(date: Date): string {
    const month = date.getMonth() + this.index.one;
    const day = date.getDate();
    return `${month}/${day}`;
  }

  convertToFullDate(date: string): string {
    const today = new Date();
    const [month, day] = date?.split('/')?.map((num) => parseInt(num));
    const year = today?.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;
  }

  /**
   * closeModal() - Closes the modal.
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * convertTo12HourFormat() - extracting time
   * @param time - time that need to be chnaged
   * @returns - time converted to 12 hours
   */
  convertTo12HourFormat(time: string): string {
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12;
    const strMinutes = minute < 10 ? '0' + minute : minute.toString();
    return `${hour}:${strMinutes} ${ampm}`;
  }

  /**
   * applyInitialFilter() - Initially filter the data to show only "CHECKED IN" passes
   */
  applyInitialFilter(data: any): void {
    this.showCheckedInOnly = true;

    this.filteredRowData = _.map(
      _.filter(data, (pass) => pass.Status === this.passes.checkedInStatus),
      (pass) => ({
        entry_type: pass.EntryType,
        name: pass.VisitorName,
        organization: pass.OrganizationName,
        active: pass.PassActive,
        expires: pass.PassExpiry,
        arrival: pass.PassArrival,
        departure: pass.PassDeparture,
        entry_point: pass.EntryPoint || '---',
        building: pass.Building,
        status: pass.Status,
        time: pass.Time ? this.convertTo12HourFormat(pass.Time) : '',
      })
    );

    this.rowData = this.filteredRowData;
  }

  /**
   *toggleCheckedInStatus() -  Filter the data based on the toggle state
   */
  toggleCheckedInStatus(event: any): void {
    this.showCheckedInOnly = event.target.checked;
    if (this.showCheckedInOnly) {
      this.filterData(this.selectedTab.date);
    } else {
      this.filterData(this.selectedTab.date);
    }
  }

  /**
   * selectTab() - This method is used to set the currently selected tab.
   * @param tab - The identifier for the tab that is being selected. This could be a string
   */
  selectTab(tab: string) {
    this.selectedTab = tab;
    this.selectedPassType ='';
    this.filterData(this.selectedTab.date);
  }

  /**
   *
   * @param selectedDate - to filter the data based on date
   * @param isTabChange - to check the count based on isTabChange
   */
  filterData(selectedDate: string): void {
    const fullSelectedDate = this.convertToFullDate(selectedDate);
    this.filtered = _.filter(this.passesData, (item) => {
      let itemDate = item.PassActive;
      return itemDate === fullSelectedDate;
    });
    this.updatePassCounts();
    if (this.selectedPassType) {
      this.filtered = _.filter(this.filtered, (item) => item.EntryType === this.selectedPassType);
    }
    

    if (this.showCheckedInOnly) {
      this.data = this.filtered;
      this.applyInitialFilter(this.data);
    } else {
      this.data = this.filtered;
      this.getAllPasses(this.data);
    }
  }

  /**
   * updatePassCounts() - updates passes count when clicked on it
   */
  updatePassCounts(): void {
    this.allPasses = this.filtered?.length;
    this.walkOn = _.filter(
      this.filtered,
      (item) => item.EntryType === this.passes.walk_on
    )?.length;
  
    this.driveOn = _.filter(
      this.filtered,
      (item) => item.EntryType === this.passes.drive_on
    )?.length;
  }
  

  /**
   * onDriveOnClick() - to get the count when clicked drive on when clicked
   */
  onDriveOnClick(): void {
    this.selectedPassType = this.passes.drive_on;
    this.filterData(this.selectedTab.date); 
  }

  /**
   * onWalkOnClick() - to get the count of walk on when clicked
   */
  onWalkOnClick(): void {
    this.selectedPassType = this.passes.walk_on;
    this.filterData(this.selectedTab.date); 
  }

  /**
   * onAllPass() - on click of allPass all data will be filtered
   */
  onAllPass(){
    this.selectedPassType = '';
    this.filterData(this.selectedTab.date); 
  }


  /**
   * onSelectChange() - This method is triggered when the user selects a new option from a dropdown/select element.
   * @param event - The event object triggered by the change in the select element.
   */
  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedDate = selectElement.value;
    this.selectedTab = this.nextDays.find((day) => day.date === selectedDate);
    this.filterData(this.selectedTab.date);
  }

  private modalService = inject(NgbModal);
  openSelectCompany(company: TemplateRef<any>) {
    this.modalService.open(company, {
      windowClass: 'common-modal-center',
      centered: true,
    });
  }

  /**
   * getAllPasses() - to get all data
   */
  getAllPasses(data: any) {
    const passesData = data.map((pass) => {
      return {
        entry_type: pass.EntryType,
        name: pass.VisitorName,
        organization: pass.OrganizationName,
        active: pass.PassActive,
        expires: pass.PassExpiry,
        arrival: pass.PassArrival,
        departure: pass.PassDeparture,
        entry_point: pass.EntryPoint || '---',
        building: pass.Building,
        status: pass.Status ? pass.Status :'',
        time: pass.Time ? this.convertTo12HourFormat(pass.Time) : '',
      };
    });
    this.rowData = passesData;
  }

  /**
   * getPasses() - to get the passes on refresh
   */
  getPasses(): void {
    this.loaderService.setLoadingState(true)
    const userProfile = JSON.parse(
      localStorage.getItem('user-profile') || '{}'
    );
    const body = {
      Email: userProfile.emailAddress,
      TableName: 'Pass',
      Key: null,
      ScreenName: 'GreenLight',
      SiteID: 1,
      ObjectID: null,
      ParentObjectField: null,
      LimitNeeded: true,
    };
    this.passesservice.getPasses(body).subscribe({
      next: (response: PassApiResponse) => {
        this.loaderService.setLoadingState(false)
        if (response) {
          this.data = response.wbdPassApiResponse;
          this.passesData = this.data;
          this.filterData(this.selectedTab.date);
        } else {
          console.warn('Warning: Response is empty.');
        }
      },
      error: (error: any) => {
        console.error(error);
        this.loaderService.setLoadingState(true)
      },
    });
  }
  
  

  /**
   * exportToExcel() - Export the data to Excel.
   * Depending on the showCheckedInOnly flag, export the filtered data or all data.
   */
  exportToExcel() {
    const dataToExport = this.showCheckedInOnly
      ? this.filteredRowData
      : this.rowData;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    XLSX.writeFile(wb, 'passes.xlsx');
  }

  colDefs: ColDef[] = [
    {
      headerName: 'ENTRY TYPE',
      field: 'entry_type',
      headerTooltip:'ENTRY TYPE',
      autoHeight: true,
      cellRenderer: this.entryTypeRenderer.bind(this),
      minWidth: 140,
      cellStyle: {
        paddingBottom: '1.5rem',
        paddingRight: '10px',
        paddingLeft: '24px',
        paddingTop: '24px',
      },
    },
    {
      headerName: 'NAME',
      field: 'name',
      headerTooltip:'NAME',
      minWidth: 120,
      headerClass: 'name',
      cellClass: 'common-row-padding common-cell-data-bold',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
    },
    {
      headerName: 'ORG.',
      field: 'organization',
      headerTooltip:'ORG.',
      filter: false,
      minWidth: 120,
      headerClass: 'name',
      cellClass: 'common-row-padding-new ',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
    },
    {
      headerName: 'ACTIVE',
      field: 'active',
      headerTooltip:'ACTIVE',
      minWidth: 100,
      headerClass: 'name',
      cellClass: 'common-row-padding',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
    },
    {
      headerName: 'EXPIRES',
      field: 'expires',
      headerTooltip:'EXPIRES',
      minWidth: 110,
      headerClass: 'name',
      cellClass: 'common-row-padding',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
    },
    {
      headerName: 'ARRIVAL',
      field: 'arrival',
      headerTooltip:'ARRIVAL',
      wrapText: true,
      autoHeight: true,
      minWidth: 110,
      cellClass: 'common-row-padding',
      headerClass: 'name',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      field: 'departure',
      headerName: 'DEPARTURE',
      headerTooltip:'DEPARTURE',
      minWidth: 100,
      cellClass: 'common-row-padding',
      headerClass: 'name',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      field: 'entry_point',
      headerName: 'ENTRY POINT',
      headerTooltip:'ENTRY POINT',
      minWidth: 100,
      cellClass: 'common-row-padding',
      headerClass: 'custom-header-adjusting',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName: 'BUILDING',
      field: 'building',
      headerTooltip:'BUILDING',
      minWidth: 100,
      cellClass: 'common-row-padding',
      headerClass: 'custom-header-adjusting',
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
      // cellStyle:{paddingRight:'0px',paddingLeft:'10px'}
    },
    {
      headerName: 'STATUS',
      field: 'status',
      headerTooltip:'STATUS',
      minWidth: 150,
      cellClass: 'common-row-padding',
      headerClass: 'custom-header-adjusting',
      cellRenderer: this.statusCellRenderer.bind(this),
      // cellStyle:{paddingRight:'10px',paddingLeft:'10px'}
    },
    {
      headerName: '',
      field: 'time',
      minWidth: 90,
      cellStyle: { padding: '24px 24px 24px 0px' },
      cellRenderer: params => {
        return `<span title="${params.value}">${params.value}</span>`;
      }
      // cellClass:'common-row-padding',
    },
    // {
    //   field:'merged-first-field',
    //   headerName:'',
    //   cellRenderer:this.mergedFieldRenderer.bind(this)

    // },
    {
      field: 'merged-second-field',
      headerName: '',
      cellClass: 'second-field',
      cellRenderer: this.thirdMergedFieldRenderer.bind(this),
      cellStyle: {
        padding: '0px',
      },
    },
  ];

  entryTypeRenderer(params: ICellRendererParams) {
    const entryType = params.value;
    const imageSrc =
      entryType === this.passes.walk_on
        ? 'assets/images/man.svg'
        : entryType === this.passes.drive_on
        ? 'assets/images/car.svg'
        : 'assets/images/default.svg';

    return `
      <span class="request-cell">
        <img src="${imageSrc}" alt="Entry Type Icon" class="entry-icon" />
        <span title=${entryType} class="request-count">${entryType}</span>
      </span>`;
  }

  statusCellRenderer(params: ICellRendererParams) {
    const value = (params?.value)?((params.value).toUpperCase()):'';
    const statusClass = this.getStatusClass(value);
    return `
         <div class="view-five-passes">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
        </div>
    `;
  }

  getStatusClass(status: string): string {
   
      switch (status) {
      case 'CHECKED IN':
        return 'checked-in account-user-common-status-cell checked-in-width';
      case 'CHECKED OUT':
        return 'checked-out account-user-common-status-cell checked-out-width';
      default:
        return '';
    }
  }

  thirdMergedFieldRenderer(params: ICellRendererParams) {
    const value = (params.data.status)?((params.data.status).toUpperCase()):'';
    const entryType = params.data.entry_type;
    const name = params.data.name;
    const gate = params.data.entry_point || '---';
    const building = params.data.building;
    const organization = params.data.organization;
    const status = params.data.status;
    const time = params.data.time;
    const statusClass = this.getStatusClass(value);

    const imageSrc =
      entryType === this.passes.walk_on
        ? 'assets/images/man.svg'
        : entryType === this.passes.drive_on
        ? 'assets/images/car.svg'
        : 'assets/images/default.svg';

    return `
      <div class="third-merged-field">
        <div class="first-row">
          <span class="entry-type-row">
            <img src="${imageSrc}" alt="Entry Type Icon" class="entry-icon" />
            <span class="entry-type-text">${entryType}</span>
          </span>
          <span class="status-time">
          <span class="request-status-tag ${statusClass}">${status}</span>
          <span class="time-text">${time}</span>
          </span>
        </div>
        
        <div class="second-row">
          <span class="name-text common-cell-data-bold">${name}</span>
          <span class="organization-text">${organization}</span>
        </div>
  
        <div class="third-row">
          <span class="gate-text">${gate}</span>
          <span >-</span>
          <span class="building-text">${building}</span>
        </div>
      </div>
    `;
  }

  colsForSmallScreen = ['merged-second-field'];
  colsForLargeScreen = ['merged-second-field'];
  colsForMediumScreen = ['merged-second-field'];
  colsForExtraLargeScreen = [
    'entry_type',
    'name',
    'organization',
    'active',
    'expires',
    'arrival',
    'departure',
    'entry_point',
    'building',
    'status',
    'time',
  ];
}