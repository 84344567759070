import { Component, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-third-party-comm-house',
  templateUrl: './third-party-comm-house.component.html',
  styleUrls: ['./third-party-comm-house.component.scss']
})
export class ThirdPartyCommHouseComponent {
  modalReference!: NgbModalRef;
  constructor(private modalService: NgbModal,){}
  openContentModal(content: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(content, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }
  openContentModalOneTC(contentOne: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(contentOne, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }

  openContentModalTwoDB(contentTwo: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(contentTwo, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }
  openContentModalThreeCOI(contentThree: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(contentThree, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }
  openContentModalFourApp(contentFourApp: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(contentFourApp, {
      windowClass: 'common-modal-center',
      size: 'lg',
      centered: true,
    });
  }
}
