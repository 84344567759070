<div class="project-resource-wrapper wrapper-left ">
    <div class="all-project-status-widget-block">
      <div class="card px-0">
        <div class="row">
          <div class="col-12 px-0">
            <span class="project-status-tag active-tag-green">Active</span>
          </div>
          <div class="col-12 col-sm-3 col-md-4 col-lg-12 project-info-section">
            <p class="project-info">Project Name: <span class="project-value">{{ projectName }}</span></p>
          </div>
          <div class="col-12 col-sm-3 col-md-4 col-lg-12 project-info-section">
            <p class="project-info">User Name: <span class="project-value">{{ userName }}</span></p>
          </div>
          <div class="col-12 col-sm-3 col-md-4 col-lg-12 project-info-section ">
            <p class="project-info d-block">Company Name: <span class="project-value ">{{ companyName }}</span></p>
          </div>
          <div class="col-12 col-sm-3 col-md-4 col-lg-12 project-info-section">
            <p class="project-info">Project Id: <span class="project-value">{{projectId}}</span></p>
          </div>
        
        
        </div>
      </div>
    </div>
  </div>
  