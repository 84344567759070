<div>
    <span *ngFor="let car of displayedDept; let i = index">
      {{ car }}<span *ngIf="i < displayedDept?.length - 1">; </span>
    </span>
    <span *ngIf="hiddenDept?.length > 0">
      <span
        ngbTooltip="{{ hiddenDept.join('; ') }}"
        triggers="hover"
        class="department-hidden"
      >
        +{{ hiddenDept?.length }}
      </span>
    </span>
  </div>