<form [formGroup]="customerForm">
    <div class="modal-content">
        <div class="modal-body justify-content-center align-items-center">
            <div class="cm-header-section">
                <div *ngIf="currentStep === 1" class="cm-header-title text-center mb-5">
                    <h5 class="modal-title mb-2" id="commonModalLabel">{{ credAppConstants.creditApplicationForm }}</h5>
                    <p class="modal-title-desc">{{ credAppConstants.credAppSubtext }}</p>
                </div>
                <div *ngIf="currentStep === 2" class="cm-header-title text-center mb-5">
                    <h5 class="modal-title mb-2" id="commonModalLabel">{{ credAppConstants.certificateOfInsurance }}
                    </h5>
                    <p class="modal-title-desc">{{ credAppConstants.coiSubtextOne }}<br>
                        {{ credAppConstants.coiSubTextTwo }}</p>
                </div>
                <div *ngIf="currentStep === 3" class="cm-header-title text-center mb-5">
                    <h5 class="modal-title mb-2" id="commonModalLabel">{{ credAppConstants.termsAndConditions }}</h5>
                    <p class="modal-title-desc">{{ credAppConstants.tncSubText }}</p>
                </div>
                <div *ngIf="currentStep === 4" class="cm-header-title text-center mb-5">
                    <h5 class="modal-title" id="commonModalLabel">{{ credAppConstants.reviewApplication }}</h5>
                    <p class="cm-subtitle mb-5">{{ credAppConstants.reviewSubtext }}</p>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="OpenWarningModal()"><span class="cm-close-text">{{ credAppConstants.close }}</span></button>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                        <div class="modal-left-section me-4">
                            <h4 class="common-left-section-title">Credit Application</h4>
                            <div class="company-meta-data">
                                <p class="title mb-0"><strong class="common-title">PROJECT:</strong>
                                    <span class="common-content">{{ projectName }}</span>
                                </p>
                                <div id="departments-container">
                                    <p class="title mb-0"><strong class="common-title">Departments:</strong></p>
                                    <p class="common-content" *ngIf="departmentsData && (customerStatus == 'NEEDS REVISIONS' || customerStatus == 'REVISIONS SUBMITTED')">
                                        <span *ngFor="let department of departmentsData; let last = last">
                                            {{ department?.departmentName }}<span *ngIf="!last">; </span>
                                        </span>
                                    </p>
                                    <p class="common-content" *ngIf="departmentsData && (customerStatus !== 'NEEDS REVISIONS' && customerStatus !== 'REVISIONS SUBMITTED')">
                                        <span *ngFor="let department of departmentsData; let last = last">
                                            {{ department?.departments?.departmentName }}<span *ngIf="!last">; </span>
                                        </span>
                                    </p>
                                </div>
                                <div class="d-flex flex-column mt-2" *ngIf="customerStatus == 'Start Application' || customerStatus == 'Finish Application'">
                                    <span class=" mb-1 d-flex gap-1" (click)="customerStatus !== 'UNDER REVIEW' && customerStatus !== 'NEEDS REVISIONS' ? openEditDepartmentsModal(editDepartmentsModal) : $event.preventDefault()"><a
                                        class="text-link">Edit departments</a> <img src="../assets/images/edit-icon.svg"
                                            alt="Edit Department"></span>
                                </div>
                            </div>
                            <div class="mb-4 mt-3">
                                <div class="list-group list-group-flush mb-2">
                                    <ul class="step-list">
                                        <li>
                                            <span class="step-icon"
                                                [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === 'Not Started' || startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === null || revisionData?.caRevisionDetails?.applicationFormDetails?.formStatusDetails?.status === null,
                                            'step-check-grey': startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === 'Pending' || revisionData?.caRevisionDetails?.applicationFormDetails?.formStatusDetails?.status === 'Needs Revision',
                                            'step-check': startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status === 'Completed' || revisionData?.caRevisionDetails?.applicationFormDetails?.formStatusDetails?.status === 'Completed'}"></span>
                                            <span class="step-text" [ngClass]="{'current-step': currentStep === 1}"
                                                (click)="selectStep(1)"> Application
                                                Form</span>
                                        </li>
                                        <li>
                                            <span class="step-icon"
                                                [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === 'Not Started' || startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === null || revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.formStatusDetails?.status === null,
                                            'step-check-grey': startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === 'Pending' || revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.formStatusDetails?.status === 'Needs Revision',
                                            'step-check': startApplicationData?.creditApplicationList?.certificateOfInsurance?.caFormStatus?.status === 'Completed' || revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.formStatusDetails?.status === 'Completed'}"></span>
                                            <span class="step-text" [ngClass]="{'current-step': currentStep === 2}"
                                                (click)="selectStep(2)">
                                                Certification of Insurance</span>
                                        </li>
                                        <li>
                                            <span class="step-icon"
                                                [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === 'Not Started' || startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === null || revisionData?.caRevisionDetails?.termsAndConditionsDetails?.formStatusDetails?.status === null,
                                            'step-check-grey': startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === 'Pending' || revisionData?.caRevisionDetails?.termsAndConditionsDetails?.formStatusDetails?.status === 'Needs Revision',
                                            'step-check': startApplicationData?.creditApplicationList?.termAndCondition?.caFormStatus?.status === 'Completed' || revisionData?.caRevisionDetails?.termsAndConditionsDetails?.formStatusDetails?.status === 'Completed'}"></span>
                                            <span class="step-text" [ngClass]="{'current-step': currentStep === 3}"
                                                (click)="selectStep(3)">Terms &
                                                Conditions</span>
                                        </li>
                                        <li>
                                            <span class="step-icon"
                                                [ngClass]="{
                                            'step-uncheck': startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Not Started' || startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === null || revisionData?.caRevisionDetails?.reviewApplicationStatusDetails?.formSatusDetails?.status === null,
                                            'step-check-grey': startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Pending' || revisionData?.caRevisionDetails?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Needs Revision',
                                            'step-check': startApplicationData?.creditApplicationList?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Completed' ||  revisionData?.caRevisionDetails?.reviewApplicationStatusDetails?.formSatusDetails?.status === 'Completed'}"></span>
                                            <span class="step-text" [ngClass]="{'current-step': currentStep === 4}"
                                                (click)="selectStep(4)">Review
                                                Application</span>
                                        </li>
                                    </ul>
                                </div>
                                <p class="required-text">(<span class="star-red">*</span>)Required</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
                        <app-cred-app-step-one frmGroupName="stepOneFormGroup" *ngIf="currentStep === 1"
                            [states]="states" (formSubmittedEvent)="statusChanges()" [projectId]="projectId"
                            [revisionData]="revisionData" [customerStatus]="customerStatus"
                            [startApplicationData]="startApplicationData" [caSeqId]="caSeqId" [caNumber]="caNumber"
                            (nextStepEvent)="nextStep()" (stepCompleted)="onStepCompleted(currentStep)"
                            (saveAndCloseEvent)="closeModal()" [creditAppStatus]="creditAppStatus"
                            [companyId]="companyId" (stepOneFormChange)="isformEdited($event)"></app-cred-app-step-one>
                        <app-certificate-of-insurance frmGroupName="stepTwoFormGroup" *ngIf="currentStep === 2"
                            [states]="states" (formSubmittedEvent)="statusChanges()"
                            [startApplicationData]="startApplicationData" [revisionData]="revisionData"
                            [customerStatus]="customerStatus" (nextStepEvent)="nextStep()"
                            (prevStepEvent)="previousStep()" (saveAndCloseEvent)="closeModal()"
                            (stepCompleted)="onStepCompleted(currentStep)" [caSeqId]="caSeqId" [caNumber]="caNumber"
                            [creditAppStatus]="creditAppStatus" (stepOneFormChange)="isformEdited($event)"></app-certificate-of-insurance>
                        <app-cred-app-step-three frmGroupName="stepThreeFormGroup" *ngIf="currentStep === 3"
                            [creditMetaData]="creditMetaData" (formSubmittedEvent)="statusChanges()"
                            [customerStatus]="customerStatus" [startApplicationData]="startApplicationData"
                            [creditAppStatus]="creditAppStatus" [revisionData]="revisionData"
                            (nextStepEvent)="nextStep()" (prevStepEvent)="previousStep()" [caSeqId]="caSeqId"
                            [caNumber]="caNumber" (saveAndCloseEvent)="closeModal()" (stepOneFormChange)="isformEdited($event)"></app-cred-app-step-three>
                        <app-cred-app-step-four frmGroupName="stepFourFormGroup" *ngIf="currentStep === 4"
                            [startApplicationData]="startApplicationData" (formSubmittedEvent)="statusChanges()"
                            [caSeqId]="caSeqId" [caNumber]="caNumber" [customerStatus]="customerStatus"
                            (prevStepEvent)="previousStep()" (saveAndCloseEvent)="closeModal()"
                            [revisionData]="revisionData"></app-cred-app-step-four>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<ng-template #editDepartmentsModal let-modal>
    <div class="page-wrapper-modal">
        <div class="refrence-letter-section">
            <div class="container cm-main-container">
                <div class="row">
                    <h3 class="checkbox-heading mb-5">{{credFormDepartmentConstant.credDepartmentFormTitle}}</h3>
                    <div class="col-md-6 col-lg-4 col-sm-6 col-12 mb-4" [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-12': first}"  *ngFor="let department of departments;let first = first">
                        <div class="common-checkbox-button" role="checkbox"
                            [attr.aria-checked]="isSelected(startApplicationData?.creditApplicationList?.caDepartments)">
                            <input type="checkbox" [id]="'custom-checkbox-' + department.departmentId"
                                class="common-checkbox-input" [value]="department.departmentId"
                                (change)="toggleDepartment(department.departmentId, $event)"
                                [checked]="isSelected(department.departmentId)" />
                            <label  class="common-checkbox-label">
                                {{ department.departmentName }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="save-btn-wrapper">
            <div class="d-flex flex-row align-items-center g-3">
                <button class="common-btn-light btn" (click)="cancel()">{{ credFormDepartmentConstant.cancel }}</button>
                <button class="common-btn-secondary btn save-btn" (click)="saveChanges()"
                    [disabled]="!hasChanges() || !selectedDepartments?.length">{{ credFormDepartmentConstant.saveChanges }}</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #WarningMsgModal let-modal>
    <div class="modal-body">
      <h4 class="section-main-header">{{ confirmClosePopup.confirmCreditAppCloseMsg }}</h4>
      <div class="d-flex justify-content-end gap-2">
        <button type="button" class="common-btn-secondary btn" (click)="closeModal();modal.close()">
          {{ confirmClosePopup.yesBtn }}
        </button>
        <button type="button" class="common-btn-light btn" (click)="modal.close()">
          {{ confirmClosePopup.noBtn }}
        </button>
      </div>
    </div>
    </ng-template>
<ng-template #successTpl>
    <div class="success">{{ credFormDepartmentConstant.deptEditSuccess }}</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{ errorMessage }}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>