<div>
  <button
    class="btn btn-primary"
    (click)="openDeleteConfirmationModal(content)"
  >
    hello
  </button>
</div>
<ng-template #content let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">Approval Delegation</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-3 ps-0">
          <div class="custom-floating-label">
            <label for="input1" class="form-label form-label-for-select">
              Appprove Delegate
            </label>

            <div class="custom-dropdown w-100">
              <select class="custom-select">
                <option
                  class="dropdown-option"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Select
                </option>
                <option class="dropdown-option" value="type1">Sophia Martinez</option>
                <option class="dropdown-option" value="type2">Andrea Noj</option>
                <option class="dropdown-option" value="type3">Alejandro Aguilar</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="custom-floating-label">
            <label for="input1" class="form-label form-label-for-select">
              Appprove Delegate
            </label>

            <div class="custom-dropdown w-100">
              <select class="custom-select">
                <option
                  class="dropdown-option"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Select
                </option>
                <option class="dropdown-option" value="type1">Type 1</option>
                <option class="dropdown-option" value="type2">Type 2</option>
                <option class="dropdown-option" value="type3">Type 3</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Approve Delegate From</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder="Start Date"
              id="date"
            />
          </div>
        </div>
        <div class="col-3 pe-0">
          <div class="custom-floating-label" id="datepicker ">
            <label for="input1" class="form-label"
              >Approve Delegate From</label
            >
            <input
              type="date"
              class="form-control d-block"
              placeholder="Start Date"
              id="date"
            />
          </div>
        </div>
      </div>
      <div class="adding-deligate">
        <span class="text-link">+ <span>Add Delegate</span></span>
      </div>
    </div>
    <div class="delegations">
      <h4 class="mb-0">Scheduled Delegations</h4>
      <div class="del">
      <div class="delegation">
        <span class="scheduled-delegations">
          <span class="delegation-name"
            >From<strong>Sophia Martinez</strong>to<strong
              >Andrea Noj</strong
            ></span
          >
          <span class="delegation-time"
            >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
          >
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </span>
      </div>
      <div class="delegation">
        <span class="scheduled-delegations">
          <span class="delegation-name"
            >From<strong>Sophia Martinez</strong>to<strong
              >Andrea Noj</strong
            ></span
          >
          <span class="delegation-time"
            >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
          >
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </span>
      </div>
      <div class="delegation">
        <span class="scheduled-delegations">
          <span class="delegation-name"
            >From<strong>Sophia Martinez</strong>to<strong
              >Andrea Noj</strong
            ></span
          >
          <span class="delegation-time"
            >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
          >
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </span>
      </div>
    </div>
    </div>
    <div class="delegations">
      <h4 class="mb-0">PAST DELEGATIONS</h4>
      <div class="del">
      <div class="delegation">
        <span class="scheduled-delegations">
          <span class="delegation-name"
            >From<strong>Sophia Martinez</strong>to<strong
              >Andrea Noj</strong
            ></span
          >
          <span class="delegation-time"
            >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
          >
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </span>
      </div>
      <div class="delegation">
        <span class="scheduled-delegations">
          <span class="delegation-name"
            >From<strong>Sophia Martinez</strong>to<strong
              >Andrea Noj</strong
            ></span
          >
          <span class="delegation-time"
            >Effective Date&nbsp;: 12/10/2024 to 12/12/2024</span
          >
          <span class="remove-contact remove-contact-blue"> Remove</span>
        </span>
      </div>
    </div>
    </div>
    <div class="buttons">
      <button class="common-btn-light btn">Cancel</button>
      <button class="common-btn-secondary btn">Save</button>
    </div>
  </div>
</ng-template>
