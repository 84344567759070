<div>
    <div class="page-wrapper-modal">
      <div class="page-header">
      </div>
      <div class="refrence-letter-section">
        <div class="container cm-main-container">
            <div class="row">
              <h2 class="checkbox-heading mb-3" >Add Department</h2>
              <form [formGroup]="departmentForm">
                <div class="row">
                    <div class="col-md-4 col-lg-4 col-sm-6 col-6 mb-3"
                       *ngFor="let dept of deptData; let first = first">
                        <div class="common-checkbox-button">
                            <input (click)="addDept(dept.departmentId,$event)" type="checkbox"
                                [id]="'dept-' + dept.departmentId" class="common-checkbox-input"
                                [formControlName]="dept.departmentId.toString()" 
                                [disabled]="departmentForm.get(dept.departmentId.toString())?.disabled">
                            <label [for]="'dept-' + dept.departmentId" class="common-checkbox-label">
                                {{dept.departmentName}}
                            </label>
                        </div>
                    </div>
                </div>
          </form>
      <div class="save-btn-wrapper">
      <div class="d-flex flex-row align-items-center g-3">
        <button class="common-btn-light btn "(click)="onCancel()">Cancel</button>
        <button class="common-btn-secondary btn save-btn"(click)='onSaveDepartments()'>Save</button>
      </div>
    </div>
    </div>
</div>
