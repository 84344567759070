<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
                <h1 class="page-title px-0 mb-0">Handbook</h1>
                <div class="d-flex align-items-center view-text ">
                    <div>
                        <p class="me-3 mb-0">You are viewing the Handbook for</p>
                    </div>
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="assets/images/location-icon.svg" alt="Icon" class="dropdown-icon">Burbank
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item" href="#">Action 1</a></li>
                            <li><a class="dropdown-item" href="#">Action 2</a></li>
                            <li><a class="dropdown-item" href="#">Action 3</a></li>
                        </ul>
                    </div>
                </div>
            </div>
			
			<div class="col-md-4 col-lg-4  d-none d-md-block ">
				<ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
					<li
					  class="nav-link"
					  id="office-production-tab"
					  role="tab"
					  aria-controls="office-production-content"
					  aria-selected="true"
					  (click)="selectTab('office-production-tab')"
					  data-bs-toggle="collapse"
					  href="#collapseOne"
					  role="button"
					  aria-expanded="false"
					  aria-controls="collapseOne"
					>
					  <span class="list-number">1.</span> Office & Production Services (OPS)
					</li>
					<div class="collapse" id="collapseOne">
					  <ul class="handbook-sub-list scrollable-container">
						<li
						  class="nav-link"
						  id="office-production-0-tab"
						  data-bs-toggle="pill"
						  href="#office-production-0-tab"
						  role="tab"
						  aria-controls="office-production-0-tab"
						  aria-selected="false"
						  (click)="selectTab('office-production-0-tab')"
						>
						  <span class="list-number">1.0</span> Overview
						</li>
						<li
						  class="nav-link"
						  id="office-production-1-tab"
						  data-bs-toggle="pill"
						  href="#office-production-1-tab"
						  role="tab"
						  aria-controls="office-production-1-tab"
						  aria-selected="false"
						  (click)="selectTab('office-production-1-tab')"
						>
						  <span class="list-number">1.1</span> Sound Stage Chart
						</li>
						<li
						  class="nav-link"
						  id="office-production-2-tab"
						  data-bs-toggle="pill"
						  href="#office-production-2-tab"
						  role="tab"
						  aria-controls="office-production-2-tab"
						  aria-selected="false"
						  (click)="selectTab('office-production-2-tab')"
						>
						  <span class="list-number">1.2</span> Stage Check-In and Check-Out
						</li>
					  <li
						class="nav-link"
						id="office-production-3-tab"
						data-bs-toggle="pill"
						href="#office-production-3-tab"
						role="tab"
						aria-controls="office-production-3-tab"
						aria-selected="false"
						(click)="selectTab('office-production-3-tab')"
					  >
						<span class="list-number">1.3</span> General Stage Housekeeping
					  </li>
					  <li
						class="nav-link"
						id="office-production-4-tab"
						data-bs-toggle="pill"
						href="#office-production-4-tab"
						role="tab"
						aria-controls="office-production-4-tab"
						aria-selected="false"
						(click)="selectTab('office-production-4-tab')"
					  >
						<span class="list-number">1.4</span> Hot Lock & Stage Card Access Procedures
					  </li>
					  <li
						class="nav-link"
						id="office-production-5-tab"
						data-bs-toggle="pill"
						href="#office-production-5-tab"
						role="tab"
						aria-controls="office-production-5-tab"
						aria-selected="false"
						(click)="selectTab('office-production-5-tab')"
					  >
						<span class="list-number">1.5</span> Stage Specs
					  </li>
					  <li
						class="nav-link"
						id="office-production-6-tab"
						data-bs-toggle="pill"
						href="#office-production-6-tab"
						role="tab"
						aria-controls="office-production-6-tab"
						aria-selected="false"
						(click)="selectTab('office-production-6-tab')"
					  >
						<span class="list-number">1.6</span> Overhead Loading – Roof Trusses & Permanent Beams
					  </li>
					  <li
						class="nav-link"
						id="office-production-7-tab"
						data-bs-toggle="pill"
						href="#office-production-7-tab"
						role="tab"
						aria-controls="office-production-7-tab"
						aria-selected="false"
						(click)="selectTab('office-production-7-tab')"
					  >
						<span class="list-number">1.7</span> Permanent Construction Requests
					  </li>
					  <li
						class="nav-link"
						id="office-production-8-tab"
						data-bs-toggle="pill"
						href="#office-production-8-tab"
						role="tab"
						aria-controls="office-production-8-tab"
						aria-selected="false"
						(click)="selectTab('office-production-8-tab')"
					  >
						<span class="list-number">1.8</span> Stage Lighting Guidelines
					  </li>
					  <li
						class="nav-link"
						id="office-production-9-tab"
						data-bs-toggle="pill"
						href="#office-production-9-tab"
						role="tab"
						aria-controls="office-production-9-tab"
						aria-selected="false"
						(click)="selectTab('office-production-9-tab')"
					  >
						<span class="list-number">1.9</span> Stage Dressing Rooms
					  </li>
					  <li
						class="nav-link"
						id="office-production-10-tab"
						data-bs-toggle="pill"
						href="#office-production-10-tab"
						role="tab"
						aria-controls="office-production-10-tab"
						aria-selected="false"
						(click)="selectTab('office-production-10-tab')"
					  >
						<span class="list-number">1.10</span> Production Guidelines – Exterior Sets
					  </li>
					  <li
						class="nav-link"
						id="office-production-11-tab"
						data-bs-toggle="pill"
						href="#office-production-11-tab"
						role="tab"
						aria-controls="office-production-11-tab"
						aria-selected="false"
						(click)="selectTab('office-production-11-tab')"
					  >
						<span class="list-number">1.11</span> Notification Policy For Surrounding Neighborhoods
					  </li>
					  <li
						class="nav-link"
						id="office-production-12-tab"
						data-bs-toggle="pill"
						href="#office-production-12-tab"
						role="tab"
						aria-controls="office-production-12-tab"
						aria-selected="false"
						(click)="selectTab('office-production-12-tab')"
					  >
						<span class="list-number">1.12</span> Daily Call Sheets | Prod. Schedules | Staff & Crew
					  </li>
					  <li
						class="nav-link"
						id="office-production-13-tab"
						data-bs-toggle="pill"
						href="#office-production-13-tab"
						role="tab"
						aria-controls="office-production-13-tab"
						aria-selected="false"
						(click)="selectTab('office-production-13-tab')"
					  >
						<span class="list-number">1.13</span> Radio Frequency Equipment – Prod. Sound & Video
					  </li>
					  <li
						class="nav-link"
						id="office-production-14-tab"
						data-bs-toggle="pill"
						href="#office-production-14-tab"
						role="tab"
						aria-controls="office-production-14-tab"
						aria-selected="false"
						(click)="selectTab('office-production-14-tab')"
					  >
						<span class="list-number">1.14</span> Camera Policy & Press/Publicity Access Procedures
					  </li>
					  <li
						class="nav-link"
						id="office-production-15-tab"
						data-bs-toggle="pill"
						href="#office-production-15-tab"
						role="tab"
						aria-controls="office-production-15-tab"
						aria-selected="false"
						(click)="selectTab('office-production-15-tab')"
					  >
						<span class="list-number">1.15</span> Production Guidelines - Water Sets
					  </li>
					  <li
						class="nav-link"
						id="office-production-16-tab"
						data-bs-toggle="pill"
						href="#office-production-16-tab"
						role="tab"
						aria-controls="office-production-16-tab"
						aria-selected="false"
						(click)="selectTab('office-production-16-tab')"
					  >
						<span class="list-number">1.16</span> Office Guidelines
					  </li>
					  <li
						class="nav-link"
						id="office-production-17-tab"
						data-bs-toggle="pill"
						href="#office-production-17-tab"
						role="tab"
						aria-controls="office-production-17-tab"
						aria-selected="false"
						(click)="selectTab('office-production-17-tab')"
					  >
						<span class="list-number">1.17</span> Furniture
					  </li>
					  <li
						class="nav-link"
						id="office-production-18-tab"
						data-bs-toggle="pill"
						href="#office-production-18-tab"
						role="tab"
						aria-controls="office-production-18-tab"
						aria-selected="false"
						(click)="selectTab('office-production-18-tab')"
					  >
						<span class="list-number">1.18</span> Phones / Wi-Fi / StudioNet
					  </li>
					  <li
						class="nav-link"
						id="office-production-19-tab"
						data-bs-toggle="pill"
						href="#office-production-19-tab"
						role="tab"
						aria-controls="office-production-19-tab"
						aria-selected="false"
						(click)="selectTab('office-production-19-tab')"
					  >
						<span class="list-number">1.19</span> Custodial Service
					  </li>
					  <li
						class="nav-link"
						id="office-production-20-tab"
						data-bs-toggle="pill"
						href="#office-production-20-tab"
						role="tab"
						aria-controls="office-production-20-tab"
						aria-selected="false"
						(click)="selectTab('office-production-20-tab')"
					  >
						<span class="list-number">1.20</span> Signage/Exterior Display
					  </li>
					  <li
						class="nav-link"
						id="office-production-21-tab"
						data-bs-toggle="pill"
						href="#office-production-21-tab"
						role="tab"
						aria-controls="office-production-21-tab"
						aria-selected="false"
						(click)="selectTab('office-production-21-tab')"
					  >
						<span class="list-number">1.21</span> Conference Room Bookings
					  </li>
					  <li
						class="nav-link"
						id="office-production-22-tab"
						data-bs-toggle="pill"
						href="#office-production-22-tab"
						role="tab"
						aria-controls="office-production-22-tab"
						aria-selected="false"
						(click)="selectTab('office-production-22-tab')"
					  >
						<span class="list-number">1.22</span> Key Shop
					  </li>
					  <li
						class="nav-link"
						id="office-production-23-tab"
						data-bs-toggle="pill"
						href="#office-production-23-tab"
						role="tab"
						aria-controls="office-production-23-tab"
						aria-selected="false"
						(click)="selectTab('office-production-23-tab')"
					  >
						<span class="list-number">1.23</span> Parking Services
					  </li>
					  <li
						class="nav-link"
						id="office-production-24-tab"
						data-bs-toggle="pill"
						href="#office-production-24-tab"
						role="tab"
						aria-controls="office-production-24-tab"
						aria-selected="false"
						(click)="selectTab('office-production-24-tab')"
					  >
						<span class="list-number">1.24</span> Warner Bros. Labor Dept.
					  </li>
					  <li
						class="nav-link"
						id="office-production-25-tab"
						data-bs-toggle="pill"
						href="#office-production-25-tab"
						role="tab"
						aria-controls="office-production-25-tab"
						aria-selected="false"
						(click)="selectTab('office-production-25-tab')"
					  >
						<span class="list-number">1.25</span> Recycling Guidelines / Social Vocational Services
					  </li>
					  <li
						class="nav-link"
						id="office-production-26-tab"
						data-bs-toggle="pill"
						href="#office-production-26-tab"
						role="tab"
						aria-controls="office-production-26-tab"
						aria-selected="false"
						(click)="selectTab('office-production-26-tab')"
					  >
						<span class="list-number">1.26</span> Smoking Policy
					  </li>
					</ul>
				  </div>
				  <li
				  class="nav-link"
				  id="purchase-billing-tab"
				  role="tab"
				  aria-controls="purchase-billing-content"
				  aria-selected="false"
				  (click)="selectTab('purchase-billing-tab')"
				  data-bs-toggle="collapse"
				  href="#collapseTwo"
				  role="button"
				  aria-expanded="false"
				  aria-controls="collapseTwo"
				>
				  <span class="list-number">2.</span> Purchasing and Billing
				</li>
				<div class="collapse" id="collapseTwo">
				  <ul class="handbook-sub-list scrollable-container">
					<li
					  class="nav-link"
					  id="purchase-billing-0-tab"
					  data-bs-toggle="pill"
					  href="#purchase-billing-0-tab"
					  role="tab"
					  aria-controls="purchase-billing-0-tab"
					  aria-selected="false"
					  (click)="selectTab('purchase-billing-0-tab')"
					>
					  <span class="list-number">2.0</span> Accounts Receivable Numbers (AR Number)
					</li>
					<li
					  class="nav-link"
					  id="purchase-billing-1-tab"
					  data-bs-toggle="pill"
					  href="#purchase-billing-1-tab"
					  role="tab"
					  aria-controls="purchase-billing-1-tab"
					  aria-selected="false"
					  (click)="selectTab('purchase-billing-1-tab')"
					>
					  <span class="list-number">2.1</span> Studio Work Authorizations (SWA/Internal POs)
					</li>
					  <li
						class="nav-link"
						id="purchase-billing-2-tab"
						data-bs-toggle="pill"
						href="#purchase-billing-2-tab"
						role="tab"
						aria-controls="purchase-billing-2-tab"
						aria-selected="false"
						(click)="selectTab('purchase-billing-2-tab')"
					  >
						<span class="list-number">2.2</span> A Quick Reference Guide To SWA Creation
					  </li>
					  <li
						class="nav-link"
						id="purchase-billing-3-tab"
						data-bs-toggle="pill"
						href="#purchase-billing-3-tab"
						role="tab"
						aria-controls="purchase-billing-3-tab"
						aria-selected="false"
						(click)="selectTab('purchase-billing-3-tab')"
					  >
						<span class="list-number">2.3</span> Studio Work Authorization Form
					  </li>
					  <li
						class="nav-link"
						id="purchase-billing-4-tab"
						data-bs-toggle="pill"
						href="#purchase-billing-4-tab"
						role="tab"
						aria-controls="purchase-billing-4-tab"
						aria-selected="false"
						(click)="selectTab('purchase-billing-4-tab')"
					  >
						<span class="list-number">2.4</span> Construction Flat Bids
					  </li>
					  <li
						class="nav-link"
						id="purchase-billing-5-tab"
						data-bs-toggle="pill"
						href="#purchase-billing-5-tab"
						role="tab"
						aria-controls="purchase-billing-5-tab"
						aria-selected="false"
						(click)="selectTab('purchase-billing-5-tab')"
					  >
						<span class="list-number">2.5</span> Invoices
					  </li>
					</ul>
				  </div>
				  
				  <li
				  class="nav-link"
				  id="studio-protection-tab"
				  role="tab"
				  aria-controls="studio-protection-content"
				  aria-selected="false"
				  (click)="selectTab('studio-protection-tab')"
				  data-bs-toggle="collapse"
				  href="#collapseThree"
				  role="button"
				  aria-expanded="false"
				  aria-controls="collapseThree"
				>
				  <span class="list-number">3.</span> Studio Protection Group / Emergency Response
				</li>
				<div class="collapse" id="collapseThree">
				  <ul class="handbook-sub-list scrollable-container">
					<li
					  class="nav-link"
					  id="studio-protection-0-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-0-tab"
					  role="tab"
					  aria-controls="studio-protection-0-tab"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-0-tab')"
					>
					  <span class="list-number">3.0</span> Studio Protection Group (SPG)
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-1-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-1-tab"
					  role="tab"
					  aria-controls="studio-protection-1-tab"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-1-tab')"
					>
					  <span class="list-number">3.1</span> WB Security - Primary Contacts
					</li>
					  <li
						class="nav-link"
						id="studio-protection-2-tab"
						data-bs-toggle="pill"
						href="#studio-protection-2-tab"
						role="tab"
						aria-controls="studio-protection-2-tab"
						aria-selected="false"
						(click)="selectTab('studio-protection-2-tab')"
					  >
						<span class="list-number">3.2</span> Gate Hours
					  </li>
					  <li
						class="nav-link"
						id="studio-protection-3-tab"
						data-bs-toggle="pill"
						href="#studio-protection-3-tab"
						role="tab"
						aria-controls="studio-protection-3-tab"
						aria-selected="false"
						(click)="selectTab('studio-protection-3-tab')"
					  >
						<span class="list-number">3.3</span> Studio Access Policies &
						Procedures
					  </li>
					  <li
						class="nav-link"
						id="studio-protection-4-tab"
						data-bs-toggle="pill"
						href="#studio-protection-4-tab"
						role="tab"
						aria-controls="studio-protection-4-tab"
						aria-selected="false"
						(click)="selectTab('studio-protection-4-tab')"
					  >
						<span class="list-number">3.4</span> Access Categories & Pass
						Types.
					  </li>
					  <li
						class="nav-link"
						id="studio-protection-5-tab"
						data-bs-toggle="pill"
						href="#studio-protection-5-tab"
						role="tab"
						aria-controls="studio-protection-5-tab"
						aria-selected="false"
						(click)="selectTab('studio-protection-5-tab')"
					  >
						<span class="list-number">3.5</span> WBSO Call Sheet.
					  </li>
					  <li
						class="nav-link"
						id="studio-protection-6-tab"
						data-bs-toggle="pill"
						href="#studio-protection-6-tab"
						role="tab"
						aria-controls="studio-protection-6-tab"
						aria-selected="false"
						(click)="selectTab('studio-protection-6-tab')"
					  >
						<span class="list-number">3.6</span> WBSO Security - Traffic
						Department: Rules & Enforcement
					  </li>
					  <li
					  class="nav-link"
					  id="studio-protection-7-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-7-content"
					  role="tab"
					  aria-controls="studio-protection-7-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-7-tab')"
					>
					  <span class="list-number">3.7</span> WBSO Security - Production Services
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-8-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-8-content"
					  role="tab"
					  aria-controls="studio-protection-8-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-8-tab')"
					>
					  <span class="list-number">3.8</span> WBSO Security - Vendor Delivery Policies
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-9-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-9-content"
					  role="tab"
					  aria-controls="studio-protection-9-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-9-tab')"
					>
					  <span class="list-number">3.9</span> Warner Bros. Entertainment Inc. Firearms-Weapons Policy
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-10-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-10-content"
					  role="tab"
					  aria-controls="studio-protection-10-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-10-tab')"
					>
					  <span class="list-number">3.10</span> SPG: Warner Bros. First Aid Dept. - Information & Regulations
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-11-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-11-content"
					  role="tab"
					  aria-controls="studio-protection-11-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-11-tab')"
					>
					  <span class="list-number">3.11</span> WBSO First Aid / Health & Wellness Services
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-12-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-12-content"
					  role="tab"
					  aria-controls="studio-protection-12-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-12-tab')"
					>
					  <span class="list-number">3.12</span> SPG: Warner Bros. Fire Dept. - Information & Regulations
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-13-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-13-content"
					  role="tab"
					  aria-controls="studio-protection-13-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-13-tab')"
					>
					  <span class="list-number">3.13</span> General Requirements
					</li>
					<li
					  class="nav-link"
					  id="studio-protection-14-tab"
					  data-bs-toggle="pill"
					  href="#studio-protection-14-content"
					  role="tab"
					  aria-controls="studio-protection-14-content"
					  aria-selected="false"
					  (click)="selectTab('studio-protection-14-tab')"
					>
					  <span class="list-number">3.14</span> Evacuation Check List
					</li>
					</ul>
				  </div>
				  <li
				  class="nav-link"
				  id="safety-environment-tab"
				  role="tab"
				  aria-controls="safety-environment-content"
				  aria-selected="false"
				  (click)="selectTab('safety-environment-tab')"
				  data-bs-toggle="collapse"
				  href="#collapseFour"
				  role="button"
				  aria-expanded="false"
				  aria-controls="collapseFour"
				>
				  <span class="list-number">4.</span> Safety & Environmental Affairs
				</li>
				<div class="collapse" id="collapseFour">
				  <ul class="handbook-sub-list scrollable-container">
					<li
					  class="nav-link"
					  id="safety-environment-0-tab"
					  data-bs-toggle="pill"
					  href="#safety-environment-0-tab"
					  role="tab"
					  aria-controls="safety-environment-0-tab"
					  aria-selected="false"
					  (click)="selectTab('safety-environment-0-tab')"
					>
					  <span class="list-number">4.0</span> Overview
					</li>
					<li
					  class="nav-link"
					  id="safety-environment-1-tab"
					  data-bs-toggle="pill"
					  href="#safety-environment-1-tab"
					  role="tab"
					  aria-controls="safety-environment-1-tab"
					  aria-selected="false"
					  (click)="selectTab('safety-environment-1-tab')"
					>
					  <span class="list-number">4.1</span> Primary Contacts: On-lot Fire/Medical/Safety Emergency Line and S&EA Team
					</li>
					  <li
						class="nav-link"
						id="safety-environment-2-tab"
						data-bs-toggle="pill"
						href="#safety-environment-2-tab"
						role="tab"
						aria-controls="safety-environment-2-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-2-tab')"
					  >
						<span class="list-number">4.2</span> Injury, Illness Prevention Program (IIPP)
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-3-tab"
						data-bs-toggle="pill"
						href="#safety-environment-3-tab"
						role="tab"
						aria-controls="safety-environment-3-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-3-tab')"
					  >
						<span class="list-number">4.3</span> Important Points To Help Keep Your Employees Safe
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-4-tab"
						data-bs-toggle="pill"
						href="#safety-environment-4-tab"
						role="tab"
						aria-controls="safety-environment-4-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-4-tab')"
					  >
						<span class="list-number">4.4</span> Staff & Crew Training
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-5-tab"
						data-bs-toggle="pill"
						href="#safety-environment-5-tab"
						role="tab"
						aria-controls="safety-environment-5-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-5-tab')"
					  >
						<span class="list-number">4.5</span> Production Start-Up Guidelines For Warner Bros. Productions
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-6-tab"
						data-bs-toggle="pill"
						href="#safety-environment-6-tab"
						role="tab"
						aria-controls="safety-environment-6-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-6-tab')"
					  >
						<span class="list-number">4.6</span> Productions Not Affiliated with Warner Bros.
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-7-tab"
						data-bs-toggle="pill"
						href="#safety-environment-7-tab"
						role="tab"
						aria-controls="safety-environment-7-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-7-tab')"
					  >
						<span class="list-number">4.7</span> Inspection Procedures For OSHA And Other Regulatory Agencies
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-8-tab"
						data-bs-toggle="pill"
						href="#safety-environment-8-tab"
						role="tab"
						aria-controls="safety-environment-8-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-8-tab')"
					  >
						<span class="list-number">4.8</span> Craft Service: Food Preparation On Sets And Mobile Food Vehicles
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-9-tab"
						data-bs-toggle="pill"
						href="#safety-environment-9-tab"
						role="tab"
						aria-controls="safety-environment-9-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-9-tab')"
					  >
						<span class="list-number">4.9</span> Air Quality: Production Considerations
					  </li>
					  <li
					  class="nav-link"
					  id="safety-environment-10-tab"
					  data-bs-toggle="pill"
					  href="#safety-environment-10-tab"
					  role="tab"
					  aria-controls="safety-environment-10-tab"
					  aria-selected="false"
					  (click)="selectTab('safety-environment-10-tab')"
					>
					  <span class="list-number">4.10</span>Genral Warning's and Notifications/ Set Construction Safety
					</li>
					  <li
						class="nav-link"
						id="safety-environment-11-tab"
						data-bs-toggle="pill"
						href="#safety-environment-11-tab"
						role="tab"
						aria-controls="safety-environment-11-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-11-tab')"
					  >
						<span class="list-number">4.11</span> Managing Chemical-Containing Products
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-12-tab"
						data-bs-toggle="pill"
						href="#safety-environment-12-tab"
						role="tab"
						aria-controls="safety-environment-12-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-12-tab')"
					  >
						<span class="list-number">4.12</span> WBSO Sewer and Storm Drain Policies
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-13-tab"
						data-bs-toggle="pill"
						href="#safety-environment-13-tab"
						role="tab"
						aria-controls="safety-environment-13-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-13-tab')"
					  >
						<span class="list-number">4.13</span> Additional Storm Drain Policy Guidelines – By Department
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-14-tab"
						data-bs-toggle="pill"
						href="#safety-environment-14-tab"
						role="tab"
						aria-controls="safety-environment-14-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-14-tab')"
					  >
						<span class="list-number">4.14</span> Guidelines for Shipping Dangerous Goods
					  </li>
					  <li
						class="nav-link"
						id="safety-environment-15-tab"
						data-bs-toggle="pill"
						href="#safety-environment-15-tab"
						role="tab"
						aria-controls="safety-environment-15-tab"
						aria-selected="false"
						(click)="selectTab('safety-environment-15-tab')"
					  >
						<span class="list-number">4.15</span> Unmanned Aerial Vehicles / Unmanned Aerial Camera Platforms / Drones
					  </li>
					</ul>
				  </div>
				  
				</ol>
			</div>
			<div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
				<select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
					<option value="office-production-tab">
						<span class="list-number">1.</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-0-tab">
						<span class="list-number">1.0</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-1-tab">
						<span class="list-number">1.1</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-2-tab">
						<span class="list-number">1.2</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-3-tab">
						<span class="list-number">1.3</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-4-tab">
						<span class="list-number">1.4</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-5-tab">
						<span class="list-number">1.5</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-6-tab">
						<span class="list-number">1.6</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-7-tab">
						<span class="list-number">1.7</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-8-tab">
						<span class="list-number">1.8</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-9-tab">
						<span class="list-number">1.9</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-10-tab">
						<span class="list-number">1.10</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-11-tab">
						<span class="list-number">1.11</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-12-tab">
						<span class="list-number">1.12</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-13-tab">
						<span class="list-number">1.13</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-14-tab">
						<span class="list-number">1.14</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-15-tab">
						<span class="list-number">1.15</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-16-tab">
						<span class="list-number">1.16</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-17-tab">
						<span class="list-number">1.17</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-18-tab">
						<span class="list-number">1.18</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-19-tab">
						<span class="list-number">1.19</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-20-tab">
						<span class="list-number">1.20</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-21-tab">
						<span class="list-number">1.21</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-22-tab">
						<span class="list-number">1.22</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-23-tab">
						<span class="list-number">1.23</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-24-tab">
						<span class="list-number">1.24</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-25-tab">
						<span class="list-number">1.25</span> Office & Production Services (OPS)
					</option>
					<option value="office-production-26-tab">
						<span class="list-number">1.26</span> Office & Production Services (OPS)
					</option>
					<option value="purchase-billing-tab">
						<span class="list-number">2.</span> Purchasing and Billing
					</option>
					<option value="purchase-billing-0-tab">
						<span class="list-number">2.0</span>  Accounts Receivable Numbers (AR Number)
					</option>
					<option value="purchase-billing-1-tab">
						<span class="list-number">2.1</span> Studio Work Authorizations (SWA/Internal POs)
					</option>
					<option value="purchase-billing-2-tab">
						<span class="list-number">2.2</span> A Quick Reference Guide To SWA Creation
					</option>
					<option value="purchase-billing-3-tab">
						<span class="list-number">2.3</span>  Studio Work Authorization Form
					</option>
					<option value="purchase-billing-4-tab">
						<span class="list-number">2.4</span> Construction Flat Bids
					</option>
					<option value="purchase-billing-5-tab">
						<span class="list-number">2.5</span>Invoices
					</option>
					<option value="studio-protection-tab">
						<span class="list-number">3.</span> Studio Protection Group / Emergency Response
					</option>
					<option value="studio-protection-0-tab">
						<span class="list-number" selected>3.0</span> Studio Protection Group (SPG)
					</option>
					<option value="studio-protection-1-tab">
						<span class="list-number">3.1</span> WB Security - Primary Contacts
					</option>
					<option value="studio-protection-2-tab">
						<span class="list-number">3.2</span> Gate Hours
					</option>
					<option value="studio-protection-3-tab">
						<span class="list-number">3.3</span> Studio Access Policies & Procedures
					</option>
					<option value="studio-protection-4-tab">
						<span class="list-number">3.4</span> Access Categories & Pass Types.
					</option>
					<option value="studio-protection-5-tab">
						<span class="list-number">3.5</span> WBSO Call Sheet.
					</option>
					<option value="studio-protection-6-tab">
						<span class="list-number">3.6</span> WBSO Security - Traffic Department: Rules & Enforcement
					</option>
					<option value="studio-protection-7-tab">
						<span class="list-number">3.7</span>  WBSO Security - Production Services
					</option>
					<option value="studio-protection-8-tab">
						<span class="list-number">3.8</span>  WBSO Security - Vendor Delivery Policies
					</option>
					<option value="studio-protection-9-tab">
						<span class="list-number">3.9</span> Warner Bros. Entertainment Inc. Firearms-Weapons Policy
					</option>
					<option value="studio-protection-10-tab">
						<span class="list-number">3.10</span> SPG: Warner Bros. First Aid Dept. - Information & Regulations
					</option>
					<option value="studio-protection-11-tab">
						<span class="list-number">3.11</span>  WBSO First Aid / Health & Wellness Services
					</option>
					<option value="studio-protection-12-tab">
						<span class="list-number">3.12</span> SPG: Warner Bros. Fire Dept. - Information & Regulations
					</option>
					<option value="studio-protection-13-tab">
						<span class="list-number">3.13</span> General Requirements
					</option>
					<option value="studio-protection-14-tab">
						<span class="list-number">3.14</span> Evacuation Check List
					</option>
					<option value="safety-environment-tab">
						<span class="list-number">4.</span> Safety & Environmental Affairs
					</option>
					<option value="safety-environment-0-tab">
						<span class="list-number">4.0</span> Overview
					</option>
					<option value="safety-environment-1-tab">
						<span class="list-number">4.1</span> Primary Contacts: On-lot Fire/Medical/Safety Emergency Line and S&EA Team
					</option>
					<option value="safety-environment-2-tab">
						<span class="list-number">4.2</span> Injury, Illness Prevention Program (IIPP)
					</option>
					<option value="safety-environment-3-tab">
						<span class="list-number">4.3</span> Important Points To Help Keep Your Employees Safe
					</option>
					<option value="safety-environment-4-tab">
						<span class="list-number">4.4</span> Staff & Crew Training
					</option>
					<option value="safety-environment-5-tab">
						<span class="list-number">4.5</span> Production Start-Up Guidelines For Warner Bros. Productions
					</option>
					<option value="safety-environment-6-tab">
						<span class="list-number">4.6</span>  Productions Not Affiliated with Warner Bros.
					</option>
					<option value="safety-environment-7-tab">
						<span class="list-number">4.7</span> Inspection Procedures For OSHA And Other Regulatory Agencies
					</option>
					<option value="safety-environment-8-tab">
						<span class="list-number">4.8</span> Craft Service: Food Preparation On Sets And Mobile Food Vehicles
					</option>
					<option value="safety-environment-9-tab">
						<span class="list-number">4.9</span> Air Quality: Production Considerations
					</option>
					<option value="safety-environment-10-tab">
						<span class="list-number">4.10</span> Genral Warning's and Notifications/ Set Construction Safety
					</option>
					<option value="safety-environment-11-tab">
						<span class="list-number">4.11</span>  Managing Chemical-Containing Products
					</option>
					<option value="safety-environment-12-tab">
						<span class="list-number">4.12</span>  WBSO Sewer and Storm Drain Policies
					</option>
					<option value="safety-environment-13-tab">
						<span class="list-number">4.13</span> Additional Storm Drain Policy Guidelines – By Department
					</option>
					<option value="safety-environment-14-tab">
						<span class="list-number">4.14</span> Guidelines for Shipping Dangerous Goods
					</option>
					<option value="safety-environment-15-tab">
						<span class="list-number">4.15</span>  Unmanned Aerial Vehicles / Unmanned Aerial Camera Platforms / Drones
					</option>
				</select>
			</div>
			<div class="col-md-8 col-lg-8 col-sm-12">
				<!-- Tab Content -->
				<div class="tab-content" id="v-pills-tabContent">
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-tab'}"
						id="office-production-tab" role="tabpanel" aria-labelledby="office-production-tab">

					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-0-tab'}"
					id="office-production-0-tab" role="tabpanel" aria-labelledby="office-production-0-tab">
					<div class="page-details-section">
						<h2 class="page-sub-heading">1.0 - Overview</h2>
						<p class="para-text-light studios-para">Office & Production Services (OPS) – A comprehensive organization that oversees all of the production facilities, offices and support spaces at WBSO, which offers the following services:</p>
						<ul class="bullet-list">
								<li>Stage Rental</li>
								<li>Filming Locations (Backlot or Non-Set)</li>
								<li>Office Space Rental</li>
								<li class="no-decoration">(*) Complimentary</li>
								<li>Air Conditioning / Electrical / Plumbing Support *</li>
								<li>Appliance Maintenance *</li>
								<li>Building Maintenance</li>
								<li>Cable Management</li>
								<li>Custodial Requests *</li>
								<li>Furniture Relocation</li>
								<li>Gardening *</li>
								<li>Internet / Secure StudioNet Provisions *</li>
								<li>Key Shop</li>
								<li>Recycling Service</li>
								<li>Telephone Setup</li>
								<li>Water Cooler Account & Service Setup</li>
								<li>Parking – Personnel or Production Equipment</li>
								<li>Logistics, Coordination and Scheduling For All Production ActivitiesLogistics, Coordination and Scheduling For All Production Activities</li>
						</ul>
						
					</div>
					<div class="page-details-section">
						<h2 class="page-sub-heading"> Primary Contacts</h2>
						<div class="dispatch-list-section">
							<h3 class="list-title">Main:</h3>
							<ul class="dispatch-list bullet-list">
								<li >Office: <span class="contact-details">818-954-6777</span></li>
								<li>Email: <span class="contact-details">wbsocallcenter@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">John Smith</h3>
							<span class="list-subtitle">Vice President, Office & Production Services</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details bullet-list-pb">818-954-7272</span></li>
								<li>Mobile: <span class="contact-details">818-636-0800</span></li>
								<li>Email: <span class="contact-details">Johh.Smith@warnerbros.com</span></li>
								<li>Please cc: Emily Fuller (Executive Assistant) <span class="contact-details"> Emily.Fuller@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Gabriel Anderson</h3>
							<span>Executive Director, Production Services</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details">818-954-5587</span></li>
								<li>Mobile: <span class="contact-details">818-822-4635</span></li>
								<li>Email: <span class="contact-details">Gabriel.Anderson@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Arelys Rico-Acevedo</h3>
							<span>Executive Director, Office Services</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details">818-954-5244</span></li>
								<li>Mobile: <span class="contact-details">818-239-2000</span></li>
								<li>Email: <span class="contact-details">Arelys.Rico-Acevedo@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Pam Marrow</h3>
							<span>Director, OPS Maintenance</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details"> 818-954-1536</span></li>
								<li>Mobile: <span class="contact-details"> 818-262-4237</span></li>
								<li>Email: <span class="contact-details">Pam.Marrow@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Gary Waters</h3>
							<span>Director, Office & Production Services, Warner Bros. Ranch</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details">818-954-1108</span></li>
								<li>Mobile: <span class="contact-details">818-464-5948</span></li>
								<li>Email: <span class="contact-details">Gary.Waters@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Ian Corrigan</h3>
							<span>Senior Manager, Production Services</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details">818-954-1132</span></li>
								<li>Mobile: <span class="contact-details">818-464-5948</span></li>
								<li>Email: <span class="contact-details">Ian.Corrigan@warnerbros.com</span></li>
							</ul>
						</div><div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Robin Pledger</h3>
							<span>Sr. Building Manager, Office Services</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details"> 818-954-3171</span></li>
								<li>Mobile: <span class="contact-details">818-612-8619</span></li>
								<li>Email: <span class="contact-details">Robin.Pledger@warnerbros.com</span></li>
							</ul>
						</div>
						<div class="dispatch-list-section">
							<h3 class="list-title list-title-pb-0">Chris Owens</h3>
							<span>Director, Parking Services</span>
							<ul class="dispatch-list bullet-list">
								<li class="bullet-list-pb">Office: <span class="contact-details">818-954-7275</span></li>
								<li>Mobile: <span class="contact-details"> 818-262-6393</span></li>
								<li>Email: <span class="contact-details">Chris.Owens@warnerbros.com
									</span></li>
							</ul>
						</div>
				</div>
					
				</div>
				<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-1-tab'}"
				id="office-production-1-tab" role="tabpanel" aria-labelledby="office-production-1-tab">
				<div class="page-details-section">
					<h2 class="page-sub-heading">1.1 - Sound Stage Chart</h2>
					<br>
					<a href="" target="_blank" download><img src="assets/images/pdf-icon.svg">  Sound Stage Chart</a>
					<div class="column">
						<img src="/assets/images/wb-stage-chart.jpg" alt="">
					</div>
					</div>

			</div>
			<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-2-tab'}"
			id="office-production-2-tab" role="tabpanel" aria-labelledby="office-production-2-tab">
			<div class="page-details-section">
				<h2 class="page-sub-heading">1.2 - Stage Check-In and Check-Out</h2>
				<p class="para-text-light studios-para">Prior to the first day of occupancy, Office & Production Services will perform a stage inspection to ensure the stage floors (both on the surface and sub-floor), catwalks, on-stage support rooms, stage doors, lighting, and all other elements are suitable for production use. Production will be invited to partake in a preliminary inspection to ensure and document our findings.</p>
				<p class="para-text-light studios-para">At the conclusion of the last workday, the stage must be returned to the condition in which it was received, unless exceptions have been agreed upon by Office & Production Services. The production will be invited on the post-occupancy inspection of all stage elements, with all necessary infrastructure repairs performed by WB Construction, Paint and/or Utilities, at production expense.</p>
			</div>
			
		</div>					
		<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-3-tab'}"
		id="office-production-3-tab" role="tabpanel" aria-labelledby="office-production-3-tab">
		<div class="page-details-section">
			<h2 class="page-sub-heading">1.3 - General Stage Housekeeping</h2>
			<p class="para-text-light studios-para">Please encourage your crew to practice the following stage housekeeping and maintenance:</p>
			<ul class="bullet-list">
				<li>Maintain a clear 4-foot perimeter, as indicated by yellow lines along the interior stage walls.</li>
				<li>No storage under or attached to any set of stairs or grid, without prior approval from Burbank Fire.</li>
				<li>No storage above vestibules without prior approval from Burbank Fire.</li>
				<li>Keep all electrical and telephone panels clear for access.</li>
				<li>Keep all stage door tracks clear of debris.</li>
				<li>Keep all trap doors clear for access.</li>
				<li>Maintain general clean-up of stages (interior and exterior) including clearance of packing materials, carpeting, trash, paint, materials, etc.</li>
			</ul>
		</div>
		
	</div>
	<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-4-tab'}"
	id="office-production-4-tab" role="tabpanel" aria-labelledby="office-production-4-tab">
	<div class="page-details-section">
		<h2 class="page-sub-heading">1.4 - Hot Lock & Stage Card Access Procedures</h2>
		<p class="para-text-light studios-para">Prior to occupying a stage, Office & Production Services must be provided with the names and contact info of all personnel who are authorized to open your stage. There are two different levels of access: Hot Lock List and Card Swipe Access. Each show determines its access privileges for crew, but the most common version is for anyone who has authority to unlock the stage doors on behalf of their department, and/or to open the elephant doors, to have both forms of access, and for others who only need personal access (e.g. cast, creative producers, etc.) to have Card Swipe Access only.</p>
		
		<h3 class="page-sub-heading">Hot Lock Access</h3>
		<p class="para-text-light studios-para">The Hot Lock List includes every member of the crew that is authorized to call WB Security to have the stage doors unlocked, and (just as critically) to have it locked after the last worker leaves each day/night. There is one Hot Lock List for each stage (which can be revised as often as needed, but must be a single master document in each version), which the production submits to Office & Production Services; that list is then uploaded to an internal document site visible to WB Security. Once that list has been posted, any of the individuals on that list can have the stage opened by calling WB Security at 818-954-1248. The person calling for access must be verified on site, either through scanning a Client ID badge at the door or (if a Client ID badge has not been issued) to meet the patrol unit and show a form of photo ID. The same process applies at the end of each workday when the stage needs to be secured. A person on the Hot Lock List must call WB Security to have all doors locked and house lights turned off for the night.</p>
		
		<h3 class="page-sub-heading">Card Swipe Access</h3>
		<p class="para-text-light studios-para">At the production’s discretion, any member of the crew who has been issued a client ID badge can be set up for card swipe access, allowing them to swipe their badge at the card reader and enter through the nearest pedestrian door without calling Security. The pedestrian door will open briefly (for around 10 seconds) and lock behind the person as they enter.</p>
		
		<p class="para-text-light">Examples of both access forms are on the following pages, and clean digital copies of each can be provided by Office & Production Services. Whenever access privileges need to be removed – either for an individual on the crew that has left the production, or at the time of a production’s departure from stage – please notify OFFICE & PRODUCTION SERVICES immediately.</p>
		
		<h3 class="page-sub-heading">Temporary Access Memos</h3>
		<p class="para-text-light studios-para">If you would like to give access to certain personnel on a limited basis (i.e. an early call time on a specific day) without allowing them long-term privileges to open the stage, Office & Production Services can also issue a temporary access memo to Security. This will function the same as your Hot Lock List, but for a specific finite date and timeframe. Please contact your assigned Office & Production Services Manager for more details or call 818-954-6777.</p>
		
		<p class="para-text-light">Your cooperation with the above procedures is appreciated.</p>
		
		<a href="#" class="contact-number-blue" download><img src="assets/images/pdf-icon.svg">  DOWNLOAD HOT LOCK STAGE REQUEST FORM</a>
	</div>
	
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-5-tab'}"
id="office-production-5-tab" role="tabpanel" aria-labelledby="office-production-5-tab">
<div class="page-details-section">
	<h2 class="page-sub-heading">1.5 - Stage Specs</h2>
	<p class="para-text-light studios-para">Download and Print Stage Specs PDF’s:</p>
	<div class="row">
	  <div class="col-6  mt-3">
		<a class="text-link" routerLink="/specs-stage" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 1 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage2-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 2 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage3-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 3 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage4-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 4 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage5-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 5 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage6-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 6 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage7-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 7 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage8-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 8 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage9-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 9 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage10-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 10 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage11-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 11 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage12-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 12 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage14-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 14 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage15-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 15 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage16-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 16 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage17-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 17 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage18-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 18 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage19-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 19 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage20-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 20 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage21-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 21 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage22-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 22 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage23-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 23 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage24-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 24 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage25-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 25 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage26-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 26 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage27-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 27 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage27A-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 27A - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage28-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 28 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage29-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 29 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage30-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 30 - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage31ML-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 31ML - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage31R-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 31R - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage32R-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 32R - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage33R-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 33R - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage34R-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 34R - Spec Sheet w/ Map
		</a>
	  </div>
	  <div class="col-6 mt-3">
		<a class="text-link" href="path/to/stage35R-spec.pdf" target="_blank">
		  <i class="bi bi-file-earmark-pdf"></i> Stage 35R - Spec Sheet w/ Map
		</a>
	  </div>
	</div>
  </div>
  
  
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-6-tab'}"
id="office-production-6-tab" role="tabpanel" aria-labelledby="office-production-6-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading studios-para">1.6 - Overhead Loading – Roof Trusses & Permanent Beams</h2>
    <ul class="bullet-list">
        <li>Please contact Operations at <span class="contact-number-blue"> 818-954-6777</span> and Safety & Environmental Affairs at <span class="contact-number-blue">818-954-2890</span>to discuss any weight load questions or concerns.</li>
        <li>Fall Protection is mandatory whenever working beyond the handrail in the “ozone”.</li>
        <li>Engineering approval is required for any unusual load requirements on roof trusses or permanent beams.</li>
        <li>Whenever possible, load at roof trusses directly.</li>
        <li>Load only at nodes of truss. DO NOT load at center.</li>
        <li>Check the connection of permanent (6”X 8”) to truss prior to load application.</li>
        <li>Loads at permanent beams perpendicular to trusses shall not exceed the following:
            <ul class="bullet-list">
                <li class="bullet-list-pb">500 lbs. for a single load</li>
                <li>300 lbs. for two loads (do not apply more than two loads).</li>
            </ul>
        </li>
        <li>DO NOT apply loads to the boards of walkway.</li>
        <li>DO NOT load single “Green Sticks” beyond 100 lbs.</li>
    </ul>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-7-tab'}"
id="office-production-7-tab" role="tabpanel" aria-labelledby="office-production-7-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.7 - Permanent Construction Requests</h2>
    <p class="para-text-light studios-para">Burbank City Municipal Code requires that Warner Bros. Studios apply for and receive a City of Burbank building permit for any structure constructed on a stage which is not a production set, such as lockup or storage areas, monitoring booth, etc.</p>
    <p class="para-text-light studios-para">If your production plans to build a permanent structure, you must contact Office & Production Services<span class="contact-number-blue"> (818-954-6777)</span> for clearance on the structure’s location and size. Office & Production Services will schedule a review meeting with Construction Services and Burbank Fire Dept. to determine code and documentation requirements.</p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-8-tab'}"
id="office-production-8-tab" role="tabpanel" aria-labelledby="office-production-8-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading studios-para">1.8 - Stage Lighting Guidelines</h2>
    <ul class="bullet-list">
        <li>
            If production requires removal of any house lights or electrical fixtures on stage, the work must be approved by Office & Production Services and performed by WB Electrical Construction and charged to the production at the current studio rate per fixture. Please contact them at<span class="contact-number-blue"> 818-954-1700</span> to inquire.
        </li>
        <li>
            A loss and/or damage fee of the current studio rate per fixture will be assessed to productions for any fixture damaged or lost due to unauthorized handling of on-stage electrical fixtures.
        </li>
    </ul>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-9-tab'}"
id="office-production-9-tab" role="tabpanel" aria-labelledby="office-production-9-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.9 - Stage Dressing Rooms</h2>
    
    <p class="para-text-light studios-para"><strong>All activities regarding dressing rooms, both within the stages or in one of the external “infill” buildings, are to be coordinated through Mitch McGill (Manager, Office & Production Services). He can be reached at <a href="mailto:Mitch.McGill@warnerbros.com " class="text-link">Mitch.McGill@warnerbros.com</a>.</strong></p>
    
    <p class="para-text-light studios-para"><strong>Furniture & Restoration Policies – All Dressing Rooms</strong></p>
    <ul class="bullet-list">
        <li>Production may request additional or alternative furniture from WBSO, or bring in furniture from outside sources. All requests involving WB furniture (whether for initial setup, mid-production changes, or removal of existing furniture to be replaced from an outside source) must be reviewed by Office & Production Services, although requests involving on-stage office furniture will be transferred to Office & Production Services. Generally, one complementary initial furniture setup will be provided, and any subsequent additions/removals/rearranging will be at the production’s expense.</li>
        <li>Condos, on-stage support rooms, and off-stage dressing rooms must be left in the condition received, including all furniture returned to original rooms and damage-free. If rooms are left in a different state from time of move-in, production will bear the expense to have the rooms reset and/or repaired.</li>
    </ul>

    <p class="para-text-light studios-para"><strong>Bldg. 120, Bldg. 121 & Bldg. 122 Dressing Rooms</strong></p>
    <ul class="bullet-list">
        <li>The dressing rooms and hair/makeup rooms in these buildings are assigned to certain adjacent stages, but are otherwise available to any other production on an as-available short-term basis. Please inquire with Mitch McGill for availability and pricing.</li>
        <li>The default access hours from the exterior doors of each building are as follows:</li>
        <ul class="bullet-list">
            <li>Building 120: Monday to Friday, 24 hours/day</li>
            <li>Building 121: Monday to Friday, 5 am to 12 am</li>
            <li>Building 122: Monday to Sunday, 5 am to 12 am</li>
        </ul>
        <li>Any person who needs access outside of these hours can arrange for card swipe access via a card access request form submitted to Office & Production Services, just as described previously for stage access.</li>
        <li>Each individual room in these buildings is accessible by a 4-digit key code. Please contact Mitch McGill for keypad instructions and codes, including requests to change codes or any access issues (such as internal battery failure). If Office & Production Services is unreachable, please try WB Security Dispatch <span class="contact-number-blue"> (818-954-1248)</span>.</li>
        <li>These off-stage dressing rooms and hair/makeup rooms are cleaned nightly by Warner Bros. Custodial staff, unless schedule adjustments are arranged between production and Office & Production Services. Warner Bros. Studios is not responsible for personal property left unattended in any of the stage condo’s and/or dressing rooms. Please direct the occupants of each room to properly secure all belongings.</li>
    </ul>

    <p class="para-text-light "><strong>Condo Buildings (Stages 4, 5, 6, 8, 9, 10, 24 and 25) and Bldg. 8 Support Rooms</strong></p>
    <p class="para-text-light">Requests for cleaning or servicing the condos (including restocking of the on-stage restrooms), can be requested from a qualified craft service person or the WB Labor Department<span class="contact-number-blue"> (818-954-2009)</span>, at the production’s expense.</p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-10-tab'}"
id="office-production-10-tab" role="tabpanel" aria-labelledby="office-production-10-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading studios-para">1.10 - Production Guidelines - Exterior Sets</h2>
    <ul class="bullet-list">
        <li>
            <strong>HOLDING A LOCATION / “20 DAY RULE”</strong>
            <ul>
                <li class="bullet-list-pb">In the spirit of fairness to all productions, productions may request blanket holds for up to (3) backlot locations at a time, one episode at a time. Holds entered in this fashion do not require a financial commitment unless/until they are challenged by another production.</li>
                <li>The episodic hold period includes the full shooting calendar for the episode, plus two weekdays in advance for possible prep, and one weekday after the final shoot day for possible strike.</li>
                <li>Blanket holds can be requested up to 20 calendar days (weekends included) prior to the first day of that episodic hold period. Please note that holds will not be accepted if they are submitted earlier than 6 am on the 20th advance day, and should be submitted by email.</li>
                <li>Example: If your episode shoots from Monday 7/15 thru Friday 7/19, your hold period for this episode would be Thursday 7/11 thru Monday 7/22, and the hold request can be submitted beginning at 6 am on June 12th (20 days prior to July 11th).</li>
                <li>When sending holds by email, the request should be submitted to your assigned Office & Production Services Manager, their clerical associate, and Ian.Corrigan@warnerbros.com, Office & Production Services Sr. Manager. If you don’t know who your Office & Production Services Manager is, please visit the WBSO Production Reps page or contact your production office.</li>
            </ul>
        </li>
        <li>
            <strong>Purchasing A Location / “4-Out-Of-7 Rule”</strong>
            <ul>
                <li class="bullet-list-pb">Productions may reserve dates on the backlot outside of the 20-day hold period with a commitment to purchase the requested dates in the form of a PO or WA to Office & Production Services. These purchases are non-refundable, even in the event of a schedule change.</li>
                <li>In order to protect availability for the many sets that our long-term series have established on the backlot, there are often restrictions on purchase requests of more than four total weekdays (regardless of activity) on Midwest Street, French Street, or New York Street, within any period of seven weekdays. If the production’s work requires more than four days to complete, please contact Office & Production Services (818-954-6777) to discuss whether an exception can be made.</li>
            </ul>
        </li>
        <li>
            <strong> Procedures</strong>
            <ul>
                <li class="bullet-list-pb"><strong>WHEN ATTEMPTING TO BOOK DATES</strong></li>
                <ul>
                    <li>If your production is not in 1st position hold for a location, you may challenge the 1st hold as soon as you are ready to commit to your schedule. By issuing a challenge, you are committing to purchasing the date(s) in question if the 1st position show releases, regardless of whether the dates are used. As with an advance purchase of an available date, challenge purchases must be accompanied by a PO or SWA.</li>
                    <li>Once Office & Production Services receives the challenge, we will contact the production in 1st position to begin the challenge process. The challenged production will have 24 hours to confirm whether they will book or release their holds. If Show A issues a challenge at 2pm and Show B receives the challenge at 3pm, Show B will have until 3pm the following business day to confirm its decision.</li>
                </ul>
                <li class="bullet-list-pb"><strong>WHEN RECEIVING A CHALLENGE:</strong></li>
                <ul>
                    <li>Once you receive a challenge for dates on which you have 1st hold, you will have 24 hours to decide whether to confirm or release the hold on those dates. If you choose to confirm the hold, you will be purchasing these location dates whether they are used or not, and you must provide a PO or SWA number upon confirmation of booking.</li>
                </ul>
                <li class="bullet-list-pb"><strong>NOTES:</strong></li>
                <ul>
                    <li>The 24-hour challenge window can only be initiated or concluded between 8:30 am and 6 pm on weekdays (excluding holidays).</li>
                    <li>Challenges are only issued to the production in 1st hold on a given location / date.</li>
                    <li>For example, if your production is in 2nd position on New York Street and there is a production in 3rd position that challenges the hold, you will not have an opportunity to respond to the challenge. If the production in 1st position releases the hold, the location will be awarded to the production that challenged the hold, regardless of the challenging production’s starting position.</li>
                </ul>
            </ul>
        </li>
        <li>
            <strong>BACKLOT PROTOCOL</strong>
            <ul>
                <li class="bullet-list-pb">The Warner Bros. backlot can be a very busy place as it is not uncommon for us to have multiple companies working in close proximity to one another, whether prepping, striking, or occasionally even shooting around each other’s red lights. We try our best not to schedule two shooting companies on adjacent streets, but there are situations when it can’t be avoided. If your challenge is successful, there may be a scenario in which you’re asked to cohabitate on the backlot with other shows. In the event that we have two companies working on neighboring streets, we will schedule a meeting between representatives from both productions to coordinate the activity so that any potential inconvenience is minimized.</li>
                <li>Additionally – we are held to a high standard by the City of Burbank, in terms of our impact on the neighborhoods outside the studio and on the environment at large. This pertains both to external curfews for noise/light disruptions to the neighborhood (which must be contained before 7 am or after 10 pm on a given day) and to the flow of water or other substances into the storm drains.</li>
                <li>To assist us with these efforts, please be sure to alert Office & Production Services as to any aspects of your production activity that may have impact beyond the perimeter of your filming location.</li>
                <li>Some examples are:
                    <ul>
                        <li class="bullet-list-pb">Audio playback</li>
                        <li>Gunfire or pyrotechnics</li>
                        <li>Special effects for weather (such as rain or snow) or any other application that creates water runoff</li>
                        <li>Loud picture vehicles</li>
                        <li>Use of large cranes or elevated lighting rigs</li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
            <strong>Unmanned Aircraft Systems (a.k.a. “Drones”)</strong>
            <ul>
                <li class="bullet-list-pb">As a result of our proximity to Burbank Airport, Warner Bros. Studios (including the Main Lot and Ranch) falls within controlled airspace that is governed by FAA regulations. Any requested use of unmanned aircraft systems – for any purpose – must be submitted to Office & Production Services five business days prior to anticipated day of flight; we will coordinate with WB Safety to determine whether the request can be granted.</li>
            </ul>
        </li>
    </ul>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-11-tab'}"
id="office-production-11-tab" role="tabpanel" aria-labelledby="office-production-11-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.11 - Notification Policy For Surrounding Neighborhoods (Main Lot And Ranch)</h2>
    <p class="para-text-light studios-para">When planning a production or special event on WBSO property, please follow the guidelines below:</p>
    
    <h3 class="page-sub-heading">When to Notify</h3>
    <p class="para-text-light bullet-list-pb">Affected neighbors must be notified of an event (e.g., production, premiere party, etc.) scheduled between the hours of 7:00AM and 10:00PM if it meets either of the following criteria:</p>
    <ul class="bullet-list">
        <li>It will be loud enough to be heard by the adjacent homeowners or businesses (e.g., special effects, stunts, gunfire, Ritter fans, condors, fireworks, music, etc.)</li>
        <li>It will utilize lights or other visual elements (e.g. fire, smoke, etc.) in such a manner that neighbors will be directly affected.</li>
    </ul>
    <p class="para-text-light">No such activities can occur until after 7:00am and must end by 10:00pm, in accordance with the Burbank City Ordinance. Although filming and other activities can continue outside these time limits, any sound and visual elements that affect neighbors must be modified to prohibit disruption.</p>
    
    <h3 class="page-sub-heading">How to Notify</h3>
    <p class="para-text-light bullet-list-pb">When possible, notification shall be given a minimum of 72 hours in advance of the event. Notice must be made in writing (on appropriate letterhead) and mailed or hand-delivered to all affected addresses. Follow these guidelines in determining who is responsible for sending out the notice:</p>
	<table class="common-table">
		<thead>
			<tr>
				<th>Type of Event</th>
				<th>Letterhead</th>
				<th>Responsibility of Notice</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>WB filming activity</td>
				<td>Production Company</td>
				<td>Production Company or Community Affairs</td>
			</tr>
			<tr>
				<td>Non-WB filming activity</td>
				<td>Production Company or Warner Bros.</td>
				<td>Production Company, Office & Production Services, or Community Affairs</td>
			</tr>
			<tr>
				<td>WB sponsored events</td>
				<td>Sponsoring Organization or Warner Bros.</td>
				<td>Office & Production Services, Special Events, Community Affairs, or sponsoring department</td>
			</tr>
			<tr>
				<td>Non-WB sponsored events</td>
				<td>Sponsoring Organization or Warner Bros.</td>
				<td>Office & Production Services, Special Events, or Community Affairs</td>
			</tr>
		</tbody>
	</table>
	
    
    <h3 class="page-sub-heading">Who to Notify</h3>
    <p class="para-text-light bullet-list-pb">Community Affairs <span class="contact-number-blue">(818-954-6099)</span> can provide you with a sample letter and a set of labels that include all of the affected neighbors to be notified depending on the location and scope of the event. A copy of the final letter needs to be faxed to Public Affairs <span class="contact-number-blue">(818-954-2409)</span>, WBSO Office & Production Services<span class="contact-number-blue">(818-954-4467)</span>, and when special effects are involved, the Burbank Fire Department Capt. Please copy your assigned OPS manager on all correspondence.</p>
    <p class="para-text-light">If you have any questions regarding this policy, please call Community Affairs at extension<span class="contact-number-blue">(818-954-6099)</span>.</p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-12-tab'}"
id="office-production-12-tab" role="tabpanel" aria-labelledby="office-production-12-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.12 - Daily Call Sheets</h2>
    <p class="para-text-light studios-para">
        Production Schedules | Staff & Crew Lists
    </p>
    <p class="para-text-light">
        Daily Call Sheets (and any auxiliary information), Season Production Schedules and Staff & Crew Lists should be provided to Office & Production Services by email: <a href=""class="text-link">wbsocallcenter@warnerbros.com</a>.
    </p>
    <p class="para-text-light">
        All Call Sheets sent to OPS should include Studio Work Authorization (SWA) or Purchase Order (PO) number listed on the document. That SWA or PO number covers required labor and equipment as detailed on the Call Sheet for the designated day’s shooting. OPS will post the call sheets to the Warner Bros. Studio Operations website, which is a secure internal database to share info with designated WBSO and production personnel.
    </p>
    <p class="para-text-light">
        All Staff & Crew Lists should include the attached directory for WBSO departments. Please reach out to <a href="" class="text-link">Gabriel.Anderson@warnerbros.com</a> and <a href="" class="text-link">Arelys.Rico-Acevedo@warnerbros.com</a> if you are unsure as to your designated OPS representatives.
    </p>
    <p class="para-text-light">
        <a href="#" class="contact-number-blue" download><img src="assets/images/pdf-icon.svg">  DOWNLOAD WBSO STAFF LIST 2020</a>
    </p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-13-tab'}"
id="office-production-13-tab" role="tabpanel" aria-labelledby="office-production-13-tab">
<div class="page-details-section">
	<h2 class="page-sub-heading">1.13 Radio Frequency Equipment - WBSO Production Sound & Video</h2>
	<p class="para-text-light studios-para">The increased use of radio frequency equipment (including, but not limited to: walkie-talkies, wireless microphones, cue aids, video transmitters, et. al.) has made it necessary for Warner Bros. Studios to establish a frequency review process, to prevent/minimize interference among shooting companies.</p>
	<p class="para-text-light">Prior to shooting, Warner Bros. Production Sound and Video Services must review a list of all RF channels in use by production equipment – including any equipment used for B-roll, publicity, or any other supplementary work taking place on set – to advise on possible interference. Please contact the following:</p>
	<ul class="bullet-list">
	  <li>Supervising Engineer: Ara Mkhitaryan – <span class="contact-number-blue">ara.mkhitaryan@warnerbros.com</span> or <span class="contact-number-blue">(818-954-2736)</span></li>
	  <li>Director: Temoc Martinez – <span class="contact-number-blue">temoc.martinez@warnerbros.com</span> or <span class="contact-number-blue">(818-954-2310)</span></li>
	</ul>
	<div class="last-para-section">
	  <p class="para-text-light">WBSO cannot ensure that equipment obtained from a source other than WBSO will be free from interference, but we can often identify the conflicts in advance to help avoid any disruption.</p>
	  <p class="para-text-light">Please note:</p>
	  <ul class="bullet-list">
		<li class="bullet-list-pb">All WBSO Parent/Partner Companies are required to utilize WBSO RF equipment obtained through the Production Sound and Video services Department.</li>
		<li>All radio frequency equipment must be operated in accordance with FCC rules and regulations.</li>
		<li>All radio frequency communications equipment requiring licensed operation will be so licensed.</li>
	  </ul>
	</div>
  </div>
  
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-14-tab'}"
id="office-production-14-tab" role="tabpanel" aria-labelledby="office-production-14-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.14 - Camera Policy & Press/Publicity Access Procedures</h2>
    <p class="para-text-light bullet-list-pb">
        All persons working at or visiting Warner Bros. Studios must adhere to the following rules and regulations regarding cameras (still or video) and all audio recording equipment. Still cameras, video cameras, and other forms of recording equipment may be used on the Main Lot and Ranch for the following purposes:
    </p>
    <div class="dispatch-list-section">
        <h3 class="list-title">Production (film, television, commercials, music videos, still shoots, etc.):</h3>
        <ul class="dispatch-list">
            <li><span>Cameras used for production are cleared by Warner Bros. Office & Production Services department.</span></li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Press:</h3>
        <ul class="dispatch-list">
            <li><span>Print and broadcast press from local and national news outlets, entertainment magazines, and professional photographers may use cameras on the lot only if authorized by a Warner Bros. Entertainment Company publicist AND Warner Bros. Office & Production Services department.</span></li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Tours:</h3>
        <ul class="dispatch-list">
            <li><span>Guests of the Warner Bros. Studio Tour Hollywood may use still cameras in designated areas on the lot (exterior sets when not containing sensitive or confidential material, and in and around the tour exhibits), under the supervision of their tour guide.</span></li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Special Events:</h3>
        <ul class="dispatch-list">
            <li><span>Cameras and recording equipment may be used in conjunction with special events held on the lot if prior arrangements have been made with WBSO’s Special Events department and/or Corporate Communications.</span></li>
        </ul>
    </div>
    <div class="last-para-section">
        <p class="para-text-light">
            Every guest pass states, “Photography or any form of image capturing (e.g. film, video, digital, etc.) on Studio’s premises without the prior written authorization of the Studio is strictly prohibited.” Employees and their guests are discouraged from bringing personal cameras (still or video) on the lot. Any photos taken must be for the employee’s personal use only. The privacy of all individuals working on the lot in any capacity should always be respected; therefore, photos may not be taken of any individual without their prior consent. Photography on a working set is strictly prohibited by anyone other than approved personnel of that production.
            Please contact your Office & Production Services Manager for further questions regarding camera access.
        </p>
        <p class="para-text-light">
            <strong>AUTHORIZED PRESS CLEARANCE</strong>
            <p class="bullet-list-pb">Obtain authorized press credentials, Warner Bros. Entertainment Company publicists must submit the following information to the WB Office & Production Services department with as much advance notice as possible (preferably at least 24 hours).</p>
        <!-- </p> -->
        <div class="dispatch-list-section">
            <h3 class="list-title">Press Credentials Requirements:</h3>
            <ul class="dispatch-list">
                <li>Purpose of shoot</li>
                <li>Name of production/company</li>
                <li>Date(s) of the visit</li>
                <li>Time of arrival and departure</li>
                <li>Each crew member’s first and last name as it appears on his/her/their valid, government-issued photo I.D.</li>
                <li>Name of main contact person on the crew and his/her/their cell phone number</li>
                <li>Detailed equipment list</li>
                <li>Destination (specific stage/back lot/ building)</li>
                <li>Number of vehicles and specific parking space(s)</li>
                <li>The name, department, and office or cell phone number of the designated Warner Bros. escort, plus the names of two alternate escorts, in case the designated person cannot meet the crew</li>
                <li>A certificate of insurance (COI) or a signed Hold Harmless Agreement (please defer to Office & Production Services for guidance)</li>
            </ul>
        </div>
        <p class="para-text-light bullet-list-pb">
            A Warner Bros. Entertainment Company escort/publicist must accompany all press/crews/photographers while they are on the lot. Press covering television or film productions are strictly limited to the area defined by the working sets, unless prior arrangements have been made with Office & Production Services to film elsewhere.
        </p>
        <p class="para-text-light bullet-list-pb">
            Once a shoot has been approved by Office & Production Services, the publicist or Warner Bros. escort should submit a pass request list to WB Security. For any crew members carrying equipment, note that the drive-on is a CAMERA PASS and include the escort’s name and cell phone number in the note section of the pass. If gate officers have questions regarding access or escorts, they should first call the publicist who requested the pass.
        </p>
        <p class="para-text-light bullet-list-pb">
            As with any vehicle entering the lot, all press/photographer crew vehicles are subject to inspection at Gate 9. Crews must report to Gate 9 for inspection and pass distribution and will be held there until a publicity escort has arrived to meet them at the appropriate gate (which will be Gate 7 unless there is a specific exception made in advance). Publicists should allow at least 30 minutes for press crews to go through security and arrive at their destination.
        </p>
        <p class="para-text-light bullet-list-pb">
            If the escort/publicist is not waiting or if there are any problems with credentials, the gate officers will contact the escort and/or backup contacts listed on the memo from WB Office & Production Services.
        </p>
        <p class="para-text-light bullet-list-pb">
            If press access is needed on very short notice due to unforeseen circumstances, publicists should call the WBSO Security Watch Commander at 818-954-4838. <strong>PLEASE NOTE:</strong> in most cases, short-notice approval for press and camera crews will not be granted. Please plan ahead to whatever degree possible.
        </p>
        <p class="para-text-light bullet-list-pb">
            Please fill out the below clearance form and insurance certificate and send them to Justin.Delaney@warnerbros.com.
        </p>
        <div class="dispatch-list-section">
            <h3 class="list-title">Required Forms:</h3>
            <ul class="dispatch-list">
                <li><a href="path_to_crew_photo_clearance_form.pdf">CREW PHOTO CLEARANCE FORM</a></li>
                <li><a href="path_to_wbsf_coi_sample.pdf">DOWNLOAD WBSF COI SAMPLE</a></li>
            </ul>
        </div>
        <p class="para-text-light studios-para">
            <strong>CONTACT</strong><br>
            <p class="">you have further questions, please contact:</p>
        <!-- </p> -->
        <div class="dispatch-list-section">
            <h3 class="list-title">Warner Bros. Office & Production Services:</h3>
            <ul class="dispatch-list bullet-list">
				<br> 
                    <strong>Justin Delaney</strong> (Supervisor – main point of contact for publicity inquiries)
                    <li class="bullet-list-pb">Office:<span class="contact-details"> 818-954-6337</span></li>
                    <li>Mobile:<span class="contact-details"> 818-397-1851</span></li>
                    <li>Email: <a href="mailto:Justin.Delaney@warnerbros.com"><span class="contact-details">Justin.Delaney@warnerbros.com</span></a></li>
    
				<br>
                    <strong>Ian Corrigan</strong> (Sr. Manager)
                    <li class="bullet-list-pb">Office:<span class="contact-details"> 818-954-1132</span></li>
                    <li>Mobile:<span class="contact-details"> 818-390-2003</span></li>
                    <li>Email: <a href="mailto:Ian.Corrigan@warnerbros.com"><span class="contact-details">Ian.Corrigan@warnerbros.com</span></a></li>
                
					<br>
                    <strong>John Smith</strong> (Vice President)
                    <li class="bullet-list-pb">Office:<span class="contact-details"> 818-954-7272</span></li>
                    <li>Email:<a href="mailto:John.Smith@warnerbros.com"><span class="contact-details">John.Smith@warnerbros.com</span></a></li>
                
            </ul>
        </div>
        
        <div class="dispatch-list-section">
            <h3 class="list-title">Warner Bros. Corporate Communications:</h3>
            <ul class="dispatch-list bullet-list">
            
                    <strong>Maria Gardner</strong> (Director)
                    <li class="bullet-list-pb">Office:<span class="contact-details"> 818-954-2233</span></li>
                    <li>Mobile:<span class="contact-details"> 310-666-1888</span></li>
                    <li>Email: <a href="mailto:Maria.Gardner@warnerbros.com"><span class="contact-details">Maria.Gardner@warnerbros.com</span></a></li>
                
                <br>
                    <strong>Jessica Zacholl</strong> (Director)
                    <li class="bullet-list-pb">Office:<span class="contact-details"> 818-954-2277</span></li>
                    <li>Mobile:<span class="contact-details"> 310-730-7025</span></li>
                    <li>Email: <a href="mailto:Jessica.Zacholl@warnerbros.com"><span class="contact-details">Jessica.Zacholl@warnerbros.com</span></a></li>
                
            </ul>
        </div>
    </div>
</div>



</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-15-tab'}"
id="office-production-15-tab" role="tabpanel" aria-labelledby="office-production-15-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading studios-para-no-paddding-upside">1.15 - Production Guidelines – Water Sets</h2>
	<table class="common-table">
		<thead>
			<tr>
				<th>Location</th>
				<th>Size</th>
				<th>Capacity (in gals.)</th>
				<th>Fill / Drain Time</th>
				<th>Typical Discharge Location</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th>WATER TANK STAGE 16**</th>
				<td>
					Lower Tank: 100 ft. long by 90 ft. wide, 20 ft. deep<br>
					Upper Tank: 193 ft. long by 116’ 6” wide, 5’ 7” deep.<br>
					Ramp entrance at east end of stage
				</td>
				<td>
					Lower Tank: 1,350,000 gals<br>
					Upper Tank: 940,000 gals<br>
					Total Tank: 2,290,000 gals
				</td>
				<td>
					Lower Tank:<br>Fill: 8 hrs, Drain: 12 hrs<br>
					Upper Tank:<br>Fill: 5 hrs, Drain: 8 hrs<br>
					Total Tank:<br>Fill: 13 hrs, Drain: 20 hrs
				</td>
				<td>By special arrangement with City of Burbank*</td>
			</tr>
			<tr>
				<th>LAGOON JUNGLE*</th>
				<td>
					Asphalt structure<br>
					Pond floor: 343 lineal feet, 8,940 sq.ft. surface<br>
					42° average wall incline, 4’ average depth<br>
					Total surface area: 11,050 sq.ft.
				</td>
				<td>269,280 gals</td>
				<td>Fill: 8 hrs<br>Drain: 12 hrs<br>Draining can only occur between 7 pm and 7 am</td>
				<td>Sanitary Sewer</td>
			</tr>
			<tr>
				<th>SWIMMING POOL* RANCH PARK</th>
				<td>
					Area: 51 ft. 6 in. x 37 ft.<br>
					Depth: 9 ft.-deep end; 3 ft. 6 in. shallow end<br>
					Underwater ports (3’ wide x 2’ high) on south, west, and north sides
				</td>
				<td>87,500 gals</td>
				<td>Fill: 9 hrs<br>Drain: 10 hrs</td>
				<td>Sanitary Sewer</td>
			</tr>
		</tbody>
	</table>
	
    <div class="last-para-section">
        <p class="para-text-light">NOTES:</p>
        <ul class="bullet-list">
            <li>* Contact Office & Production Services for information regarding Chemicals, Heating, Filtering, and E.P.A Testing.</li>
            <li>* Provided with a heater/filter system</li>
            <li>** Portable heater/filter equipment available through WB Plumbing.</li>
        </ul>
        <p class="para-text-light">ATTENTION:<p class="para-text-light bullet-list-pb"> State of California requires testing of water after each use, before it is discharged, for additives and contaminants. There is up to a five (5) day waiting period for test results. All fees are production’s responsibility.</p>
	<!-- </p> -->
        <p class="para-text-light">Warner Bros. Studios Burbank has several locations designed for use in water filming sequences</p>
		
        <ul class="bullet-list">
			<p class="bullet-list-pb">These locations include:</p>
            <li >Stage 16 Tank</li>
            <li>Jungle Lagoon</li>
            <li>Ranch Swimming Pool</li>
            <li>Other, temporary water sets constructed by the Production Company</li>
        </ul>
        <p class="para-text-light">Any set involving significant use of water – whether an existing water tank, pool, or lagoon, or a set custom built by the production, at any location on the Studio premises – requires a preliminary coordination meeting with Office & Production Services, Plumbing and Safety & Environmental Affairs, to discuss how, when, and where that water can be discharged. Please contact Office & Production Services to arrange for this meeting.</p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-16-tab'}"
id="office-production-16-tab" role="tabpanel" aria-labelledby="office-production-16-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.16 - Office Guidelines</h2>
    <p class="para-text-light studios-para">
        Prior to occupancy, an Office & Production Services Building Manager will schedule a walk-through with your office supervisors (UPM, Production Supervisor, Production Coordinator, et. al.) to discuss your needs for furniture, telecom, and any potential modifications to the space. We will present the amenities that are both complimentary and available for additional cost, and we will do everything possible to accommodate your needs. All requests to modify your office space, or any WBSO property, must be approved by your Building Manager.
    </p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-17-tab'}"
id="office-production-17-tab" role="tabpanel" aria-labelledby="office-production-17-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.17 - Furniture</h2>
    <p class="para-text-light studios-para">
        Production may request additional or alternative furniture from Office & Production Services or bring in furniture from outside sources. Generally, one complementary initial furniture setup will be provided, and any subsequent additions/removals/rearranging will be at the production’s expense. All requests involving WB-owned furniture (whether for initial setup, mid-production changes, or removal of existing furniture to be replaced from an outside source) must be handled by Office & Production Services furniture movers. Please contact your Building Manager for pricing and scheduling (note: requires advance notice of at least three business days).
    </p>
    <p class="para-text-light studios-para">
        WALLS Any items that need to be hung on / removed from the walls (including, but not limited to: artwork, Celotex, dry erase boards, hooks, etc.) must be addressed by Office & Production Services carpenters.
    </p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-18-tab'}"
id="office-production-18-tab" role="tabpanel" aria-labelledby="office-production-18-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading studios-para">1.18 - Phones / Wi-Fi / StudioNet</h2>
    <ul class="bullet-list">
        <li>
            <strong>What is StudioNet?</strong>
            <p class="para-text-light bullet-list-pb">
                A private, isolated network with internet access. Service can include wired and wireless access. Available across multiple stages or buildings on the Main Lot and/or Ranch facilities, wherever wired or wireless WB network access currently exists. Allows you to send and receive files/videos/etc. with anyone on your network. Your assigned block of public internet addresses can be mapped to any of your devices.
            </p>
        </li>
        <li>
            <strong>INTERNET</strong>
            <ul class="bullet-list">
                <li>
                    <p class="para-text-light bullet-list-pb">
                        Warner Bros. Studios is proud to offer complimentary Internet access throughout the facility. Hardline network service is available at any office station with an accessible data port. Additional data ports can be added, at an additional cost – please inquire with Office & Production Services for details. 
                    </p>
                </li>
                <li>
                    <p class="para-text-light ">
                        Wi-Fi service is available at all points on Studio property through the WB-Guest network (the password for which is printed on all daily access passes issued at the gates). Please note that authentication through a web browser will be necessary each time one logs on to this network.
                    </p>
                </li>
                <li>
                    <p class="para-text-light ">
                        Productions working at Warner Bros. for two weeks or longer have the option of setting up a private StudioNet network, which allows for each organization to have a private isolated network, available by wired or wireless access throughout the Studio. Please see the flyer on the next page for further information.
                    </p>
                </li>
            </ul>
        </li>
        <li>
            <strong>How do I request a private network?</strong>
            <ul class="bullet-list">
                <li class="bullet-list-pb">Identify the building, room numbers, and specific ports where service is needed</li>
                <li>Identify the type of network access required: wired or wireless</li>
                <li>Provide the desired start and end dates for service</li>
                <li>Identify a technical contact for setup requirements</li>
                <li>Confirm that all devices, including laptops and mobile devices, are up to date with the latest operating system updates and patches</li>
            </ul>
            <p class="para-text-light bullet-list-pb">
                StudioNet Requests are typically available within 5 business days for locations with existing wired and wireless access. For locations with no existing network access or locations that require additional network coverage, a minimum of 10 business days is required.
            </p>
        </li>
        <li>
            <strong>Are there private network restrictions?</strong>
            <p class="para-text-light bullet-list-pb">
                There is no direct access to any other networks at the Studio including Warner Bros. (WB) intranet sites and services. You will not have control of the WB network infrastructure including switches, access points, firewalls or other WB owned or managed network equipment. You may not attach your own network equipment (wired or wireless) to the private network without pre-approval from WBSO Office & Production Services. Each StudioNet is limited to a maximum of 500 devices. Please contact your WBSO Office & Production Services representative if you believe your production will reach this limit.
            </p>
        </li>
        <li>
            <strong>Questions or problems after the service is installed, including cancellation at the end of a job?</strong>
            <p class="para-text-light bullet-list-pb">
                For any issues, please contact your Office & Production Services Managers or the OPS Call Center <span class="contact-number-blue">(818-954-6777)</span>.
            </p>
        </li>
    </ul>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-19-tab'}"
id="office-production-19-tab" role="tabpanel" aria-labelledby="office-production-19-tab">
<div class="page-details-section">
	<h2 class="page-sub-heading">1.19 - Custodial Service</h2>
	<p class="para-text-light studios-para">
	  Basic office cleanings are provided by Warner Bros. Custodial every evening, at no additional cost. Please contact your Building Manager for any special cleaning or scheduling requests.
	</p>
  </div>
  
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-20-tab'}"
id="office-production-20-tab" role="tabpanel" aria-labelledby="office-production-20-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.20 - Signage/Exterior Display</h2>
    <p class="para-text-light studios-para">
        Any signage or decoration posted outside of your office, or affixed permanently within your space, must be approved by your Building Manager.
    </p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-21-tab'}"
id="office-production-21-tab" role="tabpanel" aria-labelledby="office-production-21-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.21 - Conference Room Bookings</h2>
    <p class="para-text-light studios-para">
        Office & Production Services maintains a number of conference rooms around the lot that are available for both corporate and production use. Here’s a list of rooms OPS can assist with booking:
    </p>
    <ul class="bullet-list">
        <li><span>Stage 15 Conference Room*</span></li>
        <li><span>Building 137 Room 1049*</span></li>
        <li><span>Building 156 Room 1300</span> (Spec Sheet coming soon!)</li>
        <li><span>Building 156 Room 2000</span></li>
        <li><span>Building 43 Room 2000-A</span></li>
        <li><span>Building 141 Room 263*</span></li>
        <li><span>Building 141 Room 375*</span></li>
        <li>*Denotes Cisco Telepresence system available. For technical support, please contact Post Sound at <span class="contact-number-blue"> 818-954-2220</span>.</li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light">
            To book a conference room on the main lot, please email <a href=""><span class="contact-number-blue">opsclientservices@warnerbros.com</span></a> or call <span class="contact-number-blue"> 818-954-6777</span> with the following information:
        </p>
        <ul class="bullet-list">
            <li>Meeting Title</li>
            <li>Contact Name</li>
            <li>Phone # & Email Address</li>
            <li>Any specifics on how to arrange the room</li>
            <li>Any additional details</li>
        </ul>
        <p class="para-text-light">
            Please note the following when considering booking a conference room:
        </p>
        <ul class="bullet-list">
            <li class="bullet-list-pb">Each client group (e.g., production or corporate department) can book up to 8 hours of time per week across all conference rooms, each up to 30 days in advance. Additional conference room time can be booked one day in advance, as available.</li>
            <li>On-lot conference rooms are reserved for employees of the company or production crew. OPS is not responsible for arranging lot passes or parking for meetings. Your production office will need to make the necessary arrangements.</li>
            <li>Any furniture move requests are chargeable to the client. Please discuss your requests in detail with the OPS staff assisting you.</li>
            <li>When booking a conference room, please notify OPS whether food service is involved in the meeting, and if so, please specify whether your plan is to bring food which has already been prepared (either hot food or individual snacks), or whether you are looking to have a catering setup with on-site food prep, chafing dishes, heaters, etc.</li>
            <li>In both cases, a custodial cleaning fee of $75 will apply.</li>
            <li>Please refer to the Main Lot Delivery Zone Map for available parking while delivering prepared food.</li>
            <li>If you require catering or on-site hot food service, we will have your OPS manager contact you to discuss the details. Most conference rooms are not well-equipped to support this kind of service, so an alternate location may need to be chosen.</li>
            <li>Please be aware that WB Food Services and Special Events are available to assist with food service needs.
                <ul>
                    <li class="bullet-list-pb">Food Services: <a href="mailto:foodservices@warnerbros.com"><span class="contact-number-blue">foodservices@warnerbros.com</span></a> or <span class="contact-number-blue"> 818-954-4202</span></li>
                    <li>Special Events: <a href="mailto:wbspecialevents@warnerbros.com"><span class="contact-number-blue">wbspecialevents@warnerbros.com</span></a> or <span class="contact-number-blue"> 818-954-2652</span></li>
                </ul>
            </li>
        </ul>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-22-tab'}"
id="office-production-22-tab" role="tabpanel" aria-labelledby="office-production-22-tab">
<div class="page-details-section">
	<h2 class="page-sub-heading">1.22 - Key Shop</h2>
	<p class="para-text-light studios-para">
		Office & Production Services processes all Key Shop requests for the Main Lot or Ranch buildings. Please contact the Call Center <span class="contact-number-blue">818-954-6777</span> to discuss any key requests.
	  </p>
	  
	<ul class="bullet-list">
	  <li>
		OPS will provide the production with two complete sets of keys to each room in the production’s office space or stage condo complex. The following guidelines apply throughout the rental period:
	  </li>
	  <li>
		All requests for additional keys must be made using the key request form, and at the expense of the production, at the rates listed below. The production is not allowed to order duplicate keys directly from WB Key Shop and is expressly prohibited from obtaining duplicate keys from any source outside of Warner Bros. Should the existence of non-WB manufactured keys be discovered, the production will be charged to re-key all applicable locks.
	  </li>
	  <li>
		If a key is lost, the condo door must be re-keyed and new keys issued at the production’s expense. If a key is suspected stolen, the production must file a report with WB Studio Security, with a copy forwarded to OPS, who will issue a work order for re-keying of the lock and issuance of new keys, at production’s expense.
	  </li>
	  <li>
		For necessary repairs to locks or keys as a result of normal usage/wear and tear, a request must be sent to OPS. All costs related to such work will be covered by OPS and will not be charged to the production.
	  </li>
	  <li>
		Any additional entry locks are not permitted to be installed on the stage condo doors without prior authorization from OPS. Padlocks & hasps are authorized, but if access is deemed necessary by OPS, Security, or WB/Burbank Fire, the lock will be cut without reimbursement to production.
	  </li>
	  <li>No stage master keys are ever issued to productions.</li>
	</ul>
	<div class="last-para-section">
	  <p class="para-text-light">Currently, the following basic rates for WB Key Shop services apply:</p>
	  <table class="common-table">
		<thead>
			<tr>
				<th>Key Rates</th>
				<th>$8.50 per key</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Service Call Fees</td>
				<td>Begin at a flat rate of $96. Additional work is figured at straight or premium time accordingly.</td>
			</tr>
			<tr>
				<td>Rekeying</td>
				<td>$83 per lock</td>
			</tr>
			<tr>
				<td>Padlocks</td>
				<td>Prices range from $15 to $100 dollars</td>
			</tr>
		</tbody>
	</table>
	
	  <p class="para-text-light">
		At the end of the production’s stage occupancy, a complete set of original keys and authorized duplicates must be returned to Office & Production Services immediately. Any unreturned keys will result in a re-keying of the lock and will be charged to the production at then-current WB rates.
	  </p>
	</div>
  </div>
  
  
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-23-tab'}"
id="office-production-23-tab" role="tabpanel" aria-labelledby="office-production-23-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.23 - Parking Services</h2>
    
    <p class="para-text-light studios-para">
        Production Parking: Production parking will be arranged by OPS Parking Services, based on the location and volume of your production’s shooting locations and offices. Aside from cast, executives, or crew who you wish to assign to one of your on-lot parking spaces, all office staff will be assigned to Lot A (on-lot structure near Gate 7), and all production crew will be assigned to Gate 8 (off-lot structure across from Gate 7 on Forest Lawn Drive).
    </p>

    <p class="para-text-light studios-para">
        Additional parking assignments may include:
    </p>
    
    <ul class="bullet-list">
        <li><span class="">Assigned office parking spaces –</span> varies by location</li>
        <li><span class="">Assigned stage parking spaces –</span> 15 spaces per stage</li>
        <li><span class="">Base camp location:</span> In addition to the costs outlined in your Studio Lease Agreement or Facilities Agreement, valet displacement costs may apply based on your footprint</li>
        <li><span class="">Assigned rental parking spaces in Gate 3 or Gate 7 structures*</span></li>
        <li><span class="">Assigned rental parking around stages or base camp lots*</span></li>
        <li><span class="">Assigned long-term rental space for heavy equipment (“truck bays”) at Main Lot or Ranch*</span></li>
    </ul>

    <div class="last-para-section">
        <p class="para-text-light">* Available to long-term production clients on a first-come, first-served basis; limited supply.</p>
    </div>

    <h2 class="page-sub-heading">Gate 8 (Forest Lawn Structure) - Parking and Pass Procedures</h2>
    <p class="para-text-light studios-para">
        Unless specified otherwise, this is the designated structure for all production crew during the workweek. The structure opens at 4 am daily (Monday-Friday), with the Visitor Center staffed at 4:30 am on those same dates; both the structure entrances and Visitor Center close at 9 pm. For any production crew needing to enter the structure to retrieve a vehicle outside of these hours, there is a callbox located at the sidewalk entrance on Forest Lawn Dr. The line will ring to Security Dispatch, who will ask the caller to show a client ID badge or daily pass to receive access.
    </p>

    <h2 class="page-sub-heading">Gate 3 (Olive Ave. Structure) - Parking and Pass Procedures</h2>
    <p class="para-text-light studios-para">
        Aside from weekends or early-morning weekday arrivals, Gate 3 Franklin Structure parking is only allowed with a Gate 3 parking decal or an off-lot parking pass for Gate 3 in the gate pass system. Despite its visible proximity to the stages, parking in Gate 3 is rarely assigned to production personnel on weekdays, as it is filled 200+ spaces beyond capacity with incumbent tenants and audiences on a daily basis. Due to the volume of stacked valet parking, it often takes less time for crew to walk to Gate 8 (even starting from the stages nearest to Gate 2) and drive out of the structure, then to cross Olive Ave. and wait for Gate 3 congestion to subside.
    </p>

	<h2 class="page-sub-heading">Other Parking Areas</h2>
	<ul class="bullet-list">
		<li class="bullet-list-pb"><span class="">Visitor Parking:</span> Available near most gates. Each production receives a daily limit of 6 on-lot visitor passes per day, so all pass requests for visitor parking should be consolidated and reserved for legitimate business purposes. With the exception of Lot A, all visitor parking areas have a time limit of 4 hours. Visitor parking cannot be reserved in advance unless Office & Production Services approves the space for production equipment (e.g., truck parking at Gate 7 visitor spaces for nearby shooting activity). When visitor parking is full, visitors will be redirected to either Gate 3 (Franklin Structure) or Gate 8 (Forest Lawn). Designated visitor parking may not be used by employees at any time, including after hours and weekends.</li>
		<li><span class="">Fitness Center Parking:</span> Permitted at Gate 3 Franklin Structure, for off-lot Warner Bros. employees only. Parking in any other area, without a valid decal or daily/extended vehicle parking pass for an assigned lot and space, will result in a citation and/or tow-away at the owner’s expense. Fitness Center members leaving the gym after dusk may request a WBSO Security escort by calling Dispatch <span class="contact-number-blue"> 818-954-1248</span>. Only individuals actively employed at Warner Bros. are permitted to access the Studio and use the Fitness Center. Cast members and 200-day club employees may utilize the Fitness Center only when they are working on-lot, regardless of the length the gym membership was purchased.</li>
		<li><span class="">Delivery Parking:</span> All vehicles making deliveries are eligible for delivery zone parking on a first-come, first-served basis, with a 15-minute limit, provided that the vehicle is not left unattended at any time. Delivery zones are not to be used for any other vehicles or equipment, or for any other purpose, without advance approval from Office & Production Services.</li>
		<li><span class="">Accessible Parking:</span> Available in every parking structure and flat lot, as well as in various other parking clusters adjacent to offices. These can be used on a first-come, first-served basis for any employee and/or guest with a valid client ID badge or parking pass; an accessible placard or license plate must be visible. Accessible parking usage will be strictly enforced. Citations can be issued by Burbank Police Department on all accessible/disabled and fire lane parking violations. Unauthorized parking in an accessible space is a municipal violation, even on private property.</li>
	</ul>
	
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-24-tab'}"
id="office-production-24-tab" role="tabpanel" aria-labelledby="office-production-24-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">24 - Warner Bros. Labor Dept.</h2>
    <p class="para-text-light studios-para">
        We strongly encourage all productions to contact Warner Bros. Labor at the beginning of production activity, to discuss guidelines for disposal of trash or any other refuse, an explanation of costs and a schedule for delivery/refreshments of trash receptacles. You may discuss your needs with Timothy Castagnola (Foreperson of WB Labor) at <span class="contact-number-blue"> 818-954-2009</span> or <span class="contact-number-blue">timothy.castagnola@warnerbros.com.</span>
    </p>
    <p class="para-text-light ">
        The basic rates for trash receptacles are as follows:
    </p>
    <ul class="bullet-list">
        <li><span class="">3-yard hoppers:</span> $100 per load</li>
        <li><span class="">10-yard hoppers:</span> $500 per load, up to 4 tons</li>
        <li><span class="">40-yard hoppers:</span> $550 per load, up to 4 tons</li>
    </ul>
    <p class="para-text-light">Note: Disposal of heavy items (e.g. metal, gravel/DG, liquid) or hazardous materials will incur additional costs.</p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-25-tab'}"
id="office-production-25-tab" role="tabpanel" aria-labelledby="office-production-25-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.25 - Recycling Guidelines / Social Vocational Services</h2>
    <p class="para-text-light studios-para">Warner Bros. has a deep commitment to waste reduction, recycling and environmental stewardship. Our long-term success is due to the participation of everyone who works on our lot and on our stages. Thanks to our productions and office tenants, we recycle more materials than we send to landfill each year. Our goal is continual improvement and we need your participation to maximize recycling and natural resource conservation.</p>
    
    <h3 class="page-sub-heading studios-para-no-paddding-upside">STAGES & EXTERIOR SETS:</h3>
    <p class="para-text-light">Please designate a crew member (e.g. Crafts Service, stage PA, etc.) as your lead in stage recycling.</p>
    <p class="para-text-light">As of 2018, we have partnered with Social Vocational Services, or SVS to help us collect recyclable materials on our stages. SVS is a nonprofit organization that connects individuals with intellectual disabilities to employers in the community.</p>
    <p class="para-text-light">SVS hours are Monday, Wednesday and Friday from 8am-2pm. Three SVS workers and one on-site supervisor will service your compost and recycling by emptying and wiping down the bins, then replacing appropriate bags (i.e. green compostable bags for compost bins and clear plastic bags for recycling toters.) SVS will also pick up flattened cardboard placed next to the three-yard bins outside stages. Acting as a team, they work their way across the lot transporting compost and recyclables to River Road for removal by our haulers.</p>
    
    <h3 class="page-sub-heading studios-para-no-paddding-upside">PRODUCTION ROLE:</h3>
    <p class="para-text-light">Please separate materials into appropriate bins, as outlined below. SVS will provide a green “brute” container with lid and wheels for collecting organic food waste and compostable products. You will also receive two blue “toters”, one for paper and another for cans & bottles. If it is necessary for your production to empty compost or recycling bins on your own (for example, on Tuesdays or Thursdays when SVS service is not scheduled), you may deposit bagged materials into the brown tilt-hopper. Then, replace the appropriate bag in your bins (i.e. green compostable bag for compost and clear plastic bags for recycling – both available for purchase at the Paint Plus store), so that SVS can resume service accordingly.</p>
    <p class="para-text-light">Warner Bros. Labor will continue to empty the brown tilt-hopper, which should mainly contain construction waste or trash – not food.</p>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Waste Sorting Guidelines:</h3>
    <ul class="bullet-list">
        <li><span class="">Food Waste – </span>Collect food waste in green bins lined with a green bag, then tie off and take to your assigned Compost Station; do not put food waste in a stage tilt hopper. Separating wet food waste prevents contamination of other recyclables and is key to our waste reduction strategy.</li>
        <li><span class="">Dry, Mixed Waste – </span>Bag all recyclables and place in your stage tilt hopper so our hauler can sort offsite for maximum recovery.</li>
        <li><span class="">Cans & Bottles – </span>Collect in blue “Cans & Bottles” bins lined with a clear bag then tie off and place bagged materials in your stage tilt hopper.</li>
        <li><span class="">Paper – </span>Collect in blue “Paper” bins lined with a clear bag then tie off and place bagged materials in your stage tilt hopper.</li>
        <li><span class="">Cardboard – </span>Break down cardboard boxes and place inside your stage tilt hopper.</li>
        <li><span class="">Construction & Demolition (C&D) Materials – </span>Place in your stage tilt hopper.</li>
        <li><span class="">Trash – </span>Bag and tie off any trash and place inside your stage tilt hopper.</li>
        <li><span class="">Metal – </span>Scrap metal can be taken to the “Metal” recycling roll off on River Road.</li>
        <li><span class="">Electronic Waste – </span>Take to Hazardous Waste Cage on River Road. (B-78).</li>
        <li><span class="">Batteries – </span>Collect in five-gallon buckets and mark “Battery Recycling.” Take to Hazardous Waste Cage on River Road. (B-78)</li>
        <li><span class="">Flooring – </span>Large pieces of usable carpet and linoleum can be donated free of charge to Habitat for Humanity through the Studio’s Encore material donation program. To schedule pick up call <span class="contact-number-blue"> 818-954-5902</span>.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">OFFICES:</h3>
    <p class="para-text-light">Please designate a crew member (i.e. Production Coordinator) as your lead in office recycling.</p>
    <ul class="bullet-list">
        <li><span class="">Confidential Documents – </span>Call the Records Management Department at <span class="contact-number-blue"> 818-954-3105</span> to order a locked Iron Mountain bin for confidential document shredding and recycling.</li>
        <li><span class="">Cans & Bottles – </span>Place in blue “Cans & Bottles” bins which can be found in pantries and conference rooms.</li>
        <li><span class="">Paper – </span>Empty your desk side paper bins into your copy room’s blue “Paper” bins.</li>
        <li><span class="">Cardboard – </span>Flatten and set next to your copy room’s blue “Paper” bins.</li>
        <li><span class="">Toner Cartridges – </span>Place in a box, mark “Recycle” and place in copy rooms for pick up.</li>
        <li><span class="">Media – </span>Collect CDs, DVDs, tapes and film in a box, mark “Recycle” and call <span class="contact-number-blue"> 818-954-677</span>.</li>
        <li><span class="">Batteries – </span>Place in “Battery” recycling bins which can be found in copy rooms.</li>
        <li><span class="">Electronic Waste – </span>Take to Hazardous Waste Cage on River Road.</li>
    </ul>

    <p class="para-text-light">Materials are picked up regularly. To request special pick up, contact Office Services at <span class="contact-number-blue"> 818-954-6777</span>. Thank you for partnering with Warner Bros. and SVS to compost, recycle and reduce waste sent to landfill. Together, we can create a more sustainable production environment.</p>
    
    <h3 class="page-sub-heading">Department Contacts:</h3>
	<table class="common-table">
		<thead>
			<tr>
				<th>Department</th>
				<th>Contact</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Office Waste / Confidential Document Recycling (Shredding)</td>
				<td>
					WBSO Office & Production Services<br>
					(818) 954-6777
				</td>
			</tr>
			<tr>
				<td>Stage Waste</td>
				<td>
					Timothy Castagnola, WBSO Labor Department<br>
					(818) 954-2009<br>
					Timothy.Castagnola@warnerbros.com
				</td>
			</tr>
			<tr>
				<td>Green Bags / Clear Bags / Trash Bags</td>
				<td>
					WBSO Paint Department<br>
					(818) 954-1665<br>
					wbsfpaint@warnerbros.com
				</td>
			</tr>
			<tr>
				<td>Hazardous / Electronic Waste (work related)</td>
				<td>
					Brent Gale, Manager of Safety<br>
					(818) 954-3380<br>
					brent.gale@warnerbros.com
				</td>
			</tr>
			<tr>
				<td>Green Production Practices / Encore Material Donations (Reuse)</td>
				<td>
					Mike Slavich, Director of Sustainability<br>
					(818) 954-2722<br>
					mike.slavich@warnerbros.com
				</td>
			</tr>
		</tbody>
	</table>
	
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-26-tab'}"
id="office-production-26-tab" role="tabpanel" aria-labelledby="office-production-26-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">1.26 - Smoking Policy</h2>
    <p class="para-text-light studios-para">
        Smoking poses a risk to the health of the smoker and may also be unhealthful to non-smokers in the work environment. Moreover, the effects of smoking can damage sensitive technological equipment and can be a safety hazard.
    </p>
    <p class="para-text-light">
        As required by applicable state law, the Smoking in the Workplace Policy applies to and prohibits smoking in all enclosed areas located on the Warner Bros. Studio Lot and in all California-based off-lot offices and work areas. Similar restrictions apply in our other domestic offices. Smoking is allowed in designated outside smoking areas.
    </p>
    
    <h3 class="page-sub-heading studios-para-no-paddding-upside">Definition of Smoking</h3>
    <ul class="bullet-list">
        <li>For purposes of this policy, “smoking” is defined to include:</li>
        <ul>
            <li>Any tobacco product including but not limited to, the inhaling, exhaling, burning, or carrying of a lighted or heated tobacco or plant product, whether natural or synthetic, in any manner or in any form such as a pipe, cigar, or cigarette of any kind</li>
            <li>The use of an electronic smoking device that creates an aerosol or vapor, in any manner or in any form, such as an electronic cigarette, cigar, pipe, or hookah</li>
        </ul>
    </ul>
    
    <h3 class="page-sub-heading studios-para-no-paddding-upside">Smoking In Prohibited Areas</h3>
    <ul class="bullet-list">
        <li>Smoking is prohibited in the following outside areas:</li>
        <ul>
            <li>Within 20 feet of a building entrance</li>
            <li>Within 20 feet of any backlot set</li>
            <li>Within 20 feet of a designated COVID break area</li>
            <li>The backlot “Jungle” set area</li>
            <li>While riding a bicycle or in a moving electric cart</li>
        </ul>
    </ul>
    
    <ul class="bullet-list">
        <li>Smoking is prohibited in all enclosed areas such as (but not limited to):</li>
        <ul>
            <li>Offices</li>
            <li>Sound Stages, including the “perms” and “catwalks” (unless required creatively in an on-camera production scene)</li>
            <li>Backlot Interior Sets, including the “catwalks” (unless required creatively in an on-camera production scene)</li>
            <li>Common or Private Work Areas</li>
            <li>Dining Facilities (including any food lines associated with them)</li>
            <li>Meeting and Conference Rooms</li>
            <li>Rest Rooms</li>
            <li>Elevators</li>
            <li>Photocopy and Storage Rooms</li>
            <li>File Rooms</li>
            <li>Corridors and Hallways</li>
            <li>Stairwells</li>
            <li>Medical Facilities</li>
            <li>Lobbies and Reception Areas</li>
            <li>Warehouses</li>
            <li>Storage Vaults</li>
            <li>Archive Facilities</li>
            <li>Screening Rooms</li>
            <li>Enclosed Parking Structures</li>
            <li>Company-owned/operated vehicles driven or occupied by employees</li>
            <li>Company Retail Facilities</li>
        </ul>
    </ul>

    <p class="para-text-light studios-para">
        All employees, vendors, and visitors must comply with this policy.
    </p>
    
    <h3 class="page-sub-heading studios-para-no-paddding-upside">Enforcement</h3>
    <ul class="bullet-list">
        <li>Questions or issues that arise in connection with the application or enforcement of this policy should be brought to the attention of Human Resources.</li>
        <li>The Company may take disciplinary action against those persons violating the provisions of this policy, including, but not limited to, those persons violating the “no retaliation” provisions.</li>
    </ul>
    
    <h3 class="page-sub-heading studios-para-no-paddding-upside">No Retaliation</h3>
    <ul class="bullet-list">
        <li>There shall be no retaliation or threats of retaliation in any form or manner against those persons seeking to enforce this policy.</li>
    </ul>
</div>

</div>

					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-tab'}"
						id="purchase-billing-tab" role="tabpanel" aria-labelledby="purchase-billing-tab">
					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-0-tab'}"
					id="purchase-billing-0-tab" role="tabpanel" aria-labelledby="purchase-billing-0-tab">
`					<div class="page-details-section">
    <h2 class="page-sub-heading">2.0 - Accounts Receivable Numbers (AR Number)</h2>
    <p class="para-text-light studios-para">
        Most productions at Warner Bros. Studios open a billing account through Accounts Receivable and receive a 6-digit account number. The last five digits of that number are commonly referred to as an “AR number”, which the Office & Production Services group uses to bill for stage and backlot utilization, equipment rentals, labor, and other production charges. We can also reference a PO# for any charges that are billed to your AR account, and (at the production’s request) establish with all WBSO departments that a PO number is mandatory for all services and billing. Please contact your assigned Office & Production Services representative for assistance in setting this up.
    </p>
    <p class="para-text-light ">
        For Accounts Receivable specific questions or to inquire what your AR# is, please contact EFS – Invoice to Cash at the following addresses:
    </p>
    <ul class="bullet-list">
        <li><span class="">Sophia Martinez – Sr. Manager –</span> <a href="" class="contact-number-blue">Sophia.Martinez@warnerbros.com</a></li>
        <li><span class="">Alejandro Aguilar – Account Representative –</span> <a href="" class="contact-number-blue">Alejandro.Aguilar@wbconsultant.com</a></li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light">
            If your production plans to use Studio Work Authorizations (SWA), please continue to the following section.
        </p>
    </div>
</div>

				</div>
				<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-1-tab'}"
				id="purchase-billing-1-tab" role="tabpanel" aria-labelledby="purchase-billing-1-tab">
				<div class="page-details-section">
					<h2 class="page-sub-heading">2.1 Studio Work Authorizations (SWA/Internal PO’s)</h2>
					<p class="para-text-light studios-para">Production companies working on the SWA system and using an internal Warner Bros. billing account (typically a WBS Element, or “upload-download”) must issue a Studio Work Authorization (SWA) to order all goods or services from WBSO. To create a SWA, please send your approved purchase order to Operations via email: <span class="contact-number-blue">WBSOCallCenter@warnerbros.com</span></p>
					
					<h2 class="page-sub-heading">Authorization List</h2>
					<p class="para-text-light studios-para">Prior to starting any work at the studio, it is the responsibility of the production company to submit a Studio Work Authorization Form to Operations, listing the names, signatures and initials for individuals who will request or sign off on SWA/POs submitted for processing. A blank copy of this form is provided in the following pages.</p>
					<p class="para-text-light">Please note that a SWA cannot be created without the appropriate approval signatures.</p>
				</div>
				
			</div>
			<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-2-tab'}"
			id="purchase-billing-2-tab" role="tabpanel" aria-labelledby="purchase-billing-2-tab">
			<div class="page-details-section">
				<h2 class="page-sub-heading ">2.2 - A Quick Reference Guide To SWA Creation</h2>
				<p class="para-text-light studios-para">Who can request a SWA?</p>
				<p class="para-text-light">All SWA requestors must be on the production Work Authorization List. The list is approved by a production executive prior to the start of production, with each requestor’s name and authorized credit spending limit.</p>
				<p class="para-text-light">What information is needed to create a SWA?</p>
				<ul class="bullet-list">
					<li>Name of the production</li>
					<li>Requester name</li>
					<li>Element number and detail account number (see list of detail numbers on following pages)</li>
					<li>Description of goods and services requested – Estimate, unit, or invoice # if applicable</li>
					<li>WBSO Department (see list of departments on following pages)</li>
					<li>Open/Close date</li>
					<li>Billing Total</li>
				</ul>
				<p class="para-text-light">Where and how do I send a request to create a SWA?</p>
				<p class="para-text-light">Ensure that the Work Authorization Form has been submitted to Office & Production Services with approver signatures and noted spending limits (see this handbook for an example or request a blank form from the WBSO Call Center at <span class="contact-number-blue">WBSOCallCenter@warnerbros.com</span>). Send a PO with all above information and approval signatures to the Call Center for processing.</p>
				<p class="para-text-light">All requests should be processed by the Call Center within 24 hours or less during the work week.</p>
				<p class="para-text-light">How is the SWA billed against?</p>
				<p class="para-text-light">Once the PO has been processed as a SWA by the Call Center, the completed SWA is sent to the department indicated on the PO. The departments are expected to bill against the SWA in a timely fashion. If billing has been completed and the SWA needs to be closed, please email the Call Center with this request.</p>
			</div>
			
		</div>
		<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-3-tab'}"
		id="purchase-billing-3-tab" role="tabpanel" aria-labelledby="purchase-billing-3-tab">
		<div class="page-details-section">
			<h2 class="page-sub-heading">2.3 - Studio Work Authorization Form</h2>
			<p class="para-text-light studios-para">
				<a href="#" class="download-link" download><img src="assets/images/pdf-icon.svg">  DOWNLOAD STUDIO WORK AUTHORIZATION FORM</a>
			</p>
		</div>
		
	</div>
	<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-4-tab'}"
	id="purchase-billing-4-tab" role="tabpanel" aria-labelledby="purchase-billing-4-tab">
	<div class="page-details-section">
		<h2 class="page-sub-heading">2.4 - Construction Flat Bids</h2>
		<p class="para-text-light studios-para">
			WBSO Construction Services Departments can provide Flat Bids for service based on the following:
		</p>
		<ul class="bullet-list">
			<li>
				<span class="">The client/production company should provide drawings and schedule information.</span>
			</li>
			<li>
				<span class="">A SWA or P.O. needs to be issued before work can proceed.</span>
			</li>
			<li>
				<span class="">Any scope of work changes initiated by the client/production company which requires additions, deletions or modifications must be accompanied by an approved Construction Department “Change Order“. Approval must be followed by an immediate SWA and/or P.O. issued, prior to starting the work.</span>
			</li>
			<li>
				<span class="">WBSO Construction Department will not be responsible for delays or increases in costs of completion which result from forces outside its control such as: strikes, fire, casualty, act of God, failure of power or other public utilities, inability of WBSO Construction Department to obtain essential materials or qualified personnel, or Force Majeure whether similar or dissimilar to any of the foregoing. A completed Construction Department “Change Order” must also accompany changes in the costs of completion resulting from any of the above. Approval must be followed by an immediate SWA and/or P.O. issued prior to proceeding with the work.</span>
			</li>
			<li>
				<span class="">WBSO Construction Flat Bid Proposal does not include the costs such as Greens or Stage Rental. Any other exclusion will be noted at initial discussions.</span>
			</li>
		</ul>
		<div class="last-para-section">
			<p class="para-text-light">
				WBSO Construction Flat Bid Proposal does not include the costs such as Greens or Stage Rental. Any other exclusion will be noted at initial discussions.
			</p>
		</div>
	</div>
	
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'purchase-billing-5-tab'}"
id="purchase-billing-5-tab" role="tabpanel" aria-labelledby="purchase-billing-5-tab">
<div class="page-details-section">
	<h2 class="page-sub-heading">2.5 - Invoices</h2>
	<p class="para-text-light studios-para">The following page contains two sample invoices generated by WBSO. The first is an invoice that Office & Production Services generates locally to provide a broad overview of the costs associated with your stage and backlot usage. The second invoice is an output from our SAP system – generally sorted by WBSO department, as each department submits billing separately – which Accounts Receivable will distribute at the conclusion of each billing cycle. Most WBSO departments bill on a weekly basis, although there can be some variances (such as Telecom, who bill monthly).</p>
	<h3 class="page-sub-heading studios-para-no-paddding-upside">INVOICE DETAILS:</h3>
	<ul class="bullet-list">
	  <li>Production information including project title and address</li>
	  <li>PO number provided and quantity of service hours or materials</li>
	  <li>Service/materials description</li>
	  <li>Service date</li>
	  <li>Quantity</li>
	  <li>Unit of measure (how many days, weeks, etc.)</li>
	  <li>Rate and Invoice total</li>
	</ul>
	<div class="last-para-section">
	  <p class="para-text-light">Sample Invoice</p>
	  <a href="" download><img src="/assets/images/pdf-icon.svg">  DOWNLOAD SAMPLE INVOICE</a>
	</div>
  </div>
  
</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-tab'}"
						id="studio-protection-tab" role="tabpanel" aria-labelledby="studio-protection-tab">

					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-0-tab'}"
						id="studio-protection-0-tab" role="tabpanel" aria-labelledby="studio-protection-0-tab">
						<div class="page-details-section">
							<h2 class="page-sub-heading">3.0 Studio Protection Group (SPG)</h2>
							<p class="para-text-light studios-para">The Studio Protection Group is a comprehensive
								organization leading the effort in safety and security at Warner Bros.
								Studios, which consists of the following departments:</p>
							<ul class="bullet-list">
								<li><span class="">Warner Bros. Security –</span> A uniformed security division that
									oversees all aspects of studio access and preservation, as well as on-lot
									supervision and services (such as Set Watch).</li>
								<li> <span class="">Warner Bros. Fire – </span>A fully trained and equipped Fire
									Department with certified Emergency Medical Technicians (EMT’s).</li>
								<li> <span class="">Warner Bros. First Aid – </span>An all-Registered Nurse first aid
									department available to employees, clients and guests from 6 am to midnight,
									Monday-Friday (excluding holidays).</li>
								<li> <span class="">Warner Bros. Investigations</span></li>
							</ul>
							<div class="last-para-section">

								<p class="para-text-light">Chartered to provide the full gamut of protective services
									for Warner Bros. employees,
									clients, and property, SPG is trained and prepared to
									respond to any security or emergency issues that impact Warner Bros.
									worldwide. SPG is also the only approved source of security services on Warner Bros.
									Office & Production Services property, unless specific exceptions (such as private
									security
									for cast or intellectual property oversight) are requested and approved in advance.
								</p>
								<p class="para-text-light">The following SPG access policies have been developed to
									maintain safety on
									the Studio premises and to most effectively use the parking and other available
									resources. Please feel free to contact WBSO Security at any time if you have a
									question or any special requests.
								</p>
							</div>
						</div>
						
					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-1-tab'}"
						id="studio-protection-1-tab" role="tabpanel" aria-labelledby="studio-protection-1-tab">
						<div class="page-details-section">
							<h2 class="page-sub-heading">3.1 WB Security - Primary Contacts</h2>
							<div class="dispatch-list-section">
								<h3 class="list-title">Dispatch:</h3>
								<ul class="dispatch-list">
									<li>Office: <span class="contact-details">818-954-1248</span></li>
									<li>email: <span class="contact-details">WBSFDispatch@warnerbros.com</span></li>
								</ul>
							</div>
							<div class="dispatch-list-section">
								<h3 class="list-title">Dispatch:</h3>
								<ul class="dispatch-list">
									<li>Office: <span class="contact-details">818-954-1248</span></li>
									<li>email: <span class="contact-details">WBSFDispatch@warnerbros.com</span></li>
								</ul>
							</div>
							<div class="dispatch-list-section">
								<h3 class="list-title">Dispatch:</h3>
								<ul class="dispatch-list">
									<li>Office: <span class="contact-details">818-954-1248</span></li>
									<li>email: <span class="contact-details">WBSFDispatch@warnerbros.com</span></li>
								</ul>
							</div>
							<h2 class="page-sub-heading emergency-title">On-Lot Emergency FIRE/MEDICAL/SAFETY:</h2>
							<button class="btn btn-danger common-btn-danger">+1 (818) 954-3333</button>
							<p class="para-text-bold">Please never call 911 for an emergency while at Warner Bros.
								Studios,
								as this will delay the arrival of first responders from the Studio and City
								of Burbank alike. The 3333 emergency number funnels the call through WB Security
								Dispatch, which has a direct line to the Burbank emergency dispatch system
								(allowing gate officers to direct any incoming ambulances or fire engines to the proper
								location)
								in addition to activating immediate response from WB Fire and Security.
							</p>
						</div>
					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-2-tab'}"
						id="studio-protection-2-tab" role="tabpanel" aria-labelledby="studio-protection-2-tab">
						<div class="page-detail-section">
							<h3 class="page-sub-heading studios-para-no-paddding-upside">3.2-Warner Bros.Main Lot Gate Hours & Info</h3>
								<table class="common-table">
									<thead>
										<tr>
											<th>Gate</th>
											<th>Days</th>
											<th>Hours</th>
											<th>Notes</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>Executive Gate</th>
											<td>N/A</td>
											<td>N/A</td>
											<td>Not for production or general employee use.</td>
										</tr>
										<tr>
											<th>Gate 1</th>
											<td>Closed</td>
											<td>Closed</td>
											<td>Can be used for large truck entry or opened when deemed necessary. Cannot be blocked without Burbank Fire Dept. approval.</td>
										</tr>
										<tr>
											<th>Gate 2</th>
											<td>Mon - Sun</td>
											<td>24 hours</td>
											<td>Truck deliveries permitted through this gate only on weekends.</td>
										</tr>
										<tr>
											<th>Gate 2 (Walk Through)</th>
											<td>Mon - Sun</td>
											<td>24 hours</td>
											<td>Visitor passes can be obtained when Gate 3 Visitor Center is closed.</td>
										</tr>
										<tr>
											<th>Gate 3 (Franklin Structure)</th>
											<td>Mon - Sun</td>
											<td>24 hours</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 3 (Visitor Center)</th>
											<td>Mon - Fri</td>
											<td>5am - 8:15pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 4</th>
											<td>Mon - Fri</td>
											<td>4:30 am to 10 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 4 (Walk Through)</th>
											<td>Mon - Fri</td>
											<td>4:30 am to 10 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 5</th>
											<td>Mon - Fri</td>
											<td>4:30 am to 9 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 5 (Walk Through)</th>
											<td>Mon - Fri</td>
											<td>4:30 am to 9 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 7</th>
											<td>Mon - Fri</td>
											<td>4:15 am to 10:45 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 7 (Walk Through)</th>
											<td>Mon - Fri</td>
											<td>5:00 am - 9:00 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 8 (Forest Lawn Parking Structure)</th>
											<td>Mon - Fri</td>
											<td>4:30 am - 8:45 pm</td>
											<td></td>
										</tr>
										<tr>
											<th>Gate 8 (Visitor Center)</th>
											<td>Mon - Fri</td>
											<td>4:30 am - 8:45 pm</td>
											<td></td>
										</tr>
									</tbody>
								</table>

							<h3>Warner Bros.Ranch Lot Gate Hours & Info</h3>
							<div class="page-table-section-2">
								<table class="common-table">
									<thead>
										<tr>
											<th>Gate</th>
											<th>Days</th>
											<th>Hours</th>
											<th>Notes</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">Gate 11 (Ranch Main Entrance)</th>
											<td>Mon - Sun</td>
											<td>24 Hours</td>
											<td></td>
										</tr>
										<tr>
											<th scope="row">Gate 12</th>
											<td>Closed</td>
											<td>Closed</td>
											<td>Can be used for large truck entry or opened when deemed necessary. Cannot be blocked without Burbank Fire Dept. approval.</td>
										</tr>
									</tbody>
								</table>
								
							</div>
							
						</div>
						
					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-3-tab'}"
					id="studio-protection-3-tab" role="tabpanel" aria-labelledby="studio-protection-3-tab">
					<div class="page-details-section">
						<h2 class="page-sub-heading">3.3 - Studio Access Policies & Procedures</h2>
						<br>
						<p class="para-text-light">Everyone entering Warner Bros. Studios or off-lot property – as a client, employee, guest or vehicle passenger – must be issued passes or credentials from WB Security (requested in advance by an authorized studio pass caller), and should be prepared to show a government-issued photo ID at any time in order to gain access. For all types of passes, the name on the WB-issued photo client ID badge or paper pass must exactly reflect the name on the person’s government-issued photo identification information. Any pass request that makes use of a variation from the government-issued ID (e.g. nicknames or stage names) will prohibit entry until the pass can be corrected. Any person arriving at the studio who does not have a pass in the studio’s visitor management system will be instructed to contact the person, department or production that hired or invited them to the lot. When this occurs at a drive-on gate, the vehicle carrying this person will usually be redirected to the nearest off-lot parking structure to wait until their pass has been entered – even if passes have been entered for the other passengers and/or driver.</p>
					</div>
				</div>
				<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-4-tab'}"
				id="studio-protection-4-tab" role="tabpanel" aria-labelledby="studio-protection-4-tab">
				<div class="page-details-section">
					<h2 class="page-sub-heading">3.4 - Access Categories & Pass Types</h2>
					<p class="para-text-light studios-para">Studio access for each person can be categorized as follows:</p>
					<ul class="bullet-list">
						<li><span class="">Drive-On:</span> Assigned to park inside the Warner Bros. studio gates, whether in an assigned space, a deregulated flat lot (Lot H open) or parking structure (Lot A – 3rd floor & above), or in a temporary visitor space.</li>
						<li><span class="">Off-Lot:</span> Assigned to park in an off-lot Warner Bros. parking structure (Gate 3 on Franklin Ave. or Gate 8 on Forest Lawn Drive) and enter the studio on foot. Each person parking in Off-Lot parking structures must park in the structure, collect their pass from that structure’s visitor center, and then walk across Forest Lawn Drive (Gate 8 to Gate 7) or Olive Avenue (Gate 3 to Gate 2), and enter Gate 7 or 2 on foot. They will be required to pass through a metal detector and provide the walk-thru officer with their Studio pass and photo ID.</li>
						<li><span >Walk-On:</span> No assigned parking on Warner Bros. property. Applies to a passenger riding with another driver who has been issued a Drive-On pass or a visitor arriving by other means (e.g., public transportation, rideshare, etc.), who must pick up a visitor pass at one of the locations listed directly below, and enter the studio through a pedestrian gate.
							<ul>
								<li class="bullet-list-pb">Gate 3 – Visitor Center</li>
								<li>Gate 8 – Visitor Center</li>
								<li>Gate 5 – Walk-Thru</li>
								<li>Gate 11 – Walk-Thru</li>
								<li>Building 160 (Studio Plaza)</li>
							</ul>
						</li>
					</ul>
				
					<p class="para-text-light">The different forms of WBSO Security passes and credentials are as follows:</p>
					<ul class="bullet-list">
						<li><span class="">Daily Pass:</span> Two identical paper passes, issued daily by WB Security upon arrival at the Studio gates (drive-on parking) or at the walk-on pass distribution locations listed above (off-lot parking or walk-on). One pass to be kept with the passholder, at all times. One pass to be displayed inside the windshield of the vehicle, at all times (does not apply to Walk-On access).</li>
						<li><span class="">Client ID Badge:</span> A personalized card with passholder’s photo. To be used in conjunction with one of the following parking assignments:
							<ul>
								<li class="bullet-list-pb">Parking Decal</li>
								<li>Extended Pass: Paper pass, typically printed on green stock and valid until expiration</li>
							</ul>
						</li>
						<li>Note: Long-term passholders with drive-on privileges can apply for a FastPass decal, which is installed by WB Security on the front-passenger headlight of a personal car and allows for automated passage through Studio gates. Client ID badge and extended parking applications are reviewed by WB Parking Services and Security, and will only be issued to personnel working continuously on a project at Warner Bros. Studios for more than 30 days. Daily passes will be used for all cast, crew and visitors who don’t meet that criteria.</li>
					</ul>
				
					<h2 class="page-sub-heading studios-para-no-paddding-upside">Main Lot Access – Large Vehicles (Trucks, Trailers, Vans, Etc.)</h2>
					<p class="para-text-light">All delivery vehicles, production vehicles, heavy equipment, trucks or vans requiring access to the Main Lot must first pass through Truck Inspection at Gate 9. The address for Gate 9 is 6578 Forest Lawn Dr., Los Angeles, CA 90068 on the opposite side of the street from the Studio. Upon receiving a delivery clearance pass (Truck Inspection Point Pass), vehicles must proceed immediately to Gate 7 for lot entrance, or to the designated peripheral buildings. Note: Truck Inspection is only open on Monday through Friday, from 6:15 am to 2:45 pm. From 2:45 pm to 10:45 pm, equipment can proceed directly to Gate 7. All equipment brought in outside of these hours must be discussed with Office & Production Services so that alternate entrance can be arranged.</p>
				
					<h2 class="page-sub-heading studios-para-no-paddding-upside">Ranch Access – Large Vehicles (Trucks, Trailers, Vans, etc.)</h2>
					<p class="para-text-light">All delivery vehicles, production vehicles, heavy equipment, trucks or vans requiring access to the WB Ranch must pull over at the inspection area just inside Gate 11. Inspections will be performed by Security Officers as deemed necessary. Upon receiving a delivery clearance pass (Truck Inspection Point Pass), these vehicles must immediately proceed to the designated location on the pass.</p>
				
					<h2 class="page-sub-heading studios-para-no-paddding-upside">Taxis & Rideshare Vehicles (Lyft, Uber, et. al.)</h2>
					<p class="para-text-light">Except for situations in which a taxicab is required to transport an injured person, taxis and rideshare vehicles are not allowed on the lot. Passengers arriving by taxi or rideshare must be dropped safely at an off-lot location – preferably Gate 3 or Gate 8 parking structure, where the Walk-On pass can be collected. Taxi and rideshare pickups/drop-offs may not occur at any drive-in gate, as there is no safe location in which to pull over without impeding traffic. If a person is injured and needs transportation to a local health care facility, a supervisor from WB First Aid, Security or Fire may call a taxicab and authorize access on the lot if an alternate driver is not available. In such situations, the injured person must keep his/her/their studio access credentials and photo identification, as they will need to be presented when returning to the Studio.</p>
				
					<h2 class="page-sub-heading studios-para-no-paddding-upside">Children</h2>
					<p class="para-text-light">No one under the age of 16 will be admitted unless accompanied by an adult. To enter the premises, an access pass must be requested for both the child and their adult chaperone, with a note on the child’s pass referencing that the child is under 16, and a note on both passes to confirm the two are arriving together. As previously described, the adult must show a government issued photo identification upon arrival. The underage visitor must always remain with a parent or guardian while on Warner Bros. premises. Children / minors may be asked to present further identification (e.g., valid driver’s license, state identification card, student photo identification card or passport) at the discretion of WB Security. Children who regularly accompany an adult for more than 30 days may be issued a digital photo identification access card. Any questions regarding children/minors should be addressed to the WBSO Security Watch Commander at 818-954-4838.</p>
				
					<h2 class="page-sub-heading studios-para-no-paddding-upside">Short-Term Clients: 30 Days Or Less</h2>
					<p class="para-text-light">For all productions and clients occupying the Studio for less than 30 days, passes will be requested through Office & Production Services. Please contact your assigned Office & Production Services Manager directly. Daily Pass: two identical paper passes, issued daily by WB Security upon arrival at the Studio gates (drive-on parking) or at the walk-on pass distribution locations listed above (off-lot parking or walk-on). One pass to be displayed inside the windshield of the vehicle, at all times. One pass to be kept with the passholder, at all times. Note: Government-issued photo ID will be required daily in order to receive a daily pass – no exceptions. Unless schedule exceptions are discussed in advance, a drive-on list must be submitted to Office & Production Services by 4:00 pm the weekday afternoon prior to each day of your activity (prep / shoot / strike). The list template is available on the following page. Please submit in Excel format (not PDF), as that allows for a direct upload to our pass system. The list should include:</p>
					<ul class="bullet-list">
						<li>Full name (first and last – no middles or initials) of each driver or passenger who will be entering the lot, as it reads on their driver’s license or photo ID.</li>
						<li>If the guest is a minor, please include his/her/their name, as well as that of the adult who is bringing them here.</li>
						<li>Please sort the list alphabetically by last name, so the gate officers can reference it at the gate in the event of a misspelling.</li>
						<li>The assigned parking designation for each person, whether Drive-On, Off-Lot Parking, Walk-On, or some combination thereof.</li>
					</ul>
					<p class="para-text-light">
					All passes will be entered from 12:15 am to 11:45 pm each day. Guests should anticipate and allow ample time for collecting a new pass upon arrival each day. The list should be submitted by email to BOTH of the following addresses:</p>
					<ul class="bullet-list">
						<li>Office & Production Services Manager assigned to your production</li>
						<li>ProductionDriveons@warnerbros.com</li>
					</ul>
					<p class="para-text-light">Additions to the daily drive-on list can be sent individually by email or called in to the Office & Production Services office at 818-954-6777 during business hours (7 am to 7 pm, Monday-Friday excluding holidays). When sending revised versions of the pass list, please highlight any changes from versions sent previously – including all names called or emailed individually – so we can reduce multiple entries for each person and provide WB Security with an accurate list as a cross-reference. Call sheets may be used by gate officers as a reference only for cast and above the line production personnel. They should never be considered an alternative to a submitted pass request list. Any decal or studio access pass that is found to be altered, edited or duplicated will be subject to confiscation, and the vehicle is subject to tow at the owner’s expense, which may lead to progressive discipline.</p>
				</div>
				
			</div>
			<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-5-tab'}"
			id="studio-protection-5-tab" role="tabpanel" aria-labelledby="studio-protection-5-tab">
			<h3 class="page-title no-border-bottom">3.5-WBSO Pass Request Form</h3>
			<div class="page-details-section">
				
			<a href="" download><img src="assets/images/pdf-icon.svg">  DOWNLOAD WBSO PASS REQUEST FORM</a>
			</div>
			
		</div>
		<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-6-tab'}"
		id="studio-protection-6-tab" role="tabpanel" aria-labelledby="studio-protection-6-tab">
		<div class="page-details-section">
			<h2 class="page-sub-heading">3.6 WBSO Security – Traffic Department: Rules & Enforcement</h2>
			<p class="para-text-light studios-para">
			  WB Traffic Officers patrol the Main Lot and Ranch Facility daily. Traffic Officers are responsible for enforcing parking policies, issuing citations for violation of the policies, and locating and initiating removal of any illegally parked vehicles. Studio Traffic Policies The following guidelines apply to all vehicles at all times. This includes, but is not limited to, bicycles, motorcycles, electric carts, cars, trucks, or any production-related vehicle.
			</p>
			<ul class="bullet-list">
			  <li>Adhere to the Studio speed limit of 8 mph in all locations.</li>
			  <li>Be mindful of stop signs, pedestrian walkways, and general right-of-way considerations. This includes turning at the appropriate width and not cutting corners.</li>
			  <li>3rd Street Pedestrian Walkway: The 4-foot lane on the eastbound side of the street (beginning at Gate 2 and extending to the NE corner of Stage 25) is for pedestrians only and is not for vehicle use of any kind.</li>
			  <li>6th Street Between Stage 30 and Stage 48: This road is closed to thru traffic aside from bicycles and electric carts, unless an exception has been requested through Office & Production Services.</li>
			  <li>Do not overload vehicles. The use of an electric cart to carry large items which increases the size of the cart’s dimensions is not authorized.</li>
			  <li>Do not exceed the number of passengers for the cart’s designed seating (i.e., 2 seats/2 persons, 4 seats/4 persons, etc.)</li>
			  <li>No scooters, skateboards, roller blades, skates, or any like conveyance can be used on the lot except for pre-approved production purposes. This includes any self-propelled or motorized versions of this type of conveyance. Due to congested production environments, these types of conveyances pose an inherent safety risk.</li>
			  <li>Honking horns, loud radios, and any other excessive noise is prohibited while on studio property.</li>
			  <li>Any vehicle parking in a manner which violates WB policy and poses a traffic hazard, impediment to production, or safety concern is subject to being towed without notice at the owner’s expense. Parking violations include but are not limited to: Parking in a red zone, Obstructing roadway, Unauthorized Parking, Expired parking pass/decal, Improper or failure to display parking decal, extended vehicle pass, or daily pass, Any moving traffic violation.</li>
			  <li>Deliveries must have a pass requested for a specific stage space and can only utilize an assigned stage space, elephant door delivery zone, or available exterior delivery zone. Vehicles are never to be left unattended; the driver must be present and keys must remain in the ignition and ready to move in the event of a fire or life safety emergency. Deliveries are never to take place while impeding fire lanes or thoroughfares unless a WBSO Security Officer has been requested (at production’s expense) for standby traffic control.</li>
			  <li>Citations can be issued by Burbank Police Department on all accessible and fire lane parking violations. Unauthorized parking in an accessible space is a municipal violation, even on private property. Further, any employee receiving one (1) ticketed warning for either unauthorized parking in an accessible space, or falsifying or altering a decal, extended pass, or daily pass, may lose his/her/their parking privileges.</li>
			  <li>Exterior Set Parking: Production equipment such as trucks, trailers, lifts, and dressing rooms may not be parked in parking spaces, traffic lanes, or any other area, outside the production’s assigned stage parking area or the back-lot street being rented, without making prior arrangements through WBSO Office and Production Service. There will be a charge for occupying additional space, and for displacing any reserved parking areas.</li>
			  <li>Hazmat Security Escorts: WBSO Security Traffic Officer escorts are required for all propane/fuel, waste management, and hazardous material trucks, including after-hours. Propane trucks and hazardous materials deliveries to the Main Lot may enter with Security escort, through Gate 7 (reporting first to Gate 9 during its hours of operation; reporting directly to Gate 7 otherwise, or Gate 2 when all other gates are closed). Such deliveries to the Ranch should use Gate 11. Trash vendors, recycling vendors, and dressing room pump-out trucks should follow the same access procedure. These vendors are encouraged to pick-up/drop-off before 7 am or after 7 pm, Monday through Friday.</li>
			  <li>Tow Truck Policy and Procedure: Passes for a tow or roadside service must be requested through the WB Security Watch Commander, who will approve on a case-by-case basis. Once approved, the employee must instruct the service driver on where to report (including Gate 9, during its hours of operation) and must meet the service driver at the gate of entry, as designated by the Watch Commander. The service driver’s pass will be entered on-demand at the gate, per the Watch Commander’s approval and based on the driver’s government-issued photo identification.</li>
			  <li>Electric Carts: The California Department of Motor Vehicles requires any electric cart with a seating capacity of 4 or more to be properly registered as a motor vehicle if operated outside of private property. Even with proper registration, the operation of an electric cart on city streets is limited to streets with a top speed limit of 25 mph. Crossing a higher speed roadway from Warner Bros. property directly to Warner Bros. property is authorized if the transit is direct and can be safely navigated (e.g., between Gate 2 and Gate 3, or between Gate 7 and Gate 8). Those who operate electric carts illegally off lot are subject to the same scrutiny and liabilities as those operating a motor vehicle. Anyone observed operating an electric cart off lot that is not properly registered, or in violation, of DMV regulations will be reported to Warner Bros. Security management for appropriate actions. For information regarding the registration of electric carts meeting the DMV Standard, please contact Security at 818-954-1248.</li>
			  <li>WBSO Security Traffic Citations and Parking Suspension Appeals: Anyone authorized to park on Warner Bros. property is expected to park only in the space to which they have been assigned and is subject to the associated WBSO parking regulations as described in this handbook. Parking and moving violations on WB property subject the individual to a citation issued by an authorized WB Security Officer. The receipt of three (3) traffic citations within any twelve (12) month period subjects the individual to termination of his/her/their parking privileges for ninety (90) days. Parking terminations can be appealed within five (5) days of the receipt of a letter notifying of suspension of parking privileges. Failure to notify of an intent to appeal within the five (5) days activates the termination. Appeals can only be received in writing via email or letter to the Vice President, Studio Protection Group. Once an individual’s parking privileges are terminated, their parking decal and/or Extended Parking Pass must be relinquished, and they are not allowed to park on any Warner Bros. property for the duration of their suspension period. Upon completion of their suspension period, the individual must apply with the Director of OSPM, Parking Services to acquire a replacement parking decal/Extended Vehicle Parking Pass and parking assignment. If the suspended individual is discovered parked on the property during the suspension period, their vehicle will be impounded without notification and at the owner’s expense and may lead to further or permanent termination of parking privileges as deemed appropriate by the Studio Protection Group.</li>
			</ul>
			<div class="last-para-section">
			  <p class="para-text-light">
				WB Traffic Officers patrol the Main Lot and Ranch Facility daily. Traffic Officers are responsible for enforcing parking policies, issuing citations for violation of the policies, and locating and initiating removal of any illegally parked vehicles.
			  </p>
			</div>
		  </div>
		  
		
	</div>
	<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-7-tab'}"
	id="studio-protection-7-tab" role="tabpanel" aria-labelledby="studio-protection-7-tab">
	<div class="page-details-section">
		<h2 class="page-sub-heading">3.7 - WBSO Security – Production Services</h2>
		<p class="para-text-light studios-para">
			With a few specialized exceptions, Warner Bros. Office & Production Services requires that any on-lot security services be performed by uniformed WBSO Security officers. This includes set watch (supervision of production property at an exterior set or stage entrance; standby coverage in an occupied space to which production has been granted access for filming activity), guest reception (check-in for audience members; supervision along their route on and off the lot), and any form of traffic control (enforcement of production set lock-ups; escort or standby for large equipment that may impede thoroughfares) – some of which may be elective, and some of which may be required by Office & Production Services. These requests can be ordered directly through WB Security Scheduling at 818-954-1294 or wbsfsecurityscheduling@warnerbros.com. Please have your GL or SWA number ready to include in this order.
		</p>
		<div class="last-para-section">
			<p class="para-text-light">
				There are instances in which WBSO will approve personal security escorts for on-camera performers and/or other high-profile individuals, or production company security who supervise the protection of intellectual property. In ALL instances, these approved security personnel will be required to be unarmed and out of uniform. Please contact Office & Production Services at 818-954-6777 to initiate this request.
			</p>
		</div>
	</div>
	
	
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-8-tab'}"
id="studio-protection-8-tab" role="tabpanel" aria-labelledby="studio-protection-8-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">3.8 - WBSO Security – Vendor Delivery Policies</h2>
    <p class="para-text-light studios-para">
        No vendors are permitted to drive onto the Main Lot or Ranch, with the exception of those listed on the WB Security Authorized Vendor List (delivering to WB Food Services) or approved by Office & Production Services on a short-term (usually one-day) basis. All other non-authorized vendors, flower deliveries, food, beverages, and gift deliveries (including packaged gift food such as gift baskets) are not permitted to access the Studio or Ranch Facilities at any time. You may contact Office & Production Services at 818-954-6777 for additional clarification. Exceptions will rarely be granted unless they are needed for on-camera purposes, so please prepare any vendors servicing your production to follow the protocol listed here.
    </p>
    <ul class="bullet-list">
        <li><span>Central Delivery – Main Lot:</span> Located at Gate 9, near the truck inspection station.<br>
            <span>Hours of operation:</span> 8:00 am to 6:00 pm, Monday thru Friday
        </li>
        <li><span>Central Delivery – Ranch:</span> Located at the Ranch, near the Gate 11 Visitor Center.<br>
            <span>Hours of operation:</span> 8:30 am to 5:15 pm, Monday thru Friday
        </li>
    </ul>
    <p class="para-text-light studios-para">
        All deliveries must be made during these hours unless a request is submitted in advance to the Security Watch Commander at (818) 954-4838. After-hours delivery exceptions include the delivery of picture vehicles and express courier vehicles, which may be inspected at Gate 7 or Gate 11. Central Delivery will accept items such as letters, small packages, tapes, film, flowers, and gift baskets from outside messengers, couriers, and vendor delivery services. All deliveries which are accepted by Central Delivery are redelivered to on-lot recipient(s). Central Delivery will not take possession of cartons in excess of 100 lbs. total, or hot or cold prepared foods whether in takeout or bulk orders – these must be picked up directly at an off-lot gate by employees, tenants, or production representatives.
    </p>
    <h2 class="page-sub-heading">Express Couriers and Freight Vendors – Pick-up and Delivery Policy</h2>
    <p class="para-text-light studios-para">
        The following companies are Authorized Express Couriers, collecting materials from Corporate Logistics & Traffic, located at Building 156 North, Room 1050:
    </p>
    <ul class="bullet-list">
        <li>DHL Worldwide Express</li>
        <li>DHL Global Forwarding</li>
        <li>FedEx</li>
        <li>UPS</li>
        <li>IBC Pacific</li>
        <li>Marken Time Critical Express</li>
        <li>Midnite Express</li>
        <li>Network Courier Services</li>
        <li>DHL Same Day</li>
        <li>UPS</li>
    </ul>
    <p class="para-text-light">
        The following companies are Authorized Express Vendors handling mail services located @ Bridge Building 156 North, Room 1000:
    </p>
    <ul class="bullet-list">
        <li>USPS (United States Postal Service)</li>
    </ul>
    <p class="para-text-light">
        The following companies are Authorized Express Vendors handling mail pick-up for film shipping from Building 4, Room 147:
    </p>
    <ul class="bullet-list">
        <li>DHL (call in basis)</li>
        <li>UPS Express</li>
        <li>*FedEx (daily delivery)</li>
       <li>*FedEx also picks up from Building </li>
	   <li>Pick up services are daily and are generally between the hours of 3:00 pm to 7:00 pm, Monday through Friday. Please contact WBSO Corporate Traffic department at (818) 954-4704 should there be any questions.</li>
    </ul>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-9-tab'}"
id="studio-protection-9-tab" role="tabpanel" aria-labelledby="studio-protection-9-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">3.9 - Warner Bros. Entertainment Inc. Firearms-Weapons Policy</h2>
    <p class="para-text-light studios-para">
        Warner Bros. Entertainment Inc. prohibits any firearm or weapon of any type to be possessed on Company owned, leased, or controlled property within the United States. The substance of this policy should also be applied in territories outside the United States unless the laws or regulations of a location provide otherwise. The intended goal of this policy is to maintain a safe working environment for all Warner Bros. employees and visitors.
    </p>
    <ul class="bullet-list">
        <li>Exceptions to this policy exist for (1) designated management personnel within the WBSO Security Department (as determined by SVP Warner Bros. Worldwide Security or VP, Warner Bros. Studio Protection), (2) prop weapons that are approved and monitored by the Warner Bros. Property and Special Effects Departments, and (3) law enforcement personnel in the performance of their official duties.</li>
        <li>All prop weapons and firearms used in filming must be approved in advance by production management and must be handled, transported, and stored by approved personnel in accordance with applicable local, state, and federal regulations.</li>
        <li>Law Enforcement personnel who are on duty and performing their official functions are authorized to carry firearms on Warner Bros. properties with appropriate notification to the Warner Bros. Security or Warner Bros. management personnel where Warner Bros. Security may not be present. Law Enforcement personnel in an off duty status or when not conducting their official law enforcement functions are not authorized to carry weapons on Warner Bros. properties. As Warner Bros. Entertainment Inc. operates on private property, this policy is intended to supersede any policy of a law enforcement agency which might require the law enforcement officials to carry a weapon at all times. Exceptions to this policy may be granted on a case by case basis only by SVP, Warner Bros. Worldwide Security or VP, Warner Bros. Studio Protection.</li>
        <li>Bodyguards or personal security entering Warner Bros. property with on-camera performers or other high-profile individuals are also held to this policy. Performers and other high-profile individuals may continue to employ private security personnel who may accompany them onto Company controlled property, but these persons must be unarmed and out of uniform. If requested, WBSO Security and the local Police Department will provide any additional assistance necessary to facilitate a safe environment.</li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light">
            This Firearms/Weapons policy is in effect for all Productions, on or off Company owned or leased property, whether produced by Warner Bros. or by an affiliated production company. Except as noted above, this policy applies in any country, state, or region in which a Warner Bros. production is based and included, but is not limited to the production office, base camp, set, or warehouse/lock-up facility.
        </p>
        <p class="para-text-light">
            Anyone found to be in violation of this policy will face disciplinary action which could include termination, and in some circumstances, criminal charges.
        </p>
        <p class="para-text-light">
            Any questions regarding this policy can be directed to your Office & Production Services manager, the Warner Bros. Security Department, or the Warner Bros. Safety Department.
        </p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-10-tab'}"
id="studio-protection-10-tab" role="tabpanel" aria-labelledby="studio-protection-10-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">3.10 - SPG: Warner Bros. First Aid Dept. - Information & Regulations</h2>
    <div class="dispatch-list-section">
        <h3 class="list-title">ON-LOT EMERGENCY LINE FIRE/MEDICAL/SAFETY</h3>
        <ul class="dispatch-list bullet-list">
            <li>Office: <span class="contact-details">818-954-3333</span></li>
            <li class="para-text-light">Please never call 911 for an emergency while at Warner Bros. Studios, as this will delay the arrival of first responders from the Studio and City of Burbank alike. The 3333 emergency number funnels the call through WB Security Dispatch, which has a direct line to the Burbank emergency dispatch system (allowing gate officers to direct any incoming ambulances or fire engines to the proper location) in addition to activating immediate response from WB Fire and Security.</li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">WARNER BROS. FIRST AID – PRIMARY CONTACTS</h3>
        <ul class="dispatch-list bullet-list">
            <li>Main Lot First Aid Clinic</li>
            <li>Office: <span class="contact-details">818-954-1201</span></li>
            <li>Email: <span class="contact-details">mainlotfirstaid@warnerbros.com</span></li>
            <li>Ranch First Aid Clinic</li>
            <li>Office: <span class="contact-details">818-977-5251</span></li>
            <li>Email: <span class="contact-details">ranchfirstaid@warnerbros.com</span></li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Purpose</h3>
        <p class="para-text-light">Warner Bros. First Aid is responsible for all primary first aid and responds to all medical emergencies. There are separate offices on the Main Lot and Ranch, which are staffed for productions and employees Monday through Friday (excluding holidays), from 6:00am to midnight in accordance with the WBSO Facility Agreement and 3rd Party Studio Lease Agreements. If there are no productions shooting on the lot, the First Aid Clinic will be available until 8:00 pm on the Main Lot and 7:00 pm on the Ranch.</p>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Services Provided</h3>
        <ul class="bullet-list">
            <li>Registered Nurse on duty</li>
            <li>Basic life support with emergency medication management</li>
            <li>On-site AED</li>
            <li>Local treatment of injuries and illnesses, work-related and otherwise</li>
            <li>Wellness Checks</li>
            <li>Blood Pressure monitoring</li>
            <li>Weight checks</li>
            <li>Health information consultations and education</li>
            <li>Over-the-counter medications (recipient must visit the office in person)</li>
            <li>On-set stunt coverage (30 minutes or less)</li>
            <li>Treatment of personnel whose absence would halt production</li>
            <li>On-site hire for WB Special Events</li>
            <li>Participation in on-lot health/emergency services programs and outreach</li>
            <li>Trend analysis / reports for your production</li>
            <li>Worker’s comp referrals</li>
            <li>Documentation</li>
            <li>Documentation of visits to the First Aid Clinic</li>
            <li>Workers Compensation Documentation</li>
            <li>Case monitoring of work-related injuries</li>
            <li>Cal-OSHA recordkeeping</li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Daily Coverage Guidelines</h3>
        <p class="para-text-light">Coverage outside the Monday-Friday, 6 am to midnight window requires a general ledger number (GL), studio work authorization (SWA), or purchase order (PO), with the order placed to First Aid directly by production or through the Office & Production Services department. Please inquire directly for current overtime rates. Coverage is based on production’s estimated wrap time. Each shooting production will receive a daily phone call from First Aid (approximately 5:30 pm) requesting that day’s estimated wrap time and “last person out” time (generally estimated at 60-90 minutes after wrap) to confirm staffing needs. Production will be reminded to call once the last crew member has vacated the stage that night. Someone on each production should be responsible to call First Aid once the stage is empty, to release the First Aid nurse from duty. Please stay in touch with First Aid with any changes in the estimated wrap time or other delays. Please note: a No Call fee will be assessed for not calling First Aid when the stage is cleared, to cover the additional nursing coverage costs incurred until First Aid learns directly of the actual camera wrap or last man out status either from production or from Security. All requests for coverage outside of the 6 am to midnight timeframe must be received no later than 5:00 pm the evening immediately preceding. If the request is received after 5:00 pm, First Aid will attempt to accommodate such request but cannot guarantee coverage. When First Aid is unavailable, the WBSO Fire Department is able to provide limited care and guidance.</p>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Production Information Form</h3>
        <p class="para-text-light">Several days prior to the first day of studio occupancy, Warner Bros. First Aid will contact the Production Coordinator or UPM to request contact names, phone/fax numbers, payroll company or WBSO payment account, and confirmation of prep/shoot/strike schedule. Please complete the attached form and fax or email to First Aid.</p>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Set Medic</h3>
        <p class="para-text-light">Warner Bros. First Aid does not currently provide this service. Productions are allowed to bring in their own dedicated set medic, but all medics must exchange contact info with WB First Aid prior to arrival.</p>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title">Employee Assistance Referral Program</h3>
        <p class="para-text-light">Employees have access to confidential crisis intervention or counseling services for a variety of mental and physical health-related issues, such as substance abuse, depression, anxiety, etc. If an employee requires these services, please refer him/her/them to the appropriate behavioral healthcare provider:</p>
        <ul class="bullet-list">
            <li>IATSE and Basic Crafts-represented employees who are eligible for Motion Picture Industry Health Plan (“MPIHP”) benefits should contact Optum Health Behavioral Solutions at 888-661-9141.</li>
            <li>Non-represented employees and IATSE and Basic Crafts-represented employees who are not eligible for MPIHP benefits should contact CCA at 800-833-8707.</li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <p class="para-text-light">Questions? Please do not hesitate to call us at the numbers listed above. We will be happy to assist you!</p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-11-tab'}"
id="studio-protection-11-tab" role="tabpanel" aria-labelledby="studio-protection-11-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">3.11 - WBSO First Aid / Health & Wellness Services</h2>
    <p class="para-text-light studios-para">
        <a href="" download><img src="assets/images/pdf-icon.svg">  DOWNLOAD FIRST AID / HEALTH & WELLNESS</a>
    </p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-12-tab'}"
id="studio-protection-12-tab" role="tabpanel" aria-labelledby="studio-protection-12-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">3.12 - SPG: Warner Bros. Fire Dept. – Information & Regulations</h2>
    <div class="dispatch-list-section">
        <h3 class="list-title">ON-LOT EMERGENCY LINE FIRE/MEDICAL/SAFETY</h3>
        <ul class="dispatch-list bullet-list">
            <li>Office: <span class="contact-details">818-954-3333</span></li>
        </ul>
    </div>
    <p class="para-text-light">
        Please never call 911 for an emergency while at Warner Bros. Studios, as this will delay the arrival of first responders from the Studio and City of Burbank alike. The 3333 emergency number funnels the call through WB Security Dispatch, which has a direct line to the Burbank emergency dispatch system (allowing gate officers to direct any incoming ambulances or fire engines to the proper location) in addition to activating immediate response from WB Fire and Security.
    </p>
    <!-- <h3 class="list-title list-title-pb-0"><strong>WARNER BROS. FIRE – PRIMARY CONTACTS</strong></h3> -->
    <div class="dispatch-list-section">
        <h3 class="list-title list-title-pb-0">WBFD – Fire Operations Manager – Stuart Stefani</h3>
        <ul class="dispatch-list bullet-list">
            <li>Office: <span class="contact-details">818-954-5582</span></li>
            <li>Email: <span class="contact-details">stuart.stefani@warnerbros.com</span></li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title list-title-pb-0">WBFD – Station 18</h3>
        <ul class="dispatch-list bullet-list">
            <li>Office: <span class="contact-details">818-954-1261</span></li>
            <li>Email: <span class="contact-details">WBFireStation18@warnerbros.com</span></li>
        </ul>
    </div>
    <div class="dispatch-list-section">
        <h3 class="list-title list-title-pb-0">Burbank Fire Department – On-Site Captain – John Freeborn</h3>
        <ul class="dispatch-list bullet-list">
            <li>Office: <span class="contact-details">818-954-1307</span></li>
            <li>Cell: <span class="contact-details">714-954-2233</span></li>
            <li>Email: <span class="contact-details">jfreeborn@burbank.ca.gov</span></li>
            <li>On-Site: <span class="contact-details">Tuesday-Friday weekly, aside from holidays; available otherwise by cell or email</span></li>
        </ul>
    </div>
    <div class="last-para-section">
        <h3 class="list-title"><strong>Purpose</strong></h3>
        <p class="para-text-light">
            Warner Bros. Fire Dept. and the onsite Burbank Fire Dept. Captain work together to establish minimum fire/life safety requirements. The following regulations and policies apply for Warner Bros. Main Lot, Ranch, and adjoining facilities or locations. In preparation for set construction and production, please contact the on-site captain from Burbank Fire Department to determine any special requirements during the construction phase. Please provide the following items to Office & Production Services in advance of stage occupancy, for Burbank Fire Dept. review and approval.
        </p>
        <ul class="bullet-list">
            <li>Complete set of blueprints and/or set designs</li>
            <li>Production schedule</li>
            <li>Contact list for department heads from construction, lighting, rigging, audience coordinator and transportation</li>
        </ul>
        <p class="para-text-light">
            Upon approval, a Burbank Fire Dept. stamped plan or set of plans shall remain on site for review if necessary.
        </p>
    </div>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-13-tab'}"
id="studio-protection-13-tab" role="tabpanel" aria-labelledby="studio-protection-13-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">3.13 General Requirements</h2>
    <p class="para-text-light studios-para">The following requirements outline safety and operational protocols to maintain safety and compliance on Warner Bros. Studio premises:</p>
    <ul class="bullet-list">
        <li ><span>Aisles – Four-foot (4’) Perimeter</span>
            <ul>
                <li class="bullet-list-pb">All four ft. stage perimeters and exits shall be kept clear at all times, with a clear unobstructed height of seven feet.</li>
                <li>No storage or working placement will be allowed in the fire lane even on a temporary basis, i.e., no paint storage, set walls, debris, tool boxes, bicycles, set dressings, props, backings, set lighting, or cables.</li>
                <li>Exception: Cables, hoses, air conditioning, etc., which are ramped or matted to prevent a trip hazard where they cross the 4 ft. perimeter.</li>
                <li>This regulation follows the Burbank Municipal Code and is subject to enforcement by the City of Burbank. Stages and studios found in violation of this code face the possibility of a shutdown order until compliance is restored.</li>
            </ul>
        </li>
        <li><span>Food Service</span>
            <ul>
                <li class="bullet-list-pb">Operational Permit required.</li>
                <li>No open flame cooking/heating device shall be allowed on stage or in an interior backlot set without Burbank Fire Department approval, and food prep/service vehicles are not allowed on stages at any time.</li>
                <li>All cooking equipment shall be maintained in good working order and located a minimum of five ft. from any combustibles (sets, walls, etc.) and a minimum of ten ft. from any exit. Equipment shall not block any means of egress.</li>
                <li>All food service providers shall provide an adequate number of portable fire extinguishers (2A10BC minimum). Additional extinguishers shall be provided at any separate external food warming station(s). All extinguishers shall have current California State Fire Marshal service tags affixed.</li>
                <li>No barbeques are permitted on the lot unless approved by Office & Production Services and Burbank Fire Department.</li>
                <li>A Fire Safety Officer/Fire Watch may be required for open flame devices.</li>
            </ul>
        </li>
        <li><span>Decorative Materials: Scenic Backings, Green/Blue Screens, Drapes, Tents</span>
            <ul>
                <li class="bullet-list-pb">All backings, green screens, backdrops, drapes, and all other types of large cloth/fabric hangings shall meet flame retardant requirements of California Code of Regulations, Title 19.</li>
                <li>Copies of all flame certification will be required. The flame proofing certificates shall be sent to the Burbank Fire Department On-Site to be kept on file.</li>
                <li>These items shall have ID markings on the back that match the ID on the flame proofing certificate.</li>
                <li>Additional treatment is required every three years, or after washing.</li>
                <li>Warner Bros. Paint Department can flame-proof most materials. Please contact them at 818-954-1817 if you require this service.</li>
                <li>Translights cannot be flame-proofed, so they must have noncombustible edges. All lighting equipment must be kept from coming in direct contact with these backings.</li>
                <li>Tents or canopies require a separate permit and shall have a permanently affixed label designating California State Fire Marshal compliance.</li>
            </ul>
        </li>
        <li><span>Exits</span>
            <ul>
                <li class="bullet-list-pb">All exit doors shall remain unobstructed and in accordance with the California Fire Code and Burbank Municipal Code.</li>
                <li>The maximum travel distance to any exit within the sound stage shall be 150 ft.</li>
                <li>Emergency lighting (automatically provided by an approved emergency back-up system) is required by code and shall be provided in all areas, and exit signs (lighted) shall be placed at all exits and exit paths, as deemed necessary by the Fire Department.</li>
                <li>At the discretion of Burbank Fire Department, additional lighting and/or signage may need to be installed in off-camera locations within the set, at production’s expense.</li>
            </ul>
        </li>
        <li><span>Exterior Fire Lanes</span>
            <ul>
                <li class="bullet-list-pb">Designated fire lanes throughout the studio lot must remain clear for emergency access.</li>
                <li>Unless specifically permitted, production vehicles and/or equipment shall not obstruct fire lanes, fire equipment, or building exits, including stage elephant doors.</li>
                <li>Red striped areas next to fire hydrants shall always be kept clear.</li>
                <li>When a vehicle must be parked in an area other than a designated parking space, the driver shall always remain with the vehicle. Leaving the keys in the ignition is NOT an acceptable substitute for the driver’s presence.</li>
                <li>Trailers or other non-powered equipment (A-frames, dressing rooms, dollies, etc.) shall not be left in the fire lanes under any circumstances.</li>
                <li>Refueling trucks (gasoline, diesel fuel, propane, etc.) are not allowed to refuel or park next to any stage or building. All fuel trucks shall park in the “R” lot (River Road).</li>
            </ul>
        </li>
        <li><span>Fireplaces</span>
            <ul>
                <li class="bullet-list-pb">All practical fireplaces (those involving real flame or heat) shall meet Burbank Fire Department standards and specifications. Fireplaces must be inspected by the Burbank Fire Department prior to use.</li>
                <li>Permit required for each day of use. Please see the following section for information on obtaining a special effects permit through Warner Bros. Special Effects.</li>
                <li>No free burning combustibles are allowed in fireplaces.</li>
            </ul>
        </li>
        <li><span>Gasoline and Propane Powered Equipment</span>
            <ul>
                <li class="bullet-list-pb">High lifts, scissor lifts, cranes, forklifts, or any other gasoline or propane-powered equipment may be used on stages.</li>
                <li>All equipment shall be removed from the stage at the end of the workday unless special arrangements are made with WBFD.</li>
                <li>All propane or gasoline storage containers shall be removed from the stage at the end of the workday.</li>
                <li>Large stage doors (elephant doors) are to remain open while such equipment is in operation.</li>
                <li>Overhead exhaust fans shall be operating. Please contact WB Electrical at 818-954-1700 to activate the fans.</li>
                <li>Appropriate measures must be taken to assure that the vehicle will not damage the stage floor. Any questions about how much weight the floor on any particular stage can accept should be directed to Office & Production Services (818-954-6777).</li>
            </ul>
        </li>
        <li><span>Greens and Brush</span>
            <ul>
                <li class="bullet-list-pb">All non-living greens (generally, any greens that are cut prior to 3 days of use – e.g. cut brush, tree limbs, etc.) shall be treated with an approved fire retardant. Live foliage does not require treatment.</li>
                <li>All foliage will be subject to a flame test by the Burbank Fire Department On-Site Captain.</li>
            </ul>
        </li>
        <li><span>Housekeeping</span>
            <ul>
                <li class="bullet-list-pb">Proper housekeeping shall always be maintained. Sound stages must remain free from any condition that would create or contribute to fire or the rapid spread of fire.</li>
                <li>No building material can be stored within the red line surrounding the exterior of the stages.</li>
                <li>No building material can be stored in front of any sound stage doors (elephant doors), exit doors, or means of egress.</li>
                <li>No storage is allowed on top of hard deck sets, or any “open top” rooms (covered by wire mesh).</li>
                <li>No storage is allowed under audience seating.</li>
                <li>No storage is allowed under stairwells unless protected by automatic sprinkler system, with Burbank Fire Department approval.</li>
            </ul>
        </li>
        <li><span>Non-Set Locations</span>
            <ul>
                <li class="bullet-list-pb">Shooting in any part of the Studio that is not inside a soundstage or within the borders of an exterior set requires an occupancy permit from Burbank Fire Department, even when no open flame or other fire hazard is involved. Office & Production Services will arrange to have the On-Site Captain join the tech scout.</li>
            </ul>
        </li>
        <li><span>Paint Lockers</span>
            <ul>
                <li class="bullet-list-pb">All portable paint lockers shall conform to the California Fire and Burbank Municipal Code requirements.</li>
                <li>Approved paint lockers may be obtained through the WB Paint or Safety.</li>
                <li>Cabinets shall be conspicuously labeled in red letters on a contrasting background.</li>
                <li>Not more than three cabinets shall be located on any stage.</li>
                <li>All paint storage shall be placed in an approved paint locker at the end of each workday.</li>
            </ul>
        </li>
    </ul>
</div>

  
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-14-tab'}"
id="studio-protection-14-tab" role="tabpanel" aria-labelledby="studio-protection-14-tab">
<div class="page-details-section">
	<h2 class="page-sub-heading">3.14 - Evacuation Check List</h2>
	<p class="para-text-light studios-para">
	  Prior to the start of the show, the audience coordinator will make sure that anyone who needs special assistance to exit is identified and that a pre-assigned person is assigned to help them exit the stage in the event of an evacuation.
	  An evacuation could be ordered by the Fire Department, Studio Security, or production executives. Additional instructions shall include:
	</p>
	<ul class="bullet-list">
	  <li>Remain calm.</li>
	  <li>Move immediately (but without running) to the nearest building exit, using primary exit routes whenever possible. Do not use elevators.</li>
	  <li>If caught in smoke, take short breaths and breathe through the nose. Heat and smoke will rise, so please crawl along the floor. If forced to make a dash through smoke or flames, hold your breath.</li>
	  <li>Proceed directly to the assembly point designated for your stage or building; it will likely be at least 300 feet away. Maps to this location are posted on the interior of every stage pedestrian door.</li>
	  <li>Stay in the designated evacuation area until released or relocated by authorities.</li>
	  <li>The Fire Department will conduct a head count as soon as is practical to ensure that everyone is accounted for. Please immediately report any missing persons or injuries.</li>
	  <li>DO NOT GO BACK INTO THE STAGE UNTIL GIVEN THE ALL CLEAR FROM THE FIRE DEPARTMENT.</li>
	  <li>Please report any fire prevention or life safety issue to your Office & Production Services Manager.</li>
	</ul>
	<h2 class="page-sub-heading">EMERGENCY PROTOCOL: PREPARATION, RESPONSE & EVACUATION</h2>
	<p class="para-text-light studios-para">
	  ENTERPRISE BUSINESS RESILIENCY, The Warner Bros. Enterprise Business Resiliency Department is globally responsible for planning, maintenance, and testing in the areas of emergency response, crisis management, business recovery, and IT disaster recovery. Enterprise Business Resiliency’s mission is to protect people, key assets, and business critical operations from disruption that may lead to health/safety issues, impact revenue, or tarnish the brand. In addition, the Enterprise Business Resiliency team administers WBalerts, the Warner Bros. emergency notification system, and provides emergency planning and preparedness training.
	</p>
	<p class="para-text-light">For more information about the department, visit us at: <a href="http://www.wbready.com" class="link-text">http://www.wbready.com</a></p>
	<h2 class="page-sub-heading">WBalerts</h2>
	<p class="para-text-light studios-para">
	  WBalerts is the emergency notification system used by Warner Bros. Studios., administered by Enterprise Business Resiliency.
	</p>
	<h3 class="page-sub-heading">How it Works</h3>
	<p class="para-text-light studios-para">
	  In the event of an emergency, senior management can use the WBalerts system to send emergency notifications to a wide-reaching group of people simultaneously via email and text messages, to any phone or e-mail device registered with the system. The system enables quick dissemination of information, delivery of instructions, and collection of responses about individuals’ whereabouts, health, and safety in an emergency.
	</p>
	<h3 class="page-sub-heading">Privacy</h3>
	<p class="para-text-light studios-para">
	  WBready.com (the “Site”) is operated by Warner Bros. Entertainment Inc. (“WBEI”) and is part of the WBalerts emergency warning service, which can quickly disseminate information and deliver instructions to you via email, phone, or text message, in the event of an emergency.
	  All personal data that is collected through the Site (including your name and contact details) will be considered confidential, stored according to the strictest privacy and security standards, and will only be used to contact you in the event of an emergency. It is in WBEI’s legitimate interests to process your personal data for the purpose of ensuring the safety and security of those who work for WBEI. In an emergency, we may share your personal data with emergency first responders and government authorities. To provide the service, WBEI may also share your personal data with entities in the Warner Media group of companies and external service providers (such as IT system suppliers); these entities may be located in the USA. Data protection and privacy regulations in the USA may not offer the same level of protection as in other parts of the world, such as the European Union. WBEI ensures that appropriate safeguards are in place to protect the personal data collected through the Site. If you are in the European Union, WBEI ensures this by using EU Standard Contractual Clauses or by relying upon its service providers’ participation in a European Union approved international data transfer mechanism, including the EU-U.S. Privacy Shield Framework, the adoption of Binding Corporate Rules or another European Union approved data transfer mechanism. The personal data you provide will be retained for the lifetime of your employment or your engagement.
	  WBEI may transfer the personal data you have provided in the event of a business transaction or change of control (such as if it or one of its business units or its relevant assets are acquired by, sold to, or merged with another company), where it may be necessary for the new business owner to contact you in the event of an emergency.
	  If you have any questions or concerns about this Privacy Notice or its implementation, or you wish to update, delete, object, restrict or have access to the personal data that you have provided to the Site, please contact WBPrivacyOffice@warnerbros.com.
	  If you have any other questions about the Site, please contact the Enterprise Business Resiliency team at +1 818 954 4721 (USA) or +44 (0)207 984 6850 (UK).
	</p>
	<h3 class="page-sub-heading">Register Today!</h3>
	<p class="para-text-light studios-para">Visit the Enterprise Business Resiliency website and register with the WBalerts system using the online form: <a href="https://www.wbready.comjoin-wbalerts" class="link-text">www.wbready.comjoin-wbalerts</a></p>
	<h3 class="page-sub-heading">Questions?</h3>
	<p class="para-text-light studios-para">If you have further questions about WBalerts or the use of your data by Warner Bros., please email: <a href="mailto:bcp@warnerbros.com" class="link-text">bcp@warnerbros.com</a></p>
	<h2 class="page-sub-heading">Earthquakes</h2>
	<h3 class="page-sub-heading studios-para">WHILE THE GROUND IS SHAKING…</h3>
	<ul class="bullet-list">
	  <li>DO NOT MOVE to another location or outside</li>
	  <li>If on a stage, STAY CLEAR OF THE ELEPHANT DOOR</li>
	  <li>AVOID GLASS AND WINDOWS</li>
	  <li>BE AWARE OF AFTERSHOCKS</li>
	  <li>DO NOT USE ELEVATORS</li>
	</ul>
	<h3 class="page-sub-heading studios-para">ONCE THE SHAKING STOPS…</h3>
	<ul class="bullet-list">
	  <li>DO NOT IMMEDIATELY EVACUATE</li>
	  <li>ASSESS YOUR SURROUNDINGS</li>
	  <li>Acknowledge the difference between STRUCTURAL and NON-STRUCTURAL damage</li>
	  <li>EVACUATE IF YOU OBSERVE STRUCTURAL DAMAGE OR IF STAIRWELLS OR EXITS ARE BLOCKED OR DAMAGED</li>
	</ul>
	<p class="para-text-light studios-para">
		TO LEARN MORE ABOUT EARTHQUAKE SAFETY, PLEASE VISIT:<a href="https://www.dropcoverholdon.org" class="text-link">Dropcoverholdon.Org</a>
	  </p>
  </div>
  
</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-tab'}"
						id="safety-environment-tab" role="tabpanel" aria-labelledby="safety-environment-tab">

					</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-0-tab'}"
					id="safety-environment-0-tab" role="tabpanel" aria-labelledby="safety-environment-0-tab">
					<div class="page-details-section">
						<h2 class="page-sub-heading">4.0 - Overview</h2>
						<br>
						<p class="para-text-light">Warner Bros. Safety & Environmental Affairs provides support in three major areas: Occupational Health and Safety, Environmental Affairs, and Feature/TV Production Safety.</p>
						<p class="para-text-light">S&EA works with in-house employees and production to create production safety guidelines and monitors all Warner Bros. productions worldwide, including: WBSO, WB Pictures, WBTV, WB Animation, Warner Unscripted Television, Bonanza Productions, Telepictures, SHED Media, and New Line Cinema, LLC.</p>
						<p class="para-text-light">S&EA also acts as the interface between the studio and government agencies representing the enforcement of local, state, and federal codes regulating employee occupational and environmental health and safety. (<strong>e.g. CAL/OSHA</strong>) and the environmental health of Warner Bros. Studio (<strong>EPA, SCAQMD, CITY OF BURBANK & LAC DEPT. OF HEALTH</strong>). Warner Bros. expects all its employees to perform their work in a safe manner and report any safety concerns or unsafe practices they observe by calling the <strong>Anonymous Safety Hotline at <span class="contact-number-blue">818-954-2800</span></strong>. If you have any questions or concerns regarding environmental regulations, chemical containing products, ergonomic safety, or food safety in your work area, please contact the Safety office at <strong><span class="contact-number-blue">818-954-2890</span></strong>.</p>
						<p class="para-text-light">For the purposes of this WBSO Operational Handbook, we have provided the following brief overview of some of the guidelines for the safe use of the WB facility. This overview contains general safety guidelines for employees and supervisors, safety training information for employees, and examples of the types of events that would trigger WB S&EA involvement. We also have provided some technical information regarding our Soundstage Floors and Trusses; Set Construction Safety; how to manage chemical-containing products; and environmental regulations covering air and water quality.</p>
						<p class="para-text-light"><strong>“SAFETY ON THE SET” WEBSITE</strong></p>
						<p class="para-text-light">Additional safety information can be found on our S&EA website: <a href="http://www.safetyontheset.com" target="_blank">www.safetyontheset.com</a> and is available to all productions. There you will find a printable version of the Production Safety Manual for Warner Bros. and its affiliates, Codes of Safe Practices covering employee safety for both construction and production personnel, Tool Box Talks, updated Safety Bulletins from the Alliance of Motion Picture and Television Producers (AMPTP), and the Safety Forms you will need to complete regularly during the construction, production and strike phases of your show.</p>
						<p class="para-text-light">The website is regularly updated to include new Occupational Safety & Health Administration (OSHA) regulations and information that will be helpful to your production in achieving compliance. Every employee has a role and responsibility in safety.</p>
						<p class="para-text-light">All employees, regardless of position, are required by law to notify management of any existing potential safety hazards of which they are aware. Use the <strong>Anonymous Safety Hotline <span class="contact-number-blue	">(818) 954-2800</span></strong>.</p>
						<p class="para-text-light"><strong>HAVING A WELL-DEFINED AND DOCUMENTED SAFETY PROGRAM WILL HELP REDUCE ACCIDENTS AND INJURIES AND PROVIDE YOUR PRODUCTION WITH A SAFER WORK ENVIRONMENT.</strong></p>
					</div>
					
					
				</div>
					<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-1-tab'}"
					id="safety-environment-1-tab" role="tabpanel" aria-labelledby="safety-environment-1-tab">
					<div class="page-details-section">
						<h2 class="page-sub-heading">4.1 - Primary Contacts: On-lot Fire/Medical/Safety Emergency Line and S&EA Team</h2>
						<p class="para-text-light studios-para">ON-LOT EMERGENCY LINE FIRE/MEDICAL/SAFETY<br>
							<strong class="contact-number-blue">818-954-3333</strong>
						</p>
						<p class="para-text-light">Please call the WB on-lot emergency line for all fire, medical, safety, and security emergencies. Calling <span class="contact-number-blue">(818) 954-3333</span> (or extension 4-3333 from any WB phone) will direct the call through WB Security Dispatch, which in turn has a direct line to the City of Burbank emergency dispatch system. This will allow gate officers to direct any incoming ambulances, fire engines, police, or paramedics to the proper location. This will also trigger an immediate response from WB Fire, Security, and First Aid—as appropriate. Using the 911 emergency system will only delay the arrival of emergency responders from the Studio and City of Burbank.</p>
						<p class="para-text-light ">ON-LOT SAFETY & ENVIRONMENTAL AFFAIRS</p>
						<p class="para-text-light ">Main Office<br>
							<span >Office: <strong class="contact-number-blue">818-954-2890</strong></span><br>
						<span>Fax:<strong class="contact-number-blue bullet-list-pb">818-954-2805</strong></span>
						</p>
						<p class="para-text-light">Anonymous Safety Hotline<br>
							<strong class="contact-number-blue">818-954-2800</strong><br>
							<strong class="contact-number-blue">877-566-8001 (Toll-Free)</strong>
						</p>
						<p class="para-text-light">S&EA On-Lot Team</p>
						<ul class="bullet-list">
							<li>
								<span class="">John Clements, Vice President Safety & Environmental Affairs</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-4248</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(310) 628-7364</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:john.clements@warnerbros.com">john.clements@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Corina Zurcher, Executive Director, Safety & Environmental Affairs</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-2890</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 628-7364</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:corina.zurcher@warnerbros.com">corina.zurcher@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Richard J. Levin, Director Feature Production Safety</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 977-3438</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(310) 430-3692</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:richard.j.levin@warnerbros.com">richard.j.levin@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Brent Gale, Manager, Environmental Affairs</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-3880</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 974-6569</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:brent.gale@warnerbros.com">brent.gale@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Eddie Tang, Director, Production Safety</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-1533</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 281-4328</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:eddie.tang@warnerbros.com">eddie.tang@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Vincent Chin, Manager, Production Safety</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-5568</strong></div>
								<div class="bullet-list-pb">Cell: <strong class="contact-number-blue">(818) 281-9248</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:vincent.chin@warnerbros.com">vincent.chin@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Gloria Gama, Manager, Ergonomics & Safety Resources</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-2961</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:gloria.gama@warnerbros.com">gloria.gama@warnerbros.com</a></div>
							</li>
							<li>
								<span class="">Robert Contreraz, Hazardous Waste Facility</span>
								<div class="bullet-list-pb">Office: <strong class="contact-number-blue">(818) 954-1271</strong></div>
								<div class="bullet-list-pb">Email: <a href="mailto:robert.contreraz@warnerbros.com">robert.contreraz@warnerbros.com</a></div>
							</li>
						</ul>
						<p class="para-text-light">Hours: 6:30AM-6PM, Monday through Friday (or by arrangement)<br>
							Location: Safety & Environmental Affairs (S&EA) is located on the North side of Building 44 (The Mill), 5th Street and Avenue C on the Second Floor, above the Canvas Room
						</p>
					</div>
					
					
					
				</div>
				<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-2-tab'}"
				id="safety-environment-2-tab" role="tabpanel" aria-labelledby="safety-environment-2-tab">
				<div class="page-details-section">
					<h2 class="page-sub-heading">4.2 - Injury, Illness Prevention Program (IIPP)</h2>
					<p class="para-text-light studios-para">INTRODUCTION Warner Bros. Studios Operations believes that everyone benefits from a safe and healthful work environment. We are committed to maintaining an injury-free and illness-free workplace in compliance with applicable laws and regulations governing workplace safety. To achieve this goal, the Company has adopted an Injury and Illness Prevention Program (IIPP). This program applies only to employees of Warner Bros. Studios. Any direct production hires should consult the Production Safety Manuals at <a href="http://www.safetyontheset.com" target="_blank">www.safetyontheset.com</a>. This program is everyone’s responsibility as we work together to identify and eliminate conditions and practices to ensure a safe and healthful work environment.</p>
					<p class="para-text-light">Download Warner Bros. Discovery (IIPP)</p>
				</div>
				
			</div>
			<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-3-tab'}"
			id="safety-environment-3-tab" role="tabpanel" aria-labelledby="safety-environment-3-tab">
			<div class="page-details-section">
				<h2 class="page-sub-heading">4.3 - Ten Important Points to Help Keep Your Employees Safe</h2>
				<br>
				<ul class="bullet-list">
					<li><span class="">HIRE CSATF SAFETY PASS TRAINED EMPLOYEES.</span> Only those who have completed Safety Pass Training are eligible for employment at Warner Bros.</li>
					<li><span class="">FALL PROTECTION IS MANDATORY WHEN WORKING AT HEIGHTS</span> Fall protection is required when working outside of the catwalks in the permanents of a soundstage, on unprotected roofs and elevated platforms, all aerial lifts, and in other locations and circumstances.</li>
					<li><span class="">ALWAYS PROPERLY DISPOSE OF PAINT OR CHEMICAL-CONTAINING PRODUCTS</span> It is much more cost effective to recycle your waste than to dispose of it. If waste materials cannot be recycled, then proper disposal procedures must be followed. Improper disposal of waste can result in substantial regulatory fines to your production. Avoid extra costs by recycling or handling paint waste carefully. For proper disposal instructions contact, Brent Gale (818-954-3880).</li>
					<li><span class="">DO NOT OVERLOAD THE STAGE FLOORS OR TRUSSES</span> Contact Office & Production Services (818-954-6777) or S&EA (818-954-2890) for help in determining truss and soundstage loading allowances.</li>
					<li><span class="">DO NOT PUT ANYTHING DOWN THE STORM DRAINS</span> The storm drains at Warner Bros. Studio lead directly to the L.A. River, which in turn drains directly into the Pacific Ocean. Anything that goes into the storm drains is ultimately discharged untreated to the ocean. “Anything” includes soapy water, paint, oil, grease, ice drippings, dirt, sawdust, paint chips, tap water, soda, etc. The only substance that may legally enter the storm drains is rainwater. Fines totaling hundreds of thousands of dollars are levied against companies that discharge into the storm drain system illegally, and these fines will be passed on to production companies if resulting from their production’s activities.</li>
					<li><span class="">DO NOT SHIP DANGEROUS GOODS VIA AIR</span> Dangerous goods are any chemical-containing products that are shipped by mail. One studio was recently fined $47,000 by the FAA for shipping two undeclared and improperly packaged cans of Streaks N’ Tips via air.</li>
					<li><span class="">DO NOT USE AN AERIAL / SCISSOR LIFT OR CONDOR WITHOUT A SAFETY HARNESS</span> The California Occupational Safety & Health Administration (Cal/OSHA) and WBSO both require harnesses when using any type of lift.</li>
					<li><span class="">ALL WATER DISCHARGE MUST MEET BURBANK SEWER DISCHARGE REQUIREMENTS</span> Water used in stage tanks, the Lagoon, and on exterior sets (e.g. rain bars, wet downs, etc.) must be discharged to the sanitary sewer. Production needs to meet with Office & Production Services, Plumbing and S&EA to discuss water disposal procedures. There are restrictions on the use of dyes, oils and other chemicals added to the water. There may also be discharge timing and flow rate limitations.</li>
					<li><span class="">ROUGH TERRAIN FORKLIFTS (GRADALLS) REQUIRE PRIOR APPROVAL</span> Rough terrain forklifts (e.g., Gradalls) are a very specialized piece of lifting equipment and should only be operated by personnel that has been properly trained on the equipment and has been cleared to use it.</li>
					<li><span class="">IF YOU HAVE ANY SAFETY CONCERNS OR QUESTIONS, CALL S&EA AT (818-954-2890) or the ANONYMOUS SAFETY HOTLINE (818-954-2800).</span> REMINDER: California Law states Smoking is prohibited in all buildings, soundstages and within 20 feet of building entrances. This applies to all smoking products, including e-cigarettes. Violation of this policy will result in discipline, up to and including termination.</li>
				</ul>
			</div>
			
		</div>
		<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-4-tab'}"
		id="safety-environment-4-tab" role="tabpanel" aria-labelledby="safety-environment-4-tab">
		<div class="page-details-section">
			<h2 class="page-sub-heading">4.4 - Staff & Crew Training</h2>
			<p class="para-text-light studios-para">
				In order to be eligible for employment with any Warner Bros. related or affiliated entity, employees working in IATSE and Basic Crafts represented classifications must be current in the completion of their respective Safety Pass Courses. An employee’s Safety Pass status may be verified by reviewing his/her/their Contract Services Safety Pass Card. For information on Safety Pass, please refer to <a href="http://www.CSATF.org">www.CSATF.org</a> or contact Safety Pass at 818-847-0040. It is located at: 2710 Winona Ave., Burbank, CA 91504.
			</p>
			<p class="para-text-light">
				DGA members are required to attend and pass a two-hour DGA Safety Training. Upon completion of the class, members are eligible to monitor any of the twenty-six Safety Pass classes that they would like to take to better understand IATSE safety requirements. Please contact CSATF to schedule.
			</p>
			<p class="para-text-light">
				Supervisory employees who direct the work of other employees are to actively participate in scheduling, conducting safety meetings and training classes and to ensure the attendance of all employees. Supervisors are to also provide site-specific safety information to all employees on their first day on the job, including the following:
			</p>
			<ul class="bullet-list">
				<li><span class="">Emergency Procedures:</span> emergency phone numbers, emergency exit locations, evacuation training and emergency relocation area, any special shut-down procedures, etc. Please spread the on-lot Warner Bros. emergency number (818-954-3333) to all staff and crew.</li>
				<li><span class="">The location and operation of safety equipment:</span> fire extinguishers, eye wash station & first aid department, etc.</li>
				<li><span class="">Ensure that each employee has received:</span> the appropriate General Safety Guidelines for Production (Form 1) and any specific Codes of Safe Practices for the use of tools, equipment, and materials that they encounter while performing their work. Special attention should be given to the handling and storage of potentially hazardous material.</li>
				<li><span class="">Be aware that employees new to the industry:</span> (i.e. Permits) should not be assigned work that requires special training, such as the use of respirators or areas requiring fall protection equipment, operating forklifts/condors/scissors lifts, using high-reach forklifts or other heavy equipment, working in potential confined spaces, etc. During “Permit” status, assign an experienced employee to monitor their work.</li>
				<li><span class="">Consult the Alliance of Motion Picture and Television Producers (AMPTP) Safety Bulletins:</span> if potentially hazardous activities are scheduled, such as pyrotechnic events, stunts, use of firearms, etc. A walk-through of the event should be conducted with all involved cast and crew members. A copy of the applicable AMPTP Safety Bulletin is available at <a href="http://www.safetyontheset.com">www.safetyontheset.com</a> and should be attached to that day’s call sheet, made available or conspicuously posted.</li>
				<li><span class="">See to it that employees are provided with Personal Protective Equipment (PPE):</span> and understand the selection, use, care, limitations and location of any PPE that is needed for their job assignment.</li>
			</ul>
			<p class="para-text-light">
				Provide additional worksite-specific safety training when:
			</p>
			<ul class="bullet-list">
				<li>A new employee is hired.</li>
				<li>Any employee is given a new job assignment for which they have not been trained.</li>
				<li>New substances, processes, procedures, or equipment are introduced into the workplace.</li>
				<li>The employer/manager is made aware of a new or previously unrecognized hazard.</li>
				<li>Utilizing unfamiliar locations at WBSO.</li>
			</ul>
			<p class="para-text-light">
				Any questions or guidance to the above can be addressed by calling S&EA at 818-954-2890. Warner Bros. employees can also receive site-specific safety training, including fall protection or guidance on machinery, respirator fit testing or chemical products.
			</p>
		</div>
		
	</div>
	<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-5-tab'}"
	id="safety-environment-5-tab" role="tabpanel" aria-labelledby="safety-environment-5-tab">
	<div class="page-details-section">
		<h2 class="page-sub-heading">4.5 - Production Start-Up Guidelines For Warner Bros. Productions</h2>
		<p class="para-text-light studios-para">To help you take the first steps toward keeping your production in compliance with safety regulations, we have provided the following “Warner Bros. Production Start-Up Guidelines.”</p>
		<ol class="bullet-list">
			<li><span class="">Have your UPM or Production Office Coordinator call your Production Safety Representative to set up your Pre-Production Safety Orientation Meeting.</span> All Warner Bros.-affiliated productions are to meet with a Production Safety Representative prior to the start of production. All key department heads should attend the meeting, including but not limited to: Unit Production Manager (UPM), First Assistant Director, Second Assistant Director, Construction Coordinator, Location Manager, Key Grip, Gaffer, Best Boys, and Transportation Coordinator.</li>
			<li><span class="">As required by Warner Bros. policy, make sure that the General Safety Guidelines for Production (Form 1) is attached to the production’s deal memo prior to hiring, and that each employee’s signed Safety Guidelines Acknowledgement Form is on file before they start work.</span> These documents are available at www.safetyontheset.com. Keep the original signature sheets on file and send copies to your Production Office Coordinator.</li>
			<li><span class="">Ensure your production hires only CSATF Safety Pass trained crew members</span> by checking the CSATF Experience Roster at csatf.org.</li>
			<li><span class="">Have your Production Coordinator fill out Form 2 (IIPP Contact List) and Form 4 (Serious Accident or Set Emergency Notification Procedures).</span> Keep a readily accessible copy for production. The State of California (Cal/OSHA) requires every employer to have its own Injury & Illness Prevention Program (IIPP). For Warner Bros. productions, Safety & Environmental Affairs has already written your productions IIPP. It is available for download on S&EA’s website: www.safetyontheset.com. This site provides access to Form 2 and Form 4.</li>
			<li><span class="">Review the Position Safety Responsibilities section</span> on the safetyontheset.com website. Have applicable members of the production also review this document, which outlines the safety responsibilities of every position on production. This is required by Cal/OSHA, which mandates that the IIPP assign safety duties appropriate to employees’ positions. Cal/OSHA requires every IIPP to assign safety duties. Also available at www.safetyontheset.com are the following:
				<ul>
					<li>Safety Forms</li>
					<li>Tool Box Talks</li>
					<li>Codes of Safe Practices</li>
					<li>Links to AMPTP Safety Bulletins</li>
					<li>Links to check with CSATF that employees are fully trained</li>
					<li>Links to other helpful safety information and safety resource website</li>
				</ul>
			</li>
			<li><span class="">Productions working on the WB Main Lot or WB Ranch</span> should review the Safety Manual Supplement for Productions found on the safetyontheset.com website. This portion of the website contains information on environmental regulations impacting productions—and other information.</li>
		</ol>
		<p class="para-text-light">CALL WB SAFETY <span class="contact-number-blue">(818-954-2890) </span>WHEN:</p>
		<ol class="bullet-list">
			<li>Planning your first Production Meeting of the season so that your Production Safety Representative can arrange a Production Safety Orientation. You may also contact your Production Safety Representative directly.</li>
			<li>Planning a major stunt, car chase, or special effect.</li>
			<li>Flying rigs are used.</li>
			<li>Helicopters, aircraft, watercraft, motorcycles, all-terrain vehicles (ATV’s) are used.</li>
			<li>Prior to any use of Unmanned Aerial Vehicles or Unmanned Aerial Camera Platforms (i.e. drones).</li>
			<li>Planning to film in, on, or around water.</li>
			<li>Intending to drain the Lagoon, Stage 15 or Stage 16 water tanks, or Ranch pool, or any other filming pool.</li>
			<li>Production may impact the public at large, public waterways, streams, lakes, reservoirs, or public lands.</li>
			<li>Using a warehouse as a soundstage.</li>
			<li>Contemplating demolition of any major set or structure.</li>
			<li>Drums of hazardous materials not associated with your production are present upon arrival.</li>
			<li>Production employees may be exposed to safety hazards or hazardous materials that are present as a result of the proximity to another employee, production company, or vendor.</li>
			<li>Scaffolds three stories (36 feet) or higher are being constructed.</li>
			<li>Cranes and equipment exceeding 10,000 lb. are used on a soundstage.</li>
			<li>You have questions about the load-bearing capacity of a stage floor, truss, or backlot platform (roof, fire escape, etc.)</li>
			<li>You suspect the presence of asbestos, lead paint, or another environmental hazard on location.</li>
			<li>You need safety equipment, such as fall protection.</li>
			<li>An employee lacks safety training to operate equipment or perform a task.</li>
			<li>You need assistance with ergonomics issues relating to your workspace.</li>
			<li>You have food safety concerns.</li>
			<li>You have questions about the “Safety Pass Program.”</li>
			<li>A Cal-OSHA or other governmental agency inspector visits your stage or location. The inspector should be asked to wait, if possible, until a representative of S&EA is on site. (See Inspection Procedures for OSHA and Other Regulatory Agencies on the following page.)</li>
		</ol>
		<p class="para-text-light">Please fax or email script pages and complete appropriate Production Safety Forms to S&EA for review.</p>
	</div>
	
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-6-tab'}"
id="safety-environment-6-tab" role="tabpanel" aria-labelledby="safety-environment-6-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.6 - Productions Not Affiliated with Warner Bros.</h2>
    <p class="para-text-light studios-para">CALL WB SAFETY <span class="contact-number-blue"> (818-954-2890)</span> WHEN:</p>
    <ul class="bullet-list">
        <li>Opening your production office or scheduling your first tech scout. We would like to discuss your production’s safety program and obtain information regarding safety while at WBSO.</li>
        <li>Planning a major stunt, car chase, or special effect.</li>
        <li>Helicopters, aircraft, watercraft, motorcycles, all-terrain vehicles (ATV’s) are used.</li>
        <li>Prior to any use of Unmanned Aerial Vehicles or Unmanned Aerial Camera Platforms (i.e. drones).</li>
        <li>Planning to film in, on, or around water.</li>
        <li>Intending to drain the Lagoon, Stage 15 or Stage 16 water tanks, or Ranch pool, or any other filming pool.</li>
        <li>Contemplating demolition of any major set or structure.</li>
        <li>Drums of hazardous materials not associated with your production are present upon arrival.</li>
        <li>Production employees may be exposed to safety hazards or hazardous materials that are present as a result of the proximity to another employee, production company, or vendor.</li>
        <li>Scaffolds three stories (36 feet) or higher are being constructed.</li>
        <li>Cranes and equipment exceeding 10,000 lb. are used on a soundstage.</li>
        <li>You have questions about the load-bearing capacity of a stage floor, truss, or backlot platform (roof, fire escape, etc.)</li>
        <li>A Cal-OSHA or other governmental agency inspector visits your stage or location. The inspector should be asked to wait, if possible, until a representative of S&EA is on site. (See Inspection Procedures for OSHA and Other Regulatory Agencies on the following page.)</li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light">Please follow these guidelines to ensure the safety and compliance of your production while on Warner Bros. property. For any questions or clarifications, do not hesitate to contact WB Safety at the provided number.</p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-7-tab'}"
id="safety-environment-7-tab" role="tabpanel" aria-labelledby="safety-environment-7-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.7 - Inspection Procedures for OSHA And Other Regulatory Agencies</h2>
    <p class="para-text-light studios-para">Several types of events or scenarios may cause an inspector from the California Occupational Safety Health Administration (Cal/OSHA) or other California State regulatory agency to visit the set. Some examples: a serious accident has occurred; an employee complaint has been registered with a regulatory agency, the inspector was driving by the production and noticed areas of non-compliance.</p>
    <p class="para-text-light">If an inspector arrives, follow the procedures below:</p>
    <ol class="bullet-list">
        <li>Notify the Department of Safety & Environmental Affairs (S&EA) immediately at 818-954-2890, as well as your physical production executive. Inform the inspector that you cannot proceed with the inspection until those notifications have been made.</li>
        <li>If requested by S&EA or the Production Attorney, ask the OSHA inspector to wait for S&EA’s arrival. If authorized by S&EA, accompany the inspector directly to the site in question. If possible, do not let the inspector wander into other areas.</li>
        <li>The inspector’s credentials should be requested and examined, and the nature of the visit should be determined. Be courteous, but cautious.</li>
        <li>Do not sign anything or provide any written documentation. Ask that their request for any written material be put in writing so that we may respond in writing, as this is company policy.</li>
        <li>Under OSHA regulations, an inspector has the right to privately interview crew members. However, the crew members should be informed by the Production or S&EA that they have the right to have either a union representative or attorney present during the interview.</li>
        <li>The inspector has the right to take photographs. However, it is important that the production company takes duplicate photographs of those taken by the inspector.</li>
        <li>DO NOT volunteer information or speculation. Provide short, direct, concise answers to questions posed by the inspector. If you truly don’t know the answers, simply tell the inspector that you “do not know”.</li>
        <li>Take detailed notes during the inspection. Immediately after the inspection, forward a copy of the notes to the VP S&EA as well as your production’s management.</li>
        <li>Depending upon the severity of the incident, the results of the inspection may determine whether there is a basis for the State to pursue criminal action, and an inspector may not inform you of this. However, you have the right to remain silent; have your business agent in attendance and/or the right to have legal counsel present when making statements to inspectors.</li>
    </ol>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-8-tab'}"
id="safety-environment-8-tab" role="tabpanel" aria-labelledby="safety-environment-8-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.8 - Craft Service: Food Preparation On Sets And Mobile Food Vehicles</h2>
    <p class="para-text-light studios-para">
        All productions working at Warner Bros. Studios are expected to comply with LA County Department of Public Health guidelines regarding service of food and refreshments. Please see the following bulletins from LAC DPH, which addresses food that can be served by craft service without a permit, protocols for receipt of food delivery, and information about mobile food facilities and catering operations.
    </p>
    <div class="pdf-links">
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">Approved Film Production Food Services</a>
        </p>
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">Permit and Operational Requirements for Motion Picture Catering Operations</a>
        </p>
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">General Requirements Checklist For Mobile Storage Vehicles</a>
        </p>
        <p class="para-text-light">
            <i class="bi bi-file-earmark-pdf-fill"></i>
            <a href="#" class="text-primary" target="_blank">Mobile Food Facility Vehicles</a>
        </p>
    </div>
    <div class="last-para-section">
        <p class="para-text-light">
            Food served by an employee of a Certified Food Facility who possesses a Food Manager Card or Handling Card for Catering is permissible; however, the kitchens in the WBSO sound stages are not permitted and cannot be used for any food preparation.
        </p>
        <p class="para-text-light">
            If you intend to use a Mobile Food Preparation Truck (Hot Truck); a Mobile Food Facility (Cold Truck); Trailer, Cart, or any of the other mobile food facility vehicles, the LA County Department of Public Health requires that the person cooking in the mobile food facility must have a current “Manager’s Food Handling Certificate”, and the vehicle(s) must be permitted by the LAC DPH Vehicle Inspection Program (V.I.P.). Permit requirements to operate a food truck or any food service are complex, and the process to obtain the agency’s permit can be slow and laborious.
        </p>
        <p class="para-text-light">
            The following documents, stickers, etc. are necessary to know that the truck/trailer is in compliance:
            <ol>
                <li>County of Los Angeles “Public Health Operating Permit” (Annual)</li>
                <li>Initial Inspection Sticker (Issued when truck passed VIP inspection.)</li>
            </ol>
        <!-- </p> -->
        <p class="para-text-light">
            If you have any questions, please call Gloria Gama-Tafoya (818-954-2890), Manager Ergonomics and Safety Resources. You may also reach LAC DHS Vehicle Inspection Program (V.I.P.) at 626-430-5500, or the Food and Milk Program at (626) 430-5400.
        </p>
    </div>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-9-tab'}"
id="safety-environment-9-tab" role="tabpanel" aria-labelledby="safety-environment-9-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.9 - Air Quality: Production Considerations</h2>
    <p class="para-text-light studios-para">In Southern California, the oversight of air quality is the responsibility of the South Coast Air Quality Management District (SCAQMD). The SCAQMD has enacted rules that regulate the use and operation of many materials, processes and equipment that may be used by your production company. The following guidelines and regulations have been developed to help you comply with these regulatory requirements.</p>
    <ul class="bullet-list">
        <li><span class="">Visible Emissions –</span> If your production company will be using fires, explosions or large-scale effects involving airborne smoke or dust you will need to notify Office & Production Services and S&EA prior to the effect. Regulations enforced by the South Coast Air Quality Management District (SCAQMD) forbid the airborne release of smoke, dust or other visible emissions that last more than three minutes in duration within any one-hour period. S&EA can assist you in complying with this regulation.</li>
        <li><span class="">Portable Generators –</span> While WBSO has many power supplies available across our facilities and generally wishes to avoid the use of portable generators, portable generators may be approved for usage in conjunction with certain production equipment, or in locations where a power supply is unavailable. Internal combustion engine-driven portable generators are regulated by the SCAQMD because of the exhaust gases that are generated and released into the atmosphere. To comply with the applicable SCAQMD regulations, daily and hourly use records must be kept by the portable generator operator. The SCAQMD regulations governing the use of generators are extensive, but most of them will not impact your production company if you rent the generator from an equipment rental company. When deciding on the placement of generators, consideration must be given to the effect of exhaust gases on employees working downwind or in adjacent occupied buildings. This will help to avoid re-location of the generators during production, which may be required due to errant diesel fumes or other health concerns. Please address this with Office & Production Services and S&EA on your location tech scouts.</li>
        <li><span class="">Coatings, Paint, and Other Paint-Related Materials –</span> Nearly all production companies use paints, stains, primers, etc., and all of these materials are regulated by the SCAQMD. Your paint foremen should be aware of the limitations imposed by these regulations because they govern the chemical content and use of all coatings and paints. All coatings sold through the Warner Bros. Studio Paint Department are SCAQMD compliant for their intended use. The equipment used to apply coatings is also regulated by the SCAQMD. Certain limitations apply to spray guns, which may need an SCAQMD Permit to operate. Other limitations apply to the construction and operation of spray booths. A permit must be obtained prior to the construction of any spray booth, even if it’s temporary. Finally, remind all workers working with coatings or other chemical-based products to keep the lids tightly on cans when not in use, and to store all rags in closed containers. If you need additional information or guidance on what products can be legally or used selected, please contact Brent Gale at (818) 954-3880. Purchasing non-compliant paint and paint-related materials off-lot for use on the lot is a violation of SCAQMD Regulations and Warner Bros. policy. Violators and their Supervisors will be brought to the attention of Labor Relations.</li>
    </ul>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-10-tab'}"
id="safety-environment-10-tab" role="tabpanel" aria-labelledby="safety-environment-10-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.10 - General Warnings And Notifications / Set Construction Safety</h2>
    <p class="para-text-light studios-para">
        <strong>General Warnings and Notifications</strong>
        <div class="spaced"></div>
        <strong>PROP 65 WARNING</strong>
        <div class="spaced"></div>
        <p class="para-text-light">California Prop 65 requires businesses to give a “clear and reasonable warning” prior to exposing any person to a detectable amount of a chemical listed by the state as covered by the Prop 65 Statute. The list contains a wide range of chemicals. Many of them are ingredients or components of common household products, such as ceramic ware, alcoholic beverages and aspirin. Others may be byproducts of certain combustion processes, such as motor vehicle exhaust, tobacco smoke and burning natural gas. Still others may be chemical-containing products such as dyes, or specialty pigments.
        Therefore, as required by the CA Prop 65 Statute, the following Prop 65 Warning is provided:
        <div class="spaced">
        “WARNING: This facility contains chemicals known to the State of California to cause cancer and birth defects or other reproductive harm.”
    <!-- </p> --></div>
    <p class="para-text-light">
        <strong>NOTIFICATION TO EMPLOYEES: ASBESTOS-CONTAINING BUILDING MATERIALS</strong>
        <div class="spaced">
        <p class="para-text-light">Both state and federal law require building owners to notify occupants of the presence of any asbestos-containing materials (ACM) in buildings in which they work. We are making this notification to comply with these laws, and because it is Warner Bros. Studios policy to make such information available.</p>
	</div>
        <strong>BACKGROUND</strong>
        <div class="spaced">
        <p class="para-text-light">Asbestos-containing materials were commonly used in the construction of buildings and homes prior to 1979. ACM were once used as part of certain building materials, such as insulation, roofing materials, floor tiles, and spray-on insulation.
        Warner Bros. Studio has a comprehensive Operation and Maintenance Plan for Asbestos Containing Materials. Under this plan, we use licensed ACM abatement firms to remove ACM from mechanical rooms and exposed pipes and ducts; and to remove any ACM present prior to or discovered while doing Studio remodeling and renovation work. In addition, we have conducted air sampling in all WB buildings known to contain ACM. The Environmental Protection Agency (EPA) and The Occupational Safety & Health Administration (OSHA) set standards for building occupancy, and we are pleased to say that all WBSO buildings meet these standards.
        The following WBSO buildings are known to contain ACM and are subject to this notification: 4, 5, 8, 10, 11, 12, 13, 15, 18, 19, 22, 27, 31, 34, 35, 37, 41, 44, 51, 52, 53, 54, 55, 56, 58, 59, 66, 67, 68, 69, 71, 76, 81, 90, 95, 131, 133, 135, 137, 138, 139, 140, 142, 143, 146, 151, 154, 155 and 3R.
        The survey reports and bulk sample and air sample test reports for particular building locations are available for inspection by appointment. Because inhalation of unprotected airborne asbestos fibers may pose serious potential health risks, it is important that you not disturb potential ACM (i.e., by drilling, sanding or hammering) without first calling S&EA at <strong class="contact-number-blue">818-954-2890</strong> to receive clearance.
        </p></div>
    <!-- </p> -->
    <p class="para-text-light">
        <strong>WB Productions – Specialized Safety Equipment / Personal Protective Equipment (PPE)</strong>
        <div class="spaced">
       <p class="para-text-light"> Safety & Environmental Affairs (S&EA) maintains a variety of specialized safety equipment that is available for use by WB employees. S&EA’s safety-related equipment inventory includes items like face shields, hearing protection, fall protection equipment, horizontal safety lines, vertical rope grabs, Nomex clothing (coveralls, gloves, and hoods), airline respirators, potential confined space ventilators, and high-velocity ducted air machines. Disposable personal protective equipment (PPE) is provided to individual employees free-of-charge to protect them in a variety of work conditions. Other more expensive and specialized equipment is provided for your departments and production companies free of charge; however, lost or abused equipment will be charged to the production.
        Fall protection is mandatory when working in situations that expose employees to potential falls greater than 4 feet in height and where guardrails are absent. Some examples: outside of the catwalks in the permanents of a soundstage; on roofs, shooting platforms or decks built for set construction.
        When larger quantities of items are required, such as for an entire crew, disposable items such as safety glasses, dust masks, earplugs, gloves, and goggles, and other PPE items are available for purchase through the WBSO Paint Department at <strong class="contact-number-blue">(818) 954-4444</strong>, or VER Sales at <strong class="contact-number-blue">(818) 567-3000</strong>. In addition, S&EA maintains a limited supply of expendable safety equipment to be used in emergency scenarios.
        Please call S&EA with any questions or concerns at <strong class="contact-number-blue">(818) 954-2890</strong>.
    </p></div>
    <p class="para-text-light">
        <strong>Third Party Productions – Personal Protective Equipment</strong>
        <div class="spaced">
      <p class="para-text-light">  Productions unaffiliated with WB or its production entities who are leasing production space from WBSO are responsible to supply their employees with Personal Protective Equipment as required by Cal/OSHA. Please call S&EA at <strong class="contact-number-blue">(818) 954-2890</strong> for specific requirements. This equipment can be purchased or rented from VER Sales in Burbank at <strong class="contact-number-blue">(818) 567-3000</strong>.
        Fall protection is mandatory when working in situations that expose employees to potential falls greater than 4 feet in height and where guardrails are absent. Some examples: outside of the catwalks in the permanents of a soundstage; on roofs, shooting platforms or decks built for set construction.
    </p>
</div>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-11-tab'}"
id="safety-environment-11-tab" role="tabpanel" aria-labelledby="safety-environment-11-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.11 - Managing Chemical-Containing Products</h2>
    <p class="para-text-light studios-para">General Purchasing Practices Purchase materials, especially chemical products, in the smallest quantity possible.</p>
    
    <h3 class="page-sub-heading">Paint and Painting Materials</h3>
    <p class="para-text-light studios-para">Purchase paint and paint-related products that comply with all local or state environmental regulatory agency requirements. The WBSO Paint Dept. sells compliant painting materials. Paint rags should be used sparingly. Air drying rags or throwing used rags, brushes, rollers, stir sticks, etc. in the trash is illegal.</p>
    
    <h3 class="page-sub-heading">Compressed Gases</h3>
    <p class="para-text-light studios-para">Have all compressed gases delivered by the vendor to your location. Never throw compressed gas cylinders in the trash. Chain all cylinders in an upright position with valve covers tightened down. Segregate incompatible gases such as flammables (propane, acetylene etc.) from oxidizers (oxygen, etc.).</p>
    
    <h3 class="page-sub-heading">Construction Subcontractors</h3>
    <p class="para-text-light studios-para">Inform all subcontractors you employ that the disposal of any waste generated by the subcontractor is the responsibility of the subcontractor. All waste must be taken by the subcontractor once the job is complete. Inspect their worksite frequently and watch for any dumping of waste materials into the waste hopper, storm drains, toilets, or sinks.</p>
    
    <h3 class="page-sub-heading">Special Effects and Environmental Considerations</h3>
    <p class="para-text-light studios-para">Any form of artificial snow cannot be washed down storm drains. SnoFoam should be allowed to dry into a solid and then swept up and disposed of as trash. Special Effects materials should be evaluated and disposed of appropriately by the FX person in charge.</p>
    
    <h3 class="page-sub-heading">Cleaning Up Spills of Hazardous Material</h3>
    <ul>
        <li class="para-text-light bullet-list-pb">All spills should be cleaned up promptly. The first priority after a spill is the safety of all personnel. Once personnel are safe, and if a qualified person is present, the environment should be protected by stopping the spill from reaching storm drains, sumps, gutters, soil, or bodies of water.</li>
        <li class="para-text-light">If a spill occurs, avoid touching it, walking in it or breathing it, and immediately take these steps:
            <ul>
                <li class="para-text-light">Use sand or spill absorption materials to build a dike well ahead of the course of the spill.</li>
                <li class="para-text-light">Once the flow of material has been stopped, absorb the spill using absorbent or sand.</li>
            </ul>
        </li>
        <li class="para-text-light">For large spills, or small spills of flammable materials, immediately call the WBSO emergency hotline: <span class="contact-number-blue">(818) 954-3333</span>.</li>
        <li class="para-text-light">Once the spill is absorbed, safely sweep or shovel up the spill-contaminated material into a labeled container. Do not throw spill debris into the trash; it must be disposed of as hazardous waste.</li>
        <li class="para-text-light">When time permits, or if you are unable to clean up the spill, please report the spill and any containment efforts to S&EA at <span class="contact-number-blue">(818) 954-2890</span> or call the Hazardous Waste Facility at <span class="contact-number-blue">(818) 954-1271</span>. The WBSO Fire Department can also help clean up a spill—especially during after-hours or on weekends. They can be reached at <span class="contact-number-blue">(818) 954-1261</span>.</li>
    </ul>
    
    <h3 class="page-sub-heading">Painting Operations / Waste Paint Consolidation and Labeling Guidelines</h3>
    <ul>
        <li class="para-text-light bullet-list-pb">These guidelines are to be followed by all employees working with paint and paint-related materials while at Warner Bros. Studio or on location for a Warner Bros. production.</li>
        <li class="para-text-light">Productions working on the WBSO Main Lot should take all waste described below to the Hazardous Waste Facility (Avenue D and Eighth Street). For waste at the Ranch or on location, contact Brent Gale at <span class="contact-number-blue">(818) 954-3880</span>.</li>
        <li class="para-text-light">Painting operations on soundstages or in the Mill (Bldg. 44) that generate irritating odors should be scheduled during off hours whenever possible. If other productions working in the Mill complain – off hour scheduling is mandatory. Ventilation (i.e., stage fans and floor fans) should be used to dissipate odors.</li>
        <li class="para-text-light">Water-based paint waste and solvent-based paint waste are to be kept separate from one another and as clean of debris as possible. Water-based paint that is free of debris and not contaminated with any solvent-based paint can be recycled. Since recyclable paint is less expensive to dispose of than contaminated paint (which must be disposed as hazardous waste), you can save your production money by recycling as much of your water-based paint as possible.</li>
        <li class="para-text-light">Store any flammable waste paints in 5 gal. buckets. (e.g., acetone, “Japans”). Label them “Flammable Paint”.</li>
        <li class="para-text-light">Paint-related materials such as brushes, rollers, and stir sticks are to be isolated in a separate bucket that does not contain paint. Label them “Brushes/Rollers”.</li>
        <li class="para-text-light">Waste latex, vinyl, and other water-based paints should also be stored in 5 gal. buckets. Label them “Water-Based Paint”.</li>
        <li class="para-text-light">Sawdust, sand or other absorbents used in spill cleanup should be isolated and sealed in a separate bucket that does not contain paint. Label them “Used Spill Cleanup of (insert name of material)”.</li>
        <li class="para-text-light">Used plastic bucket liners should be wiped as clean as possible while the paint is still wet, and then crushed and packaged in a separate 5 gal. bucket and labeled “Bucket Liners”. All aerosol cans are to be disposed of separately from other waste paint-related material, preferably in a 5-gallon bucket, sealed and labeled “Empty Spray Cans”. Do not store aerosol cans in the sunlight.</li>
        <li class="para-text-light">Empty waste paint buckets should be wiped clean while still wet and then sealed in plastic garbage bags (tied shut) and labeled “Empty Paint Cans”.</li>
        <li class="para-text-light">Used paint rags should be packed in a 5-gallon bucket and labeled “Used Paint Rags”.</li>
        <li class="para-text-light">When filling buckets with waste paint, do not fill the bucket to the rim. Leave 2-3 inches headspace to allow for expansion.</li>
        <li class="para-text-light">All wash water from washing brushes, rollers, buckets, etc. is to be saved and handled in the same manner as water-based paint waste. Buckets should be labeled “Brush Water”.</li>
    </ul>
    
    <h3 class="page-sub-heading">Waste Paint Drop-Off Procedures When at Warner Bros. Studios</h3>
    <ul>
        <li class="para-text-light bullet-list-pb">All studio departments and production companies are required to drop off their hazardous waste at the WBSO Hazardous Waste Facility Bldg. 78, located South of Bldg. 47 at the intersection of Avenue D and 8th Street. Note: Non-WB Productions purchasing paint from WBSO are eligible for hazardous waste services.</li>
        <li class="para-text-light">When the maximum allowable fifty-five-gallon storage limit has been reached (any combination of the above waste categories equaling a total of 11 five-gallon buckets), the following procedures shall be followed:
            <ul>
                <li class="para-text-light">All containers shall have listed on them; 1 The production or department name, 2 The container contents, labeled as described above, and 3) The stage number or set where the waste was generated.</li>
                <li class="para-text-light">The Hazardous Waste Facility must be called in advance to make sure the facility is open to accept the waste. To schedule a drop-off, call <span class="contact-number-blue">(818) 954-1271</span> or <span class="contact-number-blue">(818) 954-2890</span>.</li>
                <li class="para-text-light">Waste drop-offs will only be scheduled to be received between the hours of 6:15 a.m. and 2:15 p.m., unless special arrangements are made.</li>
                <li class="para-text-light">Waste shall not be allowed to accumulate into large quantities on stage or in departments. Frequent, smaller waste drop-offs should be made. Plan for chemical inventory and disposal when hiatus periods are approaching.</li>
                <li class="para-text-light">Waste shall not be left at the Hazardous Waste Facility if there is no one there to accept it, especially if after hours.</li>
            </ul>
        </li>
    </ul>
    
    <p class="para-text-light studios-para">Please call Brent Gale at <span class="contact-number-blue">(818) 954-3880</span> or Robert Contreraz at <span class="contact-number-blue">(818) 954-1271</span> if you have any questions.</p>
    
    <h3 class="page-sub-heading">Waste Paint Disposal Procedures When on Local Location</h3>
    <p class="para-text-light bullet-list-pb">When returning paint-related materials to WBSO (including brush water), attach a label to the bucket that exactly describes the contents of the bucket, as described above in “Waste Paint Consolidation and Labeling.”</p>
    <p class="para-text-light">Do not use terms such as “Sludge” or “Waste” to describe the contents of a bucket! In the event of a spill or mishap, police or fire departments may call for the Fire Department Hazardous Materials Response Team if all containers of chemical-containing buckets are not properly labeled.</p>
    <p class="para-text-light">Leftover paint and brush water that can still be used can be transported back to the Main Lot. Materials that are considered “waste” cannot be transported by production and will need to be picked up by a licensed hauler. Contact Brent Gale for assistance at <span class="contact-number-blue">(818) 954-3880</span>.</p>
    <p class="para-text-light">Used materials that cannot be reused are considered waste and cannot be transported back to the main lot. This waste will need to be picked up by a licensed hazardous waste hauler; S&EA has a list of pre-approved haulers. The pickup will require the acquisition of an EPA ID Number, which may take in excess of two weeks. Waste cannot be picked up or shipped until the number has been issued by the EPA, as it will be required on the federal hazardous waste manifests. Disposal in any other manner is against WB Policy.</p>
    <p class="para-text-light">If your production company anticipates that it will be generating any used chemical-containing materials off-lot that will be considered “waste,” contact Brent Gale at <span class="contact-number-blue">(818) 954-3880</span>.</p>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-12-tab'}"
id="safety-environment-12-tab" role="tabpanel" aria-labelledby="safety-environment-12-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.12 - WBSO Sewer and Storm Drain Policies</h2>
    <p class="para-text-light studios-para">
        The types of materials discharged to the sewer or storm drain systems are highly regulated by local, state, and federal law. The chart below reflects Warner Bros. policy as to what can be discharged into each system. <strong>Employees violating the policies listed below will be subject to discipline, up to and including referral to Labor Relations and possibly termination.</strong>
    </p>
	<body>
		<table class="policy-table">
			<thead>
				<tr>
					<th colspan="2">SEWER (Sinks, Toilets, Mop Closets)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Allowed</td>
					<td>Prohibited</td>
				</tr>
				<tr>
					<td>
						<ul>
							<li>Tap or bottled water - Ice - Soft drinks or other beverages - Some food (in garbage disposal only) - Mop water</li>
						</ul>
					</td>
					<td>
						<ul>
							<li><strong>Everything Else - Including:</strong> Paint / brush water - Solid foods - Car or cart wash water / soap - Cleaning supplies, solvents, or other chemicals - Gum, cigarette butts, or other trash</li>
						</ul>
					</td>
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="2">STORM DRAIN (All open drains on WBSO streets - untreated directly to L.A. River)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Allowed</td>
					<td>Prohibited</td>
				</tr>
				<tr>
					<td>
						<ul>
							<li>Rainwater</li>
						</ul>
					</td>
					<td>
						<ul>
							<li><strong>Everything Else - Including:</strong> Tap water (!) - Bottled water - Ice or melted ice runoff - Soft drinks or other beverages - Paint/brush water - Solid foods - Car or cart wash water/soap - Cleaning supplies, solvents, or other chemicals - Gum, cigarette butts, or other trash</li>
						</ul>
					</td>
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="2">COSTS AND PENALTIES</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td colspan="2">
						<ul>
								<li>WBSO Fees for Labor and Costs of Clean-Up: Depends on extent of discharge.</li>
								<li>EPA Clean-Up Costs: Depends on extent of discharge. Could be $100,000 or more.</li>
								<li>Regional Water Quality Control Board: Fines of up to $10,000 per day per discharge, plus $10 per gallon of material discharged.</li>
								<li>Criminal Penalties: Fines for the Studio and/or the Production. Criminal charges can be brought against the responsible person(s).</li>
								<li>Damage to Environment: River, Ocean, Beach, Sewage Treatment Plant.</li>
								<li>Bad Publicity: For the Studio and the Production.</li>
								<li>Loss of Discharge Permits and/or Exemptions.</li>
								<li>Disciplinary Action: Violation of Studio Policy could result in Discipline, up to and including Termination.</li>
						</ul>
					</td>
				   
				</tr>
			</tbody>
			<thead>
				<tr>
					<th colspan="2">Please spread the message: Protect the Sewers and Storm Drains!</th>
				</tr>
			</thead>
		   
		</table>
	   
	</body>
</div>
</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-13-tab'}"
id="safety-environment-13-tab" role="tabpanel" aria-labelledby="safety-environment-13-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.13 - Additional Storm Drain Policy Guidelines – By Department</h2>
    <p class="para-text-light studios-para">The State of California, the Regional Water Quality Control Board and Warner Bros. Studios strictly forbid all employees, clients and tenants from intentionally dumping or allowing chemicals—including those labeled “environmentally friendly” or “biodegradable”—into the storm drain system.</p>
    <p class="para-text-light">Please review the WBSO storm drain policy guidelines below with your department heads and their crew as applicable. For any questions on wastewater, contact S&EA at <span class="contact-number-blue">(818) 954-2890</span> or the WB Plumbing at <span class="contact-number-blue">(818) 954-1700</span>.</p>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Craft Services</h3>
    <ul class="bullet-list">
        <li>Ice, melted ice, drinking water and all other forms of liquid cannot be allowed to enter the storm drain system. Do not pour any of these liquids (or, in the case of ice, leave it to melt) on the ground in the vicinity of a storm drain catch basin.</li>
        <li>Regularly check the level of the waste tanks for Craft Service trailers, as well as on-stage or portable sinks, to make sure they do not overflow. Notify your Transportation Department when waste tanks on your trailers need to be emptied.</li>
        <li>Waste mop water and other forms of residential-type cleaning products must be deposited into mop sinks, toilets, or other locations which drain to the sanitary sewer.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Transportation</h3>
    <ul class="bullet-list">
        <li>Check the level of liquid in waste tanks on all trailers to make sure they are emptied before they overflow.</li>
        <li>Do not over-fill fuel tanks on vehicles. When done filling tanks, make sure that the filler cap is replaced before moving the vehicle.</li>
        <li>Report on-lot spills of fuel, motor oil, hydraulic oil and other vehicular fluids to S&EA at <span class="contact-number-blue">(818) 954-2890</span>. These spills must be cleaned up by a trained and qualified representative of the Hazardous Waste Facility or Fire Department, and the spill debris must be disposed of as hazardous waste.</li>
        <li>Car washing or detailing activities may not take place except in designated areas where containment is possible.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Make-Up & Costume Departments</h3>
    <ul class="bullet-list">
        <li>Do not allow water used in cleaning any equipment or materials (such as laundry) to run into the storm drain system. Please have production contact OPS <span class="contact-number-blue">(818) 954-6777</span> if you need assistance with disposal.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Props Department</h3>
    <ul class="bullet-list">
        <li>Check the level of liquid in waste tanks on all trailers to make sure they are emptied before they overflow. Notify your Transportation Department when waste tanks on your trailers need to be emptied.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Greens Department</h3>
    <ul class="bullet-list">
        <li>Do not allow water from outdoor plant watering to run into the storm drain system.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Special Effects</h3>
    <ul class="bullet-list">
        <li>Contact Office & Production Services whenever on-lot water effects are being planned on exterior sets (e.g., rain bars, wet downs, fire hydrant props, etc.) or on stage (e.g., showers, sinks, bathtubs, etc.). Advance coordination with S&EA and WB Plumbing is required to discuss plans for the required water capturing/disposal efforts.</li>
    </ul>

    <h3 class="page-sub-heading studios-para-no-paddding-upside">Production / Construction – General Waste Disposal</h3>
    <ul class="bullet-list">
        <li>Make sure the area surrounding your sound stage(s) is kept free of construction or production related waste. This includes items like cigarette butts, wood, rope, empty plastic cups or bottles, nails, screws, paper, cardboard, candy wrappers, saw dust and metal filings, and other general forms of trash. Have your laborers police these areas to ensure that they are kept clean.</li>
    </ul>
</div>


</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-14-tab'}"
id="safety-environment-14-tab" role="tabpanel" aria-labelledby="safety-environment-14-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.14 - Guidelines for Shipping Dangerous Goods</h2>
    <p class="para-text-light studios-para">READ THIS PRIOR TO SHIPPING ANYTHING BY AIR</p>
    <p class="para-text-light">When chemicals or chemical-containing products are shipped by air, they are called “Dangerous Goods.” Dangerous Goods are regulated by the International Air Transport Association (IATA) and these regulations are enforced by the Federal Aviation Administration (FAA) and the Department of Homeland Security.</p>
    <p class="para-text-light">Many of the products used by consumers in their households would be considered dangerous goods. The same could be said of many of the products shipped by offices and production companies. Some examples of dangerous goods include household products such as hair spray, glass cleaner, aerosol cans (regardless of content), paints, lubricating oils, lithium batteries, etc.</p>
    <p class="para-text-light">Shippers of dangerous goods should keep in mind that certain materials, like pressurized cylinders or aerosol cans, could represent a significant risk to an aircraft and its passengers if shipped illegally. A mishap involving such dangerous goods could impart significant liability to the shipper.</p>
    <p class="para-text-light">The process by which products or materials are identified, classified, packaged, marked and labeled in accordance with these regulations is rather complicated. Unless you have been trained to ship dangerous goods, it is NOT recommended that you do so. Instead of shipping common, readily available products, consider having productions on location instead buy the products locally.</p>
    <h3 class="page-sub-heading studios-para-no-paddding-upside">Shipping Dangerous Goods</h3>
    <p class="para-text-light">Before shipping packages, identify the contents of the package and check for possible dangerous goods. If you are given a package to ship, ask for a detailed list of the contents. If you have products or materials that you think might be dangerous goods, you should contact the mail carrier that you plan to ship the product or material with (i.e., Airborne, FedEx, etc.). They can tell you if it is a “dangerous good” and, for a small fee, most shipping companies can package and label your shipment for you.</p>
    <p class="para-text-light">Dangerous Goods Packers/Shippers:</p>
    <ul class="bullet-list">
        <li><span class="">DHL – Dangerous Goods:</span><span class="contact-number-blue"> 1-800-225-5345</span> or <a href="http://bit.ly/1tFDo3S" target="_blank">http://bit.ly/1tFDo3S</a></li>
        <li><span class="">FedEx – Dangerous Goods:</span><span class="contact-number-blue"> 1-800-463-3339</span> and say “dangerous goods” or <a href="http://bit.ly/S4YBrL" target="_blank">http://bit.ly/S4YBrL</a></li>
    </ul>
    <p class="para-text-light">Remember: The person who signs the shipping document is the responsible and legally liable party.</p>
</div>

</div>
<div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environment-15-tab'}"
id="safety-environment-15-tab" role="tabpanel" aria-labelledby="safety-environment-15-tab">
<div class="page-details-section">
    <h2 class="page-sub-heading">4.15 - Unmanned Aerial Vehicles / Unmanned Aerial Camera Platforms / Drones</h2>
    <p class="para-text-light studios-para">On-Lot Productions</p>
    <ul class="bullet-list">
        <li class="para-text-light">Small, unmanned aircraft must weigh less than 55 pounds— including payload—at takeoff (unless the vendor has a waiver provided to Risk Management and Production Safety)</li>
        <li class="para-text-light">UAS vendor must utilize the Low Altitude Authorization & Notification Capability (LAANC) automated airspace approval system</li>
        <li class="para-text-light">When utilizing First Person View (FPV), the vendor must have a visual observer keeping line of sight on the drone at all times</li>
        <li class="para-text-light">Do not fly above 400ft above ground level (AGL)</li>
        <li class="para-text-light">Do not fly within 5 miles of an airport without approval and notification to the control tower</li>
        <li class="para-text-light">Do not fly a drone from a moving vehicle unless in a sparsely populated area</li>
        <li class="para-text-light">Flying over people is not permitted unless satisfying one of the categories in Subpart D for operations over humans.</li>
        <li class="para-text-light">Night operations are permitted under Part 107.29.</li>
    </ul>
    <div class="last-para-section">
        <p class="para-text-light studios-para-no-paddding-upside">What Safety & Environmental Affairs Needs:</p>
        <ul class="bullet-list">
            <li class="">In addition to the insurance documentation required by Risk Management, Safety will need the following information from the drone vendor:</li>
            <li class="">Pre-flight inspection</li>
            <li class="">Written safety guidelines for crew</li>
            <li class="">Emergency landing procedures</li>
            <li class="">Use of a Visual Observer, assigned to no other duties during drone flight(s)</li>
            <li class="">Plan of activity (POA) with a map including:
                <ul class="bullet-list">
                    <li class="">Takeoff/Landing zones (LZ)</li>
                    <li class="">Flight path/altitude</li>
                    <li class="">Safe zone for crew members not directly involved in flight operations</li>
                    <li class="">Emergency landing zone(s) (ELZ)</li>
                    <li class="">Obstructions to be avoided</li>
                </ul>
            </li>
            <li class="para-text-light">If you are planning on using the drone less than 50 ft clearance from cast or crew while in flight (or less than 30 ft. from stunt performers), contact S&EA to discuss prior to scheduled activity.</li>
        </ul>
        <p class="para-text-light studios-para-no-paddding-upside">My Pilot is a Crew Member!</p>
        <ul class="bullet-list">
            <li class="">If the drone pilot is a crew member with other responsibilities, they may not assume those responsibilities while participating in any activities related to the drone flight (i.e., pre-flight inspection, flying, etc.). Note that all safety and insurance requirements are the same as for any third party vendor.</li>
        </ul>
        <p class="para-text-light studios-para-no-paddding-upside">Additional Safety Protocols:</p>
        <ul class="bullet-list">
            <li class="">Attach Safety Bulletin #36 – Recommended Guidelines for Safely Working Around UAS to the Call Sheet</li>
            <li class="">Hold a pre-flight safety brief (review safety guidelines, flight plan and emergency procedures)</li>
            <li class="">Hold a safety meeting prior to flight with all pertinent cast and crew.</li>
            <li class="">Follow all protocols as outlined in the POA.</li>
            <li class="">Limit radio frequency (RF) use during flight operations to reduce risk of drone control failure</li>
            <li class="">(For Los Angeles and Downtown L.A. locations only) When working in downtown Los Angeles, notify Hooper LAPD flight operations of the planned drone use location.</li>
        </ul>
    </div>
</div>

</div>
				</div>
			</div>
		</div>
	</div>
