<button type="button" class="common-btn-light btn" 
(click)="openCreditAcknowledgeModal(content)">Click</button>
<button type="button" class="common-btn-light btn" 
(click)="openCreditAcknowledgeModalOne(content1)">Click</button>
<ng-template #content let-modal>

  <div class="modal-body">
    <h4 class="section-main-header">Confirmation Header</h4>

    <label>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</label>
    <div >
   
        <label class="mb-3"> <ul>
          <li> By no means submitting a credit application guarantees the credit acceptance…</li>
          <li> …we will reach out to you for other options</li>
          <li>…by submitting, we have the right to check your credit</li>
        </ul></label>
    </div>
    
    <div class="d-flex justify-content-end gap-3 flex-column-reverse flex-md-row">
      <button type="button" class="common-btn-light btn" (click)="modal.close()">Cancel</button>

      <button type="button" class="common-btn-secondary btn"
       >I acknowledge these conditions</button>
    </div>
  </div>
</ng-template>

<ng-template #content1 let-modal>

  <div class="modal-body">
    <h4 class="section-main-header">Credit Application</h4>

    <label>Please select a project you would like to submit a credit application for:</label>
    <div class="common-checkbox-img-list">
      <ul class="list-group">
        <!-- Block 1 -->
        <li class="list-group-item">
          <div class="d-flex flex-column flex-fill">
            <div class="common-checkbox-button">
              <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input" />
              <label for="custom-checkbox-1" class="common-checkbox-label  d-flex align-items-center gap-2">
                <img src="../../../../../../assets/images/projects-small-img.svg" alt="Project Image">
                <span class="season-name">Project Name 1</span>
              </label>
            </div>
          </div>
        </li>
    
        <!-- Block 2 -->
        <li class="list-group-item">
          <div class="d-flex flex-column flex-fill">
            <div class="common-checkbox-button">
              <input type="checkbox" id="custom-checkbox-2" class="common-checkbox-input" />
              <label for="custom-checkbox-2" class="common-checkbox-label d-flex align-items-center gap-2">
                <img src="../../../../../../assets/images/projects-small-img.svg" alt="Project Image">
                <span class="season-name">Project Name 2</span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
    
    <div class="d-flex justify-content-end gap-3 flex-column-reverse flex-md-row">
      <button type="button" class="common-btn-light btn" (click)="modal.close()">Cancel</button>

      <button type="button" class="common-btn-secondary btn"
       >Continue</button>
    </div>
  </div>
</ng-template>