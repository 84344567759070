import { Component, HostListener, Input, TemplateRef, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { COL_DEF_FIELDS, COLS, COMPANY, COMPANY_FILTERS, COMPANY_USERS, COMPANY_USERS_FILTER_TYPES, DEFAULT_STATUS, DropdownSettings, GETSTATUS_CLASS, GO_TO_DROPDOWN, ICON_ACTION_NAMES, MESSAGE, RESPONSE_STATUS } from '../../../constants/studio-portal.constant';
import { COMMON_GRID_BREAKPOINTS, ITEMS_PER_PAGE, PAGINATION_PAGES } from 'src/app/shared/enum/shared.enum';
import { COMMON_GRID_OPTIONS } from 'src/app/shared/constants/common';
import { AddEditCompanyComponent } from 'src/app/shared/components/add-edit-company/add-edit-company.component';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { companiesStatusData, companyFilterData, companyId } from 'mockData/development/company-filters-data';
import { COMPANY_DATA } from 'mockData/development/company-list';
import * as _ from 'lodash';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { GoToDropdownComponent } from 'src/app/shared/components/go-to-dropdown/go-to-dropdown.component';
import { AddCompanyUserComponent } from 'src/app/shared/components/add-company-user/add-company-user.component';
import { AddNewProjectComponent } from 'src/app/shared/components/add-new-project/add-new-project.component';
import { CellRendererComponent } from 'ag-grid-community/dist/types/core/components/framework/componentTypes';
import { MergedCellComponent } from 'src/app/shared/components/merged-cell/merged-cell.component';
import { MergedCellIconsComponent } from 'src/app/shared/components/merged-cell-icons/merged-cell-icons.component';
import { SearchDataService } from 'src/app/shared/services/search.service';
import { CompanyService } from '../../../services/company.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { Router } from '@angular/router';
import { IconsService } from 'src/app/shared/services/icons/icons.service';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { StudioDashboardService } from '../../../services/studio-dashboard/studio-dashboard.service';
import { SearchCompanyComponent } from '../search-company/search-company.component';


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>; // Template for danger toast
  @ViewChild('successTplActive', { static: false }) successTplActive!: TemplateRef<any>;
  @ViewChild('successDisableCompanyTpl', { static: false }) successDisableCompanyTpl!: TemplateRef<any>;
  @ViewChild('dangerDisableCompanyTplActive', { static: false }) dangerDisableCompanyTplActive!: TemplateRef<any>; // Template for danger toast
  @ViewChild('dangerTplActive', { static: false }) dangerTplActive!: TemplateRef<any>; // Template for danger toast
  @ViewChild(SearchCompanyComponent) searchComponent!: SearchCompanyComponent;


  countries: any;
  companyTypes: any;
  companyAddressTypes: any;
  companyData: any;
  activestatus: string;
  companyId: any;
  tranferValue: any;
  currentValue: string;
  portalType: any;
  addCompanyError: any;
  addCompanySuccess: any;
  companyMetaData: any;


  constructor(private modalService: NgbModal,
    private companyService: CompanyService,
    private toastService: ToastService,
    private router: Router,
    private iconService: IconsService,
    private loaderService: LoaderService,
    private studioDashboardService: StudioDashboardService
  ) { }
  isFiltersSelected: boolean = false;
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  @Input() public searchResults: string[] = [];
  @Input() showSearchBarInput: boolean = false;
  placeholder: string = "Search company name or company ID #";
  showSearchBar: boolean = false;
  searchComplete: boolean = false;
  disableCompany: any;
  breakpoints = COMMON_GRID_BREAKPOINTS;
  company = COMPANY;
  message = MESSAGE;
  company_users = COMPANY_USERS;
  modalReference: NgbModalRef;
  getClass = GETSTATUS_CLASS;
  pages = PAGINATION_PAGES;
  itemsPerPage = ITEMS_PER_PAGE;
  company_data = COMPANY_DATA;
  filterTypes = COMPANY_USERS_FILTER_TYPES;
  // Data for dropdown lists projects, types, departments, status and dayes
  activeprojects = companyFilterData;
  status = companiesStatusData;
  companyConstants = COMPANY_FILTERS;
  go_to_dropdown = GO_TO_DROPDOWN;
  // Arrays to hold the selected filters for projects, types, departments, and statuses
  filteredProjects: any[] = [];
  filteredStatus: any[] = [];
  filteredData: any[] = [];
  filteredId: any[] = [];
  id = companyId;
  dropdown = DropdownSettings;
  defaultSelectedStatus: any[] = [];
  iconInfo = ICON_ACTION_NAMES;
  colDefFields = COL_DEF_FIELDS;
  defaultStatusConst = DEFAULT_STATUS;
  responseStatus = RESPONSE_STATUS;
  companiesSubject = new Subject<any[]>();

  // Default settings for the dropdown components
  defaultSettings: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false

  };
  colDefs: ColDef[];
  gridOptions: GridOptions = {
    ...COMMON_GRID_OPTIONS,
    rowClassRules: {
      'clickable': (params) => !!params.data,
    },
  };
  cols = COLS;
  colsForLargeScreen = this.cols.companies[0].colsForLargeScreen;
  colsForSmallScreen = this.cols.companies[0].colsForSmallScreen;
  colsForMediumScreen = this.cols.companies[0].colsForMediumScreen;
  colsForExtraLargeScreen = this.cols.companies[0].colsForExtraLargeScreen;

  rowData: any = [];

  gridApi!: GridApi;
  pageSizeOptions: number[] = [this.itemsPerPage.ten, this.itemsPerPage.twenty, this.itemsPerPage.fifty];
  startRow: number = PAGINATION_PAGES.one;
  endRow: number = ITEMS_PER_PAGE.ten;
  totalRows: number = this.rowData?.length;
  private unsubscribe$ = new Subject<void>();
  private refreshSubscription: Subscription;
  ngOnInit() {

    this.getColDef();
    this.portalType = this.company?.portaltype;
    this.getCompaniesList();
    this.iconService.currentValue.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.currentValue = value;
      if (!_.isNil(this.currentValue)) {
        this.onValueChange(value); // Trigger a method
      }
    });
    this.getCompanyMetaData();
    this.refreshCompanyList();
  }

  refreshCompanyList() {
    // Subscribe to the refresh event from the shared service
    this.refreshSubscription = this.studioDashboardService.refresh$.subscribe((shouldRefresh) => {
      if (shouldRefresh) {
        this.getCompaniesList();  // Refresh the companies list when a new project is added
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete(); // Complete the subject to avoid memory leaks    
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }

  }

  /**
     *applyDefaultStatus() - Method to set default status active
    */
  applyDefaultStatus(): void {
    let defaultStatus = this.defaultStatusConst.active;
    let defaultId = this.defaultStatusConst.activeId;
    this.filteredStatus = [{ id: defaultId, text: defaultStatus.toUpperCase() }];
    this.defaultSelectedStatus = this.filteredStatus;

    let value: any = [];
    this.status.forEach((item: any) => {
      if (item.text == this.defaultStatusConst.activeCapital)
        value.push(item)
    })
    this.defaultSelectedStatus = value;
    this.applyFilters();
  }

  /**
   *getCompaniesList() - Method to call get companies list
  */
  getCompaniesList(): void {
    this.loaderService.setLoadingState(true);

    this.companyService.getCompaniesList().subscribe((data: any) => {

      this.loaderService.setLoadingState(false);
      this.rowData = _.map(data.companies, company => ({
        compid: company.companyId,
        companyName: company.companyName,
        status: company.statusActiveInd === 'Y' ? 'ACTIVE' : 'DISABLED',
        projects: company.projectsCount,
        active: company.projectActiveCount,
        users: company.usersCount,
        goToPage: '',
        comapnyName: company.companyName,
        companyType: company.companyType.companyType,
        activeProjects: company.activeProjects,
        ids: company.companyId
      }));
      if (this.rowData) {
        this.companyData = this.rowData
        this.companiesSubject.next(this.rowData);
        this.applyDefaultStatus()
      }
    },
      (error) => {
        this.loaderService.setLoadingState(false);
      }
    )
  }

  /**
    *onCellClicked() - Method called on click of AG-GRID table 
   */
  onCellClicked(evt): void {

    if (evt?.colDef.field === this.colDefFields.mergedUser || evt?.colDef.field === this.colDefFields.mergedStatus || evt?.colDef.field === this.colDefFields.icons || evt?.colDef.field === this.colDefFields.gotopage) {
      evt.event.stopPropagation();
      return;
    } else {
      this.companyService.setCompaniesData(evt?.data);
      this.companyId = evt.data?.compid;
      this.router.navigate(['/feature/studio-dashboard/company'], {
        queryParams: {
          companyid: this.companyId,
        }
      });
    }

  }

  /**
   *hasSelectedFilters() - Method to check isFiltersSelected
  */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredProjects?.length > 0 ||
      this.filteredStatus?.length > 0
    return this.isFiltersSelected;
  }

  /**
    *getColDef() - Method to get column definations for the Ag-grid table
   */
  getColDef(): void {

    this.colDefs = [
      { headerName: 'ID#', field: 'compid', cellClass: 'account-user-active-project-cell', maxWidth: 115, sortable: true },
      {
        headerName: 'USER',
        field: 'user',
        cellRenderer: (params: ICellRendererParams) => {
          const companyName = params.data.companyName;
          const ids = params.data.ids;
          return `<span class="common-split-cell-section">
                    <span title="${companyName}" class="common-split-cell-data-1">${companyName}</span>
                    <span class="common-split-cell-data-2">${ids}</span>
                  </span>`;
        },
        width: 122,
        hide: true
      },
      {
        headerName: 'USER',
        field: 'bella',
        cellRenderer: MergedCellComponent,
        minWidth: 160,
        hide: true,
        flex: 1.5,
        filter: false,
      },
      {
        headerName: 'STATUS',
        headerClass: 'status-header-class',
        field: 'iconsStatus',
        cellClass: 'icon-status-comp',
        cellRenderer: MergedCellIconsComponent,
        cellRendererParams: {
          status: (params: ICellRendererParams) => {
            return params.data.status;
          }
        },
        minWidth: 140,
        hide: true,
        flex: 1,
        filter: false
      },
      {
        headerName: 'COMPANY NAME',
        field: 'companyName',
        minWidth: 200,
        cellClass: 'common-cell-data-bold',
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.companyName;
        },
        comparator: (valueA, valueB) => {
          const lowerA = (valueA && valueA.toString().toLowerCase().replace(/\s+/g, '')) || '';
          const lowerB = (valueB && valueB.toString().toLowerCase().replace(/\s+/g, '')) || '';

          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },
        cellRenderer: (params: ICellRendererParams) => {
          const companyName = params.data.companyName || '';

          return `<span class="common-split-cell-section">
                    <span title="${companyName}" class="common-split-cell-data-1">${companyName}</span>
                  
                  </span>`;
        },
      },
      { headerName: 'STATUS', field: 'status', cellRenderer: this.statusCellRenderer.bind(this), minWidth: 140 },
      { headerName: 'PROJECTS', field: 'projects', filter: false, sortable: true, minWidth: 130 },
      { headerName: 'ACTIVE', field: 'active', cellClass: 'account-user-active-project-cell', sortable: true, minWidth: 90 },
      { headerName: 'USERS', field: 'users', cellClass: 'account-user-active-project-cell', sortable: true, minWidth: 90 },
      {
        headerName: 'GO TO PAGE',
        field: 'goToPage',
        cellClass: 'account-user-active-project-cell',
        filter: false,
        sortable: false,
        minWidth: 165,
        cellStyle: { paddingRight: 0 },
        cellRenderer: GoToDropdownComponent,
        cellRendererParams: (params: ICellRendererParams) => {
          return {
            compid: params.data.compid,
            activeProjects: params.data.activeProjects
          };
        },
      },
      {
        field: 'icons',
        cellClass: 'companies-icons',
        cellRenderer: IconsComponent,
        headerName: '',
        filter: false,
        minWidth: 142,
        sortable: false,
        cellRendererParams: (params: ICellRendererParams) => {
          return {
            compid: params.data.compid,
            compname: params.data.companyName,
            comptype: params.data.companyType,//need to pass company type from API
            activeprojects: params.data.activeProjects
          };
        },
      },
    ];
  }

  /**
   * onGridReady() - Method to handle the grid's initialization
   * @param params - Parameters passed when the grid is ready
   */
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.updatePagination();
  }

  /**
   *updateGrid() - Method to handle update grid columns
  */
  updateGrid(): void {
    if (this.gridApi) {
      this.adjustColumns(window.innerWidth);
    }
  }

  /**
  * statusCellRenderer() - Custom cell renderer for displaying a status with a specific class.
  * @param params -  Parameters for the cell renderer, provided by the grid framework. 
  * This includes the cell value and other metadata.
  * @returns - A string containing HTML markup for rendering the cell content.
  */
  statusCellRenderer(params: ICellRendererParams): string {
    const statusClass = this.getStatusClass(params.value);
    return `
    <div class="${statusClass}">${params.value}</div>
  `;
  }

  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case this.getClass.disabled:
        return 'status-account-tag disabled';
      case this.getClass.active:
        return 'user-status-tag active status-vertically-align';
      default:
        return '';
    }
  }


  /**
     * onPageSizeChanged() - Method to handle changes in the number of items per page in the grid
     * @param event - The event containing the new page size value
     */
  onPageSizeChanged(event: any): void {
    const value = event?.target?.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }

  /**
     * onBtNext() - Method to handle clicking the "Next Page" button
     */
  onBtNext(): void {
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }

  /**
   * onBtPrevious() - Method to handle clicking the "Previous Page" button
   */
  onBtPrevious(): void {
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }

  /**
     * updatePagination() - Method to update the pagination details such as the current start and end rows
     */
  updatePagination(): void {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + this.pages.one;
      this.endRow = Math.min((currentPage + this.pages.one) * pageSize, this.totalRows);
      this.totalRows = this.rowData?.length;
    }
  }

  /**
     * @HostListener('window:resize') - Method to listen to window resize events
     * Adjusts the column visibility based on the screen size when the window is resized
     */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event?.target?.innerWidth);
  }

  /**
     * adjustColumns() - Method to adjust the visibility of columns based on screen width
     * @param screenWidth - The width of the screen to determine which columns to display
     */
  adjustColumns(screenWidth: number): void {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      if (screenWidth <= this.breakpoints.threeSeventyEight) {
        this.setVisibleColumns(this.colsForSmallScreen);
      } else if (screenWidth > this.breakpoints.threeSeventyEight && screenWidth <= this.breakpoints.fiveHundredAndTen) {
        this.setVisibleColumns(this.colsForMediumScreen);
      } else if (screenWidth > this.breakpoints.fiveHundredAndTen && screenWidth <= this.breakpoints.nineNintyTwo) {
        this.setVisibleColumns(this.colsForLargeScreen);
      } else {
        this.setVisibleColumns(this.colsForExtraLargeScreen);
      }
    }
  }

  /**
   * setVisibleColumns() - Method to set which columns are visible
   * @param visibleCols - An array of column IDs that should be visible
   */
  setVisibleColumns(visibleCols: string[]): void {
    const allColumnIds: string[] = [];
    this.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    this.gridApi.setColumnsVisible(allColumnIds, false);
    this.gridApi.setColumnsVisible(visibleCols, true);
  }

  /**
*addCompany() - invoke the add company modal popup
*/
  addCompany(): void {
    window.scrollTo(0, 0);
    let title = this.company?.addcompany;
    let portalType = this.portalType;
    let metaData = this.companyMetaData;
    let modalType = this.company?.modalAdd;
    this.modalReference = this.modalService.open(AddEditCompanyComponent, {
      windowClass: 'common-modal-xl ',
      backdrop: 'static', size: 'lg', keyboard: false, fullscreen: true
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data?.status == this.responseStatus.success) {
          this.addCompanySuccess = data?.message;
          this.showSuccess(this.successTpl);
          this.getCompaniesList();
        } else {
          this.addCompanyError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      }
    });
  }

  /** getCompanyMetaData - mthd to call get company meta data from the getCompanyMetadata api */
  getCompanyMetaData(): void {
    this.companyService.getCompanyMetadata().subscribe((data: any) => {
      this.companyMetaData = data?.companyMetaData;
    },
      (error) => {
        console.error(error)
      }
    )
  }
  /**
* selectedStatus() - Method to handle selection of status from the dropdown
* @param selectedItems - The selected items from the dropdown
*/
  selectedStatus(selectedItems: any): void {
    this.filteredStatus = selectedItems;
    this.clearSearchResults();

    this.applyFilters(); // Apply filters after selection
  }

  clearSearchResults(): void {
    // Clear the search input in the search component
    if (this.searchComponent) {
      this.searchComponent.clearSearchInput();
    }
  }
  /**
* selectedStatus() - Method to handle selection of status from the dropdown
* @param selectedItems - The selected items from the dropdown
*/
  selectedId(selectedItems: any): void {
    this.filteredId = selectedItems;
    this.applyFilters(); // Apply filters after selection
  }

  /**
  * clearFilter() - Method to clear a specific filter
  * @param filterType - The type of filter to clear (project, type, department, status)
  */
  clearAllFilters(): void {
    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;

    this.filteredId = [];
    this.selectedId(this.filteredId);
    this.id = this.filteredId;

    // Re-apply filters to reflect the cleared state
    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any {
    return _.filter(arr, i => i.id !== item.id);
  }

  /**
   * applyFilters() - Method to apply selected filters to the project data and update the paginated data
   */
  applyFilters(): void {

    let filteredData = this.companyData;
    if (this.filteredStatus?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredStatus, x => project.status === x.text)
      );
    }
    if (this.filteredId?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredId, x => project.compid == x.text)
      );
    }
    this.hasSelectedFilters();
    this.filteredData = filteredData;
    this.rowData = this.filteredData;
  }

  /**
* selectedProject() - Method to handle selection of projects from the dropdown
* @param selectedItems - The selected items from the dropdown
*/
  selectedProject(onSelect: any): void {
    this.filteredProjects = onSelect;
    this.applyFilters(); // Apply filters after selection
  }

  /**
 * removeSelectedItem() -remove selected item
\  */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.activeprojects: {
        let projectArray = [];
        this.filteredProjects = this.filteredDataList(this.filteredProjects, item);
        projectArray.push(item);
        this.activeprojects = projectArray;
      }
        break;
      case this.filterTypes.status: {
        let statusArray = [];
        this.filteredStatus = this.filteredDataList(this.filteredStatus, item);
        statusArray.push(item);
        this.status = statusArray;
      }
        break;
    }
    this.applyFilters();
  }
  /**
   * onSearchCompleted(results) - Handle search completion event.
   * @param results - Array of search results.
   */

  onSearchCompleted(results: any[]): void {
    this.searchResults = results;
    this.searchComplete = results?.length > 0;

    if (results === null || results.length === 0) {
      this.filteredId = [];
      this.applyFilters();
    } else if (this.searchComplete && results?.length > 0) {
      let array = results;
      if (array[0]?.compid) {
this.applyDefaultStatus();
        this.filteredId = [{ text: array[0].compid }];
        this.applyFilters();
      }
    }
    this.updateSelectedFiltersDisplay();

  }

  updateSelectedFiltersDisplay(): void {
    this.isFiltersSelected = this.filteredStatus.length > 0 || this.filteredId.length > 0;
  }



  /**
   * onValueChange(val: any) - Method to handle changes in value.
   * This method processes the incoming value, determines the associated icon action,
   * and triggers the appropriate user action based on the parsed value.
   * 
   * @param val - The value representing the action to be taken, expected to be a JSON string.
   */
  onValueChange(val: any): void {
    this.tranferValue = val;
    const parsedVal = val;
    if (parsedVal != null) {
      let companyId = parsedVal.rowData?.compid;
      switch (parsedVal.iconInfo) {
        case this.iconInfo.disableCompany:
          this.confirmDisable(companyId);
          break;
        case this.iconInfo.enableCompany:
          this.confirmEnable(companyId);
          break;
        default:
          console.log('Unknown action: ' + parsedVal.iconInfo);
          break;
      }
    }
  }
  /**
   * confirmDisable() - Method to handle company disable.
   */
  confirmDisable(companyId): void {
    this.confirmDisableCompany(companyId);
    this.modalService.dismissAll();
  }

  /**
   * confirmEnable() - Method to handle company enable.
   */
  confirmEnable(companyId): void {
    this.confirmEnableCompany(companyId);
    this.modalService.dismissAll();
  }

  /**
   * confirmDisableCompany() - Method to disable the company.
   * Calls the iconService to disable the company and handles the response.
   */
  confirmDisableCompany(companyId): void {
    this.iconService.disableCompany(companyId).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response?.success) {
          this.showSuccess(this.successDisableCompanyTpl);
          this.currentValue = null;
          this.iconService.changeValue(null);
          this.getCompaniesList();
        } else {
          this.showDanger(this.dangerDisableCompanyTplActive);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  /**
   * confirmEnableCompany() - Method to enable the company.
   * Calls the iconService to enable the company and handles the response.
   */
  confirmEnableCompany(companyId): void {
    this.iconService.enableCompany(companyId).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response?.success) {
          this.showSuccessActi(this.successTplActive);
          this.currentValue = null;
          this.iconService.changeValue(null);
          this.getCompaniesList();
        } else {
          this.showDanger(this.dangerTplActive);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
  /**
      * showSuccess() - Displays a success toast notification with custom content and options.
      * @param successTpl - The template reference containing the toast's content.
      */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  showSuccessActi(successTplActive: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTplActive, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message.
   * @param dangerTpl - The template reference for the danger message to display.
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.clear()
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

}
