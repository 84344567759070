import { Injectable } from '@angular/core';
import { projectCreditApplications, projectSendCreditApplications } from 'mockData/development/studio-send-credit-application';
import { Observable, of } from 'rxjs';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import { STUDIO_SUCCESS_APIMESSAGE } from '../constants/studio-portal.constant';

@Injectable({
  providedIn: 'root'
})
export class ProjectsCreditApplicationService {

  constructor(private baseApiService: BaseApiService) { }

  getCreditApplication(): Observable<any> {
    return of(projectCreditApplications);
  }
  
  getSendCreditApplication(): Observable<any> {
    return of(projectSendCreditApplications);
  }

  /**
   * getAccountPayableUsers() - to get the data of account payable users
   * @param companyId - company id that need to be passed 
   * @returns - an observable that gives data of account payable users
   */
  getAccountPayableUsers(companyId: any){
    const endpoint = `${STUDIO_PORTAL_API_PATHS.account_payable_users}companyId=${companyId}`;
      return this.baseApiService.get(endpoint); 
  }

  /**
   * sendToCustomer() - submit the data to customer
  */
  sendToCustomer(payload) {
    let endpoint = STUDIO_PORTAL_API_PATHS.ca_send_to_customer;
    return this.baseApiService.post(endpoint, payload);
  }

  /**
    * fetchCAPageData() - Method to fetch pagedata related to credit application packet.
    * @returns - The API response containing the pagedata.
  */
  fetchCAPageData() {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.ca_pagedata}`;
    return this.baseApiService.get(endpoint);
  }
  
  getViewQuoteData(caSeqId) {
    let endpoint = `${STUDIO_PORTAL_API_PATHS.view_quote}caSeqId=${caSeqId}`;
    return this.baseApiService.get(endpoint);
}

/**
 * attachQuotes() - to attach quotes for a credit application
 * @param data - data that needs to be sent to database
 * @returns - an observable with a success message
 */
  attachQuotes(data:any){
    let endpoint = STUDIO_PORTAL_API_PATHS.attachQuotes
    return this.baseApiService.post(endpoint,data)
  }
  
}
