import { Injectable } from '@angular/core';
import { STUDIO_PORTAL_API_PATHS } from '../../../../../assets/constants/apiPaths';
import { BaseApiService } from '../../../../shared/services/base-api/base-api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreditAppService {
  private dataSubject = new Subject<any>();
  data$ = this.dataSubject.asObservable();
  constructor(private baseApiService: BaseApiService) { }
  /**
   * getDashboardCreditApplication() - Method to call get Credit Application details.
   * @returns - The API response containing credit application details.
  */
  getCreditApplication(): any {
    const url = `${STUDIO_PORTAL_API_PATHS.dashboard_ca}`;
    return this.baseApiService.get(url);
  }
  /**
    *setData() - Method to reset the data by setting it to null used for refreshing the agGrid
    */
  setData() {
    this.dataSubject.next(null);
  }
}
