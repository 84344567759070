export const userRoleAccessCustCustomerUser: any = {
  //functional area
  //renamed key
  "accessOverview": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "accessRequest": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "accessProject": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "accessCompany": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "accessClientResource": ["customer_project view only", "customer_project view and manage", "customer_company admin"],

  "viewFaq": ["customer_project view only", "customer_project view and manage", "customer_credit application view and manage", "customer_manage users view and manage", "customer_company admin"],
  "editFaq": [],
  //overview dashboard
  "requestWidget": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "weatherWidget": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "activeProject": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "wbdPass": [],
  "creditappWidget": ["customer_credit application view and manage", "customer_manage users view and manage", "customer_company admin"],
  //added recently
  "ontheLotWidget": ["customer_project view only", "customer_project view and manage", "customer_company admin"],

  "makeRequest": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  //removed key
  //"createPass": [],

  "dashboardAdduser": ["customer_manage users view and manage", "customer_company admin"],
  "startCredit": ["customer_credit application view and manage","customer_company admin"],
  //project
  "viewProject": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "addNewProject": [],
  "editProject": ["customer_project view and manage","customer_company admin"],
  "mySpace": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  //renamed key
  "viewCredit": ["customer_credit application view and manage","customer_company admin"],

  "viewProjectUser": ["customer_project view only", "customer_project view and manage", "customer_company admin"],
  "applyCredit": ["customer_credit application view and manage", "customer_company admin"],
  "viewCreditProgress": ["customer_credit application view and manage","customer_company admin"],
  "viewCreditDetails": ["customer_credit application view and manage","customer_company admin"],
  

  //removed key
  //"addProjectUser": ["customer_manage users view and manage","customer_company admin"],
  //"manageProjectUser": ["customer_manage users view and manage", "customer_company admin"],
  //"removeProjectUser": ["customer_manage users view and manage", "customer_company admin"],
  //"forcePasswordReset": ["customer_manage users view and manage", "customer_company admin"],
  //"deactivatUser": ["customer_manage users view and manage", "customer_company admin"],


  //manage company & portal user
  //renamed key
  "viewCompany": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "editCompany": ["customer_company admin"],
  "viewCompanyUser": ["customer_company admin"],

  //manage customer user
  //renamed key
  "addCustomerUser": ["customer_manage users view and manage", "customer_company admin"],
  "editCustomerUser": ["customer_manage users view and manage", "customer_company admin"],

  "manageProjectUser": ["customer_manage users view and manage", "customer_company admin"],
  "removeProjectUser": ["customer_manage users view and manage", "customer_company admin"],
  "portalForcePasswordReset": ["customer_manage users view and manage", "customer_company admin"],

  "deactivatUser": ["customer_manage users view and manage", "customer_company admin"],

  //newly added
  "activateDeactivateCompanyAdminUser" : ["customer_company admin"],
  "makeCompanyAdmin": ["customer_company admin"],
  //portal user profile
  "manageProfile": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "managePortal": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  "manageEmail": ["customer_project view only", "customer_project view and manage","customer_company admin"],
  //manage customer user
  "manageCustomerUser": ["customer_company admin"],
}

// export const userRoleAccessCustStudioUser: any = {
//   //functional area
//   //renamed key
//   "accessOverview": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "accessRequest": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "accessProject": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "accessCompany": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "accessClientResource": ["studio_project view only", "studio_project view and manage", "studio_company admin"],

//   "viewFaq": ["studio_project view only", "studio_project view and manage", "studio_credit application view and manage", "studio_manage users view and manage", "studio_company admin"],
//   "editFaq": [],
//   //overview dashboard
//   "requestWidget": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "weatherWidget": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "activeProject": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "wbdPass": [],
//   "creditappWidget": ["studio_credit application view and manage", "studio_manage users view and manage", "studio_company admin"],
//   //added recently
//   "ontheLotWidget": ["studio_project view only", "studio_project view and manage", "studio_company admin"],

//   "makeRequest": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   //removed key
//   //"createPass": [],

//   "dashboardAdduser": ["studio_manage users view and manage", "studio_company admin"],
//   "startCredit": ["studio_credit application view and manage", "studio_company admin"],
//   //project
//   "viewProject": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "addNewProject": [],
//   "editProject": ["studio_project view and manage", "studio_company admin"],
//   "mySpace": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   //renamed key
//   "viewCredit": ["studio_credit application view and manage", "studio_company admin"],

//   "viewProjectUser": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "applyCredit": ["studio_credit application view and manage", "studio_company admin"],
//   "viewCreditProgress": ["studio_credit application view and manage", "studio_company admin"],
//   "viewCreditDetails": ["studio_credit application view and manage", "studio_company admin"],


//   //removed key
//   //"addProjectUser": ["studio_manage users view and manage","studio_company admin"],
//   //"manageProjectUser": ["studio_manage users view and manage", "studio_company admin"],
//   //"removeProjectUser": ["studio_manage users view and manage", "studio_company admin"],
//   //"forcePasswordReset": ["studio_manage users view and manage", "studio_company admin"],
//   //"deactivatUser": ["studio_manage users view and manage", "studio_company admin"],


//   //manage company & portal user
//   //renamed key
//   "viewCompany": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "editCompany": ["studio_company admin"],
//   "viewCompanyUser": ["studio_company admin"],

//   //manage customer user
//   //renamed key
//   "addCustomerUser": ["studio_manage users view and manage", "studio_company admin"],
//   "editCustomerUser": ["studio_manage users view and manage", "studio_company admin"],

//   "manageProjectUser": ["studio_manage users view and manage", "studio_company admin"],
//   "removeProjectUser": ["studio_manage users view and manage", "studio_company admin"],
//   "portalForcePasswordReset": ["studio_manage users view and manage", "studio_company admin"],

//   "deactivatUser": ["studio_manage users view and manage", "studio_company admin"],

//   //newly added
//   "activateDeactivateCompanyAdminUser": ["studio_company admin"],
//   "makeCompanyAdmin": ["studio_company admin"],
//   //portal user profile
//   "manageProfile": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "managePortal": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   "manageEmail": ["studio_project view only", "studio_project view and manage", "studio_company admin"],
//   //manage customer user
//   "manageCustomerUser": ["studio_company admin"],
// }

export const userRoleAccessStudioWBSOUser: any = {
  //functional area
  "myDashboard": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "company": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "projects": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "users": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "creditApp": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "clientResource": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "viewFaq": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editFaq": ["System Admin"],
  //overview dashboard
  "creditAppWidget": ["Department User", "Department Head", "CSR", "System Admin"],
  "bookmarkProjectWidget": ["Department User", "Department Head", "CSR", "System Admin"],
  "requestWidget": ["Department User", "Department Head", "CSR", "System Admin"],
  //added recently
  "ontheLotWidget": ["Department User", "Department Head", "CSR", "System Admin"],
  //company
  "viewCompanyDirectory": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "addCompany": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editCompany": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "disableCompany": ["System Admin"],
  //removed key
  //"addCompanyUser": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  //"createCreditAppPacket": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],

  //added recently
  "sendCreditPacket": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  
  "viewCompany": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "viewUsers": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  //project
  "viewProject": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "addNewProject": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editProject": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "sendCreditAppPacket": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "uploadQuote": ["CSR", "System Admin"],
  "viewQuote": ["CSR", "System Admin", "A/R"],

  //removed key
  //"addProjectUser": ["CSR", "System Admin", "A/R"],

  "mySpace": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "creditAppTab": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "userTab": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  //user management
  "addCustomerUser": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editCustomerUser": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "removeCustomerUser": ["CSR", "System Admin"],
  "portalForcePasswordReset": ["CSR", "System Admin"],
  "deactivateUser": ["CSR", "System Admin"],
  "makeCompanyAdmin": ["CSR", "System Admin"],
  "addWBSOUser": ["CSR", "System Admin"],
  "editWBSOUser": ["CSR", "System Admin"],
  //credit application
  "viewCreditApp": ["Department User", "Department Head", "CSR", "System Admin", "A/R"],
  "editCreditAppPacket": ["System Admin", "A/R"],
  "assignRiskApprover": ["System Admin", "A/R"],
  "approveCreditAppLevel1": ["Department User","Department Head"],
  "approveCreditAppLevel2": ["Department Head"],
  "approveDenyCreditApp": ["A/R", "Level 1 Approver", "Level 2 Approver"],
  "reviewSubmittedCreditApp": ["A/R"],
  "adjustCreditLimit": ["A/R"],
  "addAR": ["A/R", "Level 1 Approver", "Level 2 Approver"],
  "downloadCreditPacket": ["A/R"],
}


