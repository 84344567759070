<div class="container cm-main-container px-0 mx-0">
  <div class="common-modal-input-section grey-bg review-panel">
    <!-- Line of Credit Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.lineOfCredit.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="title mb-0">
            <strong class="common-title">{{ constants.sections.lineOfCredit.labels.requested }}:</strong>
            <span class="common-content">{{ rowData.lineOfCredit?.locRequested | currency }}</span>
          </p>
          <p class="title mb-0">
            <strong class="common-title">{{ constants.sections.lineOfCredit.labels.jobTotal }}:</strong>
            <span class="common-content">{{ rowData.lineOfCredit?.locJobTotal | currency }}</span>
          </p>
          <p class="title mb-0">
            <strong class="common-title">{{ constants.sections.lineOfCredit.labels.months }}:</strong>
            <span class="common-content">{{ rowData.lineOfCredit?.locNumOfMonths  }}</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Letter of Credit Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.letterOfCredit.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.letterOfCredit.labels.comments }}:</strong>
            <span class="common-content">{{ constants.common.none }}</span>
          </p>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <span><img src="../assets/images/pdf-icon.svg" alt=""> signed.pdf </span>
          </div>
          <div class="col-md-6 text-end">
            <a (click)="viewDnBReport()" class="text-decoration-none">
              <span class="text-link">{{ constants.common.view }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Company Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.company.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.company.labels.name }}:</strong>
            <span class="common-content">{{ rowData.companyName}}</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.company.labels.address }}:</strong>
            <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.company.labels.phone }}:</strong>
            <span class="common-content">858556565</span>
          </p>
          <div class="common-checkbox-button mb-4">
            <input type="checkbox" id="custom-checkbox-1" class="common-checkbox-input" disabled checked />
            <label for="custom-checkbox-1" class="common-checkbox-label">
              {{ constants.sections.company.labels.billingAddress }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Federal Tax ID Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.federalTaxId.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.federalTaxId.labels.tin }}:</strong>
            <span class="common-content">123456789</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Account Payable Contact Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.accountPayable.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.accountPayable.labels.name }}:</strong>
            <span class="common-content">{{ rowData.companyName}}</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.accountPayable.labels.email }}:</strong>
            <span class="common-content">name@domain.com</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.accountPayable.labels.phone }}:</strong>
            <span class="common-content">858556565</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Company Profile Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.companyProfile.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.companyProfile.labels.profile }}:</strong>
            <span class="common-content">Corporate</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.companyProfile.labels.dateStarted }}:</strong>
            <span class="common-content">1/1/24</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.companyProfile.labels.businessType }}:</strong>
            <span class="common-content">Corporation</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.companyProfile.labels.annualSales }}:</strong>
            <span class="common-content">$xx.xx</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.companyProfile.labels.netWorth }}:</strong>
            <span class="common-content">$xx.xx</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.companyProfile.labels.employees }}:</strong>
            <span class="common-content">0-25</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Principals Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.principals.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.principals.labels.name }}:</strong>
            <span class="common-content">A Brand New Company</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.principals.labels.title }}:</strong>
            <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Banking References Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.bankingReferences.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.bankingReferences.labels.bank }}:</strong>
            <span class="common-content">A Brand New Company</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.bankingReferences.labels.accountNumber }}:</strong>
            <span class="common-content">1234 5678 9012 3456</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.bankingReferences.labels.name }}:</strong>
            <span class="common-content">John Doe</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.bankingReferences.labels.title }}:</strong>
            <span class="common-content">A Brand New Company</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.bankingReferences.labels.email }}:</strong>
            <span class="common-content">name@domain.com</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.bankingReferences.labels.phone }}:</strong>
            <span class="common-content">858556565</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Trade Reference 1 Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.tradeReference.title }} 1</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.company }}:</strong>
            <span class="common-content">Name</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.contact }}:</strong>
            <span class="common-content">Data</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.address }}:</strong>
            <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.email }}:</strong>
            <span class="common-content">name@domain.com</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.phone }}:</strong>
            <span class="common-content">858556565</span>
          </p>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <span><img src="../assets/images/pdf-icon.svg" alt=""> signed.pdf </span>
          </div>
          <div class="col-md-6 text-end">
            <a (click)="viewDnBReport()" class="text-decoration-none">
              <span class="text-link">{{ constants.common.view }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Trade Reference 2 Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.tradeReference.title }} 2</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.company }}:</strong>
            <span class="common-content">Name</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.contact }}:</strong>
            <span class="common-content">Data</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.address }}:</strong>
            <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.email }}:</strong>
            <span class="common-content">name@domain.com</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.phone }}:</strong>
            <span class="common-content">858556565</span>
          </p>
        </div>
      </div>
    </div>

    <hr class="my-5">

    <!-- Trade Reference 3 Section -->
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.tradeReference.title }} 3</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.company }}:</strong>
            <span class="common-content">Name</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.contact }}:</strong>
            <span class="common-content">Data</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.address }}:</strong>
            <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.email }}:</strong>
            <span class="common-content">name@domain.com</span>
          </p>
          <p class="mb-2 title">
            <strong class="common-title">{{ constants.sections.tradeReference.labels.phone }}:</strong>
            <span class="common-content">858556565</span>
          </p>
        </div>
      </div>
    </div>

  </div>

  <!-- Terms & Conditions Section -->
  <div class="common-modal-input-section grey-bg">
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.termsConditions.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.termsConditions.labels.name }}:</strong>
            <span class="common-content">Catherine Decker</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.termsConditions.labels.title }}:</strong>
            <span class="common-content">Head of Accounting</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.termsConditions.labels.signature }}:</strong>
            <span class="common-content">Yes</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.termsConditions.labels.date }}:</strong>
            <span class="common-content">xx/xx/xxxx</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Certificate of Insurance Section -->
  <div class="common-modal-input-section grey-bg">
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.certificateOfInsurance.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="common-user-info">
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.certificateOfInsurance.labels.producer }}:</strong>
            <span class="common-content">XYZ Broker Company</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.certificateOfInsurance.labels.insured }}:</strong>
            <span class="common-content">John Doe</span>
          </p>
          <p class="mb-0 title">
            <strong class="common-title">{{ constants.sections.certificateOfInsurance.labels.address }}:</strong>
            <span class="common-content">2500 Main Street, Los Angeles, CA, 92783</span>
          </p>
        </div>
        <div class="row pt-3">
          <div class="col-md-6">
            <span><img src="../assets/images/pdf-icon.svg" alt=""> signed.pdf</span>
          </div>
          <div class="col-md-6 text-end">
            <a (click)="viewDnBReport()" class="text-decoration-none">
              <span class="text-link">{{ constants.common.view }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- D&B Report Section -->
  <div class="common-modal-input-section grey-bg">
    <div class="row mb-4">
      <div class="col-md-4">
        <h4 class="right-section-heading">{{ constants.sections.dbReport.title }}</h4>
      </div>
      <div class="col-md-8">
        <div class="row pt-3">
          <div class="col-md-6">
            <span><img src="../assets/images/pdf-icon.svg" alt=""> signed.pdf </span>
          </div>
          <div class="col-md-6 text-end">
            <a (click)="viewDnBReport()" class="text-decoration-none">
              <span class="text-link">{{ constants.common.view }}</span>
            </a>
          </div>
        </div>
        <div class="row mt-3">
          <span class="col-4 title">
            <strong class="title">{{ constants.sections.dbReport.labels.delinquency }}:</strong>
            <span class="common-content">88</span>
          </span>
          <span class="col-4 title">
            <strong class="title">{{ constants.sections.dbReport.labels.failureScore }}:</strong>
            <span class="common-content">67</span>
          </span>
          <span class="col-4 px-0 title">
            <strong class="title">{{ constants.sections.dbReport.labels.paydexScore }}:</strong>
            <span class="common-content">72</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #dangerTpl>
  <div class="danger">{{requestResponse}}</div>
</ng-template>