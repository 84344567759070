import { FormControl } from '@angular/forms';

export const CREDIT_APPLICATION = {
  creditApplication: 'Credit Applications',
  viewCredit: 'View All Credit Applications',
};

export const PROJECTS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters',
  noProjects: 'No Projects To Display'
}

export const PROJECT_FILTER_TYPES = {
  projects: 'projects',
  types: 'types',
  departments: 'departments',
  status: 'status',
  project: 'project',
  type: 'type',
  department: 'department'
}

export const MANAGE_USERS_TYPES = {
  users: 'users',
  userType: 'userType',
  status: 'status',
  jobTitles: 'jobTitles',
  user: 'user',
  jobTitle: 'jobTitle',
  portalType: 'customer',
  addModalType: 'add',
  editModalType: 'edit',
  componentType: 'manage'
}
export const CREDIT_APPLICATION_FILTERS = {
  companies: 'company',
  projects: 'project',
  departments: 'department',
  status: 'status',
}

export const departmentsTypeahead = [
  { id: 1, text: 'departmentsTypeahead' },
];

export const projectsTypeahead = [
  { id: 1, text: 'activeProjectsTypeahead' },
];

export const MANAGE_USERS_CONSTANTS = {
  addUser: '+ Add a User',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters',
  allProjects: 'All Projects',
  projectUsers: 'Project Users',
  addUserTitle: 'Add A User',
  EditUserTitle: 'Edit User',
  allCustomers: 'All Customers',
  imageSize: ['image/jpeg', 'image/png'],
  project: 'project'
}

export const PROJECT_DATES_DROPDOWN_LIST = {
  action: 'Action',
  otherAction: 'Another action',
  something: 'Something else here',
  separatedlink: 'Separated link',
  toggle: 'Toggle Dropdown',
};
export const REQUEST = {
  request: 'Requests',
  viewAllRequest: 'View All Requests',
};

export const MENU = {
  projects: 'Projects',
  request: 'Requests',
  company: 'Company',
  overview: 'Overview'

};

export const ERROR_MESSAGES = {
  projectUserErrorMessage: 'Error fetching project user',
  userDetailsErrorMessage: 'Error fetching user details',
}

export const DASHBOARD = {
  request: 'Request',
  user: 'User',
  credit: 'Credit Application',
  error: 'error getting the project',
  Updatsuccessful: 'country id updated successfully',
  lotError: 'Error in updating the country id',
  inActive: 'This company is not associated with any active projects. Please contact your',
  administrator: 'administrator',
  assistance: 'for assistance.',
  creditError: 'Error fetching credit application:',
  creditErrorAdd: 'Profile data could not be retrieved.',
  startApplication: 'Start Application',
  finishApplication:'Finish Application',
  creditApplication: 'Credit Application',
  projectSelect: 'Please select a project you would like to submit a credit application for:',
  newApplication: 'Add a New Credit Application',
  cancel: 'Cancel',
  continue: 'Continue',
  existingRecord: 'You have existing credit application(s) in draft or initiated status from Studio Operations for the following departments. Please choose one to complete or create a new application.'


};

export const ACTIVE_PROJECTS = {
  projectName: 'Project Id',
  viewProject: 'View Project',
  viewProjectAndBookmark: 'View Bookmarks & Project',
  dates: 'Dates',
  aStage: "A Stage",
  officeSuite: 'Office Suit',
  floorPlanSuit: 'Floor Plan Suit',
  groundFloorPlan: 'Ground Floor Plan',
  technicalSpec: 'Technical Spec',
  floorPlan: 'FloorPlan',
  ar: 'A/R'
};

export const PROJECT_DETAILS_SPACES = {
  mySpaces: 'My Spaces & Resources',
  spaces: 'Spaces & Resources',
  stages: 'STAGES',
  offices: 'OFFICES',
  workshops: 'WORKSHOP',
  maps: "Maps",
  viewMaps: 'View All Maps'

};

export const PROJECT_DETAILS_CONSTANTS = {
  allProjects: 'Projects',
  projectId: 'PROJECT ID # ',
  ar: 'A/R # ',
  dates: 'DATES : ',
  description: 'DESCRIPTION',
  editDescription: 'Edit Project Description',
  addUser: 'Add A User',
  projectDetailsErrorMessage: 'Error in fetching the project details',
  editDescriptionErrorMessage: 'Error in updating description',
  bookmarkErrorMessage: 'Error in upating bookmark status',
  imageError: 'Error during upload',
  projectImage: 'project',
  section: 'section',
  projectIdImage: 'projectId',
  file: 'file',
  addCreditApplication: '+ Add a Credit Application',
  editSuccess: 'Project updated successfully'
};

export const ACKNOWLEDGE_CONSTANTS = {
  acknowledgeLabel: 'Acknowledgment Required',
  acknowledgeConditionOne: 'Customer acknowledges and agrees that its submission of this Credit Application Form in no way guarantees the approval of any credit to Customer, and that Customer has no right to procure any services, materials or other items from Warner Bros. Studio Operations departments unless and until a Net Term Account is approved.',
  acknowledgeConditionTwo: 'Customer acknowledges and agrees that its submission of this Credit Application Form authorizes WBSO to investigate Customer’s credit information and history and contact the listed references for information that might be useful in the determination of whether a Net Term Account will be given to Customer.',
  cancelButton: 'Cancel',
  acknowledgeConditionsBtn: 'I acknowledge these conditions'
}

export const EDIT_DESCRIPTION_CONSTANTS = {
  editProject: 'Edit Project',
  description: 'Project Description',
  save: 'Save Changes',
  discard: 'Discard Changes',
  close: 'Close'
};

export const ADD_USER_CONSTANTS = {
  addUser: 'Add A User',
  editUser: 'Edit User',
  close: 'Close',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  jobTitle: 'Job Title',
  optionalSettings: 'Optional Settings',
  departmentAccess: 'Department Access',
  sectionAndPermissions: 'SECTION AND PERMISSIONs',
  access: 'ACCESS',
  projects: 'PROJECTS',
  serviceRequest: 'Service Request',
  creditApplication: 'CREDIT APPLICATION',
  manageUsers: 'MANAGE USERS'
};

export const PROJECT_USERS_COUNT = {
  projectUsers: 'Project Users',
  viewAll: 'View All',
  addUser: 'Add a user',
  users: 'USERS',
};

export const CREDIT_APPLICATION_CONSTANTS = {
  creditApplications: 'Credit Applications',
  startApplication: 'Start a Credit Application',
  creditApplicationForm: 'Credit Application Form',
  credAppSubtext: 'Please ensure the credit application in its entirety.',
  certificateOfInsurance: 'Certificate of Insurance',
  coiSubtextOne: 'This certificate is issued as a matter of information only and confers no rights upon the certificate holder.',
  coiSubTextTwo: 'This certificate does not amend, extend or alter the coverage afforded by the policies below.',
  termsAndConditions: 'Terms & Conditions',
  tncSubText: 'Warner Bros. Studio Facilities',
  reviewApplication: 'Review Application',
  reviewSubtext: 'Edit or Add any missing information before sending your request to WB Studio Facilities.',
  close: 'Close',
  needsRevision: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  startApplicationStatus: 'Start Application',
  finishApplication: 'Finish Application',
  allDepartments: 'All Departments',
  all: 'All'
};

export const EMAIL_NOTIFICATION = {
  emailNotification: 'Email Notifications',
  crditApplication: 'Credit Applications',
  applicationAndStatus: 'Credit Applications & their statuses',
  company: 'Company',
  companyStatus: 'New users added to a project, company updates',
  save: 'Save',
};

export const PROFILE = {
  myProfile: 'My Profile',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone',
  jobTitle: 'Job Title',
  save: 'Save',
  validatorRequiredFname: 'First Name is required.',
  validatorRequiredLname: 'last Name is required.',
  validatorRequiredEmail: 'email Name is required.',
  validatorRequiredPhone: 'phone Name is required.',
  validatorRequiredJobTitle: 'job Title is Required',
  spaceValidator: 'First Name should not contain numbers or spaces.',
  specialCharacterValidator: 'can only contain numbers, "()", "-", and "+".',
  maxLengthValidator: 'Phone number must be up to 15 characters.',
  lNameLengthValidator: 'Last name exceeded the length',
  FNameLengthValidator: 'first Name exceeded the length',
  jobTitleLengthValidator: 'Job title exceeds the length limit of 50 characters.',
  discardChanges: 'Discard Changes',
  yes: 'Yes',
  no: 'No',
  discardChangesMessage: 'Are you sure you want to discard changes?',
  tooltip: "Please enter the country code. Phone number format example for US: +1 (000) 000-0000; UK: +44 0000 000 0000",
  spaceerror: 'Please remove any extra spaces before or after the text.',
  twospaceerror: 'Please remove extra spaces between words.',

};
export const PHONE_PATTERN = /^[0-9()+\-]*$/;
export const NAME_PATTERN = /^[A-Za-z\s]*$/;




export const COMPANY = {
  companyid: 'Company ID #',
  projects: 'Projects',
  companyaddress: 'Production Company Address',
  billingaddress: 'Billing Address',
  contactinfo: 'Contact Information',
  companyuser: 'Company Users',
  creditstatus: 'Credit Status',
  projectuser: 'Add a User To',
  adduser: 'Add a User',
  viewall: 'View All',
  current: 'Current',
  editcompany: 'Edit Company',
  addcompany: 'Add a Company',
  portaltype: 'customer',
  modalEdit: 'edit',
  modalAdd: 'add',
  notavailable: 'Not Available',
  companyerror: 'Company does not exist',
  componenttype: 'company',
  success: 'success',
  validtill: 'Valid until',
  federalTaxId: 'Federal Tax ID #',

};

export const COMPANY_USERS = {
  companyusers: 'Company Users',
  user: 'User',
  filter: 'Filters',
  adduser: 'Add a User',
  edituser: 'Edit A Customer User',
  portaltype: 'customer',
  modalEdit: 'edit',
  modalAdd: 'add',
  company: 'Company',
  componenttype: 'company',
  success: 'success',
  userType: 'companyuser'
};

export const COMPANY_USERS_REQUEST = {
  system: 'system admin',
  account: 'account payable',
  company: 'company admin',
};

export const GETSTATUS_CLASS = {
  active: 'ACTIVE',
  deactivated: 'DEACTIVATED',
};

export const COMPANY_USERS_FILTER_TYPES = {
  activeprojects: 'activeprojects',
  status: 'status',
  user: 'user',
  jobtitle: 'jobtitle',
  userType: 'userType'
}
export const COMPANY_FILTERS = {
  projects: 'Projects',
  filters: 'FILTERS',
  clearFilters: 'Clear Filters'
}


export const PROJECT_USERS_LIST_CONSTANTS = {
  all: 'All',
  active: 'Active',
  deactivated: 'Deactivated',
  admin: 'Admin',
  companyAdmin: 'Company admin',
  accountPayableUser: 'account payable',
  activeStatus: 'ACTIVE',
  deactivatedStatus: 'DEACTIVATED',
  statusActive: 'Y',
  statusDeactive: 'N',
  allAccess: 'ALL'
}

export const ADD_PROJECT_USER_CONSTANTS = {
  manage: 'Can Manage',
  view: 'Can View',
  all: 'All Departments'
}

export const DropdownSettings = {
  id: "id",
  textField: "text",
  limitSelection: -1,
  maxHeight: 197,
  itemsShowLimit: 999999999999,
  searchPlaceholderText: "Search",
  noData: "No data available",
  noFilteredData: "No filtered data available",
}

export const PROJECT_DROPDOWN_SETTINGS = {
  idField: "id",
  textField: "text",
  maxHeight: 250,
  noDataAvailablePlaceholderText: "No data available",
  noFilteredDataAvailablePlaceholderText: "No filtered data available",
  closeDropDownOnSelection: false,
  defaultOpen: false
}

export const SORTING_CONSTANTS = {
  sort: "Sort:",
  mostRecent: "Most Recent",
  aToz: "A - Z"
}


export const WEATHER_FORECAST_CONSTANTS = {
  rain: "Chance of Rain:",
  change: "Change",
  forecast: "10 Day Forecast",
  weatherUrl: "https://weather.com/weather/today/l"
}

export const DATEPICKER_CONSTANTS = {
  lastLogin: "Last Login",
  dates: "Dates"
}

export const onTheLot = {
  cafetria: 'Commissary Cafeteria',
  menu: '- Menu & Order Online',
  fine: 'Fine Dining',
  party: '- Dining & Private Party menus',
  grill: 'Avon Grill',
  orderOnline: '- Menu & Order Online',
  roadRunner: 'Road Runner Delivery',
  orderOnlineMenu: '- Menu & Order Online',
  aramark: 'Aramark',
  foodServices: '- Food Services',
  feature: 'Feature Medical',
  medical: '- Medical care',
  safety: '- Safety Services',
  mentalHealth: '- Mental Health & Wellbeing support',
  solas: 'Solas Mind',
  wonderWorks: 'The Wonderworks',
  childcare: '- Childcare',
  zone10: 'Zone 10',
  fitnessWellbeing: '- Fitness & Wellbeing',
  msl: 'MSL'
}



export const ALL_PASSES = {
  wbdPass: 'WBD Pass',
  close: 'Close',
  createPass: 'View or Create passes on',
  link: 'pass.wbd.com',
  refresh: 'Refresh',
  allPasses: 'All Passes',
  driveOn: 'Drive-On',
  walkOn: 'Walk-On',
  checkedIn: 'Checked In only',
  days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  today: 'today-tab',
  checkedInStatus: 'Checked In',
  export: 'Export',
  error: 'error',
  noReponse: 'No response received',
  all_passes: 'All Passes',
  drive_on: 'Drive-On',
  walk_on: 'Walk-On',
  warn: 'No response received',
  checkedOut: 'CHECKED OUT',
  checkedInTime: 'Checked In',
   checkedOutTime: 'Checked Out'
}

export const CERTIFICATE_OF_INSURANCE = {
  title: 'Insurance Information',
  namesLabel: 'NAMES:',
  insuranceBrokerName: "Your insurance broker's name",
  producerLabel: 'Producer',
  insuredLabel: 'Insured',
  addressLabel: 'Address:',
  streetLabel: 'Street',
  cityLabel: 'City',
  stateLabel: 'State',
  zipCodeLabel: 'ZIP Code',
  uploadForm: 'Upload your Form',
  dragAndDrop: 'Drag and Drop your file here, or',
  browse: 'browse',
  addComment: 'Add a Comment',
  comments: 'Comments',
  back: 'back',
  termsAndConditionsBtn: 'Next: Terms & Conditions',
  saveAndCloseBtn: 'Save & Close',
  sample: 'View Sample',
  needsRevision: 'NEEDS REVISIONS',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application'
}

export const CREDIT_APP_TOAST_MESSAGES = {
  insuranceSuccess: 'Certificate Of Insurance Saved Successfully',
  creditApplication: 'Application Form Saved Successfully',
  reviewSuccess: 'Credit Application Submitted Successfully',
  uploadSuccess: 'Certificate Of Insurance Uploaded Successfully',
  removeSuccess: 'Certificate Of Insurance Deleted Successfully',
  tradeReferenceSuccess: 'Trade Reference Uploaded Successfully',
  tradeReferenceRemoveSuccess: 'Trade Reference Removed Successfully',
  fileMoreThan100MBErrMsg: 'File could not be uploaded due to 100MB file size limit or incorrect file format',
  fileMoreThan5MBErrMsg: 'File could not be uploaded due to 5MB file size limit or incorrect file format',
  dropOnlyOneFile: 'You can drop only one file',
  selectPdfFile: 'Please select a PDF file',
  duplicateFileName: 'File could not be uploaded due to duplicate file name',
  moreThan20Files: 'You cannot upload more than 20 files in a single upload',
  logUploadSuccess: 'Letter of Credit/Guarantee Uploaded Successfully',
  logDeleteSuccess: 'Letter of Credit/Guarantee Deleted Successfully',
  revisionApplication: 'Application Form Revisions Saved Successfully'
}

export const ADD_APPLICATION_CONSTANTS = {
  submitSuccess: '"Credit Application Created Successfully"'
}
export const CRED_FORM_ONE_CONSTANT = {
  fieldRequired: 'This field is required',
  nameInvalid: 'Name is not valid',
  streetInvalid: 'Street is not valid',
  cityInvalid: 'City is not valid',
  PhoneInvalid: 'Please enter a valid phone number',
  zipcodeInvalid: 'Zipcode is not valid',
  tinInvalid: 'Tin is not valid',
  firstNameInvalid: 'First Name is not valid',
  lastNameInvalid: 'Last Name is not valid',
  emailInvalid: 'Please enter a valid email address',
  TypeOfBusinessInvalid: 'Type Of Business is not valid',
  currencyInvalid: 'Currency Format is not valid',
  formatInvalid: 'Format is not valid',
  titleInvalid: 'Title is not valid',
  bankNameInvalid: 'Bank Name is not valid',
  accNoInvalid: 'Please enter a account number',
  contactNameInvalid: 'Contact Name is not valid',
  companyNameInvalid: 'Company Name is not valid',
  totalNoOfMonthsInvalid: 'Total Month is not valid',
  view: 'View',
  remove: 'Remove',
  section: 'letterofguarantee',
  close: 'close',
  SaveandClose: 'Save & Close',
  review: 'Next: Certificate Of Insurance',
  certificate: 'Next: Certificate of Insurance',
  cancel: 'Cancel'
}

export const CRED_FORM_THREE_CONSTANT = {
  fieldRequired: 'This field is required',
  nameInvalid: 'Name is not valid',
  titleInvalid: 'Title is not valid',
  signatureInvalid: 'Signature is not valid',
  needsRevision: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application',
  termsAndConditionsSuccess: 'Terms and Conditions Saved Successfully'
}

export const TERMS_AND_CONDITIONS = `
  <ul class="term-condition-list">
    <li class="mb-3"><b>TERMS:</b> Net 30 days from date of invoice. Customer agrees to make payments in full to Warner Bros. Studio Operations (“WBSO”), a division of WB Studio Enterprises Inc., for all amounts due according to WBSO invoice(s). Should Customer default in any such payment(s), WBSO shall have the right, without notice to Customer, to declare all invoice amounts immediately due and payable. In the event WBSO should commence any action or otherwise seek to enforce these terms and conditions against Customer, Customer agrees to pay reasonable collection and attorney(s) fees, costs and other related expenses incurred by WBSO, whether or not an action is filed. This agreement is not transferable or assignable by Customer without the prior written consent of WBSO. Any sums payable to WBSO shall be paid within the terms stated above or shall bear interest from the due date, at the then prime rate or the rate at which WBSO is then able to borrow funds, whichever is greater. This Application may be used for any and all WBSO departments regardless of those specifically identified above. Venue for all disputes shall be in the County of Los Angeles.</li>

    <li class="mb-3">Payments are to be mailed to: WBSO - P.O. Box 847250 - Los Angeles, CA 90084-7250</li>
    <li class="mb-3">The WBSO terms and condition of sales, shall govern all transactions between WBSO and Customer, including any additional terms and conditions that may be provided by any WBSO department.</li>
    <li class="mb-3">WBSO reserves the right to decline credit to Customer at WBSO's sole discretion, and, in the event credit is extended to Customer, WBSO reserves the right to change or revoke Customer's credit line on the basis of changes in WBSO's credit policies or Customer's financial condition and/or payment record.</li>
    <li class="mb-3">Customer hereby requests WBSO to consider this Application for the purpose of extending credit and authorizes (i) the references listed on this Application to provide all information requested in conjunction with this Application; and (ii) WBSO to investigate Customer's credit information and history as WBSO may deem necessary.</li>
    <li class="mb-3">The undersigned hereby certifies that he or she is duly authorized to sign this Application on behalf of Customer, that the information given in this Application is true and correct to the best of his or her knowledge and that the Customer hereby agrees to the foregoing terms and conditions.</li>
  </ul>
`;

export const CRED_FORM_FOUR_CONSTANT = {
  creditApplicationForm: 'Credit Application Form',
  lineOfCreditRequested: 'Line of Credit requested:',
  anticipatedJobTotal: 'Anticipated Job Total:',
  totalNoOfMonths: 'Total # of months:',
  lineOfCredit: 'Line of credit:',
  letterOfCredit: 'LETTER OF CREDIT/GUARANTEE:',
  company: 'COMPANY:',
  federalTaxId: 'Federal tax ID #:',
  accountPaybleContact: 'ACCOUNT PAYABLE CONTACT:',
  companyProfile: 'COMPANY PROFILE:',
  profile: 'Profile:',
  totalAnnualSales: 'Total Annual Sales:',
  startedOn: 'Started on:',
  networth: 'Networth:',
  typeOfBusiness: 'Type of Business:',
  noOfEmployees: '# of Employees:',
  reference1: 'Reference 1:',
  reference2: 'Reference 2:',
  reference3: 'Reference 3:',
  principalOfficer: 'Principal officer:',
  bankingReference: 'Banking reference:',
  tradeReferences: 'Trade references:',
  certificateOfInsurance: 'Certificate of Insurance',
  insuranceInformation: 'Insurance Information:',
  form: 'Form',
  termsAndConditions: 'Terms & Conditions',
  saveAndClose: 'Save & Close',
  submitCreditApplication: 'Submit Credit Application',
  needsRevisions: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  billingAddress: 'BILLING ADDRESS:',
}

export const PASSES_EXPECTED_TODAY = {
  passesExpectedToday: 'Today’s Passes',
  all: 'All',
  passes: 'Passes',
  driveOn: 'Drive On',
  walkOn: 'Walk On',
  nextHour: 'Next Hour',
  today: 'Today',
  checkedIn: 'Checked In',
  viewAllPasses: 'View 5 Day Passes',
  technicalSpec: 'TechnicalSpec',
  floorPlan: 'Floor Plan',
  refresh: 'Refresh',
  checkedInStatus: 'Checked In',
  all_passes: 'All Passes',
  drive_on: 'Drive-On',
  walk_on: 'Walk-On',
  error: 'No response received'
};
export const CRED_DEPARTMENT_CONSTANT = {
  credDepartmentFormTitle: 'Credit Application Departments',
  cancel: 'Cancel',
  saveChanges: 'Save Changes',
  deptEditError: 'Error While Updating Credit Application Departments',
  deptEditSuccess: 'Credit Application Departments Updated Successfully'
}

export const CRED_STEP_ONE_CONSTANT = {
  view: 'View',
  needsRevision: 'NEEDS REVISIONS',
  revisionsSubmitted: 'REVISIONS SUBMITTED',
  startApplication: 'Start Application',
  finishApplication: 'Finish Application',
  enable: 'enable',
  disable: 'disable'
}

export const CREDIT_APP_REPORT_SECTION = {
  dnbSection: 'dnb',
  locSection: 'loc',
  coiSection: 'coi',
  trSection: 'traderef1'
}

//Success message for the API
export const CUSTOMER_SUCCESS_APIMESSAGE = {
  //dashboard

  //project

  //company

}

export const VIEW_ALL_CREDIT_APPLICATIONS = {
  hii: 'Hi',
  filter: 'FILTER :',
  Creditapplications: 'Credit Applications'
}
export const CONFIRM_CLOSE_POPUP = {
  confirmCreditAppCloseMsg: 'Are you sure you want to close the application without saving?',
  yesBtn: 'Yes',
  noBtn: 'No'
}
