import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-salesforcepath-form',
  templateUrl: './salesforcepath-form.component.html',
  styleUrls: ['./salesforcepath-form.component.scss']
})
export class SalesforcePathFormComponent implements OnInit {
  sanitizedUrl: SafeResourceUrl;
  projectName: string = '';
  userName: string = '';
  companyName: string = '';
  projectId: string = '';
  isLoading: boolean = true; // Add this line


  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.projectId = params['projectId'];
      this.projectName = params['projectName'];
      this.userName = params['userName'];
      this.companyName = params['companyName'];

      const salesforceUrl = `https://wbp--great360sc.sandbox.my.salesforce-sites.com/GreenLight?project=${encodeURIComponent(this.projectName)}`;
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(salesforceUrl);
    });
  }
  onIframeLoad(): void {
    this.isLoading = false; // Set loading to false when iframe is loaded
  }

}
