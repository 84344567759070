import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
  companyJobTitleTypeahead,
  companyStatusData,
  companyUserTypeahead,
} from 'mockData/development/company-filters-data';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import {
  COMPANY_FILTERS,
  DropdownSettings,
} from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CreditApplicationService } from '../../../services/credit-applications/credit-application.service';
import { filter } from 'rxjs';
import {
  CREDIT_APPLICATION_FILTERS,
  departmentsTypeahead,
  projectsTypeahead,
  VIEW_ALL_CREDIT_APPLICATIONS,
} from '../../../constants/customer-portal.constant';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { INDEX } from '../../../enum/customer-portal.enum';
import { CreditDashboardDepartmentsComponent } from '../credit-dashboard-departments/credit-dashboard-departments.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-all-credit-applications',
  templateUrl: './view-all-credit-applications.component.html',
  styleUrls: ['./view-all-credit-applications.component.scss'],
})
export class ViewAllCreditApplicationsComponent implements OnInit {
  permissionsData: any;
  credit: any;
  projectsFilter;
  departmentFilter;
  filteredDepartments: any;
  name: string;

  constructor(
    private creditService: CreditApplicationService,
    private loaderService: LoaderService,
    private router: Router
  ) {}
  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.name = userProfile.firstName;
    this.permissionsData = { ...userProfile.roleAssigned };
    const companyId = userProfile.companyId;
    this.getCreditApplications();
  }
  filterTypes = CREDIT_APPLICATION_FILTERS;

  defaultTypeahead: any = 'typeahead';
  userFilterData: any[] = [];
  filteredProjects: any[] = [];
  filteredStatus: any[] = [];
  filteredUser: any[] = [];
  filteredJobtitle: any[] = [];
  filteredData: any[] = [];
  jobTitleFilterData: any[] = [];
  isFiltersSelected: boolean = false;
  dropdown = DropdownSettings;
  defaultSettings: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false,
  };

  // Data for dropdown lists projects, types, departments, status and dayes
  activeprojects;
  status;
  companyConstants = COMPANY_FILTERS;
  departments = departmentsTypeahead;
  projects = projectsTypeahead;
  userTypeahead: any = 'typeahead';
  creditApplication = VIEW_ALL_CREDIT_APPLICATIONS;
  rowData = [];


  /**
   *  filterDataCreation() - Method to create the users filters data and passing through the filter variables
   *  @param userRecord - All the available user list
   */
  filterDataCreation(userRecord: any): void {
    const departments = userRecord.flatMap((data) => {
      if (data.department) {
        return data.department.split(';');
      }
      return [];
    });
    const uniqueDepartments = Array.from(new Set(departments));
    const sortedDepartments = uniqueDepartments.sort();
    const consolidatedDepartments = sortedDepartments.map((dept, index) => ({
      id: index + 1,
      text: (dept as string).trim(),
    }));
    this.departmentFilter = consolidatedDepartments;

    let projectsFilter = _.uniqBy(userRecord, 'projectName').map(
      (data, index) => {
        return {
          id: index,
          text: data.projectName,
        };
      }
    );
    this.projectsFilter = projectsFilter.sort((a, b) =>
      a.text.localeCompare(b.text)
    );

    let statusFilters = _.uniqBy(userRecord, 'status').map((data, index) => {
      return {
        id: index,
        text: data.status,
      };
    });
    this.status = statusFilters.sort((a, b) => a.text.localeCompare(b.text));
  }

  /**
   * getCreditApplications() - to get the recent credit appliations.
   * @param companyId - the company id to be passsed.
   */
  getCreditApplications(): void {
    this.loaderService.setLoadingState(true);
    this.creditService.getCreditApplication().subscribe({
      next: (response: any) => {
        const credit = response?.creditApplications?.creditApplications?.map(
          (credit) => {
            const toTitleCase = (str: string): string =>
              str
                .split(/\s+/)
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(' ');
            return {
              date: credit?.submittedDate || '---',
              projectName: credit?.projectDetails?.projectName,
              projectType: credit?.projectDetails?.projectType?.projectType,
              ar: credit?.arNumber || '---',
              department: credit?.caArdepartments
                .map((dept: any) =>
                  toTitleCase(dept?.departmentDetails?.departmentName)
                )
                .join('; '),
              status: credit.customerStatus,
              time: credit?.submittedTime|| '',
              projectId: credit?.projectDetails?.projectId,
              createdDate: credit?.createdDate
            };
          }
        );
        this.rowData = credit.sort((a, b) => {
          const dateA = new Date(a.createdDate);
          const dateB = new Date(b.createdDate);
          return dateB.getTime() - dateA.getTime();
        });
        this.filterDataCreation(credit);
        this.applyFilters();
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

  /**
   * applyFilters() - to apply filters after selection
   */
  applyFilters(): void {
    this.filteredData = this.rowData;
    if (this.filteredStatus?.length > INDEX.zero) {
      const selectedStatus = this.filteredStatus.map((status) => status.text);
      this.filteredData = this.filteredData.filter((row) =>
        selectedStatus.includes(row.status)
      );
    }

    if (this.filteredDepartments?.length > INDEX.zero) {
      const selectedDepartments = this.filteredDepartments.map(
        (department) => department.text
      );

      this.filteredData = this.filteredData.filter((item) => {
        const departmentsArray = item.department
          ? item.department.split(';')
          : [];
        return departmentsArray.some((dept) =>
          selectedDepartments.includes(dept.trim())
        );
      });
    }

    if (this.filteredProjects?.length > INDEX.zero) {
      const selectedProjects = this.filteredProjects.map(
        (project) => project.text
      );
      this.filteredData = this.filteredData.filter((row) =>
        selectedProjects.includes(row.projectName)
      );
    }
    this.hasSelectedFilters();
  }

  /**
   * hasSelectedFilters() - Method to determine if any filters are currently selected.
   * @returns A boolean indicating whether any filters are selected.
   */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected =
      this.filteredStatus?.length > INDEX.zero ||
      this.filteredDepartments?.length > INDEX.zero ||
      this.filteredProjects?.length > INDEX.zero;
    return this.isFiltersSelected;
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (user, status, jobTitle)
   * @param item - The item to be removed
   */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.status:
        {
          let statusArray = [];
          this.filteredStatus = this.filteredDataList(
            this.filteredStatus,
            item
          );
          statusArray.push(item);
          this.status = statusArray;
        }
        break;
      case this.filterTypes.departments:
        {
          let departmentsArray = [];
          this.filteredDepartments = this.filteredDataList(
            this.filteredDepartments,
            item
          );
          departmentsArray.push(item);
          this.departmentFilter = departmentsArray;
        }
        break;
      case this.filterTypes.projects:
        {
          let projectsArray = [];
          this.filteredProjects = this.filteredDataList(
            this.filteredProjects,
            item
          );
          projectsArray.push(item);
          this.projectsFilter= projectsArray;
        }
        break;
    }
    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any[] {
    return arr.filter((selectedItem) => selectedItem.id !== item.id);
  }

  /**
   * clearAllFilters() - Method to clear all the selected filters
   */
  clearAllFilters(): void {
    // Clear Status
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;
    this.filteredDepartments = [];
    this.selectedDepartments(this.filteredDepartments);
    this.departmentFilter = this.filteredDepartments;
    this.filteredProjects = [];
    this.selectedProjects(this.filteredProjects);
    this.projectsFilter = this.filteredProjects;
    this.applyFilters();
  }

  colDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'DATE',
      maxWidth: 110,
      cellRenderer: this.updatedDateTimeRenderer,
      cellStyle: { paddingRight: '0px' },
    },
    {
      field: 'project',
      headerName: 'PROJECT',
      minWidth: 155,
      maxWidth: 125,
      cellRenderer: this.projectNameTypeRenderer,
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'ar',
      headerName: 'AR #',
      minWidth: 110,
      maxWidth: 100,
      cellClass: 'inter-font',
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'department',
      headerName: 'DEPARTMENTS',
      minWidth: 227,
      cellClass: 'credit-department inter-font',
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
      cellRenderer: CreditDashboardDepartmentsComponent,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 190,
      headerClass: 'custom-header-margin',
      cellRenderer: this.statusCellRenderer.bind(this),
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
  ];
  updatedDateTimeRenderer(params: ICellRendererParams) {
    const date = params.data.date;
    const time = params.data.time;
    return `
      <span class="common-split-cell-section common-mobile-section">
      <span class="common-split-cell-data-1">${date}</span>
      <span class="common-split-cell-data-2">${time}</span>
      </span>`;
  }

  companyClientRenderer(params: ICellRendererParams) {
    const companyName = params.data.companyName;
    const clientType = params.data.clientType;
    return `<span class="common-split-cell-section common-mobile-section">
      <span class="common-split-cell-data-1">${companyName}</span>
      <span class="common-split-cell-data-2">${clientType}</span>
      </span>`;
  }
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.data.status);
    return `
        <div class="status-container">
          <div class="ar-status-tag ${statusClass} credit-status">${params.data.status}</div>
        </div>
      `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'Start Application':
        return 'start-application new-app-width new-app-margin-left ';
      case 'Finish Application':
        return 'finish-application new-app-width new-app-margin-left';
      case 'APPROVED':
        return 'approved approved-wid new-app-margin-left';
      case 'DENIED':
        return 'denied new-app-margin-left';
      case 'NEEDS REVISIONS':
        return 'revision new-app-margin-left';
      case 'NEEDS APPROVAL':
        return 'need-approve new-app-margin-left';
      case 'REVISIONS SUBMITTED':
        return 'new-application new-app-width new-app-margin-left';
      case 'PENDING RISK':
        return 'revision new-app-margin-left';
      case 'UNDER REVIEW':
        return 'under-review under-review new-app-margin-left';
      case 'APPROVED (RISK)':
        return 'approved';
      case 'Application Submitted':
        return 'new-application new-app-margin-left';
        case 'CASH ON DEMAND':
          return 'cod new-app-margin-left new-app-width'
      default:
        return '';
    }
  }

  projectNameTypeRenderer(params: ICellRendererParams) {
    const projectName = params.data.projectName;
    const projectType = params.data.projectType;
    return `<span class="common-split-cell-section common-mobile-section">
      <span class="common-split-cell-data-1">${projectName}</span>
      <span class="common-split-cell-data-2">${projectType}</span>
      </span>`;
  }

  commentsRenderer(params: ICellRendererParams) {
    const comments = params.value;
    return `
        <span class="comments-cell">
          <span class="comments-count">${comments}</span>
          <img src="assets/images/comment.svg" alt="Comment Icon" class="comments-icon"/>
        </span>`;
  }

  colsForSmallScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  colsForMediumScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  colsForLargeScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  colsForExtraLargeScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];

  /**
   * selectedStatus() - Method to handle selection of status from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedStatus(selectedItems: any) {
    this.filteredStatus = selectedItems;
    this.applyFilters();
  }
  

  /**
   * selectedDepartments() - Method to handle selection of department from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedDepartments(selectedItems: any): void {
    this.filteredDepartments = selectedItems;
    this.applyFilters();
  }

  /**
   * selectedProjects() - Method to handle selection of project from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */
  selectedProjects(selectedItems: any): void {
    this.filteredProjects = selectedItems;
    this.applyFilters();
  }

  /**
   * onRowClicked() - to redirect the user to credit Application based on click
   * @param event - when the row is clicked a data is emiited
   */
  onRowClicked(event: any): void {
    const selectedRowData = event.data;
    const tabName = 'credit';
    this.router.navigate(
      [
        'feature/customer-dashboard/project-details/',
        selectedRowData.projectId,
      ],
      { queryParams: { tab: tabName } }
    );
  }
}
