import { ChangeDetectorRef, Component, ViewChild, AfterViewInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import tippy, { hideAll } from 'tippy.js';
import { POP_UP_CELL_RENDERED, PORTAL_TYPE } from '../constants/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddProjectUserComponent } from '../components/add-project-user/add-project-user.component';
import { ERROR_MSG, PROJECTS_MODAL, STUDIO_CREDIT_APPLICATION, STUDIO_PROJECT_USERS_LIST_CONSTANTS, STUDIO_SUCCESS_APIMESSAGE, USER, USERS_TYPES } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ProjectsService } from 'src/app/feature/studio-portal/services/projects.service';
import { ToastService } from '../components/toast.service';
import { LoaderService } from '../services/loader/loader.service';
import { ActivatedRoute } from '@angular/router';
import { SendCreditApplicationComponent } from 'src/app/feature/studio-portal/components/projects/project-details/send-credit-application/send-credit-application.component';
import { BOOKMARK } from 'src/app/feature/studio-portal/enum/studio-portal.enum';

@Component({
  selector: 'app-pop-up-cell-renderer',
  templateUrl: './pop-up-cell-renderer.component.html',
  styleUrls: ['./pop-up-cell-renderer.component.scss']
})
export class PopUpCellRendererComponent implements AfterViewInit {
  private params; 
  isOpen: boolean = false;  
  private tippyInstance;  // Variable to store the Tippy.js instance
  popUpDropdown = POP_UP_CELL_RENDERED;
  modalReference: NgbModalRef;
  modalText = PROJECTS_MODAL;
  userListConstants = STUDIO_PROJECT_USERS_LIST_CONSTANTS;
  creditApplication = STUDIO_CREDIT_APPLICATION;
  user = USER;
  departmentDetails: any;
  userRoleDetails: any;
  requestResponse: any;
  
  @ViewChild('content') container;  // Reference to the popup content container
  @ViewChild('trigger') button;  // Reference to the button that triggers the popup
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Input() projectData: any[] = [];
  @Output() successResponseMsg = new EventEmitter<any>();
  @Output() dangerResponseMsg = new EventEmitter<any>();
  @Output() loadBookmarkedData = new EventEmitter<void>();
  userProfileId: any;
  
  constructor(private changeDetector: ChangeDetectorRef, private modalService: NgbModal,
    private projectsService: ProjectsService, private toastService: ToastService,
    private loaderService: LoaderService, private route: ActivatedRoute) {}

  ngOnInit(){
    const userInfo = JSON.parse(localStorage.getItem('user-profile'));
    if(userInfo) {
      this.userProfileId = userInfo.userProfileId;
    }
    this.getAddProjectUserMetadata();
  }
  /**
   * ngAfterViewInit() - Lifecycle hook to initialize the Tippy instance after view is initialized
   */
  ngAfterViewInit(): void {
    this.tippyInstance = tippy(this.button.nativeElement, {
      trigger: 'manual',  // Use manual trigger
      placement: 'right',
      arrow: false,
      interactive: true,  // Allow interaction with the popup content
      appendTo: document.body,
      hideOnClick: false,  // Prevent popup from hiding on clicking inside it
      onShow: (instance) => {
        hideAll({ exclude: instance });  // Hide other Tippy popups except the current one
      },
      onClickOutside: (instance) => {
        this.isOpen = false;  // Close the popup when clicking outside
        instance.hide();  // Hide the popup
      },
    });
    this.tippyInstance.disable();  // Disable the Tippy instance initially
  }

  /**
   * agInit() - Method to initialize ag-Grid cell renderer with parameters
   * @param params - The params passed by ag-Grid to initialize the component
   */
  agInit(params) {
    this.params = params; 
  }

  /**
   * configureTippyInstance() - Method to configure and display the Tippy instance
   */
  configureTippyInstance() {
    this.tippyInstance.enable();  // Enable the Tippy instance
    this.tippyInstance.setContent(this.container.nativeElement);  // Set content of the popup
    this.tippyInstance.show();  // Show the Tippy popup
  }

  /**
   * togglePopup() - Method to toggle the popup's visibility
   */
  togglePopup() {
    this.isOpen = !this.isOpen; 
    this.changeDetector.detectChanges(); 

    if (this.isOpen) {
      this.configureTippyInstance();  // Configure and display the popup
    } else {
      this.tippyInstance.hide();  // Hide the popup
    }
  }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata(): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails = metaData?.getProjectUserMetaDataDTO?.departmentsDetails;
      this.userRoleDetails = metaData?.getProjectUserMetaDataDTO?.userRoleDetails;
      this.loaderService.setLoadingState(false);
    })
  }
  
  /**
  * openAddUserModal() - method to open the add a user modal
  */
  openAddUserModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true
    });
    this.modalReference.componentInstance.modalTitle = this.modalText?.addUserTitle;
    this.modalReference.componentInstance.projectName = this.projectData[0]?.projectName;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails = this.userRoleDetails;
    this.modalReference.componentInstance.portalType = PORTAL_TYPE.studioPortal;
    this.modalReference.componentInstance.projectId = this.projectData[0]?.projectId;
    this.modalReference.componentInstance.componentType = PORTAL_TYPE.studioTypeAdd;
    this.modalReference.componentInstance.projectCompanyId = this.projectData[0]?.company?.companyId;
    this.modalReference.componentInstance.modalType = USERS_TYPES.addModalType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == 'success') {
          this.requestResponse = this.user.addUserSuccessMsg;
          this.successResponseMsg.emit(this.requestResponse);
        } else {
          this.requestResponse = data;
          this.dangerResponseMsg.emit(this.requestResponse);
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
  * openSendCreditApplicationModal() - method to open the send credit application modal
  */
   openSendCreditApplicationModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(SendCreditApplicationComponent, {
      size: 'lg',
      centered: true
    });
    this.modalReference.componentInstance.projectId = this.projectData[0]?.projectId;
    this.modalReference.componentInstance.projectName = this.projectData[0]?.projectName;
    this.modalReference.componentInstance.projectCompanyId = this.projectData[0]?.company?.companyId;
    this.modalReference.componentInstance.companyName = this.projectData[0]?.company?.companyName;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.userListConstants.statusSuccess) {
          this.requestResponse = this.creditApplication.successSentCreditApplication;
          this.successResponseMsg.emit(this.requestResponse);
        } else {
          console.error(data);
          this.requestResponse = data;
          this.dangerResponseMsg.emit(this.requestResponse);
        }
      }
      // on close
    }, (reason) => {
      // on dismiss
    });
  }

  /**
   * removeBookmark() - method to remove the bookmark
  */
  removeBookmark(): void {
    const bookmarkPayload = {
      userProfileId: this.userProfileId,
      projectId: this.projectData[0]?.projectId,
      status: STUDIO_PROJECT_USERS_LIST_CONSTANTS.statusDeactive,
      projectBookmarked: BOOKMARK.zero
    }
    this.loaderService.setLoadingState(true);
    this.projectsService.removeBookmarkByUserAndProjectID(bookmarkPayload)
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.requestResponse = STUDIO_SUCCESS_APIMESSAGE.removeBookmark;
          this.loadBookmarkedData.emit();
          this.loaderService.setLoadingState(false);
          this.showSuccess(this.successTpl);
        },
        error: (error) => {
          this.requestResponse = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
          console.error(ERROR_MSG.bookmarkRemoveError, error);
        }
      })
  }

/**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
showSuccess(successTpl: TemplateRef<any>): void {
  this.toastService.show(successTpl, {
    classname: 'custom-toast',
    delay: 3000,
  });
}

/**
 * showDanger() - Method to display a danger (error) toast message
 * @param dangerTpl - The template reference for the danger message to display
 */
showDanger(dangerTpl: any): void {
  this.toastService.show(dangerTpl, {
    classname: 'bg-danger text-light',
    delay: 3000,
  });
}

}
