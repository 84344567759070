<!-- menu.component.html -->
<nav [class.sticky]="sticky" [ngClass]="{'bg-blue': portalType === 'studio' || portalType === 'ar'}"
  class="user-navbar navbar navbar-expand-lg navbar-light bg-light sticky-top d-none d-lg-flex">
  <div class="container">
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mx-auto" id="navbarNav">
        <!-- Customer Menu -->
        <ng-container *ngIf="portalType === 'customer'">
          <li class="nav-item" *ngIf="permissionsData['accessOverview']">
            <a class="nav-link" routerLink="/feature/customer-dashboard/overview" routerLinkActive="active"
              id="overview">
              {{ customerlabels.overview }}
            </a>
          </li>
          <li class="nav-item" *ngIf="permissionsData['accessProject']">
            <a class="nav-link" routerLink="/feature/customer-dashboard/projects-list" routerLinkActive="active"
              id="projects">
              {{ customerlabels.projects }}
            </a>
          </li>
          <li class="nav-item" *ngIf="permissionsData['accessRequest']">
            <a class="nav-link" routerLink="/feature/customer-dashboard/requests" routerLinkActive="active"
              id="requests">
              {{ customerlabels.requests }}
            </a>
          </li>
          <li class="nav-item" *ngIf="permissionsData['accessCompany']">
            <a class="nav-link" routerLink="/feature/customer-dashboard/company" routerLinkActive="active" id="company">
              {{ customerlabels.company }}
            </a>
          </li>
        </ng-container>

        <!-- Studio Menu -->
        <ng-container *ngIf="portalType === 'studio'">
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/studio-dashboard/Mydashboard" routerLinkActive="active"
              id="Mydashboard">
              {{ studiolabels.myDashboard }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/studio-dashboard/company/companies" routerLinkActive="active"
              id="companies">
              {{ studiolabels.companies }}
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/studio-dashboard/project/project-list" routerLinkActive="active"
              id="projects">
              {{studiolabels.projects }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/studio-dashboard/users" routerLinkActive="active" id="users">
              {{ studiolabels.users }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/studio-dashboard/credit-app" routerLinkActive="active"
              id="creditAplications">
              {{ studiolabels.creditAplication }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/studio-dashboard/requsets" routerLinkActive="active" id="requests">
              {{ studiolabels.request }}
            </a>
          </li>
        </ng-container>

        <!-- A/R Menu -->
        <ng-container *ngIf="portalType === 'ar'">
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/ar-dashboard/overview" routerLinkActive="active"
              id="Credit Applications">
              {{ arlabels.creditAplication }}
            </a>
            <div *ngIf="showAlert" >
            <img src="assets/images/ellipse.svg" alt="notification">
          </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/ar-dashboard/company/companies" routerLinkActive="active"
              id="companies">
              {{ arlabels.companies }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/ar-dashboard/project/project-list" routerLinkActive="active"
              id="projects">
              {{ arlabels.projects }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/feature/ar-dashboard/users" routerLinkActive="active" id="users">
              {{ arlabels.users }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
