export const CONSTANT = {
    LOGIN_USER_STATUS_APPROVED: 'Approved',
    LOGIN_USER_STATUS_DEACTIVATED: 'Deactivated',
    LOGIN_USER_STATUS_REJECTED: 'Rejected',
    LOGIN_USER_STATUS_PENDING: 'Pending',

    HOLDOVERS:
    {
        HOLOVERACTIONS:
        {
            UNRESOLVED: 'Unresolved',
            OK: 'Ok',
            HOLD: 'Hold',
            TERMINATE: 'Terminate',
            DNP: 'Did Not Play',
        },
        HOLDOVER_STATUS:
        {
            TERMINATE: 'Terminate',
            OKAY:'Ok',
            HOLD:'Hold'

        },
        PLAYDATES:
        {
            COL_MIN_WIDTH: 275,
            COL_MAX_WIDTH:300,
            DROPDOWN_SETTINGS:
            {
                SINGLESELECTION: false,
                IDFIELD: 'id',
                TESTFIELD: 'itemName',
                SELECT_ALL_TEST: 'Select All',
                UNSELECT_ALL_TEST: 'Unselect All',
                ALLOW_SEARCH_FILTER: true
            }
        },
        ENDDATE:
        {
            COL_WIDTH: 200,  
        }

    },
    USER_ROLE:
    {
        EXHIBITOR:'exb',
        ADMIN:'admin',
        LICENSEE:'licensee',
        EMPLOYEE:'employee',
        ADMIN_PAYMENT: 'admin-payment',
        ADMIN_SALES:'admin-sales',
    },
    DISPLAY_ROLENAME:
    {
        EXHIBITOR:'Exhibitor',
        ADMIN:'Admin',
        LICENSEE:'Licensee',
        EMPLOYEE:'Employee'
    },
    FILM_TITLE_DETAILS:
    {
        READ_MORE:'READ MORE +',
        READ_LESS:'READ MORE -',
        IMAX:'IMAX'
    },
    SEARCH_RESULTS_PAGE:
    {
      ACTIVE_PANEL:'advancedSearchAccordion'
    },
    HEADER_SEARCH:
    {
        FILM_TITLE:'Film Title',
        GENRE:'Genre'
    },
    USER_PREFERENCES:
    {
        BOOKING_TYPES:
        {
            CLASSICS:'classics',
            FIRSTRUN:'firstRun'
        },
        RADIO:
        {
            CHECKED: 'checked',
        }
    },
    PREVIEW_MODAL:
    {
        TITLE: 'title',
        TITLEONESHEET :'titleOneSheet',
        DEFAULT_FORMAT:'defaultFormat',
        RESOLUTION:'resolution',
        RELEASE_YEAR:'releaseYear',
        CLASSIC_DATE:'classicsDate',
        NON_THEATRICAL_DATE:'nonTheatricalDate',
        RUNTIME:'runTime',
        ASPECT_RATIO:'aspectRatio',
        MPAA_RATING:'mpaaRating',
        TYPE_OF_TITLE:'typeOfTitle',
        RELEASE_DATE:'releaseDate',
        FORMAT:'format',
        DATE_FORMAT:'MMMM d, y',
    },
    LANGUAGES:
    {
        EN:'en'
    },   
}
export const ERROR={
    error_header: 'Page Not Found',
    error_message: 'Oops! It seems you have taken a wrong turn. The page you are looking for may have been moved or no longer exists.',
    error_btn_title: 'Go back to the Homepage',
   }

   export const SUCCESS_ERROR={
    wbso_success: 'WBSO-USER ADDED SUCCESSFULLY',
    wbso_error: 'WBSO user not user created',
    wbso_success_response_msg: 'WB user added successfully',
    wbso_response_error_msg: 'Error adding WB user',
    wbso_warning_msg: 'Form is invalid',
   }

   export const PROFIE_TOAST_MESSAGES ={
    profile_success: 'User Profile has been successfully updated'
   }