<div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModal(content)"
    >
      hello
    </button>
  </div>
  <ng-template #content let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Needs Revisions</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Production Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show - Pilot</span>
        </div>
      </div>
      <div class="section-text-area">
        <h3 class="section-text">Section Name:</h3>
        <textarea class="text-area" id="credit-text" name="credit-text">Could you please let us know the contact’s full name?</textarea>
      </div>
    </div>
    <div class="end-wrapper">
      <div class="button">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Save Comment</button>
      </div>
    </div>
  </ng-template>
  
  <div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModalOne(content1)"
    >
      hello-1
    </button>
  </div>
  <ng-template #content1 let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Request A/R #</h2>
      </div>
      <div class="container cm-main-container px-0 mx-0">
        <div class="alert revision-alert mb-4" role="alert">
          <div class="container">
            <div class="row">
              <div class="wbd-alert-content my-2">
                Revisions needed:
              </div>
            </div>
            <div class="row">
              <div class="wbd-alert-content-list">
                <ul>
                  <li>
                    <span>Purchase Order</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-3 flex-column">
          <h3 class="mb-0">Upload a Purchase Order</h3>
        <div class="alert revision-alert" role="alert">
          <div class="container">
            <div class="row">
              <div class="wbd-alert-content-list my-2">
                Revisions needed:
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 mb-4 mt-2">
        <app-upload-file></app-upload-file>
      </div>
      <div class="upload-coi mb-3">
        <h5 class="mb-3">Upload a certificate of Insurance</h5>
        <div class="row mx-0">
          <div
            class="col-12 col-md-9 col-sm-9 col-lg-10 d-flex align-items-center gap-2 px-0"
          >
            <div>
              <!-- Display generic file icon -->
              <img
                src="assets/images/pdf-icon.svg"
                alt="file icon"
                class="file-icon"
              />
            </div>
            <div>
              <span  class="file-name">name</span>
            </div>
          </div>
          <div class="col-12 col-md-3 col-sm-3 col-lg-2 d-flex p-0 justify-content-end">
            <span class="text-link px-2">
              View
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <div><a href="#" class="back-link text-link">
          <   back</a>
      </div>
      <div class="d-flex align-items-center gap-2">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Send A/R # Request</button>
      </div>
      </div>
        </div>
        </div>
  </ng-template>
  <div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModalTwo(content2)"
    >
      hello-2
    </button>
  </div>
  <ng-template #content2 let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Request A/R #</h2>
      </div>
      <div class="w-100">
        <h5 class="mb-3">Upload a certificate of Insurance</h5>
          <div
            class="d-flex align-items-center justify-content-between"
          >
          <div class="d-flex gap-2">
            <div class="">
              <!-- Display generic file icon -->
              <img
                src="assets/images/pdf-icon.svg"
                alt="file icon"
                class="file-icon"
              />
            </div>
            <div>
              <span  class="file-name">name</span>
            </div>
            </div>
          
          <div class="d-flex p-0 justify-content-end">
            <span class="text-link px-2">
              View
            </span>
          </div>
        </div>
      </div>
      <div class="w-100">
        <h5 class="mb-3">Upload a certificate of Insurance</h5>
          <div
            class="d-flex align-items-center justify-content-between"
          >
          <div class="d-flex gap-2">
            <div class="">
              <!-- Display generic file icon -->
              <img
                src="assets/images/pdf-icon.svg"
                alt="file icon"
                class="file-icon"
              />
            </div>
            <div>
              <span  class="file-name">name</span>
            </div>
            </div>
          
          <div class="d-flex p-0 justify-content-end">
            <span class="text-link px-2">
              View
            </span>
          </div>
          <!-- <div class="row next-back-btns-section"> -->
     
  
        </div>
        <div class="mt-5"><a href="#" class="back-link text-link">
          <   back</a>
      </div>
      </div>
      </div>
  </ng-template>

  <div>
    <button
      class="btn btn-primary"
      (click)="openDeleteConfirmationModalFour(content4)"
    >
      hello4
    </button>
  </div>
  <ng-template #content4 let-modal>
    <div class="page-wrapper-modal">
      <div class="page-header">
        <h2 class="mb-0">Needs Revisions</h2>
      </div>
      <div class="title-company-project">
        <div class="company">
          <span class="company-heading">Company:</span>
          <span class="company-content">A Brand New Production Company</span>
        </div>
        <div class="project">
          <span class="project-heading">Project:</span>
          <span class="project-content">The New Show - Pilot</span>
        </div>

      </div>
      <div>        <div class="mb-2 red-color">
        Your account is associated with multiple accounts.
      </div> <div class="mb-3 ">
        Please select which department view you want to use to open the <br> credit application and review the risk:
      </div>
      <div class="w-100">
        <div class="col-md-6  custom-dropdown custom-floating-label px-0">
          <label for="prodComState" class="form-label">Projects</label>
        
            <select class="custom-select" >
                <option class="dropdown-option" value="" disabled selected hidden>Select Project</option>
                <option  value="">
                 Project name
                </option>
            </select>
        </div>
        </div>
      </div>
      <div class="d-flex align-self-end gap-2">
        <button class="common-btn-light btn">Cancel</button>
        <button class="common-btn-secondary btn">Select</button>
      </div>
    </div>
  
  </ng-template>