<div class="modal-body justify-content-center align-items-center">
  <div class="cm-header-section">
    <h5 class="modal-title" id="commonModalLabel">{{riskEmailTier1Constants.title}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"><span
        class="cm-close-text">Close</span></button>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
        <div class="company-meta-data">
          <p class="title mb-0">
            <strong class="common-title">{{riskEmailTier1Constants.project}} </strong><span class="common-content">The
              new show</span>
          </p>
          <p class="title mb-0 pb-2">
            <strong class="common-title">{{riskEmailTier1Constants.company}}</strong>
          </p>
          <p class="common-content pb-2 pt-0 mb-0">
            A Brand New Production Company
          </p>
          <p class="title mb-0">
            <strong class="common-title">{{riskEmailTier1Constants.type}} </strong><span class="common-content">Major
              Studio</span>
          </p>
          <p class="title mb-0">
            <strong class="common-title">{{riskEmailTier1Constants.appdate}} &nbsp;</strong><span
              class="common-content">1/1/24</span>
          </p>
          <div class="d-flex flex-column mt-2">
            <span class=" mb-1"><a routerLink="feature/studio-dashboard/cred-app" target="_blank"><img
                  src="../assets/images/link-icon.svg" alt="" class="me-2 "><span class="text-link">View Credit
                  Application</span></a></span>
            <span class="mb-1"><img src="../assets/images/pdf-blue-icon.svg" alt="" class="me-2"><span
                class="text-link">Download Packet </span></span>
          </div>
        </div>

      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
        <div class="container cm-main-container px-0 mx-0">

          <div class="common-modal-input-section grey-bg">
            <h2 class="section-main-header">{{riskEmailTier1Constants.acceptriskbefore}} <span class="text-red"> /1/24
                3:25pm</span></h2>
            <div class="mt-4">
              <p>{{riskEmailTier1Constants.hiall}}</p>

              <p class="title-content">{{riskEmailTier1Constants.content1}}<strong>Brand New Prod Company.</strong></p>

              <p class="title-content">{{riskEmailTier1Constants.content2}}<a class="text-link" href="#"
                  (click)="openHelpSlider(); $event.preventDefault();"><strong>{{riskEmailTier1Constants.list}}</strong>
                </a></p>
              <app-help-slide [helpSliderVisible]="helpSliderVisible" (close)="closeHelpSlider()">
              </app-help-slide>
              <p class="title-content">{{riskEmailTier1Constants.content3}}</p>

              <p class="title-content"><strong>{{riskEmailTier1Constants.content4}}</strong>
                {{riskEmailTier1Constants.content5}}</p>
            </div>

          </div>

          <div class="common-modal-input-section grey-bg">
            <h2 class="section-main-header">{{riskEmailTier1Constants.departmentsselected}}</h2>
            <div class="mt-4">
              <p class="mb-0 title-content">- Studio Operations</p>
              <p class="mb-0 title-content">- PPCS</p>
              <p class="mb-0 title-content">- Transportation</p>
              <p class="mb-0 title-content">- Design Studio</p>
              <p class="mb-0 title-content">- Property</p>

            </div>

          </div>

          <div class="common-modal-input-section grey-bg">
            <h2 class="section-main-header">{{riskEmailTier1Constants.creditlinerequested}}</h2>
            <div class="mt-4">
              <p class="title-content">$1,000,000.00</p>

            </div>
          </div>

          <div class="common-modal-input-section grey-bg">
            <h2 class="section-main-header">{{riskEmailTier1Constants.summaryevaluation}}</h2>
            <div class="mt-4">
              <h4 class="summary-sub-heading">{{riskEmailTier1Constants.bankreference}}</h4>
              <p class="ps-4 title-content">N/A</p>

              <h4 class="summary-sub-heading">{{riskEmailTier1Constants.tradereferences}}</h4>
              <p class="ps-4 title-content">Two Trades responded; Highest amount billed is $26,000.00</p>
              <h4 class="summary-sub-heading">{{riskEmailTier1Constants.db}} </h4>.
              <p class="ps-4 mb-0 title-content">{{riskEmailTier1Constants.deliquencyscore}} <strong>88</strong></p>
              <p class="ps-4 mb-0 title-content">{{riskEmailTier1Constants.failurescore}} <strong>67</strong></p>
              <p class="ps-4 mb-0 title-content">{{riskEmailTier1Constants.paydexscore}} <strong>22</strong></p>
            </div>
          </div>


          <div class="grey-box">
            <div class="container">
              <div class="text-end">

                <div class="btn-common-dropdown w-100">
                  <div ngbDropdown class="d-inline-block">

                    <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                      <img *ngIf="selectedAction.img" [src]="selectedAction.img" alt="" style="margin-right: 5px;">
                      {{ selectedAction.text }}
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button *ngFor="let action of actions" ngbDropdownItem (click)="updateButtonText(action)">
                        <img [src]="action.img" alt=""> {{ action.text }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>