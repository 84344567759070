<div>
    <div class="page-wrapper-modal " >
      <div class="page-header">
        <h2 class="mb-0">{{constants.title}}</h2>
      </div>
      <div class="refrences">
        <div class="bank-reference">
          <h3 class="bank mb-0">{{constants.bankreferance}}</h3>
          <p class="content mb-0">N/A</p>
        </div>
        <div class="trade-reference">
          <h3 class="trade mb-0">{{constants. tradereferance}}</h3>
          <p class="content mb-0">
            Two Trades responded; Highest amount billed is $26,000.00
          </p>
        </div>
        <div class="db">
          <h3 class="mb-0">{{constants.dandb}}</h3>
          <div class="content">
            <div class="all-contents">
              <span>-</span><span>{{constants.deliquencyscore}}</span
              ><span class="content-number">88</span>
            </div>
            <div class="all-contents">
              <span>-</span><span>{{constants.failurescore}}</span
              ><span class="content-number">67</span>
            </div>
            <div class="all-contents">
              <span>-</span><span>{{constants.paydexscore}}</span
              ><span class="content-number">73</span>
            </div>
          </div>
        </div>
      </div>
      <div class="information-content">
        <p class="content mb-0">
          <span class="block-span">{{constants.emailbody1}}</span>
          <span class="block-span">{{constants.emailbody2}}</span>
          <span class="block-span">{{constants.emailbody3}}</span>
        </p>
      </div>
      <div class="button">
          <button class="common-btn-secondary btn" (click)="onClose()">Close</button>
      </div>
    </div>
</div>
  