<div class="modal-content">
  <div class="modal-body justify-content-center align-items-center">
    <div class="cm-header-section">
      <h5 class="modal-title" id="commonModalLabel">{{ modalTitle }}</h5>
      <button type="button" class="btn-close" (click)="OpenWarningModal()">
        <span class="cm-close-text">{{ addProjectUser.close }}</span>
      </button>
      <p class="cm-subtitle">{{ projectName }}</p>
    </div>
    <div class="container cm-main-container">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="common-modal-input-section" *ngIf="(portalType == 'customer' && componentType =='manage') || (portalType == 'studio' && (componentType !=='customer' && componentType !== 'studio-company')) || 
        (portalType == 'studio' && (componentType =='addProjectUser' || componentType =='updatProjectUser'))">
          <div class="row">
            <div class="col-md-12 custom-floating-label" *ngIf="this.portalType == 'studio' && componentType =='updatProjectUser'">
              {{addProjectUser.userId}} : <b>{{projectUserId}}</b>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="input1" class="form-label">{{ addProjectUser.firstname }}</label>
              <input type="text" class="form-control" placeholder="First Name" id="input1"
                     formControlName="firstName" />
              <small class="text-danger"
                     *ngIf="form.get('firstName').invalid && (form.get('firstName').touched || form.get('firstName').dirty)">
                <div *ngIf="form.get('firstName').errors['required']">{{ addProjectUser.fieldRequired }}</div>
                <div *ngIf="form.get('firstName').errors['invalidAlphabets']">{{ addProjectUser.firstNameInvalid }}
                </div>
              </small>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="input2" class="form-label">{{ addProjectUser.lastname }}</label>
              <input type="text" class="form-control" placeholder="Last Name" id="input2" formControlName="lastName" />
              <small class="text-danger"
                     *ngIf="form.get('lastName').invalid && (form.get('lastName').touched || form.get('lastName').dirty)">
                <div *ngIf="form.get('lastName').errors['required']">{{ addProjectUser.fieldRequired }}</div>
                <div *ngIf="form.get('lastName').errors['invalidAlphabets']">{{ addProjectUser.lastNameInvalid }}</div>
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 custom-floating-label">
              <label for="input3" class="form-label">{{ addProjectUser.email }}</label>
              <input required type="email" class="form-control" placeholder="Email" id="input3"
                     formControlName="email" [disabled]="modalType === 'edit'" />
              <small class="text-danger"
                     *ngIf="form.get('email').invalid && (form.get('email').touched || form.get('email').dirty)">
                <div *ngIf="form.get('email').errors['required']">{{ addProjectUser.fieldRequired }}</div>
                <div *ngIf="form.get('email').errors['invalidEmail']">{{ addProjectUser.emailInvalid }}</div>
              </small>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="input4" class="form-label">{{ addProjectUser.jobtitle }}</label>
              <input type="text" class="form-control" placeholder="Job Title" id="input4" formControlName="jobTitle" />
              <small class="text-danger"
                     *ngIf="form.get('jobTitle').invalid && (form.get('jobTitle').touched || form.get('jobTitle').dirty)">
                <div *ngIf="form.get('jobTitle').errors['required']">{{ addProjectUser.fieldRequired }}</div>
                <div *ngIf="form.get('jobTitle').errors['invalidJobTitle']">{{ addProjectUser.jobTitleInvalid }}</div>
              </small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 custom-floating-label mb-0">
              <label for="input4" class="form-label">Phone No (optional)</label>
              <input type="text" class="form-control" placeholder="Phone No" id="input5" maxlength="20"
                     formControlName="contactNo" (keydown)="restrictNonNumericInput($event)"/>
            </div>
          </div>
          <div class="row">
            <h2 class="form-section-header custom-margin">{{ addProjectUser.optional }}</h2>
            <div class="col-md-6" *ngIf="permissionsData['makeCompanyAdmin']">
              <label class="custom-checkbox">
                <input type="checkbox" formControlName="makeUser" (change)="onMakeUserCheckboxChange($event)"/>
                <span class="checkmark"></span>
                {{ addProjectUser.makeuser }}
              </label>
            </div>
            <div class="col-md-6">
              <label class="custom-checkbox">
                <input type="checkbox" formControlName="accountPayable" />
                <span class="checkmark"></span>
                {{ addProjectUser.accountpayable }}
              </label>
            </div>
          </div>
        </div>

        <div class="common-modal-input-section">
          <h3 class="form-section-header mt-0">{{ addProjectUser.department }}</h3>
          <div class="row">
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 mb-3" [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-12': first}" *ngFor="let dept of departmentData; let first = first">
              <div class="common-checkbox-button">
                <input type="checkbox" [id]="'custom-checkbox-' + dept.departmentId" class="common-checkbox-input"
                       formControlName="department{{dept.departmentId}}" [value]="dept.departmentId" (change)="onDepartmentCheckboxChange($event,dept)" />
                <label [for]="'custom-checkbox-' + dept.departmentId" class="common-checkbox-label">
                  {{ dept.departmentName }}
                </label>
              </div>
            </div>
          </div>
        </div>

      <div class="common-modal-input-section">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="form-section-header mt-0">{{ addProjectUser.section }}</h3>
          <h3 class="form-section-header mt-0">{{ addProjectUser.access }}</h3>
        </div>
        <div class="main-acc-wrapper mb-3" *ngFor="let section of filteredSectionData">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="acc-sec-header">{{ section.roleSection }}</h3>
            <div class="common-toggle-button">
              <input type="checkbox" [id]="'custom-toggle-' + section.userRoleId" class="common-toggle-input"
                     formControlName="section{{ section.userRoleId }}"  (change)="onPermissionsChange($event,section)" />
              <label [for]="'custom-toggle-' + section.userRoleId" class="common-toggle-label"></label>
            </div>
          </div>

          <!-- Conditionally show the dropdown for sections with permissions -->
          <div class="col-12 col-sm-8 col-md-6 col-lg-4" *ngIf="section.roleSection == 'Project'">
            <div ngbDropdown class="custom-user-permission-dropdown">
              <button type="button" class="btn btn-outline-primary custom-dropdown-btn"
                      id="dropdownForm{{ section.userRoleId }}" ngbDropdownToggle  [disabled]="isUserCompanyAdmin">
                <p *ngIf="form.get('permission').value == 'Can View'">
                  <img src="../../../../assets/images/eye.svg" class="eye" alt="eye icon">
                  {{ form.get('permission').value || 'Select Permission' }}
                </p>
                <p *ngIf="form.get('permission').value == 'Can Manage'">
                  <img src="../../../../assets/images/edit1-icon.svg" class="eye" alt="eye icon">
                  {{ form.get('permission').value || 'Select Permission' }}
                </p>
              </button>
              <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownForm' + section.userRoleId" class="custom-dropdown-menu">
                <div *ngFor="let project of filteredProjectSectionData">
                  <span class="px-4 py-3 custom-dropdown-form" ngbDropdownItem
                        (click)="selectPermission(project.userRoleId, project.roleDisplay,project)">
                    <p class="dropdown-title">
                        <img  src="assets/images/tick-icon.svg" *ngIf="form.get('permission').value == 'Can View' && project.roleDisplay == 'Can View'" class="tick" alt="tick icon">
                        <img src="assets/images/tick-icon.svg"  *ngIf="form.get('permission').value == 'Can Manage' && project.roleDisplay == 'Can Manage'" class="tick" alt="tick icon">
                       {{project.roleDisplay}}
                       <img src="../../../../assets/images/eye.svg" *ngIf="project.roleDisplay == 'Can View'" class="eye" alt="eye icon">
                       <img src="../../../../assets/images/edit1-icon.svg" *ngIf="project.roleDisplay == 'Can Manage'" class="eye" alt="eye icon">
                    </p>
                    <p class="dropdown-description">
                      {{project.roleDisplayDesc}}
                    </p>
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
          <div class="cm-btn-section d-flex"  
          [ngClass]="portalType === 'studio' && componentType === 'updatProjectUser' ? 'justify-content-between' : 'justify-content-end'" 
          *ngIf="(portalType == 'studio' && (componentType !=='customer' && componentType !== 'studio-company')) || (portalType == 'studio' && (componentType =='addProjectUser' || componentType =='updatProjectUser') || (portalType == 'customer' && componentType =='manage' && (modalType == 'add' || modalType == 'edit' )))">
            <div *ngIf="portalType === 'studio' && componentType === 'updatProjectUser'">
              <a class="text-link" (click)="openConfirmationModal('remove')" *ngIf="fetchProjectUserDetails?.companyAdminInd == 0 && permissionsData['removeCustomerUser']"><img class="back-img" src="../../assets/images/remove-user-blue.svg" alt="Remove From Project" /> Remove From Project </a>
              <a class="text-link" (click)="openConfirmationModal('deactivate')" *ngIf="fetchProjectUserDetails?.statusActiveInd == 'Y' && permissionsData['deactivateUser']"><img class="back-img" src="../../assets/images/user-disable-blue.svg" alt="Disable User"  /> Deactivate User </a>
              <a class="text-link" (click)="openConfirmationModal('activate')" *ngIf="fetchProjectUserDetails?.statusActiveInd == 'N' && permissionsData['deactivateUser']"><img class="back-img" src="../../assets/images/enable-blue.svg" alt="Enable User"  /> Reactivate User </a>
            </div>
            <button type="submit" class="btn common-btn-secondary" [disabled]="(portalType == 'studio' && (componentType =='addProjectUser' || componentType =='updatProjectUser'))  || (portalType == 'customer' && componentType =='manage' && (modalType == 'add' || modalType == 'edit' )) ? (!isFormValid || isInitialState()): form.invalid">Save</button>
          </div>

          <div class="cm-btn-section d-flex justify-content-between" *ngIf="(portalType == 'studio' && componentType =='customer') || (portalType == 'studio' && (componentType !=='addProjectUser' && componentType !=='updatProjectUser') || ((portalType == 'customer' && componentType =='company')))">
            <a class="text-link" (click)="closeModal()"><img class="back-img" src="../../assets/images/blue-left-icon.svg" alt="Back" /> Back </a>
            <button type="submit" class="btn common-btn-secondary" [disabled]="form.invalid">Apply Changes</button>
          </div>

      </form>
    </div>
  </div>
</div>

<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{ iconModalTitle }}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="handleAction()">
        {{ iconsRenderer.yes }}
      </button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        {{ iconsRenderer.no }}
      </button>
    </div>
  </div>
 </ng-template>

<ng-template #WarningMsgModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{ iconsRenderer.closeProjectCreationPage }}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="closeModal();modal.close()">
        {{ iconsRenderer.yes }}
      </button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        {{ iconsRenderer.no }}
      </button>
    </div>
  </div>
 </ng-template>

<ng-template #addUserSuccessMessage>
	<div class="success">{{addProjectUser.addUserSuccessMsg}}</div>
</ng-template>

<ng-template #addUserErrorMessage>
	<div class="danger">{{userAddErrorMessage}}</div>
</ng-template>

<ng-template #EditUserSuccessMessage>
	<div class="success">{{addProjectUser.updateUserSuccess}}</div>
</ng-template>

<ng-template #EditUserErrorMessage>
	<div class="danger">{{ addProjectUser.updateErrorMessage }}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>

