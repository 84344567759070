<div class="all-projects-wrapper">
    <div class="container">
        <div class="container px-0">
            <div class="row pb-0 pt-0 px-0 mx-0 all-project-title-block">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 mx-0"
                    *ngIf="permissionsData['viewProject']">
                    <div class="all-project-link-section" (click)="returnToProjects()">
                        <img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
                        <p class="all-project-link-name mb-0">{{ projectDetailsConstants.allProjects }}</p>
                    </div>
                    <div class="all-project-title-section">
                        <p class="all-project-title-name">{{ projectDetails?.projectName }}
                        </p>
                    </div>
                </div>
                <div class="project-resource-wrapper wrapper-left col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <div class="all-project-status-widget-block">
                        <div class="card px-0">

                            <div class="row" *ngIf="permissionsData['viewProject']">
                                <div class="col-12 px-0">
                                    <span class="project-status-tag"
                                        [ngClass]="{'active-tag-green':projectStatus?.projectStatus === 'Active', 'closed-tag-black': projectStatus?.projectStatus === 'Closed' , 'not-started-purple': projectStatus?.projectStatus === 'Not Started'}">{{
                                        projectStatus?.projectStatus }}</span>
                                </div>
                                <div class="col-12 col-sm-3 col-md-4 col-lg-12 project-info-section">
                                    <p class="project-info">{{ projectDetailsConstants.projectId }}<span
                                            class="project-value">{{
                                            projectDetails?.projectId }}</span></p>
                                </div>

                                <div class="col-12  col-sm-5 col-md-5 col-lg-12  project-info-section">
                                    <p class="project-info mb-0">{{ projectDetailsConstants.dates }}<span
                                            class="project-value">{{
                                            formatDate(projectDetails?.projectStartDate) }} - {{
                                            formatDate(projectDetails?.projectEndDate) }}
                                        </span>
                                    </p>
                                </div>

                                <div class="col-12 col-sm-4 col-md-3 col-lg-12 project-info-section ">
                                    <p class="project-info mb-0">{{ projectDetailsConstants.ar }}<span
                                            class="project-value">{{
                                            getArNumbers() }}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card all-project-season-block">
                        <div class="container px-0 mx-0">
                            <div class="row mx-0">
                                <div
                                    class="all-project-season-image-section col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                    <div class="image-wrapper">
                                        <img [src]="projectDetails?.imageUrl" class="all-project-season-image img-fluid"
                                            alt="project-image"
                                            onerror="this.src='../../../../../../assets/images/project-image1.png'" />
                                        <ng-container *ngIf="permissionsData['editProject']">
                                            <app-image-dropdown class="image-dropdown"
                                                (uploadPhoto)="triggerFileUpload(fileInput)"
                                                (resetPhoto)="handleResetPhoto()"></app-image-dropdown>
                                        </ng-container>
                                        <!-- Hidden file input -->
                                        <input type="file" #fileInput accept="image/*" style="display: none;"
                                            (change)="onImageSelected($event)" />
                                    </div>


                                </div>
                                <div class="card-body col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12">
                                    <div class="all-project-season-desc-section">
                                        <h6 class="all-project-season-subtitle">{{ projectDetailsConstants.description
                                            }} :
                                        </h6>
                                        <p class="all-project-season-desc ">{{ projectDetails?.projectDesc }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mb-4 edit-desc"><a class="fb-600 text-decoration-none"
                            (click)="openEditDescriptionModal(editDescriptionModal)"
                            *ngIf="permissionsData['editProject']"> <img src="../../assets/images/edit-icon.svg"
                                class="" alt="edit description"><span class="text-link">{{
                                projectDetailsConstants.editDescription }}</span></a>
                    </div>
                </div>
                <div class="project-resource-wrapper wrapper-right col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 ">
                    <div class="comman-tabs-block" *ngIf="permissionsData['mySpace']">
                        <ul class="nav" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" [class.active]="selectedTab === 'spaces'" aria-current="page"
                                    data-bs-toggle="tab" href="#space-resource-tabpanel-0" role="tab"
                                    aria-selected="true" (click)="selectTab('spaces')">{{
                                    spacesAndResources.spaces }}</a>
                            </li>
                            <li class="nav-item"  *ngIf="permissionsData['viewCreditDetails']">
                                <a class="nav-link" [class.active]="selectedTab === 'credit'" data-bs-toggle="tab"
                                    href="#credit-tabpanel-1" role="tab" aria-controls="fill-tabpanel-1"
                                    (click)="selectTab('credit')">{{
                                    creditApplicationConstants.creditApplications }} <span
                                        *ngIf="creditApplicationData?.length">({{ creditApplicationData?.length
                                        }})</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [class.active]="selectedTab === 'users'" data-bs-toggle="tab"
                                    href="#project-users-tabpanel-2" role="tab" aria-controls="fill-tabpanel-1"
                                    (click)="selectTab('users')">{{ projectUsersConstants.projectUsers }}</a>
                            </li>
                        </ul>
                    </div>
                    <!--space resource tab-->
                    <div class="tab-content" id="tab-content">
                        <div class="tab-pane" [class.active]="selectedTab === 'spaces'" id="space-resource-tabpanel-0"
                            role="tabpanel" aria-labelledby="fill-tab-0" *ngIf="permissionsData['mySpace']">
                            <div class="space-project-tab">
                                <div class="container px-0 mx-0">
                                    <div class="all-projects-spaces-resources-title">
                                        <h2 class="mb-0">{{ spacesAndResources.mySpaces }}</h2>
                                    </div>
                                    <!-- STAGES -->
                                    <div class="row  projects-spaces-resources-block">
                                        <div class="col-12  projects-spaces-resources-subtitle ">{{
                                            spacesAndResources.stages }}</div>
                                        <!-- Loop over each stage -->
                                        <div *ngFor="let stage of stagesData"
                                            class="row projects-spaces-resources-block p-0">
                                            <div
                                                class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                                <div>
                                                    <p class="pb-0 mb-0">{{ stage.stageName }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                                <!-- Loop over each group of documents -->
                                                <div class="col-12">
                                                    <div *ngFor="let group of stage.documentGroups"
                                                        class="col-6 leftalign">
                                                        <!-- Loop over each document -->
                                                        <div class="d-flex flex-revert">
                                                            <div
                                                                class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                                <div *ngFor="let document of group.documents"
                                                                    class="bookmark-image-wrapper">
                                                                    <i class="bi" [ngClass]="{
                                                                        'bi-bookmark-fill': isHovered(document.id) || isBookmarked(document.id), 
                                                                        'bi-bookmark': !isHovered(document.id) && !isBookmarked(document.id)
                                                                    }" (click)="toggleBookmark(document.id)"
                                                                        (mouseenter)="onMouseEnter(document.id)"
                                                                        (mouseleave)="onMouseLeave(document.id)">
                                                                    </i>
                                                                    {{ document.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <!-- Offices section-->
                                    <div class="row projects-spaces-resources-block">
                                        <div class="col-12 projects-spaces-resources-subtitle">{{
                                            spacesAndResources.offices
                                            }}</div>
                                        <div *ngFor="let office of officesData"
                                            class="row projects-spaces-resources-block p-0">
                                            <div
                                                class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                                <div>
                                                    <p class="pb-0 mb-0">{{ office.stageName }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                                <!-- Loop over each group of documents -->
                                                <div class="col-12">
                                                    <div *ngFor="let group of office.documentGroups"
                                                        class="col-6 leftalign">
                                                        <!-- Loop over each document -->
                                                        <div class="d-flex flex-revert">
                                                            <div
                                                                class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                                <div *ngFor="let document of group.documents"
                                                                    class="bookmark-image-wrapper">
                                                                    <i class="bi" [ngClass]="{
                                                                        'bi-bookmark-fill': isHovered(document.id) || isBookmarked(document.id), 
                                                                        'bi-bookmark': !isHovered(document.id) && !isBookmarked(document.id)
                                                                    }" (click)="toggleBookmark(document.id)"
                                                                        (mouseenter)="onMouseEnter(document.id)"
                                                                        (mouseleave)="onMouseLeave(document.id)">
                                                                    </i>
                                                                    {{ document.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <!-- Workshops -->
                                    <div class="row projects-spaces-resources-block">
                                        <div class="col-12  projects-spaces-resources-subtitle ">{{
                                            spacesAndResources.workshops }}</div>
                                    </div>
                                    <div *ngFor="let workshop of workshopsData"
                                        class="row projects-spaces-resources-block py-0">
                                        <div
                                            class="col-lg-2 col-md-2 col-sm-4 col-4 project-spaces-resource-stage-name">
                                            <div>
                                                <p class="pb-0 mb-0">{{ workshop.stageName }}</p>
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-10 col-md-10 col-sm-8 col-8 project-spaces-resource-stages-block">
                                            <!-- Loop over each group of documents -->
                                            <div class="col-12">
                                                <div *ngFor="let group of workshop.documentGroups"
                                                    class="col-6 leftalign">
                                                    <!-- Loop over each document -->
                                                    <div class="d-flex flex-revert">
                                                        <div
                                                            class="d-flex justify-content-between flex-md-column flex-column flex-lg-row flex-md-row flex-sm-column flex-change">
                                                            <div *ngFor="let document of group.documents"
                                                                class="bookmark-image-wrapper">
                                                                <i class="bi" [ngClass]="{
                                                                    'bi-bookmark-fill': isHovered(document.id) || isBookmarked(document.id) || document?.bookmarkResources?.bookmarkedInd, 
                                                                    'bi-bookmark': !isHovered(document.id) && !isBookmarked(document.id)
                                                                }" (click)="toggleBookmark(document.id)"
                                                                    (mouseenter)="onMouseEnter(document.id)"
                                                                    (mouseleave)="onMouseLeave(document.id)">
                                                                </i>
                                                                {{ document.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="space-project-tab">
                                <div class="container px-0 mx-0">
                                    <div class="all-projects-spaces-resources-title justify-content-start">
                                        <h2 class="mb-0">{{ spacesAndResources.maps }}</h2>
                                        <span class="text-link view-maps" (click)="navigateToMaps()">{{
                                            spacesAndResources.viewMaps}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!---credit Application tab-->
                        <div class="tab-pane credit-app-tab" [class.active]="selectedTab === 'credit'"
                            id="credit-tabpanel-1" role="tabpane-l" aria-labelledby="fill-tab-1" *ngIf="permissionsData['viewCreditProgress']">
                            <div class="container px-0 mx-0">
                                <div class="all-projects-spaces-resources-title">
                                    <h2>{{ creditApplicationConstants.creditApplications }} <span
                                            *ngIf="creditApplicationData?.length">({{ creditApplicationData?.length
                                            }})</span></h2>
                                </div>
                                <!-- Make request -->
                                <div *ngFor="let application of creditApplicationData"
                                    class="row all-projects-credit-AR-section">
                                    <div class="col-12 all-projects-credit-app-subtitle">
                                        <div class="subtitle-block w-100">
                                            <div class="ar-id">A/R # <span class="ar-id-no">{{ application.arNumber
                                                    }}</span></div>
                                            <div class="ar-status-tag"
                                                *ngIf="application.customerStatus !== 'Start Application' && application.customerStatus !== 'Finish Application'"
                                                [ngClass]="{
                                                    'under-review': application.customerStatus === 'UNDER REVIEW',
                                                    'revision': application.customerStatus === 'NEEDS REVISIONS',
                                                    'approved': application.customerStatus === 'APPROVED',
                                                    'send-to': application.customerStatus === 'SEND TO CUSTOMER',
                                                    'cod' : application.customerStatus === 'CASH ON DEMAND',
                                                    'new-application w-auto': application.customerStatus === 'REVISIONS SUBMITTED' || application.customerStatus === 'Application Submitted'
                                            }">{{ application.customerStatus }}
                                            </div>
                                            <ng-container *ngIf="application.customerStatus === 'Start Application'">
                                                <div class="start-credit-app-btn">
                                                    <button class="common-btn-primary btn"
                                                        (click)="openAcknowledgemodal(acknowledgemodal, application.caSeqId, application.customerStatus, application.caNumber)">
                                                        <img class="common-btn-primary-icon"
                                                            src="../../assets/images/add.svg" alt="add" />
                                                        <span class="common-btn-primary-text-sm">Start
                                                            Application</span>
                                                    </button>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="application.customerStatus === 'Finish Application'">
                                                <div class="start-credit-app-btn">
                                                    <button class="common-btn-primary btn"
                                                        (click)="openFinishApplicationModal(application)">
                                                        <img class="common-btn-primary-icon"
                                                            src="../../assets/images/like.svg" alt="add" />
                                                        <span class="common-btn-primary-text-sm">Finish
                                                            Application</span>
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="project-credit-app-credit-block"
                                        *ngIf="application.customerStatus !== 'Start Application' && application.customerStatus !== 'Finish Application'">
                                        <ng-container *ngIf="application.lineOfCredit?.locRequested">
                                            <button class="custom-credit-limit-btn common-primary-btn btn btn-block">
                                                Credit Limit: <span class="credit-limit-amt">{{
                                                    application.lineOfCredit?.locRequested | currency }}</span>
                                            </button>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="application.customerStatus !== 'Start Application' && application.customerStatus !== 'Finish Application'">
                                            <a class="text-link fb-600"
                                                (click)="openRevisionsModal(application.caSeqId,application.caNumber,application.customerStatus)">View
                                                Application</a>
                                        </ng-container>
                                    </div>
                                    <div class="project-credit-app-costume-block">
                                        <div>
                                            <ul>
                                                <!-- Display "All departments" if the current application has any department name containing "All" -->
                                                <li *ngIf="hasAllDepartments(application)" class="project-credit-app-dept">
                                                   {{ creditApplicationConstants.allDepartments }}
                                                </li>
                                                
                                                <!-- Display the list of department names if "All" is not present -->
                                                <ng-container *ngIf="!hasAllDepartments(application)">
                                                    <li *ngFor="let dept of application.caDepartments" class="project-credit-app-dept">
                                                        {{ dept.departments.departmentName }}
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr class="m-0" />
                                </div>

                                <div class="all-projects-credit-AR-section">
                                    <div class="add-credit-add">
                                        <span class="text-link" (click)="openStartCreditApplicationModal()">{{
                                            projectDetailsConstants.addCreditApplication }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!---Project user tab-->
                        <div class="tab-pane project-user-tab" [class.active]="selectedTab === 'users'"
                            id="project-users-tabpanel-2" role="tabpane-2" aria-labelledby="fill-tab-1">
                            <div class="container px-0 mx-0">
                                <div class="all-projects-spaces-resources-title ">
                                    <h2>{{ projectUsersConstants.projectUsers }}</h2>
                                    <div>
                                        <a class="text-link fb-600"
                                            routerLink="/feature/customer-dashboard/manage-users"
                                            (click)="navigateToManageUsers()"
                                            *ngIf="permissionsData['viewProjectUser']">{{
                                            projectUsersConstants.viewAll }}</a>
                                        <button class="common-btn-primary btn" (click)="openAddUserModal()"
                                            *ngIf="permissionsData['addCustomerUser']">
                                            <img class="common-btn-primary-icon" src="../../assets/images/add.svg"
                                                alt="add" />
                                            <span class="common-btn-primary-text-sm">{{ projectUsersConstants.addUser
                                                }}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="row mx-0 px-0">
                                    <div class="progress_container d-flex justify-content-center">
                                        <!-- circular progress bar -->
                                        <div class="pass-status-widgets-container d-flex">
                                            <ng-container *ngFor="let user of projectUsersCount; let i = index">
                                                <!-- Each widget -->
                                                <div class="pass-status-widgets" [ngClass]="{
                                                  'widget-blue': user.label === userListConstants.all,
                                                  'widget-green': user.label === userListConstants.active,
                                                  'widget-grey': user.label === userListConstants.deactivated,
                                                  'widget-darkgrey': user.label === userListConstants.admin
                                                }" (click)="openManageUsers(user)">
                                                    <div class="pass-status-widgets-values">{{ user.usersCount }}</div>
                                                    <div class="pass-status-widgets-labels">{{ user.label }}</div>
                                                </div>

                                                <!-- Conditionally render the arrow only if not the last widget -->
                                                <div *ngIf="i < projectUsersCount?.length - 1"
                                                    class="progress_container_arrow">
                                                    <img alt="keyboard-right-arrow"
                                                        src="../../../../assets/images/keyboard-arrow-right.svg" />
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="project-user-section">
                                        <div class="project-users-title">{{ projectUsersConstants.users }}:</div>
                                        <div class="project-users-list">
                                            <span *ngFor="let user of getSortedUsersList(); let i = index">
                                                {{ user }}<span *ngIf="i < projectUsersData?.length - 1">;</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #editDescriptionModal let-modal>
    <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
            <h5 class="modal-title mb-3" id="commonModalLabel">{{ editConstants.editProject }}</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span class="cm-close-text">{{ editConstants.close }}</span>
            </button>
            <p class="cm-subtitle mb-3">{{ projectName }}</p>
        </div>

        <div class="container cm-main-container">
            <form [formGroup]="editForm">
                <div class="common-modal-input-section">
                    <h2 class="section-main-header mb-3">{{ editConstants.description }}</h2>
                    <div class="mt-56 custom-floating-label">
                        <div class="section-content">
                            <div class="custom-floating-label">
                                <textarea formControlName="description" class="form-control common-text-area"
                                    placeholder="Enter description" id="desc1" rows="5">
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="cm-btn-section">
                    <button type="button" class="discard-btn common-btn-light btn me-3" (click)="discardChanges()"
                        [disabled]="disableButtons">
                        {{ editConstants.discard }}
                    </button>
                    <button type="button" class="common-btn-secondary btn" (click)="updateDescription()"
                        [disabled]="disableButtons">
                        {{ editConstants.save }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #acknowledgemodal let-modal>
    <div class="modal-body p-0">
        <div class="page-wrapper-modal">
            <div class="page-header mb-4">
                <h2>{{ acknowledgeConstants.acknowledgeLabel }}</h2>
            </div>
            <div>
                <label class="mb-3">
                    <ul>
                        <li> {{ acknowledgeConstants.acknowledgeConditionOne }} </li>
                        <li> {{ acknowledgeConstants.acknowledgeConditionTwo }} </li>
                    </ul>
                </label>
            </div>

            <div class="button">
                <button type="button" class="common-btn-light btn" (click)="modal.close()">{{
                    acknowledgeConstants.cancelButton }}</button>
                <button type="button" class="common-btn-secondary btn" (click)="acknowledgeConditions(modal)">{{
                    acknowledgeConstants.acknowledgeConditionsBtn }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{ projectDetailsErrorMessage }}</div>
</ng-template>

<ng-template #successTpl>
    <div class="success">{{ editDescriptionMessage }}</div>
</ng-template>

<ng-template #editDescriptionError>
    <div class="danger">{{ projectDetailsConstants.editDescriptionErrorMessage }}</div>
</ng-template>

<ng-template #bookmarkSuccess>
    <div class="success">{{ bookmarkSuccessMessage }}</div>
</ng-template>

<ng-template #bookmarkError>
    <div class="success">{{ projectDetailsConstants.bookmarkErrorMessage }}</div>
</ng-template>

<ng-template #dangerTpl1>
    <div class="danger">{{ uploadError }}</div>
</ng-template>

<ng-template #successTpl1>
    <div class="success">{{ uploadSuccess }}</div>
</ng-template>

<ng-template #dangerTplreset>
    <div class="danger">{{ resetError }}</div>
</ng-template>


<app-toast aria-live="polite" aria-atomic="true"></app-toast>