<div class="modal-body justify-content-center align-items-center">
    <div class="cm-header-section">
        <h5 class="modal-title" id="commonModalLabel">{{title}}</h5>
        <button type="button" class="btn-close" (click)="closeModal()">
            <span class="cm-close-text">{{close}}</span>
        </button>
        <p class="cm-subtitle mb-5" *ngIf="isEditMode">{{ userDetails?.company }}</p>
    </div>
    <div class="container cm-main-container">
        <div class="common-modal-input-section" *ngIf="!searchComplete || showSearchBar">
            <app-search [placeholder]="placeholder" [usersList]="usersList" [userType]="userType"
                [disabled]="searchDisabled" (searchComplete)="onSearchCompleted($event)"></app-search>

            <div class="d-flex justify-content-between" *ngFor="let user of selectedUser">
                <div class="user-details">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span class="username">{{ user.firstName }} {{user.lastName}}</span>
                        </li>
                        <li class="list-group-item">
                            <span class="user-designation">{{ user.jobTitle}} </span>
                        </li>
                        <li class="list-group-item">
                            <span class="user-email"> {{ user.emailAddress}} </span>
                        </li>
                    </ul>
                </div>

                <span *ngIf="!isEditMode" (click)="resetSearch()" class="reset-link">{{reset}}</span>
            </div>
        </div>

        <div class="common-modal-input-section" [ngClass]="{'disabled': !searchComplete}">
            <form [formGroup]="rolesForm">
                <h3 class="form-section-header">User Role <img src="../../../../../../assets/images/question-mark.svg"
                        alt="" (click)="toggleHelpSlider()"></h3>
                <div class="row">
                    <div class="col-12 col-md-4 col-sm-6 mb-sm-3 mb-0 radio-block" *ngFor="let role of showroles">
                        <div class="common-radio-btn">
                            <input class="common-radio-input mb-0" type="radio" [id]="role.userRoleId" [value]="role"
                                formControlName="userRole" [disabled]="rolesForm.disabled">
                            <label class="common-radio-label" [for]="role">{{role.role}}</label>
                        </div>
                    </div>
                </div>
            </form>
            <app-help-slideout [helpSliderVisible]="helpSliderVisible"></app-help-slideout>

        </div>

        <!-- Department Access Section (outside of rolesForm) -->
        <div *ngIf="showDepartmentDetails" class="common-modal-input-section">
            <h3 class="form-section-header mt-0">DEPARTMENTS ACCESS</h3>
            <form [formGroup]="departmentForm">
                <div class="row">
                    <div class="col-md-4 col-lg-4 col-sm-6 col-6 mb-3"
                        [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-12': first}"
                        *ngFor="let dept of departmentData; let first = first">
                        <div class="common-checkbox-button">
                            <input (click)="addDept(dept.departmentId,$event)" type="checkbox"
                                [id]="'dept-' + dept.departmentId" class="common-checkbox-input"
                                [formControlName]="dept.departmentId.toString()" [disabled]="departmentForm.disabled">
                            <label [for]="'dept-' + dept.departmentId" class="common-checkbox-label">
                                {{dept.departmentName}}
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <!-- A/R Role Details Section -->
        <div *ngIf="showArDetails" class="common-modal-input-section">
            <div class="row">
                <div class="d-flex justify-content-between">
                    <h2 class="form-section-header custom-margin mb-4 mt-0">{{arTitles.permissions}}</h2>
                    <h2 class="form-section-header custom-margin mb-4 mt-0">{{arTitles.access}}</h2>
                </div>
                <form [formGroup]="arForm">
                    <div class="main-acc-wrapper mb-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3 class="acc-sec-header">{{arTitles.finalReview}}</h3>
                            <div class="common-toggle-button">
                                <input type="checkbox" id="custom-toggle-1" class="common-toggle-input"
                                    formControlName="finalReview" [disabled]="arForm.disabled">
                                <label for="custom-toggle-1" class="common-toggle-label"></label>
                            </div>
                        </div>
                        <div ngbDropdown class="custom-user-permission-dropdown">
                            <button type="button" class="btn btn-outline-primary custom-dropdown-btn" id="dropdownForm1"
                                ngbDropdownToggle>
                                <p>
                                    <img *ngIf="selectedRole === 'Level 1 Approver'"
                                        src="../../../../assets/images/prefix-user.svg" class="eye" alt="user icon">
                                    <img *ngIf="selectedRole === 'Level 2 Approver'"
                                        src="../../../../assets/images/l2-approver.svg" class="eye" alt="user icon">
                                    {{ selectedRole }}
                                </p>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="custom-dropdown-menu">
                                <form *ngFor="let role of filteredRoles" class="px-4 py-3 custom-dropdown-form"
                                    ngbDropdownItem (click)="selectRole(role)">
                                    <img *ngIf="selectedRole === role.role" src="assets/images/tick-icon.svg"
                                        class="tick" alt="tick icon">
                                    <p class="dropdown-title">
                                        {{ role.role }}
                                        <img *ngIf="role.role === 'Level 1 Approver'"
                                            src="../../../../assets/images/prefix-user.svg" class="eye" alt="user icon">
                                        <img *ngIf="role.role === 'Level 2 Approver'"
                                            src="../../../../assets/images/l2-approver.svg" class="eye" alt="user icon">
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="main-acc-wrapper mb-4">
                        <div *ngFor="let role of filteredAr" class="d-flex justify-content-between align-items-center">
                            <h3 class="acc-sec-header">{{arTitles.arNumber}}</h3>
                            <div class="common-toggle-button">
                                <input type="checkbox" id="custom-toggle-2" class="common-toggle-input"
                                    formControlName="arNumber" [disabled]="arForm.disabled">
                                <label for="custom-toggle-2" class="common-toggle-label"></label>
                            </div>
                        </div>
                        <div class="edit-section d-flex align-items-center">
                            <img src="../../../../../../assets/images/edit-icon.svg" alt="edit icon">
                            <p>{{arTitles.arNumberDescription}}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="cm-btn-section" *ngIf="searchComplete || isEditMode">
            <button type="button" class="btn common-btn-secondary"
                [disabled]="isFormReadOnly || !isAddUserButtonEnabled || isInitialRolesFormState() "
                (click)="saveUser()">{{ isEditMode ? saveText :
                addUserText }}</button>
        </div>

        <ng-template #successTpl>
            <div class="success">{{success_msg}}</div>
        </ng-template>

        <ng-template #dangerTpl>
            <div class="danger">{{error_msg}}</div>
        </ng-template>

        <app-toast aria-live="polite" aria-atomic="true"></app-toast>