import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CRED_FORM_ONE_CONSTANT, CRED_STEP_ONE_CONSTANT, CREDIT_APP_REPORT_SECTION, CREDIT_APP_TOAST_MESSAGES } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { FormValidationService } from 'src/app/shared/services/form-validations/form-validation.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { NgbActiveModal, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ViewCreditAppServiceService } from 'src/app/shared/services/view-credit-app-service/view-credit-app-service.service';
import * as _ from 'lodash';
import { INDEX } from 'src/app/feature/customer-portal/enum/customer-portal.enum';

@Component({
  selector: 'app-cred-app-step-one',
  templateUrl: './cred-app-step-one.component.html',
  styleUrls: ['./cred-app-step-one.component.scss']
})
export class CredAppFormOneComponent implements OnInit {
  @Input() customerStatus: any;
  @Input() revisionData: any;
  @Input() states: any;
  @Input() creditAppStatus: string;
  @Input() projectId: number;
  @Input() startApplicationData: any;
  @Input() caSeqId: number;
  @Input() caNumber: number;
  @Input() companyId: number;
  @Output() nextStepEvent = new EventEmitter<void>();
  @Output() stepCompleted = new EventEmitter<number>();
  @Output() saveAndCloseEvent = new EventEmitter<void>();
  @Output() formSubmittedEvent = new EventEmitter<any[]>();
  @Output() stepOneFormChange = new EventEmitter()
  @Output() formDirtyStatus = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput!: ElementRef;  // Reference to the file input element
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('successUploadFileTpl', { static: false }) successUploadFileTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  disableUpload: boolean = false;  // This will control whether upload is allowed or not
  isFileOver = false;
  files: File[] = [];
  uploadedFiles: File[] = [];
  form: FormGroup;
  public successMsg: any;
  public errorMsg: any;
  toastMessages = CREDIT_APP_TOAST_MESSAGES;
  public helpSliderVisible: boolean = false;
  credFormOne = CRED_FORM_ONE_CONSTANT
  tr1fileName: string | null = null;
  tr1ErrorMessage: string | null = null;
  tr2fileName: string | null = null;
  tr2ErrorMessage: string | null = null;
  tr3fileName: string | null = null;
  tr3ErrorMessage: string | null = null;
  tradeRefferenc1Data: any;
  tradeRefferenc2Data: any;
  tradeRefferenc3Data: any;
  credFormOneConstant = CRED_STEP_ONE_CONSTANT;
  letterOfCreditPDFDocument: any;
  userProfileId: any;
  applicationData;
  logResponseDetails;
  showBankReference: any;
  lineOfcredit: any;
  letterOfGuarantee: any;
  productionCompany: any;
  federalTax: any;
  accountPayable: any;
  companyProfile: any;
  principalsAndPartners: any;
  tradeReference1: any;
  tradeReference2: any;
  tradeReference3: any;
  isRevision: boolean = false;
  reviewRequiredSections = [];
  selectedState;
  selectedBillingState;
  selectedTrOneState;
  selectedTrTwoState;
  selectedTrThreeState;
  useBillingAddress;
  contactData;
  partnersData;
  tradeReferenceDoc: any;
  tradeRefId: any;
  locMessage: any;
  logMessage: any;
  prodComMessage: any;
  billingMessage: any;
  accountPayableMessage: any;
  bankingMessage: any;
  companyMessage: any;
  partnerMessage: any;
  tr1Message: any;
  tr2Message: any;
  tr3Message: any;
  fedTaxMessage: any;
  tradeReference1ReUpload: any;
  tradeReference2ReUpload: any;
  tradeReference3ReUpload: any;
  initialFormValues: any;
  isFormChanged: any;
  formChanged: boolean;
  disableUpload2: boolean;
  disableUpload1: boolean;
  disableUpload3: boolean;
  disableLetterOfCredit: boolean;
  disableAccountPayable: boolean;
  disableprinciple: boolean;
  isTr1FileUploaded: boolean = false;
  isTr2FileUploaded: boolean = false;
  isTr3FileUploaded: boolean = false;
  isNextButtonDisabled: boolean = false;
  localFormData;

  constructor(
    private fb: FormBuilder,
    private formValidationService: FormValidationService,
    private loaderService: LoaderService,
    private creditApplicationService: CreditApplicationsService,
    private toastService: ToastService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    private sharedViewCreditAppService: ViewCreditAppServiceService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.restoreFormData();
    this.applicationData = this.startApplicationData;
    if (this.applicationData) {
      this.contactData = this.applicationData?.creditApplicationList?.accountPayableContact;
      this.partnersData = this.applicationData?.creditApplicationList?.partners;
      this.patchInitialTraderefDocuments(this.applicationData?.creditApplicationList);
    }
    else if (this.revisionData) {
      this.contactData = this.revisionData?.caRevisionDetails?.applicationFormDetails?.accountsPayableContactsDetails;
      this.partnersData = this.revisionData?.caRevisionDetails?.applicationFormDetails?.partnersDetails;
    }
    this.setBillingAddress();
    this.initializeForm();
    // this.checkCreditAppStatus();
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userProfileId = userProfile.userProfileId;
    this.patchValueOnCustomerStatus();
    this.revisionRequiredStatus();
    if (this.customerStatus === this.credFormOneConstant.needsRevision) {
      this.isRevision = true;
      this.getRevisionRequiredMessage();
    }
    this.getProdCompanyState();
    this.getBillingState();
    this.getTrOneState();
    this.getTrTwoState();
    this.getTrThreeState();
    if (this.applicationData && this.customerStatus === 'Finish Application' || this.customerStatus === 'Start Application') {
        this.initialFormValues = this.form?.getRawValue();
      this.form.valueChanges.subscribe(() => {
        this.checkFormChanges();
      });
    }
    if(this.customerStatus === 'NEEDS REVISIONS'){
      if(this.revisionData?.caRevisionDetails?.applicationFormDetails){
      this.initialFormValues = this.form?.getRawValue();
        this.form.valueChanges.subscribe(() => {
          this.checkFormChanges();
        });
      }
    }
    this.updateDisableUpload();
  }

  /**
   * checkFormChanges() - checks if form values has any changes
   */
  checkFormChanges(): void {
    const currentFormValues = this.form?.getRawValue();
    const isChanged = !_.isEqual(this.initialFormValues, currentFormValues);
    this.stepOneFormChange.emit(isChanged);
  }

  /**
   * isInitialState() - returns a boolean value by checking initial values and recent value
   * @returns - boolean value checking initial state and formvalue 
   */
  isInitialState(): boolean {
    return JSON.stringify(this.form?.value) === JSON.stringify(this.initialFormValues);
  }


  ngOnChanges(): void {
    this.applicationData = this.startApplicationData;
    if (this.applicationData) {
      this.contactData = this.applicationData?.creditApplicationList?.accountPayableContact;
      this.partnersData = this.applicationData?.creditApplicationList?.partners;
      this.patchInitialTraderefDocuments(this.applicationData?.creditApplicationList);
    }
    else if (this.revisionData) {
      this.contactData = this.revisionData?.caRevisionDetails?.applicationFormDetails?.accountsPayableContactsDetails;
      this.partnersData = this.revisionData?.caRevisionDetails?.applicationFormDetails?.partnersDetails;
    }
    this.setBillingAddress();
    this.patchValueOnCustomerStatus();
    this.revisionRequiredStatus();
    if (this.customerStatus === this.credFormOneConstant.needsRevision) {
      this.isRevision = true;
      this.getRevisionRequiredMessage();
    }
    this.getProdCompanyState();
    this.getBillingState();
    this.getTrOneState();
    this.getTrTwoState();
    this.getTrThreeState();
    if (this.applicationData && this.customerStatus === 'Finish Application' || this.customerStatus === 'Start Application') {
      this.initialFormValues = this.form?.getRawValue();
      this.form?.valueChanges.subscribe(() => {
        this.checkFormChanges();
      });
    }
  if(this.customerStatus === 'NEEDS REVISIONS'){
    if(this.revisionData?.caRevisionDetails?.applicationFormDetails){
    this.initialFormValues = this.form?.getRawValue();
    this.form?.valueChanges.subscribe(() => {
      this.checkFormChanges();
    });
    }
  }
  }

  /**
 * initializeForm() - method to initialize the step one form
 */
  initializeForm(): void {
    this.form = this.fb.group({
      lineOfCredit: this.fb.group({
        lineOfCreditRequested: [
          '',
          [
            Validators.required,
            // Validators.maxLength(14),
            this.formValidationService.validateNoLoc,
          ],
        ],
        anticipatedJobTotal: [
          '',
          [
            Validators.required,
            // Validators.maxLength(14),
            this.formValidationService.validateNoLoc,
          ],
        ],
        totalNoOfMonths: [
          '',
          [
            Validators.required,
            Validators.maxLength(3),
            this.formValidationService.validateNo,
          ],
        ],
      }),

      letterOfGuarantee: this.fb.group({
        logComment: [
          '',
          [
            Validators.required,
          ],
        ],
      }),

      productionCompany: this.fb.group({
        name: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        street: [
          '',
          [
            Validators.required,
            Validators.maxLength(85),
            this.formValidationService.streetValidator,
          ],
        ],
        city: ['',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.cityValidator,
          ]
        ],
        state: [
          '',
          Validators.required
        ],
        zipcode: ['',
          [
            Validators.required,
            Validators.maxLength(12),
            this.formValidationService.zipCodeValidator
          ]
        ],
        phone: ['',
          [
            Validators.required,
            Validators.maxLength(20),
            this.formValidationService.validatePhoneNo
          ]
        ],
        useForBillingAddress: [false],
        billingStreet: ['', [
          Validators.required,
          Validators.maxLength(85),
          this.formValidationService.streetValidator,
        ],],
        billingCity: ['',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.cityValidator,
          ]],
        billingState: ['', Validators.required],
        billingZipcode: ['', [
          Validators.required,
          Validators.maxLength(12),
          this.formValidationService.zipCodeValidator
        ]],
        billingPhone: ['', [
          Validators.required,
          Validators.maxLength(20),
          this.formValidationService.validatePhoneNo
        ]],
      }),

      federalTax: this.fb.group({
        federalTaxTin: ['',
          [
            Validators.required,
            Validators.maxLength(15),
            this.formValidationService.validateFederalTaxTin
          ]
        ],
      }),

      accountPayable: this.fb.array([]), // Initialize as a FormArray

      companyProfile: this.fb.group({
        cpProfile: [
          '',
        ],
        cpDateStarted: [
          '',
        ],
        cpTypeOfBusiness: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateBusinessType,
          ],
        ],
        cpTotalAnnualsale: [
          '',
          [
            this.formValidationService.validateNoLoc,
          ],
        ],
        cpNetworth: [
          '',
          [
            this.formValidationService.validateNoLoc,
          ],
        ],
        cpNumOfEmployees: [
          '',
          [
            this.formValidationService.validateNoWithComma,
          ],
        ],
      }),

      principalsAndPartners: this.fb.array([]),

      bankingReference: this.fb.group({
        brbankName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        brAccountNumber: [
          '',
          [
            Validators.maxLength(20),
            this.formValidationService.validateNo,
          ],
        ],
        brContactName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        brTitle: [
          '',
          [
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        brEmail: [
          '',
          [
            Validators.maxLength(80),
            this.formValidationService.validateEmail,
          ],
        ],
        brPhone: [
          '',
          [
            Validators.maxLength(20),
            this.formValidationService.validatePhoneNo,
          ],
        ],
      }),

      tradeReference1: this.fb.group({
        tr1CompanyName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        tr1ContactFirstName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr1ContactLastName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr1Email: [
          '',
          [
            Validators.required,
            Validators.maxLength(80),
            this.formValidationService.validateEmail,
          ],
        ],
        tr1Phone: [
          '',
          [
            Validators.required,
            Validators.maxLength(20),
            this.formValidationService.validatePhoneNo,
          ],
        ],
        tr1Street: [
          '',
          [
            Validators.maxLength(85),
            this.formValidationService.streetValidator,
          ],
        ],
        tr1City: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.cityValidator,
          ],
        ],
        tr1state: [''],
        tr1ZipCode: [
          '',
          [
            Validators.maxLength(12),
            this.formValidationService.zipCodeValidator,
          ],
        ],
      }),

      tradeReference2: this.fb.group({
        tr2CompanyName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        tr2ContactFirstName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr2ContactLastName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr2Email: [
          '',
          [
            Validators.maxLength(80),
            this.formValidationService.validateEmail,
          ],
        ],
        tr2Phone: [
          '',
          [
            Validators.maxLength(20),
            this.formValidationService.validatePhoneNo,
          ],
        ],
        tr2Street: [
          '',
          [
            Validators.maxLength(85),
            this.formValidationService.streetValidator,
          ],
        ],
        tr2City: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.cityValidator,
          ],
        ],
        tr2state: [''],
        tr2ZipCode: [
          '',
          [
            Validators.maxLength(12),
            this.formValidationService.zipCodeValidator,
          ],
        ],
      }),

      tradeReference3: this.fb.group({
        tr3CompanyName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphanumericSpecialChars,
          ],
        ],
        tr3ContactFirstName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr3ContactLastName: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.validateAlphabets,
          ],
        ],
        tr3Email: [
          '',
          [
            Validators.maxLength(80),
            this.formValidationService.validateEmail,
          ],
        ],
        tr3Phone: [
          '',
          [
            Validators.maxLength(20),
            this.formValidationService.validatePhoneNo,
          ],
        ],
        tr3Street: [
          '',
          [
            Validators.maxLength(85),
            this.formValidationService.streetValidator,
          ],
        ],
        tr3City: [
          '',
          [
            Validators.maxLength(50),
            this.formValidationService.cityValidator,
          ],
        ],
        tr3state: [''],
        tr3ZipCode: [
          '',
          [
            Validators.maxLength(12),
            this.formValidationService.zipCodeValidator,
          ],
        ],
      }),
    });

    // Add an initial account payable contact
    this.addAccountPayableContact();
    this.addPrincipal();
  }

  /**
 * addAccountPayableContact() - method to add account payable contact section
 */
  addAccountPayableContact(): void {
    const contactGroup = this.fb.group({
      apFirstName: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphabets]],
      apLastName: ['', [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphabets]],
      apEmail: ['', [Validators.required, Validators.maxLength(80), this.formValidationService.validateEmail]],
      apPhone: ['', [Validators.required, Validators.maxLength(20), this.formValidationService.validatePhoneNo]],
      removed: [false]
    });

    this.accountPayableContacts?.push(contactGroup);
  }

  /**
   * addPrincipal() - method to add the principal/partners sections
   */
  addPrincipal(): void {
    if (this.principalsAndPartnersArray?.length < 5) {
      const principalGroup = this.fb.group({
        ppName: ['', [Validators.maxLength(50), this.formValidationService.validateAlphabets]],
        ppTitle: ['', [Validators.maxLength(50), this.formValidationService.validateAlphanumericSpecialChars]],
        removed: [false]
      });

      this.principalsAndPartnersArray?.push(principalGroup);
    }
  }

  /**
* patchValueOnCustomerStatus() - method to patch the data and disable the form according to the status
*/
  patchValueOnCustomerStatus(): void {
    if (this.customerStatus === this.credFormOneConstant.startApplication) {
      this.initializeForm();
      this.pathchValue();
    }
    else if (this.customerStatus === this.credFormOneConstant.finishApplication) {
      this.initializeForm();
      this.pathchValue();
    }
    else if (this.customerStatus === this.credFormOneConstant.needsRevision) {
      this.patchRevision();
    }
    else if (this.customerStatus === this.credFormOneConstant.revisionsSubmitted) {
      this.disableUpload = true;
      this.patchRevision();
      this.disableFormControls();
    }
    else {
      this.disableUpload = true;
      this.pathchValue();
      this.disableFormControls();
    }
  }

  /**
   * getProdCompanyState() - method to fetch the production company state data
   */
  getProdCompanyState(): void {
    const formValue = this.form?.value;
    const productionCompanyStateId = formValue?.productionCompany?.state;
    const selectedState = this.states?.find(state => {
      return state.stateId == productionCompanyStateId; // Find the state object
    });
    this.selectedState = selectedState;
  }

  /**
   * getBillingState() - method to fetch the billing state address
   */
  getBillingState(): void {
    const formValue = this.form?.value;
    const billingStateId = formValue?.productionCompany?.billingState;
    const selectedState = this.states?.find(state => {
      return state.stateId == billingStateId; // Find the state object
    });
    this.selectedBillingState = selectedState;
  }

  /**
   * getTrOneState() - method to fetch the trade reference one state data
   */
  getTrOneState(): void {
    const formValue = this.form?.value;
    const tradeRefOneStateId = formValue?.tradeReference1?.tr1state;
    const selectedState = this.states?.find(state => {
      return state.stateId == tradeRefOneStateId; // Find the state object
    });
    this.selectedTrOneState = selectedState;
  }

  /**
   * getTrTwoState() - method to fetch the trade reference two state data
   */
  getTrTwoState(): void {
    const formValue = this.form?.value;
    const tradeRefTwoStateId = formValue?.tradeReference2?.tr2state;
    const selectedState = this.states?.find(state => {
      return state.stateId == tradeRefTwoStateId; // Find the state object
    });
    this.selectedTrTwoState = selectedState;
  }

  /**
   * getTrThreeState() - method to fetch the trade reference three state data
   */
  getTrThreeState(): void {
    const formValue = this.form?.value;
    const tradeRefThreeStateId = formValue?.tradeReference3?.tr3state;
    const selectedState = this.states?.find(state => {
      return state.stateId == tradeRefThreeStateId; // Find the state object
    });
    this.selectedTrThreeState = selectedState;
  }

  /**
   * onStateChange() - method to get the state details
   */
  onStateChange(): void {
    const selectedStateId = this.form?.get('productionCompany.state')?.value; // Get the selected stateId
    const selectedState = this.states.find(state => {
      return state.stateId == selectedStateId; // Find the state object
    });
    this.selectedState = selectedState;
  }

  onBillingStateChange(): void {
    const selectedStateId = this.form?.get('productionCompany.billingState')?.value; // Get the selected stateId
    const selectedState = this.states.find(state => {
      return state.stateId == selectedStateId; // Find the state object
    });
    this.selectedBillingState = selectedState;
  }

  /**
  * onTrOneStateChange() - method to get the state details
  */
  onTrOneStateChange(): void {
    const selectedStateId = this.form?.get('tradeReference1.tr1state')?.value; // Get the selected stateId
    const selectedState = this.states.find(state => {
      return state.stateId == selectedStateId; // Find the state object
    });
    this.selectedTrOneState = selectedState;
  }

  /**
  * onTrTwoStateChange() - method to get the state details
  */
  onTrTwoStateChange(): void {
    const selectedStateId = this.form?.get('tradeReference2.tr2state')?.value; // Get the selected stateId
    const selectedState = this.states.find(state => {
      return state.stateId == selectedStateId; // Find the state object
    });
    this.selectedTrTwoState = selectedState;
  }

  /**
  * onTrThreeStateChange() - method to get the state details
  */
  onTrThreeStateChange(): void {
    const selectedStateId = this.form?.get('tradeReference3.tr3state')?.value; // Get the selected stateId
    const selectedState = this.states.find(state => {
      return state.stateId == selectedStateId; // Find the state object
    });
    this.selectedTrThreeState = selectedState;
  }

  /**
   * revisionRequiredStatus() - to enable and disable based on review required status
   */
  revisionRequiredStatus(): void {
    if (this.customerStatus === this.credFormOneConstant.needsRevision) {
      const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;

      // Define mapping of sections
      const sectionMappings = [
        { key: 'lineOfCredit', label: 'Line of Credit', condition: data?.lineOfCreditDetails?.reviewRequired },
        { key: 'letterOfGuarantee', label: 'Letter of Credit / Guarantee', condition: data?.letterOfGuaranteeDetails?.reviewRequired },
        { key: 'productionCompany', label: 'Production Company', condition: data?.productionCompanyAddressDetails[0]?.reviewRequired },
        { key: 'billingAddress', label: 'Production Company', condition: data?.productionCompanyAddressDetails[1]?.reviewRequired },
        { key: 'federalTax', label: 'Federal Tax Id', condition: data?.productionCompanyAddressDetails[0]?.federalTaxIdRevisionDetails?.reviewRequired },
        { key: 'accountPayableForm', label: 'Account Payable', condition: data?.accountsPayableContactsDetails[0]?.reviewRequired },
        { key: 'companyProfile', label: 'Company Profile', condition: data?.caCompanyProfileDetails?.reviewRequired },
        { key: 'principalsAndPartners', label: 'Principals/Partners', condition: data?.partnersDetails[0]?.reviewRequired },
        { key: 'bankingReference', label: 'Banking References', condition: data?.bankingReferencesDetails?.reviewRequired },
        { key: 'tradeReference1', label: 'Trading Reference 1', condition: data?.tradeReference1Details?.reviewRequired },
        { key: 'tradeReference2', label: 'Trading Reference 2', condition: data?.tradeReference2Details?.reviewRequired },
        { key: 'tradeReference3', label: 'Trading Reference 3', condition: data?.tradeReference3Details?.reviewRequired },
      ];
      // Filter and create the dynamic list
      this.reviewRequiredSections = sectionMappings?.filter(section => section?.condition);
      this.updateFormControls();
      this.updateDisableUpload();
    }
  }

  /**
   * updateDisableUpload() - method to add disabled state for various fields
   */
  updateDisableUpload() {
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    if(this.customerStatus == 'NEEDS REVISIONS'){
      this.disableUpload1 = !data?.tradeReference1Details?.reviewRequired;
      this.disableUpload2 = !data?.tradeReference2Details?.reviewRequired;
      this.disableUpload3 = !data?.tradeReference3Details?.reviewRequired;
      this.disableLetterOfCredit = !data?.letterOfGuaranteeDetails?.reviewRequired;
      this.disableAccountPayable = !data?.accountsPayableContactsDetails[0]?.reviewRequired;
      this.disableprinciple = !data?.partnersDetails[0]?.reviewRequired;
       
    }else if(this.customerStatus == 'UNDER REVIEW'){
      this.disableUpload1 = true;
      this.disableUpload2 = true;
      this.disableUpload3 = true;
      this.disableLetterOfCredit = true;
      this.disableAccountPayable = true;
      this.disableprinciple = true;

    }else if(this.customerStatus == 'Application Submitted'){
      this.disableUpload1 = true;
      this.disableUpload2 = true;
      this.disableUpload3 = true;
      this.disableLetterOfCredit = true;
      this.disableAccountPayable = true;
      this.disableprinciple = true;

    }else if(this.customerStatus == 'Finish Application'){
      this.disableUpload1 = false;
      this.disableUpload2 = false;
      this.disableUpload3 = false;
      this.disableLetterOfCredit = false;
      this.disableAccountPayable = false;
      this.disableprinciple = false;

    }
    else if(this.customerStatus == 'REVISION SUBMITTED'){
      this.disableUpload1 = true;
      this.disableUpload2 = true;
      this.disableUpload3 = true;
      this.disableLetterOfCredit = true;
      this.disableAccountPayable = true;
      this.disableprinciple = true;

    }else{
      this.disableUpload1 = false;
      this.disableUpload2 = false;
      this.disableUpload3 = false;
      this.disableLetterOfCredit = false;
      this.disableAccountPayable = false;
      this.disableprinciple = false;

    }
  }

  /**
   * method to get the messages for revisions required sections
   */
  getRevisionRequiredMessage() {
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    this.locMessage = data?.lineOfCreditDetails?.reviewDetails?.reviewMailBody;
    this.logMessage = data?.letterOfGuaranteeDetails?.reviewDetails?.reviewMailBody;
    this.prodComMessage = data?.productionCompanyAddressDetails[0]?.reviewDetails?.reviewMailBody;
    this.fedTaxMessage = data?.productionCompanyAddressDetails[0]?.federalTaxIdRevisionDetails?.reviewDetails?.reviewMailBody
    this.billingMessage = data?.productionCompanyAddressDetails[1]?.reviewDetails?.reviewMailBody;
    this.accountPayableMessage = data?.accountsPayableContactsDetails[0]?.reviewDetails?.reviewMailBody;
    this.bankingMessage = data?.bankingReferencesDetails?.reviewDetails?.reviewMailBody;
    this.companyMessage = data?.caCompanyProfileDetails?.reviewDetails?.reviewMailBody;
    this.partnerMessage = data?.partnersDetails[0]?.reviewDetails?.reviewMailBody;
    this.tr1Message = data?.tradeReference1Details?.reviewDetails?.reviewMailBody;
    this.tr2Message = data?.tradeReference2Details?.reviewDetails?.reviewMailBody;
    this.tr3Message = data?.tradeReference3Details?.reviewDetails?.reviewMailBody;
  }

  /**
   * updateFormControls() - to update form controls based on review required
   */
  updateFormControls(): void {
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    const fedTax = false;
    if (!data) {
      return;
    }

    else if (data) {
      this.form?.get('bankingReference')?.[data?.bankingReferencesDetails?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('lineOfCredit')?.[data?.lineOfCreditDetails?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('letterOfGuarantee')?.[data?.letterOfGuaranteeDetails?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('productionCompany')?.[data?.productionCompanyAddressDetails[0]?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();

      const billingAddressRequired = data?.productionCompanyAddressDetails[1]?.reviewRequired;
      this.form?.get('productionCompany')?.get('billingStreet')?.[billingAddressRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('productionCompany')?.get('billingCity')?.[billingAddressRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('productionCompany')?.get('billingState')?.[billingAddressRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('productionCompany')?.get('billingZipcode')?.[billingAddressRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('productionCompany')?.get('billingPhone')?.[billingAddressRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('federalTax')?.[data?.productionCompanyAddressDetails[0]?.federalTaxIdRevisionDetails?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('accountPayable')?.[data?.accountsPayableContactsDetails[0]?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('companyProfile')?.[data?.caCompanyProfileDetails?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('principalsAndPartners')?.[data?.partnersDetails[0]?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('tradeReference1')?.[data?.tradeReference1Details?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('tradeReference2')?.[data?.tradeReference2Details?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
      this.form?.get('tradeReference3')?.[data?.tradeReference3Details?.reviewRequired ? this.credFormOneConstant.enable : this.credFormOneConstant.disable]();
    }
  }


  /**
  * patchRevision() - To patch revision details for step one
  */
  patchRevision(): void {
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    const lineOfCreditGroup = this.form?.get('lineOfCredit') as FormGroup;
    lineOfCreditGroup?.patchValue({
      anticipatedJobTotal: data?.lineOfCreditDetails?.locJobTotal ? `$${data.lineOfCreditDetails.locJobTotal?.toLocaleString('en-US')}` : null,
      lineOfCreditRequested: data?.lineOfCreditDetails?.locRequested ? `$${data.lineOfCreditDetails.locRequested?.toLocaleString('en-US')}`
        : null,
      totalNoOfMonths: data?.lineOfCreditDetails?.locNumOfMonths,
    });
    const letterOfGuaranteeGroup = this.form?.get('letterOfGuarantee') as FormGroup;
    letterOfGuaranteeGroup?.patchValue({
      logComment: data?.letterOfGuaranteeDetails?.logComments
    });
    if (data?.letterOfGuaranteeDetails?.logDocUrl) {
      const fileName = data?.letterOfGuaranteeDetails?.logDocUrl.split('/').pop(); // Extract the file name
      const file: File = new File([], fileName); // Create a new File object (this is just a placeholder)
      this.files.push(file); // Push the actual File object
      this.uploadedFiles = this.files; // Store the uploaded files
    }

    const productionCompanyDetails = this.form?.get(
      'productionCompany'
    ) as FormGroup;
    if (!data?.productionCompanyAddressDetails[0]?.billingAddressSameInd) {
      productionCompanyDetails?.patchValue({
        name: this.revisionData?.caRevisionDetails?.companyDetails?.companyName,
        street: data?.productionCompanyAddressDetails[0]?.street,
        city: data?.productionCompanyAddressDetails[0]?.city,
        state: data?.productionCompanyAddressDetails[0]?.state?.stateId,
        zipcode: data?.productionCompanyAddressDetails[0]?.zip,
        phone: data?.productionCompanyAddressDetails[0]?.phone,
        useForBillingAddress: data?.productionCompanyAddressDetails[0]?.billingAddressSameInd,
        billingStreet: data?.productionCompanyAddressDetails[1]?.street,
        billingCity: data?.productionCompanyAddressDetails[1]?.city,
        billingState: data?.productionCompanyAddressDetails[1]?.state?.stateId,
        billingZipcode: data?.productionCompanyAddressDetails[1]?.zip,
        billingPhone: data?.productionCompanyAddressDetails[1]?.phone,
      });
    }
    else {
      productionCompanyDetails?.patchValue({
        name: this.revisionData?.caRevisionDetails?.companyDetails?.companyName,
        street: data?.productionCompanyAddressDetails[0]?.street,
        city: data?.productionCompanyAddressDetails[0]?.city,
        state: data?.productionCompanyAddressDetails[0]?.state?.stateId,
        zipcode: data?.productionCompanyAddressDetails[0]?.zip,
        phone: data?.productionCompanyAddressDetails[0]?.phone,
        useForBillingAddress: data?.productionCompanyAddressDetails[0]?.billingAddressSameInd,
      });
    }
    const federalTaxIn = this.form?.get('federalTax');
    federalTaxIn?.patchValue({
      federalTaxTin: data?.productionCompanyAddressDetails[0]?.fedralTaxNum,
    });

    const accountPayableArray = this.form?.get('accountPayable') as FormArray;
    this.accountPayableContacts?.clear();
    if (data?.accountsPayableContactsDetails && Array.isArray(data?.accountsPayableContactsDetails) && data?.accountsPayableContactsDetails?.length) {
      data.accountsPayableContactsDetails.forEach(contact => {
        const contactGroup = this.fb.group({
          apFirstName: [contact?.firstName, [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphabets]],
          apLastName: [contact?.lastName, [Validators.required, Validators.maxLength(50), this.formValidationService.validateAlphabets]],
          apEmail: [contact?.emailAddress, [Validators.required, this.formValidationService.validateEmail]],
          apPhone: [contact?.telephone, [Validators.required, this.formValidationService.validatePhoneNo]],
        });
        accountPayableArray?.push(contactGroup);
      });
    }
    const companyProfile = this.form?.get('companyProfile');
    const formattedDate = this.formatDate(data?.caCompanyProfileDetails?.dateStarted);
    companyProfile?.patchValue({
      cpProfile: data?.caCompanyProfileDetails?.caCpType,
      cpDateStarted: formattedDate,
      cpTypeOfBusiness: data?.caCompanyProfileDetails?.typeOfBusiness,
      cpTotalAnnualsale: data?.caCompanyProfileDetails?.totalAnnualSales ? `$${data.caCompanyProfileDetails.totalAnnualSales.toLocaleString('en-US')}` : null,
      cpNetworth: data?.caCompanyProfileDetails?.networth ? `$${data.caCompanyProfileDetails.networth.toLocaleString('en-US')}` : null,
      cpNumOfEmployees: data?.caCompanyProfileDetails?.noOfEmployees ? `${data.caCompanyProfileDetails.noOfEmployees.toLocaleString('en-US')}` : null,
    });

    const principalsArray = this.form?.get('principalsAndPartners') as FormArray;
    this.principalsAndPartnersArray?.clear();
    if (data?.partnersDetails && Array.isArray(data?.partnersDetails) && data?.partnersDetails?.length) {
      data.partnersDetails.forEach(principal => {
        const principalGroup = this.fb.group({
          ppName: principal?.partnerName,
          ppTitle: principal?.partnerTitle,
        });
        principalsArray?.push(principalGroup);
      });
    }
    else {
      this.addPrincipal();
    }
    const bankingReference = this.form?.get('bankingReference');
    bankingReference?.patchValue({
      brbankName: data?.bankingReferencesDetails?.bankName,
      brAccountNumber: data?.bankingReferencesDetails?.accountNumber,
      brContactName: data?.bankingReferencesDetails?.contactFullName,
      brTitle: data?.bankingReferencesDetails?.title,
      brEmail: data?.bankingReferencesDetails?.emailAddress,
      brPhone: data?.bankingReferencesDetails?.phoneNumber,
    });
    const tradeReference1 = this.form?.get('tradeReference1');
    tradeReference1?.patchValue({
      tr1CompanyName: data?.tradeReference1Details?.trCompanyName,
      tr1ContactFirstName: data?.tradeReference1Details?.trFirstName,
      tr1ContactLastName: data?.tradeReference1Details?.trLastName,
      tr1Email: data?.tradeReference1Details?.trEmail,
      tr1Phone: data?.tradeReference1Details?.trPhone,
      tr1Street: data?.tradeReference1Details?.trStreet,
      tr1City: data?.tradeReference1Details?.trCity,
      tr1state: data?.tradeReference1Details?.trStateId,
      tr1ZipCode: data?.tradeReference1Details?.trZipCode,
    });
    const tradeReference2 = this.form?.get('tradeReference2');
    tradeReference2?.patchValue({
      tr2CompanyName: data?.tradeReference2Details?.trCompanyName,
      tr2ContactFirstName: data?.tradeReference2Details?.trFirstName,
      tr2ContactLastName: data?.tradeReference2Details?.trLastName,
      tr2Email: data?.tradeReference2Details?.trEmail,
      tr2Phone: data?.tradeReference2Details?.trPhone,
      tr2Street: data?.tradeReference2Details?.trStreet,
      tr2City: data?.tradeReference2Details?.trCity,
      tr2state: data?.tradeReference2Details?.trStateId,
      tr2ZipCode: data?.tradeReference2Details?.trZipCode,
    });
    const tradeReference3 = this.form?.get('tradeReference3');
    tradeReference3?.patchValue({
      tr3CompanyName: data?.tradeReference3Details?.trCompanyName,
      tr3ContactFirstName: data?.tradeReference3Details?.trFirstName,
      tr3ContactLastName: data?.tradeReference3Details?.trLastName,
      tr3Email: data?.tradeReference3Details?.trEmail,
      tr3Phone: data?.tradeReference3Details?.trPhone,
      tr3Street: data?.tradeReference3Details?.trStreet,
      tr3City: data?.tradeReference3Details?.trCity,
      tr3state: data?.tradeReference3Details?.trStateId,
      tr3ZipCode: data?.tradeReference3Details?.trZipCode,
    });
    this.patchRevisionTraderefDocuments(data);
  }

  get accountPayableContacts(): FormArray {
    return this.form?.get('accountPayable') as FormArray;
  }

  get principalsAndPartnersArray(): FormArray {
    return this.form?.get('principalsAndPartners') as FormArray;
  }

  /**
   * removeAccountPayableContact() - method to remove account payable contact
   * @param index - index of the contact to be removed
   */
  removeAccountPayableContact(index: number): void {
    const accountPayableArray = this.form.get('accountPayable') as FormArray;
    if (accountPayableArray.length > 1) {
      accountPayableArray.removeAt(index);
      const contactGroup = accountPayableArray.at(index) as FormGroup;
      contactGroup?.get('removed')?.setValue(true);
      this.form.markAsDirty();
      this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      this.cdr.detectChanges();
    }
  }

  /**
   * removePartner() - method to remove the partner details
   * @param index - index of the partner to be removed
   */
  removePartner(index: number): void {
    const partnersArray = this.form.get('principalsAndPartners') as FormArray;
    if (partnersArray?.length > 1) {
      partnersArray.removeAt(index);
      const partnerGroup = partnersArray.at(index) as FormGroup;
      partnerGroup?.get('removed')?.setValue(true);
      this.form.markAsDirty();
      this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      this.cdr.detectChanges();
    }
  }

  /**
   * method to check the billing address
   */
  onBillingAddressToggle(): void {
    this.useBillingAddress = this.form.get('productionCompany.useForBillingAddress')?.value;
    if (this.useBillingAddress) {
      // If checked, clear the billing address fields
      this.form.patchValue({
        billingStreet: '',
        billingCity: '',
        billingZipcode: ''
      });
    }
  }

  /**
   * setBillingAddress() - method to set the billing address
   */
  setBillingAddress(): void {
    this.form?.get('productionCompany?.useForBillingAddress')?.valueChanges.subscribe((value) => {
      const transformedValue = value ? 1 : 0;
      this.form?.get('productionCompany?.useForBillingAddress')?.setValue(transformedValue, { emitEvent: false });
    });
  }

  /**
  * onFileRemoved() - method toremove the file
  * @param file - file to be removed
  */
  onFileRemoved(removedFiles: File[]): void {
    if (removedFiles) {
      this.removeLogFile();
    }
  }

  /**
   * onFilesUpdated() - to upload the file
   * @param files - the files that needed to be uploaded
   */
  onFilesUpdated(files: File[]): void {
    this.loaderService.setLoadingState(true);
    this.uploadedFiles = files;
    const formData = new FormData();
    _.forEach(this.uploadedFiles, (file) => {
      formData.append('file', file);
    });
    const logId = this.applicationData?.creditApplicationData?.letterOfGuarantee?.logId;
    const data = {
      caSeqId: this.caSeqId,
      caNumber: this.caNumber,
      logId: logId,
      sectionName: "letterofguarantee",
      isRemove: false
    };
    formData.append('data', JSON.stringify(data));

    this.creditApplicationService.uploadLogFile(formData).subscribe({
      next: (response) => {
        this.loaderService.setLoadingState(false);
        this.logResponseDetails = response;
        this.successMsg = this.toastMessages?.logUploadSuccess;
        this.showSuccess(this.successUploadFileTpl);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error("error uploading the log file", error);
        this.errorMsg = error.errorMessage;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * openPDF() - method to view the pdf file
  */
  openPDF(): void {
    this.loaderService.setLoadingState(true);
    const logDetails = this.logResponseDetails?.responseDTO;
    let logDocUrl;
    if(this.revisionData != undefined){
      logDocUrl = this.revisionData?.caRevisionDetails?.applicationFormDetails?.letterOfGuaranteeDetails?.logDocUrl
    }else{
      logDocUrl = this.startApplicationData?.creditApplicationList?.letterOfGuarantee?.logDocUrl;
    }
    const file = this.logResponseDetails? this.logResponseDetails?.responseDTO?.logDocUrl : logDocUrl;
    this.sharedViewCreditAppService.getCreditAppReport(file, 'letterofguarantee').pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          this.loaderService.setLoadingState(false);
          console.error("error uploading the log file", error);
          this.errorMsg = error.errorMessage;
          this.showDanger(this.dangerTpl);
        }
      })
  }

  /**
   * removeLogFile() - method to remove the uploaded log file
   */
  removeLogFile(): void {
    this.loaderService.setLoadingState(true);
    const logDetails = this.logResponseDetails?.responseDTO;
    const formData = new FormData();
    const data = {
      caSeqId: logDetails ? logDetails?.caSeqId : this.applicationData?.creditApplicationList?.caSeqId,
      caNumber: logDetails ? logDetails?.caNumber : this.applicationData?.creditApplicationList?.caNumber,
      logId: logDetails ? logDetails?.logId : this.applicationData?.creditApplicationList?.letterOfGuarantee?.logId,
      sectionName: "letterofguarantee",
      isRemove: true,
      filePath: logDetails ? logDetails?.logDocUrl : this.applicationData?.creditApplicationList?.letterOfGuarantee?.logDocUrl
    };
    formData.append('data', JSON.stringify(data));
    this.creditApplicationService.uploadLogFile(formData).subscribe({
      next: (response) => {
        this.loaderService.setLoadingState(false);
        this.successMsg = this.toastMessages?.logDeleteSuccess;
        this.showSuccess(this.successUploadFileTpl);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error("error uploading the log file", error);
        this.errorMsg = error.errorMessage;
        this.showDanger(this.dangerTpl);
      }
    });
  }


  /**
   * disableFormControls() - method to disable the form controls
   */
  disableFormControls(): void {
    if (!this.form) {
      return;
    }
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key)?.disable(); // Disable top-level controls
      const nestedGroup = this.form.get(key) as FormGroup;
      if (nestedGroup) {
        Object.keys(nestedGroup.controls).forEach((nestedKey) => {
          nestedGroup.get(nestedKey)?.disable(); // Disable nested controls
        });
      }
    });
  }

  /**
   * createPayload() - creating payload for submitting credit application
   * @returns - a payload for submitting credit application
   */
  createPayload(): any {
    const formValue = this.form.value;
    const caProdCompany = [];
    const filteredContacts = formValue.accountPayable.filter(contact => !contact.removed);
    const filteredPartners = formValue.principalsAndPartners.filter(partner => !partner.removed);

    // Check if the billing address is the same as the production address
    if (formValue.productionCompany.useForBillingAddress) {
      caProdCompany.push({
        caProdComAddrId: this.applicationData?.creditApplicationList?.caProdCompany?.caProdComAddrId ? this.applicationData?.creditApplicationList?.caProdCompany?.caProdComAddrId : null,
        companyAddressType: {
          companyAddressTypeId: 1,
          addressType: "Production company Address"
        },
        countryId: this.selectedState?.countryId,
        state: {
          stateId: formValue.productionCompany.state ? formValue.productionCompany.state : this.selectedState?.stateId,
          stateCode: this.selectedState?.stateCode,
        },
        street: formValue?.productionCompany?.street,
        city: formValue?.productionCompany?.city,
        zip: formValue.productionCompany?.zipcode,
        phone: formValue.productionCompany?.phone,
        billingAddressSameInd: 1,
        companyName: formValue?.productionCompany?.name,
        fedralTaxNum: formValue?.federalTax?.federalTaxTin,
      });
    } else {
      // If the billing address is different, add both addresses
      caProdCompany.push({
        caProdComAddrId: this.applicationData?.creditApplicationList?.caProdCompany?.caProdComAddrId ? this.applicationData?.creditApplicationList?.caProdCompany?.caProdComAddrId : null,
        companyAddressType: {
          companyAddressTypeId: 1,
          addressType: "Production company Address"
        },
        countryId: this.selectedState?.countryId,
        state: {
          stateId: this.selectedState?.stateId,
          stateCode: this.selectedState?.stateCode,
        },
        street: formValue?.productionCompany?.street,
        city: formValue?.productionCompany?.city,
        zip: formValue.productionCompany.zipcode,
        phone: formValue.productionCompany.phone,
        billingAddressSameInd: 0,
        companyName: formValue?.productionCompany?.name,
        fedralTaxNum: formValue?.federalTax?.federalTaxTin,
      });

      // Add billing address
      caProdCompany.push({
        caProdComAddrId: this.applicationData?.creditApplicationList?.caProdCompany?.caProdComAddrId ? this.applicationData?.creditApplicationList?.caProdCompany?.caProdComAddrId : null,
        companyAddressType: {
          companyAddressTypeId: 2,
          addressType: "billing Address"
        },
        countryId: this.selectedBillingState?.countryId,
        state: {
          stateId: this.selectedBillingState?.stateId,
          stateCode: this.selectedBillingState?.stateCode,
        },
        street: formValue?.productionCompany?.billingStreet,
        city: formValue?.productionCompany?.billingCity,
        zip: formValue.productionCompany.billingZipcode,
        phone: formValue.productionCompany.billingPhone,
        billingAddressSameInd: 0,
        companyName: formValue?.productionCompany?.name,
        fedralTaxNum: formValue?.federalTax?.federalTaxTin,
      });
    }

    let payload = {
      caSeqId: this.caSeqId,
      caNumber: this.caNumber,
      projectId: this.projectId,
      companyId: this.companyId,
      locId: this.applicationData?.creditApplicationList?.lineOfCredit?.locId ? this.applicationData?.creditApplicationList?.lineOfCredit?.locId : null,
      logId: this.applicationData?.creditApplicationList?.letterOfGuarantee?.logId ? this.applicationData?.creditApplicationList?.letterOfGuarantee?.logId : null,
      caCompanyProfileId: this.applicationData?.creditApplicationList?.caCompanyProfile?.caCompanyProfileId ? this.applicationData?.creditApplicationList?.caCompanyProfile?.caCompanyProfileId : null,
      brId: this.applicationData?.creditApplicationList?.bankingReferences?.brId ? this.applicationData?.creditApplicationList?.bankingReferences?.brId : null,
      lineOfCredit: {
        locId: this.applicationData?.creditApplicationList?.lineOfCredit?.locId ? this.applicationData?.creditApplicationList?.lineOfCredit?.locId : null,
        locRequested: formValue?.lineOfCredit?.lineOfCreditRequested
          ? parseInt(formValue?.lineOfCredit?.lineOfCreditRequested?.toString()?.replace(/[$,]/g, ''))
          : null,

        locJobTotal: formValue?.lineOfCredit?.anticipatedJobTotal ? parseInt(formValue?.lineOfCredit?.anticipatedJobTotal?.toString()?.replace(/[$,]/g, '')) : null,
        locNumOfMonths: formValue?.lineOfCredit?.totalNoOfMonths ? formValue?.lineOfCredit?.totalNoOfMonths : null,
      },
      letterOfGuarantee: {
        logId: this.applicationData?.creditApplicationList?.letterOfGuarantee?.logId ? this.applicationData?.creditApplicationList?.letterOfGuarantee?.logId : null,
        logComments: formValue?.letterOfGuarantee?.logComment,
        logDocUrl: this.logResponseDetails ? this.logResponseDetails?.responseDTO?.logDocUrl : this.applicationData?.creditApplicationList?.letterOfGuarantee?.logDocUrl
      },
      caProdCompany: caProdCompany,

      accountsPayableContacts: filteredContacts.map(contact => ({
        accountsPayableContactId: contact?.accountsPayableContactId ? contact?.accountsPayableContactId : null,
        firstName: contact?.apFirstName ? contact?.apFirstName : null,
        lastName: contact?.apLastName ? contact?.apLastName : null,
        emailAddress: contact?.apEmail ? contact?.apEmail : null,
        telephone: contact?.apPhone ? contact?.apPhone : null,
      })),

      caCompanyProfile: {
        companyProfileId: this.applicationData?.creditApplicationList?.caCompanyProfile?.caCompanyProfileId ? this.applicationData?.creditApplicationList?.caCompanyProfile?.caCompanyProfileId : null,
        caCpType: formValue?.companyProfile?.cpProfile ? formValue?.companyProfile?.cpProfile : null,
        dateStarted: formValue.companyProfile.cpDateStarted ? formValue.companyProfile.cpDateStarted : null,
        typeOfBusiness: formValue?.companyProfile?.cpTypeOfBusiness ? formValue?.companyProfile?.cpTypeOfBusiness : null,
        totalAnnualSales: parseInt(formValue?.companyProfile?.cpTotalAnnualsale?.toString()?.replace(/[$,]/g, '')),
        networth: parseInt(formValue?.companyProfile?.cpNetworth?.toString()?.replace(/[$,]/g, '')),
        noOfEmployees: parseInt(formValue?.companyProfile?.cpNumOfEmployees?.toString()?.replace(/[,]/g, '')),
      },
      partners: filteredPartners.map(principal => ({
        partnersId: principal?.partnersId ? principal?.partnersId : null,
        partnerName: principal?.ppName ? principal?.ppName : null,
        partnerTitle: principal?.ppTitle ? principal?.ppTitle : null
      })),
      bankingReferences: {
        brId: this.applicationData?.creditApplicationList?.bankingReferences?.brId ? this.applicationData?.creditApplicationList?.bankingReferences?.brId : null,
        bankName: formValue?.bankingReference?.brbankName,
        accountNumber: formValue?.bankingReference?.brAccountNumber,
        contactFullName: formValue?.bankingReference?.brContactName,
        title: formValue?.bankingReference?.brTitle,
        emailAddress: formValue?.bankingReference?.brEmail,
        phoneNumber: formValue?.bankingReference?.brPhone,
      },
      tradeReferences: [
        {
          trId: this.applicationData?.creditApplicationList?.tradeReferences1?.trId ? this.applicationData?.creditApplicationList?.tradeReferences1?.trId : null,
          trOrder: this.tr1fileName || (
            formValue?.tradeReference1?.tr1CompanyName &&
            formValue?.tradeReference1?.tr1ContactFirstName &&
            formValue?.tradeReference1?.tr1ContactLastName &&
            formValue?.tradeReference1?.tr1Email &&
            formValue?.tradeReference1?.tr1Phone
          ) ? 1 : null,
          trCompanyName: formValue?.tradeReference1?.tr1CompanyName,
          trFirstName: formValue?.tradeReference1?.tr1ContactFirstName,
          trLastName: formValue?.tradeReference1?.tr1ContactLastName,
          trEmail: formValue?.tradeReference1?.tr1Email,
          trPhone: formValue?.tradeReference1?.tr1Phone,
          trCity: formValue?.tradeReference1?.tr1City,
          trStateId: this.selectedTrOneState?.stateId,
          trStreet: formValue?.tradeReference1?.tr1Street ? formValue?.tradeReference1?.tr1Street : null,
          trZipCode: formValue?.tradeReference1?.tr1ZipCode,
          trDocumentUrl: this.tradeRefferenc1Data ? this.tradeRefferenc1Data?.trDocumentUrl : this.applicationData?.creditApplicationList?.tradeReferences1?.trDocumentUrl
        },
        {
          trId: this.applicationData?.creditApplicationList?.tradeReferences2?.trId ? this.applicationData?.creditApplicationList?.tradeReferences2?.trId : null,
          trOrder: this.tr2fileName || (
            formValue?.tradeReference2?.tr2CompanyName &&
            formValue?.tradeReference2?.tr2ContactFirstName &&
            formValue?.tradeReference2?.tr2ContactLastName &&
            formValue?.tradeReference2?.tr2Email &&
            formValue?.tradeReference2?.tr2Phone
          ) ? 2 : null,
          trCompanyName: formValue?.tradeReference2?.tr2CompanyName,
          trFirstName: formValue?.tradeReference2?.tr2ContactFirstName,
          trLastName: formValue.tradeReference2.tr2ContactLastName,
          trEmail: formValue?.tradeReference2?.tr2Email,
          trPhone: formValue?.tradeReference2?.tr2Phone,
          trCity: formValue?.tradeReference2?.tr2City,
          trStateId: this.selectedTrTwoState?.stateId,
          trStreet: formValue?.tradeReference2?.tr2Street,
          trZipCode: formValue?.tradeReference2?.tr2ZipCode,
          trDocumentUrl: this.tradeRefferenc2Data ? this.tradeRefferenc2Data?.trDocumentUrl : this.applicationData?.creditApplicationList?.tradeReferences2?.trDocumentUrl
        },
        {
          trId: this.applicationData?.creditApplicationList?.tradeReferences3?.trId ? this.applicationData?.creditApplicationList?.tradeReferences3?.trId : null,
          trOrder: this.tr3fileName || (
            formValue?.tradeReference3?.tr3CompanyName &&
            formValue?.tradeReference3?.tr3ContactFirstName &&
            formValue?.tradeReference3?.tr3ContactLastName &&
            formValue?.tradeReference3?.tr3Email &&
            formValue?.tradeReference3?.tr3Phone
          ) ? 3 : null,
          trCompanyName: formValue?.tradeReference3?.tr3CompanyName,
          trFirstName: formValue?.tradeReference3?.tr3ContactFirstName,
          trLastName: formValue?.tradeReference3?.tr3ContactLastName,
          trEmail: formValue?.tradeReference3?.tr3Email,
          trPhone: formValue?.tradeReference3?.tr3Phone,
          trCity: formValue?.tradeReference3?.tr3City,
          trStateId: this.selectedTrThreeState?.stateId,
          trStreet: formValue?.tradeReference3?.tr3Street,
          trZipCode: formValue?.tradeReference3?.tr3ZipCode,
          trDocumentUrl: this.tradeRefferenc3Data ? this.tradeRefferenc3Data?.trDocumentUrl : this.applicationData?.creditApplicationList?.tradeReferences3?.trDocumentUrl
        },
      ]

    };

    return payload;
  }

  /**
   * restoreFormData() - method to restore the entered form data if the api is
   */
  restoreFormData(): void {
    this.localFormData = JSON.parse(localStorage?.getItem('formData'));
    if (this.localFormData) {
        console.log(this.localFormData);
        this.pathchValue(); // Patch the form with the stored data
    }
  }

  /**
   * submitCreditform() - method to submit the credit application form data
   */
  submitCreditform() {
    let payload = this.createPayload();

    this.creditApplicationService.saveCreditAppForm(payload).subscribe({
      next: (response: any) => {
        this.cdr.detectChanges();
        this.formSubmittedEvent.emit();
        this.nextStepEvent.emit();
        localStorage.removeItem('formData');
        this.loaderService.setLoadingState(false);
        this.successMsg = this.toastMessages.creditApplication;
        this.showSuccess(this.successUploadFileTpl);
      },
      error: (error) => {
        localStorage.setItem('formData', JSON.stringify(payload));
        this.isNextButtonDisabled = false;
        console.error(error);
        this.errorMsg = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * updateCreditform() - to update the credit application 
   */
  updateCreditform(): void {
    this.formSubmittedEvent.emit();
    this.loaderService.setLoadingState(true);
    let payload = this.createPayload();
    this.creditApplicationService.updateCreditAppForm(payload).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.successTpl);
        this.stepCompleted.emit(2);
        this.formSubmittedEvent.emit();
      },
      error: (error) => {
        console.error(error);
        this.errorMsg = error.message;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * submitCreditAppication() - Method to submit the form data to the API
   */
  submitCreditAppication(): void {
    if (this.customerStatus !== this.credFormOneConstant.needsRevision) {
      this.submitCreditform();
    } else {
      this.updateCreditform();
      this.formSubmittedEvent.emit();
    }
  }

  formatDate(dateString: string): string {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    }

    const parts = dateString?.split('/'); // Split the date string
    if (parts.length !== 3) {
      return '';
    }
    let [month, day, year] = parts;
    month = month.padStart(2, '0');
    day = day.padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
 * pathchValue() - to patch the value other than revision
 */
  pathchValue(): void {
    const data = this.localFormData ? this.localFormData : this.startApplicationData?.creditApplicationList;
    const lineOfCreditGroup = this.form?.get('lineOfCredit') as FormGroup;
    lineOfCreditGroup?.patchValue({
      anticipatedJobTotal: data?.lineOfCredit?.locJobTotal ? `$${data.lineOfCredit?.locJobTotal.toLocaleString('en-US')}`
        : null,
      lineOfCreditRequested: data?.lineOfCredit?.locRequested
        ? `$${data.lineOfCredit.locRequested?.toLocaleString('en-US')}`
        : null,
      totalNoOfMonths: data?.lineOfCredit?.locNumOfMonths,
    });

    const letterOfGuaranteeGroup = this.form?.get('letterOfGuarantee') as FormGroup;
    letterOfGuaranteeGroup?.patchValue({
      logDocUrl: data?.letterOfGuarantee.logDocUrl,
      logComment: data?.letterOfGuarantee?.logComments
    });

    if (data?.letterOfGuarantee?.logDocUrl) {
      const fileName = data?.letterOfGuarantee?.logDocUrl.split('/').pop();
      const fileExists = this.files.map(file => file.name).indexOf(fileName) === -1;
      if (fileExists) {
        const file: File = new File([], fileName); // Create a new File object
        this.files.push(file); // Push the new file
        this.uploadedFiles = this.files; // Store the updated files
      }
    }
    const productionCompanyDetails = this.form?.get(
      'productionCompany'
    ) as FormGroup;
    if (!data?.caProdCompany[0]?.billingAddressSameInd) {
      productionCompanyDetails?.patchValue({
        name: this.startApplicationData?.creditApplicationList?.companyName,
        street: data?.caProdCompany[0]?.street,
        city: data?.caProdCompany[0]?.city,
        state: data?.caProdCompany[0]?.state?.stateId,
        zipcode: data?.caProdCompany[0]?.zip,
        phone: data?.caProdCompany[0]?.phone,
        useForBillingAddress: data?.caProdCompany[0]?.billingAddressSameInd,
        billingStreet: data?.caProdCompany[1]?.street,
        billingCity: data?.caProdCompany[1]?.city,
        billingState: data?.caProdCompany[1]?.state?.stateId,
        billingZipcode: data?.caProdCompany[1]?.zip,
        billingPhone: data?.caProdCompany[1]?.phone,
      });
    }
    else {
      productionCompanyDetails?.patchValue({
        name: this.startApplicationData?.creditApplicationList?.companyName,
        street: data?.caProdCompany[0]?.street,
        city: data?.caProdCompany[0]?.city,
        state: data?.caProdCompany[0]?.state?.stateId,
        zipcode: data?.caProdCompany[0]?.zip,
        phone: data?.caProdCompany[0]?.phone,
        useForBillingAddress: data?.caProdCompany[0]?.billingAddressSameInd,
      });
    }

    const federalTaxIn = this.form?.get('federalTax');
    federalTaxIn?.patchValue({
      federalTaxTin: data?.caProdCompany[0]?.fedralTaxNum,
    });

    const accountPayableArray = this.form?.get('accountPayable') as FormArray;
    this.accountPayableContacts?.clear();
    if (data?.accountPayableContact && Array.isArray(data?.accountPayableContact) && data?.accountPayableContact?.length) {
      data.accountPayableContact.forEach(contact => {
        const contactGroup = this.fb.group({
          apFirstName: contact?.firstName,
          apLastName: contact?.lastName,
          apEmail: contact?.emailAddress,
          apPhone: contact?.telephone,
        });
        accountPayableArray?.push(contactGroup);
      });
    }
    else {
      this.addAccountPayableContact();
    }

    const companyProfile = this.form?.get('companyProfile');
    const formattedDate = this.formatDate(data?.caCompanyProfile?.dateStarted);
    companyProfile?.patchValue({
      cpProfile: data?.caCompanyProfile?.caCpType,
      cpDateStarted: formattedDate,
      cpTypeOfBusiness: data?.caCompanyProfile?.typeOfBusiness,
      cpTotalAnnualsale: data?.caCompanyProfile?.totalAnnualSales ? `$${data.caCompanyProfile.totalAnnualSales.toLocaleString('en-US')}`
        : null,
      cpNetworth: data?.caCompanyProfile?.networth ? `$${data.caCompanyProfile.networth.toLocaleString('en-US')}`
        : null,
      cpNumOfEmployees: data?.caCompanyProfile?.noOfEmployees ? `${data.caCompanyProfile.noOfEmployees.toLocaleString('en-US')}`
        : null,
    });

    const principalsArray = this.form?.get('principalsAndPartners') as FormArray;
    this.principalsAndPartnersArray?.clear();
    if (data?.partners && Array.isArray(data?.partners) && data?.partners?.length) {
      data.partners.forEach(principal => {
        const principalGroup = this.fb.group({
          ppName: principal?.partnerName,
          ppTitle: principal?.partnerTitle,
        });
        principalsArray?.push(principalGroup);
      });
    }
    else {
      this.addPrincipal();
    }

    const bankingReference = this.form?.get('bankingReference');
    bankingReference?.patchValue({
      brbankName: data?.bankingReferences?.bankName,
      brAccountNumber: data?.bankingReferences?.accountNumber,
      brContactName: data?.bankingReferences?.contactFullName,
      brTitle: data?.bankingReferences?.title,
      brEmail: data?.bankingReferences?.emailAddress,
      brPhone: data?.bankingReferences?.phoneNumber,
    });

    const tradeReference1 = this.form?.get('tradeReference1');
    tradeReference1?.patchValue({
      tr1CompanyName: data?.tradeReferences1?.trCompanyName,
      tr1ContactFirstName: data?.tradeReferences1?.trFirstName,
      tr1ContactLastName: data?.tradeReferences1?.trLastName,
      tr1Email: data?.tradeReferences1?.trEmail,
      tr1Phone: data?.tradeReferences1?.trPhone,
      tr1Street: data?.tradeReferences1?.trStreet,
      tr1City: data?.tradeReferences1?.trCity,
      tr1state: data?.tradeReferences1?.trStateId,
      tr1ZipCode: data?.tradeReferences1?.trZipCode,
      tr1DocumentUrl: data?.tradeReferences1?.trDocumentUrl
    });

    const tradeReference2 = this.form?.get('tradeReference2');
    tradeReference2?.patchValue({
      tr2CompanyName: data?.tradeReferences2?.trCompanyName,
      tr2ContactFirstName: data?.tradeReferences2?.trFirstName,
      tr2ContactLastName: data?.tradeReferences2?.trLastName,
      tr2Email: data?.tradeReferences2?.trEmail,
      tr2Phone: data?.tradeReferences2?.trPhone,
      tr2Street: data?.tradeReferences2?.trStreet,
      tr2City: data?.tradeReferences2?.trCity,
      tr2state: data?.tradeReferences2?.trStateId,
      tr2ZipCode: data?.tradeReferences2?.trZipCode,
      tr2DocumentUrl: data?.tradeReferences2?.trDocumentUrl
    });

    const tradeReference3 = this.form?.get('tradeReference3');
    tradeReference3?.patchValue({
      tr3CompanyName: data?.tradeReferences3?.trCompanyName,
      tr3ContactFirstName: data?.tradeReferences3?.trFirstName,
      tr3ContactLastName: data?.tradeReferences3?.trLastName,
      tr3Email: data?.tradeReferences3?.trEmail,
      tr3Phone: data?.tradeReferences3?.trPhone,
      tr3Street: data?.tradeReferences3?.trStreet,
      tr3City: data?.tradeReferences3?.trCity,
      tr3state: data?.tradeReferences3?.trStateId,
      tr3ZipCode: data?.tradeReferences3?.trZipCode,
      tr3DocumentUrl: data?.tradeReferences3?.trDocumentUrl
    });
  }

  /**
    * convertStringToNgbDate() - converts a date string to NgbDateStruct
    */
  convertStringToNgbDate(dateString: string): NgbDateStruct {
    if (!dateString) return null;

    const parts = dateString.split('/');
    return {
      year: parseInt(parts[2], 10),
      month: parseInt(parts[1], 10),
      day: parseInt(parts[0], 10),
    };
  }

  /**
   * nextPage() - Method to handle the action of proceeding to the next page.
   * It calls the submitCreditform1 method to handle form submission.
   */
  nextPage() {
    this.isNextButtonDisabled = true;
    if (this.customerStatus !== this.credFormOneConstant.startApplication && this.customerStatus !== this.credFormOneConstant.finishApplication && this.customerStatus !== this.credFormOneConstant.needsRevision) {
      this.nextStepEvent.emit();
    } else {
      this.submitCreditAppication();
    }

    const currentFormValues = this.form?.getRawValue();
    const isChanged = !_.isEqual(this.initialFormValues, currentFormValues);
    this.stepOneFormChange.emit(!isChanged);
  }

  /**
   * saveAndClose() - Method to handle the action of saving and closing the form.
   * It calls the submitCredit form1 method to handle form submission.
   */
  saveAndClose() {
    this.submitCreditAppication();
    this.saveAndCloseEvent.emit();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = true;
  }
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;
  }
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileOver = false;

    if (event.dataTransfer?.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }
  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.addFiles(input.files);
    }
    this.resetFileInput();  // Reset input after handling the selection
  }

  addFiles(files: FileList) {
    for (let i = 0; i < files?.length; i++) {
      this.files.push(files[i]);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  // Reset the file input to allow re-uploading of the same files
  resetFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
  * onTrFileChange() - method to upload the Trade Reference file
  */
  onTrFileChange(event: any, trOrder: number): void {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (<= 5MB)
      if (file.size > 5 * 1024 * 1024) {

        if (trOrder === INDEX.one) {
          this.tr1fileName = null;
          this.tr1ErrorMessage = this.toastMessages?.fileMoreThan100MBErrMsg;
        }
        else if (trOrder === INDEX.two) {
          this.tr2fileName = null;
          this.tr2ErrorMessage = this.toastMessages?.fileMoreThan100MBErrMsg;
        }
        else if (trOrder === INDEX.three) {
          this.tr3fileName = null;
          this.tr3ErrorMessage = this.toastMessages?.fileMoreThan100MBErrMsg;
        }
        return;
      }
      else {
        this.loaderService.setLoadingState(true);
        const formData = new FormData();
        formData.append('file', file);
        let trId;
        if (trOrder === INDEX.one) {
          this.isTr1FileUploaded = true;
          trId = this.tradeReference1ReUpload || this.startApplicationData?.creditApplicationList?.tradeReferences1?.trId;
        } else if (trOrder === INDEX.two) {
          this.isTr2FileUploaded = true;
          trId = this.tradeReference2ReUpload || this.startApplicationData?.creditApplicationList?.tradeReferences2?.trId;
        } else if (trOrder === INDEX.three) {
          this.isTr3FileUploaded = true;
          trId = this.tradeReference3ReUpload || this.startApplicationData?.creditApplicationList?.tradeReferences3?.trId;
        }
        const data = {
          caSeqId: this.caSeqId,
          trId: trId ? trId : null,
          trOrder: trOrder,
          sectionName: CREDIT_APP_REPORT_SECTION.trSection,
          isRemove: false
        };
        formData.append('data', JSON.stringify(data));
        this.creditApplicationService.uploadTradeRefference(formData).subscribe({
          next: (response: any) => {
            if (trOrder === INDEX.one) {
              this.tradeRefferenc1Data = response?.responseDTO;
              console.log(this.tradeRefferenc1Data, "tradereference one data");
              this.tr1fileName = this.tradeRefferenc1Data?.trDocumentUrl ? this.tradeRefferenc1Data?.trDocumentUrl : file.name;
              this.tr1ErrorMessage = null;
            }
            else if (trOrder === INDEX.two) {
              this.tradeRefferenc2Data = response?.responseDTO;
              this.tr2fileName =  this.tradeRefferenc2Data?.trDocumentUrl ? this.tradeRefferenc2Data?.trDocumentUrl : file.name;
              this.tr2ErrorMessage = null;
            }
            else if (trOrder === INDEX.three) {
              this.tradeRefferenc3Data = response?.responseDTO;
              this.tr3fileName =  this.tradeRefferenc3Data?.trDocumentUrl ? this.tradeRefferenc3Data?.trDocumentUrl : file.name;
              console.log(this.tr3fileName);
              this.tr3ErrorMessage = null;
            }
            this.loaderService.setLoadingState(false);
            this.successMsg = this.toastMessages?.tradeReferenceSuccess
            this.showSuccessFileUpload(this.successUploadFileTpl);
          },
          error: (error) => {
            this.loaderService.setLoadingState(false);
            console.error('Error uploading the file:', error);
            this.errorMsg = error;
            this.showDanger(this.dangerTpl);
          }
        });
      }
    }
  }

  /**
  * viewTradeRefferenceReport() - method to call  the uploadTradeReferenceFile method
  */
  viewTradeRefferenceReport(tradeReferenceDetails): void {
    console.log(tradeReferenceDetails, "trade reference details");
    const section = CREDIT_APP_REPORT_SECTION.trSection;
    this.getTradeRefferenceReport(tradeReferenceDetails, section);
  }

  /**
  * removeTradeRefferenceReport() - method to remove the uploaded Trade Reference File
  */
  removeTradeRefferenceReport(event: Event, tradeData: any, trOrder: number) {
    console.log(tradeData, "trade data");
    this.loaderService.setLoadingState(true);
    const formData = new FormData();
    let trId = null;
    if (trOrder === INDEX.one) {
      this.isTr1FileUploaded = false;
      this.tradeRefId = this.startApplicationData?.creditApplicationList?.tradeReferences1?.trId;
      this.tradeReferenceDoc = this.startApplicationData?.creditApplicationList?.tradeReferences1?.trDocumentUrl;
    } else if (trOrder === INDEX.two) {
      this.isTr2FileUploaded = false;
      this.tradeRefId = this.startApplicationData?.creditApplicationList?.tradeReferences2?.trId;
      this.tradeReferenceDoc = this.startApplicationData?.creditApplicationList?.tradeReferences2?.trDocumentUrl;
    } else if (trOrder === INDEX.three) {
      this.isTr3FileUploaded = false;
      this.tradeRefId = this.startApplicationData?.creditApplicationList?.tradeReferences3?.trId;
      this.tradeReferenceDoc = this.startApplicationData?.creditApplicationList?.tradeReferences3?.trDocumentUrl;
    }

    const data = {
      caSeqId: this.caSeqId,
      trId: tradeData ? tradeData?.trId : this.tradeRefId,
      trOrder: trOrder,
      sectionName: CREDIT_APP_REPORT_SECTION.trSection,
      isRemove: true,
      filePath: this.tradeReferenceDoc
    };

    formData.append('data', JSON.stringify(data));

    this.creditApplicationService.uploadTradeRefference(formData).subscribe({
      next: (response: any) => {
        if (trOrder === INDEX.one) {
          this.tr1fileName = '';
          this.tradeReference1ReUpload = response?.responseDTO?.trId;
        } else if (trOrder === INDEX.two) {
          this.tr2fileName = '';
          this.tradeReference2ReUpload = response?.responseDTO?.trId;
        } else if (trOrder === INDEX.three) {
          this.tr3fileName = '';
          this.tradeReference3ReUpload = response?.responseDTO?.trId;
        }

        this.loaderService.setLoadingState(false);
        this.successMsg = this.toastMessages?.tradeReferenceRemoveSuccess;
        this.showSuccessFileUpload(this.successUploadFileTpl);
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error('Error uploading the file:', error);
        this.errorMsg = error;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
  * getTradeRefferenceReport() - method to get the Certificate of Insurance through API call
  */
  getTradeRefferenceReport(url: any, section: any): void {
    this.loaderService.setLoadingState(true);
    this.sharedViewCreditAppService.getCreditAppReport(url, section)
      .pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          console.error(error);
          this.errorMsg = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        }
      })
  }

  /**
  * showSuccess() - Method to display a success toast notification.
  * @param successTpl - The template reference for the success message
  */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
  * showSuccessFileUpload() - Method to display a success toast notification.
  * @param successUploadFileTpl - The template reference for the upload file success message
  */
  showSuccessFileUpload(successUploadFileTpl: TemplateRef<any>) {
    this.toastService.show(successUploadFileTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * toggleHelpSlider() - Method to toggle the visibility of the help slider
   * 
   * This method switches the `helpSliderVisible` boolean state between `true` and `false`,
   * effectively showing or hiding the help slider.
   *  @returns {void} This method does not return any value.
   */
  toggleHelpSlider(): void {
    this.helpSliderVisible = !this.helpSliderVisible;
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any) {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
  * viewLetterOfCreditPDFDocument() - method to call the getLetterOfCreditPDFDocument
  */
  viewLetterOfCreditPDFDocument(): void {
    const section = CREDIT_APP_REPORT_SECTION.locSection;
    this.getLetterOfCreditPDFDocument(this.letterOfCreditPDFDocument?.documentUrl, section);
  }

  /**
  * getLetterOfCreditPDFDocument() - method to get the Letter of Credit PDF document through API call
  */
  getLetterOfCreditPDFDocument(url: any, section: any): void {
    this.loaderService.setLoadingState(true);
    this.sharedViewCreditAppService.getCreditAppReport(url, section)
      .pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          console.error(error);
          this.errorMsg = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        }
      });
  }

  /**
 * revisionPayload() - method to post the data after revision
 * @returns - returns payload to be sent to api
 */
  revisionPayload() {
    const data = this.revisionData?.caRevisionDetails?.applicationFormDetails;
    const formValue = this.form?.getRawValue();
    const filteredContacts = formValue?.accountPayable?.filter(contact => !contact.removed);
    const filteredPartners = formValue?.principalsAndPartners?.filter(partner => !partner.removed);

    // Initialize reviewRequired flags for each section
    let reviewRequiredLineOfCredit = true;
    let reviewRequiredLetterOfGuarantee = true;
    let reviewRequiredProductionCompany = true;
    let reviewRequiredFederalTax = true;
    let reviewRequiredAccountPayable = true;
    let reviewRequiredCompanyProfile = true;
    let reviewRequiredPartners = true;
    let reviewRequiredBankingReference = true;
    let reviewRequiredTradeReferences1 = true;
    let reviewRequiredTradeReferences2 = true;
    let reviewRequiredTradeReferences3 = true;

    // Check if the line of credit has changed
    reviewRequiredLineOfCredit = !(
      data?.lineOfCreditDetails?.locRequested === formValue.lineOfCredit.lineOfCreditRequested &&
      data?.lineOfCreditDetails?.locJobTotal === formValue.lineOfCredit.anticipatedJobTotal &&
      data?.lineOfCreditDetails?.locNumOfMonths === formValue.lineOfCredit.totalNoOfMonths
    );

    // Check if the letter of guarantee has changed
    reviewRequiredLetterOfGuarantee = !(
      data?.letterOfGuaranteeDetails?.logComments === formValue.letterOfGuarantee.logComment
    );

    // Check if the production company address has changed
    if (formValue.productionCompany.useForBillingAddress) {
      reviewRequiredProductionCompany = !(
        data?.productionCompanyAddressDetails[0]?.street === formValue.productionCompany.street &&
        data?.productionCompanyAddressDetails[0]?.city === formValue.productionCompany.city &&
        data?.productionCompanyAddressDetails[0]?.state?.stateId === formValue.productionCompany.state &&
        data?.productionCompanyAddressDetails[0]?.zip === formValue.productionCompany.zipcode &&
        data?.productionCompanyAddressDetails[0]?.phone === formValue.productionCompany.phone
      );
    } else {
      reviewRequiredProductionCompany = !(
        data?.productionCompanyAddressDetails[1]?.street === formValue.productionCompany.billingStreet &&
        data?.productionCompanyAddressDetails[1]?.city === formValue.productionCompany.billingCity &&
        data?.productionCompanyAddressDetails[1]?.state?.stateId === formValue.productionCompany.billingState &&
        data?.productionCompanyAddressDetails[1]?.zip === formValue.productionCompany.billingZipcode &&
        data?.productionCompanyAddressDetails[1]?.phone === formValue.productionCompany.billingPhone
      );
    }

    // Check if the federal tax ID has changed
    reviewRequiredFederalTax = !(
      data?.productionCompanyAddressDetails[0]?.fedralTaxNum === formValue.federalTax.federalTaxTin
    );

    // Check if the account payable contacts have changed
    reviewRequiredAccountPayable = filteredContacts.some((contact, index) => {
      const originalContact = data?.accountsPayableContactsDetails[index];
      return originalContact?.firstName !== contact.apFirstName ||
        originalContact?.lastName !== contact.apLastName ||
        originalContact?.emailAddress !== contact.apEmail ||
        originalContact?.telephone !== contact.apPhone;
    });

    // Check if the company profile has changed
    reviewRequiredCompanyProfile = !(
      data?.caCompanyProfileDetails?.caCpType === formValue.companyProfile.cpProfile &&
      data?.caCompanyProfileDetails?.dateStarted === formValue.companyProfile.cpDateStarted &&
      data?.caCompanyProfileDetails?.typeOfBusiness === formValue.companyProfile.cpTypeOfBusiness &&
      data?.caCompanyProfileDetails?.totalAnnualSales === formValue.companyProfile.cpTotalAnnualsale &&
      data?.caCompanyProfileDetails?.networth === formValue.companyProfile.cpNetworth &&
      data?.caCompanyProfileDetails?.noOfEmployees === formValue.companyProfile.cpNumOfEmployees
    );

    // Check if the partners have changed
    reviewRequiredPartners = filteredPartners.some((partner, index) => {
      const originalPartner = data?.partnersDetails[index];
      return originalPartner?.partnerName !== partner.ppName ||
        originalPartner?.partnerTitle !== partner.ppTitle;
    });

    // Check if the banking reference has changed
    reviewRequiredBankingReference = !(
      data?.bankingReferencesDetails?.bankName === formValue.bankingReference.brbankName &&
      data?.bankingReferencesDetails?.accountNumber === formValue.bankingReference.brAccountNumber &&
      data?.bankingReferencesDetails?.contactFullName === formValue.bankingReference.brContactName &&
      data?.bankingReferencesDetails?.title === formValue.bankingReference.brTitle &&
      data?.bankingReferencesDetails?.emailAddress === formValue.bankingReference.brEmail &&
      data?.bankingReferencesDetails?.phoneNumber === formValue.bankingReference.brPhone
    );

    // Check if each trade reference has changed
    reviewRequiredTradeReferences1 = !(
      data?.tradeReference1Details?.trCompanyName === formValue.tradeReference1.tr1CompanyName &&
      data?.tradeReference1Details?.trFirstName === formValue.tradeReference1.tr1ContactFirstName &&
      data?.tradeReference1Details?.trLastName === formValue.tradeReference1.tr1ContactLastName &&
      data?.tradeReference1Details?.trEmail === formValue.tradeReference1.tr1Email &&
      data?.tradeReference1Details?.trPhone === formValue.tradeReference1.tr1Phone &&
      data?.tradeReference1Details?.trCity === formValue.tradeReference1.tr1City &&
      data?.tradeReference1Details?.trStateId === formValue.tradeReference1.tr1state &&
      data?.tradeReference1Details?.trStreet === formValue.tradeReference1.tr1Street &&
      data?.tradeReference1Details?.trZipCode === formValue.tradeReference1.tr1ZipCode
    );

    reviewRequiredTradeReferences2 = !(
      data?.tradeReference2Details?.trCompanyName === formValue.tradeReference2.tr2CompanyName &&
      data?.tradeReference2Details?.trFirstName === formValue.tradeReference2.tr2ContactFirstName &&
      data?.tradeReference2Details?.trLastName === formValue.tradeReference2.tr2ContactLastName &&
      data?.tradeReference2Details?.trEmail === formValue.tradeReference2.tr2Email &&
      data?.tradeReference2Details?.trPhone === formValue.tradeReference2.tr2Phone &&
      data?.tradeReference2Details?.trCity === formValue.tradeReference2.tr2City &&
      data?.tradeReference2Details?.trStateId === formValue.tradeReference2.tr2state &&
      data?.tradeReference2Details?.trStreet === formValue.tradeReference2.tr2Street &&
      data?.tradeReference2Details?.trZipCode === formValue.tradeReference2.tr2ZipCode
    );

    reviewRequiredTradeReferences3 = !(
      data?.tradeReference3Details?.trCompanyName === formValue.tradeReference3.tr3CompanyName &&
      data?.tradeReference3Details?.trFirstName === formValue.tradeReference3.tr3ContactFirstName &&
      data?.tradeReference3Details?.trLastName === formValue.tradeReference3.tr3ContactLastName &&
      data?.tradeReference3Details?.trEmail === formValue.tradeReference3.tr3Email &&
      data?.tradeReference3Details?.trPhone === formValue.tradeReference3.tr3Phone &&
      data?.tradeReference3Details?.trCity === formValue.tradeReference3.tr3City &&
      data?.tradeReference3Details?.trStateId === formValue.tradeReference3.tr3state &&
      data?.tradeReference3Details?.trStreet === formValue.tradeReference3.tr3Street &&
      data?.tradeReference3Details?.trZipCode === formValue.tradeReference3.tr3ZipCode
    );

    const caProdCompany = [];
    if (formValue.productionCompany.useForBillingAddress) {
      caProdCompany.push({
        caProdComAddrId: this.revisionData?.caRevisionDetails?.applicationFormDetails?.productionCompanyAddressDetails[0]?.caProdComAddrId ? this.revisionData?.caRevisionDetails?.applicationFormDetails?.productionCompanyAddressDetails[0]?.caProdComAddrId : null,
        companyAddressType: {
          companyAddressTypeId: 1,
          addressType: "Production company Address"
        },
        countryId: this.selectedState?.countryId,
        StateRequest: {
          stateId: formValue.productionCompany.state ? formValue.productionCompany.state : this.selectedState?.stateId,
          stateCode: this.selectedState?.stateCode,
        },
        street: formValue?.productionCompany?.street,
        city: formValue?.productionCompany?.city,
        zip: formValue.productionCompany.zipcode,
        phone: formValue.productionCompany.phone,
        billingAddressSameInd: 1,
        companyName: formValue?.productionCompany?.name,
        fedralTaxNum: formValue?.federalTax?.federalTaxTin,
        reviewRequired: reviewRequiredProductionCompany
      });
    } else {
      // If the billing address is different, add both addresses
      caProdCompany.push({
        caProdComAddrId: this.revisionData?.caRevisionDetails?.applicationFormDetails?.productionCompanyAddressDetails[0]?.caProdComAddrId ? this.revisionData?.caRevisionDetails?.applicationFormDetails?.productionCompanyAddressDetails[0]?.caProdComAddrId : null,
        companyAddressType: {
          companyAddressTypeId: 1,
          addressType: "Production company Address"
        },
        countryId: this.selectedState?.countryId,
        StateRequest: {
          stateId: this.selectedState?.stateId,
          stateCode: this.selectedState?.stateCode,
        },
        street: formValue?.productionCompany?.street,
        city: formValue?.productionCompany?.city,
        zip: formValue.productionCompany.zipcode,
        phone: formValue.productionCompany.phone,
        billingAddressSameInd: 0,
        companyName: formValue?.productionCompany?.name,
        fedralTaxNum: formValue?.federalTax?.federalTaxTin,
        reviewRequired: reviewRequiredProductionCompany
      });

      // Add billing address
      caProdCompany.push({
        caProdComAddrId: this.revisionData?.caRevisionDetails?.applicationFormDetails?.productionCompanyAddressDetails[1]?.caProdComAddrId ? this.revisionData?.caRevisionDetails?.applicationFormDetails?.productionCompanyAddressDetails[1]?.caProdComAddrId : null,
        companyAddressType: {
          companyAddressTypeId: 2,
          addressType: "billing Address"
        },
        countryId: this.selectedBillingState?.countryId,
        StateRequest: {
          stateId: this.selectedBillingState?.stateId,
          stateCode: this.selectedBillingState?.stateCode,
        },
        street: formValue?.productionCompany?.billingStreet,
        city: formValue?.productionCompany?.billingCity,
        zip: formValue.productionCompany.billingZipcode,
        phone: formValue.productionCompany.billingPhone,
        billingAddressSameInd: 0,
        companyName: formValue?.productionCompany?.name,
        fedralTaxNum: formValue?.federalTax?.federalTaxTin,
        reviewRequired: reviewRequiredProductionCompany
      });
    }
    let payload = {
      caSeqNumber: this.caSeqId,
      caNumber: this.caNumber,
      projectId: this.projectId,
      companyId: this.companyId,
      locId: data?.lineOfCreditDetails?.locId,
      logId: data?.letterOfGuaranteeDetails?.logId,
      companyProfileId: data?.caCompanyProfileDetails?.caCompanyProfileId,
      brId: data?.bankingReferencesDetails?.brId,

      lineOfCreditRequest: {
        locId: data?.lineOfCreditDetails?.locId ? data?.lineOfCreditDetails?.locId : null,
        locRequested: formValue.lineOfCredit.lineOfCreditRequested ? parseInt(formValue.lineOfCredit.lineOfCreditRequested?.toString()?.replace(/[$,]/g, '')) : 0,
        locJobTotal: formValue.lineOfCredit.anticipatedJobTotal ? parseInt(formValue.lineOfCredit.anticipatedJobTotal?.toString()?.replace(/[$,]/g, '')) : 0,
        locNumOfMonths: formValue.lineOfCredit.totalNoOfMonths ? +formValue.lineOfCredit.totalNoOfMonths : 0,
        reviewRequired: reviewRequiredLineOfCredit,
      },

      letterOfGuaranteeRequest: {
        logId: data?.letterOfGuaranteeDetails?.logId ? data?.letterOfGuaranteeDetails?.logId : null,
        logComments: formValue.letterOfGuarantee.logComment,
        logDocUrl: this.logResponseDetails?.responseDTO?.logDocUrl,
        reviewRequired: reviewRequiredLetterOfGuarantee,
      },

      productionCompanyRequest: caProdCompany,

      accountPayableRequest: this.contactData.map((contact, index) => ({
        accountsPayableContactId: contact?.accountsPayableContactId ? contact?.accountsPayableContactId : null,
        firstName: formValue.accountPayable[index]?.apFirstName,
        lastName: formValue.accountPayable[index]?.apLastName,
        emailAddress: formValue.accountPayable[index]?.apEmail,
        telephone: formValue.accountPayable[index]?.apPhone,
        reviewRequired: reviewRequiredAccountPayable,
      })),

      companyProfileRequest: {
        companyProfileId: data?.caCompanyProfileDetails?.caCompanyProfileId,
        caCpType: formValue.companyProfile.cpProfile,
        dateStarted: formValue.companyProfile.cpDateStarted ? formValue.companyProfile.cpDateStarted : null,
        typeOfBusiness: formValue.companyProfile.cpTypeOfBusiness,
        totalAnnualSales: parseInt(formValue.companyProfile.cpTotalAnnualsale?.toString()?.replace(/[$,]/g, '')),
        networth: parseInt(formValue.companyProfile.cpNetworth?.toString()?.replace(/[$,]/g, '')),
        noOfEmployees: parseInt(formValue.companyProfile.cpNumOfEmployees?.toString()?.replace(/[,]/g, '')),
        reviewRequired: reviewRequiredCompanyProfile,
      },

      partnersRequest: this.partnersData.map((partner, index) => ({
        partnersId: partner?.partnersId ? partner?.partnersId : null,
        partnerName: formValue?.principalsAndPartners[index]?.ppName,
        partnerTitle: formValue?.principalsAndPartners[index]?.ppTitle,
        reviewRequired: reviewRequiredPartners
      })),

      bankReferenceRequest: {
        brId: data?.bankingReferencesDetails?.brId,
        bankName: formValue.bankingReference.brbankName,
        accountNumber: formValue.bankingReference.brAccountNumber,
        contactFullName: formValue.bankingReference.brContactName,
        title: formValue.bankingReference.brTitle,
        emailAddress: formValue.bankingReference.brEmail,
        phoneNumber: formValue.bankingReference.brPhone,
        reviewRequired: reviewRequiredBankingReference,
      },

      tradeReferencesRequest: [
        {
          trId: data?.tradeReference1Details?.trId,
          trCompanyName: formValue.tradeReference1.tr1CompanyName,
          trFirstName: formValue.tradeReference1.tr1ContactFirstName,
          trLastName: formValue.tradeReference1.tr1ContactLastName,
          trEmail: formValue.tradeReference1.tr1Email,
          trPhone: formValue.tradeReference1.tr1Phone,
          trCity: formValue.tradeReference1.tr1City,
          trStateId: this.selectedTrOneState?.stateId,
          trStreet: formValue.tradeReference1.tr1Street,
          trZipCode: formValue.tradeReference1.tr1ZipCode,
          trDocumentUrl: this.tr1fileName ? this.tr1fileName : this.applicationData?.creditApplicationList?.tradeReferences1?.trDocumentUrl,
          reviewRequired: reviewRequiredTradeReferences1,
        },
        {
          trId: data?.tradeReference2Details?.trId,
          trCompanyName: formValue.tradeReference2.tr2CompanyName,
          trFirstName: formValue.tradeReference2.tr2ContactFirstName,
          trLastName: formValue.tradeReference2.tr2ContactLastName,
          trEmail: formValue.tradeReference2.tr2Email,
          trPhone: formValue.tradeReference2.tr2Phone,
          trCity: formValue.tradeReference2.tr2City,
          trStateId: this.selectedTrTwoState?.stateId,
          trStreet: formValue.tradeReference2.tr2Street,
          trZipCode: formValue.tradeReference2.tr2ZipCode,
          trDocumentUrl: this.tr2fileName ? this.tr2fileName : this.applicationData?.creditApplicationList?.tradeReferences2?.trDocumentUrl,
          reviewRequired: reviewRequiredTradeReferences2,
        },
        {
          trId: data?.tradeReference3Details?.trId,
          trCompanyName: formValue.tradeReference3.tr3CompanyName,
          trFirstName: formValue.tradeReference3.tr3ContactFirstName,
          trLastName: formValue.tradeReference3.tr3ContactLastName,
          trEmail: formValue.tradeReference3.tr3Email,
          trPhone: formValue.tradeReference3.tr3Phone,
          trCity: formValue.tradeReference3.tr3City,
          trStateId: this.selectedTrThreeState?.stateId,
          trStreet: formValue.tradeReference3.tr3Street,
          trZipCode: formValue.tradeReference3.tr3ZipCode,
          trDocumentUrl: this.tr3fileName ? this.tr3fileName : this.applicationData?.creditApplicationList?.tradeReferences3?.trDocumentUrl,
          reviewRequired: reviewRequiredTradeReferences3,
        },
      ],
    };

    return payload;
  }

  /**
   * closeModal() - method to close the modal
   */
  closeModal() {
    this.saveAndCloseEvent.emit();
  }

  /**
   * saveAndCloseRevisions() - method to save and close revisions
   */
  saveAndCloseRevisions() {
    this.submitRevisionApplication();
    this.saveAndCloseEvent.emit();
  }

  /**
   * nextReviewApplication() - method to submit the revisions and move to next step
   */
  nextReviewApplication() {
    this.submitRevisionApplication();
    this.nextStepEvent.emit();
    this.formSubmittedEvent.emit();
  }

  /**
   * submitRevisionApplication() - method to submit the credit application step1 revisions
   */
  submitRevisionApplication(): void {
    this.loaderService.setLoadingState(true);
    let revisionPayload = this.revisionPayload();
    this.creditApplicationService
      .postCreditApplication(revisionPayload)
      .subscribe({
        next: (response) => {
          this.loaderService.setLoadingState(false);
          this.successMsg = this.toastMessages.revisionApplication;
          this.showSuccess(this.successUploadFileTpl);
        },
        error: (error) => {
          console.error(error);
          this.loaderService.setLoadingState(false);
          this.errorMsg = error.errorMessage;
          this.showDanger(this.dangerTpl);
        },
      });
  }

  /**
   * scroll() - method to scroll to a particular section
   * @param key - the section to be scrolled to 
   */
  scroll(key: string): void {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.warn(`Element with ID '${key}' not found.`);
    }
  }

  /**

   * formatCurrency() - method to format currency
   * @param event - an event to trigger this function
   * @param controlName - control name
   * @param formName - form name
   */
  formatCurrency(event: any, controlName: string, formName: string): void {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    if (inputValue) {
      const parts = inputValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      inputValue = parts.length > 1 ? parts[0] + '.' + parts[1] : parts[0];
    }
    inputValue = '$' + inputValue;
    event.target.value = inputValue;
    this.form.get(`${formName}.${controlName}`).setValue(inputValue);
  }

  /*patchRevisionTraderefDocuments() - method to patch the trade reference uploaded document on revision stage
  * @param revisionData revision data
  */
  patchRevisionTraderefDocuments(revisionData: any): void {
    if (revisionData?.tradeReference1Details?.trDocumentUrl) {
      this.tradeRefferenc1Data = revisionData?.tradeReference1Details;
      this.tr1fileName = revisionData?.tradeReference1Details?.trDocumentUrl;
    }
    if (revisionData?.tradeReference2Details?.trDocumentUrl) {
      this.tradeRefferenc2Data = revisionData?.tradeReference2Details;
      this.tr2fileName = revisionData?.tradeReference2Details?.trDocumentUrl;
    }
    if (revisionData?.tradeReference3Details?.trDocumentUrl) {
      this.tradeRefferenc3Data = revisionData?.tradeReference3Details;
      this.tr3fileName = revisionData?.tradeReference3Details?.trDocumentUrl;
    }
  }

  /* patchInitialTraderefDocuments() - method to patch the trade reference uploaded document on initial stage
  * @param appData application form data
  */
  patchInitialTraderefDocuments(appData: any): void {
    if (appData?.tradeReferences1?.trDocumentUrl) {
      this.tradeRefferenc1Data = appData?.tradeReferences1;
      this.tr1fileName = appData?.tradeReferences1?.trDocumentUrl;
    }
    if (appData?.tradeReferences2?.trDocumentUrl) {
      this.tradeRefferenc2Data = appData?.tradeReferences2;
      this.tr2fileName = appData?.tradeReferences2?.trDocumentUrl;
    }
    if (appData?.tradeReferences3?.trDocumentUrl) {
      this.tradeRefferenc3Data = appData?.tradeReferences3;
      this.tr3fileName = appData?.tradeReferences3?.trDocumentUrl;
    }
  }

}