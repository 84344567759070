<div class="container cm-main-container px-0 mx-0">
  <div class="alert revision-alert" role="alert" *ngIf="reviewRequiredSections?.length">
    <div class="container">
      <div class="row">
        <div class="wbd-alert-content my-2">
          Revisions needed:
        </div>
      </div>
      <div class="row">
        <div class="wbd-alert-content-list">
          <ul>
            <li *ngFor="let section of reviewRequiredSections">
              <span>{{ section.label }}</span>&nbsp;
              <span class="div-anch">
                <a class="wbd-action text-link" (click)="scroll(section.key)">Scroll to section</a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="common-modal-input-section grey-bg" id="lineOfCredit">
      <div formGroupName="lineOfCredit">
        <h2 class="section-main-header">Line of Credit<sup class="text-danger">*</sup></h2>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="locMessage">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ locMessage }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div class="row">
            <div class="col-md-4 custom-floating-label">
              <label for="lnCreditReq" class="form-label">Line of credit requested</label>
              <input type="text" class="form-control" placeholder="$ Line of credit requested" id="lnCreditReq"
                formControlName="lineOfCreditRequested"
                [value]="form.get('lineOfCredit.lineOfCreditRequested')?.value" (input)="formatCurrency($event,'lineOfCreditRequested','lineOfCredit')">
              <small class="text-danger"
                *ngIf="form.get('lineOfCredit.lineOfCreditRequested').invalid && (form.get('lineOfCredit.lineOfCreditRequested').touched || form.get('lineOfCredit.lineOfCreditRequested').dirty)">
                <div *ngIf="form.get('lineOfCredit.lineOfCreditRequested').errors['required']">{{
                  credFormOne.fieldRequired }}</div>
                <div *ngIf="form.get('lineOfCredit.lineOfCreditRequested').errors['maxlength']">Max length exceeded
                </div>
                <div *ngIf="form.get('lineOfCredit.lineOfCreditRequested').errors['invalidno']">{{
                  credFormOne.currencyInvalid }}</div>
              </small>
            </div>
            <div class="col-md-4 custom-floating-label">
              <label for="AntiJobTotal" class="form-label">Anticipated Job Total</label>
              <input type="text" class="form-control" placeholder="$ Anticipated Job Total" id="AntiJobTotal"
                formControlName="anticipatedJobTotal"
                [value]="form.get('lineOfCredit.anticipatedJobTotal')?.value" (input)="formatCurrency($event,'anticipatedJobTotal','lineOfCredit')">
              <small class="text-danger"
                *ngIf="form.get('lineOfCredit.anticipatedJobTotal').invalid && (form.get('lineOfCredit.anticipatedJobTotal').touched || form.get('lineOfCredit.anticipatedJobTotal').dirty)">
                <div *ngIf="form.get('lineOfCredit.anticipatedJobTotal').errors['required']">{{
                  credFormOne.fieldRequired }}</div>
                <div *ngIf="form.get('lineOfCredit.anticipatedJobTotal').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="form.get('lineOfCredit.anticipatedJobTotal').errors['invalidno']">{{
                  credFormOne.currencyInvalid }}</div>
              </small>
            </div>
            <div class="col-md-4 custom-floating-label">
              <label for="totalOfMonth" class="form-label">Total # of months</label>
              <input type="text" class="form-control" placeholder="Total # of months" id="totalOfMonth"
                formControlName="totalNoOfMonths">
              <small class="text-danger"
                *ngIf="form.get('lineOfCredit.totalNoOfMonths').invalid && (form.get('lineOfCredit.totalNoOfMonths').touched || form.get('lineOfCredit.totalNoOfMonths').dirty)">
                <div *ngIf="form.get('lineOfCredit.totalNoOfMonths').errors['required']">{{ credFormOne.fieldRequired }}
                </div>
                <div *ngIf="form.get('lineOfCredit.totalNoOfMonths').errors['maxlength']">Max length exceeded</div>
              </small>
              <small class="text-danger"
              *ngIf="form.get('lineOfCredit.totalNoOfMonths').invalid && form.get('lineOfCredit.totalNoOfMonths').dirty">
                <div *ngIf="form.get('lineOfCredit.totalNoOfMonths').errors['invalidno']">{{
                  credFormOne.totalNoOfMonthsInvalid }}</div>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="letterOfGuarantee" class="common-modal-input-section" id="letterOfGuarantee">
      <h2 class="section-main-header custom-cred-header">Letter of Credit / Guarantee</h2>
      <div class="container cm-main-container px-0 mx-0">
        <div class="alert revision-alert" role="alert" *ngIf="logMessage">
          <div class="container">
            <div class="row">
              <div class="font-weight-bold my-2">
                "{{ logMessage }}"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 custom-floating-label my-4">
          <app-upload-file [disabled]="disableLetterOfCredit"  [allowOnlySingleFile]="true" (filesUpdated)="onFilesUpdated($event)" [viewFileExternal]="true" [section]="'letterofguarantee'" (viewFileCallback)="openPDF()" (filesChanged)="onFileRemoved($event)"
            [uploadedFiles]="uploadedFiles"></app-upload-file>
        </div>
        <div class="col-md-12 custom-floating-label">
          <label for="LtofCreditComment" class="form-label"> Comments</label>
          <input type="text" class="form-control" placeholder="Comments" id="LtofCreditComment"
            formControlName="logComment">
        </div>
        <!-- Section to view the Uploaded Document from the API -->
        <div class="row pt-3" *ngIf="letterOfCreditPDFDocument">
          <div class="col-md-6"><span><img src="../assets/images/pdf-icon.svg" alt="">
              {{letterOfCreditPDFDocument?.name}}
            </span></div>
          <div class="col-md-6 text-end"><a (click)="viewLetterOfCreditPDFDocument()"
              class="text-link">{{credFormOneConstant?.view}}</a></div>
        </div>
      </div>
    </div>

    <div formGroupName="productionCompany">
      <div class="common-modal-input-section" id="productionCompany">
        <h2 class="section-main-header custom-cred-header">Production Company<sup class="text-danger">*</sup></h2>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="prodComMessage">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ prodComMessage }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <p class="form-section-header mt-0 mb-4">Name:</p>
          </div>

          <div class="col-md-12 custom-floating-label input-icon">
            <label for="prodComName" class="form-label"> Name</label>
            <input type="text" class="form-control" placeholder="What is the production legal name?" id="prodComName"
              formControlName="name">
            <span class="help-icon me-1 clickable" (click)="toggleHelpSlider()">
            </span>
            <small class="text-danger"
              *ngIf="form.get('productionCompany.name').invalid && (form.get('productionCompany.name').touched || form.get('productionCompany.name').dirty)">
              <div *ngIf="form.get('productionCompany.name').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.name').errors['maxlength']">Max length exceeeded</div>
              <div *ngIf="form.get('productionCompany.name').errors['invalidName']">{{ credFormOne.nameInvalid }}
              </div>
            </small>
          </div>

          <div class="col-md-12 mt-5">
            <p class="form-section-header mt-0 mb-4">Address:</p>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="prodComStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="prodComStreet" formControlName="street">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.street').invalid && (form.get('productionCompany.street').touched || form.get('productionCompany.street').dirty)">
              <div *ngIf="form.get('productionCompany.street').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.street').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('productionCompany.street').errors['invalidName']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" class="form-control" placeholder="City" id="prodComCity" formControlName="city">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.city').invalid && (form.get('productionCompany.city').touched || form.get('productionCompany.city').dirty)">
              <div *ngIf="form.get('productionCompany.city').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.city').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('productionCompany.city').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="state" (change)="onStateChange()">
                  <option class="dropdown-option" value="">State</option>
                  <option class="dropdown-option" *ngFor="let state of states" [value]="state?.stateId">{{ state.stateName }}</option>
                </select>
                <small class="text-danger" *ngIf="form.get('productionCompany.state').invalid">
                  <div *ngIf="form.get('productionCompany.state').errors['required']">{{ credFormOne.fieldRequired
                    }}</div>
                </small>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" class="form-control" placeholder="ZIP Code" id="prodComZipcode"
                  formControlName="zipcode">
                <small class="text-danger"
                  *ngIf="form.get('productionCompany.zipcode').invalid && (form.get('productionCompany.zipcode').touched || form.get('productionCompany.zipcode').dirty)">
                  <div *ngIf="form.get('productionCompany.zipcode').errors['required']">{{ credFormOne.fieldRequired }}
                  </div>
                  <div *ngIf="form.get('productionCompany.zipcode').errors['maxlength']">Max length exceeded</div>
                  <div *ngIf="form.get('productionCompany.zipcode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="prodComPhone" formControlName="phone">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.phone').invalid && (form.get('productionCompany.phone').touched || form.get('productionCompany.phone').dirty)">
              <div *ngIf="form.get('productionCompany.phone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.phone').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('productionCompany.phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12">
            <label class="custom-checkbox" for="prodComBillingAddress">
              <input type="checkbox" id="prodComBillingAddress" formControlName="useForBillingAddress"
                (change)="onBillingAddressToggle()">
              <span class="checkmark"></span> Use for billing address
            </label>
          </div>
        </div>
      </div>
      <div class="common-modal-input-section" *ngIf="!form.get('productionCompany.useForBillingAddress').value"
        id="billingAddress">
        <h2 class="section-main-header custom-cred-header mb-5">Billing Address<sup class="text-danger">*</sup></h2>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="billingMessage">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ billingMessage }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 custom-floating-label">
            <label for="prodComStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="prodComStreet"
              formControlName="billingStreet">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.billingStreet').invalid && (form.get('productionCompany.billingStreet').touched || form.get('productionCompany.billingStreet').dirty)">
              <div *ngIf="form.get('productionCompany.billingStreet').errors['required']">{{ credFormOne.fieldRequired
                }}</div>
              <div *ngIf="form.get('productionCompany.billingStreet').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('productionCompany.billingStreet').errors['invalidName']">{{
                credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" class="form-control" placeholder="City" id="prodComCity" formControlName="billingCity">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.billingCity').invalid && (form.get('productionCompany.billingCity').touched || form.get('productionCompany.billingCity').dirty)">
              <div *ngIf="form.get('productionCompany.billingCity').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('productionCompany.billingCity').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('productionCompany.billingCity').errors['invalidCity']">{{ credFormOne.cityInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="billingState"
                  (change)="onBillingStateChange()">
                  <option class="dropdown-option" value="">State</option>
                  <option class="dropdown-option" *ngFor="let state of states" [value]="state?.stateId">{{ state.stateName }}</option>
                </select>
                <small class="text-danger" *ngIf="form.get('productionCompany.billingState').invalid">
                  <div *ngIf="form.get('productionCompany.billingState').errors['required']">{{
                    credFormOne.fieldRequired
                    }}</div>
                </small>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" class="form-control" placeholder="ZIP Code" id="prodComZipcode"
                  formControlName="billingZipcode">
                <small class="text-danger"
                  *ngIf="form.get('productionCompany.billingZipcode').invalid && (form.get('productionCompany.billingZipcode').touched || form.get('productionCompany.billingZipcode').dirty)">
                  <div *ngIf="form.get('productionCompany.billingZipcode').errors['required']">{{ credFormOne.fieldRequired }}
                  </div>
                  <div *ngIf="form.get('productionCompany?.billingZipcode').errors['maxlength']">Max length exceeded</div>
                  <div *ngIf="form.get('productionCompany?.billingZipcode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="prodComPhone"
              formControlName="billingPhone">
            <small class="text-danger"
              *ngIf="form.get('productionCompany.billingPhone').invalid && (form.get('productionCompany.billingPhone').touched || form.get('productionCompany.billingPhone').dirty)">
              <div *ngIf="form.get('productionCompany.billingPhone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('productionCompany.billingPhone').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('productionCompany.billingPhone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="common-modal-input-section" id="federalTax">
      <div formGroupName="federalTax">
        <h2 class="section-main-header">Federal Tax ID #<sup class="text-danger">*</sup></h2>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="fedTaxMessage">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ fedTaxMessage }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6 custom-floating-label input-icon">
            <label for="federalTaxTIN" class="form-label"> TIN</label>
            <input type="text" class="form-control" placeholder="TIN" id="federalTaxTIN"
              formControlName="federalTaxTin">
            <small class="text-danger"
              *ngIf="form.get('federalTax.federalTaxTin').invalid && (form.get('federalTax.federalTaxTin').touched || form.get('federalTax.federalTaxTin').dirty)">
              <div *ngIf="form.get('federalTax.federalTaxTin').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('federalTax.federalTaxTin').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('federalTax.federalTaxTin').errors['invalidTin']">{{ credFormOne.tinInvalid }}</div>
            </small>
            <span class="help-icon me-1 clickable" (click)="toggleHelpSlider()"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="common-modal-input-section" id="accountPayableForm">
      <h2 class="section-main-header">Account Payable Contact<sup class="text-danger">*</sup></h2>
      <div class="container cm-main-container px-0 mx-0">
        <div class="alert revision-alert" role="alert" *ngIf="accountPayableMessage">
          <div class="container">
            <div class="row">
              <div class="font-weight-bold my-2">
                "{{ accountPayableMessage }}"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="accountPayable">
        <div *ngFor="let contact of accountPayableContacts.controls; let i = index" [formGroupName]="i">
          <div class="row mt-5">
            <div class="col-md-6 custom-floating-label">
              <label for="accPayContFName{{i}}" class="form-label"> First Name</label>
              <input type="text" class="form-control" placeholder="First Name" id="accPayContFName{{i}}"
                formControlName="apFirstName">
              <small class="text-danger"
                *ngIf="contact.get('apFirstName').invalid && (contact.get('apFirstName').touched || contact.get('apFirstName').dirty)">
                <div *ngIf="contact.get('apFirstName').errors['required']">{{ credFormOne.fieldRequired }}</div>
                <div *ngIf="contact.get('apFirstName').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="contact.get('apFirstName').errors['invalidAlphabets']">{{ credFormOne.firstNameInvalid }}
                </div>
              </small>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="accPayContLName{{i}}" class="form-label"> Last Name</label>
              <input type="text" class="form-control" placeholder="Last Name" id="accPayContLName{{i}}"
                formControlName="apLastName">
              <small class="text-danger"
                *ngIf="contact.get('apLastName').invalid && (contact.get('apLastName').touched || contact.get('apLastName').dirty)">
                <div *ngIf="contact.get('apLastName').errors['required']">{{ credFormOne.fieldRequired }}</div>
                <div *ngIf="contact.get('apLastName').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="contact.get('apLastName').errors['invalidAlphabets']">{{ credFormOne.lastNameInvalid }}
                </div>
              </small>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="accPayContEmail{{i}}" class="form-label"> Email</label>
              <input type="text" class="form-control" placeholder="Email" id="accPayContEmail{{i}}"
                formControlName="apEmail">
              <small class="text-danger"
                *ngIf="contact.get('apEmail').invalid && (contact.get('apEmail').touched || contact.get('apEmail').dirty)">
                <div *ngIf="contact.get('apEmail').errors['required']">{{ credFormOne.fieldRequired }}</div>
                <div *ngIf="contact.get('apEmail').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="contact.get('apEmail').errors['invalidEmail']">{{ credFormOne.emailInvalid }}</div>
              </small>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="accPayContPhone{{i}}" class="form-label"> Phone</label>
              <input type="text" class="form-control" placeholder="Phone" id="accPayContPhone{{i}}"
                formControlName="apPhone">
              <small class="text-danger"
                *ngIf="contact.get('apPhone').invalid && (contact.get('apPhone').touched || contact.get('apPhone').dirty)">
                <div *ngIf="contact.get('apPhone').errors['required']">{{ credFormOne.fieldRequired }}</div>
                <div *ngIf="contact.get('apPhone').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="contact.get('apPhone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}</div>
              </small>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label text-end">
            <p class="remove-contact"
            (click)="removeAccountPayableContact(i)"
            [ngClass]="{
              'remove-contact-disabled': accountPayableContacts?.length <= 1 || (customerStatus === 'UNDER REVIEW' || customerStatus === 'Application Submitted') || !this.revisionData?.caRevisionDetails?.applicationFormDetails?.accountsPayableContactsDetails.reviewRequired,
              'remove-contact-blue': accountPayableContacts?.length > 1 && customerStatus !== 'UNDER REVIEW' && customerStatus !== 'Application Submitted' || this.revisionData?.caRevisionDetails?.applicationFormDetails?.accountsPayableContactsDetails.reviewRequired
            }">
            Remove Contact
          </p>
          </div>
          <hr *ngIf="accountPayableContacts.length > 1 && i < accountPayableContacts.length - 1" />
        </div>
      </div>
      <div class="col-md-12 custom-floating-label my-4">
        <span class="custom-remove-contact">
          <span class="fw-semibold" [ngClass]="{
            'text-link':!disableAccountPayable
          }" (click)="!disableAccountPayable ? addAccountPayableContact() : $event.preventDefault()">+ Add a Contact</span>
        </span>      
      </div>
    </div>

    <div class="common-modal-input-section" id="companyProfile">
      <div formGroupName="companyProfile">
        <h2 class="section-main-header">Company Profile</h2>
        <div class="container cm-main-container px-0 mx-0 mb-4">
          <div class="alert revision-alert" role="alert" *ngIf="companyMessage">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ companyMessage }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="form-section-header mt-2 mb-0">Select profile:</p>
          </div>
          <div class="row px-4">
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-4">
              <div class="common-radio-btn">
                <input class="common-radio-input mb-0" type="radio" name="cpProfile" formControlName="cpProfile"
                  id="Corporation" value="Corporation" />
                <label class="common-radio-label">
                  Corporation
                </label>
              </div>
              <div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-4">
              <div class="common-radio-btn">
                <input class="common-radio-input mb-0" type="radio" name="cpProfile" formControlName="cpProfile"
                  id="Proprietorship" value="Proprietorship" />
                <label class="common-radio-label">
                  Proprietorship
                </label>
              </div>
              <div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-4">
              <div class="common-radio-btn">
                <input class="common-radio-input mb-0" type="radio" name="cpProfile" id="tele"
                  formControlName="cpProfile" value="Partnership" />
                <label class="common-radio-label">
                  Partnership
                </label>
              </div>
              <div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-4">
              <div class="common-radio-btn">
                <input class="common-radio-input mb-0" type="radio" name="cpProfile" id="feature"
                  formControlName="cpProfile" value="LLC" />
                <label class="common-radio-label">
                  LLC
                </label>
              </div>
              <div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-sm-6 col-6 px-0 mt-4">
              <div class="common-radio-btn">
                <input class="common-radio-input mb-0" type="radio" name="cpProfile" id="Other"
                  formControlName="cpProfile" value="Other" />
                <label class="common-radio-label">
                  Other
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <p class="form-section-header mt-4 mb-3">Business info:</p>
          </div>
          <div class=" col-md-6 custom-floating-label mt-3" id="datepicker">
            <label for="comProfileDateStarted" class="form-label">Date Started</label>
            <input type="date" class="form-control d-block" placeholder="Date Started" formControlName="cpDateStarted"
              id="date" />
          </div>
          <div class="col-md-6 custom-floating-label mt-3">
            <label for="comProfileTypBusiness" class="form-label">Type of Business</label>
            <input type="text" class="form-control" placeholder="Type of Business" id="comProfileTypBusiness"
              formControlName="cpTypeOfBusiness">
            <small class="text-danger"
              *ngIf="form.get('companyProfile.cpTypeOfBusiness').invalid && (form.get('companyProfile.cpTypeOfBusiness').touched || form.get('companyProfile.cpTypeOfBusiness').dirty)">
              <div *ngIf="form.get('companyProfile.cpTypeOfBusiness').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('companyProfile.cpTypeOfBusiness').errors['invalidChars']">{{
                credFormOne.TypeOfBusinessInvalid }}</div>
            </small>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6 custom-floating-label mt-3">
                    <label for="comProfiletotalAnnSales" class="form-label">Total Annual Sales</label>
                    <input type="text" class="form-control" placeholder="$ Total Annual Sales"
                      id="comProfiletotalAnnSales" formControlName="cpTotalAnnualsale" (input)="formatCurrency($event,'cpTotalAnnualsale','companyProfile')">
                    <small class="text-danger"
                      *ngIf="form.get('companyProfile.cpTotalAnnualsale').invalid && (form.get('companyProfile.cpTotalAnnualsale').touched || form.get('companyProfile.cpTotalAnnualsale').dirty)">
                      <div *ngIf="form.get('companyProfile.cpTotalAnnualsale').errors['invalidno']">{{
                        credFormOne.currencyInvalid }}</div>
                    </small>
                  </div>
                  <div class="col-md-6 custom-floating-label mt-3">
                    <label for="comProfileNetworth" class="form-label">Networth</label>
                    <input type="text" class="form-control" placeholder="$ Networth" id="comProfileNetworth"
                      formControlName="cpNetworth" (input)="formatCurrency($event,'cpNetworth','companyProfile')">
                    <small class="text-danger"
                      *ngIf="form.get('companyProfile.cpNetworth').invalid && (form.get('companyProfile.cpNetworth').touched || form.get('companyProfile.cpNetworth').dirty)">
                      <div *ngIf="form.get('companyProfile.cpNetworth').errors['invalidno']">{{
                        credFormOne.currencyInvalid }}</div>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-md-3 custom-floating-label mt-3">
                <label for="comProfileNoofEmployees" class="form-label"># of Employees</label>
                <input type="text" class="form-control" placeholder="# of Employees" id="comProfileNoofEmployees"
                  formControlName="cpNumOfEmployees"
                  [value]="form.get('companyProfile.cpNumOfEmployees')?.value | numberFormat" >
                <small class="text-danger"
                  *ngIf="form.get('companyProfile.cpNumOfEmployees').invalid && (form.get('companyProfile.cpNumOfEmployees').touched || form.get('companyProfile.cpNumOfEmployees').dirty)">
                  <div *ngIf="form.get('companyProfile.cpNumOfEmployees').errors['maxlength']">Max length exceeded</div>
                  <div *ngIf="form.get('companyProfile.cpNumOfEmployees').errors['invalidno']">{{
                    credFormOne.formatInvalid }}</div>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="common-modal-input-section" id="principalsAndPartners">
      <h2 class="section-main-header">Principals/Officers or Partners</h2>
      <div class="container cm-main-container px-0 mx-0">
        <div class="alert revision-alert" role="alert" *ngIf="partnerMessage">
          <div class="container">
            <div class="row">
              <div class="font-weight-bold my-2">
                "{{ partnerMessage }}""
              </div>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="principalsAndPartners">
        <div *ngFor="let principal of principalsAndPartnersArray.controls; let i = index" [formGroupName]="i">
          <div class="row mt-5">
            <div class="col-md-6 custom-floating-label">
              <label for="principalName{{i}}" class="form-label">Name</label>
              <input type="text" class="form-control" placeholder="Name" id="principalName{{i}}"
                formControlName="ppName">
              <small class="text-danger"
                *ngIf="principal.get('ppName').invalid && (principal.get('ppName').touched || principal.get('ppName').dirty)">
                <div *ngIf="principal.get('ppName').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="principal.get('ppName').errors['invalidAlphabets']">{{ credFormOne.nameInvalid }}</div>
              </small>
            </div>
            <div class="col-md-6 custom-floating-label">
              <label for="principalTitle{{i}}" class="form-label">Title</label>
              <input type="text" class="form-control" placeholder="Title" id="principalTitle{{i}}"
                formControlName="ppTitle">
              <small class="text-danger"
                *ngIf="principal.get('ppTitle').invalid && (principal.get('ppTitle').touched || principal.get('ppTitle').dirty)">
                <div *ngIf="principal.get('ppTitle').errors['maxlength']">Max length exceeded</div>
                <div *ngIf="principal.get('ppTitle').errors['invalidName']">{{ credFormOne.titleInvalid }}</div>
              </small>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label text-end">
            <p class="remove-contact" (click)="removePartner(i)" [ngClass]="{
                 'remove-contact-disabled': principalsAndPartnersArray?.length <= 1 || (customerStatus === 'UNDER REVIEW' || customerStatus === 'Application Submitted') || !this.revisionData?.caRevisionDetails?.applicationFormDetails?.partnersDetails.reviewRequired,
                 'remove-contact-blue': principalsAndPartnersArray?.length > 1  && customerStatus !== 'UNDER REVIEW' && customerStatus !== 'Application Submitted' || this.revisionData?.caRevisionDetails?.applicationFormDetails?.partnersDetails.reviewRequired
               }">
              Remove Partner
            </p>
          </div>
          <hr *ngIf="principalsAndPartnersArray.length > 1 && i < principalsAndPartnersArray.length - 1" />
        </div>
      </div>
      <div class="col-md-12 custom-floating-label my-4">
        <span class="custom-remove-contact">
          <span
            class="fw-semibold"
            [ngClass]="{
              'text-link': !disableprinciple
            }" (click)="!disableprinciple ? addPrincipal() : $event.preventDefault()">
            + Add Principal
          </span>
        </span>      
      </div>
    </div>

    <div class="common-modal-input-section" id="bankingReference">
      <div formGroupName="bankingReference">
        <h2 class="section-main-header">Banking Reference</h2>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="bankingMessage">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ bankingMessage }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefBnkName" class="form-label">Bank Name</label>
            <input type="text" class="form-control" placeholder="Bank Name" id="bankingRefBnkName"
              formControlName="brbankName">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brbankName').invalid && (form.get('bankingReference.brbankName').touched || form.get('bankingReference.brbankName').dirty)">
              <div *ngIf="form.get('bankingReference.brbankName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('bankingReference.brbankName').errors['invalidName']">{{ credFormOne.bankNameInvalid
                }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefAccNum" class="form-label"> Account Number</label>
            <input type="text" class="form-control" placeholder="Account Number" id="bankingRefAccNum"
              formControlName="brAccountNumber">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brAccountNumber').invalid && (form.get('bankingReference.brAccountNumber').touched || form.get('bankingReference.brAccountNumber').dirty)">
              <div *ngIf="form.get('bankingReference.brAccountNumber').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('bankingReference.brAccountNumber').errors['invalidno']">{{ credFormOne.accNoInvalid
                }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefContName" class="form-label"> Contact Name</label>
            <input type="text" class="form-control" placeholder="Contact Name" id="bankingRefContName"
              formControlName="brContactName">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brContactName').invalid && (form.get('bankingReference.brContactName').touched || form.get('bankingReference.brContactName').dirty)">
              <div *ngIf="form.get('bankingReference.brContactName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('bankingReference.brContactName').errors['invalidAlphabets']">{{
                credFormOne.contactNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefTitle" class="form-label"> Title</label>
            <input type="text" class="form-control" placeholder="Title" id="bankingRefTitle" formControlName="brTitle">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brTitle').invalid && (form.get('bankingReference.brTitle').touched || form.get('bankingReference.brTitle').dirty)">
              <div *ngIf="form.get('bankingReference.brTitle').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('bankingReference.brTitle').errors['invalidName']">{{ credFormOne.titleInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="bankingRefEmail" formControlName="brEmail">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brEmail').invalid && (form.get('bankingReference.brEmail').touched || form.get('bankingReference.brEmail').dirty)">
              <div *ngIf="form.get('bankingReference.brEmail').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('bankingReference.brEmail').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="bankingRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="bankingRefPhone" formControlName="brPhone">
            <small class="text-danger"
              *ngIf="form.get('bankingReference.brPhone').invalid && (form.get('bankingReference.brPhone').touched || form.get('bankingReference.brPhone').dirty)">
              <div *ngIf="form.get('bankingReference.brPhone').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('bankingReference.brPhone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="common-modal-input-section">
      <div formGroupName="tradeReference1" id="tradeReference1">
        <h2 class="section-main-header">Trade References<sup class="text-danger">*</sup></h2>
        <div class="row mt-5">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4 d-flex align-items-center gap-1">Trade reference 1: <span class="help-icon clickable" (click)="toggleHelpSlider()"></span></p>                   
          </div>
        </div>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="tr1Message">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ tr1Message }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-6 me-auto custom-floating-label">
            <label for="tradeRefCompName" class="form-label">Company Name</label>
            <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName"
              formControlName="tr1CompanyName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1CompanyName').invalid && (form.get('tradeReference1.tr1CompanyName').touched || form.get('tradeReference1.tr1CompanyName').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1CompanyName').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('tradeReference1.tr1CompanyName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference1.tr1CompanyName').errors['invalidName']">{{
                credFormOne.companyNameInvalid }}</div>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContFName" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName"
              formControlName="tr1ContactFirstName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1ContactFirstName').invalid && (form.get('tradeReference1.tr1ContactFirstName').touched || form.get('tradeReference1.tr1ContactFirstName').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1ContactFirstName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1ContactFirstName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference1.tr1ContactFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
            <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName"
              formControlName="tr1ContactLastName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1ContactLastName').invalid && (form.get('tradeReference1.tr1ContactLastName').touched || form.get('tradeReference1.tr1ContactLastName').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1ContactLastName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1ContactLastName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference1.tr1ContactLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail" formControlName="tr1Email">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1Email').invalid && (form.get('tradeReference1.tr1Email').touched || form.get('tradeReference1.tr1Email').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1Email').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1Email').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Email').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone" formControlName="tr1Phone">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1Phone').invalid && (form.get('tradeReference1.tr1Phone').touched || form.get('tradeReference1.tr1Phone').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1Phone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference1.tr1Phone').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="tradeRefStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet"
              formControlName="tr1Street">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1Street').invalid && (form.get('tradeReference1.tr1Street').touched || form.get('tradeReference1.tr1Street').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1Street').errors['maxlength']">Max length exceeded
              </div>
              <div *ngIf="form.get('tradeReference1.tr1Street').errors['invalidStreet']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" placeholder="City" id="prodComCity" class="form-control" formControlName="tr1City">
            <small class="text-danger"
              *ngIf="form.get('tradeReference1.tr1City').invalid && (form.get('tradeReference1.tr1City').touched || form.get('tradeReference1.tr1City').dirty)">
              <div *ngIf="form.get('tradeReference1.tr1City').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference1.tr1City').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="tr1state"
                  (change)="onTrOneStateChange()">
                  <option class="dropdown-option" value="">State</option>
                  <option class="dropdown-option" *ngFor="let state of states" [value]="state?.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control"
                  formControlName="tr1ZipCode">
                <small class="text-danger"
                  *ngIf="form.get('tradeReference1.tr1ZipCode').invalid && (form.get('tradeReference1.tr1ZipCode').touched || form.get('tradeReference1.tr1ZipCode').dirty)">
                  <div *ngIf="form.get('tradeReference1.tr1ZipCode').errors['maxlength']">Max length exceeded</div>
                  <div *ngIf="form.get('tradeReference1.tr1ZipCode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid}}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label d-flex align-items-center my-4 pe-0">
            <span class="fw-semibold me-3"
              *ngIf="!tr1fileName">Or</span>
              <label for="LtofCreditUploadDoc1" class="d-inline-block"
              *ngIf="!tr1fileName">
              <span class="fw-semibold d-flex align-items-center" [ngClass]="{
                'text-link': !disableUpload1 
              }">
                <span class="upload-icon me-1" ></span> Upload Document</span>
              <input type="file" id="LtofCreditUploadDoc1" hidden="" class="form-control" [disabled]="disableUpload1"
                (change)="onTrFileChange($event,1)" accept=".pdf">
            </label>
            <div class="row mx-0 w-100"
              *ngIf="tr1fileName">
              <div class="col-12 col-md-9 col-sm-9 col-lg-9 d-flex align-items-center gap-2 px-0">
                <div>
                  <img src="assets/images/pdf-icon.svg" alt="file icon" class="file-icon" />
                </div>
                <div>
                  <span [title]="tr1fileName" class="file-name">{{ tr1fileName ||
                    applicationData?.creditApplicationList?.tradeReferences1?.trDocumentUrl | extractFilename }}</span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-sm-3 col-lg-3 d-flex view-remove justify-content-end pe-0">
                <button class="btn btn-link fb-600"
                  (click)="viewTradeRefferenceReport(tradeRefferenc1Data ? tradeRefferenc1Data?.trDocumentUrl : startApplicationData?.creditApplicationList?.tradeReferences1?.trDocumentUrl)">{{credFormOne.view}}</button>
                  <button 
                  class="btn fb-600"
                  [ngClass]="{
                    'btn-link': customerStatus !== 'UNDER REVIEW' && customerStatus !== 'Application Submitted' && !this.revisionData?.caRevisionDetails?.applicationFormDetails?.tradeReference1Details?.reviewRequired  
                  }"
                  [disabled]="customerStatus === 'UNDER REVIEW' || customerStatus === 'Application Submitted'"
                  (click)="customerStatus !== 'UNDER REVIEW' && customerStatus !== 'Application Submitted' ? removeTradeRefferenceReport($event, tradeRefferenc1Data, 1) : $event.preventDefault()">
                  {{credFormOne.remove}}
                </button>
                
              </div>
            </div>
          </div>
          <div *ngIf="tr1ErrorMessage" class="text-danger">
            <p>{{ tr1ErrorMessage }}</p>
          </div>
          <div class="col-md-12 py-3">
            <hr class="border-dark-subtle border-bottom" />
          </div>
        </div>
      </div>

      <div formGroupName="tradeReference2" id="tradeReference2">
        <div class="row">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4 d-flex align-items-center gap-1">Trade reference 2: <span class="help-icon clickable" (click)="toggleHelpSlider()"></span></p>  
          </div>
        </div>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="tr2Message">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ tr2Message }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-6 me-auto custom-floating-label">
            <label for="tradeRefCompName" class="form-label">Company Name</label>
            <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName"
              formControlName="tr2CompanyName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2CompanyName').invalid && (form.get('tradeReference2.tr2CompanyName').touched || form.get('tradeReference2.tr2CompanyName').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2CompanyName').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('tradeReference2.tr2CompanyName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2CompanyName').errors['invalidName']">{{
                credFormOne.companyNameInvalid }}</div>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContFName" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName"
              formControlName="tr2ContactFirstName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2ContactFirstName').invalid && (form.get('tradeReference2.tr2ContactFirstName').touched || form.get('tradeReference2.tr2ContactFirstName').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2ContactFirstName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference2.tr2ContactFirstName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2ContactFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
            <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName"
              formControlName="tr2ContactLastName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2ContactLastName').invalid && (form.get('tradeReference2.tr2ContactLastName').touched || form.get('tradeReference2.tr2ContactLastName').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2ContactLastName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference2.tr2ContactLastName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2ContactLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail" formControlName="tr2Email">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2Email').invalid && (form.get('tradeReference2.tr2Email').touched || form.get('tradeReference2.tr2Email').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2Email').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference2.tr2Email').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2Email').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone2" formControlName="tr2Phone">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2Phone').invalid && (form.get('tradeReference2.tr2Phone').touched || form.get('tradeReference2.tr2Phone').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2Phone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference2.tr2Phone').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2Phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="tradeRefStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet"
              formControlName="tr2Street">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2Street').invalid && (form.get('tradeReference2.tr2Street').touched || form.get('tradeReference2.tr2Street').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2Street').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2Street').errors['invalidStreet']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" placeholder="City" id="prodComCity" class="form-control" formControlName="tr2City">
            <small class="text-danger"
              *ngIf="form.get('tradeReference2.tr2City').invalid && (form.get('tradeReference2.tr2City').touched || form.get('tradeReference2.tr2City').dirty)">
              <div *ngIf="form.get('tradeReference2.tr2City').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference2.tr2City').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="tr2state"
                  (change)="onTrTwoStateChange()">
                  <option class="dropdown-option" value="">State</option>
                  <option class="dropdown-option" *ngFor="let state of states" [value]="state?.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control"
                  formControlName="tr2ZipCode">
                <small class="text-danger"
                  *ngIf="form.get('tradeReference2.tr2ZipCode').invalid && (form.get('tradeReference2.tr2ZipCode').touched || form.get('tradeReference2.tr2ZipCode').dirty)">
                  <div *ngIf="form.get('tradeReference2.tr2ZipCode').errors['maxlength']">Max length exceeded</div>
                  <div *ngIf="form.get('tradeReference2.tr2ZipCode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label d-flex align-items-center my-4 pe-0">
            <span class="fw-semibold me-3"
              *ngIf="!tr2fileName">Or</span>
              <label for="LtofCreditUploadDoc2" class="d-inline-block"
              *ngIf="!tr2fileName">
              <span class="fw-semibold d-flex align-items-center"
              [ngClass]="{
                'text-link': !disableUpload2  
              }">
                <span class="upload-icon me-1"></span> Upload Document</span>
              <input type="file" id="LtofCreditUploadDoc2" hidden="" class="form-control"
              [disabled]="disableUpload2"
              (change)="onTrFileChange($event,2)"   accept=".pdf">
            </label>
            <div class="row mx-0 w-100"
              *ngIf="tr2fileName">
              <div class="col-12 col-md-9 col-sm-9 col-lg-9 d-flex align-items-center gap-2 px-0">
                <div>
                  <img src="assets/images/pdf-icon.svg" alt="file icon" class="file-icon" />
                </div>
                <div>
                  <span [title]="tr2fileName" class="file-name">{{ tr2fileName ||
                    applicationData?.creditApplicationList?.tradeReferences2?.trDocumentUrl | extractFilename }}</span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-sm-3 col-lg-3 d-flex view-remove justify-content-end pe-0">
                <button class="btn btn-link fb-600"
                  (click)="viewTradeRefferenceReport(tradeRefferenc2Data ? tradeRefferenc2Data?.trDocumentUrl : startApplicationData?.creditApplicationList?.tradeReferences2?.trDocumentUrl)">{{credFormOne.view}}</button>
                  <button class="btn btn-link fb-600"
                [disabled]="customerStatus === 'UNDER REVIEW' || customerStatus === 'Application Submitted'"
                (click)="customerStatus !== 'UNDER REVIEW' && customerStatus !== 'Application Submitted' ? removeTradeRefferenceReport($event, tradeRefferenc2Data, 2) : $event.preventDefault()">{{credFormOne.remove}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="tr2ErrorMessage" class="text-danger">
            <p>{{ tr2ErrorMessage }}</p>
          </div>
          <div class="col-md-12 py-3">
            <hr class="border-dark-subtle border-bottom" />
          </div>
        </div>
      </div>

      <div formGroupName="tradeReference3" id="tradeReference3">
        <div class="row">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4 d-flex align-items-center gap-1">Trade reference 3: <span class="help-icon clickable" (click)="toggleHelpSlider()"></span></p>
          </div>
        </div>
        <div class="container cm-main-container px-0 mx-0">
          <div class="alert revision-alert" role="alert" *ngIf="tr3Message">
            <div class="container">
              <div class="row">
                <div class="font-weight-bold my-2">
                  "{{ tr3Message }}"
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-start">
          <div class="col-md-6 me-auto custom-floating-label">
            <label for="tradeRefCompName" class="form-label">Company Name</label>
            <input type="text" class="form-control" placeholder="Company Name" id="tradeRefCompName"
              formControlName="tr3CompanyName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3CompanyName').invalid && (form.get('tradeReference3.tr3CompanyName').touched || form.get('tradeReference3.tr3CompanyName').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3CompanyName').errors['required']">{{ credFormOne.fieldRequired }}
              </div>
              <div *ngIf="form.get('tradeReference3.tr3CompanyName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3CompanyName').errors['invalidName']">{{
                credFormOne.companyNameInvalid }}</div>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContFName" class="form-label">Contact First Name</label>
            <input type="text" class="form-control" placeholder="Contact First Name" id="tradeRefContFName"
              formControlName="tr3ContactFirstName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3ContactFirstName').invalid && (form.get('tradeReference3.tr3ContactFirstName').touched || form.get('tradeReference3.tr3ContactFirstName').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3ContactFirstName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference3.tr3ContactFirstName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3ContactFirstName').errors['invalidAlphabets']">{{
                credFormOne.firstNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefContLName" class="form-label"> Contact Last Name</label>
            <input type="text" class="form-control" placeholder="Contact Last Name" id="tradeRefContLName"
              formControlName="tr3ContactLastName">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3ContactLastName').invalid && (form.get('tradeReference3.tr3ContactLastName').touched || form.get('tradeReference3.tr3ContactLastName').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3ContactLastName').errors['required']">{{
                credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference3.tr3ContactLastName').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3ContactLastName').errors['invalidAlphabets']">{{
                credFormOne.lastNameInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefEmail" class="form-label"> Email</label>
            <input type="text" class="form-control" placeholder="Email" id="tradeRefEmail" formControlName="tr3Email">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3Email').invalid && (form.get('tradeReference3.tr3Email').touched || form.get('tradeReference3.tr3Email').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3Email').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference3.tr3Email').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3Email').errors['invalidEmail']">{{ credFormOne.emailInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="tradeRefPhone" class="form-label"> Phone</label>
            <input type="text" class="form-control" placeholder="Phone" id="tradeRefPhone" formControlName="tr3Phone">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3Phone').invalid && (form.get('tradeReference3.tr3Phone').touched || form.get('tradeReference3.tr3Phone').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3Phone').errors['required']">{{ credFormOne.fieldRequired }}</div>
              <div *ngIf="form.get('tradeReference3.tr3Phone').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3Phone').errors['invalidPhone']">{{ credFormOne.PhoneInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="tradeRefStreet" class="form-label"> Street</label>
            <input type="text" class="form-control" placeholder="Street" id="tradeRefStreet"
              formControlName="tr3Street">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3Street').invalid && (form.get('tradeReference3.tr3Street').touched || form.get('tradeReference3.tr3Street').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3Street').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3Street').errors['invalidStreet']">{{ credFormOne.streetInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="prodComCity" class="form-label"> City</label>
            <input type="text" placeholder="City" id="prodComCity" class="form-control" formControlName="tr3City">
            <small class="text-danger"
              *ngIf="form.get('tradeReference3.tr3City').invalid && (form.get('tradeReference3.tr3City').touched || form.get('tradeReference3.tr3City').dirty)">
              <div *ngIf="form.get('tradeReference3.tr3City').errors['maxlength']">Max length exceeded</div>
              <div *ngIf="form.get('tradeReference3.tr3City').errors['invalidCity']">{{ credFormOne.cityInvalid }}</div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> State</label>
                <select id="prodComState" class="custom-select" formControlName="tr3state"
                  (change)="onTrThreeStateChange()">
                  <option class="dropdown-option" value="">State</option>
                  <option class="dropdown-option" *ngFor="let state of states" [value]="state?.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="prodComZipcode" class="form-label"> ZIP Code</label>
                <input type="text" placeholder="ZIP Code" id="prodComZipcode" class="form-control"
                  formControlName="tr3ZipCode">
                <small class="text-danger"
                  *ngIf="form.get('tradeReference3.tr3ZipCode').invalid && (form.get('tradeReference3.tr3ZipCode').touched || form.get('tradeReference3.tr3ZipCode').dirty)">
                  <div *ngIf="form.get('tradeReference3.tr3ZipCode').errors['maxlength']">Max length exceeded</div>
                  <div *ngIf="form.get('tradeReference3.tr3ZipCode').errors['invalidZipCode']">{{
                    credFormOne.zipcodeInvalid
                    }}</div>
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-12 custom-floating-label d-flex align-items-center my-4 pe-0">
            <span class="fw-semibold me-3"
              *ngIf="!tr3fileName">Or</span>
              <label for="LtofCreditUploadDoc3" class="d-inline-block"
              *ngIf="!tr3fileName">
              <span class="fw-semibold d-flex align-items-center" [ngClass]="{
                'text-link': !disableUpload3  
              }">
                <span class="upload-icon me-1"></span> Upload Document</span>
              <input type="file" id="LtofCreditUploadDoc3" hidden="" class="form-control"
              [disabled]="disableUpload3"
              (change)="onTrFileChange($event,3)"  accept=".pdf">
            </label>
            <div class="row mx-0 w-100"
              *ngIf="tr3fileName">
              <div class="col-12 col-md-9 col-sm-9 col-lg-9 d-flex align-items-center gap-2 px-0">
                <div>
                  <img src="assets/images/pdf-icon.svg" alt="file icon" class="file-icon" />
                </div>
                <div>
                  <span [title]="tr3fileName" class="file-name">{{ tr3fileName ||
                    applicationData?.creditApplicationList?.tradeReferences3?.trDocumentUrl | extractFilename}}</span>
                </div>
              </div>
              <div class="col-12 col-md-3 col-sm-3 col-lg-3 d-flex view-remove justify-content-end pe-0">
                <button class="btn btn-link fb-600"
                  (click)="viewTradeRefferenceReport(tradeRefferenc3Data ? tradeRefferenc3Data?.trDocumentUrl : startApplicationData?.creditApplicationList?.tradeReferences3?.trDocumentUrl)">{{credFormOne.view}}</button>
                  <button class="btn btn-link fb-600"
                  [disabled]="customerStatus === 'UNDER REVIEW' || customerStatus === 'Application Submitted'"
                  (click)="customerStatus !== 'UNDER REVIEW' && customerStatus !== 'Application Submitted' ? removeTradeRefferenceReport($event, tradeRefferenc3Data, 3) : $event.preventDefault()">{{credFormOne.remove}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="tr3ErrorMessage" class="text-danger">
            <p>{{ tr3ErrorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cm-btn-section" *ngIf="!isRevision">
      <button type="button" class="btn common-btn-secondary mb-2" data-bs-dismiss="modal"
        (click)="nextPage()" [disabled]="isNextButtonDisabled">{{credFormOne.certificate}}</button>
      <button type="button" class="btn common-btn-light ms-2 mb-2" [disabled]="!form.dirty" (click)="saveAndClose()"
        *ngIf="!isRevision" data-bs-dismiss="modal">{{credFormOne.SaveandClose}}</button>
    </div>
    <div class="cm-btn-section" *ngIf="isRevision">
      <button type="button" class="btn common-btn-light ms-2 mb-2" data-bs-dismiss="modal"
        (click)="closeModal()">{{credFormOne.cancel}}</button>
      <button type="button" class="btn common-btn-light ms-2 mb-2" data-bs-dismiss="modal"
        (click)="saveAndCloseRevisions()" [disabled]="!form.dirty">{{credFormOne.SaveandClose}}</button>
      <button type="button" class="btn common-btn-secondary mb-2 ms-2" (click)="nextReviewApplication()"
        data-bs-dismiss="modal">{{credFormOne.review}}</button>
    </div>
  </form>
  <app-help-slideout [helpSliderVisible]="helpSliderVisible"></app-help-slideout>
</div>

<ng-template #successTpl>
  <div class="success">{{ toastMessages.creditApplication }}</div>
</ng-template>

<ng-template #successUploadFileTpl>
  <div class="success">{{ successMsg }}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{ errorMsg }}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>