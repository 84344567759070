import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { INDEX } from '../../../enum/customer-portal.enum';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-credit-dashboard-departments',
  templateUrl: './credit-dashboard-departments.component.html',
  styleUrls: ['./credit-dashboard-departments.component.scss']
})
  
export class CreditDashboardDepartmentsComponent {
  displayedDept: string[] = [];
  hiddenDept: string[] = [];

  agInit(params: ICellRendererParams): void {
    const Dept: string[] = (params.value || '').split(';').map(dept => dept.trim());
    const formattedDepartments = Dept.map(department => {
          return department.replace(
        /\+\d+/g,
        (match) =>
          `<span>${match}</span>`
      );
    });
    formattedDepartments.sort();
    const maxVisibleDept = INDEX.three;

    if (formattedDepartments.length > maxVisibleDept) {
      this.displayedDept = formattedDepartments.slice(INDEX.zero, maxVisibleDept);
      this.hiddenDept = formattedDepartments.slice(maxVisibleDept);
    } else {
      this.displayedDept = formattedDepartments;
      this.hiddenDept = [];
    }
  }

  refresh(params: ICellRendererParams): boolean {
    this.agInit(params); 
    return true;
  }
}
