<div class="project-wrapper" *ngIf="permissionsData['projects']">
	<div class="container">
		<div class="row">
			<div class="comman-title-block px-0">
				<div class="text-blue top-nav-company mb-3" *ngIf="companyname && !viewcompanypage">
					<img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
					<p class="back-link mb-0" (click)="redirectViewCompany()">
					 {{companyConst.allcompanies}}
					</p>
				  </div>
				  <div class="text-blue top-nav-company mb-3" *ngIf="companyname && viewcompanypage">
					<img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
					<p class="back-link mb-0" (click)="redirectViewCompany()">
					 {{companyname}}
					</p>
				  </div>
				  
				<div class="comman-title-section">
					<h1>{{project.projects}}</h1>
					<button class="btn common-btn-primary" *ngIf="permissionsData['addNewProject']" (click)="openAddProjectModal()">+ {{project.project}}</button>
				</div>
				<div class="comman-filter-block">
					<div class="container">
						<div class="row">
							<div class="col-lg-9 col-12 px-0">
								<div class="comman-filter-title-section">
									<p>{{project.filters}}</p>
								</div>
								<div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
									<!-- Multiselect Dropdowns -->
									<app-ng-multiselect-dropdown [placeholder]="'ID#'" class='custom-multiselect'
										[settings]="defaultSettings" [type]="userTypeahead" [filterData]="projectIdFilter" [data]="usersId" 
										(onSelect)="selectedUserId($event)">
									</app-ng-multiselect-dropdown>
									<app-ng-multiselect-dropdown *ngIf="company" [placeholder]="'Companies'" class='custom-multiselect'
										[settings]="defaultSettings" [data]="company"
										(onSelect)="selectedCompany($event)"
										[(ngModel)]="defaultSelectedCompany">
									</app-ng-multiselect-dropdown>
									<app-ng-multiselect-dropdown [placeholder]="'Projects'" [settings]="defaultSettings"
										class='custom-multiselect' [data]="projects"
										(onSelect)="selectedProject($event)">
									</app-ng-multiselect-dropdown>
									<app-ng-multiselect-dropdown [placeholder]="'Types'" [settings]="defaultSettings"
										class='custom-multiselect' [data]="types" (onSelect)="selectedType($event)">
									</app-ng-multiselect-dropdown>
									<app-datepicker class='custom-multiselect' [placeholder]="placeholder"
										(dateRangeSelected)="onDateRangeSelected($event)">
									</app-datepicker>
									<app-ng-multiselect-dropdown [placeholder]="'Status'" [settings]="defaultSettings"
										class='custom-multiselect' [data]="status" (onSelect)="selectedStatus($event)">
									</app-ng-multiselect-dropdown>
								</div>
							</div>
							<div class="col-lg-3 col-12 px-0">
								<div class="comman-filter-title-section align-right-sort-text">
									<p class="sort-text">{{sort.sort}}</p>
								</div>
								<div class="filter-sort-block d-flex align-right-sort-text">
									<div class="most-recent-button btn-primary" [ngClass]="{'common-btn-secondary btn': sortId==1}"
										(click)="sortByMostRecent(1)">{{sort.mostRecent}}</div>
									<div class="most-recent-button btn-primary" [ngClass]="{'common-btn-secondary btn': sortId==2}"
										(click)="ascendingSort(2)">{{sort.aToz}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Selected filters section -->
			<div class="selected-filters-section px-0">
				<!-- Combined Selected Filters -->
				<div *ngIf="isFiltersSelected" class="selected-filters">
					<!-- Selected projectId -->
					<div *ngFor="let projectId of filteredUsersId" class="filter-tag">
						{{ projectId.projectId }}
						<button type="button" (click)="removeSelectedItem('projectId', projectId)">×</button>
					</div>
					<!-- Selected Company -->
					<div *ngFor="let company of filteredCompany" class="filter-tag">
						{{ company.text }}
						<button type="button" (click)="removeSelectedItem('company', company)">×</button>
					</div>
					<!-- Selected Projects -->
					<div *ngFor="let project of filteredProjects" class="filter-tag">
						{{ project.text }}
						<button type="button" (click)="removeSelectedItem('project', project)">×</button>
					</div>
					<!-- Selected Types -->
					<div *ngFor="let type of filteredTypes" class="filter-tag">
						{{ type.text }}
						<button type="button" (click)="removeSelectedItem('type', type)">×</button>
					</div>
					<!-- Selected Date Range -->
					<div *ngIf="selectedFromDate && selectedToDate" class="filter-tag">
						{{ formatDateRange(selectedFromDate, selectedToDate) }}
						<button type="button" (click)="clearDateRange()">×</button>
					</div>
					<!-- Selected Status -->
					<div *ngFor="let status of filteredStatus" class="filter-tag">
						{{ status.text }}
						<button type="button" (click)="removeSelectedItem('status', status)">×</button>
					</div>
					<!-- Clear All Filters Button  -->
					<a class="text-link" (click)="clearAllFilters()">{{project.clearFilters}}</a>
				</div>
				<div *ngIf="paginatedData" class="project-list-wrapper px-0">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-4 " *ngFor="let data of paginatedData">
							<div class="project-card-block" (click)="openProjectDetails(data.projectId, userProfileId)">
								<div class="card clickable">
									<div class="id-block">
										<span class="id-title">{{project.id}}</span>
										<span class="id-no">{{data.projectId}}</span>
									</div>
									<img class="project-card-img img-fluid"
									[src]="data.imageUrl == null ? getImageUrl(data.projectType.projectType) : data.imageUrl" alt="project image" onerror="this.src='../../assets/images/project-image1.png'">
									<!-- <img class="project-card-img img-fluid" [src]="data.imageUrl"
										alt="blank-image" onerror="this.src='../../assets/images/project-image1.png'"> -->
									<div class="card-body">
										<span class="company-name ellipses-email-studio">{{data.company.companyName}}</span>
										<h2 class="project-name">{{data.projectName}}</h2>
										<p class="project-type">{{data.projectType.projectType}} <span *ngIf="data?.seasonNumber">-  {{project.season}} {{data.seasonNumber}}</span></p>
										<div class="project-date-status-block">
											<p class=" project-status">{{data.projectStartDate}} -
												{{data.projectEndDate}}</p>
											<!-- <p class=" project-status">{{ data.projectStartDate | date: 'dd/mm/yy' }} -
												{{ data.projectEndDate | date: 'dd/mm/yy' }}</p> -->
												<span class="project-status-tag project-active-inactive"
												[ngClass]="{'active-tag-green': data?.projectStatus.projectStatus === 'Active', 'closed-tag-black': data?.projectStatus.projectStatus === 'Closed', 'not-started-purple p-2': data?.projectStatus.projectStatus === 'Not Started'}">{{data.projectStatus.projectStatus}}</span>
										</div>
										<div class="dropdown-icons-block">
											<div>
												<div class='custom-dropdown' (click)="$event.stopPropagation()">
													<select class="custom-select" (change)="onSelectChange($event, userProfileId, data.projectId, data.company.companyId, data.company.companyName, data.projectName)">
														<option class="dropdown-option" value="" disabled selected
															hidden>{{project.goTo}}</option>
														<option [value]="1">{{project.project}}</option>
														<option [value]="2">{{project.users}}</option>
														<option [value]="3">{{project.spacesResources}}</option>
														<option [value]="4">{{project.company}}</option>
													</select>
												</div>
											</div>
											<div class="icons" (click)="$event.stopPropagation()">
												<span *ngIf="permissionsData['addCustomerUser']" (click)="openAddUserModal(data.projectId, data.company.companyId, data.projectName)" class="common-icons">
													<img class="img-fluid" ngbTooltip="Add a User"
														src="../../../../../assets/images/person-add.svg"
														alt="Add Person">
												</span>
												<span>
													<span (click)="openSendCreditApplicationModal(data)">
													<img class="img-fluid" ngbTooltip="Send Credit Application"
														src="../../../../../assets/images/house-icon.svg"
														alt="house icon">
													</span>
												</span>
												<span *ngIf="!data?.projectBookmarked">
													<span (click)="addBookmark(data)">
													<img class="img-fluid" ngbTooltip="Bookmark Project"
														src="../../../../../assets/images/bookmark.svg"
														alt="Bookmark Project">
													</span>
												</span>
												<span *ngIf="data?.projectBookmarked">
													<span (click)="removeBookmark(data)">
													<img class="img-fluid" ngbTooltip="Remove Bookmark"
														src="../../../../../assets/images/active-bookmark.svg"
														alt="Remove Bookmark">
													</span>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="paginatedData.length == 0 || filteredData.length == 0" class="no-projects-wrapper">
					<h6 class="no-project-msg">{{ companyid ? project.noProjectsCompany : project.noProjects }}</h6>
				</div>
			</div>
			<div *ngIf="paginatedData?.length > 0 || filteredData?.length > 0">
				<app-pagination class="p-0" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
					[currentPage]="currentPage" (pageChange)="onPageChange($event)"
					(itemsPerPageChange)="onItemsPerPageChange($event)">
				</app-pagination>
			</div>
		</div>
	</div>

	<ng-template #successTpl>
		<div class="success"><span *ngIf="requestResponse">{{requestResponse?.message}}</span></div>
	</ng-template>
	<ng-template #successMsgTpl>
		<div class="success">{{successResponse}}</div>
	</ng-template>
	<ng-template #dangerTpl>
		<div class="danger"><span *ngIf="requestResponse">{{requestResponse}}</span></div>
	</ng-template>
	<app-toast aria-live="polite" aria-atomic="true"></app-toast>