import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyUserComponent } from '../../../../shared/components/add-company-user/add-company-user.component';
import {
  ACKNOWLEDGE_CONSTANTS,
  COMPANY,
  DASHBOARD,
} from '../../constants/customer-portal.constant';
import { OnTheLotService } from '../../services/onTheLot/on-the-lot.service';
import { ActiveProjectsService } from '../../services/active-projects/active-projects.service';
import {
  Project,
  ProjectResponse,
  ResourceSpace,
} from 'src/app/shared/models/active-projects';
import * as _ from 'lodash';
import { CompanyService } from '../../services/company/company.service';
import { ToastService } from '../../../../shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { CreditApplicationService } from '../../services/credit-applications/credit-application.service';
import { ProjectsListService } from '../../services/projects-list/projects-list.service';
import { CreditAppSidebarComponent } from '../projects/project-details/credit-app-sidebar/credit-app-sidebar.component';
import { filter } from 'rxjs';
import { StartCreditApplicationComponent } from '../projects/project-details/start-credit-application/start-credit-application.component';
import { ProjectUsersService } from '../../services/project-users/project-users.service';
import { INDEX } from '../../enum/customer-portal.enum';
import { SuccessBannerService } from '../../services/success-banner/success-banner.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @ViewChild('content1', { static: true }) content1!: TemplateRef<any>;
  @ViewChild('acknowledgemodal', { static: true })
  acknowledgemodal!: TemplateRef<any>;
  spacesAndResources: ResourceSpace[] = [];
  groupedSpacesAndResources: any[] = [];
  projectDetails!: ProjectResponse;
  projectsList: Project[] = [];
  first_name: string;
  userProfileId: any;
  response: any;
  modalReference: NgbModalRef;
  projectStatusId = 2;
  acknowledgeConstants = ACKNOWLEDGE_CONSTANTS;

  dashboard = DASHBOARD;
  company = COMPANY;
  userInfo = JSON.parse(localStorage.getItem('user-profile'));
  userProjectsByStatus: any;
  addUserSuccess: any;
  addUserError: any;
  companyUserMetaData: any;
  companyId: any;
  permissionsData: any;
  selectedOptionId: any;
  alertMessage: string | null = null;

  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;

  credit: any;
  userId: number;
  projectIds: any[] = [];
  result: any;
  projectData: any[] = [];
  selectedProjectId: any;
  departmentDetails: any;
  creditApplications: any;
  selectedDepartmentId: any;
  selectedProjectOptions: any;
  filteredCreditApplications: any;
  projectInfo: any;
  selectedProject: any;
  selectedCreditApplication: any;
  isCreditApplicationCount: boolean = false;
  extraFieldValue: any;
  acknowledgeData: any;
  zero: any = INDEX.zero;
  newApplicationdata: any;
  projectIdUser: number[] = [];
  isCredit: boolean;
  constructor(
    private modalService: NgbModal,
    private onTheLotService: OnTheLotService,
    private activeProjectsService: ActiveProjectsService,
    private creditService: CreditApplicationService,
    private companyService: CompanyService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private projectsService: ProjectsListService,
    private activeModal: NgbActiveModal,
    private projectUsersService: ProjectUsersService,
    private alertService: SuccessBannerService
  ) {}

  ngOnInit() {
    this.userProfileId = this.userInfo.userProfileId;
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.companyId = userProfile.companyId;
    this.loaderService.setLoadingState(true);
    this.getAddProjectUserMetadata();
    this.getOnTheLot();
    this.getActiveProjects();
    this.getUserDetailsById(false);
    this.getCreditApplications();
    this.loaderService.setLoadingState(false);
    this.updateFirstName();
    this.getAlertMessage();
  }

  /**
   * updateFirstName() to update name based on settings
   */
  updateFirstName() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    const updatedData = JSON.parse(localStorage.getItem('updated-profile'));
    if (updatedData) {
      this.first_name = updatedData?.firstName;
    } else {
      this.first_name = userProfile.firstName;
    }
  }

  /**
   *addUser() - invoke the add user modal popup
   */

  addUser(): void {
    let title = this.company?.adduser;
    let metaData = this.companyUserMetaData;
    let portalType = this.company?.portaltype;
    let modalType = this.company?.modalAdd;
    let componentType = this.company?.componenttype;
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true,
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.company.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
        } else {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      }
    });
    this.modalReference.componentInstance.emitError.subscribe((data) => {
      if (data) {
        this.addUserError = data?.message;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   *getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
   * to add the user in company.
   */
   getCompanyUserMetaData(): void {
    this.companyService.getCompanyUserMetaData(this.companyId).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data?.companyUserMetaData;
        
        if (this.projectIdUser && this.companyUserMetaData) {       
          this.companyUserMetaData.projectDetails = _.filter(
            this.companyUserMetaData.projectDetails,
            (project) => _.includes(this.projectIdUser, project.projectId)
          );
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }
  

  /**
   * getCreditApplications() - to get the recent credit appliations.
   * @param companyId - the company id to be passsed.
   */
  getCreditApplications(): void {
    this.loaderService.setLoadingState(true);
    this.creditService.getCreditApplication().subscribe({
      next: (response) => {
        this.credit = response;
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(this.dashboard.creditError, error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  /**
   * showDanger() - Displays a danger (error) toast message.
   * This method is called when there is an error in adding the user.
   * @param dangerTpl - The template reference for the danger message to display.
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * getOnTheLot() - used to retrieve metadata for on-the-lot in dashboard
   */
  getOnTheLot() {
    this.loaderService.setLoadingState(true);
    this.onTheLotService.getOnTheLotData().subscribe({
      next: (response: any) => {
        if (response) {
          this.response = response;
          this.loaderService.setLoadingState(false);
        } else {
          console.warn(this.dashboard.lotError);
          this.loaderService.setLoadingState(false);
        }
      },
    });
  }

  /**
   * getActivePRojects() - used to get active projects based on the logged in user
   */
  getActiveProjects(): void {
    this.loaderService.setLoadingState(true);
    const userProfileId = this.userInfo.userProfileId;
    this.activeProjectsService
      .getActiveProjects(userProfileId, this.projectStatusId)
      .subscribe({
        next: (response: ProjectResponse | null) => {
          if (response) {
            this.projectDetails = response;
            this.projectsList = response.userProjectsByStatus.projectList;
            this.loaderService.setLoadingState(false);
            this.spacesAndResources = [];

            this.projectsList.forEach((project) => {
              // Ensure spaceAndResources exists and is an array
              if (
                project.spaceAndResources &&
                project.spaceAndResources.length > 0
              ) {
                project.spaceAndResources.forEach((resource) => {
                  this.spacesAndResources.push(resource);
                });
              }
            });

            // Group the spaces and resources after populating
            this.groupSpacesAndResources();
          } else {
            console.warn(this.dashboard.error);
            this.loaderService.setLoadingState(false);
          }
        },
      });
  }

  /**
   * groupSpacesAndResources() - used to group resource values based on resources type
   */
  groupSpacesAndResources(): void {
    const grouped = _.groupBy(
      this.spacesAndResources,
      (resource) => `${resource.resourceType}-${resource.stageNumber || 'N/A'}`
    );
    this.groupedSpacesAndResources = Object.keys(grouped).map((key) => {
      const resources = grouped[key];
      return {
        resourceType: resources[0].resourceType,
        stageNumber: resources[0].stageNumber,
        resourceValues: resources.map((r) => r.resourceValue),
      };
    });
  }

  /**
   * updateOnTheLot() - its is for updating the country id
   * @param index - the country id that need to be updated
   */
  updateOnTheLot(index: number): void {
    this.loaderService.setLoadingState(true);
    const data = {
      onTheLotCountryId: index,
    };
    this.onTheLotService.updateOnTheLot(data, this.userProfileId).subscribe({
      next: (response) => {
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(this.dashboard.lotError, error);
        this.loaderService.setLoadingState(false);
      },
    });
  }
  /**
   * getUserDetailsById() - Fetches user details by user ID and opens the  modal.
   * @param userId - The ID of the user whose details are to be fetched.
   */
  getUserDetailsById(isCredit:boolean): void {
    this.loaderService.setLoadingState(true);
    this.companyService.getUserSearch(this.userProfileId, true).subscribe({
      next: (data: any) => {
        if(isCredit){
          this.projectIds = this.getCreditApplicationProjects(data,isCredit);
          this.loaderService.setLoadingState(false);
        }
        else{
          this.getUserData(data)
        }
        
      },
      error: (error: any) => {
        this.addUserError = error;
        this.showDanger(this.dangerTpl);
        console.error(error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

  /**
   * getCreditApplicationProjects() - to get the list of credit applications related to that project
   * @param response - the reponse we are getting from searching user and related projects
   * @returns  - list of projectIds
   */
  getCreditApplicationProjects(response: any, isCredit:boolean): any {
    const projectIds: number[] = [];
    response.companyUser?.companyUserProjectAssignments?.forEach(
      (assignment: any) => {
        const hasCreditRole = assignment?.roles.some(
          (role: any) =>
            role?.userRoleId === INDEX.three && role?.isUserRoleSelected
        );

        if (hasCreditRole) {
          const project = assignment.project;
          if (project?.isProjectSelected) {
            projectIds.push(project.projectId);
            const projectInfo = {
              projectId: project?.projectId,
              projectName: project?.projectName,
              imageUrl: project?.imageUrl,
            };
            this.projectData.push(projectInfo);
          }
        }
      }
    );
    if (this.projectIds) {
      this.openMultipleProjectModal();
    }
    return projectIds;
  }

  /**
   * getUserData() - to get the projectIds which he is having role as manage user
   * @param response - gives the reponse of projectids having permission manage user
   */
  getUserData(response: any): any {
    response.companyUser?.companyUserProjectAssignments?.forEach(
      (assignment: any) => {
        const hasCreditRole = assignment?.roles.some(
          (role: any) =>
            role?.userRoleId === 4 && role?.isUserRoleSelected
        );
        if (hasCreditRole) {
          const project = assignment.project;
          if (project?.isProjectSelected) {
            this.projectIdUser.push(project.projectId);
          }
        }
      },
    );
    this.getCompanyUserMetaData()
  }

  /**
   * getProjectDetails() - to get the project details and their credit applications
   * @param projectId - the project id that has been selected from modal
   */
  getProjectDetails(projectId: any): void {
    this.loaderService.setLoadingState(true);
    this.projectsService
      .getProjectDataById(this.projectIds[0], this.userProfileId)
      .subscribe({
        next: (response: any) => {
          if (response) {
            const res = response?.project;
            this.projectInfo = res;
            this.getCreditApplicationData(res);
            this.loaderService.setLoadingState(false);
          } else {
            console.warn(this.dashboard.creditErrorAdd);
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  /**
   * getCreditApplicationData() - get credit applications based on status
   * @param response - the reponse we are getting from projects to filter relevant details
   */
  getCreditApplicationData(response: any): void {
    const filteredCreditApps = response?.creditApplications;
    if (filteredCreditApps) {
      const approvedCreditApps = filteredCreditApps
        .filter(
          (app) =>
            app.customerStatus === this.dashboard.startApplication ||
            app.customerStatus === this.dashboard.finishApplication
        )
        .map((app) => ({
          caSeqId: app.caSeqId,
          caNumber: app.caNumber,
          customerStatus: app.customerStatus,
          departments: app.caDepartments,
        }));

      this.newApplicationdata = {
        projectId: response.projectId,
        projectName: response.projectName,
        companyId: response.company.companyId,
        companyName: response.company.companyName,
        departmentDetails: this.departmentDetails,
      };
      this.filteredCreditApplications = approvedCreditApps;
      if (this.filteredCreditApplications?.length > INDEX.one) {
        this.isCreditApplicationCount = true;
      }
      if (
        filteredCreditApps &&
        this.filteredCreditApplications.length === INDEX.zero
      ) {
        this.openStartCreditApplicationModal(this.newApplicationdata);
      }
    } else {
      this.newApplicationdata = {
        projectId: response.projectId,
        projectName: response.projectName,
        companyId: response.company.companyId,
        companyName: response.company.companyName,
        departmentDetails: this.departmentDetails,
      };
      this.openStartCreditApplicationModal(this.newApplicationdata);
    }
  }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata(): void {
    this.loaderService.setLoadingState(true);
    this.projectUsersService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails =
        metaData?.getProjectUserMetaDataDTO?.departmentsDetails;
      this.loaderService.setLoadingState(false);
    });
  }
  /**
   * openStartCreditApplicationModal() - method to add a new credit application
   */
  openStartCreditApplicationModal(result: any): void {
    if (this.modalReference) {
      this.modalReference.close();
    }
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(
      StartCreditApplicationComponent,
      {
        size: 'lg',
        centered: true,
        backdrop: 'static',
      }
    );
    this.modalReference.componentInstance.projectId = result.projectId;
    this.modalReference.componentInstance.projectName = result.projectName;
    this.modalReference.componentInstance.companyId = result.companyId;
    this.modalReference.componentInstance.companyName = result.companyName;
    this.modalReference.componentInstance.departmentDetails =
      this.departmentDetails;
    this.modalReference.result.then(
      (data) => {
        if (data) {
        }
      },
      (reason) => {}
    );
  }

  /**
   * acknowledgeConditions() - method to open the credit application
   * @param modal - current modal
   */
  aacknowledgeConditions(result: any): void {
    if (this.modalReference) {
      this.modalReference.close();
    }
    window.scrollTo(0, 0);

    // Close the current modal if necessary (you can pass the modal instance to close it)
    if (this.modalReference) {
      this.modalReference.close();
    }

    // Open the new modal
    this.modalReference = this.modalService.open(CreditAppSidebarComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });

    this.modalReference.componentInstance.projectId = this.result.projectId;
    this.modalReference.componentInstance.projectName = this.result.projectName;
    this.modalReference.componentInstance.caSeqId =
      this.result?.creditApplications[0].caSeqId;
    this.modalReference.componentInstance.caNumber =
      this.result?.creditApplications[0].caNumber;
    this.modalReference.componentInstance.companyId = this.companyId;
    this.modalReference.componentInstance.customerStatus =
      this.result?.creditApplications[0].customerStatus;
  }

  /**
   * openMultipleProjectModal() - to open modal for multiple projects
   */
  openMultipleProjectModal(): void {
    if (this.modalReference) {
      this.modalReference.close();
    }
    this.modalReference = this.modalService.open(this.content1, {
      size: 'lg',
      centered: true,
    });
    this.modalReference.result.then(
      (result) => {
        this.resetVariables();
      },
      (reason) => {
        this.resetVariables();
      }
    );
  }

  /**
   * resetVariables() - to reset all variables after closing the modal
   */
  resetVariables(): void {
    this.projectData = [];
    this.selectedProjectId = null;
    this.selectedProject = null;
    this.selectedCreditApplication = null;
    this.selectedDepartmentId = null;
    this.filteredCreditApplications = [];
    this.selectedCreditApplication = null;
    this.selectedOptionId = null;
    this.newApplicationdata = null;
  }

  /**
   * submitSelectedProject() - to call the function after selecting project and credit application
   */
  submitSelectedProject(): void {
    this.submitSelectedCredit();
  }

  /**
   * onProjectSelect() - to get the selected Project
   * @param selectedProject - to get the details of selected project
   */
  onProjectSelect(selectedProject: any): void {
    this.projectIds = [selectedProject.projectId];
    this.selectedProject = selectedProject;
    this.getProjectDetails(this.projectIds);
  }

  /**
   * onCreditSelect() - to get the selected credit application based on departments
   * @param option - to get the selected credit application
   */
  onCreditSelect(option: any): void {
    this.selectedCreditApplication = option;
  }

  /**
   * submitSelectedCredit() - to submit the credit application and project details
   */
  submitSelectedCredit(): void {
    if (this.selectedOptionId === INDEX.zero) {
      this.openStartCreditApplicationModal(this.newApplicationdata);
    } else {
      if (this.selectedProject && this.selectedCreditApplication) {
        const creditApplications = [
          {
            caSeqId: this.selectedCreditApplication.caSeqId,
            caNumber: this.selectedCreditApplication.caNUmber,
            customerStatus: this.selectedCreditApplication.customerStatus,
          },
        ];
        const data = {
          projectId: this.selectedProject.projectId,
          projectName: this.selectedProject.projectName,
          creditApplications: creditApplications,
        };
        this.result = data;
        if (
          creditApplications[0].customerStatus ===
          this.dashboard.finishApplication
        ) {
          this.aacknowledgeConditions(this.result);
        } else {
          this.openAcknowledgemodal(data);
        }
      }
    }
  }

  /**
   * getDepartmentNames() - to display the departments with comma seperated values
   * @param departments - to pass the departements for the modal
   * @returns - departments with comma seperated values
   */
  getDepartmentNames(departments: any[]): string {
    return departments
      .map((department) => department.departments.departmentName)
      .join(', ');
  }

  /**
   * openAcknowledgemodal() - method to open the acknowledge modal
   * @param acknowledgemodal - template reference to the acknowledge modal
   */
  openAcknowledgemodal(data: any) {
    if (this.modalReference) {
      this.modalReference.close();
    }

    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(this.acknowledgemodal, {
      size: 'md',
      centered: true,
    });
  }

  /**
   * getAlertMessage() - method to display the alert message
   */
  getAlertMessage() {
    this.alertService.alertMessage$.subscribe((message) => {
      this.alertMessage = message;
    });
  }
}
