<div class="page-wrapper-modal" [formGroup]="creditApplicationForm">
    <div class="page-header">
    </div>
    <div class="refrence-letter-section">
        <div class="container cm-main-container">
            <div class="common-checkbox-section">
                <div class="row">
                    <h3 class="checkbox-heading mb-4">Credit Application Departments</h3>

                    <div class="col-md-4 col-lg-4 col-sm-6 col-6 mb-3"
                        [ngClass]="{'col-lg-12 col-md-12 col-sm-12 col-12': first}"
                        *ngFor="let dept of departmentsData; let first = first">
                        <div class="common-checkbox-button">
                            <input type="checkbox" [id]="'custom-checkbox-' + dept.departmentId" class="common-checkbox-input"
                              formControlName="department{{dept.departmentId}}" [value]="dept.departmentId"
                              (change)="onDepartmentCheckboxChange($event,dept)" />
                            <label  class="common-checkbox-label">
                              {{ dept.departmentName }}
                            </label>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="save-btn-wrapper">
        <div class="d-flex flex-row align-items-center g-3">
            <button class="common-btn-light btn" (click)="closeModal()">Cancel</button>
            <button class="common-btn-secondary btn save-btn" [disabled]="!isAtLeastOneSelected()"
                (click)="onSubmit()">Start Application</button>
        </div>
    </div>
</div>

<ng-template #successTpl>
    <div class="success">{{successMessage}}</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{errorMessage.message}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>

<ng-template #acknowledgemodal let-modal>
    <div class="modal-body p-0">
        <div class="page-wrapper-modal">
            <div class="page-header mb-4">
                <h2>{{ acknowledgeConstants.acknowledgeLabel }}</h2>
            </div>
            <div>
                <label class="mb-3">
                    <ul>
                        <li> {{ acknowledgeConstants.acknowledgeConditionOne }} </li>
                        <li> {{ acknowledgeConstants.acknowledgeConditionTwo }} </li>
                    </ul>
                </label>
            </div>

            <div class="button">
                <button type="button" class="common-btn-light btn" (click)="modal.close()">{{
                    acknowledgeConstants.cancelButton }}</button>
                <button type="button" class="common-btn-secondary btn" (click)="acknowledgeConditions(modal)">{{
                    acknowledgeConstants.acknowledgeConditionsBtn }}</button>
            </div>
        </div>
    </div>
</ng-template>