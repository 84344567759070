import { Component, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-review-credit-app',
  templateUrl: './review-credit-app.component.html',
  styleUrls: ['./review-credit-app.component.scss']
})
export class ReviewCreditAppComponent {
  constructor(private modalService:NgbModal){}

  openDeleteConfirmationModal(content: TemplateRef<any>): void {
    this.modalService.open(content, { size: 'lg' , centered: true });
  }
  openDeleteConfirmationModalOne(content1: TemplateRef<any>): void {
    this.modalService.open(content1, { size: 'lg' , centered: true });
  }
  openDeleteConfirmationModalTwo(content2: TemplateRef<any>): void {
    this.modalService.open(content2, { size: 'lg' , centered: true });
  }
  openDeleteConfirmationModalFour(content4: TemplateRef<any>): void {
    this.modalService.open(content4, { size: 'lg' , centered: true });
  }
  
}
