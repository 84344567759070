<div class="project-wrapper">
<div class="container">
  <div class="row">
    <div class="comman-title-block">
      <div class="comman-title-section">
        <h1>Requests</h1>
        <!-- Button to open the modal -->
        <button class="common-btn-primary btn" (click)="openRequestsModal(requestModal)">+ Make a Request</button>
      </div>
    </div>
    <!-- Other content -->
  </div>
</div>
</div>

<ng-template #requestModal let-modal>
  <div class="modal-body justify-content-center">
    <div class="cm-header-section">
      <h5 class="modal-title" id="commonModalLabel">Add A Request</h5>
      <p *ngIf="userName && companyName" class="cm-subtitle mb-3">{{ userName }} - {{ companyName }}</p>
      
      <button (click)="modal.dismiss('Cross click')" type="button" class="btn-close" data-bs-dismiss="modal"
        aria-label="Close"><span class="cm-close-text">Close</span></button>
    </div>
    <div class="container cm-main-container">
      
       
      
      <div class="common-modal-input-section">
     
          <div class="w-100">
            <div class="col-md-6  custom-dropdown custom-floating-label px-0">
              <label for="prodComState" class="form-label">Projects</label>
            
                <select class="custom-select" (change)="onProjectSelect($event)">
                    <option class="dropdown-option" value="" disabled selected hidden>Select Project</option>
                    <option *ngFor="let project of projects" [value]="project.projectId">
                      {{ project.projectName }}
                    </option>
                </select>
            </div>
            </div>
      

         
          <div class="col-12">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" (change)="onRadioChange($event)" type="radio" name="requestType"
                id="hotLockStage" />
              <label class="common-radio-label" for="hotLockStage">
                Hot Lock Stage Request(SalesForce)
              </label>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" (change)="onRadioChange($event)" type="radio" name="requestType"
                id="cognitoForm" />
              <label class="common-radio-label" for="cognitoForm">
                Set-Lighting Request(Cognito)
              </label>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="common-radio-btn">
              <input class="common-radio-input mb-0" (change)="onRadioChange($event)" type="radio" name="requestType"
                id="hot-lock" value="d/m/yyyy" />
              <label class="common-radio-label" for="hot-lock">
                Hot-Lock Request(Cognito)
              </label>
            </div>
          </div>
        </div>
    
      <div class="cm-btn-section">
        <button type="button" class="btn common-btn-secondary me-2"  [disabled]="!isNextEnabled || !isProjectSelected" data-bs-dismiss="modal"
          (click)="navigateToSalesforce()">Next</button>
      </div>
    </div>
  </div>
</ng-template>
