<div *ngIf="isAuthenticated">
  <app-menu [portalType]="portalType"></app-menu>
</div>
<div class="container-fluid page-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
                <h1 class="page-title px-0 mb-0">{{constants.directory}}</h1>
                <div class="d-flex align-items-center view-text ">
                    <div>
                        <p class="me-3 mb-0 change-weather-title-block">You are viewing the Directory for <span class="change-weather-title">Burbank</span> <span class="change-weather-toggle"> <span>Change</span> <i class="bi bi-geo-alt-fill"></i>
                        </span></p>
                    </div>
                  
                </div>
            </div>
            <div class="col-md-4 col-lg-4 d-none d-md-block">
                <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <li
                    class="nav-link"
                    id="office-production-services-tab"
                    role="tab"
                    aria-controls="office-production-services-content"
                    aria-selected="true"
                    (click)="selectTab(constants.office_production_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'office-production-services-tab'}"
                  > {{constants.office_and_production_services}}
                  </li>
                  <li
                    class="nav-link"
                    id="safety-environmental-affairs-tab"
                    role="tab"
                    aria-controls="safety-environmental-affairs-content"
                    aria-selected="false"
                    (click)="selectTab(constants.safety_environmental_affairs_tab)"
                    [ngClass]="{'show active': selectedTab === 'safety-environmental-affairs-tab'}"
                  > {{constants.safety_and_environment_affairs}}
                  </li>
                  <li
                    class="nav-link"
                    id="studio-protection-group-tab"
                    role="tab"
                    aria-controls="studio-protection-group-content"
                    aria-selected="false"
                    (click)="selectTab(constants.studio_protection_group_tab)"
                    [ngClass]="{'show active': selectedTab === 'studio-protection-group-tab'}"
                  > {{constants.studio_protection_group}}
                  </li>
                  <li
                    class="nav-link"
                    id="construction-services-tab"
                    role="tab"
                    aria-controls="construction-services-content"
                    aria-selected="false"
                    (click)="selectTab(constants.construction_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'construction-services-tab'}"
                  > {{constants.construction_services}}
                  </li>
                  <li
                    class="nav-link"
                    id="costume-tab"
                    role="tab"
                    aria-controls="costume-content"
                    aria-selected="false"
                    (click)="selectTab(constants.costume_tab)"
                    [ngClass]="{'show active': selectedTab === 'costume-tab'}"
                  > {{constants.costume}}
                  </li>
                  <li
                    class="nav-link"
                    id="design-studio-tab"
                    role="tab"
                    aria-controls="design-studio-content"
                    aria-selected="false"
                    (click)="selectTab(constants.design_studio_tab)"
                    [ngClass]="{'show active': selectedTab === 'design-studio-tab'}"
                  > {{constants.design_studio}}
                  </li>
                  <li
                    class="nav-link"
                    id="post-production-creative-services-tab"
                    role="tab"
                    aria-controls="post-production-creative-services-content"
                    aria-selected="false"
                    (click)="selectTab(constants.post_production_creative_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'post-production-creative-services-tab'}"
                  > {{constants.post_production_creative_services}}
                  </li>
                  <li
                    class="nav-link"
                    id="production-sound-video-tab"
                    role="tab"
                    aria-controls="production-sound-video-content"
                    aria-selected="false"
                    (click)="selectTab(constants.production_sound_video_tab)"
                    [ngClass]="{'show active': selectedTab === 'production-sound-video-tab'}"
                  > {{constants.production_sound_and_video}}
                  </li>
                  <li
                    class="nav-link"
                    id="property-drapery-floor-covering-tab"
                    role="tab"
                    aria-controls="property-drapery-floor-covering-content"
                    aria-selected="false"
                    (click)="selectTab(constants.property_drapery_floor_covering_tab)"
                    [ngClass]="{'show active': selectedTab === 'property-drapery-floor-covering-tab'}"
                  > {{constants.property_drapery_floor_covering}}
                  </li>
                  <li
                    class="nav-link"
                    id="food-services-special-events-tab"
                    role="tab"
                    aria-controls="food-services-special-events-content"
                    aria-selected="false"
                    (click)="selectTab(constants.food_services_special_events_tab)"
                    [ngClass]="{'show active': selectedTab === 'food-services-special-events-tab'}"
                  > {{constants.food_services_special_events}}
                  </li>
                  <li
                    class="nav-link"
                    id="grip-tab"
                    role="tab"
                    aria-controls="grip-content"
                    aria-selected="false"
                    (click)="selectTab(constants.grip_tab)"
                    [ngClass]="{'show active': selectedTab === 'grip-tab'}"
                  > {{constants.grip}}
                  </li>
                  <li
                    class="nav-link"
                    id="set-lighting-tab"
                    role="tab"
                    aria-controls="set-lighting-content"
                    aria-selected="false"
                    (click)="selectTab(constants.set_lighting_tab)"
                    [ngClass]="{'show active': selectedTab === 'set-lighting-tab'}"
                  > {{constants.set_lighting}}
                  </li>
                  <li
                    class="nav-link"
                    id="special-effects-tab"
                    role="tab"
                    aria-controls="special-effects-content"
                    aria-selected="false"
                    (click)="selectTab(constants.special_effects_tab)"
                    [ngClass]="{'show active': selectedTab === 'special-effects-tab'}"
                  > {{constants.special_effects}}
                  </li>
                  <li
                    class="nav-link"
                    id="transportation-tab"
                    role="tab"
                    aria-controls="transportation-content"
                    aria-selected="false"
                    (click)="selectTab(constants.transportation_tab)"
                    [ngClass]="{'show active': selectedTab === 'transportation-tab'}"
                  > {{constants.transportation}}
                  </li>
                  <li
                    class="nav-link"
                    id="labor-tab"
                    role="tab"
                    aria-controls="labor-content"
                    aria-selected="false"
                    (click)="selectTab(constants.labor_tab)"
                    [ngClass]="{'show active': selectedTab === 'labor-tab'}"
                  > {{constants.labor}}
                  </li>
                  <li
                    class="nav-link"
                    id="utilities-tab"
                    role="tab"
                    aria-controls="utilities-content"
                    aria-selected="false"
                    (click)="selectTab(constants.utilities_tab)"
                    [ngClass]="{'show active': selectedTab === 'utilities-tab'}"
                  > {{constants.utilites}}
                  </li>
                  <li
                    class="nav-link"
                    id="studio-tours-tab"
                    role="tab"
                    aria-controls="studio-tours-content"
                    aria-selected="false"
                    (click)="selectTab(constants.studio_tours_tab)"
                    [ngClass]="{'show active': selectedTab === 'studio-tours-tab'}"
                  > {{constants.studio_tours}}
                  </li>
                  <li
                    class="nav-link"
                    id="additional-services-tab"
                    role="tab"
                    aria-controls="additional-services-content"
                    aria-selected="false"
                    (click)="selectTab(constants.additional_services_tab)"
                    [ngClass]="{'show active': selectedTab === 'additional-services-tab'}"
                  > {{constants.additional_on_lot_client_employee_services}}
                  </li>
                </ol>
              </div>
              
              <div class="col-md-4 col-lg-4 col-sm-12 d-block d-md-none mobile-select-block">
                <select class="form-select" id="mobileSelect" [(ngModel)]="selectedTab" (change)="onSelectChange($event)">
                  <option value="office-production-services-tab">{{constants.office_and_production_services}}
                  </option>
                  <option value="safety-environmental-affairs-tab">{{constants.safety_and_environment_affairs}}
                  </option>
                  <option value="studio-protection-group-tab">{{constants.studio_protection_group}}
                  </option>
                  <option value="construction-services-tab">{{constants.construction_services}}
                  </option>
                  <option value="costume-tab">{{constants.costume}}
                  </option>
                  <option value="design-studio-tab">{{constants.design_studio}}
                  </option>
                  <option value="post-production-creative-services-tab">{{constants.post_production_creative_services}}
                  </option>
                  <option value="production-sound-video-tab">{{constants.production_sound_and_video}}
                  </option>
                  <option value="property-drapery-floor-covering-tab">{{constants.property_drapery_floor_covering}}
                  </option>
                  <option value="food-services-special-events-tab">{{constants.food_services_special_events}}
                  </option>
                  <option value="grip-tab">{{constants.grip}}
                  </option>
                  <option value="set-lighting-tab">{{constants.set_lighting}}
                  </option>
                  <option value="special-effects-tab">{{constants.special_effects}}
                  </option>
                  <option value="transportation-tab">{{constants.transportation}}
                  </option>
                  <option value="labor-tab">{{constants.labor}}
                  </option>
                  <option value="utilities-tab">{{constants.utilites}}
                  </option>
                  <option value="studio-tours-tab">{{constants.studio_tours}}
                  </option>
                  <option value="additional-services-tab">
                    {{constants.additional_on_lot_client_employee_services}}
                  </option>
                </select>
              </div>
              
              <div class="col-md-8 col-lg-8 col-sm-12">
                <!-- Tab Content -->
                <div class="tab-content" id="v-pills-tabContent">
                  <!-- OFFICE & PRODUCTION SERVICES (NEW) -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'office-production-services-tab'}"
                  id="office-production-services-tab" role="tabpanel" aria-labelledby="office-production-services-tab">
                  <div class="page-details-section">
                      <h2 class="page-sub-heading">{{constants.office_and_production_services}}</h2>
                      <div class="dispatch-list-section">
                          <h3 class="list-title">General Office:</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>OPS Call Center and Copier Services/Canon Help Desk</li>
                              <li>Phone: <span class="contact-details">818-954-6777</span></li>
                              <li>Email: <span class="text-link clickable contact-details"><a href="mailto:wbsocallcenter@warnerbros.com">wbsocallcenter@warnerbros.com</a></span></li>
                          </ul>
                      </div>
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Sound Stage & Exterior Set Inquiries (Main Lot & Ranch) – New Productions Only</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-2577</span></li>
                              <li>Website: <span class="text-link clickable contact-details"><a href="http://www.wbstagesandsets.com" target="_blank">www.wbstagesandsets.com</a></span></li>
                          </ul>
                      </div>
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Parking Services</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-7275 (4-PARK)</span></li>
                              <li>Email: <span class="text-link clickable contact-details"><a href="mailto:chris.owens@warnerbros.com">chris.owens@warnerbros.com</a></span> and <span class="text-link clickable contact-details"><a href="mailto:gregg.pawlik@warnerbros.com">gregg.pawlik@warnerbros.com</a></span></li>
                          </ul>
                      </div>
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Publicity Clearances (Justin Delaney)</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-6337</span></li>
                              <li>Email: <span class="text-link clickable contact-details"><a href="mailto:justin.delaney@warnerbros.com">justin.delaney@warnerbros.com</a></span></li>
                          </ul>
                      </div>
                  </div>  
              </div>
              
              
                  <!-- SAFETY & ENVIRONMENTAL AFFAIRS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'safety-environmental-affairs-tab'}"
                    id="safety-environmental-affairs-tab" role="tabpanel" aria-labelledby="safety-environmental-affairs-tab">
                    <div class="page-details-section">
                        <h2 class="page-sub-heading">{{constants.safety_and_environment_affairs}}</h2>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Anonymous Safety Hotline</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2800</span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Toll-Free Hotline</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">877-566-8001</span></li>
                            </ul>
                        </div>
                        <div class="dispatch-list-section">
                            <h3 class="list-title">Food Safety Hotline</h3>
                            <ul class="dispatch-list bullet-list">
                                <li>Phone: <span class="contact-details">818-954-2350</span></li>
                            </ul>
                        </div>
                    </div>
                    
                  </div>
              
                  <!-- STUDIO PROTECTION GROUP -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-protection-group-tab'}"
                  id="studio-protection-group-tab" role="tabpanel" aria-labelledby="studio-protection-group-tab">
                  <div class="page-details-section">
                      <h2 class="page-sub-heading">{{constants.studio_protection_group}}</h2>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Dispatch</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-1248</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:WBSFDispatch@warnerbros.com">WBSFDispatch@warnerbros.com</a></li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Watch Commander – Security</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-4838</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:WBSFWatchCommander@warnerbros.com">WBSFWatchCommander@warnerbros.com</a></li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Card Access</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-5222</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:WBSFCardAccess@warnerbros.com">WBSFCardAccess@warnerbros.com</a></li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Scheduling</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-1294</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:WBSFSecurityScheduling@warnerbros.com">WBSFSecurityScheduling@warnerbros.com</a></li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Burbank Fire Department – On-Site Captain – Ryan Weber</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Mobile: <span class="contact-details">818-531-5509</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:rweber@burbankca.gov">rweber@burbankca.gov</a></li>
                              <li>On-Site: Tuesday-Friday weekly, aside from holidays; available otherwise by mobile or email.</li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">Warner Bros. Fire Station 18</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-1261</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:WBFireStation18@warnerbros.com">WBFireStation18@warnerbros.com</a></li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">First Aid – Main Lot</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-954-1201</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:MainLotFirstAid@warnerbros.com">MainLotFirstAid@warnerbros.com</a></li>
                          </ul>
                      </div>
              
                      <div class="dispatch-list-section">
                          <h3 class="list-title">First Aid – Ranch</h3>
                          <ul class="dispatch-list bullet-list">
                              <li>Phone: <span class="contact-details">818-977-5251</span></li>
                              <li>Email: <a class="text-link clickable contact-details" href="mailto:RanchFirstAid@warnerbros.com">RanchFirstAid@warnerbros.com</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
              
              
                  <!-- CONSTRUCTION SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'construction-services-tab'}"
                  id="construction-services-tab" role="tabpanel" aria-labelledby="construction-services-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.construction_services}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Gene Owens & Bruce Jepsen</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1260</span></li>
                             <li>Email: <a href="mailto:WBSOConstSvc@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">WBSOConstSvc@warnerbros.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- COSTUME -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'construction-services-tab'}"
                  id="construction-services-tab" role="tabpanel" aria-labelledby="construction-services-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.construction_services}}</h2>
                     
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Gene Owens & Bruce Jepsen</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1260</span></li>
                             <li>Email: 
                                 <a href="mailto:WBSOConstSvc@warnerbros.com" class="text-link clickable">
                                     <span class="contact-details">WBSOConstSvc@warnerbros.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
                 </div>
             </div>
             
             <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'costume-tab'}"
                  id="costume-tab" role="tabpanel" aria-labelledby="costume-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.costume}}</h2>
                     
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Rentals</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1297</span></li>
                             <li>Email: 
                                 <a href="mailto:wbsfcostumedesk@warnerbros.com" class="text-link clickable">
                                     <span class="contact-details">wbsfcostumedesk@warnerbros.com</span>
                                 </a>
                             </li>
                             <li>Website: 
                                 <a href="http://www.wbcostumedept.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">www.wbcostumedept.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
                     
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Administration</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-5693</span></li>
                             <li>Email: 
                                 <a href="mailto:wbcostumesnewaccounts@warnerbros.com" class="text-link clickable">
                                     <span class="contact-details">wbcostumesnewaccounts@warnerbros.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
                     
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Alterations</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2017</span></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- DESIGN STUDIO -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'design-studio-tab'}"
                  id="design-studio-tab" role="tabpanel" aria-labelledby="design-studio-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.design_studio}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">New Projects (Pam Lord)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-7118</span></li>
                             <li>Email: <a href="mailto:pam.lord@warnerbros.com" class="text-link clickable contact-details">pam.lord@warnerbros.com</a></li>
                             <li>Website: <a href="http://www.warnerbrosdesignstudio.com" target="_blank" class="text-link clickable contact-details">www.warnerbrosdesignstudio.com</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Metal Shop</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-7118</span></li>
                             <li>Email: <a href="mailto:wbds@warnerbros.com" class="text-link clickable contact-details">wbds@warnerbros.com</a></li>
                             <li>Website: <a href="https://www.warnerbrosdesignstudio.com/shops/metal-shop/" target="_blank" class="text-link clickable contact-details">https://www.warnerbrosdesignstudio.com/shops/metal-shop/</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Paint Shop</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1817</span></li>
                             <li>Email: <a href="mailto:wbsfpaint@warnerbros.com" class="text-link clickable contact-details">wbsfpaint@warnerbros.com</a></li>
                             <li>Website: <a href="https://www.warnerbrosdesignstudio.com/shops/paint-shop/" target="_blank" class="text-link clickable contact-details">https://www.warnerbrosdesignstudio.com/shops/paint-shop/</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Photo Lab</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-7118</span></li>
                             <li>Email: <a href="mailto:photolab@warnerbros.com" class="text-link clickable contact-details">photolab@warnerbros.com</a></li>
                             <li>Website: <a href="https://www.warnerbrosdesignstudio.com/shops/photo-lab/" target="_blank" class="text-link clickable contact-details">https://www.warnerbrosdesignstudio.com/shops/photo-lab/</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Sign & Scenic Art</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1815</span></li>
                             <li>Email: <a href="mailto:wbsigns@warnerbros.com" class="text-link clickable contact-details">wbsigns@warnerbros.com</a></li>
                             <li>Website: <a href="https://www.warnerbrosdesignstudio.com/shops/sign-scenic-art/" target="_blank" class="text-link clickable contact-details">https://www.warnerbrosdesignstudio.com/shops/sign-scenic-art/</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Staff Shop (Moldings)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2269</span></li>
                             <li>Email: <a href="mailto:wbds@warnerbros.com" class="text-link clickable contact-details">wbds@warnerbros.com</a></li>
                             <li>Website: <a href="https://www.warnerbrosdesignstudio.com/shops/staff-shop/" target="_blank" class="text-link clickable contact-details">https://www.warnerbrosdesignstudio.com/shops/staff-shop/</a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
             
              
                  <!-- POST PRODUCTION CREATIVE SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'post-production-creative-services-tab'}"
                  id="post-production-creative-services-tab" role="tabpanel" aria-labelledby="post-production-creative-services-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.post_production_creative_services}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">General Info</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2515</span></li>
                             <li>Email: <a href="mailto:wbsfpostproduction@warnerbros.com" class="text-link clickable contact-details">
                                 wbsfpostproduction@warnerbros.com</a></li>
                             <li>Website: <a href="http://www.wbppcs.com" target="_blank" class="text-link clickable contact-details">
                                 www.wbppcs.com</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Motion Picture Imaging</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2464</span></li>
                             <li>Email: <a href="mailto:mpi-clientservicesreception@warnerbros.onmicrosoft.com" class="text-link clickable contact-details">
                                 mpi-clientservicesreception@warnerbros.onmicrosoft.com</a></li>
                             <li>Website: <a href="http://www.wbppcs.com/burbank-picture" target="_blank" class="text-link clickable contact-details">
                                 www.wbppcs.com/burbank-picture</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Projection / Screening Rooms (Betty Soto)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2144</span></li>
                             <li>Email: <a href="mailto:betty.soto@warnerbros.com" class="text-link clickable contact-details">
                                 betty.soto@warnerbros.com</a></li>
                             <li>Website: <a href="http://www.wbppcs.com/burbank-screening-rooms" target="_blank" class="text-link clickable contact-details">
                                 www.wbppcs.com/burbank-screening-rooms</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Scoring Stage (Jamie Olvera)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-6800</span></li>
                             <li>Email: <a href="mailto:jamie.olvera@warnerbros.com" class="text-link clickable contact-details">
                                 jamie.olvera@warnerbros.com</a></li>
                             <li>Website: <a href="http://www.wbppcs.com/facilities/scoring" target="_blank" class="text-link clickable contact-details">
                                 www.wbppcs.com/facilities/scoring</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">WB Game Audio (Emma Emrich)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2467</span></li>
                             <li>Email: <a href="mailto:emma.emrich@warnerbros.com" class="text-link clickable contact-details">
                                 emma.emrich@warnerbros.com</a></li>
                             <li>Website: <a href="http://www.wbgameaudio.com" target="_blank" class="text-link clickable contact-details">
                                 www.wbgameaudio.com</a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">2 Pop Music Editorial (Irene Montero)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2318</span></li>
                             <li>Email: <a href="mailto:irene.montero@warnerbros.com" class="text-link clickable contact-details">
                                 irene.montero@warnerbros.com</a></li>
                             <li>Website: <a href="http://www.2popmusic.com" target="_blank" class="text-link clickable contact-details">
                                 www.2popmusic.com</a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- PRODUCTION SOUND & VIDEO -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'production-sound-video-tab'}"
                  id="production-sound-video-tab" role="tabpanel" aria-labelledby="production-sound-video-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.production_sound_and_video}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">General Info</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2511</span></li>
                             <li>Email: <a href="mailto:wbsfproductionsound@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfproductionsound@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbsoundandvideo.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbsoundandvideo.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Rentals</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4202</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Wireless Frequency Review (Ara Mkhitaryan)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2736</span></li>
                             <li>Email: <a href="mailto:Ara.Mkhitaryan@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">Ara.Mkhitaryan@warnerbros.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- PROPERTY | DRAPERY | FLOOR COVERING -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'property-drapery-floor-covering-tab'}"
                  id="property-drapery-floor-covering-tab" role="tabpanel" aria-labelledby="property-drapery-floor-covering-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.property_drapery_floor_covering}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">General Rentals</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2181</span></li>
                             <li>Email: <a href="mailto:wbsfproperty@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfproperty@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbpropertydept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbpropertydept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">The Collection</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-7405</span></li>
                             <li>Email: <a href="mailto:wbsfproperty@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfproperty@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbpropertydept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbpropertydept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Hand Props</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-3547</span></li>
                             <li>Email: <a href="mailto:wbsfproperty@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfproperty@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbpropertydept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbpropertydept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Hardware</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1335</span></li>
                             <li>Email: <a href="mailto:wbsfproperty@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfproperty@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbpropertydept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbpropertydept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Lighting Fixtures</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1888</span></li>
                             <li>Email: <a href="mailto:wbsfproperty@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfproperty@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbpropertydept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbpropertydept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Drapery</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4426</span></li>
                             <li>Email: <a href="mailto:wbsfdrapery@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsfdrapery@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbpropertydept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbpropertydept.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- FOOD SERVICES | SPECIAL EVENTS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'food-services-special-events-tab'}"
                  id="food-services-special-events-tab" role="tabpanel" aria-labelledby="food-services-special-events-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.food_services_special_events}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Food Services – General Info</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4203</span></li>
                             <li>Website: 
                                 <a href="http://food.wb.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">food.wb.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Commissary Fine Dining Room – Reservations</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4220</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Master Chefs Production Catering</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1267</span></li>
                             <li>Email: 
                                 <a href="mailto:ProductionCatering@warnerbros.com" class="text-link clickable">
                                     <span class="contact-details">ProductionCatering@warnerbros.com</span>
                                 </a>
                             </li>
                             <li>Website: 
                                 <a href="http://food.wb.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">food.wb.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Road Runner – Delivery Service for Craft Service & Meals</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4202</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Special Events</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2652</span></li>
                             <li>Email: 
                                 <a href="mailto:wbspecialevents@warnerbros.com" class="text-link clickable">
                                     <span class="contact-details">wbspecialevents@warnerbros.com</span>
                                 </a>
                             </li>
                             <li>Website: 
                                 <a href="http://www.wbspecialevents.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">www.wbspecialevents.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- GRIP -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'grip-tab'}"
                  id="grip-tab" role="tabpanel" aria-labelledby="grip-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.grip}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">General Info</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1590</span></li>
                             <li>Website: <a href="http://www.wbgripdept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbgripdept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Equipment Rentals – Pacific Ave.</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1863</span></li>
                             <li>Website: <a href="http://www.wbgripdept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbgripdept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Maintenance</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1929</span></li>
                             <li>Website: <a href="http://www.wbgripdept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbgripdept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Scene Dock</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1387</span></li>
                             <li>Website: <a href="http://www.wbgripdept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbgripdept.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Canvas Room</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2671</span></li>
                             <li>Website: <a href="http://www.wbgripdept.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbgripdept.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- SET LIGHTING -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'set-lighting-tab'}"
                  id="set-lighting-tab" role="tabpanel" aria-labelledby="set-lighting-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.set_lighting}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">General Info</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2575</span></li>
                             <li>Website: 
                                 <a href="http://www.wbsetlighting.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">www.wbsetlighting.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Equipment Rentals – Pacific Ave.</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4806</span></li>
                             <li>Website: 
                                 <a href="http://www.wbsetlighting.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">www.wbsetlighting.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Repair Shop – Pacific Ave.</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2322</span></li>
                             <li>Website: 
                                 <a href="http://www.wbsetlighting.com" target="_blank" class="text-link clickable">
                                     <span class="contact-details">www.wbsetlighting.com</span>
                                 </a>
                             </li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- SPECIAL EFFECTS -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'special-effects-tab'}"
                  id="special-effects-tab" role="tabpanel" aria-labelledby="special-effects-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.special_effects}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Services & Permit Requests (Tyler Matson)</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-1365</span></li>
                             <li>Email: <a href="mailto:tyler.matson@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">tyler.matson@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbspecialeffects.com" class="text-link clickable" target="_blank">
                                 <span class="contact-details">www.wbspecialeffects.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- TRANSPORTATION -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'transportation-tab'}"
                  id="transportation-tab" role="tabpanel" aria-labelledby="transportation-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.transportation}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Dispatch & Equipment Rentals</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4106</span></li>
                             <li>Email: <a href="mailto:wbsftransportation@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">wbsftransportation@warnerbros.com</span>
                             </a></li>
                             <li>Website: <a href="http://www.wbsftransportation.com" target="_blank" class="text-link clickable">
                                 <span class="contact-details">www.wbsftransportation.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- LABOR -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'labor-tab'}"
                  id="labor-tab" role="tabpanel" aria-labelledby="labor-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.labor}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Pam Marrow, Labor (Studio Utilities): Trash Bins, Non-Set Cleaning</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2009</span></li>
                             <li>Email: <a href="mailto:pam.marrow@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">pam.marrow@warnerbros.com</span>
                             </a></li>
                         </ul>
                     </div>
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Daniel Fregoso, Labor (Craft Service): Set & Stage Cleaning, Supply Rentals</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2900</span></li>
                             <li>Email: <a href="mailto:daniel.fregoso@warnerbros.com" class="text-link clickable">
                                 <span class="contact-details">daniel.fregoso@warnerbros.com</span>
                             </a></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
              
                  <!-- UTILITIES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'utilities-tab'}"
                  id="utilities-tab" role="tabpanel" aria-labelledby="utilities-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.utilites}}</h2>
             
                     <div class="dispatch-list-section">
                         <ul class="dispatch-list bullet-list mt-4">
                             <li>Air Conditioning: <span class="contact-details">818-954-1700</span></li>
                             <li>Electrical: <span class="contact-details">818-954-1700</span></li>
                             <li>Plumbing: <span class="contact-details">818-954-1700</span></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
             <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'studio-tours-tab'}"
             id="studio-tours-tab" role="tabpanel" aria-labelledby="studio-tours-tab">
            <div class="page-details-section">
                <h2 class="page-sub-heading">{{constants.studio_tours}}</h2>
        
                <div class="dispatch-list-section">
                    <h3 class="list-title">WB Studio Tour Hollywood</h3>
                    <ul class="dispatch-list bullet-list">
                        <li>Phone: <span class="contact-details">818-977-8687</span></li>
                        <li>Email: 
                            <a href="mailto:studio.tour@warnerbros.com" class="text-link clickable">
                                <span class="contact-details">studio.tour@warnerbros.com</span>
                            </a>
                        </li>
                        <li>Website: 
                            <a href="http://www.wbstudiotour.com" target="_blank" class="text-link clickable">
                                <span class="contact-details">www.wbstudiotour.com</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
              
                  <!-- ADDITIONAL ON-LOT CLIENT & EMPLOYEE SERVICES -->
                  <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'additional-services-tab'}"
                  id="additional-services-tab" role="tabpanel" aria-labelledby="additional-services-tab">
                 <div class="page-details-section">
                     <h2 class="page-sub-heading">{{constants.additional_on_lot_client_employee_services}}</h2>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">DirecTV – Lindsey Richman</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">310-490-5068</span></li>
                             <li>Email: <a href="mailto:lindsey@gettvdirect.com" class="text-link clickable">
                                 <span class="contact-details">lindsey@gettvdirect.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">E-Car – Electric Cart Vendor</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-764-4500</span></li>
                             <li>Email: <a href="mailto:ecargolfcarts@gmail.com" class="text-link clickable">
                                 <span class="contact-details">ecargolfcarts@gmail.com</span>
                             </a></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">First Entertainment Credit Union – Warner Bros. Branch</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">888-800-3328</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Fitness Center</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-4242</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Hair Salon</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-2951</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Mothers Rooms – Info</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-954-6777</span></li>
                         </ul>
                     </div>
             
                     <div class="dispatch-list-section">
                         <h3 class="list-title">Victor’s Shoe Shine & Repair</h3>
                         <ul class="dispatch-list bullet-list">
                             <li>Phone: <span class="contact-details">818-445-0881</span></li>
                         </ul>
                     </div>
                 </div>
             </div>
             
             
                </div>
              </div>
              

            </div>
            </div>
</div>
