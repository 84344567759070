import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RISK_EMAIL_CONSTANTS } from '../../../constants/studio-portal.constant';


@Component({
  selector: 'app-risk-email',
  templateUrl: './risk-email.component.html',
  styleUrls: ['./risk-email.component.scss']
})
export class RiskEmailComponent {
  constants=RISK_EMAIL_CONSTANTS;
  constructor(public activeModal:NgbActiveModal){}
  
  onClose(): void {
    this.activeModal.close();
  }
  
}
