<form>
    <div class="row row-cols-sm-auto">
        <div class="col-12 position-absolute">
            <!-- This hidden div is used for rendering custom day templates within the datepicker -->
            <div class="dp-hidden">
                <div class="input-group multiselect-dropdown">
                    <div class="range-dates">
                        <ng-template #t let-date let-focused="focused">
                            <span class="custom-day" [class.focused]="isToday(date)" [class.range]="isRange(date)"
                                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                                (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                            </span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group multiselect-dropdown custom-date-div" placement="top"
                    ngbTooltip="Select a date or date range"  >
                <input #dpToDate [placeholder]="placeholder" (input)="toDate = validateInput(toDate, dpToDate.value)"
                    (click)="openDatepicker(datepicker)" ngbDatepicker #datepicker="ngbDatepicker" [firstDayOfWeek]="calenderFormat"  [autoClose]="'false'"
                    (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden"
                    tabIndex=-1 [minDate]="{ year: 1900, month: 1, day: 1 }" [footerTemplate]="footerTemplate"
                    class="custom-date-input dropdown-btn" />
                <button class="custom-date-button btn bi" (click)="datepicker.toggle()" type="button">
                    <span class="datepicker-multiselect__caret"></span>
                </button>
            </div>
        </div>
    </div>
</form>

<!-- Footer template for datepicker actions -->
<ng-template #footerTemplate>
    <hr class="my-0" />
    <div class="button-group">
        <button *ngIf="hasSelectedDate" class="btn btn-primary w-100" (click)="onApply(datepicker)">
            Apply
        </button>
        <button *ngIf="!hasSelectedDate" class="btn btn-secondary w-100" (click)="onCancel(datepicker)">
           Close
        </button>
    </div>
</ng-template>
