import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CREDIT_APP_FORM_CONSTANTS } from '../../../constants/studio-portal.constant';
import { CREDIT_APP_REPORT_SECTION } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { ViewCreditAppServiceService } from 'src/app/shared/services/view-credit-app-service/view-credit-app-service.service';

@Component({
  selector: 'app-final-cred-app-form',
  templateUrl: './final-cred-app-form.component.html',
  styleUrls: ['./final-cred-app-form.component.scss']
})
export class FinalCredAppFormComponent {
  constants = CREDIT_APP_FORM_CONSTANTS;
  responseDandBreport: unknown;
  requestResponse: any;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @Input() rowData: any;


  constructor(private loaderService: LoaderService, private toastService: ToastService,
    private sharedViewCreditAppService: ViewCreditAppServiceService
  ) {}

  ngOnInit() { }
  /**
* viewDnBReport() - method to call  the getDandBReportAPI method
*/
  viewDnBReport(): void {
    const section = CREDIT_APP_REPORT_SECTION.dnbSection;
    this.getDandBReportAPI(this.responseDandBreport, section);
  }

  /**
   * getDandBReportAPI() - Fetches the D&B report from the API and opens it in a new tab.
   * @param url - The API endpoint to fetch the D&B report.
   * @param section - The section identifier for the report.
   */
  getDandBReportAPI(url: any, section: any): void {
    this.loaderService.setLoadingState(true);
    this.sharedViewCreditAppService.getCreditAppReport(url, section)
      .pipe()
      .subscribe({
        next: (response: any) => {
          const docUrl = response?.url;
          this.loaderService.setLoadingState(false);
          window.open(docUrl, '_blank');
        },
        error: (error) => {
          console.error(error);
          this.requestResponse = error;
          this.loaderService.setLoadingState(false);
          this.showDanger(this.dangerTpl);
        }
      });
  }

  /**
   * showDanger() - Displays a danger toast notification.
   * @param dangerTpl - The template reference for the danger toast message.
   */
  showDanger(dangerTpl: TemplateRef<any>): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

}
