<div class="container">
    <div class="container cm-main-container px-0 mx-0">
        <div class="common-modal-input-section grey-bg review-panel">
            <h2 class="section-main-header">{{credFormFour.creditApplicationForm}}</h2>
            <div class="review-wrapper">
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.lineOfCredit}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.lineOfCreditRequested}}</h5>
                            <ng-container *ngIf="lineOfCreditData?.locRequested; else noLoc">
                                <p class="review-values">${{ lineOfCreditData?.locRequested }}</p>
                            </ng-container>
                            <ng-template #noLoc>
                                <p class="review-values"> N/A </p>
                            </ng-template>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.anticipatedJobTotal}}</h5>
                            <ng-container *ngIf="lineOfCreditData?.locJobTotal; else noJobtotal">
                                <p class="review-values">${{ lineOfCreditData?.locJobTotal }}</p>
                            </ng-container>
                            <ng-template #noJobtotal>
                                <p class="review-values"> N/A </p>
                            </ng-template>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.totalNoOfMonths}}</h5>
                            <ng-container *ngIf="lineOfCreditData?.locNumOfMonths; else noMonths">
                                <p class="review-values">{{ lineOfCreditData?.locNumOfMonths }}</p>
                            </ng-container>
                            <ng-template #noMonths>
                                <p class="review-values"> N/A </p>
                            </ng-template>
                        </div>
                    </div>
                    <hr>
                </div>

                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.letterOfCredit}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <p class="review-values" *ngIf="letterOfGuaranteeData?.logDocUrl">{{
                                letterOfGuaranteeData?.logDocUrl }}</p>
                            <p class="review-values">{{ letterOfGuaranteeData?.logComments || "No Comments" }}</p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.company}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align"
                            *ngIf="caProdCompanyData; else noCompanyData">
                            <span class="review-values">{{ startApplicationData?.creditApplicationList?.companyName
                                }}</span><br />
                            <span class="review-values">{{ caProdCompanyData?.street }}<span
                                    *ngIf="caProdCompanyData?.street && caProdCompanyData?.city">,</span> {{
                                caProdCompanyData?.city }}<span
                                    *ngIf="caProdCompanyData?.city && caProdCompanyData?.state?.stateName">,</span>
                                {{ caProdCompanyData?.state?.stateName }}<span
                                    *ngIf="caProdCompanyData?.state?.stateName && caProdCompanyData?.zip">,</span>{{
                                caProdCompanyData?.zip
                                }}</span><br />
                            <span class="review-values">{{ caProdCompanyData?.phone }}</span><br />
                            <div class="col-md-12" *ngIf='isBillingAddressSame'>
                                <label class="custom-checkbox" for="prodComBillingAddress">
                                    <input type="checkbox" id="prodComBillingAddress" [checked]="isBillingAddressSame"
                                        disabled>
                                    <span class="checkmark"></span> Use for billing address
                                </label>
                            </div>
                        </div>
                        <ng-template #noCompanyData>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                <span class="review-values">{{ startApplicationData?.creditApplicationList?.companyName
                                    }}</span><br />
                                <span class="review-values"> N/A </span><br />
                            </div>
                        </ng-template>
                    </div>
                    <hr>
                </div>
                <div class="review-inner" *ngIf="caProdCompanyData2; else noBillingAddress">
                    <h3 class="form-section-header">{{credFormFour.billingAddress}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <span class="review-values">{{ caProdCompanyData2?.street }}<span
                                    *ngIf="caProdCompanyData2?.street && caProdCompanyData2?.city">,</span> {{
                                caProdCompanyData2?.city }}<span
                                    *ngIf="caProdCompanyData2?.city && caProdCompanyData2?.state?.stateName">,</span>
                                {{ caProdCompanyData2?.state?.stateName }}<span
                                    *ngIf="caProdCompanyData2?.state?.stateName && caProdCompanyData2?.phone">,</span>{{
                                caProdCompanyData2?.zip
                                }}</span><br />
                            <span class="review-values">{{ caProdCompanyData2?.phone }}</span><br />
                        </div>
                    </div>
                    <hr>
                </div>
                <ng-template #noBillingAddress>
                    <div class="review-inner" *ngIf="caProdCompanyData2">
                        <h3 class="form-section-header">{{credFormFour.billingAddress}}</h3>
                        <div class="row mb-4 align-items-center custom-padding">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                                <span class="review-values"> N/A </span><br />
                            </div>
                        </div>
                        <hr>
                    </div>
                </ng-template>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.federalTaxId}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <p class="review-values"> {{ this.caProdCompanyData?.fedralTaxNum || "N/A" }} </p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.accountPaybleContact}}</h3>
                    <div class="row mb-4 align-items-center custom-padding"
                        *ngFor="let data of accountPayableContactData">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <ng-container
                                *ngIf="data?.firstName || data?.lastName || data?.emailAddress || data?.telephone; else noData">
                                <span class="review-values" *ngIf="data?.firstName && data?.lastName">
                                    {{ data?.firstName }} {{ data?.lastName }}
                                </span><br />
                                <span class="review-values" *ngIf="data?.emailAddress">{{ data?.emailAddress
                                    }}</span><br />
                                <span class="review-values" *ngIf="data?.telephone">{{ data?.telephone }}</span><br />
                            </ng-container>
                            <ng-template #noData>
                                <span class="review-values">N/A</span>
                            </ng-template>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.companyProfile}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.profile}}</h5>
                            <p class="review-values">{{ companyProfileData?.caCpType || "N/A" }}</p>
                            <h5 class="title-content">{{credFormFour.totalAnnualSales || "N/A" }}</h5>
                            <p class="review-values">{{ companyProfileData?.totalAnnualSales || "N/A" }}</p>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.startedOn}}</h5>
                            <p class="review-values">{{ companyProfileData?.dateStarted || "N/A" }}</p>
                            <h5 class="title-content">{{credFormFour.networth}}</h5>
                            <p class="review-values">${{ companyProfileData?.networth || "N/A"}}</p>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 custom-left-align">
                            <h5 class="title-content">{{credFormFour.typeOfBusiness}}</h5>
                            <p class="review-values">{{ companyProfileData?.typeOfBusiness || "N/A" }}</p>
                            <h5 class="title-content">{{credFormFour.noOfEmployees}}</h5>
                            <p class="review-values">{{ companyProfileData?.noOfEmployees || "N/A" }}</p>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.principalOfficer}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="profile-office-details" *ngFor="let data of prinipalOfficerData">
                                <ng-container *ngIf="data?.partnerName || data?.partnerTitle; else noPartnerData">
                                    <span class="review-values">{{ data?.partnerName }}</span><br />
                                    <span class="review-values">{{ data?.partnerTitle }}</span>
                                </ng-container>
                                <ng-template #noPartnerData>
                                    <span class="review-values"> N/A </span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.bankingReference}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <ng-container *ngIf="bankingReferenceData?.bankName || 
                                bankingReferenceData?.accountNumber || 
                                bankingReferenceData?.contactFullName || 
                                bankingReferenceData?.title || 
                                bankingReferenceData?.emailAddress || 
                                bankingReferenceData?.phoneNumber; else noBankingData">
                                <span class="review-values">{{ bankingReferenceData?.bankName }}</span><br />
                                <span class="review-values">{{ bankingReferenceData?.accountNumber }}</span><br />
                                <span class="review-values">{{ bankingReferenceData?.contactFullName }}</span><br />
                                <span class="review-values">{{ bankingReferenceData?.title }}</span><br />
                                <span class="review-values">{{ bankingReferenceData?.emailAddress }}</span><br />
                                <span class="review-values">{{ bankingReferenceData?.phoneNumber }}</span><br />
                            </ng-container>
                            <ng-template #noBankingData>
                                <span class="review-values"> N/A </span>
                            </ng-template>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="review-inner">
                    <h3 class="form-section-header">{{credFormFour.tradeReferences}}</h3>
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="trade-reference">
                                <h5 class="title-content">{{credFormFour.reference1}}</h5>
                                <ng-container *ngIf="tradeReferenceOneData?.trCompanyName ||
                                    tradeReferenceOneData?.trFirstName || 
                                    tradeReferenceOneData?.trLastName || 
                                    tradeReferenceOneData?.trPhone || 
                                    tradeReferenceOneData?.trEmail || 
                                    tradeReferenceOneData?.trStreet || 
                                    tradeReferenceOneData?.trStateName || 
                                    tradeReferenceOneData?.trZipCode || 
                                    tradeReferenceOneData?.trDocumentUrl; else noTr1Data">
                                    <span class="review-values">{{ tradeReferenceOneData?.trCompanyName }}</span><br />
                                    <span class="review-values">{{ tradeReferenceOneData?.trFirstName }} {{
                                        tradeReferenceOneData?.trLastName }}</span><br />
                                    <span class="review-values">{{ tradeReferenceOneData?.trPhone }}</span><br />
                                    <span class="review-values">{{ tradeReferenceOneData?.trEmail }}</span><br />
                                    <span class="review-values">{{ tradeReferenceOneData?.trStreet }}<span
                                            *ngIf=" tradeReferenceOneData?.trStreet && tradeReferenceOneData?.trCity">,</span>
                                        {{
                                        tradeReferenceOneData?.trCity }}<span
                                            *ngIf="tradeReferenceOneData?.trCity && tradeReferenceOneData?.trStateName">,</span>
                                        {{ tradeReferenceOneData?.trStateName }}<span
                                            *ngIf="tradeReferenceOneData?.trStateName && tradeReferenceOneData?.trZipCode">,</span>
                                        {{
                                        tradeReferenceOneData?.trZipCode }} </span><br />
                                    <span class="review-values">{{ tradeReferenceOneData?.trDocumentUrl }}</span>
                                </ng-container>
                                <ng-template #noTr1Data>
                                    <span class="review-values"> N/A </span>
                                </ng-template>
                            </div>
                            <div class="trade-reference">
                                <h5 class="title-content">{{credFormFour.reference2}}</h5>
                                <ng-container *ngIf="tradeReferenceTwoData?.trCompanyName ||
                                    tradeReferenceTwoData?.trFirstName || 
                                    tradeReferenceTwoData?.trLastName || 
                                    tradeReferenceTwoData?.trPhone || 
                                    tradeReferenceTwoData?.trEmail || 
                                    tradeReferenceTwoData?.trStreet || 
                                    tradeReferenceTwoData?.trStateName || 
                                    tradeReferenceTwoData?.trZipCode || 
                                    tradeReferenceTwoData?.trDocumentUrl; else noTr2Data">
                                    <span class="review-values">{{ tradeReferenceTwoData?.trCompanyName }}</span><br />
                                    <span class="review-values">{{ tradeReferenceTwoData?.trFirstName }} {{
                                        tradeReferenceTwoData?.trLastName }}</span><br />
                                    <span class="review-values">{{ tradeReferenceTwoData?.trPhone }}</span><br />
                                    <span class="review-values">{{ tradeReferenceTwoData?.trEmail }}</span><br />
                                    <span class="review-values">{{ tradeReferenceTwoData?.trStreet }}<span
                                            *ngIf="tradeReferenceTwoData?.trStreet && tradeReferenceTwoData?.trCity">,</span>
                                        {{
                                        tradeReferenceTwoData?.trCity }}<span
                                            *ngIf="tradeReferenceTwoData?.trCity && tradeReferenceTwoData?.trStateName">,</span>
                                        {{ tradeReferenceTwoData?.trStateName }}<span
                                            *ngIf="tradeReferenceTwoData?.trStateName && tradeReferenceTwoData?.trZipCode">,</span>
                                        {{
                                        tradeReferenceTwoData?.trZipCode }} </span><br />
                                    <span class="review-values">{{ tradeReferenceTwoData?.trDocumentUrl }}</span>
                                </ng-container>
                                <ng-template #noTr2Data>
                                    <span class="review-values"> N/A </span>
                                </ng-template>
                            </div>
                            <div class="trade-reference">
                                <h5 class="title-content">{{credFormFour.reference3}}</h5>
                                <ng-container *ngIf="tradeReferenceThreeData?.trCompanyName ||
                                    tradeReferenceThreeData?.trFirstName || 
                                    tradeReferenceThreeData?.trLastName || 
                                    tradeReferenceThreeData?.trPhone || 
                                    tradeReferenceThreeData?.trEmail || 
                                    tradeReferenceThreeData?.trStreet || 
                                    tradeReferenceThreeData?.trStateName || 
                                    tradeReferenceThreeData?.trZipCode || 
                                    tradeReferenceThreeData?.trDocumentUrl; else noTr3Data">
                                    <span class="review-values">{{ tradeReferenceThreeData?.trCompanyName
                                        }}</span><br />
                                    <span class="review-values">{{ tradeReferenceThreeData?.trFirstName }} {{
                                        tradeReferenceThreeData?.trLastName }}</span><br />
                                    <span class="review-values">{{ tradeReferenceThreeData?.trPhone }}</span><br />
                                    <span class="review-values">{{ tradeReferenceThreeData?.trEmail }}</span><br />
                                    <span class="review-values">{{ tradeReferenceThreeData?.trStreet }}<span
                                            *ngIf="tradeReferenceThreeData?.trStreet && tradeReferenceThreeData?.trCity">,</span>
                                        {{
                                        tradeReferenceThreeData?.trCity }}<span
                                            *ngIf="tradeReferenceThreeData?.trCity && tradeReferenceThreeData?.trStateName">,</span>
                                        {{ tradeReferenceThreeData?.trStateName }}<span
                                            *ngIf="tradeReferenceThreeData?.trStateName && tradeReferenceThreeData?.trZipCode">,</span>
                                        {{
                                        tradeReferenceThreeData?.trZipCode }} </span><br />
                                    <span class="review-values">{{ tradeReferenceThreeData?.trDocumentUrl }}</span>
                                </ng-container>
                                <ng-template #noTr3Data>
                                    <span class="review-values"> N/A </span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-modal-input-section grey-bg review-panel">
            <h2 class="section-main-header">{{credFormFour.certificateOfInsurance}}</h2>
            <div class="review-wrapper">
                <div class="review-inner">
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="insurance-info-details">
                                <h5 class="title-content">{{credFormFour.insuranceInformation}}</h5>
                                <ng-container *ngIf="certificateOfInsuranceData?.coiProducerName || 
                                certificateOfInsuranceData?.coiInsuredName ||
                                certificateOfInsuranceData?.coiStreet ||
                                certificateOfInsuranceData?.city ||
                                certificateOfInsuranceData?.stateId ||
                                certificateOfInsuranceData?.zipCode; else noCoiData">
                                    <span class="review-values">{{ certificateOfInsuranceData?.coiProducerName }} <span
                                            *ngIf="certificateOfInsuranceData?.coiProducerName && certificateOfInsuranceData?.coiInsuredName">,</span>
                                        {{ certificateOfInsuranceData?.coiInsuredName }}</span><br />
                                    <span class="review-values">{{ certificateOfInsuranceData?.coiStreet }}<span
                                            *ngIf="certificateOfInsuranceData?.coiStreet && certificateOfInsuranceData?.city">,</span>
                                        <br />
                                        <span class="review-values">{{ certificateOfInsuranceData?.city }}<span
                                                *ngIf="certificateOfInsuranceData?.city && certificateOfInsuranceData?.stateId">,</span>
                                            {{
                                            certificateOfInsuranceData?.stateId }}<span
                                                *ngIf="certificateOfInsuranceData?.stateId && certificateOfInsuranceData?.zipCode">,</span>
                                            {{
                                            certificateOfInsuranceData?.zipCode }}
                                        </span>
                                    </span>
                                </ng-container>
                                <ng-template #noCoiData>
                                    <span class="review-values"> N/A </span>
                                </ng-template>
                            </div>
                            <div class="insurance-info-details">
                                <h5 class="title-content">{{credFormFour.form}}</h5>
                                <ng-container *ngIf="certificateOfInsuranceData?.coiDocumentUrl; else noCoiDocUrl">
                                    <span class="review-values">{{ certificateOfInsuranceData?.coiDocumentUrl }}</span>
                                </ng-container>
                                <ng-template #noCoiDocUrl>
                                    <span class="review-values">N/A</span>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-modal-input-section grey-bg review-panel">
            <h2 class="section-main-header">{{credFormFour.termsAndConditions}}</h2>
            <div class="review-wrapper">
                <div class="review-inner">
                    <div class="row mb-4 align-items-center custom-padding">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 custom-left-align">
                            <div class="terms-condtion">
                                <span class="review-values"
                                    *ngIf="termsAndConditionsData?.status === 'Completed'">Signed</span>
                                <span class="review-values" *ngIf="termsAndConditionsData?.status !== 'Completed'">Not
                                    Signed</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex gap-2 cust-sendar-action-btn">
            <div class="left-btn">
                <a class="text-link" (click)="previousPage()"><img class="back-img"
                        src="../../assets/images/blue-left-icon.svg" alt="Back" />back</a>
            </div>
            <div class="right-btn">
                <button type="button" class="btn common-btn-light" (click)="saveAndClose()"
                    [disabled]="!areButtonsEnabled">{{credFormFour.saveAndClose}}</button>
                <button type="button" class="btn common-btn-secondary" (click)="submitApplication()"
                    [disabled]="!areButtonsEnabled">{{credFormFour.submitCreditApplication}}</button>
            </div>
        </div>
    </div>
</div>

<ng-template #successTpl>
    <div class="success d-flex flex-column w-100">
        <div class="success-header text-start">
            <strong>Thank you for your submission.</strong>
        </div>
        <div class="success-body text-start">
            We will look over your credit application and get back to you within 5-7 business days.
        </div>
    </div>
</ng-template>

<ng-template #saveApplication>
    <div class="success">Credit Application Saved Successfully</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{ submissionError }}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>