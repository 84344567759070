import { Component, HostListener, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { STUDIO_DASHBOARD_CREDIT_APPLICATION } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ROW_DATA_CREDIT_APPLICATION } from 'mockData/development/studio-portal-dashboard';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { StudioDashboardService } from '../../../services/studio-dashboard/studio-dashboard.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-studio-dashboard-credit-application',
  templateUrl: './studio-dashboard-credit-application.component.html',
  styleUrls: ['./studio-dashboard-credit-application.component.scss']
})
export class StudioDashboardCreditApplicationComponent {
  @ViewChild('creditGrid') creditGrid!: AgGridAngular;
  @HostListener('window:resize', ['$event'])
  gridApi!: GridApi;
  colDefsCredit: ColDef[];
  creditApplicationConstants = STUDIO_DASHBOARD_CREDIT_APPLICATION;
  rowDataCredit = [];
  displayErrorMsg: any;
  creditApplications: any;
  gridOptions: GridOptions = {
    domLayout: 'autoHeight',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      filter: true
    },
  };
  
  constructor(private toastService: ToastService,
    private loaderService: LoaderService,
    private studioDashboardService: StudioDashboardService){}
  
  ngOnInit(){
    this.getColumnData();
    this.getCreditApplicationData();
  }

  /**
   * getColumnData() - Method to retrieve credit application columns 
  */
  getColumnData(): void{
  this.colDefsCredit = [
    {
      headerName: '',
      field: 'date',
      wrapText: true,
      autoHeight: true,
      minWidth: 110,
      cellClass: 'credit-date',
      cellStyle: { padding: '24px 10px 24px 24px' }
    },
    {
      headerName: '',
      field: 'arNo',
      wrapText: true,
      autoHeight: true,
      minWidth: 100,
      cellClass: 'credit-date',
      cellStyle: { padding: '24px 10px 0px 0px' },
      cellRenderer: (params: ICellRendererParams) => {
        const arNo = params.data.arNo;
        return `  
     <div class="common-split-cell-section">
     <div class="common-split-cell-section-1 common-cell-data-bold ">AR</div>
          <div class=" common-split-cell-section-1 common-cell-data-bold lh-1">${arNo}</div></div> `;
      }
    },

    {
      headerName: '',
      field: 'department',
      wrapText: true,
      autoHeight: true,
      flex: 3,
      cellRenderer: (params: ICellRendererParams) => {
        const department = params.data.department;
        const projectName = params.data.projectName;
        const companyName = params.data.companyName;
        return `   <div title="${department}" class="credit-department-table-section common-cell-data-bold" style="text-transform: capitalize;">      
     ${department}  
       
      </div><div class="credit-project-table-section">${projectName} - ${companyName}</div>`;
      },
      minWidth: 180,
      cellStyle: { padding: '0px 10px 24px 0px' }
    },
    {
      headerName: '',
      field: 'merged-cell',
      wrapText: true,
      autoHeight: true,
      minWidth: 160,
      flex: 3,
      hide:false,
      cellRenderer: (params: ICellRendererParams) => {
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);

        const date = params.data.date;
        const arNo = params.data.arNo;
        const lineofCredit = params.data.lineofCredit;
        const department = params.data.department;
        const projectName = params.data.projectName;
        return `<div class="common-split-cell-section credit-app-sm-screen">
        <div class="d-flex justify-content-between">   
        <div class="common-split-cell-data-1 m-16">${arNo} </div>
          
        <div class="ar-status-tag ${statusClass} w-auto">${status}</div>
        </div>
      
                <div class="common-split-cell-data-2 m-16">${date}</div>
                 <div title="${department}" class="common-split-cell-data-2 m-16 ">
                 <span class="common-cell-data-bold w-auto">${department}</span><div>Project Name:${projectName}</div></div>
                 <div class="common-split-cell-data-2 m-16"><span class="common-cell-data-bold">Line of Credit:</span> ${lineofCredit}</span>
              </div>`;

      }, cellStyle: { padding: '24px 24px 24px 24px' }
    },
    {
      headerName: '',
      field: 'lineofCredit',
      wrapText: true,
      autoHeight: true,
      minWidth: 160,
      flex: 2,
      cellRenderer: (params: ICellRendererParams) => {
        const lineofCredit = params.data.lineofCredit;
        return `<div class="common-split-cell-section">      
   <div class="common-split-cell-section-1 common-cell-data-bold lh-1">Line of Credit:</div>
     <div class="common-split-cell-section-2 lh-2">${lineofCredit}</div>   
      </div>`;

      }, cellStyle: { padding: '0px 10px 0px 0px' }
    },
    {
      headerName: '',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      cellClass: 'account-user-common-status-cell cell-right-align',
      minWidth: 168,
      cellStyle: { padding: '0px 24px 14px 10px' }
    },
  ];
 }

  /**
    * getCreditApplicationData() - Method to retrieve credit application details.
  */
  getCreditApplicationData(): void {
    this.loaderService.setLoadingState(true);
    this.studioDashboardService.getDashboardCreditApplication().subscribe(
      (data) => {
        this.loaderService.setLoadingState(false);
        let userData = [];
        this.creditApplications = data?.creditApplications?.creditApplications.slice(0, 5);
        console.log(this.creditApplications)
        for (const item of this.creditApplications) {
          const allDepartments = item?.cadepartments?.some(
            (dept: any) => dept?.departments?.departmentId === 1
          );
          console.log(allDepartments)
  
          const department = allDepartments
            ? 'All Departments'
            : _.map(item?.cadepartments, (dept: any) => dept?.departments?.departmentName).join('; ');
  
          userData.push({
            date: item?.submittedDate,
            arNo: item?.arNumber ? item?.arNumber : '----',
            department: department,
            lineofCredit: item?.lineOfCredit?.locRequested ? '$'+item?.lineOfCredit?.locRequested : '-',
            projectName: item?.projectDetails?.projectName,
            companyName: item?.companyDetails?.companyName,
            status: item?.studioStatus,
          });
        }
        this.rowDataCredit = userData;
        this.loaderService.setLoadingState(false);
      },
      (error) => {
        this.loaderService.setLoadingState(false);
        this.displayErrorMsg = error;
        this.showDanger(this.displayErrorMsg);
      });
  }


  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="">
        <div class="ar-status-tag ${statusClass}">${params.value}</div>
        </div>
    `;
  }

  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'SENT TO CUSTOMER':
        return 'ar-status-tag send-to account-user-common-status-cell send-to-custom';
      case 'NEW APPLICATION':
        return 'ar-status-tag new-application account-user-common-status-cell';
      case 'UNDER REVIEW':
        return 'ar-status-tag  under-review account-user-common-status-cell';
      case 'NEEDS REVISIONS':
        return 'ar-status-tag revision account-user-common-status-cell';
      case 'REVISIONS SUBMITTED':
        return 'ar-status-tag new-application account-user-common-status-cell';
      case 'PENDING RISK':
        return 'ar-status-tag revision account-user-common-status-cell';
      case 'NEEDS APPROVAL':
        return 'ar-status-tag  assigned-color needs-approval account-user-common-status-cell wid-needs-app';
      case 'APPROVED':
        return 'ar-status-tag  resolved-color approved account-user-common-status-cell';
      case 'APPROVED (RISK)':
        return 'ar-status-tag  resolved-color approved account-user-common-status-cell';
      case 'DENIED':
        return 'ar-status-tag  resolved-color denied account-user-common-status-cell';
      case 'Start Application':
        return 'ar-status-tag  start-application account-user-common-status-cell start-app';
      default:
        return '';
    }
  }
  
  /**
   * onGridReady() - Method to manage the grid of columns 
  */
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.adjustColumns(window.innerWidth);
  }

  /**
   * onResize() - Method to manage resize the columns 
  */
  onResize(event: any): void {
    this.adjustColumns(event.target.innerWidth);
  }

  /**
   * adjustColumns() - Method to adjust the columns 
  */
  adjustColumns(screenWidth: number): void {
    const creditGridApi = this.creditGrid.api;
    if (creditGridApi) {
      const creditColumnIds = ['date', 'arNo', 'department', 'status', 'lineofCredit'];
      if (screenWidth <= 378) {
        creditGridApi.setColumnsVisible(creditColumnIds, false);
        creditGridApi.setColumnsVisible(['merged-cell'], true);
      } else if (screenWidth <= 992) {
        creditGridApi.setColumnsVisible(creditColumnIds, false);
        creditGridApi.setColumnsVisible(['merged-cell'], true);
      }else {
        creditGridApi.setColumnsVisible(creditColumnIds, true);
        creditGridApi.setColumnsVisible(['merged-cell'], false);
      }
    }
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: any): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

}
