import {
  Component,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { rowData, userTypeData } from 'mockData/development/studio-users'; // Ensure this path is correct
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AddCompanyUserComponent } from 'src/app/shared/components/add-company-user/add-company-user.component';
import { AddWbUserComponent } from 'src/app/shared/components/add-wb-user/add-wb-user.component';
import {
  ADD_CUSTOMER_USER_TITLE,
  ADD_WB_USER_TITLE,
  COMMON_GRID_OPTIONS,
} from 'src/app/shared/constants/common';
import { SearchDataService } from 'src/app/shared/services/search.service';
import { UserService } from '../../services/user.service';
import {
  BUTTON_TEXTS,
  COL_DEF_FIELDS,
  DEFAULT_STATUS,
  ERROR_MESSAGE,
  LABEL_TEXTS,
  statusData,
  USER,
  USER_DROPDOWN_SETTINGS,
  USER_FILTER_TYPES,
  VIEW_ALL_USERS_CONSTANTS,
} from '../../constants/studio-portal.constant';
import { AgGridAngular } from 'ag-grid-angular';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { INDEX, PAGES } from '../../enum/studio-portal.enum';
import { TypeaheadService } from 'src/app/shared/services/typeahead/typeahead.service';
import { debounceTime, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import {
  companyJobTitleTypeahead,
  companyUserTypeahead,
} from 'mockData/development/company-filters-data';
import { companyUserIdTypeahead } from 'mockData/development/view-all-users';
import { STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';
import {
  StudioOpsUser,
  StudioOpsUsersResponse,
} from 'src/app/shared/models/users';
import { ERROR_MESSAGES, PROJECT_USERS_LIST_CONSTANTS } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CompanyService } from '../../services/company.service';
import { UsersServiceService } from '../../services/users/users-service.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { IconsComponent } from 'src/app/shared/components/ag-grid-icons/icons/icons.component';
import { IconsService } from 'src/app/shared/services/icons/icons.service';
import { LoaderService } from '../../../../shared/services/loader/loader.service';
import { UserProfileResponse } from 'src/app/shared/models/profileModel';
import { HeaderService } from 'src/app/core/services/header/header.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [DatePipe]
})
export class UsersComponent {
  userRoleMetaData: any;
  wbsoDepartmentDataList: any;
  userFilterData;
  idFilterData;
  jobTitleFilterData;
  userMetadata: any;
  customerUsers: any;
  addUserSuccess: any;
  addUserError: any;
  usersData: any;
  userDetails: any;
  userDetailsId: any;
  metaData: any;
  permissionsData: any;
  userMsg = USER;

  ADD_WB_USER_TITLE(ADD_WB_USER_TITLE: any) {
    throw new Error('Method not implemented.');
  }
  ADD_CUSTOMER_USER_TITLE(ADD_CUSTOMER_USER_TITLE: any) {
    throw new Error('Method not implemented.');
  }
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  public dropdownSettings: IDropdownSettings = {};
  private unsubscribe$ = new Subject<void>();
  @ViewChild('userGrid') userGrid!: AgGridAngular;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('successTplActiveDeactive', { static: false }) successTplActiveDeactive!: TemplateRef<any>;
  @ViewChild('dangerTplActiveDeactive', { static: false }) dangerTplActiveDeactive!: TemplateRef<any>;
  defaultSettings = USER_DROPDOWN_SETTINGS;
  viewAll = VIEW_ALL_USERS_CONSTANTS;
  isFiltersSelected: boolean = false;
  filteredId: any[] = [];
  filteredUsers: any[] = [];
  filteredUserType: any[] = [];
  filteredStatus: any[] = [];
  filteredJobTitle: any[] = [];
  filteredLastLogin: any[] = [];
  filteredCompanies: any[] = [];
  filterTypes = USER_FILTER_TYPES;
  modalReference: NgbModalRef;
  filteredRowData: any[] = [];
  rowData: any[] = [];
  iD: any[];
  user: any;
  studioUserType = userTypeData;
  status = statusData;
  companyUserMetaData: any;
  jobTitle = companyJobTitleTypeahead;
  companies = [];
  company: any
  activecompanies;
  lastLogin: any[];
  selectedLastLogin: NgbDate = null;
  addWbUserText = BUTTON_TEXTS.addWbUser;
  addCustomerUserText = BUTTON_TEXTS.addCustomerUser;
  clearAllFiltersText = BUTTON_TEXTS.clearAllFilters;
  filters = BUTTON_TEXTS.filters;
  userLabel = PROJECT_USERS_LIST_CONSTANTS;
  itemsPerPage = LABEL_TEXTS.itemsPerPage;
  searchResultsUsers$: Observable<any[]>;
  searchResultsJobTitles$: Observable<any[]>;
  placeholder: string = 'Last Login';
  selectedFromDate: any = null;
  selectedToDate: any = null;
  userTypeahead: any = 'typeahead';
  users = companyUserTypeahead;
  jobTitleTypeAhead = companyJobTitleTypeahead;
  usersId = companyUserIdTypeahead;
  customerType: 'customer' = 'customer';
  wbUserType: 'wbuser' = 'wbuser';
  @Input() userType;
  url: string;
  @Input() searchTerm: string = null;
  @Input() public portalType: any = '';
  @Input() public modalType: any = '';
  usersList = [];
  currentValue: any;
  tranferValue: any;
  projectUserId: any
  userLoggedIn: any;
  userStatus: any;
  successActivateDeactivateMsg: any;
  errorMessage = ERROR_MESSAGES;
  message = ERROR_MESSAGE.userDeactivated;
  errorActivateDeactivateMsg: any;
  dateFormatId;
  userProfileSettings;
  userInfo = JSON.parse(localStorage.getItem('user-profile'));

  constructor(
    private modalService: NgbModal,
    private searchDataService: SearchDataService,
    private userService: UserService,
    private companyService: CompanyService,
    private usersService: UsersServiceService,
    private toastService: ToastService,
    public datepipe: DatePipe,
    private iconService: IconsService,
    private loaderService: LoaderService,
    private headerService: HeaderService

  ) {
    this.statusCellRenderer = this.statusCellRenderer.bind(this);
  }

  ngOnInit() {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.permissionsData = { ...userProfile.roleAssigned };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      showSelectedItemsAtTop: false,
    };
    this.iD = this.getUniqueValues('id');
    this.user = this.getUniqueValues('fullName');
    this.jobTitle = this.getUniqueValues('jobTitle');
    this.lastLogin = this.getUniqueValues('updatedDate');
    this.iconService.currentValue.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.currentValue = JSON.stringify(value);
      this.onValueChange(this.currentValue);
    });

    this.getAllUsers();
    this.getWbUserMetaData();
    this.getCustomerUserMetaData();
    this.getDateFormatId()
  }

  /**
 * getDateFormat() - method to get the date fomrat from user profile
 */
  getDateFormatId(): void {
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    this.headerService
      .getuserprofile(userProfileId.userProfileId)
      .subscribe({
        next: (response: UserProfileResponse | null) => {
          if (response) {
            this.userProfileSettings = response?.userProfileSearch;
            this.dateFormatId = this.userProfileSettings?.userPreferences?.dateFormatSettings?.dateFormatId;
          }
        }
      });
  }


  /**
   * getAllUsers() - to retrieve all users
   */
  getAllUsers() {
    this.loaderService.setLoadingState(true);
    this.userService.getUsers().subscribe({
      next: (response: StudioOpsUsersResponse) => {
        this.usersData = response;
        const userData = response.studioOpsUsers.map((user) => {
          const isWBSOUser = user.userType === 'WBSO';
          let requestType = '';
          if (user.isSystemAdmin) {
            requestType = 'System Admin';
          } else if (user.accountPayableUserInd == 1) {
            requestType = 'Account Payable';
          } else if (user.companyAdminInd == 1) {
            requestType = 'Company Admin';
          }

          return {
            userType: user.userType,
            id: user.userProfileId,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user.firstName} ${user.lastName}`,
            email: user.emailAddress,
            status: user.statusActiveInd === 'Y' ? this.userLabel.activeStatus : this.userLabel.deactivatedStatus,
            updatedDate: user.lastLoginDate,
            additionalTime: user.lastLoginTime,
            jobTitle: user.jobTitle,
            requestType: requestType,
            contactNumber: user.contactNumber,
            company: isWBSOUser ? user.userType : user.companyName, // Correctly using isWBSOUser
          };
        });
        this.rowData = userData;
        this.getFiltersData(userData);
        this.applyFilters();
        this.loaderService.setLoadingState(false);
      },
      error: (error) => {
        console.error(this.viewAll.errorMessage, error);
        this.loaderService.setLoadingState(false);
      },
    });
  }

  colsForSmallScreen = ['merged-field', 'icons'];
  colsForMediumScreen = ['merged-field', 'icons'];
  colsForLargeScreen = ['user', 'status', 'activity', 'jobTitle', 'icons'];
  colsForExtraLargeScreen = [
    'id',
    'user',
    'status',
    'activity',
    'jobTitle',
    'phone',
    'company',
    'icons',
  ];

  /**
   * getUniqueValues() - Get unique values for a specified key from rowData.
   * @param key - The key of the items to get unique values for.
   * @returns {any[]} - An array of unique values.
   */
  getUniqueValues(key: string): any[] {
    const uniqueValues = [
      ...new Set(this.rowData.map((item) => item[key])),
    ].map((item, index) => ({
      id: index + 1,
      text: item,
    }));
    return uniqueValues;
  }
  selectedId(onSelect: any) {
    this.filteredId = onSelect;
    this.applyFilters(); // Apply filters after selection
  }
  selectedUser(onSelect: any) {
    this.filteredUsers = onSelect;
    this.applyFilters(); // Apply filters after selection
  }
  /**
    * selectedUserType() - Method to update the selected user type from the dropdown
    * @param onSelect - The selected user type
  */
  selectedUserType(onSelect: any) {
    this.filteredUserType = onSelect;
    this.applyFilters(); // Apply filters after selection
  }
  selectedStatus(onSelect: any) {
    this.filteredStatus = onSelect;
    this.applyFilters(); // Apply filters after selection
  }
  selectedJobTitle(onSelect: any) {
    this.filteredJobTitle = onSelect;
    this.applyFilters(); // Apply filters after selection
  }
  selectedCompanies(onSelect: any) {
    this.filteredCompanies = onSelect;
    this.applyFilters(); // Apply filters after selection
  }

  /**
   * clearAllFilters() - Clear all selected filters.
   */

  clearAllFilters(): void {
    this.filteredId = [];
    this.selectedUserId(this.filteredId);
    this.usersId = this.filteredId;

    this.filteredUsers = [];
    this.selectedUser(this.filteredUsers);
    this.users = this.filteredUsers;

    this.filteredUserType = [];
    this.selectedUserType(this.filteredUserType);
    this.studioUserType = this.filteredUserType;
    
    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;

    this.filteredJobTitle = []; // Clear the jobtitle filter
    this.selectedJobTitle(this.filteredJobTitle);
    this.jobTitleTypeAhead = this.filteredJobTitle;

    this.selectedFromDate = null;
    this.selectedToDate = null;

    this.filteredCompanies = []; // Clear the jobtitle filter
    this.selectedCompanies(this.filteredCompanies);
    this.activecompanies = this.filteredCompanies;
    this.applyFilters();
  }

  filteredDataList(arr: any[], item: any): any[] {
    return arr.filter((selectedItem) => selectedItem.id !== item.id);
  }

  /**
   * removeSelectedItem() - Remove a selected item from a filter.
   * @param filterType - The type of filter.
   * @param item - The item to be removed.
   */
  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case 'ID':
        {
          let userIdArray = [];
          this.filteredId = this.filteredDataList(
            this.filteredId,
            item);
          userIdArray.push(item);
          this.usersId = userIdArray;
        }
        break;
      case 'user':
        {
          let userArray = [];
          this.filteredUsers = this.filteredDataList(this.filteredUsers, item);
          userArray.push(item);
          this.users = userArray;
        }
        break;
      case this.filterTypes.userType:
        {
          let userTypeArray = [];
          this.filteredUserType = this.filteredDataList(
          this.filteredUserType,
          item
          );
          userTypeArray.push(item);
          this.userType = userTypeArray;
        }
        break;
      case 'status':
        {
          let statusArray = [];
          this.filteredStatus = this.filteredDataList(
            this.filteredStatus,
            item
          );
          statusArray.push(item);
          this.status = statusArray;
        }
        break;
      case 'jobTitle':
        {
          let jobTitleArray = [];
          this.filteredJobTitle = this.filteredDataList(
            this.filteredJobTitle,
            item
          );
          jobTitleArray.push(item);
          this.jobTitleTypeAhead = jobTitleArray;
        }
        break;
      case 'companies':
        {
          let companiesArray = [];
          this.filteredCompanies = this.filteredDataList(
            this.filteredCompanies,
            item
          );
          companiesArray.push(item);
          this.activecompanies = companiesArray;
        }
        break;
    }
    this.applyFilters();
  }


  gridOptions: GridOptions ={ ...COMMON_GRID_OPTIONS,
    rowClassRules: {
      'clickable': (params) => !!params.data,
    },
  }

  colDefs: ColDef[] = [
    {
      headerName: 'ID#',
      field: 'id',
      cellClass: 'common-cell-data-bold',
      minWidth: 100,
      maxWidth: 100,
      cellStyle:{paddingRight:0}
    },
    {
      headerName: 'User',
      field: 'user',
      wrapText: true,
      autoHeight: true,
      filter:true,
        sortable: true,
        valueGetter: (params: any) => {
          return params.data.fullName; 
        },
      cellRenderer: (params: ICellRendererParams) => {
        const fullName = params.data.fullName;
        const email = params.data.email;
        const requestType = params.data.requestType;
        let requestTypeClass = ''; // Default class if no requestType is provided
        if (requestType) {
          if (requestType.toLowerCase() === 'system admin') {
            requestTypeClass = 'system-admin';
          } else if (requestType.toLowerCase() === 'account payable') {
            requestTypeClass = 'account-payable';
          } else if (requestType.toLowerCase() === 'company admin') {
            requestTypeClass = 'company-admin';
          } else if (requestType === '') {
            requestTypeClass = '';
          }
        }
        return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span title=${email} class="common-split-cell-data-2 ellipsis-email">${email}</span>
            <span class="common-studio-user-request-label ${requestTypeClass}">${requestType}</span>
          </span>
        `;
      },
      minWidth: 160,
    },
    {
      headerName: 'USER',
      field: 'merged-field',
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams) => {
        const fullName = params.data.fullName;
        const email = params.data.email;
        const requestType = params.data.requestType;
        const status = params.data.status;
        const statusClass = this.getStatusClass(status);

        let requestTypeClass = '';
        if (requestType === 'system admin') {
          requestTypeClass = 'system-admin';
        } else if (requestType === 'account payable') {
          requestTypeClass = 'account-payable';
        } else if (requestType === 'company admin') {
          requestTypeClass = 'company-admin';
        } else if (requestType === '' || requestType === undefined) {
          requestTypeClass = '';
        }

        return `
          <span class="common-split-cell-section common-mobile-section-users">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email mobile-screen-margin">${email}</span>
      <span class="common-studio-user-request-label ${requestTypeClass}">${requestType}</span>
            <span class="common-split-cell-mobile-data">
              <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
            </span>
          </span>
        `;
      },
      minWidth: 155,
      hide: true,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: this.statusCellRenderer.bind(this),
      minWidth: 165,
    },
    {
      headerName: 'Last Login',
      field: 'activity',
      filter: false,
      cellRenderer: (params: ICellRendererParams) => {
        const date = params.data.updatedDate;
        const time = params.data.additionalTime;
        if (!date) {
          return 'N/A'
        }
        return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font common-split-cell-data-1">${date}</span>
                  <span class="common-split-cell-data-2">${time}</span>
                </span>`;
      },
      minWidth: 120,
    },
    {
      headerName: 'JOB TITLE',
      field: 'jobTitle',
      cellClass: 'common-cell-data-bold',
      minWidth: 150,
    },
    {field:'phone',headerName:'Phone No',minWidth:120,  cellClass: 'common-cell-data-mt account-user-active-project-cell'},
    {
      headerName: 'COMPANY',
      field: 'company',
      cellClass: 'common-cell-data-mt account-user-active-project-cell',
      minWidth: 68,
    },
    {
      field: 'icons',
      cellClass: 'user-component-icons',
      cellRenderer: IconsComponent,
      cellRendererParams(params: ICellRendererParams) {
        return {
          row: params.data,
        }

      },
      headerName: '',
      filter: false,
      minWidth: 123,
    },
  ];

  /**
   * statusCellRenderer() - Renders a cell with the appropriate status class and value.
   * @param params - Parameters passed by ag-Grid to the cell renderer. Includes data and context information.
   */

  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
      <div class="status-container">
        <div class="request-status-tag ${statusClass}">${params.value}</div>
      </div>
    `;
  }
  /**
   * getStatusClass() - Returns the appropriate class for each status value.
   * @param status - The status string to get class for.
   * @returns {string} - The appropriate CSS class based on the status.
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case 'DEACTIVATED':
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  }

  /**
   * addWbUser() - invoke the add WB user modal popup
   */
  addWbUser(): void {
    this.searchDataService.searchshow = true;
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddWbUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
      backdrop: 'static',
      keyboard: false

    });
    if (this.modalReference && this.modalReference.componentInstance) {
      // this.modalReference.componentInstance.showSearchBarInput = true;
      this.modalReference.componentInstance.title = ADD_WB_USER_TITLE;
      this.modalReference.componentInstance.isEditMode = false;
      this.modalReference.componentInstance.componentType = 'wbuser';
      this.modalReference.componentInstance.userType = 'wbuser';
      this.modalReference.componentInstance.userRoleMetaData =
        this.userMetadata.wbsoUserAddMetaDataDto.userRoleDetails;
      this.modalReference.componentInstance.wbsoDepartmentDataList =
        this.userMetadata.wbsoUserAddMetaDataDto.departmentDetails;
      this.modalReference.componentInstance.usersList = this.userMetadata.wbsoUserAddMetaDataDto.wbsoUsersList;
      this.modalReference.componentInstance.arData = this.userMetadata.wbsoUserAddMetaDataDto.arPermissions;
      this.modalReference.result.then((data) => {
        if (data) {
          if (data.status == 'success') {
            this.addUserSuccess = data.message;
            this.showSuccess(this.successTpl);
            this.getAllUsers();
          } 
        }
      }, (reason) => {

      });
      this.modalReference.componentInstance.emitError.subscribe((data) => {
        if (data) {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      });

    }
  }
  /**
   * addAUser() - Open modal to add a customer user.
   */
  addAUser(): void {
    this.searchDataService.searchshow = true;
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
      backdrop: 'static',
      keyboard: false

    });

    if (this.modalReference && this.modalReference.componentInstance) {
      this.modalReference.componentInstance.showSearchBarInput = true;
      this.modalReference.componentInstance.title = ADD_CUSTOMER_USER_TITLE;
      this.modalReference.componentInstance.portalType = 'studio';
      this.modalReference.componentInstance.modalType = 'add';
      this.modalReference.componentInstance.isEditMode = false;
      this.modalReference.componentInstance.searchComplete = true;
      this.modalReference.componentInstance.componentType = 'customer';
      this.modalReference.componentInstance.metaData = this.companyUserMetaData;
      this.modalReference.componentInstance.companyDataList = this.customerUsers.companyData.companyList;
      this.modalReference.result.then((data) => {
        if (data) {
          if (data.status == 'success') {
            this.addUserSuccess = data.message;
            this.showSuccess(this.successTpl);
            this.getAllUsers();
          } 
        }
      
      }, (reason) => {
      });
      this.modalReference.componentInstance.emitError.subscribe((data) => {
        if (data) {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      });

    }
  }

  /**
 * showSuccess() - Displays a success toast notification with custom content and options.
 * @param successTpl - The template reference containing the toast's content.
 */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  /**
     * showDanger() - Displays a danger (error) toast message.
     * This method is called when there is an error in adding the user.
     * @param dangerTpl - The template reference for the danger message to display.
     */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
 * selectedUser() - Method to update the selected user ID from the dropdown
 * @param selectedItems - The selected users
 */
  selectedUserId(selectedItems: any) {
    this.filteredUsers = [selectedItems]; // Assuming single selection; for multiple selections, adjust accordingly.
    this.applyFilters();
  }

  /**
   * onCellClicked() - Handles the cell click event in a table or grid.
   * @param event - The event object containing details about the clicked cell.
   */

  onCellClicked(event: any): void {
    if (event.column.colId !== COL_DEF_FIELDS.icons) {
      const userId = event.data.id;
      this.userDetailsId = this.usersData.studioOpsUsers.find(user => user.userProfileId === +userId);
      this.loaderService.setLoadingState(true);
      const userType = event.data.userType;
      if (userType === 'Customer') {
        this.searchCustomerUser(userId);
      }
      else if (userType === 'WBSO') {
        this.searchWbsoUser(userId);
      }
    }
  }

  searchWbsoUser(userId) {
    this.loaderService.setLoadingState(true);
    this.usersService.searchWbsoUser(userId).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        let data = response.wbsoUser
        this.showEditWbUserForm(data.userProfileId, data);
      },
      error: (err) => {
        console.error('Error fetching user metadata:', err);
        this.loaderService.setLoadingState(false);
        this.addUserError = err;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  searchCustomerUser(userId) {
    this.loaderService.setLoadingState(true);
    this.usersService.searchCustomerUser(userId).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.companyService.getCompanyUsersMetadata(response.customerUser.companyId).subscribe(
          (metaDataResponse) => {
            this.metaData = metaDataResponse.companyUserMetaData;;
            this.showEditCompanyUserForm(response.userProfileId, response.customerUser, this.metaData);
            this.loaderService.setLoadingState(false);
          },
          (error) => {
            console.error('Error fetching metadata:', error);
            this.loaderService.setLoadingState(false);
          }
        );
      },
      error: (err) => {
        this.loaderService.setLoadingState(false);
        this.addUserError = err;
        this.showDanger(this.dangerTpl);
      }
    });
  }

  /**
   * showEditCompanyUserForm() - Show modal for editing a company user.
   * @param userId - The ID of the user to be edited.
   * @param userDetails - The details of the user to be edited.
   */
  private showEditCompanyUserForm(userId: string, userDetails: any, metadata: any): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });

    if (this.modalReference.componentInstance) {
      this.modalReference.componentInstance.title = `Edit A Customer User`;
      this.modalReference.componentInstance.searchResults = [userId];
      this.modalReference.componentInstance.isEditMode = true; 
      this.modalReference.componentInstance.portalType = 'studio';
      this.modalReference.componentInstance.modalType = 'edit';
      this.modalReference.componentInstance.componentType = 'customer';
      this.modalReference.componentInstance.metaData = metadata;
      this.modalReference.componentInstance.userDetails = userDetails;
      this.modalReference.componentInstance.showSearchBar = false; 
      this.modalReference.componentInstance.searchComplete = true;
      this.modalReference.componentInstance.selectedCompanyName = userDetails?.companyName;
       this.modalReference.componentInstance.selectedCompanyId = userDetails?.companyId;
       this.modalReference.componentInstance.companyDataList = this.customerUsers.companyData.companyList;
      this.modalReference.result.then((data) => {
        if (data) {
          if (data.status == 'success') {
            this.addUserSuccess = data.message;
            this.showSuccess(this.successTpl);
            this.getAllUsers();
          }   
        }
      }, 
      (reason) => {
      });
      this.modalReference.componentInstance.emitError.subscribe((data) => {
        if (data) {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      });
    }
  }
  
  /**
   * showEditWbUserForm() - Show modal for editing a WB user.
   * @param userId - The ID of the user to be edited.
   * @param userDetails - The details of the user to be edited.
   */

  private showEditWbUserForm(userId: string, userDetails: any): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddWbUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });

    if (this.modalReference.componentInstance) {
      this.modalReference.componentInstance.title = `Edit A WB User`;
      this.modalReference.componentInstance.isEditMode = true;
      this.modalReference.componentInstance.searchResults = [userId];
      this.modalReference.componentInstance.userDetails = userDetails;
      this.modalReference.componentInstance.componentType = 'wbuser';
      this.modalReference.componentInstance.userType = 'wbuser';
      this.modalReference.componentInstance.userRoleMetaData =
        this.userMetadata.wbsoUserAddMetaDataDto.userRoleDetails;
      this.modalReference.componentInstance.wbsoDepartmentDataList =
        this.userMetadata.wbsoUserAddMetaDataDto.departmentDetails;
      this.modalReference.componentInstance.usersList = this.userMetadata.wbsoUserAddMetaDataDto.wbsoUsersList;
      this.modalReference.componentInstance.arData = this.userMetadata.wbsoUserAddMetaDataDto.arPermissions;
      this.modalReference.result.then((data) => {
        if (data) {
          if (data.status == 'success') {
            this.addUserSuccess = data.message;
            this.showSuccess(this.successTpl);
            this.getAllUsers();
          } 
        }
      }, (reason) => {
      });
      this.modalReference.componentInstance.emitError.subscribe((data) => {
        if (data) {
          this.addUserError = data?.message;
          this.showDanger(this.dangerTpl);
        }
      });

    }
  }
  /**
   * applyFilters() - Method to apply filters based on filter criteria.
   */
  applyFilters() {
    let filteredData = this.rowData;
    if (this.filteredId?.length) {
      filteredData = _.filter(filteredData, (user) =>
        _.some(this.filteredId, (x) => user.id == x.id)
      );
    }

    if (this.filteredUsers?.length) {
      filteredData = filteredData.filter(user =>
        this.filteredUsers.some(selectedUser =>
          user.fullName === `${selectedUser.firstname} ${selectedUser.lastname}` &&
          user.email === selectedUser.email
        )
      );
    }

    if (this.filteredUserType?.length > 0) {
      const selectedUserType = this.filteredUserType.map(status => status.text);
      const isAllUsers = _.filter(selectedUserType, userType => userType === this.viewAll?.allUsers)
      if(isAllUsers.length>0){
        this.filteredRowData = this.rowData;
      }
      else{
        filteredData  = filteredData.filter((row) => {
        const userType = row?.userType;
        const requestType = row?.requestType;
        return (userType && selectedUserType.includes(userType)) || 
           (requestType && selectedUserType.includes(requestType));
        });
      }
    }

    if (this.filteredStatus?.length > 0) {
      const selectedStatus = this.filteredStatus.map(status => status.text);
      filteredData = filteredData.filter((row) =>
        selectedStatus.includes(row.status)
      );
    }

    if (this.filteredJobTitle?.length) {
      filteredData = _.filter(filteredData, user =>
        _.some(this.filteredJobTitle, x => user.jobTitle == x.jobtitle)
      );
    }
    if (this.filteredCompanies?.length) {
      filteredData = _.filter(filteredData, (user) =>
        _.some(this.filteredCompanies, (x) => _.includes(user.company, x.text))
      );
    }

    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);

      // Filter rows based on the last login date
      filteredData = _.filter(filteredData, row => {
        // dateFormatId = 1 represents (mm/dd/yyyy) format and dateFormatId = 2 represents(dd/mm/yyyy) format
        if (this.dateFormatId === 1) {
          const lastLoginDate = new Date(row?.updatedDate);
          return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
        }
        else {
          const lastLoginDate = this.parseDate(row?.updatedDate);
          return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
        }
      });
    }


    this.hasSelectedFilters();
    this.filteredRowData = filteredData;

  }

  /**
* parseDate() - method to convert the date into dd/mm/yyyy format
* @param dateString 
* @returns 
*/
  parseDate(dateString: string | null): Date | NgbDate | null {
    if (!dateString) {
      return null; // Return null if the dateString is null or empty
    }
    else {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    }
  }


  /**
   * onDateRangeSelected() - Method triggered when a date range is selected.
   * @param dateRange - The object containing the selected date range
   */

  onDateRangeSelected(dateRange: {
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
  }) {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate; // Treat single date as both 'from' and 'to'
    } else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }

  /**
 * formatDateRange() - method to format the date
 * @param fromDate - The start date.
 * @param toDate - The end date.
 * @returns A string representing the formatted date range.
*/
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    // dateFormatId = 1 represents (mm/dd/yyyy) format and dateFormatId = 2 represents(dd/mm/yyyy) format
    const formattedFromDate = this.dateFormatId === 1 ? `${formatToTwoDigits(fromDate.month)}/${formatToTwoDigits(fromDate.day)}/${fromDate.year.toString()}` : `${formatToTwoDigits(fromDate.day)}/${formatToTwoDigits(fromDate.month)}/${fromDate.year.toString()}`;
    const formattedToDate = this.dateFormatId === 1 ? `${formatToTwoDigits(toDate.month)}/${formatToTwoDigits(toDate.day)}/${toDate.year.toString()}` : `${formatToTwoDigits(toDate.day)}/${formatToTwoDigits(toDate.month)}/${toDate.year.toString()}`;
    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }
  /**
 * clearDateRange() - method to clear the selected date range.
 */
  clearDateRange() {
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }

  hasSelectedFilters(): boolean {
    this.isFiltersSelected =
      this.filteredId?.length > 0 ||
      this.filteredUsers?.length > 0 ||
      this.filteredUserType?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      this.filteredJobTitle?.length > 0 ||
      this.filteredCompanies?.length > 0 ||
      this.selectedFromDate !== null ||
      this.selectedToDate !== null;

    return this.isFiltersSelected;
  }
  getWbUserMetaData(): void {
    this.usersService.getWbsoMetaDeta().subscribe({
      next: (response: any) => {
        this.userMetadata = response;
      },
      error: (err) => {
        console.error('Error fetching user metadata:', err);
      }
    });
  }

  getCustomerUserMetaData(): void {
    this.usersService.getCustomerMetaDeta().subscribe({
      next: (response: any) => {
        this.customerUsers = response;
      },
      error: (err) => {
        console.error('Error fetching user metadata:', err);
      }
    });
  }

  /**
   * getFiltersData() - method to get the data of the filters based on the row data
   * @param userData - the data of the users
   */
  getFiltersData(userData): void {

    this.userFilterData = userData
      .filter((x) => x.email !== null)
      .map(({ id, email, firstName, lastName, fullName }) => ({
        id: id,
        email: email,
        firstname: firstName,
        lastname: lastName,
        fullName: fullName
      }));

    this.idFilterData = userData
      .filter((x) => x.email !== null)
      .map(({ id }) => ({
        id: id,
      }));

    const uniqueJobTitles = Array.from(
      new Map(
        userData
          .filter((x) => x.jobTitle !== null)
          .map(({ userProfileId, jobTitle }) => [
            jobTitle.toLowerCase(),
            { id: userProfileId, jobtitle: jobTitle },
          ])
      ).values()
    );

    this.jobTitleFilterData = uniqueJobTitles.map((item, index) => ({
      id: index,
      jobtitle: item['jobtitle'],
    }));

    const transformedData = userData.flatMap(({ id, company }) => {
      if (company) {
        if (company.includes(',')) {
          return company.split(',').map((company) => ({
            id,
            company: company.trim(),
          }));
        }
        return [{ id, company: company.trim() }];
      }
      return [];
    });

    const uniqueCompanies = Array.from(
      new Map(
        transformedData
          .filter(({ company }) => company !== null && company !== '') // Ensure company exists
          .map(({ userProfileId, company }) => [
            company,
            { id: userProfileId, company },
          ])
      ).values()
    );

    this.activecompanies = uniqueCompanies.map((item, index) => ({
      id: index,
      text: item['company'],
    }));
  }

  /**
   * onValueChange(val: any) - Method to handle changes in value.
   * This method processes the incoming value, determines the associated icon action,
   * and triggers the appropriate user action based on the parsed value.
   * 
   * @param val - The value representing the action to be taken, expected to be a JSON string.
   */
  onValueChange(val: any): void {
    this.tranferValue = val;
    const parsedVal = JSON.parse(val);
    if (parsedVal != null) {
      this.projectUserId = parsedVal.rowData.id;
      this.userStatus = parsedVal.rowData.status === "ACTIVE" ? this.userLabel.statusDeactive : this.userLabel.statusActive;
    }
    if (this.userInfo != null) {
      this.userLoggedIn = this.userInfo.userProfileId;
    }
    if (parsedVal) {
      switch (parsedVal.iconInfo) {
        case 'freeze-account':
          console.log('Anyone can takeover this icon');
          break;
        case 'deactivate-account':
          this.deactivateUser();
          break;
        case 'activate-account':
          this.activateUser();
          break;
        default:
          console.log('Unknown action: ' + parsedVal.iconInfo);
          break;
      }
    }
  }

  /**
   * deactivateUser() - Method to deactivate a user.
   * Calls the iconService to deactivate the user and handles the response.
   * Displays success message if deactivation is successful; logs a warning if not.
   */
  deactivateUser(): void {
    if (this.projectUserId === this.userLoggedIn) {
      alert('You cannot deactivate your own account.');
      return;
    }
    this.loaderService.setLoadingState(true);
    this.iconService.deactivateStudioProjectUser(this.projectUserId, this.userStatus, this.userLoggedIn).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response) {
          this.successActivateDeactivateMsg = this.userMsg.userDectivatedMsg;
          this.showActiveDeactivateIcon(this.successTplActiveDeactive);
          this.iconService.changeValue(null);
          this.loaderService.setLoadingState(false);
          this.getAllUsers();
        } else {
          this.showDangerActiveDeactivateIcon(this.dangerTplActiveDeactive);
          this.loaderService.setLoadingState(false);
        }
      },
      error: (error) => {
        this.loaderService.setLoadingState(false);
        this.errorActivateDeactivateMsg = error;
        this.iconService.changeValue(null);
        console.error('Error fetching user :', error.message);
        this.showDangerActiveDeactivateIcon(this.dangerTplActiveDeactive);
      },
    });
  }

  /**
   * activateUser() - Method to activate a user.
   * Calls the iconService to activate the user and handles the response.
   * Displays success message if activation is successful; logs a warning if not.
   */
  activateUser(): void {
    this.loaderService.setLoadingState(true);
    this.iconService.activateStudioProjectUser(this.projectUserId, this.userStatus, this.userLoggedIn).pipe(take(1)).subscribe({
      next: (response: any) => {
        if (response) {
          this.successActivateDeactivateMsg = this.userMsg.userActivatedMsg;
          this.showActiveDeactivateIcon(this.successTplActiveDeactive);
          this.iconService.changeValue(null);
          this.loaderService.setLoadingState(false);
          this.getAllUsers();
        } else {
          this.showDangerActiveDeactivateIcon(this.dangerTplActiveDeactive);
        }
      },
      error: (error) => {
        this.errorActivateDeactivateMsg = error;
        this.iconService.changeValue(null);
        console.error('Error fetching user :', error.message);
        this.loaderService.setLoadingState(false);
        this.showDangerActiveDeactivateIcon(this.dangerTplActiveDeactive);
      },
    });
  }

  /**
    * showActiveDeactivateIcon() - Displays a success toast notification with custom content and options.
    * @param successTplActiveDeactive - The template reference containing the toast's content.
    */
  showActiveDeactivateIcon(successTplActiveDeactive: TemplateRef<any>) {
    this.toastService.clear();
    this.toastService.show(successTplActiveDeactive, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
    * showDangerActiveDeactivateIcon() - Displays a success toast notification with custom content and options.
    * @param successTplActiveDeactive - The template reference containing the toast's content.
    */
  showDangerActiveDeactivateIcon(successTplActiveDeactive: TemplateRef<any>) {
    this.toastService.clear();
    this.toastService.show(successTplActiveDeactive, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete(); // Complete the subject to avoid memory leaks
  }
}
