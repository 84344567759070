import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  DropdownSettings,
  COMPANY_FILTERS,
  CREDIT_APP_CONSTANTS,
  NOTIFICATION_NOT_SENT,
  STUDIO_CREDIT_APPLICATION_STATUS,
} from '../../constants/studio-portal.constant';
import {
  companyJobTitleTypeahead,
  companyStatusData,
  companyUserTypeahead,
} from '../../../../../../mockData/development/company-filters-data';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ApprovedWithRiskComponent } from './approved-with-risk/approved-with-risk.component';
import { CreditStatusDetailsComponent } from './credit-status-details/credit-status-details.component';
import { StudioDashboardService } from '../../services/studio-dashboard/studio-dashboard.service';
import { CredAppComponent } from './cred-app/cred-app.component';
import * as _ from 'lodash';
import { CREDIT_APP_FILTER_TYPES } from '../../constants/studio-portal.constant';
import { TOGGLE_INDEX } from '../../enum/studio-portal.enum';
import { CreditApplicationDeptComponent } from './credit-application-dept/credit-application-dept.component';
import { AgGridAngular } from 'ag-grid-angular';
import { CreditAppService } from '../../services/credit-app/credit-app.service';
import { TitleCasePipe } from '@angular/common';


@Component({
  selector: 'app-credit-application',
  templateUrl: './credit-application.component.html',
  styleUrls: ['./credit-application.component.scss'],
  providers: [TitleCasePipe]
})
export class CreditApplicationComponent {
  @ViewChild('confirmationModal', { static: false }) confirmationModal!: TemplateRef<any>;
  @ViewChild('agGrid') agGrid: AgGridAngular;
  modalReference: NgbModalRef;
  userFilterData: any[] = [];
  filteredCompany: any[] = [];
  filteredProjects: any[] = [];
  filteredDepartments: any[] = [];
  filteredStatus: any[] = [];
  filteredRowData: any[] = [];
  isFiltersSelected: boolean = false;
  filterTypes = CREDIT_APP_FILTER_TYPES;
  displayErrorMsg: any;
  creditApplications: any;
  dropdown = DropdownSettings;
  constants = CREDIT_APP_CONSTANTS;
  showUnderReviewModal: boolean = false;
  defaultSettings: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false,
  };
  defaultSettingsStatus: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: false,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false,
  };
  togglestatusFilter: any;
  toggleSelectedFilter: any;
  status: any;

  constructor(
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private studioDashboardService: StudioDashboardService,
    private creditAppService: CreditAppService,
    private titleCasePipe: TitleCasePipe
  ) { }

  // Data for dropdown lists projects, types, departments, status and dayes
  companyData;
  projectData;
  departmentData;
  statusData = STUDIO_CREDIT_APPLICATION_STATUS;
  companyConstants = COMPANY_FILTERS;
  rowData = [];

  colDefs: ColDef[] = [
    {
      field: 'date',
      headerName: 'DATE',
      maxWidth: 101,
      cellRenderer: this.updatedDateTimeRenderer,
      cellStyle: { paddingRight: '0px' },
    },
    {
      field: 'company',
      headerName: 'COMPANY',
      minWidth: 120,
      cellRenderer: this.companyClientRenderer,
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
      valueGetter: (params: any) => {
        return params.data.companyName;
      },
      comparator: (valueA, valueB) => {
        const lowerA = (valueA || '').toString().toLowerCase();
        const lowerB = (valueB || '').toString().toLowerCase();
        if (lowerA < lowerB) return -1;
        if (lowerA > lowerB) return 1;
        return 0;
      },
    },
    {
      field: 'project',
      headerName: 'PROJECT',
      minWidth: 125,
      cellRenderer: this.projectNameTypeRenderer,
      cellStyle: { paddingLeft: '20px', paddingRight: '0px' },
      valueGetter: (params: any) => {
        return params.data.projectName;
      },
      comparator: (valueA, valueB) => {
        const lowerA = (valueA || '').toString().toLowerCase();
        const lowerB = (valueB || '').toString().toLowerCase();
        if (lowerA < lowerB) return -1;
        if (lowerA > lowerB) return 1;
        return 0;
      },
    },
    {
      field: 'ar',
      headerName: 'AR #',
      minWidth: 90,
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
      cellRenderer: this.uploadRenderer.bind(this),
      valueGetter: (params: any) => {
        return params.data.ar;
      },
      comparator: (valueA, valueB) => {
        const lowerA = (valueA || '').toString().toLowerCase();
        const lowerB = (valueB || '').toString().toLowerCase();
        if (lowerA < lowerB) return -1;
        if (lowerA > lowerB) return 1;
        return 0;
      },
    },
    {
      field: 'department',
      headerName: 'DEPARTMENTS',
      minWidth: 180,
      cellClass: 'credit-department inter-font',
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
      cellRenderer: CreditApplicationDeptComponent
    },
    {
      field: 'reviewTime',
      headerName: 'REVIEW TIME',
      minWidth: 147,
      cellClass: 'credit-review-time inter-font',
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'comments',
      headerName: '',
      minWidth: 70,
      cellRenderer: this.commentsRenderer.bind(this),
      cellStyle: { paddingLeft: '15px', paddingRight: '0px' },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 202,
      cellRenderer: this.statusCellRenderer.bind(this),
      cellStyle: (params) => {
        const isClickable = Object.values(
          this.constants.allowedStatuses
        ).includes(params.value);
        return {
          cursor: isClickable ? 'pointer' : 'default',
          paddingLeft: '15px',
          paddingRight: '0px',
        };
      },
    },
    {
      field: 'email',
      headerName: '',
      minWidth: 60,
      cellRenderer: (params: ICellRendererParams) => {
        const rowIndex = params.node.rowIndex;

        let imageUrl = '';
        if (rowIndex === 0) {
          imageUrl = 'assets/images/email-sent.svg';
        } else if (rowIndex === 1) {
          imageUrl = 'assets/images/approved-email.svg';
        }
        return imageUrl
          ? `<span class="email-cell">
          <img src="${imageUrl}" alt="Email Icon" class="email-icon"/>
         </span>`
          : '';
      },
      cellStyle: { paddingLeft: '10px', paddingRight: '0px' },
    },
  ];
  updatedDateTimeRenderer(params: ICellRendererParams) {
    const date = params.data.date;
    const time = params.data.time;
    return `
    <span class="common-split-cell-section common-mobile-section">
    <span class="common-split-cell-data-1">${date}</span>
    <span class="common-split-cell-data-2">${time}</span>
    </span>`;
  }

  companyClientRenderer(params: ICellRendererParams) {
    const companyName = params.data.companyName;
    const clientType = params.data.clientType;
    return `<span class="common-split-cell-section common-mobile-section">
    <span class="common-split-cell-data-1" title="${companyName}">${companyName}</span>
    <span class="common-split-cell-data-2">${clientType}</span>
    </span>`;
  }
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.data.status);
    const isClickable = Object.values(this.constants.allowedStatuses).includes(
      params.data.status
    );

    return `
      <div class="status-container">
        <div class="ar-status-tag ${statusClass} credit-status">${params.data.status}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'SENT TO CUSTOMER':
        return 'send-to';
      case 'SEND TO CUSTOMER':
        return 'send-to';
      case 'NEW APPLICATION':
        return 'new-application new-app-width';
      case 'APPROVED':
        return 'approved approved-wid';
      case 'DENIED':
        return 'denied';
      case 'NEEDS REVISIONS':
        return 'revision';
      case 'NEEDS APPROVAL':
        return 'need-approve';
      case 'REVISIONS SUBMITTED':
        return 'new-application new-app-width';
      case 'PENDING RISK':
        return 'revision new-app-width';
      case 'UNDER REVIEW':
        return 'under-review';
      case 'APPROVED (RISK)':
        return 'approved';
      default:
        return '';
    }
  }

  projectNameTypeRenderer(params: ICellRendererParams) {
    const projectName = params.data.projectName;
    const projectType = params.data.projectType;
    return `<span class="common-split-cell-section common-mobile-section">
    <span class="common-split-cell-data-1" title="${projectName}">${projectName}</span>
    <span class="common-split-cell-data-2">${projectType}</span>
    </span>`;
  }

  commentsRenderer(params: ICellRendererParams) {
    const comments = params.value;
    return `
      <span class="comments-cell">
        <span class="comments-count">${comments}</span>
        <img src="assets/images/comment.svg" alt="Comment Icon" class="comments-icon"/>
      </span>`;
  }
  uploadRenderer(params: ICellRendererParams) {
    const ar = params.value;
    return `
      <span class="comments-cell">
        <span class="comments-count">${ar}</span>
      </span>`;
  }
  /**
   * departmentCellRenderer() - Renders a cell in the grid to display department names.
   * @param {ICellRendererParams} params - The parameters provided by the grid for rendering the cell.
   * @returns {string} - The HTML string to be rendered in the cell.
   */
  departmentCellRenderer(params: ICellRendererParams) {
    const departmentText = params.value || '';
    const maxDisplay = 3;
    const sortedDepartments = _.sortBy(departmentText, 'departmentName');
    const displayedDepartments = sortedDepartments.slice(0, maxDisplay);
    const hiddenCount = sortedDepartments?.length - maxDisplay;
    const formattedDepartments = displayedDepartments
      .map((dep) => this.capitalizeFirstLetter(dep))
      .join('; ');
    let formattedText = formattedDepartments;
    if (hiddenCount > 0) {
      formattedText += ` <span style="color: blue; font-weight: bold;">+${hiddenCount}</span>`;
    }
    return `
      <span class="department-text" 
            title="${sortedDepartments.join(', ')}">
             ${formattedText}
      </span>
    `;
  }
  /**
   * Capitalizes the first letter of each word in a given string.
   * @param {string} str - The string to be formatted.
   * @returns {string} - The formatted string with the first letter of each word capitalized.
  */
  capitalizeFirstLetter(str: string): string {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  colsForSmallScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  colsForMediumScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  colsForLargeScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];
  colsForExtraLargeScreen = [
    'date',
    'company',
    'project',
    'ar',
    'department',
    'reviewTime',
    'comments',
    'status',
    'email',
  ];

  /**
   * selectedCompany() - Method to handle selection of company from the dropdown
   * @param onSelect - The selected items from the dropdown
   */

  selectedCompany(onSelect: any): void {
    this.filteredCompany = onSelect;
    this.applyFilters();
  }

  ngOnInit(): void {
    this.getCreditApplicationData();
    this.refreshAgGrid();
  }
  /**
   * selectedProject() - Method to handle selection of project from the dropdown
   * @param onSelect - The selected items from the dropdown
   */
  selectedProject(onSelect: any): void {
    this.filteredProjects = onSelect;
    this.applyFilters();
  }

  /**
   * selectedDepartment() - Method to handle selection of department from the dropdown
   * @param onSelect - The selected items from the dropdown
   */

  selectedDepartment(onSelect: any): void {
    this.filteredDepartments = onSelect;
    this.applyFilters();
  }

  /**
   * selectedStatus() - Method to handle selection of status from the dropdown
   * @param selectedItems - The selected items from the dropdown
   */

  selectedStatus(selectedItems: any): void {
    this.filteredStatus = selectedItems;
    this.applyFilters();
  }

  onDateRangeSelected(dateRange: { fromDate: NgbDate | null }) { }

  /**
   * onCellClicked(event) - Handles the click event on a table cell, opening the modal if the clicked column is not 'status'.
   * @param event - The event object containing data about the clicked cell.
   */
  /**
   * onCellClicked(event) - Handles the click event on a table cell, opening the modal if the clicked column is not 'status'.
   * @param event - The event object containing data about the clicked cell.
   */

  onCellClicked(event: any): void {
    if (event.column.colId !== 'status') {
      const status = event.data.status;

      switch (status) {
        case this.constants.allowedStatuses.APPROVED_RISK:
        case this.constants.allowedStatuses.APPROVED:
        case this.constants.allowedStatuses.DENIED:
        case this.constants.allowedStatuses.PENDING_RISK:
        case this.constants.allowedStatuses.NEEDS_APPROVAL:
          this.loaderService.setLoadingState(true);
          this.openCredAppComponent(event.data); // Open the CreditStatusDetailsComponent
          this.loaderService.setLoadingState(false);
          break;
        case 'UNDER REVIEW':
          this.showUnderReviewModal = true;
          if (this.showUnderReviewModal) {
            this.openConfirmationModal();
          }
          break;

        default:
          break;
      }
    }
  }

  /**
   * openConfirmationModal() - Opens a confirmation modal.
   */
  openConfirmationModal(): void {
    this.modalReference = this.modalService.open(this.confirmationModal, {
      windowClass: 'common-modal-center',
      centered: true,
      backdrop: 'static',
    });
  }

  /**
   * closeUnderReviewModal() - Closes the "Under Review" modal.
   */
  closeUnderReviewModal(): void {
    this.showUnderReviewModal = false;
    this.modalReference.close();
  }
  /**
   * openCredAppComponent(rowData) - Opens the Credit Application Details modal with the provided row data.
   * @param rowData - The data for the selected credit application row.
   */
  private openCredAppComponent(rowData: any): void {
    this.modalReference = this.modalService.open(CredAppComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });

    if (this.modalReference.componentInstance) {
      this.modalReference.componentInstance.rowData = rowData;
      this.modalReference.componentInstance.modalClosed.subscribe(() => {
        this.refreshAgGrid();
      })
    }
  }

  /**
   *refreshAgGrid() - Method to refresh the credit application data
   */
  refreshAgGrid() {
    this.getCreditApplicationData();
    this.creditAppService.setData();
  }

  /**
   * getCreditApplicationData() - Method to retrieve credit application details.
   */
  getCreditApplicationData(): void {
    this.loaderService.setLoadingState(true);
    this.studioDashboardService.getDashboardCreditApplication().subscribe(
      (data) => {
        this.loaderService.setLoadingState(false);
        let userData = [];
        this.creditApplications = data?.creditApplications?.creditApplications;
        for (let item of this.creditApplications) {
          const department = _.map(
            item?.cadepartments,
            (department) => department?.departments?.departmentName
          );
          userData.push({
            date: item?.submittedDate ? item?.submittedDate : item?.submittedDate == null ? '--' : '', // added for date null case
            time: item?.submittedTime ? item?.submittedTime : '',
            companyName: item?.companyDetails.companyName,
            clientType: item?.companyDetails.companyType.companyType,
            projectName: item?.projectDetails.projectName,
            projectType: item?.projectDetails.projectType.projectType,
            ar: item?.arNumber ? item?.arNumber : '----',
            department: department,
            reviewTime: item?.approvalCommentsDTO.reviewTime,
            comments: item?.approvalCommentsDTO.commentsCount,
            status: item?.studioStatus,
            profileId: item.projectDetails?.profileId, 
            projectId: item.projectDetails?.projectId,
            companyId: item.companyDetails?.companyId,
            lineOfCredit: item.lineOfCredit,


          });
        }
        this.rowData = userData;
        this.loaderService.setLoadingState(false);
        this.modifyDataset(this.creditApplications);
        this.applyFilters();
      },
      (error) => {
        this.loaderService.setLoadingState(false);
        this.displayErrorMsg = error;
      }
    );
  }
  /**
   *modifyDataset() - modifyDataset method transform the data for the filters.
   * @param metaData - getting metadata from the API based on screen specific.
   */

  modifyDataset(metaData): void {
    const sortedUniqueProjects = _.sortBy(
      _.map(
        _.uniqBy(
          _.filter(
            metaData,
            (x) => x.projectDetails && x.projectDetails.projectName
          ), // Filter out invalid projectDetails
          (x) => x.projectDetails.projectName // Ensure uniqueness based on projectName
        ),
        (x) => ({
          id: x.projectDetails.projectId,
          project: x.projectDetails.projectName,
        }) // Map to the desired structure
      ),
      ({ project }) => project.toLowerCase() // Sort by project name, case-insensitive
    );

    this.projectData = sortedUniqueProjects.map((item, index) => ({
      id: index,
      text: item['project'],
    }));
    const uniqueCompanies = _.sortBy(
      _.map(
        _.uniqBy(
          _.filter(
            metaData,
            (x) => x.companyDetails && x.companyDetails.companyName
          ), // Filter out invalid projectDetails
          (x) => x.companyDetails.companyName // Ensure uniqueness based on projectName
        ),
        (x) => ({
          id: x.companyDetails.companyId,
          company: x.companyDetails.companyName,
        }) // Map to the desired structure
      ),
      ({ company }) => company.toLowerCase() // Sort by project name, case-insensitive
    );
    this.companyData = uniqueCompanies.map((item, index) => ({
      id: index,
      text: item['company'],
    }));

    const uniqueDepartments = _.sortBy(
      _.map(
        _.uniqBy(
          _.flatMap(metaData, (item) =>
            item.cadepartments
              ? item.cadepartments.map((dep) => dep.departments)
              : []
          ), // Flatten all departments
          (dep) => dep.departmentId // Ensure uniqueness based on departmentId
        ),
        (dep) => ({ id: dep.departmentId, departmentName: dep.departmentName }) // Map to the desired structure
      ),
      ({ departmentName }) => departmentName.toLowerCase() // Sort by department name, case-insensitive
    );

    this.departmentData = uniqueDepartments.map((item, index) => ({
      id: index,
      text: item['departmentName'],
    }));
  
    this.togglestatusFilter = this.statusData;
  }

  /**
   * applyFilters() - Method to apply selected filters to the project data and update the paginated data
   */
  applyFilters(): void {
    let filteredData = this.rowData;

    if (this.filteredCompany?.length) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredCompany, (x) =>
          _.includes(project.companyName.toLowerCase(), x.text.toLowerCase())
        )
      );
    }

    if (this.filteredProjects?.length) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredProjects, (x) =>
          _.includes(project.projectName.toLowerCase(), x.text.toLowerCase())
        )
      );
    }
    if (this.filteredDepartments?.length) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredDepartments, (x) =>
          _.some(project.department, (department) =>
            department.toLowerCase().includes(x.text.toLowerCase())
          )
        )
      );
    }
    if (this.filteredStatus?.length) {
      filteredData = _.filter(filteredData, (project) =>
        _.some(this.filteredStatus, (x) => project.status.toLowerCase() == x.text.toLowerCase())
      );
    }
    filteredData = _.filter(filteredData, (project) => !project.isRedEnvelope);
    this.filteredRowData = filteredData;
    this.isFiltersSelected = filteredData?.length !== this.rowData?.length;
    this.hasSelectedFilters();

  }
  /**
   * hasSelectedFilters() - Method to check if any filters are selected.
   * @returns boolean - True if any filters are selected, otherwise false.
   */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected =
      this.filteredProjects?.length > 0 ||
      this.filteredStatus?.length > 0 ||
      this.filteredCompany?.length > 0 ||
      this.filteredDepartments?.length > 0;
    return this.isFiltersSelected;
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any {
    return _.filter(arr, (i) => i.id !== item.id);
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (project, type, department, status)
   * @param item - The item to be removed
   */

  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.projects:
        {
          let projectArray = [];
          this.filteredProjects = this.filteredDataList(
            this.filteredProjects,
            item
          );
          projectArray.push(item);
          this.projectData = projectArray;
        }
        break;
      case this.filterTypes.status:
        {
          let statusArray = [];
          this.filteredStatus = this.filteredDataList(
            this.filteredStatus,
            item
          );
          statusArray.push(item);
          this.statusData = statusArray;
        }
        break;
      case this.filterTypes.department:
        {
          let userArray = [];
          this.filteredDepartments = this.filteredDataList(
            this.filteredDepartments,
            item
          );
          userArray.push(item);
          this.departmentData = userArray;
        }
        break;
      case this.filterTypes.company:
        {
          let jobtitleArray = [];
          this.filteredCompany = this.filteredDataList(
            this.filteredCompany,
            item
          );
          jobtitleArray.push(item);
          this.companyData = jobtitleArray;
        }
        break;
    }
    this.applyFilters();
  }
  isFilterEnabled: boolean;
  /**
   * clearFilter() - Method to clear a specific filter
   * @param filterType - The type of filter to clear (project, type, department, status)
   */
  clearFilter(): void {
    this.filteredCompany = [];
    this.selectedCompany(this.filteredCompany);
    this.companyData = this.filteredCompany;

    this.filteredProjects = [];
    this.selectedProject(this.filteredProjects);
    this.projectData = this.filteredProjects;

    this.filteredDepartments = [];
    this.selectedDepartment(this.filteredDepartments);
    this.departmentData = this.filteredDepartments;

    this.filteredStatus = [];
    this.selectedStatus(this.filteredStatus);
    this.statusData = this.filteredStatus;

    if (this.isFilterEnabled) {
      this.toggleFilter();
    }

    this.applyFilters();
  }

  /**
   * toggleFilter() - method to toggle the status letter notification button
   */
  toggleFilter(): void {
    this.isFilterEnabled = !this.isFilterEnabled;
    if (this.isFilterEnabled) {
      this.applyStatusLetterFilter();
    } else {
      this.clearFilter();
    }
  }
  /**
   * applyStatusLetterFilter() - Method to set the filters for status letter notification sent
   */
  applyStatusLetterFilter(): void {
    const filterToLookup = this.statusData?.length === TOGGLE_INDEX.zero ? this.togglestatusFilter : this.statusData;
    const selectedStatus = _.filter(filterToLookup, item => _.includes(NOTIFICATION_NOT_SENT, item?.text));
    this.statusData = selectedStatus;
    this.selectedStatus(selectedStatus);
    this.filteredStatus = selectedStatus;
    this.toggleSelectedFilter = selectedStatus;

  }
}
