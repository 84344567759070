import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { GLOBAL_API_PATHS, STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

export interface Company {
  companyId: number;
  companyName: string;
  billingAddressSameInd: number;
  projectCount: number;
  userCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  baseUrl: string;
  private companiesData = new BehaviorSubject<any[]>([]);

  constructor(private baseApiService:BaseApiService,private http:HttpClient) {}

  /** getCompanyUsersByCompID - getCompanyUsersByCompID used to call get company users by passing companyId*/

  getCompanyUsersByCompID(companyId):any {

    let endpoint = STUDIO_PORTAL_API_PATHS.company_users + '?' + 'companyId=' + companyId;
    return this.baseApiService.get(endpoint);

  }

  /** getCompanyUsersMetadata - getCompanyUsersMetadata used to call get company users screen metadata by passing companyId*/

  getCompanyUsersMetadata(companyId):any {

    let endpoint = STUDIO_PORTAL_API_PATHS.company_users_metadata + '?' +'companyId=' + companyId;
    return this.baseApiService.get(endpoint);

  }

  /** getCompanyMetadata - getCompanyMetadata used to call get company screen metadata*/

  getCompanyMetadata():any {

    let endpoint = STUDIO_PORTAL_API_PATHS.company_metadata;
    return this.baseApiService.get(endpoint);

  }

  /** getCompanyMetadata - getCompanyMetadata used to call get company screen metadata*/

  getCompanyDetailsByCompanyId(companyId):any {

    let endpoint = STUDIO_PORTAL_API_PATHS.company_details_by_companyid + '?' +'companyId=' + companyId;
    return this.baseApiService.get(endpoint);

  }

  /** addCompanyDetails - addCompanyDetails used to call add company details*/

 addCompanyDetails(data):any {

    let endpoint = STUDIO_PORTAL_API_PATHS.company_add_update;
    return this.baseApiService.post(endpoint,data);

  }
  
  /** updateCompanyDetails - updateCompanyDetails used to call update company details*/

 updateCompanyDetails(data):any {

  let endpoint = STUDIO_PORTAL_API_PATHS.company_add_update;
  return this.baseApiService.put(endpoint,data);

}
  /** updateCompanyDetails - updateCompanyDetails used to call update company details*/

  getCompaniesList():any {

    let endpoint = STUDIO_PORTAL_API_PATHS.companies_list;
    return this.baseApiService.get(endpoint);
  
  }

   /** enableCompany - enableCompany used to call enable company api*/

   enableCompany(companyId):any {

    let endpoint = STUDIO_PORTAL_API_PATHS.enable_company + '?' +'companyId=' + companyId;
    return this.baseApiService.put(endpoint,companyId);
  
  }
  
   /** disableCompany - disableCompany used to call disable company api*/

   disableCompany(companyId):any {

    let endpoint = STUDIO_PORTAL_API_PATHS.disable_company + '?' +'companyId=' + companyId;
    return this.baseApiService.put(endpoint,companyId);
  
  }
  
  /** addStudioCompanyUser - addStudioCompanyUser used to post add company user api*/

  addStudioCompanyUser(data): any { 
    let endpoint = STUDIO_PORTAL_API_PATHS.add_customer_user;
    return this.baseApiService.post(endpoint,data);
  }

   /** editStudioCompanyUser - editStudioCompanyUser used to update company user api*/

   editStudioCompanyUser(data): any { 
    let endpoint = STUDIO_PORTAL_API_PATHS.edit_customer_user;
    return this.baseApiService.put(endpoint,data);
  }
  
  /** getUserByUserProfileId - getUserByUserProfileId used to call get Project User details by projectId and company id*/
  getUserByUserProfileId(userProfileId): any {
    const url = `${STUDIO_PORTAL_API_PATHS.users_by_profile_id}?userProfileId=${userProfileId}`;
    return this.baseApiService.get(url);
  }

  /** getHtmlContent - getHtmlContent used to call get handbook api*/
  getHtmlContent(url: string): Observable<string> {
    return this.http.get(url,{responseType:'text'});
  }

 /** Setter for companiesData*/
  setCompaniesData(data: any[]) {
    this.companiesData.next(data);
  }

  /** getter for companiesData*/
  getCompaniesSubject() {
    return this.companiesData.asObservable();
  }

}
