import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { roles } from 'mockData/development/studio-users';
import { ADD_PROJECT_USER, ADD_WB_USER_TITLE, AR_SECTION_TITLES, SEARCH_USER_TEXT, PLACEHOLDER_TEXT } from '../../constants/common';
import { SearchDataService } from '../../services/search.service';
import { BUTTON_TEXTS } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { AddWbsoService } from 'src/app/feature/studio-portal/services/add-wbso.service';
import { WbUserData } from '../../models/wbuser';
import { ToastService } from '../toast.service';
import { SUCCESS_ERROR } from 'src/assets/constants/globalContants';
import { WBCONSTANT_DATA } from '../../../shared/constants/common'
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-add-wb-user',
  templateUrl: './add-wb-user.component.html',
  styleUrls: ['./add-wb-user.component.scss']
})
export class AddWbUserComponent implements OnInit {
  response: unknown;
  selectedRole: any;
  filteredRoles: any[];
  filteredAr: any;
  deptid: any[] = [];
  arSelected: boolean;
  permissionsData: any;
  userLoggedIn: any;
  @Output() emitError = new EventEmitter<any>();
  initialRolesFormValues: any;
  formatter(user: { username: string; designation: string; }): any {
    throw new Error('Method not implemented.');
  }
  @Input() public title: string = ADD_WB_USER_TITLE;
  @Input() public isEditMode: boolean = false;
  @Input() public userDetails: any = {};
  @Input() showSearchBarInput: boolean;
  @Input() userType;
  @Input() userRoleMetaData;
  @Input() wbsoDepartmentDataList;
  @Input() usersList;
  @Input() arData;
  @Input() departmentData: any[];
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  modalReference: NgbModalRef;
  searchUserText = SEARCH_USER_TEXT;
  placeholder: string = PLACEHOLDER_TEXT;
  rolesForm: FormGroup;
  departmentForm: FormGroup;
  arForm: FormGroup;
  roles = roles;
  isAddUserButtonEnabled = false;
  showArDetails = false;
  showDepartmentDetails = false;
  isDepartmentRole = false;
  searchComplete: boolean = false;
  showSearchBar: boolean = true;
  arTitles = AR_SECTION_TITLES;///chnaging it to dynamic
  addProjectUser = ADD_PROJECT_USER;
  saveText = BUTTON_TEXTS.save;
  addUserText = BUTTON_TEXTS.addUser;
  close = BUTTON_TEXTS.close;
  reset = BUTTON_TEXTS.reset;
  success_msg = SUCCESS_ERROR.wbso_success;
  error_msg = SUCCESS_ERROR.wbso_error;
  searchBarInput: string = null;
  searchDisabled: boolean = false;
  roleIds: any
  selectedRoleIds: string[] = [];
  selectedUser: any[] = [];
  departmentId: any[] = [];
  roleID: any[] = [];
  showroles: any;
  allDepartmentsControl = new FormControl(false);
  isFormReadOnly: boolean;
  public helpSliderVisible: boolean = false;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private searchService: SearchDataService,
    private addWbUserService: AddWbsoService,
    private loaderService: LoaderService,
    private toastService: ToastService,
  ) {

  }
  /**
   * toggleHelpSlider() - Method to toggle the visibility of the help slider
   * 
   * This method switches the `helpSliderVisible` boolean state between `true` and `false`,
   * effectively showing or hiding the help slider.
   *  @returns {void} This method does not return any value.
   */
  toggleHelpSlider(): void {
    this.helpSliderVisible = !this.helpSliderVisible;
  }

  selectRole(role: any) {
    this.selectedRole = role.role;
    this.roleIds = role.userRoleId;
    this.rolesForm.markAsDirty();
    if (this.selectedRole === "A/R") {
      this.arForm.markAsDirty();
      this.arForm.updateValueAndValidity();
    } else {
      // this.arForm.reset(); 
    }
    this.checkFormReadiness();
  }

  ngOnInit(): void {

    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.userLoggedIn = userProfile.userProfileId;
    this.permissionsData = { ...userProfile.roleAssigned };

    this.filteredAr = this.arData?.slice(2);
    // Initialize component data and forms
    this.departmentData = this.wbsoDepartmentDataList;
    this.showroles = this.userRoleMetaData;
    this.searchComplete = this.isEditMode;
    this.showSearchBar = this.searchService.searchshow || this.showSearchBarInput;
    this.departmentData = this.wbsoDepartmentDataList;
    this.initializeForms();
    this.rolesForm.valueChanges.subscribe(() => {
      this.checkFormReadiness();
    });
    this.arForm.valueChanges.subscribe(() => {
      this.checkFormReadiness();
    });

    this.departmentForm.valueChanges.subscribe(() => {
      this.checkFormReadiness();
    });

    this.rolesForm.get('userRole').valueChanges.subscribe(value => {
      this.departmentId = [];
      this.isAddUserButtonEnabled = value !== null && value !== '';
      if (value.portalType === 'wbso') {
        this.showArDetails = value.userRoleId === WBCONSTANT_DATA.wbsoAR;
        if (value.userRoleId === WBCONSTANT_DATA.wbsoAR) {
          this.arSelected = true;
        } else {
          this.arSelected = false;
          this.arForm.reset();
        }
        this.showDepartmentDetails = [WBCONSTANT_DATA.department, WBCONSTANT_DATA.deptmartmentUser].includes(value.userRoleId);
      }
      if (this.showDepartmentDetails) {
        this.isAddUserButtonEnabled = false;
      }
    });

    this.filteredRoles = this.arData?.slice(0, 2);
    if (this.filteredRoles?.length > 0) {
      this.selectedRole = this.filteredRoles[0].role; // Default to the first role
      this.roleIds = this.filteredRoles[0].userRoleId;
    }
    this.departmentForm.get('showDepartmentDetails')?.valueChanges.subscribe(value => {
      this.isAddUserButtonEnabled = value !== null && value !== '';
    });
    if (this.isEditMode) {
      this.setupEditDetails(this.userDetails);
      this.rolesForm.valueChanges.subscribe(() => {
        this.isInitialRolesFormState();
      });
    }
    if (this.userDetails.userProfileId === this.userLoggedIn) {
      this.isFormReadOnly = true;
      this.disableForms();
    } else {
      this.isFormReadOnly = !this.hasEditPermission() || this.userDetails.statusActiveInd !== 'Y';
      if (this.isFormReadOnly) {
        this.disableForms();
      }
    }
  }

  /**
   * isInitialRolesFormState() - Method to handle disable functionality on save button 
  */
  isInitialRolesFormState() {
    return JSON.stringify(this.rolesForm?.getRawValue()) == JSON.stringify(this.initialRolesFormValues)
  }

  /* checkFormReadiness() - Determines if the "Add User" button should be enabled.
  * 
  */
  checkFormReadiness(): void {
    this.isAddUserButtonEnabled = !this.isFormReadOnly && (
      this.rolesForm.dirty || this.arForm.dirty || this.departmentForm.dirty
    );
  }

  /**
  *  initializeForms() - Initializes the forms used in the component.
  */
  private initializeForms(): void {
    this.rolesForm = this.fb.group({
      userRole: new FormControl('')
    });
    this.arForm = this.fb.group({
      finalReview: [false],
      arNumber: [false]
    });
    this.departmentForm = this.fb.group({});
    this.departmentData?.forEach(dept => {
      this.departmentForm.addControl(dept.departmentId.toString(), this.fb.control(false));
    });
  }

  /**
  * setupEditDetail() - Sets up the component with user details for editing.
  * @param details - The user details to populate the forms.
  */
  public setupEditDetails(details: any): void {
    this.showSearchBar = true;
    this.searchDisabled = true;

    const userInfo = [{
      department: details.departments,
      emailAddress: details.emailAddress,
      firstName: details.firstName,
      jobTitle: details.jobTitle,
      lastName: details.lastName,
      wbsoUsersId: details.userProfileId,
      userRoles: details.userRoles.map(role => role.userRoleId), // Array of userRoleIds
      ardetails: details.arDetails
    }];

    this.selectedUser = userInfo;
    details.userRoles.forEach(role => {
      const matchingRole = this.showroles.find(showRole => showRole.userRoleId === details.userRoles[0].userRoleId);
      if (matchingRole) {
        this.selectedRole = matchingRole.role;
        this.rolesForm.get('userRole')?.setValue(matchingRole, { emitEvent: false });
        if (matchingRole.role === "A/R") {
          this.showArDetails = true;
          const finalReview = details?.userRoles.some(r => r.roleSection === "Final Review");
          const arNumber = details.userRoles.some(r => r.roleSection === "AR Number");
          this.arForm.patchValue({
            finalReview: finalReview,
            arNumber: arNumber
          });
          this.selectedRole = finalReview ? 'Level 1 Approver' : 'Level 2 Approver';

        }
      } else {
        console.warn('No matching role found for userRoleId:', role.userRoleId);
      }
    });
    this.departmentId = [];
    if (details.departments?.length > 0) {
      details.departments.forEach(dept => {
        const departmentControl = this.departmentForm.get(dept.departmentId.toString());
        if (departmentControl) {
          departmentControl.setValue(true, { emitEvent: false });
          this.departmentId.push({ departmentId: dept.departmentId });
        }
      });
      this.showDepartmentDetails = true;
    } else {
      this.showDepartmentDetails = false;
    }
    if (details.statusActiveInd !== 'Y') {
      this.disableForms();
    }
  }

  /**
   * disableForms() - Disables all form controls for roles, department, and AR forms.
   */
  private disableForms(): void {
    this.rolesForm.disable();
    this.departmentForm.disable();
    this.arForm.disable();
  }

  /**
  * hasEditPermission() - Checks if the current user has permission to edit a WBSO user.
  */
  hasEditPermission(): boolean {
    const allowedRoles = this.permissionsData['editWBSOUser'];
    return allowedRoles && allowedRoles.includes(this.permissionsData.role);
  }

  /**
  *onSearchCompleted() - Called when a user search is completed.
  * @param selectedUser - The user selected from the search results.
  */
  onSearchCompleted(selectedUser: any): void {
    console.log('Search completed with user:', selectedUser);
    this.searchComplete = true;
    this.selectedUser = selectedUser;
    this.searchDisabled = true;
    if (this.searchComplete) {
      this.rolesForm.get('userRole').enable();
    }
  }

  /**
  * closeModal() - Closes the modal.
  */
  closeModal(): void {
    this.activeModal.close();
  }
  /**
  * resetSearch() - Resets the search and form values.
  * This is called when the user wants to clear the current input.
  */
  resetSearch(): void {
    this.searchComplete = false;
    this.selectedUser = [];
    this.rolesForm.reset();
    this.rolesForm.get('userRole').disable();
    this.arForm.reset();
    this.departmentForm.reset();
    this.searchBarInput = null;
    this.searchDisabled = false;
    this.isDepartmentRole = false;
  }

  /**
  * addDept() - Adds a department ID to the list of selected departments.
  * @param id - The department ID to add.
  */
  addDept(id: any, event: any): void {
    if (event.target.checked) {
      if (id === 1) {
        for (const controlName in this.departmentForm.controls) {
          if (this.departmentForm.controls.hasOwnProperty(controlName)) {
            this.departmentForm.controls[controlName].setValue(true, { emitEvent: false });
          }
        }
        this.departmentId = this.departmentData.map(department => ({ departmentId: department.departmentId }));
      } else {
        this.departmentId.push({ departmentId: id });
        if (((this.departmentData.length == this.departmentId.length) || (this.departmentData.length - 1 == this.departmentId.length)) && this.departmentForm.controls['1']) {
          this.departmentForm.controls['1'].setValue(true, { emitEvent: false });
        }
      }
    } else {
      if (id === 1) {
        for (const controlName in this.departmentForm.controls) {
          if (this.departmentForm.controls.hasOwnProperty(controlName)) {
            this.departmentForm.controls[controlName].setValue(false, { emitEvent: false });
          }
        }
        this.departmentId = [];
      } else {
        const indexToRemove = this.departmentId.findIndex(dept => dept.departmentId === 1);
        if (indexToRemove !== -1) {
          this.departmentId.splice(indexToRemove, 1);
        }
        this.departmentId = this.departmentId.filter(dept => dept.departmentId !== id);
        if (this.departmentData.length != this.departmentId.length && this.departmentForm.controls['1']) {
          this.departmentForm.controls['1'].setValue(false, { emitEvent: false });
        }
      }
    }
    this.isAddUserButtonEnabled = this.departmentId.length > 0;
  }

  /**
     * saveUser() - Add WBuser with assigned role.
     * 
     */
  saveUser(): void {
    let userInfo = JSON.parse(localStorage.getItem('user-profile'));
    let statusActiveInd = userInfo.statusActiveInd
    let loginUserId = userInfo.userProfileId
    if (this.rolesForm.valid) {
      const formData = {
        ...this.selectedUser[0],
        ...this.rolesForm.value,
        arDetails: this.arForm.value,
        departmentDetails: this.departmentForm.value
      };
      const WbUserData: WbUserData = {

        firstName: this.selectedUser[0].firstName,
        lastName: this.selectedUser[0].lastName,
        emailAddress: this.selectedUser[0].emailAddress,
        jobTitle: this.selectedUser[0].jobTitle,
        contactNo: null,
        statusActiveInd: statusActiveInd,
        loginUserId: loginUserId,
        userRoleIds: this.getUserRoleIds(),
        departments: this.departmentId
      };
      this.loaderService.setLoadingState(true);
      if (this.isEditMode) {
        this.updateUser(WbUserData);
      }
      else {
        this.addWbUserService.addWbUser(WbUserData).subscribe(
          (response: any) => {
            this.response = response
            let message = { status: "success", message: response.message }
            this.activeModal.close(message);
            this.loaderService.setLoadingState(false);
          },
          error => {
            let message = { status: "error", message: error }
            this.loaderService.setLoadingState(false);
            console.error(SUCCESS_ERROR.wbso_response_error_msg, error);
            this.emitError.emit(message);

          }
        )
      }
    }
    else {
      console.warn(SUCCESS_ERROR.wbso_warning_msg);
    }
  }

  /**
 * getUserRoleIds() - Generates an array of role IDs based on selected roles in the form.
*/
  getUserRoleIds(): any[] {
    const roles = [this.rolesForm.value.userRole.userRoleId];
    if (this.arForm.get('arNumber')?.value) {
      roles.push(this.filteredAr[0].userRoleId);

    }
    if (this.arForm.get('finalReview')?.value && this.roleIds) {
      roles.push(this.roleIds)
    }
    return roles;
  }

  updateUser(userData: WbUserData): void {
    this.addWbUserService.updateWbUser(userData).subscribe(
      (response: any) => {
        this.response = response
        let message = { status: "success", message: response.message }
        this.loaderService.setLoadingState(false);
        this.activeModal.close(message);
      },
      (error: any) => {
        let errorMessage = { status: "error", message: error }
        this.loaderService.setLoadingState(false);
        this.emitError.emit(errorMessage);
      }
    );
  }

  /**
    * showSuccess() - Displays a success toast notification with custom content and options.
    * @param successTpl - The template reference containing the toast's content.
    */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
  /**
     * showDanger() - Displays a danger (error) toast message.
     * This method is called when there is an error in adding the user.
     * @param dangerTpl - The template reference for the danger message to display.
     */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}

