<div class="container-fluid page-wrapper" *ngIf="permissionsData['editFaq']">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-4 d-none d-md-block">
        <ol class="handbook-list" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'overview-tab'}" id="overview-tab" role="tab"
            aria-controls="overview-content" aria-selected="true" (click)="selectTab(constants.overview_tab)">
            {{constants.overview}}
          </li>
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'account-tab'}" id="account-tab" role="tab"
            aria-controls="details-content" (click)="selectTab(constants.account_tab)">
            {{constants.account}}
          </li>
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'projects-tab'}" id="projects-tab" role="tab"
            aria-controls="account-content" (click)="selectTab(constants.your_projects_tab)">
            {{constants.projects}}
          </li>
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'requests-tab'}" id="requests-tab" role="tab"
            aria-controls="projects-content" (click)="selectTab(constants.requests_tab)">
            {{constants.requests}}
          </li>
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'users-tab'}" id="users-tab" role="tab"
            aria-controls="requests-content" (click)="selectTab(constants.users_tab)">
            {{constants.users}}
          </li>
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'contact-us-tab'}" id="contact-us-tab"
            role="tab" aria-controls="users-content" (click)="selectTab(constants.contact_us_tab)">
            {{constants.contact_us}}
          </li>
          <li class="nav-link" [ngClass]="{'show active': selectedTab === 'client-resources-tab'}"
            id="client-resources-tab" role="tab" aria-controls="contact-us-content"
            (click)="selectTab(constants.client_resource_tab)">
            {{constants.client_resource}}
          </li>
        </ol>
      </div>
      <!-- Tab Contents -->
      <div class="col-md-8 col-lg-8 col-sm-12">
        <div class="tab-content" id="v-pills-tabContent">

          <!-- Overview Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'overview-tab'}" id="overview-tab"
            role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.overview}}</h1>
                <div *ngIf="isEditable">
                  <button (click)="addOverviewSection()" type="button"
                    class="common-btn-primary btn mb-3">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getOverviewSections(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'overview', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="title1" class="form-label">{{constants.title}}</label>
                  <input formControlName="title" class="form-control" placeholder="Type Question Here" id="title1" />
                </div>
                <div class="custom-floating-label">
                  <label for="details1" class="form-label">{{constants.details}}</label>
                  <textarea formControlName="details" class="form-control common-text-area" placeholder="Type Answer Here"
                    id="details1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getOverviewSections(); let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.overview_faq)" type="button" class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.overview_faq) || isOverviewFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.overview_tab)" type="button" class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.overview_faq) || isOverviewFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>

          <!-- Company Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'account-tab'}" id="account-tab"
            role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.account}}</h1>
                <div *ngIf="isEditable">
                  <button (click)="addAccountSection()" type="button"
                    class="common-btn-primary btn mb-3">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getAccountSections(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'account', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="accountTitle1" class="form-label">{{constants.title}}</label>
                  <input formControlName="accountTitle" class="form-control" placeholder="Type Question Here"
                    id="accountTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="accountDetails1" class="form-label">{{constants.details}}</label>
                  <textarea formControlName="accountDetails" class="form-control common-text-area"
                    placeholder="Type Answer Here" id="accountDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getAccountSections(); let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.account_faq)" type="button" class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.account_faq) || isAccountFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.account_tab)" type="button" class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.account_faq) || isAccountFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>

          <!-- Projects Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'projects-tab'}" id="projects-tab"
            role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.projects}}</h1>
                <div *ngIf="isEditable">
                  <button (click)="addProjectsSection()" type="button"
                    class="common-btn-primary btn mb-3">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getProjectsSections(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'projects', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="projectTitle1" class="form-label">{{constants.title}}</label>
                  <input formControlName="projectTitle" class="form-control" placeholder="Type Question Here"
                    id="projectTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="projectDetails1" class="form-label">{{constants.details}}</label>
                  <textarea formControlName="projectDetails" class="form-control common-text-area"
                    placeholder="Type Answer Here" id="projectDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getProjectsSections(); let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.projects_faq)" type="button" class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.projects_faq) || isProjectsFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.your_projects_tab)" type="button" class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.projects_faq) || isProjectsFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>

          <!-- Requests Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'requests-tab'}" id="requests-tab"
            role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.requests}}</h1>
                <div *ngIf="isEditable">
                  <button (click)="addRequestsSection()" type="button"
                    class="common-btn-primary btn mb-3">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getRequestsSections(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'requests', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="requestTitle1" class="form-label">{{constants.title}}</label>
                  <input formControlName="requestTitle" class="form-control" placeholder="Type Question Here"
                    id="requestTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="requestDetails1" class="form-label">{{constants.details}}</label>
                  <textarea formControlName="requestDetails" class="form-control common-text-area"
                    placeholder="Type Answer Here" id="requestDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getRequestsSections(); let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.requests_faq)" type="button" class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.requests_faq) || isRequestsFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.requests_tab)" type="button" class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.requests_faq) || isRequestsFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>

          <!-- Users Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'users-tab'}" id="users-tab"
            role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.users}}</h1>
                <div *ngIf="isEditable">
                  <button (click)="addUsersSection()" type="button"
                    class="common-btn-primary btn mb-3">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getUsersSections(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'users', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="userName1" class="form-label">{{constants.title}}</label>
                  <input formControlName="userName" class="form-control" placeholder="Type Question Here" id="userName1" />
                </div>
                <div class="custom-floating-label">
                  <label for="userDetails" class="form-label">{{constants.details}}</label>
                  <input formControlName="userDetails" class="form-control" placeholder="Type Answer Here"
                    id="userDetails" />
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getUsersSections(); let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.users_faq)" type="button" class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.users_faq) || isUsersFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.users_tab)" type="button" class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.users_faq) || isUsersFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>

          <!-- Contact Us Tab -->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'contact-us-tab'}" id="contact-us-tab"
            role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.contact_us}}</h1>
                <div *ngIf="isEditable">
                  <button (click)="addContactUsSection()" type="button"
                    class="common-btn-primary btn mb-3">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getContactUsSections(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'contactUs', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="contactTitle1" class="form-label">{{constants.title}}</label>
                  <input formControlName="contactTitle" class="form-control" placeholder="Type Question Here"
                    id="contactTitle1" />
                </div>
                <div class="custom-floating-label">
                  <label for="contactDetails1" class="form-label">{{constants.details}}</label>
                  <textarea formControlName="contactDetails" class="form-control common-text-area"
                    placeholder="Type Answer Here" id="contactDetails1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getContactUsSections(); let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.contactus_faq)" type="button" class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.contactus_faq) || isContactUsFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.contact_us_tab)" type="button" class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.contactus_faq) || isContactUsFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>

          <!-- Client Resource tab-->
          <div class="tab-pane fade" [ngClass]="{'show active': selectedTab === 'client-resources-tab'}"
            id="resource-tab" role="tabpanel">
            <form>
              <div class="d-flex justify-content-between align-items-center">
                <h1 class="tab-content-title">{{constants.client_resource}}</h1>
                <div *ngIf="isEditable">
                  <button type="button" class="common-btn-primary btn mb-3"
                    (click)="addClientResourceSection()">{{constants.add_faq}}</button>
                </div>
              </div>
              <div class="page-details-section" *ngFor="let section of getClientResourcesSection(); let i = index"
                [formGroup]="section">
                <div class="d-flex justify-content-end">
                  <i (click)="openDeleteConfirmationModal(content, 'clientResources', i)" class="bi bi-trash"></i>
                </div>
                <div class="custom-floating-label">
                  <label for="summary1" class="form-label">{{constants.title}}</label>
                  <input formControlName="clientTitle" class="form-control" placeholder="Type Question Here" id="summary1" />
                </div>
                <div class="custom-floating-label">
                  <label for="description1" class="form-label">{{constants.details}}</label>
                  <textarea formControlName="clientDetails" class="form-control common-text-area"
                    placeholder="Type Answer Here" id="description1" rows="5"></textarea>
                </div>
              </div>
              <div class="page-details-section" *ngIf="!isEditable">
                <div *ngFor="let section of getClientResourcesSection() let i = index" class="card">
                  <h2 class="page-sub-heading">{{ section.get('title').value }}</h2>
                  <p class="para-text-light">{{ section.get('details').value }}</p>
                </div>
              </div>
              <div *ngIf="isEditable" class="d-flex justify-content-end gap-3">
                <button (click)="openSubmitModal(constants.client_resource_faq)" type="button"
                  class="common-btn-secondary btn"
                  [disabled]="(!hasChanges(constants.client_resource_faq) || isClientResourceFormInvalid()) && !deletedFaqIds?.length">{{constants.save_changes}}</button>
                <button (click)="discardChanges(constants.client_resource_tab)" type="button"
                  class="common-btn-light btn"
                  [disabled]="(!hasChanges(constants.client_resource_faq) || isClientResourceFormInvalid()) && !deletedFaqIds?.length">{{constants.discard}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{constants.are_you_sure_you_want_to_delete}}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="confirmDelete()">{{constants.ok}}</button>
      <button type="button" class="common-btn-light btn" (click)="modal.close()">{{constants.cancel}}</button>
    </div>
  </div>
</ng-template>

<ng-template #unsavedChangesModalContent let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{constants.confirm_save_changes}}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="modal.close(true)">Yes</button>
      <button type="button" class="common-btn-light btn" (click)="modal.close(false)">No</button>
    </div>
  </div>
</ng-template>

<ng-template #submitModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">{{constants.submit_changes}}</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-secondary btn" (click)="modal.close(true)">Yes</button>
      <button type="button" class="common-btn-light btn" (click)="modal.close(false)">No</button>
    </div>
  </div>
</ng-template>