<div class="account-user-wrapper">
  <div class="container px-0">
    <div class="row">
      <div class="comman-title-block">
        <div class="d-flex mb-3">
          <img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
          <p class="back-link mb-0" role="button" routerLink="/feature/customer-dashboard/company">
            {{company_users.company}}
          </p>
        </div>
        <div class="comman-title-section">
          <h1>
            {{company_users.companyusers}}
          </h1>
          <button class="btn btn-dark custom-button" (click)="addUser()"> + {{company_users.adduser}}</button>
        </div>
        <div class="comman-filter-block">
          <div class="comman-filter-title-section mb-0">
            <p>{{company_users.filter}} :</p>
          </div>
          <div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
            <app-ng-multiselect-dropdown [placeholder]="'User'"
                                         [settings]="defaultSettings"
                                         [type]="userTypeahead"
                                         [data]="user"
                                         [filterData] ="userFilterData"
                                         [searchtextplaceholder]="'Type a name or email address'"
                                         (onSelect)="selectedUser($event)">
            </app-ng-multiselect-dropdown>
            <app-ng-multiselect-dropdown [placeholder]="'User Type'" 
                                         [settings]="defaultSettings"
										                     [data]="userType" 
                                         (onSelect)="selectedUserType($event)">
            </app-ng-multiselect-dropdown>
            <app-ng-multiselect-dropdown [placeholder]="'Status'"
                                         [settings]="defaultSettings"
                                         [data]="status"
                                         (onSelect)="selectedStatus($event)">
            </app-ng-multiselect-dropdown>
            <app-datepicker [placeholder]="'Last Login'" (dateRangeSelected)="onDateRangeSelected($event)"></app-datepicker>
            <app-ng-multiselect-dropdown [placeholder]="'Job Title'"
                                         [settings]="defaultSettings"
                                         [type]="userTypeahead"
                                         [data]="jobtitle"
                                         [filterData] ="jobTitleFilterData"
                                         [searchtextplaceholder]="'Type a job title'"
                                         (onSelect)="selectedJobtitle($event)">
            </app-ng-multiselect-dropdown>
            <app-ng-multiselect-dropdown [placeholder]="'Active Projects'"
                                         [settings]="defaultSettings"
                                         [data]="activeprojects"
                                         (onSelect)="selectedProject($event)">
            </app-ng-multiselect-dropdown>
          </div>

        </div>
      </div>
      <!-- Selected filters section -->
      <div class="selected-filters-section">
        <div *ngIf="isFiltersSelected" class="selected-filters">
          <div *ngFor="let activeprojects of filteredProjects" class="filter-tag">
            {{ activeprojects.text }}
            <button type="button" (click)="removeSelectedItem('activeprojects', activeprojects)">×</button>
          </div>
          <div *ngFor="let userType of filteredUserType" class="filter-tag">
						{{ userType.text }}
						<button type="button" (click)="removeSelectedItem('userType', userType)">×</button>
					</div>
					<div *ngFor="let status of filteredStatus" class="filter-tag">
            {{ status.text }}
            <button type="button" (click)="removeSelectedItem('status', status)">×</button>
          </div>
          <div *ngIf="selectedFromDate && selectedToDate"  class="filter-tag">
            {{ formatDateRange(selectedFromDate, selectedToDate) }}
            <button type="button" (click)="clearDateRange()">×</button>
          </div>
          <div *ngFor="let user of filteredUser" class="filter-tag">
            {{ user.firstname }}
            <button type="button" (click)="removeSelectedItem('user', user)">×</button>
          </div>
          <div *ngFor="let job of filteredJobtitle" class="filter-tag">
            {{ job.jobtitle }}
            <button type="button" (click)="removeSelectedItem('jobtitle', job)">×</button>
          </div>
          <a class="text-link" (click)="clearFilter()">{{ companyConstants.clearFilters }}</a>
        </div>
      </div>
      <div class="common-grid-section">
        <div class="common-grid-block">
          <div class="mt-4">
            <app-common-ag-grid class="ag-theme-custom"
                                [colDefs]="colDefs"
                                [rowData]="filteredRowData"
                                [colsForSmallScreen]="colsForSmallScreen"
                                [colsForMediumScreen]="colsForMediumScreen"
                                [colsForLargeScreen]="colsForLargeScreen"
                                [colsForExtraLargeScreen]="colsForExtraLargeScreen"
                                [editType]="'fullRow'"
                                [suppressClickEdit]="true"
                                [animateRows]="true"
                                (cellClicked)="onCellClicked($event)">
            </app-common-ag-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #successTpl>
  <div class="success">{{addUserSuccess}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{addUserError}}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>
