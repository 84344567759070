import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractFilename'
})
export class ExtractFilenamePipe implements PipeTransform {
  
  transform(inputURL: string): string {
    if (!inputURL) {
      return '';
    }
    // Extract the last part after the last "/"
    const segments = inputURL.split('/');
    return segments[segments.length - 1];
  }

}
