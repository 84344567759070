import { Injectable } from "@angular/core";
import { BaseApiService } from "src/app/shared/services/base-api/base-api.service";
import { STUDIO_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class CreditStatusDetailsService {
  constructor(public baseApiService: BaseApiService) { }
  /**
    * addaComment() - Sends a POST request to add a new comment.
    * @param data - The comment data to be sent in the request body.
    * @returns - Observable<any> - An observable of the response.
  */
  addaComment(data: any): any {
    let endpoint = STUDIO_PORTAL_API_PATHS.add_Comments;
    return this.baseApiService.post(endpoint, data);
  }
}