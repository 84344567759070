import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VIEW_QUOTES_CA } from 'mockData/development/studio-send-credit-application';
import { STUDIO_CREDIT_APPLICATION } from 'src/app/feature/studio-portal/constants/studio-portal.constant';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { ViewCreditAppServiceService } from 'src/app/shared/services/view-credit-app-service/view-credit-app-service.service';

@Component({
  selector: 'app-view-quotes',
  templateUrl: './view-quotes.component.html',
  styleUrls: ['./view-quotes.component.scss']
})
export class ViewQuotesComponent implements OnInit{
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  creditApplicationConstants = STUDIO_CREDIT_APPLICATION;
  viewQuotesCAConstants = VIEW_QUOTES_CA;
  caQuotesGroupDetails: any[] = [];
  caQuotes: any[] = [];
  @Input() caSeqId;
  @Input() caNumber;
  requestResponse: any;

  constructor(public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private accountService: ProjectsCreditApplicationService,
    private sharedViewCreditAppService:ViewCreditAppServiceService
  ){}
    
  ngOnInit(){

    this.getViewQuoteDetails()
  }

  /**
   * closeModal() - Method to close the modal
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * getViewQuoteDetails() - Method to get the view quote data.
   */

  getViewQuoteDetails(): void {
    this.loaderService.setLoadingState(true);
    this.accountService.getViewQuoteData(this.caSeqId).subscribe((response: any) => {
      this.loaderService.setLoadingState(false);
      if (response.success) {
        this.caQuotesGroupDetails = response.caQuotesSearchResponseDetails.caQuotesGroupDetails;
        this.caQuotes = response.caQuotesSearchResponseDetails.caQuotesGroupDetails.caQuotes;
      } 
      else {
        console.error('Error fetching quote data');
      }

      error: (error) => {
        this.loaderService.setLoadingState(false);
        console.error('Error fetching quote data');
      }
      
    });
  }

  /**
* viewQuotes() - method to get the quotes document through API call
*/
viewQuotes(url:any,section:any): void {
  this.loaderService.setLoadingState(true);
  this.sharedViewCreditAppService.getCreditAppReport(url,section)
    .pipe()
    .subscribe({
      next: (response: any) => {
        const docUrl = response?.url;
        this.loaderService.setLoadingState(false);
        window.open(docUrl, '_blank');
      },
      error: (error) => {
        console.error(error);
        this.requestResponse = error;
        this.loaderService.setLoadingState(false);
        this.showDanger(this.dangerTpl);
      }
    })
}

/**
  * showDanger() - Method to display a danger (error) toast message
  * @param dangerTpl - The template reference for the danger message to display
*/
showDanger(dangerTpl: any): void {
  this.toastService.show(dangerTpl, {
    classname: 'bg-danger text-light',
    delay: 3000,
  });
}

}
