<button class="btn btn-primary" (click)="openContentModalOneTC(contentOne)">T&C</button>
<button class="btn btn-primary" (click)="openContentModalTwoDB(contentTwo)">DB</button>
<button class="btn btn-primary" (click)="openContentModalThreeCOI(contentThree)">COI</button>
<button class="btn btn-primary" (click)="openContentModalFourApp(contentFourApp)">App</button>

<button data-bs-toggle="modal" data-bs-target="#thirdpartycommhouse">Thirdpartycommhouse</button>
<!-- common modal section-->
<div class="modal fade" id="thirdpartycommhouse" tabindex="-1" aria-labelledby="commonModalLabel" aria-hidden="false">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content common-modal-xl">
      <div class="modal-body justify-content-center align-items-center">
        <div class="cm-header-section">
          <h5 class="modal-title" id="commonModalLabel">Credit Application</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
              class="cm-close-text">Close</span></button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
              <div class="modal-left-section me-4">
               
                <h4 class="common-left-section-title">Credit Application</h4>
                <div class="mb-2 mt-3">
                  <div class="list-group list-group-flush mb-2">
                    <ul class="step-list">
                        <li>
                            <span class="step-text">Status</span>
                          </li>
                      <li>
                        <span class="step-text current-step mb-2">Documents</span>
                      </li>
                      <li>
                        <span class=" mb-1"><a class="text-link"><img src="../assets/images/pdf-blue-icon.svg" alt=""
                            class="me-2">Document Packet</a></span>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr>
                <h4 class="common-left-section-title mt-4">Company Info</h4>
                <div class="company-meta-data">
                  <p class="title mb-0"><strong class="common-title">PROJECT:</strong><span class="common-content">The new show</span></p>
                  <p class="title mb-0"><strong class="common-title">Comapny:</strong></p>
                  <p class="pb-2 pt-0 mb-0 commmon-content">A Brand New Production Company</p>
                  <p class="title mb-0"><strong class="common-title">TYPE:</strong><span class="common-content">Major Studio</span></p>
                  <p class="title mb-0"><strong class="common-title">APPLICATION DATE:</strong><span class="common-content">1/1/24</span></p>
                  <div class="d-flex flex-column mt-2">
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Projects & Quotes</a></span>
                    <span class=" mb-1"><a class="text-link"><img src="../assets/images/link-icon.svg" alt=""
                          class="me-2">View Company</a></span>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
              <div class="container cm-main-container px-0 mx-0">

                <div class="common-modal-input-section grey-bg review-panel">
                  <div class="row pt-3">
                    <div class="col-md-4">
                        <h4 class="right-section-heading">Purchase Order</h4>
                      </div>
                    <div class="col-md-4"><span class="d-flex gap-3 align-items-center"><img src="../assets/images/pdf-icon.svg" alt="">PO.pdf</span></div>
                    <div class="col-md-4 text-end"><a href="#" class="text-link">View</a></div>
                  </div>
                  <hr class="my-3">
                  <div class="row pt-3">
                    <div class="col-md-4">
                        <h4 class="right-section-heading">Certificate of Insurance</h4>
                      </div>
                    <div class="col-md-4"><span class="d-flex gap-3 align-items-center"><img src="../assets/images/pdf-icon.svg" alt="">COI.pdf</span></div>
                    <div class="col-md-4 text-end"><a href="#" class="text-link">View</a></div>
                  </div>
                  <div class="row-btn-section">
                    <button (click)="openContentModal(content)"
                      type="button"
                      class="btn common-btn-secondary mt-4 me-2 mb-1"
                    >
                    View Documents Activity
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
    <div class="white-box">
        <h2 class="section-main-header text-left common-heading">
          Credit Application Form Activity
        </h2>
        <div class="comment-section">
          <div class="row comment-list">
            <div class="commnet-icon-section col-md-1">
              <span class="user-icon-text">AN</span>
            </div>
            <div class="common-comment-section col-11">
              <p class="font-14 mb-0">
                <strong class="common-content-bold"
                  >Andrea Noj</strong
                >
              </p>
              <p class="common-small-content">xx/xx/xx xx:xxAM</p>
              <p class="common-normal-content">
                Reviewed Certificate of Insurance
              </p>
            </div>
          </div>
          <hr>
          <div class="row comment-list">
            <div class="commnet-icon-section col-md-1">
              <span class="user-icon-text">AN</span>
            </div>
            <div class="common-comment-section col-11">
              <p class="font-14 mb-0">
                <strong class="common-content-bold"
                  >Andrea Noj</strong
                >
              </p>
              <p class="common-small-content">xx/xx/xx xx:xxAM</p>
              <p class="common-normal-content">
                Reviewed Purchase Order
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
          <button type="button" class="btn common-btn-secondary" (click)="modal.close()">Close</button>
        </div>
        </div>
      </div>
</ng-template>
<ng-template #contentOne let-modal>
  <div class="wrapper">
  <div class="container">
    <h2 class="common-heading mb-4">John Doe Activity</h2>
    <div class="container px-0">
  <div class="grey-box mb-0">
    <div class="inner-content">
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
      <p>No Activity</p>
    </div>
  </div>
</div>
</div>
<div class="d-flex justify-content-end mt-4">
  <button type="button" class="btn common-btn-secondary" (click)="modal.close()">Close</button>
</div>
</div>
</ng-template>
<ng-template #contentTwo let-modal>
  <div class="wrapper">
    <div class="container">
      <h2 class="common-heading mb-4">D&B Report Activity</h2>
      <div class="container px-0">
  <div class="grey-box mb-0">
    <div class="inner-content">
      <p>No Activity</p>
    </div>
  </div>
  </div>
  </div>
  <div class="d-flex justify-content-end">
    <button type="button" class="btn common-btn-secondary mt-4" (click)="modal.close()">Close</button>
  </div>
  </div>
</ng-template>
<ng-template #contentThree let-modal>
  <div class="wrapper">
    <div class="container">
      <h2 class="common-heading mb-4">Certificate of Insurance Activity</h2>
      <div class="container px-0">
  <div class="grey-box mb-0">
    <div class="inner-content">
      <p>No Activity</p>
    </div>
  </div>
  </div>
  </div>
  <div class="d-flex justify-content-end mt-4">
    <button type="button" class="btn common-btn-secondary" (click)="modal.close()">Close</button>
  </div>
  </div>
</ng-template>
<ng-template #contentFourApp let-modal>
  <div class="wrapper">
    <div class="container">
      <h2 class="common-heading mb-4">
        Comments(5)
      </h2>
      <div class="container px-0">
        <div class="grey-box">

          <div class="comment-section">
            <div class="row comment-list">
              <div class="commnet-icon-section col-md-1">
                <span class="user-icon-text">AK</span>
              </div>
              <div class="common-comment-section col-11">
                <p class="font-14 mb-0">
                  <strong class="common-content-bold"
                    >Andrew Noel</strong
                  >
                </p>
                <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                <p class="common-normal-content">
                  Sent email to Trade Reference 1
                </p>
              </div>
            </div>
            <div class="row comment-list">
              <div class="commnet-icon-section col-md-1">
                <span class="user-icon-text">AK</span>
              </div>
              <div class="common-comment-section col-11">
                <p class="font-14 mb-0">
                  <strong class="common-content-bold"
                    >Andrew Noel</strong
                  >
                </p>
                <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                <p class="common-normal-content">
                  Asked revisions for Banking References:<br />
                  <strong class="common-content-extra-bold"
                    >“Could you please let us know the contact’s full
                    name?”</strong
                  >
                </p>
              </div>
            </div>
            <div class="row comment-list no-border">
              <div class="commnet-icon-section col-md-1">
                <span class="user-icon-text">AK</span>
              </div>
              <div class="common-comment-section col-11">
                <p class="font-14 mb-0">
                  <strong class="common-content-bold"
                    >Andrew Noel</strong
                  >
                </p>
                <p class="common-small-content">xx/xx/xx xx:xxAM</p>
                <p class="common-normal-content">Lorem Ipsum</p>
              </div>
            </div>
          </div>
        </div>
  </div>
  </div>
  <div class="d-flex justify-content-end">
    <button type="button" class="btn common-btn-secondary" (click)="modal.close()">Close</button>
  </div>
  </div>
</ng-template>
