import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { COMMON_AG_GRID_CONSTANTS, COMMON_GRID_OPTIONS } from '../../constants/common';
import { COMMON_GRID_BREAKPOINTS, ITEMS_PER_PAGE, PAGINATION_PAGES } from '../../enum/shared.enum';

@Component({
  selector: 'app-common-ag-grid',
  templateUrl: './common-ag-grid.component.html',
  styleUrls: ['./common-ag-grid.component.scss']
})
export class CommonAgGridComponent {
  itemsPerPage = ITEMS_PER_PAGE;
  @Input() enableBrowserTooltips: boolean;
  @Input() rowData: any[] = [];  // Input data array holding the row data for the ag-Grid
  @Input() colDefs: ColDef[] = [];  // Input array holding the column definitions for the ag-Grid
  @Output() gridReady = new EventEmitter<any>();  // Output event emitter to emit when the grid is ready
  @Output() cellClicked = new EventEmitter<any>();

  // Input arrays for column visibility based on different screen sizes
  @Input() colsForSmallScreen: string[] = []; 
  @Input() colsForMediumScreen: string[] = []; 
  @Input() colsForLargeScreen: string[] = [];
  @Input() colsForExtraLargeScreen: string[] = [];

  @Input() editType: string | null = null; // Add Input for edit type
  @Input() suppressClickEdit: boolean = false; // Add Input for suppressClickEdit
  @Input() animateRows: boolean = true; // Add Input for animateRows
  @Input() pageSizeOptions: number[] = [this.itemsPerPage.ten, this.itemsPerPage.twenty, this.itemsPerPage.thirty, this.itemsPerPage.fourty, this.itemsPerPage.fifty];  // Input for page size options in pagination
  startRow: number;  // Variable to store the starting row index of the current page
  endRow: number;  // Variable to store the ending row index of the current page
  totalRows: number;  // Variable to store the total number of rows in the grid
  agGridConstants = COMMON_AG_GRID_CONSTANTS;
  pages = PAGINATION_PAGES;
  breakpoints = COMMON_GRID_BREAKPOINTS;

  // Grid options configuration for ag-Grid
  @Input() gridOptions: GridOptions = COMMON_GRID_OPTIONS;
  gridApi!: GridApi;

  /**
   * onGridReady() - Method to handle the grid's initialization
   * @param params - Parameters passed when the grid is ready
   */
  onGridReady(params: any) {
    this.gridApi = params.api; 
    this.gridReady.emit(params);
    this.updatePagination();
    this.adjustColumns(window.innerWidth);
  }

  /**
   * onPageSizeChanged() - Method to handle changes in the number of items per page in the grid
   * @param event - The event containing the new page size value
   */
  onPageSizeChanged(event: any) {
    const value = event.target.value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.updatePagination();
  }

  /**
   * onBtNext() - Method to handle clicking the "Next Page" button
   */
  onBtNext() {
    window.scrollTo(0,0);
    this.gridApi.paginationGoToNextPage();
    this.updatePagination();
  }

  /**
   * onBtPrevious() - Method to handle clicking the "Previous Page" button
   */
  onBtPrevious() {
    window.scrollTo(0,0);
    this.gridApi.paginationGoToPreviousPage();
    this.updatePagination();
  }

  /**
   * updatePagination() - Method to update the pagination details such as the current start and end rows
   */
  updatePagination() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const pageSize = this.gridApi.paginationGetPageSize();
      this.startRow = currentPage * pageSize + this.pages.one;
      this.endRow = Math.min((currentPage + this.pages.one) * pageSize, this.totalRows);
      this.totalRows = this.rowData?.length;
    }
  }

  /**
   * @HostListener('window:resize') - Method to listen to window resize events
   * Adjusts the column visibility based on the screen size when the window is resized
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const screenWidth = event.target.innerWidth;
    this.adjustColumns(screenWidth);
  }

  /**
   * adjustColumns() - Method to adjust the visibility of columns based on screen width
   * @param screenWidth - The width of the screen to determine which columns to display
   */
  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });

      // Set visible columns based on the screen width ranges
      if (screenWidth <= this.breakpoints.threeSeventyEight) {
        this.setVisibleColumns(this.colsForSmallScreen);
      } else if (screenWidth > this.breakpoints.threeSeventyEight && screenWidth <= this.breakpoints.fiveHundredThirtyEight) {
        this.setVisibleColumns(this.colsForMediumScreen);
      } else if (screenWidth > this.breakpoints.fiveHundredAndEleven && screenWidth <= this.breakpoints.nineNintyTwo) {
        this.setVisibleColumns(this.colsForLargeScreen);
      } else {
        this.setVisibleColumns(this.colsForExtraLargeScreen);
      }
    }
  }

  /**
   * setVisibleColumns() - Method to set which columns are visible
   * @param visibleCols - An array of column IDs that should be visible
   */
  setVisibleColumns(visibleCols: string[]) {
    const allColumnIds: string[] = [];
    this.gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getColId());
    });
    this.gridApi.setColumnsVisible(allColumnIds, false);
    this.gridApi.setColumnsVisible(visibleCols, true);
  }
  
  /**
   * Method to handle the cell click event
   * @param event - Event object emitted by ag-Grid when a cell is clicked
   */
  onCellClicked(event: any) {
    // Emit the event to the parent component
    this.cellClicked.emit(event);
  }
}
