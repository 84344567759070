
  <div class="container mt-5">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <app-sidebar-sales 
          [projectName]="projectName" 
          [userName]="userName" 
          [companyName]="companyName"
          [projectId]="projectId">
        </app-sidebar-sales>
            </div>
        <div class="d-flex col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
          <div id="cognito-form-container"></div>
          <div class="fetch-project-deatils"><button class="btn common-btn-light clickable" (click)="addProjectDetails()">Fetch User Details</button></div>
        </div>
    </div>
  </div>

  