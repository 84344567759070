<div class="common-grid-block">
    <div class="mt-4">
      <ag-grid-angular class="ag-theme-custom"
        [gridOptions]="gridOptions"
        [columnDefs]="colDefs"
        [rowData]="rowData"
        [editType]="'fullRow'"
        [suppressClickEdit]="true"
        [animateRows]="true"	
        [enableBrowserTooltips]="true"					 
        (gridReady)="onGridReady($event)"
        (paginationChanged)="updatePagination()"
        (cellClicked)="onCellClicked($event)">
      </ag-grid-angular>
    </div>
  </div>
  <div class="common-pagination-section" *ngIf="rowData?.length">
    <div class="common-pagination-controls">
      <div class="common-pagination-block">
        <label for="page-size" class="per-page">{{agGridConstants.items}}</label>
        <div class="btn-group custom-dropdown">
        <select id="page-size" class="custom-select" (change)="onPageSizeChanged($event)">
          <option *ngFor="let size of pageSizeOptions" [value]="size" class="dropdown-option">{{size}}</option>
        </select>
      </div>
      </div>
      <div class="common-pagination-navigation-block">
        <div class="pagination-no align-self-center">{{startRow}} - {{endRow}} of {{totalRows}}
          <span class="paginator-arrow-icons align-self-center">
            <a class="arrow-1" (click)="onBtPrevious()"><img src="../../assets/images/arrow-left.svg" alt="icon" /></a>
            <a class="arrow-2" (click)="onBtNext()"><img src="../../assets/images/arrow-right.svg" alt="icon" /></a>
          </span>
        </div>
      </div> 
    </div>
  </div>
