<ngb-alert *ngIf="alertMessage" [dismissible]="true" (closed)="alertMessage = null">
  <div class="row">
    <div class="col-9">
      <div class="success d-flex flex-column w-100">
        <div class="success-header text-start">
          <strong>Thank you for your submission.</strong>
        </div>
        <div class="success-body text-start">
          We will look over your credit application and get back to you within 5-7 business days.
        </div>
      </div>
    </div>
  </div>
</ngb-alert>
<div class="overview-wrapper">
  <div class="container overview-user-header-wrapper">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 ">
        <div class="user-greet-action-block">
          <div class="user-greeting-block">
            <h4 class="greeting-text">Hi, <span class="greet-user-name">{{first_name}}</span></h4>
          </div>
          <div class="user-actions-block">
            <div class="d-flex ">
              <button class="btn btn-dark custom-button" *ngIf="permissionsData['accessOverview']">
                <img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
                <span> {{dashboard.request}}</span>
              </button>
              <button *ngIf="permissionsData['addCustomerUser']" class="btn btn-dark custom-button" (click)="addUser()">
                <img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
                <span> {{dashboard.user}}</span>
              </button>
              <button *ngIf="permissionsData['startCredit']" class="btn btn-dark custom-button"
                (click)="getUserDetailsById(true)">
                <img class="common-btn-primary-icon" src="../../assets/images/add.svg" alt="add" />
                <span>{{dashboard.credit}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 ">
        <app-weather-forecast></app-weather-forecast>
      </div>
    </div>
  </div>
</div>
<div class="container overview-user-requests-wrapper">
  <div class="row">
    <!-- Left section with 3 TABLES i.e.,ACTIVE PROJECTS, REQUEST, CREDIT-APPLICATION  -->
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-0 left-column-top ">
      <div *ngIf="projectsList && projectsList?.length == 0"
        class="common-panel-block panel panel-default panel-no-project-block d-flex align-items-center">
        <p class="text-center p-1">
          {{dashboard.inActive}}
          <a href="mailto:OpsClientServices@wbd.com">{{dashboard.administrator}}</a> {{dashboard.assistance}}
        </p>
      </div>
      <div *ngIf="projectsList && projectsList?.length > 0 && permissionsData['activeProject']">
        <app-active-projects [resources]="groupedSpacesAndResources" *ngIf="[projectsList]?.length > 0"
          [projectList]="projectsList"></app-active-projects>
      </div>
      <app-requests></app-requests>
      <app-credit-applications *ngIf="permissionsData['creditappWidget'] && credit"
        [response]="credit"></app-credit-applications>
    </div>
    <!-- Widget Section-passes and on-the-lot -->
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-0  right-column-top">
      <app-pass-expected-today></app-pass-expected-today>
      <app-on-the-lot *ngIf="response" [response]="response" (lotData)="updateOnTheLot($event)"></app-on-the-lot>
    </div>
    <!--<div *ngIf="projectsList && projectsList?.length == 0" class="common-panel-block panel panel-default panel-no-project-block">
      <p class="text-center">
        {{dashboard.inActive}}
        <a href="mailto:OpsClientServices@wbd.com">{{dashboard.administrator}}</a> {{dashboard.assistance}}
      </p>
    </div>-->
  </div>
</div>

<ng-template #content1 let-modal>
  <div class="page-wrapper-modal">
    <div class="page-header">
      <h2 class="mb-0">{{dashboard.creditApplication}}</h2>
    </div>
    <p class="project-desc mb-0">
      {{dashboard.projectSelect}}
    </p>
    <div class="common-checkbox-img-list">
      <ul class="list-group">
        <li *ngFor="let project of projectData" class="list-group-item">
          <div class="d-flex flex-column flex-fill">
            <div class="common-checkbox-button">
              <input type="radio" id="custom-radio-{{project.projectId}}" name="projectSelection"
                class="common-checkbox-input" [(ngModel)]="selectedProjectId" [value]="project.projectId"
                (ngModelChange)="onProjectSelect(project)" />
              <label for="custom-radio-{{project.projectId}}"
                class="common-checkbox-label d-flex align-items-center gap-2">
                <img [src]="project.imageUrl" alt=""
                  onerror="this.src='../../../../../assets/images/projects-small-img.svg'">
                <span class="season-name">{{ project.projectName }}</span>
              </label>
            </div>
            <div *ngIf="selectedProjectId === project.projectId">
              <p class="credit-select mt-3">{{dashboard.existingRecord}}:</p>
              <div class="common-checkbox-img-list">
                <ul class="list-group">
                  <li *ngFor="let option of filteredCreditApplications" class="list-group-item">
                    <div class="d-flex flex-column flex-fill">
                      <div class="common-checkbox-button">
                        <input type="radio" id="custom-radio-{{option.caSeqId}}" name="optionSelection"
                          class="common-checkbox-input" [(ngModel)]="selectedOptionId" [value]="option.caSeqId"
                          (ngModelChange)="onCreditSelect(option)" />
                        <label for="custom-radio-{{option.caSeqId}}"
                          class="common-checkbox-label d-flex align-items-center gap-2">
                          <span class="season-name">{{ getDepartmentNames(option.departments) }}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <div class="common-checkbox-button px-3 py-2" *ngIf="isCreditApplicationCount">
                    <input type="radio" id="New Application" [value]="zero" class="common-checkbox-input"
                      [(ngModel)]="selectedOptionId" />
                    <label for="New Application" class="common-checkbox-label d-flex align-items-center gap-2">
                      <span class="season-name">{{dashboard.newApplication}}</span>
                    </label>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>



  </div>
  <div class="end-wrapper">
    <button type="button" class="common-btn-light btn" (click)="modal.close()">{{dashboard.cancel}}</button>
    <button type="button" class="common-btn-secondary btn"
      (click)="submitSelectedProject()">{{dashboard.continue}}</button>
  </div>
</ng-template>

<ng-template #acknowledgemodal let-modal>
  <div class="modal-body p-0">
    <div class="page-wrapper-modal">
      <div class="page-header mb-4">
        <h2>{{ acknowledgeConstants.acknowledgeLabel }}</h2>
      </div>
      <div>
        <label class="mb-3">
          <ul>
            <li> {{ acknowledgeConstants.acknowledgeConditionOne }} </li>
            <li> {{ acknowledgeConstants.acknowledgeConditionTwo }} </li>
          </ul>
        </label>
      </div>

      <div class="button">
        <button type="button" class="common-btn-light btn" (click)="modal.close()">{{
          acknowledgeConstants.cancelButton }}</button>
        <button type="button" class="common-btn-secondary btn" (click)="aacknowledgeConditions(modal)">{{
          acknowledgeConstants.acknowledgeConditionsBtn }}</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #successTpl>
  <div class="success">{{addUserSuccess}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{addUserError}}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>