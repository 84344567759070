import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { IDropdownSettings } from 'src/app/shared/models/multiselect.model';
import {
  COMPANY_USERS, DropdownSettings, COMPANY_USERS_FILTER_TYPES, COMPANY_FILTERS, COMPANY_USERS_REQUEST,
  GETSTATUS_CLASS, MANAGE_USERS_CONSTANTS,
  PROJECT_USERS_LIST_CONSTANTS
} from '../../../constants/customer-portal.constant';
import {
  INDEX, PAGES
} from '../../../enum/customer-portal.enum';
import { companyStatusData, companyUserTypeahead, companyJobTitleTypeahead, companyUserTypeData } from '../../../../../../../mockData/development/company-filters-data';
import { NgbDate, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddCompanyUserComponent } from '../../../../../shared/components/add-company-user/add-company-user.component';
import { IconsComponent } from '../../../../../shared/components/ag-grid-icons/icons/icons.component';
import * as _ from 'lodash';
import { PopUpCellRendererComponent } from 'src/app/shared/pop-up-cell-renderer/pop-up-cell-renderer.component';
import { DatePipe } from '@angular/common';
import { CompanyService } from '../../../services/company/company.service';
import { ToastService } from '../../../../../shared/components/toast.service';
import { IconsService } from '../../../../../shared/services/icons/icons.service';
import { LoaderService } from '../../../../../shared/services/loader/loader.service';
import { HeaderService } from '../../../../../core/services/header/header.service';
import { UserProfileResponse } from '../../../../../shared/models/profileModel';
import { Subject, take, takeUntil } from 'rxjs';



@Component({
  selector: 'app-company-user',
  templateUrl: './company-user.component.html',
  styleUrls: ['./company-user.component.scss'],
  providers: [DatePipe]
})
export class CompanyUserComponent {
  constructor(private modalService: NgbModal, private companyService: CompanyService,
    private toastService: ToastService,
    private iconService: IconsService,
    private loaderService: LoaderService,
    public datepipe: DatePipe,
    private headerService: HeaderService
  ) { }

  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  company_users = COMPANY_USERS;
  filterTypes = COMPANY_USERS_FILTER_TYPES;
  requestType = COMPANY_USERS_REQUEST;
  getClass = GETSTATUS_CLASS;
  dropdown = DropdownSettings;
  manageUserConstants = MANAGE_USERS_CONSTANTS;
  userLabel = PROJECT_USERS_LIST_CONSTANTS;
  index = INDEX
  modalReference: NgbModalRef;
  companyId: any;
  addUserSuccess: any;
  addUserError: any;
  userProfileId: any;
  iconValue: any;
  dateFormatId;
  userProfileSettings;
  selectedFromDate: any = null;
  selectedToDate: any = null;
  // Default settings for the dropdown components
  defaultSettings: IDropdownSettings = {
    idField: this.dropdown.id,
    textField: this.dropdown.textField,
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: this.dropdown.limitSelection,
    clearSearchFilter: true,
    maxHeight: this.dropdown.maxHeight,
    itemsShowLimit: this.dropdown.itemsShowLimit,
    searchPlaceholderText: this.dropdown.searchPlaceholderText,
    noDataAvailablePlaceholderText: this.dropdown.noData,
    noFilteredDataAvailablePlaceholderText: this.dropdown.noFilteredData,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false

  };

  // Data for dropdown lists projects, types, departments, status and dayes
  activeprojects;
  status = companyStatusData;
  userType = companyUserTypeData;
  companyConstants = COMPANY_FILTERS;
  user = companyUserTypeahead;
  jobtitle = companyJobTitleTypeahead;
  userTypeahead: any = 'typeahead';
  isFiltersSelected: boolean = false;
  // Arrays to hold the selected filters for projects, types, departments, and statuses
  filteredProjects: any[] = [];
  filteredUserType: any[] = [];
  filteredStatus: any[] = [];
  filteredUser: any[] = [];
  filteredJobtitle: any[] = [];
  filteredData: any[] = [];
  filteredRowData: any[] = [];

  rowData: any[] = [];
  companyUserData: any;
  companyUserMetaData: any;
  userFilterData: any[] = [];
  jobTitleFilterData: any[] = [];
  activeProjectFilterData: any[] = [];
  colDefs: ColDef[];
  private unsubscribe$ = new Subject<void>();

  /**
*getColumnDef() - getColumnDef method returns the grid data input.
*/

  getColumnDef(): void {
    this.colDefs = [
      {
        headerName: 'User',
        field: 'user',
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams) => {
          const fullName = params.data.firstName + ' ' + params.data.lastName;
          const email = params.data.emailAddress;
          let companyAdminInd = params.data.companyAdminInd;
          let requestTypeClass = '';
          if (companyAdminInd == 1) {
            requestTypeClass = 'company-admin';
            companyAdminInd = this.requestType.company;
          }
          return `
          <span class="common-split-cell-section">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
            <span class="common-studio-user-request-label ${requestTypeClass}">${companyAdminInd}</span>
          </span>
        `;
        },
        minWidth: 165,
        valueGetter: (params: any) => {
          return params.data.firstName;
        },
        comparator: (valueA, valueB) => {
          const lowerA = (valueA || '').toString().toLowerCase();
          const lowerB = (valueB || '').toString().toLowerCase();
          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },

      },
      {
        headerName: 'STATUS',
        field: 'status',
        cellRenderer: this.statusCellRenderer.bind(this),
        minWidth: 160,
        valueGetter: (params: any) => {
          return params.data.statusActiveInd;
        },
        comparator: (valueA, valueB) => {
          const lowerA = (valueA || '').toString().toLowerCase();
          const lowerB = (valueB || '').toString().toLowerCase();
          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },
      },
      {
        headerName: 'Last Login',
        field: 'activity',
        filter: false,
        cellRenderer: (params: ICellRendererParams) => {
          let date;
          let time;
          if (params.data.lastLoginDate !== null) {
            date = params.data.lastLoginDate;
            time = params.data.lastLoginTime;
          }
          else {
            date = 'N/A';
            time = '';
          }
          return `<span class="common-split-cell-section">
                  <span class="common-split-cell-data-default-font common-split-cell-data-1">${date}</span>
                  <span class="common-split-cell-data-2">${time}</span>
                </span>`;
        }, minWidth: 140,
        valueGetter: (params: any) => {
          return params.data.lastLoginDate;
        },
        comparator: (valueA, valueB) => {
          const lowerA = (valueA || '').toString().toLowerCase();
          const lowerB = (valueB || '').toString().toLowerCase();
          if (lowerA < lowerB) return -1;
          if (lowerA > lowerB) return 1;
          return 0;
        },
      },
      { headerName: 'JOB TITLE', field: 'jobTitle', cellClass: 'common-cell-data-bold', minWidth: 120 },
      { headerName: 'PHONE', field:'contactNumber',minWidth:100},
      { headerName: 'ACTIVE PROJECTS', field: 'projects', cellClass: 'common-cell-data-mt account-user-active-project-cell', minWidth: 150 },
      {
        headerName: 'USER',
        field: 'merged-field',
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams) => {
          const fullName = `${params.data.firstName} ${params.data.lastName}`.trim();
          const email = params.data.emailAddress;
          const status = params.data.statusActiveInd;
          const statusClass = this.getStatusClass(status);

          return `
          <span class="common-split-cell-section company-user-mb">
            <span class="common-split-cell-data-1">${fullName}</span>
            <span title="${email}" class="common-split-cell-data-2 ellipsis-email">${email}</span>
            <span class="common-split-cell-data-4">
              <span class="request-status-tag ${statusClass} mt-common-ag-grid-contant">${status}</span>
            </span>
          </span>
        `;
        },
        minWidth: 150,
      },
      {
        field: 'icons',
        headerName: '',
        cellRenderer: IconsComponent,
        cellClass:'company-users-icons',
        filter: false,
        minWidth: 125,
        maxWidth:140
      },
      {
        headerName: 'Action (Angular)',
        colId: 'action',
        cellRenderer: PopUpCellRendererComponent,
        editable: false,
        maxWidth: 150,
      },


    ];
  }

  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.data.statusActiveInd);
    return `
      <div class="status-container">
        <div class="request-status-tag ${statusClass}">${params.data.statusActiveInd}</div>
      </div>
    `;
  }

  getStatusClass(status: string): string {
    switch (status) {
      case (this.getClass.active).toLowerCase():
        return 'user-status-tag active account-user-common-status-cell mt-common-ag-grid';
      case (this.getClass.deactivated).toLowerCase():
        return 'user-status-tag deactivated account-user-common-status-cell mt-common-ag-grid';
      default:
        return '';
    }
  } 

  colsForSmallScreen = [ 'icons','merged-field',];
  colsForMediumScreen = [ 'icons','merged-field',];
  colsForLargeScreen = ['user', 'status', 'activity', 'jobTitle', 'icons']
  colsForExtraLargeScreen = ['user', 'status', 'activity', 'jobTitle','contactNumber', 'projects', 'icons'];

  /**
  * selectedProject() - Method to handle selection of projects from the dropdown
  * @param onSelect - The selected items from the dropdown
  */
  selectedProject(onSelect: any): void  {
    this.filteredProjects = onSelect;
    this.applyFilters();
  }

  /**
  * selectedUser() - Method to handle selection of users from the dropdown
  * @param onSelect - The selected items from the dropdown
  */

  selectedUser(onSelect: any): void {
    this.filteredUser = onSelect;
    this.applyFilters();
  }

  /**
  * selectedProject() - Method to handle selection of jobtitle from the dropdown
  * @param onSelect - The selected items from the dropdown
  */
 
  selectedJobtitle(onSelect: any) : void {
    this.filteredJobtitle= onSelect;
    this.applyFilters();
  }

  /**
*addUser() - invoke the add user modal popup
*/

  addUser(): void {
    window.scrollTo(0,0);
    let title = this.company_users?.adduser;
    let metaData = this.companyUserMetaData;
    let portalType = this.company_users?.portaltype;
    let modalType = this.company_users?.modalAdd;
    let componentType = this.company_users?.componenttype;
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true
    });
    this.modalReference.componentInstance.title = title;
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.modalType = modalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data.status == this.company_users?.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
          this.getCompanyUser();
        }
      }
    });
    this.modalReference.componentInstance.emitError.subscribe((data) => {
      if (data) {
        this.addUserError = data?.message;
        this.showDanger(this.dangerTpl);
      }
    });
  }


  /**
  * showSuccess() - Displays a success toast notification with custom content and options.
  * @param successTpl - The template reference containing the toast's content.
  */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }
/**
* showDanger() - Displays a danger (error) toast message.
* This method is called when there is an error in adding the user.
* @param dangerTpl - The template reference for the danger message to display.
*/
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * selectedUserType() - Method to update the selected user type from the dropdown
   * @param selectedItems - The selected user type
  */
    selectedUserType(selectedItems: any) {
      this.filteredUserType = selectedItems;
      this.applyFilters();
    }
  
  /**
  * selectedStatus() - Method to handle selection of status from the dropdown
  * @param selectedItems - The selected items from the dropdown
  */
  selectedStatus(selectedItems: any): void {
    this.filteredStatus = selectedItems;
    this.applyFilters();
  }

  /**
  * hasSelectedFilters() - Method to check if any filters are selected.
  * @returns boolean - True if any filters are selected, otherwise false.
  */
  hasSelectedFilters(): boolean {
    this.isFiltersSelected = this.filteredProjects?.length > 0 ||
      this.filteredUserType?.length > 0 ||
      this.filteredStatus?.length > 0 || this.filteredUser?.length > 0
      || this.filteredJobtitle?.length > 0 ||
      this.selectedFromDate !== null ||
      this.selectedToDate !== null;
    return this.isFiltersSelected;
  }

  /**
  * clearFilter() - Method to clear a specific filter
  * @param filterType - The type of filter to clear (project, type, department, status)
  */
  clearFilter(): void {

    this.filteredProjects = []; // Clear the project filter
    this.selectedProject(this.filteredProjects);
    this.activeprojects = this.filteredProjects;

    this.filteredUserType = [];// Clear User Type
    this.selectedUserType(this.filteredUserType);
    this.userType = this.filteredUserType;
    
    this.filteredStatus = []; // Clear the status filter
    this.selectedStatus(this.filteredStatus);
    this.status = this.filteredStatus;

    this.filteredUser = []; // Clear the user filter
    this.selectedUser(this.filteredUser);
    this.user = this.filteredUser;

    this.filteredJobtitle= []; // Clear the jobtitle filter
    this.selectedJobtitle(this.filteredJobtitle);
    this.jobtitle = this.filteredJobtitle;

    this.selectedFromDate = null;
    this.selectedToDate = null;

    this.applyFilters();
  }

  /**
   * filteredDataList() - Method to filter out the selected item from the array
   * @param arr - The array from which the item needs to be removed
   * @param item - The item to be removed
   * @returns The filtered array
   */
  filteredDataList(arr: any[], item: any): any {
    return _.filter(arr, i => i.id !== item.id);
  }

  /**
   * applyFilters() - Method to apply selected filters to the project data and update the paginated data
   */
  applyFilters(): void {
    let filteredData = this.rowData;

    if (this.filteredProjects?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredProjects, x => _.includes(project.projects.toLowerCase(), x.text.toLowerCase()))
      );
    }
    
    if (this.filteredUserType?.length > 0) {
      const selectedUserType = this.filteredUserType.map(status => status.text);
      const isAllUsers = _.filter(selectedUserType, userType => userType === this.manageUserConstants.allCustomers)
      if(isAllUsers.length>0){
        filteredData = this.rowData;
      }
      else{
       filteredData = filteredData.filter((row) =>
        selectedUserType.includes(row?.accountType));
      }
    }
    
    if (this.filteredStatus?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredStatus, x => project.statusActiveInd == x.text.toLowerCase())
      );
    }


    if (this.selectedFromDate && this.selectedToDate) {
      const selectedFromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      const selectedToDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);

      // Filter rows based on the last login date
      filteredData = _.filter(filteredData, row => {
        // dateFormatId = 1 represents (mm/dd/yyyy) format and dateFormatId = 2 represents(dd/mm/yyyy) format
        if (this.dateFormatId === 1) {
          const lastLoginDate = new Date(row?.lastLoginDate);
          return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
        }
        else {
          const lastLoginDate = this.parseDate(row?.lastLoginDate);
          return lastLoginDate >= selectedFromDate && lastLoginDate <= selectedToDate;
        }
      });
    }
    if (this.filteredUser?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredUser, x => project.firstName == x.firstname)
      );
    }

    if (this.filteredJobtitle?.length) {
      filteredData = _.filter(filteredData, project =>
        _.some(this.filteredJobtitle, x => project.jobTitle.toLowerCase() == x.jobtitle.toLowerCase())
      );
    }
   
    this.hasSelectedFilters();
    this.filteredRowData = filteredData;
  }

  /**
   * removeSelectedItem() - Method to remove a specific item from a selected filter
   * @param filterType - The type of filter the item belongs to (project, type, department, status)
   * @param item - The item to be removed
   */

  removeSelectedItem(filterType: string, item: any): void {
    switch (filterType) {
      case this.filterTypes.activeprojects: {
        let projectArray = [];
        this.filteredProjects = this.filteredDataList(this.filteredProjects, item);
        projectArray.push(item);
        this.activeprojects = projectArray;
      }
        break;
      case this.filterTypes.userType: {
          let userTypeArray = [];
          this.filteredUserType = this.filteredDataList(
          this.filteredUserType,
          item
         );
         userTypeArray.push(item);
         this.userType = userTypeArray;
      }
        break;
      case this.filterTypes.status: {
        let statusArray = [];
        this.filteredStatus = this.filteredDataList(this.filteredStatus, item);
        statusArray.push(item);
        this.status = statusArray;
      }
        break;
      case this.filterTypes.user: {
        let userArray = [];
        this.filteredUser = this.filteredDataList(this.filteredUser, item);
        userArray.push(item);
        this.user = userArray;
      }
        break;
      case this.filterTypes.jobtitle: {
        let jobtitleArray = [];
        this.filteredJobtitle = this.filteredDataList(this.filteredJobtitle, item);
        jobtitleArray.push(item);
        this.jobtitle = jobtitleArray;
      }
        break;
    }
    this.applyFilters();
  }

  /**
  * onDateRangeSelected() - Method triggered when a date range is selected.
  * @param dateRange - The object containing the selected date range, specifically the fromDate.
*/
  onDateRangeSelected(dateRange: { fromDate: NgbDate | null; toDate: NgbDate | null }) {
    if (dateRange.fromDate && !dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.fromDate;  // Treat single date as both 'from' and 'to'
    }
    else if (dateRange.fromDate && dateRange.toDate) {
      this.selectedFromDate = dateRange.fromDate;
      this.selectedToDate = dateRange.toDate;
    }
    this.applyFilters();
  }


  /**
   * formatDateRange() - method to format the date
   * @param fromDate - The start date.
   * @param toDate - The end date.
   * @returns A string representing the formatted date range.
 */
  formatDateRange(fromDate: NgbDate, toDate: NgbDate): string {
    const formatToTwoDigits = (num: number) => num < PAGES.ten ? `${PAGES.zero}${num}` : `${num}`;
    // dateFormatId = 1 represents (mm/dd/yyyy) format and dateFormatId = 2 represents(dd/mm/yyyy) format
    const formattedFromDate = this.dateFormatId === 1 ? `${formatToTwoDigits(fromDate.month)}/${formatToTwoDigits(fromDate.day)}/${fromDate.year.toString()}` : `${formatToTwoDigits(fromDate.day)}/${formatToTwoDigits(fromDate.month)}/${fromDate.year.toString()}`;
    const formattedToDate = this.dateFormatId === 1 ? `${formatToTwoDigits(toDate.month)}/${formatToTwoDigits(toDate.day)}/${toDate.year.toString()}` : `${formatToTwoDigits(toDate.day)}/${formatToTwoDigits(toDate.month)}/${toDate.year.toString()}`;
    // If both dates are the same, return just the single date
    if (fromDate.equals(toDate)) {
      return `${formattedFromDate}`;
    }
    else {
      return `${formattedFromDate} - ${formattedToDate}`;
    }
  }

  /**
* parseDate() - method to convert the date into dd/mm/yyyy format
* @param dateString 
* @returns 
*/
  parseDate(dateString: string | null): Date | NgbDate | null {
    if (!dateString) {
      return null; // Return null if the dateString is null or empty
    }
    else {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    }
  }

  /**
   * clearDateRange() - method to clear the selected date range.
 */
  clearDateRange(): void{
    this.selectedFromDate = null;
    this.selectedToDate = null;
    this.applyFilters();
  }

/**
*onCellClicked() - onCellClicked method invoke the grid row to edit the user information.
* @param params - The cell parameters containing the value for edit.
*/

  onCellClicked(evt): void{
    if (evt.column.colId !== 'icons') {
      const userId = evt.data.userProfileId;
      this.getUserDetailsById(userId);
    }
  }

  /**
  * getUserDetailsById() - Fetches user details by user ID and opens the edit user modal.
  * @param userId - The ID of the user whose details are to be fetched.
 */
  getUserDetailsById(userId: number): void {
    this.loaderService.setLoadingState(true);
    this.companyService.getUserSearch(userId, false).subscribe({
      next: (data: any) => {
        this.loaderService.setLoadingState(false);
        this.openEditUserModal(data?.companyUser);
      },
      error: (error: any) => {
        this.addUserError = error;
        this.showDanger(this.dangerTpl);
        console.error(error);
        this.loaderService.setLoadingState(false);
      }
    });
  }

/**
* openEditUserModal() - get user infomation based on userprofile id to edit the user.
* @param userDetails - user details object should pass as input to edit the selected user info.
*/

  openEditUserModal(userDetails: any) {
    window.scrollTo(0, 0);
    let metaData = this.companyUserMetaData;
    let portalType = this.company_users?.portaltype;
    let componentType = this.company_users?.componenttype;
    this.modalReference = this.modalService.open(AddCompanyUserComponent, {
      windowClass: 'common-modal-xl ',
      fullscreen: true
    });
    this.modalReference.componentInstance.metaData = metaData;
    this.modalReference.componentInstance.modalType = this.company_users?.modalEdit;
    this.modalReference.componentInstance.title = this.company_users?.edituser;
    this.modalReference.componentInstance.isEditMode = true;
    this.modalReference.componentInstance.userDetails = userDetails;
    this.modalReference.componentInstance.userType = this.company_users?.userType;
    this.modalReference.componentInstance.portalType = portalType;
    this.modalReference.componentInstance.componentType = componentType;
    this.modalReference.result.then((data) => {
      if (data) {
        if (data?.status == this.company_users?.success) {
          this.addUserSuccess = data?.message;
          this.showSuccess(this.successTpl);
          this.getCompanyUser();
        }
      }
    });
    this.modalReference.componentInstance.emitError.subscribe((data) => {
      if (data) {
        this.addUserError = data?.message;
        this.showDanger(this.dangerTpl);
      }
    });
  }



  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.companyId = userProfile?.companyId;
    this.userProfileId = userProfile?.userProfileId;
    this.getCompanyUser();
    this.getCompanyUserMetaData();
    this.getColumnDef();
    this.iconService.currentValue.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.iconValue = value; // Update the local value when it changes
      if (!_.isNil(this.iconValue)) {
        this.onValueChange(value); // Trigger a method
      }
    });
    this.getDateFormatId();
  }

  /**
 * getDateFormat() - method to get the date fomrat from user profile
 */
  getDateFormatId(): void {
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    this.headerService
      .getuserprofile(userProfileId.userProfileId)
      .subscribe({
        next: (response: UserProfileResponse | null) => {
          if (response) {
            this.userProfileSettings = response?.userProfileSearch;
            this.dateFormatId = this.userProfileSettings?.userPreferences?.dateFormatSettings?.dateFormatId;
          }
        }
      });
  }

/**
*getCompanyUser() - getCompanyUser method gives the user list that tagged to the compnay whicu user logged in.
*/

  getCompanyUser(): void{
    this.loaderService.setLoadingState(true);
    this.companyService.getCompanyUser(this.companyId).subscribe({
      next: (data: any) => {
        this.companyUserData = data?.companyUsers;
        this.companyUserData.forEach((element) => {
          element.statusActiveInd == 'Y' ? element.statusActiveInd = 'active' : element.statusActiveInd = 'deactivated';
          if (element.companyAdminInd == 1) {
            element.accountType = this.userLabel?.companyAdmin;
          } else if (element.accountPayableUserInd == 1) {
            element.accountType = this.userLabel?.accountPayableUser;
          }
        }
        );
        this.rowData = this.companyUserData;
        this.modifyDataset(this.companyUserData);
        this.applyFilters();
        this.loaderService.setLoadingState(false);
      },
      error: (error: any) => {
        this.addUserError = error;
        this.showDanger(this.dangerTpl);
        console.error(error);
        this.loaderService.setLoadingState(false);
      }
    })
  }

/**
*getCompanyUserMetaData() - getCompanyUserMetaData method gives list of project, department and other information that helps
 * to add the user in company.
*/

  getCompanyUserMetaData(): void {
    this.companyService.getCompanyUserMetaData(this.companyId).subscribe({
      next: (data: any) => {
        this.companyUserMetaData = data.companyUserMetaData;
      },
      error: (error: any) => {
        console.error(error);
        this.addUserError = error;
        this.showDanger(this.dangerTpl);
      }
    })
  }

/**
*modifyDataset() - modifyDataset method transform the data for the filters.
* @param metaData - getting metadata from the API based on screen specific.
*/

  modifyDataset(metaData): void {
    // returns the data for user typeahead value
    this.userFilterData = metaData.filter(x => x.emailAddress !== null)
      .map(({ userProfileId, emailAddress, firstName, lastName }) => ({
        id: userProfileId,
        email : emailAddress,
        firstname: firstName,
        lastname: lastName,
        fullName: `${firstName} ${lastName}`
      }));
    const uniqueJobTitles = Array.from(
      new Map(
        metaData
          .filter(x => x.jobTitle !== null)
          .map(({ userProfileId, jobTitle }) => [jobTitle.toLowerCase(), { id: userProfileId, jobtitle: jobTitle }])
      ).values()
    );
    const transformedData = metaData.flatMap(({ userProfileId, projects }) => {
      if (projects.includes(',')) {
        return projects.split(',').map(project => ({
          userProfileId, 
          project: project.trim() 
        }));
      }
      return [{ userProfileId, project: projects.trim() }];
    });

    const uniqueActiveprojects = Array.from(
      new Map(
        transformedData
          .filter(x => x.project !== null && x.project !== '')
          .map(({ userProfileId, project }) => [project, { id: userProfileId, project: project }])
      ).values()
    );
    // returns the data for jobtitle typeahead value
    this.jobTitleFilterData = uniqueJobTitles.map((item, index) => (
      {
        id: index,
        jobtitle: item['jobtitle']
      }
    ));
    // returns the data for active project value
    let sorteduniqueJobTitles = _.sortBy(uniqueActiveprojects, ({ project }) => project.toLowerCase())
    this.activeprojects = sorteduniqueJobTitles.map((item, index) => (
      {
        id: index,
        text: item['project']
      }
    ));

  }


  /**
   * onValueChange(val: any) - Method to handle changes in value.
   * This method processes the incoming value, determines the associated icon action,
   * and triggers the appropriate user action based on the parsed value.
   * 
   * @param val - The value representing the action to be taken, expected to be a JSON string.
   */
  onValueChange(val: any) : any {
    let singlerowData = val;
    switch (singlerowData?.iconInfo) {
      case 'deactivate-account':
        this.changeAccountStatus(singlerowData.rowData, 'N');
        break;
      case 'activate-account':
        this.changeAccountStatus(singlerowData.rowData, 'Y');
        break;
      default:
        break;
    }
  }

/**
* changeAccountStatus() - Method to handle user status update using grid icon
* @param singlerowData - The selected items from the on row
* @param updateValue - status value to update the user status.
*/

  changeAccountStatus(singlerowData, updateValue) {
    this.loaderService.setLoadingState(true);
    this.companyService.updatedUserStatus(singlerowData.userProfileId, updateValue, singlerowData.userProfileId).pipe(take(1)).subscribe({
      next: (data: any) => {
        updateValue == 'Y' ?
          this.addUserSuccess = "User activated successfully" :
          this.addUserSuccess = "User deactivated successfully";
        this.showSuccess(this.successTpl);
        this.loaderService.setLoadingState(false);
        this.iconValue = null;
        this.iconService.changeValue(null);
        this.getCompanyUser();
        this.clearFilter();
      },
      error: (error: any) => {
        this.loaderService.setLoadingState(false);
        this.addUserError = error;
        this.iconValue = null;
        this.iconService.changeValue(null);
        this.showDanger(this.dangerTpl);
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete(); // Complete the subject to avoid memory leaks
  }
}
