<div class="container cm-main-container px-0 mx-0">
  <div class="common-modal-input-section grey-bg review-panel">
    <div class="row align-items-center">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-text">
        <h4 class="section-main-header cust-risk-pend-sec-header">{{ constants.titles.status }}</h4>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-button">
        <div class="status-container">
          <div [ngClass]="getStatusClass(rowData?.status)" class="ar-status-tag">
            {{ rowData.status }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4 align-items-center">
      <div class="col-md-12">
        <p class="mb-1">
          <strong class="title-content">{{ constants.labels.creditLimit }}: </strong> {{ rowData?.creditLimit}}
        </p>
        <p class="mb-1">
          <strong class="title-content">{{ constants.labels.sentRiskEmail }}: &nbsp; </strong>
          <span class="pend-risk-details"> 10/19/2024</span>
          <a class="mx-2 text-decoration-none">
            <span class="text-link" (click)="openRiskEmailModal()">{{ constants.buttons.view }}</span>
          </a>
          <span class="">
            <strong class="title-content">{{ constants.labels.sentBy }}: &nbsp; </strong>
            <span class="mb-0 title-content"> {{rowData.sentBy}}</span>
          </span>
        </p>
        <p class="">
          <strong class="title-content">{{ constants.labels.reasons }}: &nbsp;</strong>
          <span class="title-content">No Bank Reference</span>
        </p>
        <div class="mb-5">
          <button class="btn common-btn-secondary me-3" (click)="openRiskEmailTier1Modal()">
            {{ constants.buttons.reviewRiskEmail }}
          </button>

          <strong class="title-content">(Submit your approval before 1/1/24 3:25pm)</strong>

        </div>
      </div>
    </div>
    <hr>
    <p><strong class="title-content">{{ constants.titles.departments }} </strong></p>
    <p><span class="pend-risk-details">{{rowData?.department}}</span></p>
  </div>

  <!-- comment-list -->
  <div class="grey-box mb-0">
    <h2 class="section-main-header text-left common-heading">
      {{ constants.titles.comments }}({{ comments.length }})
    </h2>
    <div class="comment-section">
      <div *ngFor="let comment of comments" class="row comment-list">
        <div class="commnet-icon-section col-md-1">
          <span class="user-icon-text">{{(comment.modifiedByName)}}</span>
        </div>
        <div class="common-comment-section col-11">
          <p class="font-14 mb-0">
            <strong class="common-content-bold">{{ comment.modifiedByName}}</strong>
          </p>
          <p class="common-small-content">{{ comment.modifiedDatetime | date: 'MM/dd/yyyy h:mm a' }}</p>
          <p class="common-normal-content">
            {{ comment.commentText }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="grey-box mt-1">
    <div class="row text-area-block">
      <div class="col-12">
        <textarea class="form-control form-control-lg common-text-area" placeholder="{{ constants.labels.typeComment }}"
          [(ngModel)]="commentText"></textarea>
      </div>
    </div>
  </div>
  <div class="cm-btn-section">
    <button type="button" class="btn common-btn-secondary me-2 mb-2" (click)="addComments()">
      {{ constants.buttons.addComment }}
    </button>
  </div>
</div>
<ng-template #successTpl>
  <div class="success">{{success_msg}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{error_msg}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>