import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridOptions,
  ICellRendererParams,
} from 'ag-grid-community';
import { CREDIT_APPLICATION } from '../../../constants/customer-portal.constant';
import { Route, Router } from '@angular/router';
 
@Component({
  selector: 'app-credit-applications',
  templateUrl: './credit-applications.component.html',
  styleUrls: ['./credit-applications.component.scss'],
})
export class CreditApplicationsComponent implements OnInit {
  @ViewChild('creditGrid') creditGrid!: AgGridAngular;
  private _response: any;
  userProfile: any;
  permissionsData: any;
  @Input()
  set response(value: any) {
    this._response = value;
    if (this._response) {
      this.getCreditApplications();
    }
  }
 
  gridApi!: GridApi;
  gridOptions: GridOptions = {};
  rowDataCredit: any;
  colDefsCredit: ColDef[] = [];
  creditApplication = CREDIT_APPLICATION;
 
  constructor(private router: Router) {
    this.statusCellRenderer = this.statusCellRenderer.bind(this);
  }
 
  ngOnInit() {
    this.userProfile = JSON.parse(localStorage.getItem('user-profile'))
    this.permissionsData = { ...this.userProfile.roleAssigned };
    this.generateRequestAgGrid();
  }
 
  /**
   * extractDate() - extract only date
   * @param dateTime - date to format
   * @returns
   */
  extractDate(dateTime: string): string {
    return dateTime.substring(0, 10);
  }
 
  /**
   * formatDate() - method to format the date
   * @param dateString - the date to be formatted
   * @returns - returns the formatted date
   */
  formatDate(dateString: string): string {
    if (!dateString) return '';
    const parts = dateString.split('-');
    const year = parts[0].slice(-2);
    const month = parts[1];
    const day = parts[2];
    return `${year}/${month}/${day}`;
  }
 
  /**
   * to display most recent credit application
   */
  getCreditApplications(): void {
    const toTitleCase = (str: string): string =>
      str
        .split(/\s+/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
        .join(' ');
  
    const formattedData = this._response.creditApplications.creditApplications.map(
      (credit: any) => {
        // Find the department with departmentId === 1
        const department = credit?.caArdepartments?.find((dept: any) => dept?.departmentDetails?.departmentId === 1);
        let departments;
        if (department) {
          departments = 'All Departments'
        } else {
          departments = credit?.caArdepartments
            .map((dept: any) => {
              return toTitleCase(dept?.departmentDetails?.departmentName);
            })
            .join('; ');
        }
      
 
        const projectName = credit?.projectDetails?.projectName || 'N/A';
       const companyName = credit?.companyDetails?.companyName || 'N/A';
        return {
          date: credit?.submittedDate  || '---',
          arNo: credit?.arNumber,
          department: departments,
          projectName: projectName,
          companyName:companyName,
          lineofCredit: credit?.lineOfCredit?.locRequested,
          status: credit.customerStatus,
          projectId: credit?.projectDetails?.projectId,
          createdDate: credit?.createdDate
        };
      }
    );
  console.log(formattedData )
    this.rowDataCredit = formattedData
      .sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
      .slice(0, 5);
  }
 
  /**
   * generateRequestAgGrid() - configuration for Ag grid
   */
  generateRequestAgGrid() {
    this.gridOptions = {
      domLayout: 'autoHeight',
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        filter: true,
        cellStyle: { lineHeight: 'normal' },
      },
    };
 
    this.colDefsCredit = [
      {
        headerName: '',
        field: 'date',
        wrapText: true,
        autoHeight: true,
        minWidth: 118,
        cellClass: 'credit-date',
        cellStyle: { padding: '24px 12px 12px 24px',top:'32px' }
      },
      {
        headerName: '',
        field: 'arNo',
        wrapText: true,
        autoHeight: true,
        minWidth: 90,
        cellClass: 'credit-date',
        cellRenderer: (params: ICellRendererParams) => {
          const arNo = params.data.arNo || '---';
          return `  
       <div class="common-split-cell-section">
       <div class="common-split-cell-section-1 common-cell-data-bold lh-base">AR</div>
            <div class=" common-split-cell-section-1 common-cell-data-bold lh-base">${arNo}</div></div> `;
      },
        cellStyle: { padding: '24px 12px 12px 10px',top:'30px'}
      },
      {
        headerName: '',
        field: 'department',
        wrapText: true,
        autoHeight: true,
        flex: 3,
        cellRenderer: (params: ICellRendererParams) => {
          const department = params.data.department;
          const projectName = params.data.projectName;
         const companyName = params.data.companyName;
          return `
            <div class="d-flex flex-column gap-1" >
              <span
                title="${department}"
                class="credit-department-table-section common-cell-data-bold"
              >
                ${department}
              </span>
              <span class="lh-base">${projectName} - ${companyName}</span>
            </div>`;
        },
        minWidth: 180,
        cellStyle: { padding: '24px 10px 24px 0px' },
      },
     
     {
  headerName: '',
  field: 'merged-column',
  wrapText: true,
  autoHeight: true,
  minWidth: 160,
  flex: 3,
  cellRenderer: (params: ICellRendererParams) => {
    const date = params.data.date;
    const arNo = params.data.arNo || '----';
    const lineofCredit = params.data.lineofCredit;
    const department = params.data.department;
    if (lineofCredit) {
      return `<span class="common-split-cell-section credit-app-sm-screen">
        <span class="common-split-cell-data-1 m-16">${arNo}</span>
        <span class="common-split-cell-data-2 m-16">${date}</span>
        <span title="${department}" class="common-split-cell-data-2 m-16 "><span class="common-cell-data-bold ">${department}</span></span>
        <span class="common-split-cell-data-2 m-16"><span class="common-cell-data-bold">Line of Credit:</span> $${this.formatNumber(lineofCredit)}</span>
      </span>`;
    } else {
      return `<span class="common-split-cell-section credit-app-sm-screen">
        <span class="common-split-cell-data-1 m-16">${arNo}</span>
        <span class="common-split-cell-data-2 m-16">${date}</span>
        <span title="${department}" class="common-split-cell-data-2 m-16 "><span class="common-cell-data-bold ">${department}</span></span>
        <span class="common-split-cell-data-2 m-16"><span class="common-cell-data-bold">Line of Credit:</span> ----</span>
      </span>`;
    }
  },
  cellStyle: { padding: '24px 0px 24px 24px' },
},
 
      {
        headerName: '',
        field: 'lineofCredit',
        wrapText: true,
        autoHeight: true,
        minWidth: 160,
        flex: 2,
        cellRenderer: (params: ICellRendererParams) => {
          const lineofCredit = params.data.lineofCredit;
          if (lineofCredit) {
            return `<span class="credit-department-table-section">      
              <span ><span class="common-cell-data-bold">Line of Credit:</span><br/>$${this.formatNumber(lineofCredit)}</span>  
              </span>`;
          } else {
            return `<span class="credit-department-table-section">      
              <span ><span class="common-cell-data-bold">Line of Credit:</span><br/>----</span>  
              </span>`;
          }
        },
        cellStyle: { padding: '24px 0px 24px 0px' },
      },
     
      {
        headerName: '',
        field: 'status',
        cellRenderer: this.statusCellRenderer.bind(this),
        cellClass: 'account-user-common-status-cell cell-right-align',
        minWidth: 178,
        cellStyle: { padding: '24px 24px 24px 10px',textAlign: 'left' },
      },
    ];
  }
 
  /**
   * formatNumber() - to format the number to dollars
   * @param num - number to be formatted
   * @returns - a formatted number
   */
  formatNumber(num: number): string {
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  }
 
  /**
   * statusCellRenderer() - Custom cell renderer for displaying a status with a specific class.
   * @param params -  Parameters for the cell renderer, provided by the grid framework.
   * This includes the cell value and other metadata.
   * @returns - A string containing HTML markup for rendering the cell content.
   */
  statusCellRenderer(params: ICellRendererParams) {
    const statusClass = this.getStatusClass(params.value);
    return `
         <div class="status-container">
        <div class="ar-status-tag ${statusClass}">${params.value}</div>
        </div>
    `;
  }
 
  /**
   * getStatusClass() - Returns the appropriate class for each status value
   * @param status - The status string to get class for
   */
  getStatusClass(status: string): string {
    switch (status) {
      case 'REVISIONS SUBMITTED':
        return 'ar-status-tag new-application account-user-common-status-cell credit-status';
     case 'Application Submitted':
        return 'ar-status-tag new-application account-user-common-status-cell credit-status';
      case 'UNDER REVIEW':
        return 'ar-status-tag  under-review account-user-common-status-cell credit-status';
      case 'NEEDS REVISIONS':
        return 'ar-status-tag revision account-user-common-status-cell credit-status';
      case 'NEEDS APPROVAL':
        return 'ar-status-tag  assigned-color needs-approval account-user-common-status-cell credit-status';
      case 'APPROVED':
        return 'ar-status-tag  resolved-color approved account-user-common-status-cell credit-status';
      case 'DENIED':
        return 'ar-status-tag  resolved-color denied account-user-common-status-cell credit-status';
      case 'Start Application':
        return 'ar-status-tag  start-application account-user-common-status-cell  credit-application-status start-app-updated credit-status';
        case 'Finish Application':
          return 'ar-status-tag  finish-application account-user-common-status-cell finish-app credit-application-status credit-status';
      default:
        return '';
    }
  }
 
  /**
   * onGridReady() - Event handler for when the grid is ready
   * @param params - it is the object provided by AG Grid when the grid is ready
   */
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.adjustColumns(window.innerWidth);
  }
 
  /**
   * HostListener() - listen for window resize events
   * @param event - The 'event' parameter is the native resize event object
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustColumns(event.target.innerWidth);
  }
 
  /**
   * adjustColumns() - Adjusts column visibility based on the screen width
   * @param screenWidth - The current width of the screen/window
   */
 
  adjustColumns(screenWidth: number) {
    if (this.gridApi) {
      const allColumnIds: string[] = [];
      this.gridApi.getAllGridColumns().forEach((column) => {
        allColumnIds.push(column.getColId());
      });
 
      if (screenWidth <= 992) {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(['merged-column', 'status'], true);
      } else {
        this.gridApi.setColumnsVisible(allColumnIds, false);
        this.gridApi.setColumnsVisible(
          ['date', 'arNo', 'department', 'lineofCredit', 'status'],
          true
        );
      }
    }
  }
 
  /**
   * onRowClicked() - to redirect the user to credit Application based on click
   * @param event - when the row is clicked a data is emiited
   */
  onRowClicked(event: any): void {
    const selectedRowData = event.data;
    const tabName = 'credit';
    this.router.navigate(['feature/customer-dashboard/project-details/',selectedRowData.projectId], { queryParams: { tab: tabName } });
  }
}
 