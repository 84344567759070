<div class="modal-body justify-content-center">
    <div class="cm-header-section">
        <h5 class="modal-title" id="commonModalLabel">{{ constants.modalTitle }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
            <span class="cm-close-text">{{ constants.closelabel }}</span></button>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-3 px-0">
                <div class="modal-left-section me-4">
                    <h4 class="common-left-section-title mt-4">{{ constants.modalTitle }}</h4>
                    <div class="mb-4 mt-3">
                        <div class="list-group list-group-flush mb-2">
                            <ul class="step-list">
                                <li>
                                    <span class="step-text" [ngClass]="{'current-step': selectedTab === 'status'}"
                                        (click)="switchTab('status')">
                                        {{ constants.statusLabel }}: {{ rowData?.status }}
                                    </span>
                                </li>
                                <li>
                                    <span class="step-text" [class.current-step]="selectedTab === 'form'"
                                        (click)="switchTab('form')">
                                        {{ constants.formLabel }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <span class="mb-1">
                            <img src="../assets/images/pdf-blue-icon.svg" alt="" class="me-2">
                            <span class="text-link">{{ constants.downloadPacketText }}</span>
                        </span>
                    </div>
                    <hr>
                    <h4 class="common-left-section-title">{{ constants.companyLabel }} Info</h4>
                    <div class="company-meta-data">
                        <p class="title mb-0">
                            <strong class="common-title">{{ constants.projectLabel }}:</strong>
                            <span class="common-content">{{ rowData.projectName }}</span>
                        </p>
                        <p class="title mb-0 pb-2">
                            <strong class="common-title">{{ constants.companyLabel }}:</strong>
                        </p>
                        <p class="common-content pb-2 pt-0 mb-0">
                            {{ rowData.companyName }}
                        </p>
                        <p class="title mb-0">
                            <strong class="common-title">{{ constants.typeLabel }}:</strong>
                            <span class="common-content">{{ rowData.projectType }}</span>
                        </p>
                        <p class="title mb-0">
                            <strong class="common-title">{{ constants.applicationDateLabel }}:</strong>
                            <span class="common-content">{{ rowData.date }}</span>
                        </p>
                        <div class="d-flex flex-column mt-2">
                            <span class="mb-1">
                                <a (click)="navigateToProject()"><img src="../assets/images/link-icon.svg" alt="" class="me-2">
                                    <span class="text-link">{{ constants.viewProjectsText }}</span>
                                </a>
                            </span>
                            <span class="mb-1">
                                <a (click)="navigateToCompany()"><img src="../assets/images/link-icon.svg" alt="" class="me-2">
                                    <span class="text-link">{{ constants.viewCompanyText }}</span>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
                <app-credit-status-details *ngIf="selectedTab === 'status' && showCreditStatusDetails"
                    [rowData]="rowData"></app-credit-status-details>
                <app-approved-with-risk *ngIf="selectedTab === 'status' && showApprovedWithRisk"
                    [rowData]="rowData"></app-approved-with-risk>
                    <app-final-cred-app-form *ngIf="selectedTab === 'form'" [rowData]="rowData"></app-final-cred-app-form>
                </div>
            
        </div>
    </div>

</div>
