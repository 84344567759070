import { Component, TemplateRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import {
  ACKNOWLEDGE_CONSTANTS,
  ADD_USER_CONSTANTS,
  CREDIT_APPLICATION_CONSTANTS,
  EDIT_DESCRIPTION_CONSTANTS,
  MANAGE_USERS_CONSTANTS,
  MANAGE_USERS_TYPES,
  PROJECT_DETAILS_CONSTANTS,
  PROJECT_DETAILS_SPACES,
  PROJECT_USERS_COUNT,
  PROJECT_USERS_LIST_CONSTANTS,
} from '../../../constants/customer-portal.constant';
import { projectDetails } from 'mockData/development/project-details';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbDate,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { AddProjectUserComponent } from 'src/app/shared/components/add-project-user/add-project-user.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectsListService } from '../../../services/projects-list/projects-list.service';
import { ProjectUsersService } from '../../../services/project-users/project-users.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { BOOKMARK } from 'src/app/feature/studio-portal/enum/studio-portal.enum';
import { CreditAppSidebarComponent } from './credit-app-sidebar/credit-app-sidebar.component';
import { CreditApplicationsService } from '../../../services/credit-applications/credit-applications.service';
import { StartCreditApplicationComponent } from './start-credit-application/start-credit-application.component';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent {
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('dangerTplreset', { static: false }) dangerTplreset!: TemplateRef<any>;
  @ViewChild('successTpl1', { static: false }) successTpl1!: TemplateRef<any>;
  @ViewChild('dangerTpl1', { static: false }) dangerTpl1!: TemplateRef<any>;
  @ViewChild('editDescriptionError', { static: false })
  editDescriptionError!: TemplateRef<any>;
  @ViewChild('editDescriptionSuccess', { static: false })
  editDescriptionSuccess!: TemplateRef<any>;
  @ViewChild('bookmarkSuccess', { static: false })
  bookmarkSuccess!: TemplateRef<any>;
  @ViewChild('bookmarkError', { static: false })
  bookmarkError!: TemplateRef<any>;
  @ViewChild('acknowledgemodal', { static: false }) acknowledgemodal!: TemplateRef<any>;

  projectImages = projectDetails;
  projectDetails;
  projectResponse;
  projectStatus;
  projectUsersData;
  projectUsersCount: any[] = [];
  spacesAndResources = PROJECT_DETAILS_SPACES;
  projectUsersConstants = PROJECT_USERS_COUNT;
  creditApplicationConstants = CREDIT_APPLICATION_CONSTANTS;
  userListConstants = PROJECT_USERS_LIST_CONSTANTS;
  spacesAndResourcesData;
  stagesData: any[] = [];
  officesData: any[] = [];
  workshopsData: any[] = [];
  projectDetailsConstants = PROJECT_DETAILS_CONSTANTS;
  acknowledgeConstants = ACKNOWLEDGE_CONSTANTS;
  editConstants = EDIT_DESCRIPTION_CONSTANTS;
  addUserConstants = ADD_USER_CONSTANTS;
  projectData: any = {};
  title = this.projectDetailsConstants.addUser;
  editForm: FormGroup;
  selectedTab: string = 'spaces';
  bookmarkedArticles: number[] = [];
  hoveredDocuments: Set<number> = new Set();
  editDescriptionMessage;
  bookmarkSuccessMessage;
  bookmarkErrorMessage;
  modalReference!: NgbModalRef;
  departmentDetails;
  userRoleDetails;
  projectId;
  projectName;
  manageUserConstants = MANAGE_USERS_CONSTANTS;
  resourceSpaceId;
  permissionsData: any;
  initialDescription: string = ''; // Store the original description
  disableButtons: boolean = true;
  creditAppStatus = 'Needs Revisions';
  file: File;
  fileData: string | ArrayBuffer;
  creditApplicationData;
  selectedCreditApplication: any;
  isRemove: boolean;
  projectDetailsErrorMessage;
  companyId: number;
  uploadSuccess: any;
  uploadError: any;
  resetError: any;
  selectedFiles: File[] = [];
  uploadLimitReached = false;
  allowedFileTypes = this.manageUserConstants.imageSize;
  maxSizeInBytes = 5 * 1024 * 1024;
  startApplicationData: any;
  errorMessage: any;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private projectsService: ProjectsListService,
    private projectUsersService: ProjectUsersService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private creditApplicationService: CreditApplicationsService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    this.companyId = userProfile?.companyId;
    this.permissionsData = { ...userProfile.roleAssigned };

    this.getProjectDetails();
    this.initializeEditDescriptionForm();
    this.getAddProjectUserMetadata();

    const savedTab = localStorage.getItem('selectedTab');
    if (savedTab) {
      this.selectedTab = savedTab;
    }

    this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.selectedTab = params['tab']; // Set the selected tab based on the query parameter
      }
    });

  }

  /**
   * initializeEditDescriptionForm() - method to initialize the form
   */
  initializeEditDescriptionForm(): void {
    this.initialDescription = this.projectDetails?.projectDesc || '';
    this.editForm = this.formBuilder.group({
      description: [this.initialDescription, Validators.required],
    });
    this.editForm.get('description')?.valueChanges.subscribe((value) => {
      this.disableButtons =
        value.trim() === this.projectDetails?.projectDesc.trim() ||
        this.editForm.pristine;
    });
  }

  /**
   * discardChanges() - method to discard the text added in edit description input box
   */
  discardChanges() {
    this.editForm.patchValue({
      description: this.projectDetails?.projectDesc
    });
    this.editForm.markAsPristine();
    this.disableButtons = true;
  }

  /**
   * getProjectDetails() - Method to retrieve detailed information about a specific project
   * Retrieves the project ID from the route parameters.
   * Fetches project details by project ID and assigns the response to appropriate variables.
   * Sets the project status, project users data, and spaces/resources data.
   * Processes spaces and resources data, counts project users, and sorts the project user list.
   */
  getProjectDetails() {
    this.loaderService.setLoadingState(true);
    const projectId = this.route.snapshot.paramMap.get('projectId');
    this.projectId = projectId;
    const projectIdNumber = +projectId;
    let userProfileId = JSON.parse(localStorage.getItem('user-profile'));
    const userId = userProfileId.userProfileId;
    this.projectsService.getProjectDataById(projectIdNumber, userId).subscribe({
      next: (response: any) => {
        if (response) {
          const res = response.project;
          this.projectResponse = response;
          this.projectDetails = res;
          this.projectName = this.projectResponse.project.projectName;
          this.projectStatus = res.projectStatus;
          this.projectUsersData = res.userProfiles;
          this.spacesAndResourcesData = res.spaceAndResources;
          this.resourceSpaceId = this.spacesAndResourcesData?.resourceSpaceId;
          this.creditApplicationData = res.creditApplications;

          this.getSpacesAndResourcesData();
          this.bookmarkedArticles = _.filter(
            this.spacesAndResourcesData,
            (res) => res.bookmarkResources?.bookmarkedInd === 1
          ).map((res) => res.resourceSpaceId);
          this.projectUsersCount = this.generateProjectUsersCount(
            this.projectUsersData
          );
          this.sortProjectUsersList();
          this.loaderService.setLoadingState(false);
        } else {
          console.warn('Profile data could not be retrieved.');
        }
      },
      error: (error) => {
        console.error('Error fetching project details', error);
        this.loaderService.setLoadingState(false); 
        this.projectDetailsErrorMessage = error;
        this.showDanger(this.dangerTpl);
      },
    });
  }

  /**
   * hasAllDepartments() - method to check if All chekcbox is selected
   * @param application - a specific credit application
   * @returns - true if All is selected else false
   */
  hasAllDepartments(application): boolean {
    return application?.caDepartments?.some(dept => dept?.departments?.departmentName === this.creditApplicationConstants.all);
  }

  /**
   * getArNumbers() - method to get the ar number from credit applications
   * @returns - the list of ar numbers
   */
  getArNumbers(): string {
    if (!this.creditApplicationData) {
      return '';
    }
    return this.creditApplicationData
      .filter((project) => project.arNumber) // Filter out projects with null or 0 AR numbers
      .map((project) => project.arNumber)
      .join(', ');
  }

  /**
   * formatDate() - method to format the date
   * @param dateString - the date to be formatted
   * @returns - returns the formatted date
   */
  formatDate(dateString: string): string {
    if (!dateString) return '';
    const parts = dateString.split('/');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2].slice(-2);
    return `${day}/${month}/${year}`;
  }

  /**
   * updateDescription() - method to update the description
   */
  updateDescription(): void {
    if (this.editForm.valid) {
      this.loaderService.setLoadingState(true);
      const descriptionValue = this.editForm.get('description')?.value;
      const data = {
        projectId: this.projectDetails.projectId,
        projectDesc: descriptionValue,
      };
      this.projectsService.updateProjectDescription(data).subscribe(
        (response: any) => {
          this.modalReference.close();
          this.editDescriptionMessage =
            this.projectDetailsConstants.editSuccess;
          this.showSuccess(this.successTpl);
          this.getProjectDetails();
          this.loaderService.setLoadingState(false);
          // Update initial description and reset form status after save
          this.initialDescription = descriptionValue;
          this.editForm.markAsPristine();
          this.disableButtons = true;
        },
        (error) => {
          console.error('Error updating project description', error);
          this.loaderService.setLoadingState(false);
          this.showDanger(this.editDescriptionError);
        }
      );
    }
  }

  /**
   * getSpacesAndResourcesData() - Method to filter and transform the spaces and resources data
   * Filters the spaces and resources data based on resource type ('Stage', 'Office', 'Workshop').
   * Transforms the filtered data into a structured format for display.
   */
  getSpacesAndResourcesData() {
    this.stagesData = this.transformData(
      this.spacesAndResourcesData?.filter(
        (item) => item.resourceType === 'Stage'
      )
    );
    this.officesData = this.transformData(
      this.spacesAndResourcesData?.filter(
        (item) => item.resourceType === 'Office'
      )
    );
    this.workshopsData = this.transformData(
      this.spacesAndResourcesData?.filter(
        (item) => item.resourceType === 'Workshop'
      )
    );
  }

  /**
   * toggleBookmark() - toggles the bookmark between the normal and fill state
   * @param documentId - id of the document to be bookmarked
   */
  toggleBookmark(documentId: number): void {
    if (this.isBookmarked(documentId)) {
      this.removeBookmark(documentId);
      this.bookmarkedArticles = this.bookmarkedArticles.filter(
        (id) => id !== documentId
      );
    } else {
      this.addBookmark(documentId);
      this.bookmarkedArticles.push(documentId);
    }
  }

  /**
   * addBookmark() - method to bookmark the spaces and resources
   * @param resourceSpaceId - resourceId of the sepecific resource in stages
   * @param currentBookmarkedInd - current state of bookmark (bookmarked or not bookmarked)
   */
  addBookmark(resourceSpaceId: number): void {
    const userProfileId = JSON.parse(
      localStorage.getItem('user-profile')
    )?.userProfileId;
    const projectId = this.projectDetails?.projectId;
    const bookmarkedInd = BOOKMARK.one;
    const status = 'Y';

    const payload = {
      userProfileId,
      projectId,
      resourceSpaceId,
      bookmarkedInd,
      status,
    };

    this.projectsService.addBookmarkSpacesAndResources(payload).subscribe({
      next: (response: any) => {
        this.getProjectDetails();
        this.bookmarkSuccessMessage = response?.message;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.bookmarkSuccess);
      },
      error: (error) => {
        console.error('Error bookmarking resource:', error);
        this.showDanger(this.bookmarkError);
      },
    });
  }

  /**
   * removeBookmark() - method to bookmark the spaces and resources
   * @param resourceSpaceId - resourceId of the sepecific resource in stages
   * @param currentBookmarkedInd - current state of bookmark (bookmarked or not bookmarked)
   */
  removeBookmark(resourceSpaceId: number): void {
    const userProfileId = JSON.parse(
      localStorage.getItem('user-profile')
    )?.userProfileId;
    const projectId = this.projectDetails?.projectId;
    const bookmarkedInd = BOOKMARK.zero;
    const status = 'N';

    const payload = {
      userProfileId,
      projectId,
      resourceSpaceId,
      bookmarkedInd,
      status,
    };

    this.projectsService.removeBookmarkSpacesAndResources(payload).subscribe({
      next: (response: any) => {
        this.getProjectDetails();
        this.bookmarkSuccessMessage = response?.message;
        this.loaderService.setLoadingState(false);
        this.showSuccess(this.bookmarkSuccess);
      },
      error: (error) => {
        console.error('Error bookmarking resource:', error);
        this.showDanger(this.bookmarkError);
      },
    });
  }

  /**
   * transformData() - Method to transform raw resources data into a structured format
   * Loops through the resources array and organizes data into groups based on the `stageNo`.
   * Each group contains a list of documents, and bookmarks are flagged.
   * @param resources - Array of raw resource data to be transformed.
   * @returns Transformed array of resources organized by stages and documents.
   */
  transformData(resources: any[]): any[] {
    const result = [];

    resources?.forEach((resource) => {
      const existingStage = result.find(
        (item) => item.stageName === resource.stageNo
      );
      const document = {
        id: resource.resourceSpaceId,
        name: resource.resourceValue,
        bookmarked: resource?.bookmarkResources?.bookmarkedInd ? 1 : 0,
      };

      if (existingStage) {
        let lastGroup =
          existingStage.documentGroups[
          existingStage?.documentGroups?.length - 1
          ];
        if (lastGroup?.documents?.length === 3) {
          existingStage.documentGroups.push({ documents: [document] });
        } else {
          lastGroup.documents.push(document);
        }
      } else {
        result.push({
          stageName: resource.stageNo,
          documentGroups: [
            {
              documents: [document],
            },
          ],
        });
      }
    });

    return result;
  }

  /**
   * generateProjectUsersCount() - Method to generate the count of users based on specific categories
   * Generates the total count of users, active users, deactivated users, and admins.
   * Returns an array of user count objects for each category.
   * @param projectUsersData - Array of user profile data.
   * @returns Array of user count objects for different categories (All, Active, Deactivated, Admin).
   */
  generateProjectUsersCount(projectUsersData: any[]) {
    return [
      {
        id: 1,
        usersCount: projectUsersData.length,
        label: 'All',
      },
      {
        id: 2,
        usersCount: projectUsersData.filter(
          (user) => user.statusActiveInd === 'Y'
        ).length,
        label: 'Active',
      },
      {
        id: 3,
        usersCount: projectUsersData.filter(
          (user) => user.statusActiveInd === 'N'
        ).length,
        label: 'Deactivated',
      },
      {
        id: 4,
        usersCount: projectUsersData.filter(
          (user) => user.companyAdminInd === 1
        ).length,
        label: 'Admin',
      },
    ];
  }

  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }

  /**
   * selectTab() - Method to select the current tab and store the selected tab in localStorage
   * @param tab - The selected tab
   */
  selectTab(tab: string) {
    this.selectedTab = tab;
    localStorage.setItem('selectedTab', tab);
  }

  /**
   * onMouseEnter() - Method to handle the mouse entering over a document, adding it to hoveredDocuments.
   * @param documentId - The ID of the document the mouse is hovering over.
   */
  onMouseEnter(documentId: number) {
    this.hoveredDocuments.add(documentId);
  }

  /**
   * onMouseLeave() - Method to handle the mouse leaving a document, removing it from hoveredDocuments.
   * @param documentId - The ID of the document the mouse was hovering over.
   */
  onMouseLeave(documentId: number) {
    this.hoveredDocuments.delete(documentId);
  }

  /**
   * isHovered() - Method to check if a document is currently being hovered over.
   * @param documentId - The ID of the document to check.
   * @returns boolean - Whether the document is hovered or not.
   */
  isHovered(documentId: number): boolean {
    return this.hoveredDocuments.has(documentId);
  }

  /**
   * triggerFileUpload() - Programmatically triggers a click event to open the file upload dialog.
   * @param fileInput - The file input element to trigger the click event on.
   */
  triggerFileUpload(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  /**
   * handleResetPhoto() - To Reset the photo
   */
  handleResetPhoto(): void {
    const isRemove = true;
    const fullImageUrl = this.projectResponse.project.imageUrl;
    const url = new URL(fullImageUrl);
    let s3Path = url.pathname;
    if (s3Path.startsWith('/')) {
      s3Path = s3Path.substring(1);
    }
    const data = {
      section: this.manageUserConstants.project,
      projectId: this.projectResponse.project.projectId.toString(),
      filePath: s3Path,
      isRemove: isRemove.toString(),
      projectImageBase64: null,
    };
    this.projectsService
      .resetImage(data)
      .pipe()
      .subscribe({
        next: (response: any) => {
          this.editDescriptionMessage = response.message;
          this.showSuccess(this.successTpl);
          this.getProjectDetails();
        },
        error: (error) => {
          this.showDanger(this.dangerTplreset);
          this.resetError = error;
        },
      });
  }

  /**
   * onImageSelected() - Handles the file selection event when a file is chosen from the file upload dialog.
   * @param event - The event triggered when the user selects a file.
   */
  onImageSelected(event: any): void {
    const files: FileList | null = event.target.files;
  
    if (files && files.length > 0) {
      const fileList: File[] = Array.from(files);
  
      // Process each file to generate base64
      fileList.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Url = (reader.result as string).split(',')[1]; 
          const payload = {
            projectId: this.route.snapshot.paramMap?.get('projectId'),
            section: this.projectDetailsConstants.projectImage,
            projectImageBase64: base64Url,
            isRemove: false,
            filePath: null,
            fileName: file.name,
          };
  
          // Upload the payload
          this.uploadFiles(payload);
        };
        reader.readAsDataURL(file);
      });
    }
  }

  /**
   * uploadFiles() - method to capture the file that user uploaded.
   * @param file - Selected file as a input to convert into base64URL.
   */
  uploadFiles(payload: any): void {
    this.loaderService.setLoadingState(true);
    this.projectsService.addImage(payload).subscribe({
      next: (response: any) => {
        this.uploadSuccess = response.message;
        this.showSuccess(this.successTpl1);
        this.getProjectDetails(); 
      },
      error: (error) => {
        this.uploadError = error;
        this.showDanger(this.dangerTpl1); 
        this.loaderService.setLoadingState(false);
      },
      complete: () => {
        this.loaderService.setLoadingState(false);
      },
    });
  }

  /**
   * navigateToManageUsers() - Navigates to the 'Manage Users' screen, passing the current project's ID and name as query parameters.
   */
  navigateToManageUsers(): void {
    this.router.navigate(['feature/customer-dashboard/manage-users'], {
      queryParams: {
        projectId: this.projectDetails.projectId,
        projectName: this.projectDetails.projectName,
      },
    });
  }

  /**
   * openManageUsers() - Navigates to the 'Manage Users' screen with additional filter parameters, passing the current project and selected user label.
   * @param user - The user object containing the selected user's ID to filter by.
   */
  openManageUsers(user): void {
    const filterParams = {
      projectId: this.projectDetails.projectId,
      projectName: this.projectDetails.projectName,
      labelId: user.id,
    };

    this.router.navigate(['feature/customer-dashboard/manage-users'], {
      queryParams: filterParams,
    });
  }

  /**
   * sortProjectUsersList() - Method to sort the list of users in alphabetical order.
   */
  sortProjectUsersList(): void {
    this.projectUsersData = this.projectUsersData.sort((a, b) =>
      a.firstName.localeCompare(b.firstName)
    );
  }

  /**
   * getSortedUsersList() - Method to get the sorted list of users with full names.
   */
  getSortedUsersList(): string[] {
    return _.map(
      this.projectUsersData,
      (user) => `${user.firstName} ${user.lastName}`
    );
  }

  /**
   * navigateToAddUser() - Method to navigate to the "Add User" page.
   */
  navigateToAddUser(): void {
    this.router.navigate(['feature/customer-dashboard/add-user']);
  }

  /**
   * openEditDescriptionModal() - Method to open the edit project description modal.
   * @param editDescriptionModal - Template reference for the edit description modal.
   */
  openEditDescriptionModal(editDescriptionModal: TemplateRef<any>) {
    window.scrollTo(0, 0);
    this.editForm.patchValue({
      description: this.projectDetails?.projectDesc,
    });
    this.modalReference = this.modalService.open(editDescriptionModal, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
  }

  /**
   * openAcknowledgemodal() - method to open the acknowledge modal
   * @param acknowledgemodal - template reference to the acknowledge modal
   */
  openAcknowledgemodal(
    acknowledgemodal: TemplateRef<any>,
    caSeqId,
    customerStatus,
    caNumber?
  ) {
    this.selectedCreditApplication = { caSeqId, caNumber, customerStatus };
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(acknowledgemodal, {
      size: 'md',
      centered: true,
    });
  }

  /**
   * acknowledgeConditions() - method to open the credit application
   * @param modal - current modal
   */
  acknowledgeConditions(modal: NgbActiveModal): void {
    window.scrollTo(0, 0);
    modal.close();
    this.modalReference = this.modalService.open(CreditAppSidebarComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.caSeqId =
      this.selectedCreditApplication?.caSeqId;
    this.modalReference.componentInstance.caNumber =
      this.selectedCreditApplication?.caNumber;
    this.modalReference.componentInstance.companyId = this.companyId;
    this.modalReference.componentInstance.customerStatus =
      this.selectedCreditApplication?.customerStatus;
  }

  /**
   * openFinishApplicationModal() - method to open the CreditAppSidebarComponent directly for finishing the application
   * @param application - The application object containing the necessary details.
   */
  openFinishApplicationModal(application): void {
    this.loaderService.setLoadingState(true);
    this.creditApplicationService.startCreditApplication(application.caSeqId, application.caNumber).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.startApplicationData = response;
        if (this.startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status == 'Not Started') {
          const caSeqId = application.caSeqId;
          const caNumber = application.caNumber;
          const customerStatus = application.customerStatus;

          this.selectedCreditApplication = { caSeqId, caNumber, customerStatus };
          window.scrollTo(0, 0);
          this.modalReference = this.modalService.open(this.acknowledgemodal, {
            size: 'md',
            centered: true,
          });
        } else {
          window.scrollTo(0, 0);
          this.modalReference = this.modalService.open(CreditAppSidebarComponent, {
            windowClass: 'common-modal-xl',
            fullscreen: true,
          });
          this.modalReference.componentInstance.projectId = this.projectId;
          this.modalReference.componentInstance.projectName = this.projectName;
          this.modalReference.componentInstance.caSeqId = application.caSeqId;
          this.modalReference.componentInstance.caNumber = application.caNumber;
          this.modalReference.componentInstance.companyId = this.companyId;
          this.modalReference.componentInstance.customerStatus = application.customerStatus;
        }
      },
      error: (error) => {
        console.error(error);
        this.loaderService.setLoadingState(false);
        this.errorMessage = error;
        this.showDanger(this.dangerTpl);
      }
    })
  }

  /**
  * startApplication() - method to get the credit application data
  * @param sequenceId 
  * @param creditNumber 
  */
  startApplication(sequenceId, creditNumber, customerStatus) {
    this.loaderService.setLoadingState(true);
    this.creditApplicationService.startCreditApplication(sequenceId, creditNumber).subscribe({
      next: (response: any) => {
        this.loaderService.setLoadingState(false);
        this.startApplicationData = response;
        if (this.startApplicationData?.creditApplicationList?.applicationFormStatus?.caFormStatus?.status == 'Not Started') {
          this.selectedCreditApplication = { sequenceId, creditNumber, customerStatus };
          window.scrollTo(0, 0);
          this.modalReference = this.modalService.open(this.acknowledgemodal, {
            size: 'md',
            centered: true,
          });
        } else {
          this.selectedCreditApplication = { sequenceId, creditNumber, customerStatus };

          window.scrollTo(0, 0);
          this.modalReference = this.modalService.open(CreditAppSidebarComponent, {
            windowClass: 'common-modal-xl',
            fullscreen: true,
          });
          this.modalReference.componentInstance.projectId = this.projectId;
          this.modalReference.componentInstance.projectName = this.projectName;
          this.modalReference.componentInstance.caSeqId = sequenceId;
          this.modalReference.componentInstance.caNumber = creditNumber;
          this.modalReference.componentInstance.companyId = this.companyId;
          this.modalReference.componentInstance.customerStatus = customerStatus;
        }

      },
      error: (error) => {
        console.error(error);
        this.loaderService.setLoadingState(false);
        this.errorMessage = error;
        this.showDanger(this.dangerTpl);
      }
    })
  }
  /**
   * openRevisionsModal() - method to open the revisions modal
   * @param sequenceId - the sequentialId
   * @param creditNumber - the credit number
   */
  openRevisionsModal(caSeqId, caNumber, customerStatus): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(CreditAppSidebarComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.componentInstance.creditAppStatus =
      this.creditAppStatus;
    this.modalReference.componentInstance.caSeqId = caSeqId;
    this.modalReference.componentInstance.caNumber = caNumber;
    this.modalReference.componentInstance.customerStatus = customerStatus;
  }

  /**
   * getAddProjectUserMetadata() - Method to fetch the metadata for add project user screen
   */
  getAddProjectUserMetadata() {
    this.projectUsersService.fetchMetaData().subscribe((response: any) => {
      const metaData = response;
      this.departmentDetails =
        metaData.getProjectUserMetaDataDTO.departmentsDetails;
      this.userRoleDetails = metaData.getProjectUserMetaDataDTO.userRoleDetails;
    });
  }

  /**
   * openAddUserModal() - Method to open the add a user modal.
   */
  openAddUserModal() {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(AddProjectUserComponent, {
      windowClass: 'common-modal-xl',
      fullscreen: true,
    });
    this.modalReference.componentInstance.modalTitle =
      this.manageUserConstants.addUserTitle;
    this.modalReference.componentInstance.departmentDetails =
      this.departmentDetails;
    this.modalReference.componentInstance.userRoleDetails =
      this.userRoleDetails;
    this.modalReference.componentInstance.portalType =
      MANAGE_USERS_TYPES.portalType;
    this.modalReference.componentInstance.modalType =
      MANAGE_USERS_TYPES.addModalType;
    this.modalReference.componentInstance.componentType =
      MANAGE_USERS_TYPES.componentType;
    this.modalReference.componentInstance.projectId = this.projectId;
    this.modalReference.componentInstance.projectName = this.projectName;
    this.modalReference.result.then(
      (data) => {
        if (data) {
          if (data.status == 'success') {
            this.getProjectDetails();
          }
        }
        // on close
      },
      (reason) => {
        // on dismiss
      }
    );
  }

  /**
   * openStartCreditApplicationModal() - method to add a new credit application
   */
  openStartCreditApplicationModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(StartCreditApplicationComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalReference.componentInstance.projectId = this.projectDetails?.projectId;
    this.modalReference.componentInstance.projectName = this.projectDetails?.projectName;
    this.modalReference.componentInstance.companyId = this.projectDetails?.company?.companyId;
    this.modalReference.componentInstance.companyName = this.projectDetails?.company?.companyName;
    this.modalReference.componentInstance.departmentDetails = this.departmentDetails;
    this.modalReference.result.then(
      (data) => {
        if (data) {
          this.getProjectDetails();
        }
      },
      (reason) => {
        // on dismiss
      });
  }

  /**
   * Converts an NgbDate object into a string formatted as 'DD/MM/YY'.
   * @param ngbDate - An object of NgbDate containing day, month, and year.
   * @returns A formatted date string in 'DD/MM/YY' format.
   */
  convertNgbDateToString(ngbDate: NgbDate): string {
    const day = ngbDate.day < 10 ? '0' + ngbDate.day : ngbDate.day;
    const month = ngbDate.month < 10 ? '0' + ngbDate.month : ngbDate.month;
    return `${day}/${month}/${ngbDate.year.toString().slice(-2)}`;
  }

  /**
   * returnToProjects() - Method to navigate back to the projects list and clear the selected tab from localStorage.
   */
  returnToProjects() {
    localStorage.removeItem('selectedTab');
    this.router.navigate(['/feature/customer-dashboard/projects-list']);
  }

  /**
   * isBookmarked() - Method to check if an article is bookmarked.
   * @param articleId - The ID of the article to check.
   * @returns boolean - Whether the article is bookmarked.
   */
  isBookmarked(articleId: number): boolean {
    return this.bookmarkedArticles.includes(articleId);
  }

  /**
   * navigateToMaps() - method to navigate to maps page
   */
  navigateToMaps() {
    window.open('/maps-directory-protocols', '_blank');
  }
}
