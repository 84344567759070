import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserProfileService } from 'src/app/core/services/user-profileinfo/user-profile.service';
import { ProjectsListService } from '../../services/projects-list/projects-list.service';
import { HeaderService } from 'src/app/core/services/header/header.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';

@Component({
  selector: 'app-customer-request',
  templateUrl: './customer-request.component.html',
  styleUrls: ['./customer-request.component.scss']
})
export class CustomerRequestComponent implements OnInit {
  @ViewChild('requestModal') requestModal: TemplateRef<any>;
  isNextEnabled: boolean = false;
  selectedRequestType: string = '';
  modalReference: NgbModalRef;
  projects: any[] = [];
  userName: string = '';
  companyName: string = '';
  selectedProjectId: string = ''; // New variable for storing the selected project ID
  selectedProject: any; // Add this line
  isProjectSelected: boolean = false; 

  constructor(private modalService: NgbModal, private router: Router, private http: HttpClient ,  private projectListService: ProjectsListService,
    private userProfileService: HeaderService,    private loaderService: LoaderService,
    
  ) { }

  ngOnInit(): void {
    // Initialization logic if needed
  }

  openRequestsModal(requestModal): void {
    this.fetchProjectsAndUserData(() => {
      this.modalReference = this.modalService.open(requestModal, {
        windowClass: 'common-modal-xl',
        fullscreen: true,
      });
    });
  }

  // fetchProjectsAndUserData(callback: () => void): void {
  //   const userProfile = JSON.parse(localStorage.getItem('user-profile'));
  //   const userProfileId = userProfile?.userProfileId;

  //   if (!userProfileId) {
  //     console.error('User profile ID not found.');
  //     return;
  //   }

  //   // Ensure the API call includes all required query parameters
  //   const params = new HttpParams().set('userProfileId', userProfileId.toString());

  //   this.http.get(`https://greenlight-api-dev.wbd.com/customer-portal/projects`, { params })
  //     .subscribe((response: any) => {
  //       console.log('API Response:', response); // Log the response to inspect its structure

  //       if (response.success) {
  //         this.projects = response.userProjects.projects;
  //         if (this.projects.length > 0) {
  //           this.userName = response.userProjects.userName || 'Unknown User'; // Adjust this line based on actual response
  //           this.companyName = this.projects[0].company.companyName;
  //         }
  //         callback(); // Open the modal after data is fetched
  //       } else {
  //         console.error('API call unsuccessful:', response.message);
  //       }
  //     }, error => {
  //       console.error('Error fetching projects:', error);
  //     });
  // }
  fetchProjectsAndUserData(callback: () => void): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    const userProfileId = userProfile?.userProfileId;
    this.userProfileService.getuserprofile(userProfileId).subscribe({
      next: (userProfileResponse: any) => {
        if (userProfileResponse.success) {
          const firstName = userProfileResponse.userProfileSearch.firstName || '';
          const lastName = userProfileResponse.userProfileSearch.lastName || '';
          this.userName = `${firstName} ${lastName}`.trim();

          this.projectListService.getProjectsData(userProfileId).subscribe({
            next: (projectResponse: any) => {
              if (projectResponse.success) {
                this.projects = projectResponse.userProjects.projects;
                console.log('Projects:', this.projects);

                if (this.projects.length > 0) {
                  this.companyName = this.projects[0].company.companyName || 'Unknown Company';
                }
                callback();
              } 
              this.loaderService.setLoadingState(false);

            },
          });
        } 
      },
    });
  }



  onRadioChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.isNextEnabled = target.checked;
    this.selectedRequestType = target.id;
  }

  onProjectSelect(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedProjectId = selectElement.value;
    this.isProjectSelected = this.selectedProjectId !== '';
    this.selectedProject = this.projects.find(project => project.projectId.toString() === this.selectedProjectId);
    console.log('Selected Project ID:', this.selectedProjectId);
    console.log('Selected Project:', this.selectedProject);
  }




  navigateToSalesforce(): void {
    this.modalService.dismissAll();
    console.log('Projects Array:', this.projects);
    console.log('Selected Project ID:', this.selectedProjectId);


    const selectedProject = this.projects.find(project => project.projectId.toString() === this.selectedProjectId);
    console.log('Selected Project ID:', this.selectedProjectId);
    console.log('Selected Project:', this.selectedProject);

    // Assuming selectedRequestType holds the projectId
    const queryParams = {
      projectId: selectedProject?.projectId,
      projectName: selectedProject?.projectName,
      userName: this.userName,
      companyName: this.companyName
    };
    console.log('Query Params:', queryParams);
    if (this.selectedRequestType === 'hotLockStage') {
      this.router.navigate(['/feature/customer-dashboard/salesforce-path'], { queryParams: queryParams });
    } else if (this.selectedRequestType === 'cognitoForm') {
      this.router.navigate(['/feature/customer-dashboard/setlightform'], { queryParams: queryParams });
    } else if (this.selectedRequestType === 'hot-lock') {
      this.router.navigate(['/feature/customer-dashboard/hotlock'], { queryParams: queryParams });
    }
  }
}
