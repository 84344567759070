import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/shared/services/base-api/base-api.service';
import { CUSTOMER_PORTAL_API_PATHS } from 'src/assets/constants/apiPaths';

@Injectable({
  providedIn: 'root'
})
export class CreditApplicationService {

  constructor( private baseApiService: BaseApiService) { }

  /**
   * getCreditApplication() - to get all credit applications
   * @param companyId - the company id to get credit applications
   * @returns an observable of reponse
   */
  getCreditApplication(){
    const endpoint = CUSTOMER_PORTAL_API_PATHS.credit_applications;
    return this.baseApiService.get(endpoint);
  }
}
