import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { NgMultiSelectDropDown } from 'src/app/shared/components/ng-multiselect-dropdown/ng-multiselect-dropdown.module';
import { StudioPortalRoutingModule } from './studio-portal-routing.module';
import { UsersComponent } from './components/users/users.component';
import { StudioPortalComponent } from './studio-portal.component';
// import { CommonAgGridComponent } from 'src/app/shared/components/common-ag-grid/common-ag-grid.component';
import { CompanyProjectComponent } from './components/company/company-project/company-project.component';
// import { FeatureModule } from '../feature.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViewCompanyComponent } from './components/company/view-company/view-company.component';
// // import { AddNewProjectComponent } from './components/company/add-new-project/add-new-project.component';
import { CompaniesComponent } from './components/company/companies/companies.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ProjectsComponent } from './components/projects/projects.component';
import { ViewAllUsersComponent } from './components/projects/view-all-users/view-all-users.component';
import { CoreModule } from 'src/app/core/core.module';
import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';import { SearchCompanyComponent } from './components/company/search-company/search-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddProjectComponent } from './components/projects/add-project/add-project.component';
import { SendCreditApplicationComponent } from './components/projects/project-details/send-credit-application/send-credit-application.component';
import { ViewQuotesComponent } from './components/projects/project-details/view-quotes/view-quotes.component';
import { AttachQuotesComponent } from './components/projects/project-details/attach-quotes/attach-quotes.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StudioDashboardCreditApplicationComponent } from './components/dashboard/studio-dashboard-credit-application/studio-dashboard-credit-application.component';
import { StudioDashboardRequestsComponent } from './components/dashboard/studio-dashboard-requests/studio-dashboard-requests.component';
import { StudioDashboardBookmarkedProjectsComponent } from './components/dashboard/studio-dashboard-bookmarked-projects/studio-dashboard-bookmarked-projects.component';
import { CreditApplicationComponent } from './components/credit-application/credit-application.component';
import { CreditStatusDetailsComponent } from './components/credit-application/credit-status-details/credit-status-details.component';
import { AddDepartmentComponent } from './components/credit-application/add-department/add-department.component';
import { RiskEmailTier1Component } from './components/credit-application/risk-email-tier1/risk-email-tier1.component';
import { FinalCredAppFormComponent } from './components/credit-application/final-cred-app-form/final-cred-app-form.component';
import { CredAppComponent } from './components/credit-application/cred-app/cred-app.component';
import { ApprovedWithRiskComponent } from './components/credit-application/approved-with-risk/approved-with-risk.component';
import { CreditApplicationDeptComponent } from './components/credit-application/credit-application-dept/credit-application-dept.component';
import { HelpSlideComponent } from './components/credit-application/help-slide/help-slide.component';
import { RiskEmailComponent } from './components/credit-application/risk-email/risk-email.component';
// import { ViewAttachQuotesComponent } from './components/projects/project-details/view-attach-quotes/view-attach-quotes.component';


@NgModule({
    declarations: [
     UsersComponent,
      StudioPortalComponent,
      CompanyProjectComponent,
      ViewCompanyComponent,
      // AddNewProjectComponent,
      CompaniesComponent,
      ProjectsComponent,
      ViewAllUsersComponent,
      ProjectDetailsComponent,
      SearchCompanyComponent,
      AddProjectComponent,
      SendCreditApplicationComponent,
      ViewQuotesComponent,
      AttachQuotesComponent,
      CreditApplicationComponent,
      CreditStatusDetailsComponent,
      RiskEmailComponent,
      AddDepartmentComponent,
      DashboardComponent,
      StudioDashboardCreditApplicationComponent,
      StudioDashboardRequestsComponent,
      StudioDashboardBookmarkedProjectsComponent,
      RiskEmailTier1Component,
      FinalCredAppFormComponent,
      CredAppComponent,
      ApprovedWithRiskComponent,
      HelpSlideComponent,
      CreditApplicationDeptComponent,
      // ViewAttachQuotesComponent
    ],
    imports: [
      CommonModule,
      AgGridModule,
      ReactiveFormsModule,
      FormsModule,
      StudioPortalRoutingModule,
      NgMultiSelectDropDown,
      SharedModule,
      NgMultiSelectDropDownModule,
      CoreModule,
      NgbTooltipModule,
      NgbModule,
    ],
    exports: [
      UsersComponent,
      StudioPortalComponent,
      CompaniesComponent

    ],
    providers: [
      NgbActiveModal,
    ]
  })
  export class StudioPortalModule { }
  
  
