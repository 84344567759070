
import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { ADD_DEPARTMENT_ARRAY, CREDIT_APP_DETAILS_CONSTANTS, STATUS_CLASSES, STUDIO_SUCCESS_APIMESSAGE } from '../../../constants/studio-portal.constant';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { AddDepartmentComponent } from '../add-department/add-department.component';
import { RiskEmailComponent } from '../risk-email/risk-email.component';
import { ToastService } from 'src/app/shared/components/toast.service';
import { CreditStatusDetailsService } from '../../../services/credit-status-details/credit-status-details.service';

@Component({
  selector: 'app-approved-with-risk',
  templateUrl: './approved-with-risk.component.html',
  styleUrls: ['./approved-with-risk.component.scss']
})
export class ApprovedWithRiskComponent {
  @Input() rowData: any;
  deptData = ADD_DEPARTMENT_ARRAY;
  msg = STUDIO_SUCCESS_APIMESSAGE;
  private modalService = inject(NgbModal);
  selectedDepartments: any[] = [];
  caNumber: number = 4;
  caId: number = 4;
  commentText: string = '';
  error_msg: string = '';
  success_msg: string = '';
  comments: any[] = [];
  modifiedByName: string = '';
  modalReference: NgbModalRef;
  copySuccess: boolean = false;
  constants = CREDIT_APP_DETAILS_CONSTANTS;
  constructor(private toastService: ToastService, private creditStatusService: CreditStatusDetailsService) { }
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('AddDepartmentComponent', { static: false }) AddDepartmentComponent!: TemplateRef<any>;
  @ViewChild('RiskEmailComponent', { static: false }) RiskEmailComponent!: TemplateRef<any>;


  ngOnInit() {} 
  /**
     * openAddDepartmentModal() - Opens the Add Department Modal and 
     * if any departments selected invoke on DepartmentsSelected method
  */
  openAddDepartmentModal(): void {
    window.scrollTo(0, 0);
    const modalRef = this.modalService.open(AddDepartmentComponent, {
      windowClass: 'common-modal',
      size: 'lg',
      fullscreen: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.selectedDepartments = [...this.selectedDepartments];
    modalRef.result.then((departments) => {
      if (departments.length > 0) {
        this.onDepartmentsSelected(departments);
      }
    }, () => { });
  }

  /**
     * onDepartmentsSelected()=Handles the selection of departments.
     * @param departments-The list of selected departments return from the modal.
  */
  onDepartmentsSelected(departments): void {
    const updatedDepartments = _.map(departments, id => _.find(this.deptData, { departmentId: id }));
    this.selectedDepartments = _.filter(updatedDepartments, Boolean);
  }

  /**
   * formattedDepartmentNames()=To get the formatted data to display it on the UI
  */
  get formattedDepartmentNames(): string {
    return _.join(_.map(this.selectedDepartments, 'departmentName'), '; ');
  }
  /**
     * openRiskEmailModal() - Opens the Risk Email modal for user review.
     */
  openRiskEmailModal(): void {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(RiskEmailComponent, {
      windowClass: 'common-modal',
      size: 'lg',
      fullscreen: false,
      backdrop: 'static'
    });
  }
  /**
 * copyToClipboard() - Copies the provided text to the clipboard.
 * @param text - The text to be copied to the clipboard.
 */
  copyToClipboard(text: string): void {
    const textToCopy = text;
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        this.copySuccess = true;
        alert('Copied to clipboard!');

      },
      (err) => {
        console.error('Copy failed', err);
        this.copySuccess = false;
      }
    );
  }
  /**
   addComments() - This method handles the adding a comment.It handles both success and error 
   responses by displaying appropriate messages to the user via toast notifications.
   */
  addComments() {
    if (!this.commentText.trim()) {
      this.error_msg = this.msg.errorwithoutinput;
      this.showDanger(this.dangerTpl);
      return;
    }
    const commentData = {
      caNumber: this.caNumber,
      caId: this.caId,
      commentText: this.commentText
    };
    this.creditStatusService.addaComment(commentData).subscribe(
      (response) => {
        this.success_msg = response.message;
        this.commentText = '';
        this.error_msg = '';
        // this.modifiedByName = this.getModifiedByName(response);
        this.comments.push({
          modifiedByName: response.caCommentAdd.modifiedByName,
          modifiedDatetime: response.caCommentAdd.modifiedDatetime,
          commentText: response.caCommentAdd.commentText
        });
        this.showSuccess(this.successTpl);
        console.log('Success:', response);
      },
      (error) => {
        this.error_msg = this.msg.errorwithmethod;
        this.showDanger(this.dangerTpl);
        console.error('Error:', error);
      }
    );
  }
  getStatusClass(status: string): string {
    return STATUS_CLASSES[status];
  }
  /**
    * showSuccess() - Displays a success toast notification with custom content and options.
    * @param successTpl - The template reference containing the toast's content.
  */
  showSuccess(successTpl: TemplateRef<any>) {
    this.toastService.clear()
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Displays a danger (error) toast message.
   * This method is called when there is an error in adding the user.
   * @param dangerTpl - The template reference for the danger message to display.
  */
  showDanger(dangerActionTpl: any) {
    this.toastService.show(dangerActionTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
