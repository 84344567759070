import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ACKNOWLEDGE_CONSTANTS, ADD_APPLICATION_CONSTANTS } from 'src/app/feature/customer-portal/constants/customer-portal.constant';
import { CreditApplicationsService } from 'src/app/feature/customer-portal/services/credit-applications/credit-applications.service';
import { ProjectsCreditApplicationService } from 'src/app/feature/studio-portal/services/projects-credit-application.service';
import { ToastService } from 'src/app/shared/components/toast.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { CreditAppSidebarComponent } from '../credit-app-sidebar/credit-app-sidebar.component';

@Component({
  selector: 'app-start-credit-application',
  templateUrl: './start-credit-application.component.html',
  styleUrls: ['./start-credit-application.component.scss']
})
export class StartCreditApplicationComponent {
  @Input() departmentDetails: any;
  @Input() projectId;
  @Input() projectName;
  @Input() companyId;
  @ViewChild('successTpl', { static: false }) successTpl!: TemplateRef<any>;
  @ViewChild('dangerTpl', { static: false }) dangerTpl!: TemplateRef<any>;
  @ViewChild('acknowledgemodal', { static: false }) acknowledgemodal!: TemplateRef<any>;

  
  departmentsData: any;
  creditApplicationForm: FormGroup;
  errorMessage;
  successMessage;
  constants = ADD_APPLICATION_CONSTANTS;
  modalReference!: NgbModalRef;
  acknowledgeConstants = ACKNOWLEDGE_CONSTANTS;
  selectedCreditApplication: any;
  responseInitiate: any;
  constructor(
    private loaderService: LoaderService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private creditApplicationService: CreditApplicationsService,
    private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.creditApplicationForm = this.fb.group({});
    this.departmentsData = this.departmentDetails;
    this.initializeFormControls();
    this.changeButtonState();
  }

  /**
   * changeButtonState() - method to subscribe to form value changes and updates the save button state.
   */
  changeButtonState(): void {
    this.creditApplicationForm.valueChanges.subscribe(() => {
      this.toggleSaveButton();
    });
  }

  /**
   * initializeFormControls() - method to dynamically create form controls for each department.
   */
  initializeFormControls(): void {
    // Loop through departmentDetails and create form controls dynamically
    this.departmentsData.forEach(dept => {
      this.creditApplicationForm.addControl(`department${dept.departmentId}`, this.fb.control(false));
    });
  }

  onDepartmentCheckboxChange(event: Event, dept: any): void {
    const checkbox = event.target as HTMLInputElement;
    const departmentId = Number(checkbox.value);
  
    switch (checkbox.checked) {
      case true: {
        if (departmentId === this.departmentsData[0].departmentId) {
          // If 'All' checkbox is checked, select all checkboxes
          this.departmentsData.forEach((department) => {
            this.creditApplicationForm
              .get(`department${department.departmentId}`)
              ?.setValue(true);
          });
        } else {
          // Add the current department to selected list
          const allSelected = this.departmentsData.every((department) =>
            this.creditApplicationForm.get(`department${department.departmentId}`)?.value
          );
  
          // If all individual checkboxes are checked, also check the 'All' checkbox
          this.creditApplicationForm
            .get(`department${this.departmentsData[0].departmentId}`)
            ?.setValue(allSelected);
        }
        break;
      }
      case false: {
        if (departmentId === this.departmentsData[0].departmentId) {
          // If 'All' checkbox is unchecked, unselect all checkboxes
          this.departmentsData.forEach((department) => {
            this.creditApplicationForm
              .get(`department${department.departmentId}`)
              ?.setValue(false);
          });
        } else {
          // If an individual checkbox is unchecked, uncheck the 'All' checkbox
          this.creditApplicationForm
            .get(`department${this.departmentsData[0].departmentId}`)
            ?.setValue(false);
        }
        break;
      }
    }
  }
  

  /**
   * isAtLeastOneSelected() - method to check if at least one department checkbox is selected.
   * @returns - A boolean value indicating whether any department is selected.
   */
  isAtLeastOneSelected(): boolean {
    const formValues = this.creditApplicationForm.value;
    return Object.values(formValues).some(value => value === true);
  }

  /**
   * toggleSaveButton() - method to enable or disable the save button based on form selections.
   */
  toggleSaveButton(): void {
    const saveButton = document.querySelector('.save-btn') as HTMLButtonElement;
    if (saveButton) {
      saveButton.disabled = !this.isAtLeastOneSelected();
    }
  }

  /**
   * closeModal() - method to close the current modal.
   */
  closeModal(): void {
    this.activeModal.close();
  }

  /**
   * onSubmit() - method to handle the form submission process.
   */
  onSubmit(): void {
    if (this.creditApplicationForm.valid) {
      this.openAcknowledgemodal();
    } else {
      console.error('Form is invalid:', this.creditApplicationForm.errors);
    }
  }

  
  /**
   * openAcknowledgemodal() - method to open the acknowledge modal
   * @param acknowledgemodal - template reference to the acknowledge modal
   */
  openAcknowledgemodal() {
    window.scrollTo(0, 0);
    this.modalReference = this.modalService.open(this.acknowledgemodal, {
      size: 'md',
      centered: true,
    });
  }

  /**
     * acknowledgeConditions() - method to open the credit application
     * @param modal - current modal
     */
    acknowledgeConditions(modal: NgbActiveModal): void {
      window.scrollTo(0, 0);
      modal.close();
       const selectedDepartmentIds = this.departmentDetails
        .filter(dept => this.creditApplicationForm.value[`department${dept.departmentId}`])
        .map(dept => dept.departmentId);
      
      const departmentOneSelected = selectedDepartmentIds.includes(1);
      // If departmentId 1 is selected, send only departmentId 1, otherwise send all selected departments
      const finalDepartmentIds = departmentOneSelected ? [1] : selectedDepartmentIds;

      const payload = {
        companyId: this.companyId,
        projectId: this.projectId,
        caPacketText: null,
        caDepartmentIds: finalDepartmentIds,
        caAccPayUsrIds: [],
        portalType: 'customer'
      };
      this.loaderService.setLoadingState(true);
      this.creditApplicationService.addCreditApplication(payload).subscribe({
        next: (response: any) => {
          this.responseInitiate = response?.caInitiateResponseDto;
          this.successMessage = this.constants.submitSuccess;
          this.showSuccess(this.successTpl);
          this.activeModal.close(this.successMessage);
          this.loaderService.setLoadingState(false);
          this.modalReference = this.modalService.open(CreditAppSidebarComponent, {
            windowClass: 'common-modal-xl',
            fullscreen: true,
          });
        
          this.modalReference.componentInstance.projectId = this.projectId;
          this.modalReference.componentInstance.projectName = this.projectName;
          this.modalReference.componentInstance.caSeqId = this.responseInitiate?.caSeqId;
          this.modalReference.componentInstance.caNumber =this.responseInitiate?.caNumber;
          this.modalReference.componentInstance.companyId = this.companyId;
          this.modalReference.componentInstance.customerStatus = this.responseInitiate?.customerStatus;
        },
        error: (error) => {
          this.errorMessage = { status: 'error', message: error };
          this.activeModal.close(error);
          this.showDanger(this.dangerTpl);
          this.loaderService.setLoadingState(false);
        },
      });
    }
  /**
   * showSuccess() - Displays a success toast notification with custom content and options.
   * @param successTpl - The template reference containing the toast's content.
   */
  showSuccess(successTpl: TemplateRef<any>): void {
    this.toastService.show(successTpl, {
      classname: 'custom-toast',
      delay: 3000,
    });
  }

  /**
   * showDanger() - Method to display a danger (error) toast message
   * @param dangerTpl - The template reference for the danger message to display
   */
  showDanger(dangerTpl: TemplateRef<any>): void {
    this.toastService.show(dangerTpl, {
      classname: 'bg-danger text-light',
      delay: 3000,
    });
  }
}
