<div class="container cm-main-container px-0 mx-0">
    <div class="common-modal-input-section grey-bg">
        <div [innerHTML]="termsContent" class="term-condition-desc">
        </div>
    </div>

    <div class="common-modal-input-section terms-input-sec mt-5">
        <div class="container cm-main-container px-0 mx-0">
            <div class="alert revision-alert" role="alert" *ngIf="termsAndConditionsMessage">
                <div class="container">
                    <div class="row">
                        <div class="font-weight-bold my-2">
                            "{{ termsAndConditionsMessage }}"
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-6 custom-floating-label">
                    <label for="termCondiName" class="form-label"> Name</label>
                    <input type="text" class="form-control" placeholder="Name" id="termCondiName"
                        formControlName="name">
                    <small class="text-danger"
                        *ngIf="form.get('name').invalid && (form.get('name').touched || form.get('name').dirty)">
                        <div *ngIf="form.get('name').errors['maxlength']">Max length exceeded</div>
                        <div *ngIf="form.get('name').errors['invalidAlphabets']">{{credFormThree.nameInvalid }}
                        </div>
                    </small>
                </div>
                <div class="col-md-6 custom-floating-label">
                    <label for="termCondiTitle" class="form-label"> Title</label>
                    <input type="text" class="form-control" placeholder="Title" id="termCondiTitle"
                        formControlName="title">
                    <small class="text-danger"
                        *ngIf="form.get('title').invalid && (form.get('title').touched || form.get('title').dirty)">
                        <div *ngIf="form.get('title').errors['maxlength']">Max length exceeded</div>
                        <div *ngIf="form.get('title').errors['invalidAlphanumericSpecial']">
                            {{credFormThree.titleInvalid }}</div>
                    </small>
                </div>
                <div class="col-md-6 custom-floating-label">
                    <label for="termCondiSignature" class="form-label"> Signature</label>
                    <input type="text" class="form-control" placeholder="Signature" id="termCondiSignature"
                        formControlName="signature">
                    <small class="text-danger"
                        *ngIf="form.get('signature').invalid && (form.get('signature').touched || form.get('signature').dirty)">
                        <div *ngIf="form.get('signature').errors['maxlength']">Max length exceeded</div>
                        <div *ngIf="form.get('signature').errors['invalidAlphabets']">
                            {{credFormThree.signatureInvalid }}</div>
                    </small>
                </div>
                <div class="col-md-4 custom-floating-label"  id="datepicker ">
                    <label for="termCondiDate" class="form-label">Date</label>
                    <input type="date" class="form-control  d-block" id="termCondiDate" placeholder="Date" name="dp" formControlName="date"
                    ngbDatepicker (ngModelChange)="onDateSelect($event)" />
                </div>
            </div>
        </form>
    </div>
    <div class="cm-btn-section d-flex align-items-center">
        <div class="left-btn me-auto">
            <a class="text-link" (click)="previousPage()"><img class="back-img"
                    src="../../assets/images/blue-left-icon.svg" alt="Back" />back
            </a>
        </div>
        <button type="button" class="btn common-btn-secondary" data-bs-dismiss="modal" (click)="nextPage()">Next: Review
            Application</button>
        <button type="button" class="btn common-btn-light ms-2" data-bs-dismiss="modal" (click)="saveAndClose()"
            [disabled]="!form.dirty">Save & Close</button>
    </div>
</div>

<ng-template #successTpl>
    <div class="success success-text-transform">{{credFormThree.termsAndConditionsSuccess}}</div>
</ng-template>

<ng-template #dangerTpl>
    <div class="danger">{{ submissionError }}</div>
</ng-template>