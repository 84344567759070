<div class="page-wrapper">
  <div class="container px-0">
    <div class="row">
      <!-- <div class="all-project-link-section">
        <img src="../../assets/images/keyboard_arrow_left.svg" alt="keyboard-arrow-left" />
        <p class="all-project-link-name mb-0">All Projects</p>
    </div> -->
      <div class="comman-title-block">
        <div class="comman-title-section">
          <h1>Hi, {{name}}</h1>
        </div>
        <div class="comman-filter-block">
          <div class="comman-filter-title-section mb-0">
            <p>{{creditApplication.filter}}</p>
          </div>
          <div class="dual-filter-section">
            <div class="comman-filter-section d-flex flex-wrap align-items-center gap-3">
              <app-ng-multiselect-dropdown [placeholder]="'Projects'" [settings]="defaultSettings"
                 [data]="projectsFilter" 
                 (onSelect)="selectedProjects($event)">
              </app-ng-multiselect-dropdown>

              <app-ng-multiselect-dropdown [placeholder]="'Departments'" [settings]="defaultSettings"
                 [data]="departmentFilter" 
                [searchtextplaceholder]="'Type a job title'" (onSelect)="selectedDepartments($event)">
              </app-ng-multiselect-dropdown>
              
              <app-ng-multiselect-dropdown [placeholder]="'Status'" [settings]="defaultSettings" [data]="status"
                (onSelect)="selectedStatus($event)">
              </app-ng-multiselect-dropdown>
            </div>
            <!-- <div class="notification-coloum">
                <img
                  src="assets/images/approved-email.svg"
                  alt="approved-email"
                />
                <span class="notification-status"
                  >Status letter/notification not sent</span
                >
                <div class="form-check form-switch mb-0">
                  <input
                    class="form-check-input switch common-tog"
                    type="checkbox"
                    role="switch"
                    checked
                  />
                </div>
              </div> -->
          </div>
        </div>
      </div>

      <div class="selected-filters-section">
        <!-- Combined Selected Filters -->
        <div *ngIf="isFiltersSelected" class="selected-filters">

          <div *ngFor="let status of filteredStatus" class="filter-tag">
            {{ status.text }}
            <button type="button" (click)="removeSelectedItem('status', status)">×</button>
          </div>

          <div *ngFor="let department of filteredDepartments" class="filter-tag">
            {{ department.text }}
            <button type="button" (click)="removeSelectedItem('department', department)">×</button>
          </div>

          <!-- Selected Projects -->
          <div *ngFor="let project of filteredProjects" class="filter-tag">
            {{ project.text }}
            <button type="button" (click)="removeSelectedItem('project', project)">×</button>
          </div>

          <!-- Clear All Filters Button -->
          <a class="text-link" (click)="clearAllFilters()">clear All</a>
        </div>
      </div>

      <div class="common-grid-section">
        <div class="common-grid-block">
          <div class="mt-4">
            <app-common-ag-grid class="ag-theme-custom" [colDefs]="colDefs" [rowData]="filteredData"
              [colsForSmallScreen]="colsForSmallScreen" [colsForMediumScreen]="colsForMediumScreen"
              [colsForLargeScreen]="colsForLargeScreen" [colsForExtraLargeScreen]="colsForExtraLargeScreen"
              [editType]="'fullRow'" [suppressClickEdit]="true" [animateRows]="true"   (cellClicked)="onRowClicked($event)">>
            </app-common-ag-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>