<div class="container cm-main-container px-0 mx-0">
  <div class="common-modal-input-section grey-bg">
    <h2 class="section-main-header"> {{ constants.title }}<sup class="text-danger">*</sup></h2>
    <div class="container cm-main-container px-0 mx-0">
      <div class="alert revision-alert" role="alert" *ngIf="coiMessage">
        <div class="container">
          <div class="row">
            <div class="font-weight-bold my-2">
              "{{ coiMessage }}"
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <form [formGroup]="insuranceForm">
        <div class="row">
          <div class="col-md-12">
            <p class="form-section-header mt-0 mb-4"> {{ constants.namesLabel }} </p>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="insurInfoProducer" class="form-label"> {{ constants.producerLabel }} </label>
            <input type="text" class="form-control" formControlName="producer" placeholder="Producer"
              id="insurInfoProducer">
            <span class="span-info ps-2"> {{ constants.insuranceBrokerName }} </span>
            <small class="text-danger"
              *ngIf="insuranceForm.get('producer').invalid && (insuranceForm.get('producer').touched || insuranceForm.get('producer').dirty)">
              <div *ngIf="insuranceForm.get('producer').errors['required']">This field is required</div>
              <div *ngIf="insuranceForm.get('producer').errors['maxlength']">Producer name should not exceed 50
                characters</div>
              <div *ngIf="insuranceForm.get('producer').errors['invalidAlphanumericSpecial']">{{
                addProjectUser.firstNameInvalid }}
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="insurInfoInsured" class="form-label"> {{ constants.insuredLabel }} </label>
            <input type="text" class="form-control" formControlName="insured" placeholder="Insured"
              id="insurInfoInsured">
            <span class="span-info ps-2">Your full name</span>
            <small class="text-danger"
              *ngIf="insuranceForm.get('insured').invalid && (insuranceForm.get('insured').touched || insuranceForm.get('insured').dirty)">
              <div *ngIf="insuranceForm.get('insured').errors['required']">This field is required</div>
              <div *ngIf="insuranceForm.get('insured').errors['maxlength']">Insured name should not exceed 50 characters
              </div>
              <div *ngIf="insuranceForm.get('insured').errors['invalidAlphanumericSpecial']">{{
                addProjectUser.firstNameInvalid }}
              </div>
            </small>
          </div>

          <div class="col-md-12 mt-3">
            <p class="form-section-header mt-0 mb-4"> {{ constants.addressLabel }} </p>
          </div>
          <div class="col-md-12 custom-floating-label">
            <label for="insurInfoStreet" class="form-label"> {{ constants.streetLabel }} </label>
            <input type="text" formControlName="street" class="form-control" placeholder="Street" id="insurInfoStreet">
            <small *ngIf="insuranceForm.controls['street'].touched && insuranceForm.controls['street'].errors"
              class="text-danger mt-1">
              <div *ngIf="insuranceForm.get('street').errors['required']">This field is required</div>
              <div *ngIf="insuranceForm.controls['street'].errors['maxLength']">
                Street name should not exceed 85 characters.
              </div>
              <div *ngIf="insuranceForm.controls['street'].errors['invalidStreet']">
                Street name is invalid.
              </div>
            </small>
          </div>
          <div class="col-md-6 custom-floating-label">
            <label for="insurInfoCity" class="form-label"> {{ constants.cityLabel }} </label>
            <input type="text" formControlName="city" class="form-control" placeholder="City" id="insurInfoCity">
            <small *ngIf="insuranceForm.controls['city'].touched && insuranceForm.controls['city'].errors"
              class="text-danger mt-1">
              <div *ngIf="insuranceForm.get('city').errors['required']">This field is required</div>
              <div *ngIf="insuranceForm.controls['city'].errors['maxLength']">
                City must not exceed 50 characters.
              </div>
              <div *ngIf="insuranceForm.controls['city'].errors['invalidCity']">
                City can only contain letters, numbers, and spaces.
              </div>
            </small>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5 custom-dropdown custom-floating-label">
                <label for="prodComState" class="form-label"> {{ constants.stateLabel }} </label>
                <select class="custom-select" id="prodComState" formControlName="state">
                  <option class="dropdown-option" value="">Select</option>
                  <option *ngFor="let state of states" [value]="state.stateId">{{ state.stateName }}</option>
                </select>
              </div>
              <div class="col-md-7 custom-floating-label">
                <label for="insurInfoZipcode" class="form-label"> {{ constants.zipCodeLabel }} </label>
                <input type="text" formControlName="zipcode" class="form-control" placeholder="ZIP Code"
                  id="insurInfoZipcode">
                <small *ngIf="insuranceForm.controls['zipcode'].touched && insuranceForm.controls['zipcode'].errors"
                  class="text-danger mt-1">
                  <div *ngIf="insuranceForm.get('zipcode').errors['required']">This field is required</div>
                  <div *ngIf="insuranceForm.controls['zipcode'].errors['maxLength']">
                    ZIP Code should not exceed 12 characters.
                  </div>
                  <div *ngIf="insuranceForm.controls['zipcode'].errors['invalidZipCode']">
                    ZIP Code can only contain numeric characters.
                  </div>
                </small>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="common-modal-input-section">
    <h2 class="section-main-header"> {{ constants.uploadForm }} </h2>
    <form>
      <div class="row">
        <div class="col-md-12 custom-floating-label my-4">
          <app-upload-file [disabled]="customerStatus == 'NEEDS REVISIONS' || customerStatus == 'REVISIONS SUBMITTED' || customerStatus == 'UNDER REVIEW' || customerStatus == 'Application Submitted' && !this.revisionData?.caRevisionDetails?.certificateOfInsuranceDetails?.reviewRequired ? true : false" (filesUpdated)="onFilesChanged($event)" (filesChanged)="onFileRemoved($event)"
          (viewFileCallback)="openPdf()" [viewFileExternal]="true" [section]="'certificateOfInsurance'" [uploadedFiles]="uploadedFiles"></app-upload-file>
        </div>
      </div>
    </form>
    <div class="col-md-12 custom-floating-label">
      <label for="LtofCreditUploadDoc" class="d-inline-block">
        <span class="fw-semibold text-link d-flex align-items-center" (click)="openPdf()">
          {{constants.sample}}</span>
      </label>
    </div>
  </div>

  <div class="cm-btn-section d-flex align-items-center">
    <div class="left-btn me-auto">
      <a class="text-link" (click)="previousPage()"><img class="back-img" src="../../assets/images/blue-left-icon.svg"
          alt="Back" /> {{
        constants.back }} </a>
    </div>
    <button type="button" class="btn common-btn-secondary" data-bs-dismiss="modal" (click)="nextPage()"> {{
      constants.termsAndConditionsBtn }} </button>
    <button type="button" class="btn common-btn-light ms-2" data-bs-dismiss="modal" (click)="saveAndClose()"
      [disabled]="!insuranceForm.dirty && !isFileUploaded"> {{ constants.saveAndCloseBtn }} </button>
  </div>
</div>

<ng-template #successTpl>
  <div class="success">{{ toastMessages.insuranceSuccess }}</div>
</ng-template>

<ng-template #uploadSuccess>
  <div class="success">{{ toastMessages.uploadSuccess }}</div>
</ng-template>

<ng-template #removeSuccess>
  <div class="success">{{ toastMessages.removeSuccess }}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{ insuranceError }}</div>
</ng-template>
<app-toast aria-live="polite" aria-atomic="true"></app-toast>