import { Component, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {  handbookTab, handbookTab1, handbookTab2, WBSO_page } from 'src/app/core/constants/core.constant';
import { NUMBER } from 'src/app/core/enum/core.enum';
import { CompanyService } from 'src/app/feature/studio-portal/services/company.service';

@Component({
  selector: 'app-wbso-handbook-page',
  templateUrl: './wbso-handbook-page.component.html',
  styleUrls: ['./wbso-handbook-page.component.scss']
})
export class WbsoHandbookPageComponent implements OnInit {
  constants = WBSO_page;
  handbookTab = handbookTab;
  handbookTab1 = handbookTab1;
  handbookTab2 = handbookTab2;
  number = NUMBER;
  portalType: string;
  handbookHeadContent: any=[];
  handbookContent: any;
  chart: any;
  css: any;
  cssHead: any;
  onTheLotCountryName: any;
  handbookContent1: any;
  handbookContent2: any;
  selectedTab: string = this.constants.overview;
  isFirstCollapsed: boolean = true;

  constructor(private companyService: CompanyService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    const userProfile = JSON.parse(localStorage.getItem('user-profile'));
    if (userProfile?.userTypeId == 1) {
      this.portalType = "customer";
    }
    else if (userProfile?.userTypeId == 2) {
      this.portalType = "studio";
    }
    this.onTheLotCountryName = userProfile?.userPreferences?.onTheLotCountry?.onTheLotCountryName ?  userProfile.userPreferences?.onTheLotCountry?.onTheLotCountryName : 'Burbank';
    this.handbookApiOfcProd( this.selectedTab );

  }





  /**
   * selectTab() - method to update the currently selected tab.
   * @param tab - The identifier for the tab to be selected. This is typically a string that corresponds to one of the available tabs.
  */
  selectTab(tab: string) : void {
    this.selectedTab = tab;
    this.handbookApiOfcProd( this.selectedTab );
    this.handbookApiPurchase( this.selectedTab );
    this.handbookApiStudioProt( this.selectedTab );
    
  }

  /**
   * onSelectChange() - method to render different tabs after clicking on the button 
   * @param event - The change event object associated with the select element.
  */
  onSelectChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedTab = selectElement.value;
    console.log(this.selectedTab);
    this.handbookApiOfcProd( this.selectedTab );
    this.handbookApiPurchase( this.selectedTab );
    this.handbookApiStudioProt( this.selectedTab );
  }
  
   /**
   * handbookApiOfcProd() - method to get office production tab details
  */
  handbookApiOfcProd(selectedTab:any) : void {
  
      let url = 'https://client.wb.com/handbook/section-1/';  
 
      this.companyService.getHtmlContent(url).subscribe(
        (content) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(content, 'text/html');
           // Get all h3 elements and filter out the blank ones
          const headings = Array.from(doc.body.querySelectorAll('h3'))
          .filter(h3 => {
            const text = h3.textContent?.trim();
            return text && text.length > 0;  
          });

          const specificHeading = Array.from(headings).find(h3 => {
          
            return h3.textContent.trim() === selectedTab;
          });
        
          if (specificHeading) {
            const parentDiv = specificHeading.closest('.ae-acf-repeater-item'); 
            
            if (parentDiv) {
              this.handbookContent =  this.sanitizer.bypassSecurityTrustHtml(parentDiv.innerHTML);  
            } 
          } else {
          }
        },
        (error) => {
          console.error('Error fetching HTML content:', error);
        }
      );
   
   
  }
  
   /**
   * handbookApiPurchase() - method to get purchase and billing tab details
  */
  handbookApiPurchase(selectedTab:any) : void  {
 
    let url = 'https://client.wb.com/handbook/section-2/';
    this.companyService.getHtmlContent(url).subscribe(
      (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html'); 
        const headings = doc.body.querySelectorAll('h3');
  
        const specificHeading = Array.from(headings).find(h3 => {
          return h3.textContent.trim() === selectedTab;
        });
  
        if (specificHeading) {
          const parentDiv = specificHeading.closest('.ae-acf-repeater-item'); 
          
          if (parentDiv) {
            this.handbookContent1 =  this.sanitizer.bypassSecurityTrustHtml(parentDiv.innerHTML);  
          } 
        } else {
        
        }
      },
      (error) => {
        console.error('Error fetching HTML content:', error);
      }
    );
  
  }

  /**
   * handbookApiStudioProt() - method to get studio protection tab details
  */
  handbookApiStudioProt(selectedTab:any)  : void {
    
    let url = 'https://client.wb.com/handbook/section-3/';
    this.companyService.getHtmlContent(url).subscribe(
      (content) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const headings = doc.body.querySelectorAll('h3');
  
        const specificHeading = Array.from(headings).find(h3 => {
          return h3.textContent.trim() === selectedTab;
        });
  
        if (specificHeading) {
          const parentDiv = specificHeading.closest('.ae-acf-repeater-item'); 
          
          if (parentDiv) {
            this.handbookContent2 = this.sanitizer.bypassSecurityTrustHtml(parentDiv.innerHTML);  
          } 
        } else {
        
        }
      },
      (error) => {
        console.error('Error fetching HTML content:', error);
      }
    );
  
}
  
}
