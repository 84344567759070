<div class="container cm-main-container px-0 mx-0">
  <div class="common-modal-input-section grey-bg review-panel">
    <div class="row align-items-center">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-text">
        <h4 class="section-main-header cust-risk-pend-sec-header">{{ constants.titles.status }}</h4>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 custom-status-button">
        <div class="status-container">
          <div [ngClass]="getStatusClass(rowData?.status)" class="ar-status-tag">
            {{ rowData.status }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4 align-items-center">
      <div class="col-md-12">
        <!-- Conditionally render A/R # and related content -->
        <ng-container *ngIf="rowData.status !== 'DENIED'">
          <p class="mb-2">
            <strong><span class="title-content">A/R # :&nbsp;</span></strong>
            <span class="ar-no-box ms-3">{{ rowData.ar || '- - - - - -' }}</span>
            <a  (click)="copyToClipboard(rowData.ar)"
              class="ms-3 edit-project-info text-decoration-none clickable"><img src="../../assets/images/copy.svg"
                class="" alt="edit department">


              <span class="text-link ms-1">Copy </span>
            </a>
          </p>

          <p class="mb-2">
            <strong><span class="title-content">Credit Limit:&nbsp;</span></strong>
            <span class="pend-risk-details">{{ rowData.creditLimit}}</span>
          </p>

          <p class="mb-2">
            <strong><span class="title-content">Approved on:&nbsp;</span></strong>
            <span class="pend-risk-details">{{ rowData.approvedOn }}</span>
            <span class="mb-0 title-content">&nbsp;&nbsp;&nbsp;<strong>Approved by:</strong></span>
            <span class="pend-risk-details">&nbsp;{{ rowData.approvedBy }}</span>
          </p>
        </ng-container>

        <!-- Conditionally render Denied on and Denied by -->
        <ng-container *ngIf="rowData.status === 'DENIED'">
          <p class="mb-2">
            <strong><span class="title-content">Denied on:&nbsp;</span></strong>
            <span class="pend-risk-details">{{ rowData.deniedOn | date:'MM/dd/yyyy' }}</span>
            <span class="mb-0 title-content">&nbsp;&nbsp;&nbsp;<strong>Denied by:</strong></span>
            <span class="pend-risk-details">&nbsp;{{ rowData.deniedBy }}</span>
          </p>
        </ng-container>

        <p class="mb-2">
          <strong><span class="title-content">Reasons:&nbsp;</span></strong>
          <span class="pend-risk-details">{{ rowData.reasons }}</span>
        </p>
      </div>
    </div>

    <hr class="my-5"><!-- divider -->
    <!-- Conditionally render Letter of Approval section -->
    <ng-container *ngIf="rowData.status !== 'DENIED'">
      <div class="row align-items-center">
        <div class="col-7 d-flex align-items-center gap-2">
          <strong>Letter of Approval Sent on&nbsp;:</strong>
          <span>{{ rowData.letterSentOn | date:'MM/dd/yyyy h:mm a' }}</span>
          <a class="text-link mb-0 clickable" (click)="openRiskEmailModal()">View</a>
        </div>
        <div class="col-5 d-flex align-items-center gap-2">
          <strong>Sent by&nbsp;:</strong>
          <span>{{ rowData.sentBy }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="common-modal-input-section grey-bg review-panel">
    <div class="row mb-4 align-items-center">
      <div class="col-md-12">
        <p class="mb-0"><span class="title-content">Cash On Demand Departments :</span></p>
        <p class="mb-0">
          <span class="pend-risk-details">{{rowData?.department}}</span>
        </p>
        <p class="mb-0"><a class="edit-project-info text-decoration-none clickable"> <img
              src="../../assets/images/edit-icon.svg" class="" alt="edit department"><span class="text-link ms-1"
              (click)="openAddDepartmentModal()">Add
              Department</span> </a></p>
      </div>
    </div>

    <hr class="my-5"><!-- divider -->
    <div class="row mb-4 align-items-center">
      <div class="col-7 d-flex align-items-center gap-2">
        <strong>COD Notification Sent on&nbsp;:</strong>
        <span>1/1/24 12:00PM</span>
        <a class="text-link mb-0 clickable" (click)="openRiskEmailModal()">View</a>
      </div>
      <div class="col-5 d-flex align-items-center gap-2">
        <strong>Sent by&nbsp;:</strong>
        <span>Sophia Martinez</span>
      </div>
    </div>
  </div>
  <!-- Comments Section -->
  <div class="grey-box mb-0">
    <h2 class="section-main-header text-left common-heading">
      {{ constants.titles.comments }}({{ comments.length }})
    </h2>
    <div class="comment-section">
      <div *ngFor="let comment of comments" class="row comment-list">
        <div class="commnet-icon-section col-md-1">
          <span class="user-icon-text">{{(comment.modifiedByName)}}</span>
        </div>
        <div class="common-comment-section col-11">
          <p class="font-14 mb-0">
            <strong class="common-content-bold">{{ comment.modifiedByName}}</strong>
          </p>
          <p class="common-small-content">{{ comment.modifiedDatetime }}</p>
          <p class="common-normal-content">
            {{ comment.commentText }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="grey-box mt-1">
    <div class="row text-area-block">
      <div class="col-12">
        <textarea class="form-control form-control-lg common-text-area" placeholder="{{ constants.labels.typeComment }}"
          [(ngModel)]="commentText"></textarea>
      </div>
    </div>
  </div>
  <div class="cm-btn-section">
    <button type="button" class="btn common-btn-secondary me-2 mb-2" (click)="addComments()">Add Comment</button>
  </div>
</div>

<ng-template #confirmationModal let-modal>
  <div class="modal-body">
    <h4 class="section-main-header">Are you sure you want to reopen this credit application?</h4>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="common-btn-light btn" (click)="modal.close()">
        Cancel
      </button>
      <button type="button" class="common-btn-secondary btn">
        Reopen
      </button>
    </div>
  </div>
</ng-template>
<ng-template #successTpl>
  <div class="success">{{success_msg}}</div>
</ng-template>

<ng-template #dangerTpl>
  <div class="danger">{{error_msg}}</div>
</ng-template>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>